import ReactGA from "react-ga";
import { dispatchEvent, eventsToDispatch } from "@coworker/sharedlibrary/src/utils/eventDispatcher";
import { setDateRange } from "../../../recentorder/src/store/recentorder/thunks";

const AppLogger = {
	warn: (message, event, properties) => {
		const data = {
			level: "WARN",
			event: event,
			message: message,
			logData: properties
		};
		dispatchEvent(eventsToDispatch.LOG, data);
		dispatchEvent(eventsToDispatch.LOG_TO_NATIVE, data);
	},
	info: (message, event, properties) => {
		const data = {
			level: "INFO",
			event: event,
			message: message,
			logData: properties
		};
		dispatchEvent(eventsToDispatch.LOG, data);
		dispatchEvent(eventsToDispatch.LOG_TO_NATIVE, data);
	},
	error: (message, event, properties) => {
		const data = {
			level: "ERROR",
			event: event,
			message: message,
			logData: properties
		};
		dispatchEvent(eventsToDispatch.LOG, data);
		dispatchEvent(eventsToDispatch.LOG_TO_NATIVE, data);
	},
	sendToGAEvent: (category, action, label, value) => {
		try {
			ReactGA.event({
				category: category,
				action: action,
				label: label,
				value: value || 1
			});
		} catch {
			//need to see if this really happens in microfrontend
		}
	}
};

export default AppLogger;
