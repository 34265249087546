export const CatalogPage = "/browse/";
export const CatalogPagePath = CatalogPage.concat(":id");
export const BrowsePage = "/browse/:id";
export const SearchPage = "/search/";
export const SearchPagePath = SearchPage.concat(":query");
export const CartPagePath = "/cart";
export const DetailPage = "/detail/";
export const DetailPagePath = DetailPage.concat(":id");
export const PlannerPage = "/planner/";
export const PlannerPagePath = PlannerPage.concat(":id");
export const RoomsetPage = "/roomset/";
export const RoomsetPagePath = RoomsetPage.concat(":id");
export const ServicePagePath = "/service";
export const PtagPage = "/ptag/";
export const PtagPagePath = PtagPage.concat(":id");
export const AboutPagePath = "/about";
export const UserProfilePath = "/user";
export const StorePagePath = "/storeinfo";
export const RecentOrderPagePath = "/recentorder";
export const ScannerPagePath = "/scan";
export const ExplorePagePath = "/explore";
export const KitchenPlannerPagePath = "/kitchenplanner/";
export const WebShoppingListPath = "/web/list";
export const kioskpath = "/kiosk/list";
export const ExternalPlannerPage = "/external/import/planner/";
export const ExternalPlannerPagePath = ExternalPlannerPage.concat(":id");

export const ExternalPtagPage = "/external/import/ptag/";
export const ExternalPtagPagePath = ExternalPtagPage.concat(":id");

export const ExternalCartPagePath = "/external/import/cart/";

export const ExternalKitchenPlannerPagePath = "/external/import/kitchenplanner/";

export const ExternalWebShoppingListPath = "/external/import/web/list/";

export const ExternalSearchPage = "/external/search/";
export const ExternalSearchPagePath = ExternalSearchPage.concat(":query");
export const ExternalDetailPage = "/external/detail/";
export const ExternalDetailPagePath = ExternalDetailPage.concat(":id");

export const ExternalProductDetailPagePath = "/external/productdetail/";
