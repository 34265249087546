import * as RetailitemMapper from "@coworker/sharedlibrary/src/helpers/retailItemInformationHelper";

import APIEndpoints from "@coworker/sharedlibrary/src/constants/apiEndpoints";
import AppLogger from "@coworker/sharedlibrary/src/logger/appLogger";
import URI from "@coworker/sharedlibrary/src/helpers/uriLinkHelper";
import httpService from "@coworker/sharedlibrary/src/service/httpService";

class ProductService {
	constructor(config) {
		this.config = config;
	}

	/**
	 * Calls getServiceProductRelations to fetch full details of a item.
	 *
	 * @param {Object} item [RetailItem]
	 * @param {String} item.ItemType ItemType of retailItem ART|SPR
	 * @param {String} item.ItemId Item id of retailitem 10000000
	 *
	 * @return {Object} Returns serviceProductRelations.
	 */

	getServiceProductRelations = async retailItem => {
		try {
			const { buCode, isMetricUsingStore, backEndLocale } = this.config;

			const uri = new URI(APIEndpoints.retailItem, true);
			const url = uri.fill({
				bu: buCode,
				id: `${retailItem.ItemType}${retailItem.ItemId}`
			});

			let retailItemFull = RetailitemMapper.mapRetailItem({ ...retailItem }, isMetricUsingStore, backEndLocale);

			const result = await httpService.get(url, {
				headers: this.config.headers
			});

			retailItemFull = RetailitemMapper.mapRetailItem({ ...result }, isMetricUsingStore, backEndLocale);

			if (retailItemFull.ServiceProductRelations) {
				return Promise.resolve(retailItemFull.ServiceProductRelations);
			}

			return Promise.reject();
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);

			return Promise.reject();
		}
	};
	/**
	 * Calls getRetailItem to fetch full details of a item.
	 *
	 *
	 * @param {String} itemId ItemType of retailItem ART|SPR
	 * @param {Boolean} useStock fetch stock if true
	 *
	 * @return {Object} Returns a  retailItem.
	 */
	scanItem = async (itemId, useStock) => {
		const { isMetricUsingStore, backEndLocale, buCode } = this.config;
		var uri = new URI(APIEndpoints.retailItem, true);
		var url = uri.fill({
			bu: buCode,
			id: itemId
		});

		const rixResponse = await httpService.get(url, {
			headers: this.config.headers
		});
		if (rixResponse !== null && rixResponse !== undefined) {
			var mapped = RetailitemMapper.mapRetailItem(rixResponse, isMetricUsingStore, backEndLocale);
			mapped.isProductItem = true;
			if (useStock) {
				var stockupdatedItems = await this.getUpdatedItemsStock([mapped]);
				if (stockupdatedItems) mapped = stockupdatedItems && stockupdatedItems[0];
			}
			return mapped;
		} else {
			// var ptag = await this.findPtag(itemId);
			// if (ptag !== null) {
			//   ptag.isCombination = true;
			//   return ptag;
			// }
		}
		return null;
	};

	/**
	 * Calls getRetailItem to fetch full details of a item.
	 *
	 * @param {Object} item [RetailItem]
	 * @param {String} item.ItemType ItemType of retailItem ART|SPR
	 * @param {String} item.ItemId Item id of retailitem 10000000
	 *
	 * @return {Object} Returns a  retailItem.
	 */
	getRetailItem = async retailItem => {
		try {
			const { buCode, isMetricUsingStore, backEndLocale } = this.config;

			let uri = new URI(APIEndpoints.retailItem, true);
			let url = uri.fill({
				bu: buCode,
				id: `${retailItem.ItemType}${retailItem.ItemId}`
			});
			let retailItemFull = RetailitemMapper.mapRetailItem({ ...retailItem }, isMetricUsingStore, backEndLocale);
			const result = await httpService.get(url, {
				headers: this.config.headers
			});

			retailItemFull = RetailitemMapper.mapRetailItem({ ...result }, isMetricUsingStore, backEndLocale);
			if (retailItem.Rating) {
				retailItemFull.Rating = retailItem.Rating;
			} else {
				if (retailItemFull.ItemRating) {
					retailItemFull.Rating = {
						Count: retailItemFull.ItemRating.Count,
						Max: 5,
						Value: retailItemFull.ItemRating.Average
					};
				}
			}
			if (retailItem.storeInfo) {
				RetailitemMapper.updatePrice(retailItem.storeInfo, retailItemFull);
				retailItemFull.storeInfo = retailItem.storeInfo;
			}
			return retailItemFull;
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);
			return null;
		}
	};

	/**
	 * Calls retailItems getRetailItemsByid api and returns retailItemsData.
	 *
	 * @param {Array} ids [itemIds = 'ART1000000|SPR1000000']
	 * @param {Boolean} fetchItemStock fetches itemStock if set to true
	 *
	 * @return {Array} Returns List of retailItems.
	 */
	getRetailItemsByid = async (ids, fetchItemStock = true, fetchLatestStock = true) => {
		try {
			if (ids && ids.length === 0) {
				return [];
			}
			const { isMetricUsingStore, backEndLocale, buCode } = this.config;

			let url = new URI(APIEndpoints.retailItems, true).fill({ bu: buCode });

			console.log("URL: " + url);

			let fqBuilder = "";
			ids.forEach(item => {
				fqBuilder = fqBuilder + "&id=" + item;
			});
			url = url + fqBuilder;
			let result;
			if (ids.length < 50) {
				result = await httpService.get(url, {
					headers: this.config.headers
				});
			} else {
				let bulkUrl = new URI(APIEndpoints.retailItemsBulk, true).fill({
					bu: buCode
				});
				result = await httpService.post(bulkUrl, {
					body: {
						itemIds: ids
					},
					headers: this.config.headers
				});
			}

			let resultitems = [];
			const items = result.retailItems;

			if (items !== undefined) {
				items.forEach(element => {
					let item = RetailitemMapper.mapRetailItem(element, isMetricUsingStore, backEndLocale);

					resultitems.push(item);
				});
				if (fetchItemStock) {
					await this.getUpdatedItemsStock(resultitems, fetchLatestStock);
				}
				return resultitems;
			}
		} catch (error) {
			AppLogger.error(error.message, "getRetailItemsByid");
			return [];
		}
	};
	/**
	 * Calls retailItems getRetailItemsByid api and returns retailItemsData.
	 *
	 * @param {Object} item [RetailItem]
	 * @param {String} item.ItemType ItemType of retailItem ART|SPR
	 * @param {String} item.ItemId Item id of retailitem 10000000
	 *
	 * @return {Array} Returns List of retailItems.
	 */
	getChildItems = async (item, fetchStock = true, fetchLatestStock = true) => {
		const { buCode } = this.config;
		let uri = new URI(APIEndpoints.sprchildItems, true);
		let url = uri.fill({
			bu: buCode,
			id: `${item.ItemType}${item.ItemId}`
		});
		const result = await httpService.get(url, {
			headers: this.config.headers
		});

		let childItems = result.childItems;
		if (childItems && childItems.length > 0) {
			let retailItems = childItems.map(cItem => {
				return cItem.retailItem;
			});
			if (fetchStock) await this.getUpdatedItemsStock(retailItems, fetchLatestStock);

			return childItems;
		}
		return [];
	};

	/**
	 * Calls relatedItems API to get complementory and similar items
	 *
	 *
	 * @param {Object} item [RetailItem]
	 * @param {String} item.ItemType ItemType of retailItem ART|SPR
	 * @param {String} item.ItemId Item id of retailitem 10000000
	 * @param {String} type complementory|similar
	 *
	 * @return {Array} Returns List of related retail Items.
	 */
	getRelatedItems = async (item, type) => {
		const { isMetricUsingStore, backEndLocale, buCode } = this.config;

		let uri = new URI(APIEndpoints.relatedRetailItems, true);
		let url = uri.fill({
			type: type.toLowerCase(),
			bu: buCode,
			id: `${item.ItemType}${item.ItemId}`
		});
		const result = await httpService.get(url, {
			headers: this.config.headers
		});
		let resultitems = [];
		const items = result.retailItems;

		if (items !== undefined && items.length > 0) {
			items.forEach(element => {
				let item = RetailitemMapper.mapRetailItem(element, isMetricUsingStore, backEndLocale);

				resultitems.push(item);
			});
			await this.getUpdatedItemsStock(resultitems);

			return resultitems;
		}
		return [];
	};

	/**
	 * Calls relatedItems API to get complementory and similar items
	 *
	 *
	 * @param {Object} item [RetailItem]
	 * @param {String} item.ItemType ItemType of retailItem ART|SPR
	 * @param {String} item.ItemId Item id of retailitem 10000000
	 *
	 * @return {Array} Returns List of related series retails Items.
	 */
	getSeriesItems = async item => {
		try {
			const { isMetricUsingStore, backEndLocale, countryCode, languageCode, buCode } = this.config;

			const catalogueTypeSeries = item.CatalogReferences && item.CatalogReferences.find(x => x.catalogueId === "series" || x.catalogueId === "SERIES");

			if (catalogueTypeSeries && catalogueTypeSeries.categoryId) {
				let uri = new URI(APIEndpoints.rangecategoryitems, true);
				let url = uri.fill({
					catId: `${catalogueTypeSeries.categoryId}`,
					countryCode: countryCode,
					language: languageCode,
					buCode: buCode,
					limit: 25
				});
				let result = await httpService.get(url, {
					headers: this.config.headers
				});
				let seriesItemsresult = result.items || [];
				const categoryItems = {
					items: [],
					facets: seriesItemsresult.Facets,
					numFound: seriesItemsresult.length,
					itemNextLink: "",
					itemsLink: ""
				};

				seriesItemsresult.forEach(element => {
					let retailItem = RetailitemMapper.buildCatalogRetailItem(element, isMetricUsingStore, backEndLocale);

					categoryItems.items.push(retailItem);
				});
				await this.getUpdatedItemsStock(categoryItems.items);

				return { ...categoryItems, seriesId: catalogueTypeSeries.categoryId };
			}
		} catch (error) {
			return null;
		}
		return null;
	};

	/**
	 * Calls relatedItems API to get complementory and similar items
	 *
	 *
	 * @param {Object} item [RetailItem]
	 * @param {String} item.gprId grpId of retail Items
	 *
	 * @return {Array} Returns List of related gpr items ( color.size)
	 */
	getGprs = async item => {
		if (!item.hasGpr) {
			return [];
		}
		let retailItems = [];
		try {
			const { isMetricUsingStore, backEndLocale, buCode } = this.config;
			const url = new URI(APIEndpoints.retailItemGprs, true).fill({
				id: item.gprId,
				bu: buCode
			});
			const gpr = await httpService.get(url, {
				headers: this.config.headers
			});

			if (gpr && gpr.retailItems && gpr.retailItems.length > 0) {
				gpr.retailItems.forEach(element => {
					let gprRetailItem = RetailitemMapper.mapRetailItem({ ...element }, isMetricUsingStore, backEndLocale);

					retailItems.push(gprRetailItem);
				});

				return retailItems;
			}
		} catch (error) {
			//
		}
		return retailItems;
	};

	/**
	 * Calls relatedItems API to get complementory and similar items
	 *
	 *
	 * @param {Object} retailItem [RetailItem]
	 * @param {String} item.ItemType ItemType of retailItem ART|SPR
	 * @param {String} item.ItemId Item id of retailitem 10000000
	 * @param {String} bu business unit of a store
	 *
	 * @return {Array} Returns detail stock of item including forecast and saleslocations
	 */
	getStockandLocationsAsync = async (retailItem, bu) => {
		let link = new URI(APIEndpoints.retailItemDetailStock, true);
		let url = link.fill({
			id: `${retailItem.ItemType}${retailItem.ItemId}`,
			bu: bu
		});
		const forecastReponse = await httpService.get(url, {
			headers: this.config.headers
		});

		return forecastReponse.detailStockInfo;
	};

	getItemSalesDetails = async itemid => {
		const link = new URI(APIEndpoints.retailItemSearch, true).fill({
			q: itemid,
			bu: this.config.buCode
		});

		const result = await httpService.get(link, {
			headers: this.config.headers
		});
		let items =
			(result &&
				result.Result &&
				result.Result.Items &&
				result.Result.Items.length > 0 &&
				result.Result.Items.map(element => {
					let retailItem = RetailitemMapper.buildSearchRetailItem({ ...element }, true, "");
					return retailItem;
				})) ||
			[];
		return (items && items[0].SalesEndDate) || "";
	};
	/**
	 * Calls retailItems api and returns retailItemsData.
	 *
	 * @param {Array} [items = 'ART1000000|SPR1000000'] use itemType and itemId
	 * @param {string} [buCode = '445'] use bussineesUnit
	 * @return {Array} Returns retailItems with stock.
	 *
	 */
	getUpdatedItemsStock = async (items, fetchLatest = false, fetchSalesPrice = false) => {
		if (!items || items.length === 0) {
			return [];
		}

		let request = {
			fetchSalesPrice: fetchSalesPrice,
			countryCode: this.config.countryCode,
			bu: this.config.buCode,
			items: items
				.filter(x => x !== undefined)
				.map(x => {
					return {
						ItemType: x.ItemType,
						ItemId: x.ItemId
					};
				}),
			fetchLatest: fetchLatest
		};
		return httpService
			.post(APIEndpoints.retailItemStock, {
				body: request,
				headers: this.config.headers
			})
			.then(response => response.retailItems)
			.then(retailItems => {
				let stockUpdatedItems = [...items];
				if (retailItems) {
					stockUpdatedItems.forEach(item => {
						let stockItem = retailItems.find(x => x.ItemId === item.ItemId);
						if (stockItem) {
							item.storeInfo = stockItem;
							item.availableStockToSell = getAvailableStock(stockItem);
							item.isFoodItem = RetailitemMapper.isFoodItemSpec(item.storeInfo);

							let price = stockItem.StorePrices && stockItem.StorePrices.find(stock => stock.Type === "NORMAL");
							if (price) {
								item.price = price;
							}
							let familyPrice = stockItem.StorePrices && stockItem.StorePrices.find(stock => stock.Type === "FAMILY");
							if (familyPrice) {
								item.familyPrice = familyPrice;
							}
						}
					});
					return stockUpdatedItems;
				}
				return stockUpdatedItems;
			})
			.then(result => {
				return Promise.resolve(result);
			})
			.catch(() => {
				return Promise.resolve(items);
			});
	};
}

export default ProductService;
const getAvailableStock = storeInfo => {
	let stock = storeInfo.StockAvailabilities;

	if (stock) {
		return stock.ToSell;
	}
	return 0;
};
