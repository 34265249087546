export const DfpKey = {
	ENABLE_LCD_ORDER: "ikea.salja.settings.ENABLE_LCD_ORDER",
	ENABLE_CASH_ON_DELIVERY: "ikea.mtc.mfs.sales.configattr.ENABLE_CASH_ON_DELIVERY",
	TRANSPORT_DESK_CASHLINES: "ikea.salja.settings.TRANSPORT_DESK_CASHLINES",
	SHOW_PAYORDER_LINK_FOR_TRANSPORT_ORDER: "ikea.salja.settings.SHOW_PAYORDER_LINK_FOR_TRANSPORT_ORDER",
	ENABLE_TRANSPORT_ORDER_MERGE: "ikea.salja.settings.ENABLE_TRANSPORT_ORDER_MERGE",
	NUMBER_OF_DAYS_TO_CHECK_FOR_MERGE_ORDER: "ikea.salja.settings.NUMBER_OF_DAYS_TO_CHECK_FOR_MERGE_ORDER",
	NOTIFICATION_PICKING_READY_FOR_HANDOUT_ENABLED: "ikea.mtc.mfs.sales.configattr.NOTIFICATION_PICKING_READY_FOR_HANDOUT_ENABLED",

	DELIVERY_FRIEGHT_REASON_CODES: "ikea.mtc.mfs.sales.configattr.REASON_CODES_CHANGE_FREIGHT",
	ADDRESS_SUGGESTION: "ikea.salja.settings.ADDRESSSUGGESTION",

	DFP_PRODUCTNAME: "Salja",

	DELIVERY_METHOD_WHITELIST: "ikea.salja.settings.DELIVERYMETHODWHITELIST",

	PROVIDED_METHOD_WHITELIST: "ikea.salja.settings.SERVICEWHITELIST",

	STORE_PICKING_PRICE: "ikea.salja.settings.STOREPICKINGPRICE",

	WEBSALJA_ALLOWED_USERS: "ikea.salja.settings.WEBSALJA_ALLOWED_USERS",

	ENABLE_ICMPROFILE_CREATE_UPDATE: "ikea.salja.settings.ENABLEICMPROFILECREATEORUPDATE",
	SERVICE_COST_BASED_ON_ORIGINAL_PRICE: "ikea.mtc.mfs.sales.configattr.SERVICE_COST_BASED_ON_ORIGINAL_PRICE",

	//pbi        8220//
	SHOW_CUSTOMER_APPROVAL_CHECKBOX: "ikea.mtc.mfs.service.configattr.SHOW_CUSTOMER_APPROVAL_CHECKBOX",

	PREFFERD_CONTACT_METHOD_FOR_NOTIFICATION_FOR_SURVEY: "ikea.mtc.mfs.sales.configattr.PREFERRED_CONTACT_METHOD_FOR_NOTIFICATION_FOR_SURVEY",

	TAX_ID_FORMAT: "ikea.mtc.mfs.common.configattr.TAX_ID_FORMAT",

	TAX_EXCLUDE_VALIDATION_ID: "ikea.mtc.mfs.common.configattr.FIELDS_TO_EXCLUDE_TAXID_TYPE_VALIDATION",

	PREFFERED_ORDER_MANAGEMENT_INTEGRATION_POINT: "ikea.mtc.mfs.sales.configattr.ORDER_MANAGEMENT_INTEGRATION_POINT",

	ISOM_ALLOWED_USERS: "ikea.salja.settings.ISOM_ALLOWED_USERS",

	PRIMARY_CURRENCY: "ikea.mtc.mfs.common.configattr.PRIMARY_CURRENCY",
	ICM_PROFILE_ENABLED: "ikea.mtc.mfs.sales.configattr.ICM_PROFILE_ENABLED",
	MANDATORY_CONTACT_METHOD_TYPE_ROLE_BUSINESS: "ikea.mtc.mfs.cust.configattr.MANDATORY_CONTACT_METHOD_TYPE_ROLE_BUSINESS",
	MANDATORY_CONTACT_METHOD_TYPE_ROLE_PRIVATE: "ikea.mtc.mfs.cust.configattr.MANDATORY_CONTACT_METHOD_TYPE_ROLE_PRIVATE",

	URL_FOR_PAYORDER_ONLINE: "ikea.mtc.mfs.sales.configattr.URL_FOR_PAYORDER_ONLINE",
	DEFAULT_PRINT_TERMS_AND_CONDITION_OPTION: "ikea.salja.settings.DEFAULT_PRINT_TERMS_AND_CONDITION_OPTION",
	SALES_ORDER_CREATION_METHOD: "ikea.mtc.mfs.sales.configattr.SALES_ORDER_CREATION_METHOD",
	DEFAULT_SALES_ORDER_CREATION_METHOD: "ikea.mtc.mfs.sales.configattr.DEFAULT_SALES_ORDER_CREATION_METHOD",
	ISOM_ROLLOUT_CONFIGURATION: "ikea.mtc.mfs.sales.configattr.ISOM_ROLLOUT_CONFIGURATION",
	CUSTOMER_MEETING_POINT_SITE_REFERENCE: "ikea.mtc.mfs.sales.configattr.CUSTOMER_MEETING_POINT_SITE_REFERENCE",
	DISCOUNT_TYPES: "ikea.mtc.mfs.sales.configattr.ALLOWED_DISCOUNT_TYPES",
	FAQ_SECTION_LINKS: "ikea.salja.settings.FAQ_SECTION_LINKS",
	SUPERUSER_MAIL: "ikea.salja.settings.SUPERUSER_MAIL",
	ENABLE_SO_DISCOUNT_TYPES: "ikea.mtc.mfs.sales.configattr.ENABLE_SO_DISCOUNT_TYPES",

	EMPLOYEE_DISCOUNT_PERCENTAGE: "ikea.mtc.mfs.sales.configattr.EMPLOYEE_DISCOUNT_PERCENTAGE",

	ALL_CAN_CREATE_VOUCHER_AND_EMPLOYEE_DISCOUNT: "ikea.mtc.mfs.sales.configattr.ALL_CAN_CREATE_VOUCHER_AND_EMPLOYEE_DISCOUNT",

	DELIVERY_PRICE_CONFIG: "ikea.mtc.mfs.sales.configattr.ENABLE_DELIVERY_PRICE_CONFIGRATION",
	ALLOWED_PAYMENT_DELIVERY_CODES: "ikea.mtc.mfs.sales.configattr.ALLOWED_DELIVERY_SERVICES_FOR_PAYMENT_ON_DELIVERY",
	ALLOWED_DELIVERY_SERVICES_FOR_CASH_ON_COLLECT: "ikea.mtc.mfs.sales.configattr.ALLOWED_DELIVERY_SERVICES_FOR_CASH_ON_COLLECT",

	CASH_ON_DELIVERY_NOT_ALLOWED: "ikea.mtc.mfs.sales.configattr.CASH_ON_DELIVERY_NOT_ALLOWED",

	ENABLE_CASH_ON_COLLECT: "ikea.mtc.mfs.sales.configattr.ENABLE_CASH_ON_COLLECT",
	ISOM_TERMS_AND_CONFITION: "ikea.mtc.mfs.sales.configattr.GENERAL_TERMS_AND_CONDITION_ISOM",
	LCD_TERMS_AND_CONFITION: "ikea.mtc.mfs.sales.configattr.LCD_CONDITION_RTF",

	CASH_AND_CARRY_TERMS_AND_CONFITION: "ikea.mtc.mfs.sales.configattr.SALES_ORDER_CONDITION_RTF",

	TRANSPORT_DESK_PRINTER_NAME: "ikea.salja.settings.TRANSPORT_DESK_PRINTER_NAME",
	TRANSPORT_DESK_EMPU_PRINTER_NAME: "ikea.salja.settings.TRANSPORT_DESK_EMPU_PRINTER_NAME",
	DEFAULT_PREFERRED_WAY_OF_COMMUNICATION: "ikea.mtc.mfs.sales.configattr.DEFAULT_PREFERRED_WAY_OF_COMMUNICATION",
	REASON_CODES_CANCEL_SALES_ORDER: "ikea.mtc.mfs.sales.configattr.REASON_CODES_CANCEL_SALES_ORDER",
	REASON_CODES_UPDATE_ORDER: "ikea.mtc.mfs.sales.configattr.REASON_CODES_CHANGE_DEL_DATE",

	ICM_EXCHANGE_BETWEEN_PREFERRED_WAY_OF_COMMUNICATION_BUSINESSCUST_BILLING:
		"ikea.mtc.mfs.sales.configattr.ICM_EXCHANGE_BETWEEN_PREFERRED_WAY_OF_COMMUNICATION_BUSINESSCUST_BILLING",
	ICM_EXCHANGE_BETWEEN_PREFERRED_WAY_OF_COMMUNICATION_BUSINESSCUST_DELIVERY:
		"ikea.mtc.mfs.sales.configattr.ICM_EXCHANGE_BETWEEN_PREFERRED_WAY_OF_COMMUNICATION_BUSINESSCUST_DELIVERY",
	ICM_EXCHANGE_BETWEEN_PREFERRED_WAY_OF_COMMUNICATION_PRIVATECUST_BILLING:
		"ikea.mtc.mfs.sales.configattr.ICM_EXCHANGE_BETWEEN_PREFERRED_WAY_OF_COMMUNICATION_PRIVATECUST_BILLING",
	ICM_EXCHANGE_BETWEEN_PREFERRED_WAY_OF_COMMUNICATION_PRIVATECUST_DELIVERY:
		"ikea.mtc.mfs.sales.configattr.ICM_EXCHANGE_BETWEEN_PREFERRED_WAY_OF_COMMUNICATION_PRIVATECUST_DELIVERY",

	ORDER_CONFIRMATION_TYPE: "ikea.salja.settings.ORDER_CONFIRMATION_TYPE",
	STANDALONE_SERVICE_INTEGRATOR: "ikea.salja.settings.STANDALONE_SERVICE_INTEGRATOR",
	ALLOWED_PROVIDED_SERVICES_ON_TRANSPORTORDER: "ikea.salja.settings.ALLOW_PROVIDED_SERVICES_ON_TRANSPORT_ORDER",
	ANNOUNCEMENT_FOR_USERS: "ikea.salja.settings.ANNOUNCEMENT_FOR_USERS",
	PREFERRED_CONTACT_METHOD_FOR_NOTIFICATION_PICKING_READY_FOR_HANDOUT:
		"ikea.mtc.mfs.sales.configattr.PREFERRED_CONTACT_METHOD_FOR_NOTIFICATION_PICKING_READY_FOR_HANDOUT",
	ENABLE_WEBVIEWS_LOGGING_TO_ISELL: "ikea.mtc.mfs.common.configattr.ENABLE_WEBVIEWS_LOGGING_TO_ISELL",
	ENABLE_SEARCH_BY_ZIPCODE: "ikea.mtc.mfs.cust.configattr.ENABLE_SEARCH_BY_ZIPCODE"
};

export const localeKey = window.location.origin + "selectedLocale";
export const tokenKey = window.location.origin + "newapptoken";
export const authconfigKey = window.location.origin + "authconfigKey";
export const tokenResponseKey = window.location.origin + "tokenResponseKey";
export const overidedStoreKey = window.location.origin + "overidedStore";

export const StorageKeys = {
	localeKey,
	tokenKey,
	authconfigKey,
	tokenResponseKey,
	overidedStoreKey
};

export const AuthorizationPreference = {
	key: DfpKey.WEBSALJA_ALLOWED_USERS,
	name: DfpKey.DFP_PRODUCTNAME
};
export const configPreferrences = [
	{
		key: DfpKey.ADDRESS_SUGGESTION,
		name: DfpKey.DFP_PRODUCTNAME
	},
	{
		key: DfpKey.ENABLE_ICMPROFILE_CREATE_UPDATE,
		name: DfpKey.DFP_PRODUCTNAME
	},
	{
		key: DfpKey.STORE_PICKING_PRICE,
		name: DfpKey.DFP_PRODUCTNAME
	},
	{
		key: DfpKey.FAQ_SECTION_LINKS,
		name: DfpKey.DFP_PRODUCTNAME
	},
	{
		key: DfpKey.SUPERUSER_MAIL,
		name: DfpKey.DFP_PRODUCTNAME
	},
	{
		key: DfpKey.PROVIDED_METHOD_WHITELIST,
		name: DfpKey.DFP_PRODUCTNAME
	},
	{
		key: DfpKey.DELIVERY_METHOD_WHITELIST,
		name: DfpKey.DFP_PRODUCTNAME
	},
	{
		key: DfpKey.SERVICE_COST_BASED_ON_ORIGINAL_PRICE,
		name: "iSell"
	},
	{
		key: DfpKey.SHOW_CUSTOMER_APPROVAL_CHECKBOX,
		name: "iSell"
	},
	{
		key: DfpKey.PREFFERD_CONTACT_METHOD_FOR_NOTIFICATION_FOR_SURVEY,
		name: "iSell"
	},
	{
		key: DfpKey.TAX_ID_FORMAT,
		name: "iSell"
	},
	{
		key: DfpKey.TAX_EXCLUDE_VALIDATION_ID,
		name: "iSell"
	},
	{
		key: DfpKey.PREFFERED_ORDER_MANAGEMENT_INTEGRATION_POINT,
		name: "iSell"
	},
	{
		key: DfpKey.ISOM_ALLOWED_USERS,
		name: DfpKey.DFP_PRODUCTNAME
	},
	{
		key: DfpKey.PRIMARY_CURRENCY,
		name: "iSell"
	},
	{
		key: DfpKey.ICM_PROFILE_ENABLED,
		name: "iSell"
	},
	{
		key: DfpKey.MANDATORY_CONTACT_METHOD_TYPE_ROLE_BUSINESS,
		name: "iSell"
	},
	{
		key: DfpKey.MANDATORY_CONTACT_METHOD_TYPE_ROLE_PRIVATE,
		name: "iSell"
	},

	{
		key: DfpKey.TRANSPORT_DESK_PRINTER_NAME,
		name: DfpKey.DFP_PRODUCTNAME
	},
	{
		key: DfpKey.DEFAULT_PREFERRED_WAY_OF_COMMUNICATION,
		name: "iSell"
	},
	{
		key: DfpKey.ICM_EXCHANGE_BETWEEN_PREFERRED_WAY_OF_COMMUNICATION_BUSINESSCUST_BILLING,
		name: "iSell"
	},
	{
		key: DfpKey.ICM_EXCHANGE_BETWEEN_PREFERRED_WAY_OF_COMMUNICATION_BUSINESSCUST_DELIVERY,
		name: "iSell"
	},
	{
		key: DfpKey.ICM_EXCHANGE_BETWEEN_PREFERRED_WAY_OF_COMMUNICATION_PRIVATECUST_BILLING,
		name: "iSell"
	},
	{
		key: DfpKey.ICM_EXCHANGE_BETWEEN_PREFERRED_WAY_OF_COMMUNICATION_PRIVATECUST_DELIVERY,
		name: "iSell"
	},
	{
		key: DfpKey.ANNOUNCEMENT_FOR_USERS,
		name: DfpKey.DFP_PRODUCTNAME
	},
	{
		key: DfpKey.PREFERRED_CONTACT_METHOD_FOR_NOTIFICATION_PICKING_READY_FOR_HANDOUT,
		name: "iSell"
	},
	{
		key: DfpKey.ENABLE_SEARCH_BY_ZIPCODE,
		name: "iSell"
	}
];
