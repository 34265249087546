import { useSelector } from "react-redux";
import React from "react";
import Lightbox from "@ingka/lightbox";
import Popup from "@ingka/popup";
const ConfirmDialog = ({ onSubmit, onClose, title, text, showOnlyYes = false }) => {
	const { messages } = useSelector(state => state.intl);
	function getModelButtons() {
		if (showOnlyYes) {
			return [
				{
					text: messages.SG_BTN_Yes,
					type: "primary",
					onClick: () => {
						onSubmit(true);
					}
				}
			];
		}
		return [
			{
				text: messages.SG_BTN_Yes,
				type: "primary",
				onClick: () => {
					onSubmit(true);
				}
			},
			{
				text: messages.SG_BTN_No,
				type: "secondary",
				onClick: () => {
					onSubmit(false);
				}
			}
		];
	}
	const settings = {
		title,
		text,
		popupBtns: getModelButtons()
	};
	return (
		<Lightbox
			focusLockProps={focus}
			visible
			modalType="alert"
			light={"false"}
			handleCloseBtn={() => {
				onClose();
			}}
		>
			<Popup {...settings} />
		</Lightbox>
	);
};

export default ConfirmDialog;
const focus = {
	returnFocus: false,
	autoFocus: true
};
