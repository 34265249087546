import { push, goBack } from "connected-react-router";
import { useDispatch } from "react-redux";
import React from "react";

import { clearCart } from "../../store/cart/cartActions";
import { getStoreConfigSelector } from "../../utils/shellObjectService";
import useMicrofrontend from "../../hooks/useMicroFrontend";

const MircoOrderConfirmation = props => {
	const dispatch = useDispatch();
	const handleCartClearGobacktoHome = () => {
		dispatch(push("/"));
		dispatch(clearCart());
	};

	const pageGoback = () => {
		dispatch(goBack());
	};
	let exitToHome = props.location.state && props.location.state.exitToHome;
	let customer = props.location.state && props.location.state.customer;
	let empuItems = props.location.state && props.location.state.empuItems;
	let config = getStoreConfigSelector();
	let { appId, appUrl, appName } = config.environment.microapps.recentOrderApp;

	const { isLoaded, recentorder } = useMicrofrontend(appId, appUrl, appName);

	React.useEffect(() => {
		if (!recentorder) return;
		config.view = "confirmation";
		config.exitToHome = exitToHome === true ? true : false;
		config.orderNo = props.match.params.orderNo;
		config.customer = customer;
		config.empuItems = empuItems;
		config.type = props.match.params.type;
		recentorder.SetSharedConfiguration(config);
		const { render, unMount, subscribe, unSubscribe } = recentorder;
		render(appId, config);
		subscribe("CLEAR_CART_NAVIGATE_HOME", handleCartClearGobacktoHome);

		subscribe("HOST_GOBACK", pageGoback);
		return () => {
			unMount(appId);
			unSubscribe("CLEAR_CART_NAVIGATE_HOME", handleCartClearGobacktoHome);
			unSubscribe("HOST_GOBACK", pageGoback);
		};
	}, [isLoaded]); // eslint-disable-line

	if (!isLoaded) return null;
	if (!recentorder) return <div />;
	return <div id={appId} />;
};
export default MircoOrderConfirmation;
