import React from "react";
import styled from "styled-components";
import posed, { PoseGroup } from "react-pose";
import inputTypes from "./types";
import { HideBodyScrollStyle } from "../../globalStyle";
const InputPopupContext = React.createContext(null);
const useInputPopup = () => {
	const [config, setConfig] = React.useContext(InputPopupContext);

	const getInput = React.useCallback(
		(type, conf = {}) => {
			let resolver = null;

			const resolve = value => {
				setConfig(null);
				if (resolver) {
					resolver(value);
				}
			};
			const cancel = () => {
				setConfig(null);
				if (resolver) {
					resolver();
				}
			};

			setConfig({ type, ...conf, resolve, cancel, test: "test" });
			return new Promise(resolve => {
				resolver = resolve;
			});
		},
		[setConfig]
	);
	return { getInput, popupOpen: !!config };
};
const Container = styled.div`
	display: flex;
	flex-direction: column;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: 105;
	padding-bottom: calc(env(safe-area-inset-bottom));
	padding-top: calc(env(safe-area-inset-top));
`;

const PosedContainer = posed(Container)({
	enter: {
		y: 0,
		transition: {
			ease: "easeIn",
			duration: 300
		}
	},
	exit: {
		y: "100%",
		transition: {
			ease: "easeOut",
			duration: 300
		}
	}
});

const Content = styled.div`
	flex-grow: 1;
	z-index: 103 !important;
	overflow-x: hidden;
`;

const InputPopupAnimationWrapper = ({ children, animation, active }) => {
	if (animation) {
		return (
			<PoseGroup animateOnMount>
				{active && (
					<PosedContainer className={"inputpopup"} key="container">
						{children}
					</PosedContainer>
				)}
			</PoseGroup>
		);
	} else {
		return (active && <Container className={"inputpopup"}>{children}</Container>) || null;
	}
};
const InputPopup = () => {
	const [input] = React.useContext(InputPopupContext);
	const { type = "", cancel, resolve, ...config } = input || {};
	const popupConf = inputTypes[type] || {};
	const Component = popupConf.component;

	return (
		<InputPopupAnimationWrapper animation={false} active={!!input}>
			{input && (
				<React.Fragment>
					<Content showScroll={config.showScroll} className={"inputpopup"}>
						<HideBodyScrollStyle />
						<Component {...config} onSubmit={resolve} onClose={cancel} />
					</Content>
				</React.Fragment>
			)}
		</InputPopupAnimationWrapper>
	);
};

const InputPopupProvider = ({ children }) => {
	const [input, setInput] = React.useState(null);

	return <InputPopupContext.Provider value={[input, setInput]}>{children}</InputPopupContext.Provider>;
};

export { useInputPopup, InputPopup, InputPopupProvider };
