import { SalesItemTypes } from "@coworker/sharedlibrary";
import Enumerable from "linq";

const { ART } = SalesItemTypes;
const FAMILY_TYPE = "Family";
const isomItemsBuilder = {
	createItems: (cartItems, isFamily = false, priceViewType = "Private") => {
		let isomitems = [];

		cartItems.forEach(itm => {
			if (itm.isCombination) {
				itm.childItems.forEach(citm => {
					isomitems.push(citm);
				});
			} else {
				isomitems.push(itm);
			}
		});

		let items = [];
		let sorteditems = Enumerable.from(isomitems)
			.orderBy(x => x.isProduct)
			.toArray();
		let lineId = 1;
		sorteditems.forEach(cartItem => {
			let result = buildItems(null, cartItem, lineId, isFamily, priceViewType);
			result.items.forEach(itm => {
				items.push(itm);
			});
			lineId = result.lineId;
		});
		return items;
	}
};

const buildItems = (groupParent, cartItem, lineId, isfamily, priceViewType) => {
	let items = [];
	if (cartItem.retailItem.ItemType === ART) {
		let articleItem = createItem(cartItem, lineId, isfamily, priceViewType);
		if (groupParent !== null) {
			articleItem.orderedQuantity = articleItem.orderedQuantity * groupParent.quantity;
		}
		items.push(articleItem);
		lineId = lineId + 1;
	} else {
		let subItemList = [];

		let sprItem = createItem(cartItem, lineId, isfamily, priceViewType);
		if (groupParent !== null) {
			sprItem.orderedQuantity = sprItem.orderedQuantity * groupParent.quantity;
		}
		lineId = lineId + 1;
		cartItem.childItems.forEach(subItem => {
			let childItem = createItem(subItem, lineId, isfamily, priceViewType);
			childItem.orderedQuantity = childItem.orderedQuantity * sprItem.orderedQuantity;
			subItemList.push(childItem);
			lineId = lineId + 1;
		});
		let itemReferences = [];
		subItemList.forEach(x => {
			let refitem = {
				lineId: x.id,
				quantity: x.orderedQuantity
			};
			itemReferences.push(refitem);
		});
		sprItem.itemReferences = itemReferences;

		items.push(sprItem);
		items = items.concat(subItemList);
	}
	return { items, lineId };
};

const createItem = (shoppingCartItem, lineId, isFamily, priceViewType) => {
	let type = isFamily ? "IKEAFamilySalesUnitPrice" : "RegularSalesUnitPrice";
	let item = shoppingCartItem.retailItem;
	let itemPriceMoney = shoppingCartItem.price;
	let unitPrice = (shoppingCartItem.unitPrice && shoppingCartItem.unitPrice.find(x => x.type === type)) || {};
	let subTotal = {};
	let imageTypesList = (item.Images && item.Images[0]) || [];
	let images = [];
	Object.entries(imageTypesList).forEach(([key, value]) => {
		images.push({
			type: key,
			url: value.Url
		});
	});
	if (shoppingCartItem.subtotals) {
		let viewType = priceViewType;
		subTotal = shoppingCartItem.subtotals.prices.find(x => (x.viewType === isFamily ? FAMILY_TYPE : viewType));
	}
	if (isFamily && shoppingCartItem.familyPrice && shoppingCartItem.familyPrice.Value) {
		itemPriceMoney = shoppingCartItem.familyPrice;
	}
	return {
		itemType: item.ItemType,
		itemNo: shoppingCartItem.itemId,
		itemName: item.Name,
		id: shoppingCartItem.id,
		orderedQuantity: shoppingCartItem.quantity,
		unitSalesPrice: {
			amount: itemPriceMoney.Value,
			currencyCode: itemPriceMoney.Currency
		},

		refId: shoppingCartItem.id,
		unitPrice: unitPrice,
		subtotals: {
			prices: subTotal
		},
		itemDescription: item.itemDescription,
		itemImages: images,
		itemUnitPrice: getUnitPrice(shoppingCartItem, isFamily),
		itemPriceDetails: getitemPriceDetails(shoppingCartItem, isFamily, priceViewType),
		importDetails: shoppingCartItem.importDetails,
		handInLocation: shoppingCartItem.handOverLocation
	};
};

const getUnitPrice = (shoppingCartItem, isFamily) => {
	let unitPrices = {
		familyPrice: undefined,
		salesPrice: undefined,
		listPrice: undefined
	};
	let { unitPrice } = shoppingCartItem;

	if (!unitPrice) {
		return undefined;
	}
	let hasFamilyPrice = false;
	let unitItemPrice = unitPrice.find(x => x.type === "RegularSalesUnitPrice");
	if (isFamily) {
		let unitFamilyItemPrice = unitPrice.find(x => x.type === "IKEAFamilySalesUnitPrice");
		if (unitFamilyItemPrice) {
			hasFamilyPrice = true;
			unitItemPrice = unitFamilyItemPrice;
		}
	}
	if (unitItemPrice) {
		let { currentPrice, discountedPrice } = unitItemPrice;
		if (isFamily && hasFamilyPrice) {
			unitPrices.familyPrice = {
				...discountedPrice,
				taxAmount: discountedPrice.indicativeTax,
				currencyCode: shoppingCartItem.price.Currency,
				taxDetails: getTaxDetails(shoppingCartItem, discountedPrice)
			};
		}
		unitPrices.listPrice = {
			...currentPrice,
			taxAmount: currentPrice.indicativeTax,
			currencyCode: shoppingCartItem.price.Currency,
			taxDetails: getTaxDetails(shoppingCartItem, currentPrice)
		};
		if (isFamily && hasFamilyPrice) {
			unitPrices.salesPrice = {
				...discountedPrice,
				taxAmount: discountedPrice.indicativeTax,
				currencyCode: shoppingCartItem.price.Currency,
				taxDetails: getTaxDetails(shoppingCartItem, discountedPrice)
			};
		} else {
			unitPrices.salesPrice = {
				...currentPrice,
				taxAmount: currentPrice.indicativeTax,
				currencyCode: shoppingCartItem.price.Currency,
				taxDetails: getTaxDetails(shoppingCartItem, currentPrice)
			};
		}
	}
	return unitPrices;
};

const getitemPriceDetails = (shoppingCartItem, isFamily, priceViewType) => {
	let itemPriceDetails = {};
	let viewType = priceViewType;
	let type = isFamily ? FAMILY_TYPE : viewType;
	if (shoppingCartItem.subtotals) {
		itemPriceDetails = shoppingCartItem.subtotals.prices.find(x => x.viewType === type);
		if (!itemPriceDetails) {
			itemPriceDetails = shoppingCartItem.subtotals.prices.find(x => x.viewType === viewType);
		}
		if (itemPriceDetails.savings) {
			itemPriceDetails.savings = itemPriceDetails.savings.map(x => {
				return {
					...x,
					code: x.code || x.id,
					id: x.id || x.code
				};
			});
		}
		return itemPriceDetails;
	}
	return undefined;
};
const getTaxDetails = (shoppingCartItem, unitPrice) => {
	if (shoppingCartItem.receiptPriceDetails) {
		let { taxDetails } = shoppingCartItem.receiptPriceDetails;

		return taxDetails.map(tax => {
			return {
				taxCode: tax.taxCode,
				taxType: tax.taxType,
				taxPercent: tax.taxPercent,
				taxableAmount: unitPrice.priceExclTax * shoppingCartItem.quantity,
				taxAmount: unitPrice.indicativeTax * shoppingCartItem.quantity,
				taxPercentage: tax.taxPercent
			};
		});
	}
};

export default isomItemsBuilder;
