import { createGlobalStyle } from "styled-components";

import loading from "../static/loading.svg";

export const GlobalStyle = createGlobalStyle`

  body ,html, #root {
    background-color: #fff;
    width: 100%;
    height: 100%;
    min-height: 100%;
    font-family: 'NotoIKEALatin';
    overscroll-behavior-y: contain;
     margin: 0px;
  }

  * {
    -webkit-tap-highlight-color: transparent;
  }

  a  {
  text-decoration: none;
    user-select: none;
    overscroll-behavior-y: contain;
  }

  img {
    background-image: url(${loading});
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    background-size: 50%;
  }

  .btn--outlined, .btn--transactional, .btn--filled, .btn--text, .btn--danger {
    border-radius: 28px !important;
    :focus,:hover,:active {border-radius: 28px !important;}
  }

  .tabs__tab {
    color: #111111;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
  }

  .tabs__tab--active {
    color: #111111;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
  }
`;

export const HideBodyScrollStyle = createGlobalStyle`
  body {
    overflow-y:hidden !important;
  }
`;
