import React from "react";
import Button from "@ingka/button";
import { Dropdown, DropdownItem } from "@ingka/dropdown";
import Modal, { Prompt, ModalFooter } from "@ingka/modal";
import { useSelector } from "react-redux";

const OrderReasonDialog = ({ reasonKey, title, reasonTitle, onClose, onSubmit }) => {
	const [reasonCode, setReasonCode] = React.useState("CUSCHMIND");
	const { messages } = useSelector(state => state.intl);
	const { preferences } = useSelector(state => state.appController);
	function getModelButtons() {
		return [
			{
				text: messages.SG_BTN_OK || "OK",
				type: "primary",
				onClick: () => {
					onSubmit(reasonCode);
				}
			},
			{
				text: messages.SG_BTN_Cancel,
				type: "secondary",
				onClick: () => {
					onClose();
				}
			}
		];
	}
	function getselectList() {
		const reasonCodesOrderPref = preferences && preferences.find(x => x && x.name === reasonKey);
		if (reasonCodesOrderPref && reasonCodesOrderPref !== null && reasonCodesOrderPref.value) {
			return reasonCodesOrderPref.value.map(x => ({
				key: x,
				value: getCodeMessageValue(x)
			}));
		}
		return [
			{
				key: "CUSCHMIND",
				value: getCodeMessageValue("CUSCHMIND")
			}
		];
	}

	function handleOnChange(e) {
		setReasonCode(e.target.value);
	}

	//SALJA-1904 - for all codes
	function getCodeMessageValue(code) {
		switch (code) {
			case "PLANNOTDEL":
				return messages.SG_LBL_RC_AutomaticCancelOfPlannedNotDeliveredOrder || "Automatic cancel of planned not delivered order";
			case "DELAYBYIK":
				return messages.SG_LBL_RC_DelayCausedByIkea || "Delay caused by IKEA";
			case "CUSCHMIND":
				return messages.SG_LBL_RC_RequestedByTheCustomer || "Requested by the customer";
			case "REDBUTTON":
				return messages.SG_LBL_RC_RedButton || "Red button";
			case "PIANOTFUL":
				return messages.SG_LBL_RC_PaymentInAdvanceNotFullyPaid || "Payment in advance not fully paid";
			case "PIANOTPAID":
				return messages.SG_LBL_RC_PaymentInAdvanceNotPaid || "Payment in advance not paid";
			case "FREETEXT":
				return messages.SG_LBL_RC_FreeText || "Free text";
			case "CUST_BOUGHT_AT_STORE":
				return messages.SG_LBL_RC_CustomerBoughtAtStore || "Customer bought at store";
			case "DELAY":
				return messages.SG_LBL_RC_Delay || "Delay";
			case "FAULTY_GOODS_BY_DEL":
				return messages.SG_LBL_RC_FaultyGoodsByDelivery || "Faulty goods by delivery";
			case "TECHMALFUNC":
				return messages.SG_LBL_RC_TechnicalProblems || "Technical problems";
			case "STAT_47_NO_CUST_ANSWER":
				return messages.SG_LBL_RC_Status47 || "Status 47, no customer answer";
			case "SALESERR":
				return messages.SG_LBL_RC_SalesError || "Sales error";
			case "SAC_IN_SAMS":
				return messages.SG_LBL_RC_SacInSams || "SAC in SAMS";
			case "DOUBLE_ORDER":
				return messages.SG_LBL_RC_DoubleOrderIsBooked || "Double order is booked";
			case "ARTICLES_MISSING":
				return messages.SG_LBL_RC_ArticlesMissing || "Articles missing";
			case "DELTSP":
				return messages.SG_LBL_RC_DELTSP || "DELTSP";
			case "SP_NO_CAPACITY":
				return messages.SG_LBL_RC_SPNoCapacity || "SP No Capacity";

			case "SALESSTOP":
				return messages.SG_LBL_RC_Salestop || "Sales stop";
		}
		return code;
	}

	return (
		<Modal visible={true} escapable={true} handleCloseBtn={() => onClose()} focusLockProps={focus}>
			<Prompt
				title={title}
				footer={
					<ModalFooter compact={false}>
						{getModelButtons().map(button => {
							return <Button key="1" {...button}></Button>;
						})}
					</ModalFooter>
				}
			>
				<Dropdown defaultValue={"CUSCHMIND"} data-testid={"reasonCodenselect"} id={"reasonCode"} label={reasonTitle} onChange={e => handleOnChange(e)}>
					{getselectList().map(item => (
						<DropdownItem key={item.key} value={item.key} name={item.value} />
					))}
				</Dropdown>
			</Prompt>
		</Modal>
	);
};

export default OrderReasonDialog;
const focus = {
	returnFocus: false,
	autoFocus: true
};
