import React from "react";
import CustomerView from "./../../microfrontends/customerView";
import { useSelector } from "react-redux";
import { useWindowEventListener } from "@coworker/sharedlibrary";

import Modal, { Sheets, ModalBody, ModalHeader } from "@ingka/modal";

const focus = {
	returnFocus: false,
	autoFocus: true
};

const PreSelectCustomerDialog = ({ onClose }) => {
	const { messages } = useSelector(state => state.intl);
	const { storeInfo } = useSelector(state => state.appController);

	useWindowEventListener("ON_SAVE_CUSTOMER", () => {
		onClose && onClose();
	});

	return (
		<Modal
			visible
			focusLockProps={focus}
			handleCloseBtn={() => {
				onClose();
			}}
			escapable={false}
		>
			<Sheets header={<ModalHeader title={messages.SG_LBL_CustomerSearchTitle || "Find customer or address"} floating={false} />}>
				<ModalBody>
					<CustomerView view="AddressSelection" elementId="addressSelection" messages={messages} storeInfo={storeInfo} />
				</ModalBody>
			</Sheets>
		</Modal>
	);
};

export default PreSelectCustomerDialog;
