import { DfpKey, OrderTypes, httpService } from "@coworker/sharedlibrary";
import Button from "@ingka/button";
import { push } from "connected-react-router";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { getCustomerProfileObject } from "./../../../utils/shellObjectService";
import { useHotKeys } from "../../../hooks/useHotKeys";
import useMicrofrontend from "../../../hooks/useMicroFrontend";
import { useCanUpdateQuotation, useQuotation } from "../../../hooks/useQuotation";
import { useSalesPerson } from "../../../hooks/useSalesPerson";
import AppLogger from "../../../logger/appLogger";
import checkOutService from "../../../service/cart/checkOutService";
import OrderRetreivalService from "../../../service/orderRetrievalService";
import { clearExternalReference, updateCreditInfo } from "../../../store/cart/cart.thunks";
import { clearCart, updateCartTotal, updateQuotation } from "../../../store/cart/cartActions";
import SalesCoworkerConfiguration from "../../../store/salesCoworkerConfiguration";
import { appactionCreators } from "../../../store/shell/shellAction";
import { getStoreConfigSelector } from "../../../utils/shellObjectService";
import { useQuotationPopup } from "../../quotation";
import { useCartModelPopup } from "./modelPopup";

const OrderButtonsSection = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	.btn {
		margin-bottom: 24px;
		border-radius: 25px;
		width: 100%;
	}
`;
const GuestMode = styled.h6`
	margin-top: 10px;
	margin-bottom: 0px;
	color: red;
	font-size: 16px;
	text-align: center;
`;
const CreateOrderCheckoutOption = ({ disabled, canCreateSalesOrder, onlyService }) => {
	const { order, isQuotation, bookedServiceProposal, items } = useSelector(state => state.cartController);
	const [salesMode] = useSalesPerson();
	let guestMode = !salesMode;
	const isRestrictedInMarket = SalesCoworkerConfiguration.isRestrictedInMarket();
	let { messages } = useSelector(state => state.intl);
	let { buCode, countryCode, environment, preferences = [] } = getStoreConfigSelector();

	let { appId, appUrl, appName } = environment.microapps.recentOrderApp;

	const { recentorder } = useMicrofrontend(appId, appUrl, appName);

	const dispatch = useDispatch();
	const quotationAllowed = useQuotation();
	const updateQuoteNeeded = useCanUpdateQuotation();
	const { getQuotationModel } = useQuotationPopup();
	const { getCartModel } = useCartModelPopup();

	const { kitchen, cartId, isTransportDeskMode, hideSaveShoppingList, hasServices } = useSelector(state => state.cartController);

	useHotKeys(["cntrl+l", "cntrl+s", "F9"], e => {
		//save shopping list
		if (e.keys === "cntrl+l" && (!guestMode || !onlyService || !disabled)) {
			createShoppingList();
		}
		//save order
		if ((e.keys === "cntrl+s" || e.keys === "F9") && (canCreateSalesOrder || !guestMode || !disabled)) {
			createOrder(OrderTypes.CCorder);
		}
	});
	function showBusyLoading(loading) {
		dispatch(appactionCreators.showBusyLoading(loading));
	}
	function showNotification(level, message, detail) {
		dispatch(appactionCreators.showNotification(level, message, detail));
	}
	async function createOrder(type) {
		let pickupNotification;

		let notifyDialog = preferences.find(x => x.name === DfpKey.NOTIFICATION_PICKING_READY_FOR_HANDOUT_ENABLED);

		if (type !== OrderTypes.ShoppingList && notifyDialog && isLcdFullServePickup()) {
			const { customer } = getCustomerProfileObject();
			pickupNotification = await getCartModel("orderPickupNotifyDialog", {
				customer
			});

			if (!pickupNotification) return;
		}

		showBusyLoading(true);
		dispatch(updateCartTotal(isTransportDeskMode)).then(() => {
			checkOutService
				.createOrder(type, pickupNotification)
				.then(order => {
					showBusyLoading(false);
					recentorder && recentorder.removeDraftOrder(cartId);
					dispatch(clearExternalReference());
					openOrderConfirmationView(order);
					sendToIHPInteraction(order);
					dispatch(clearCart());
				})
				.catch(error => {
					if (error.paymentTransactions) {
						dispatch(updateCreditInfo(error.paymentTransactions));
					}
					showBusyLoading(false);
					showNotification("error", messages.SG_LBL_FailedToCreateSalesOrder || "failed to create sales order", error.message);
				});
		});
	}
	//send details to ihp if order contains kitchen
	function sendToIHPInteraction(order) {
		try {
			if (kitchen) {
				const config = SalesCoworkerConfiguration.getUserConfig();
				let orderRetrieval = new OrderRetreivalService();
				orderRetrieval.getOrder(order.orderNo, false, false).then(response => {
					if (kitchen && response && response.hasService) {
						AppLogger.info("send interaction to ihp for kitchen order", "SendToIHPInteraction", {
							orderNo: order.orderNo,
							buCode: buCode,
							countryCode: countryCode,
							drawingId: kitchen.serialNumber
						});
						httpService.post("/api/planner/kitchenPlanner/interaction/send", {
							body: {
								orderNo: order.orderNo,
								buCode: buCode,
								countryCode: countryCode,
								drawingId: kitchen.id
							},
							headers: config.headers
						});
					}
				});
			}
		} catch (error) {
			AppLogger.error("failed to send to ihp interaction", "IHP_INTERACTION_ERROR", {
				errorMessage: error.message
			});
		}
	}

	async function showBagDialog() {
		await getCartModel("shoppingListDialog", {
			saveShoppingList: () => createOrder(OrderTypes.ShoppingList)
		});
	}

	function createShoppingList() {
		if (hasServices) {
			showBagDialog();
		} else {
			createOrder(OrderTypes.ShoppingList);
		}
	}

	function cancelQuotation() {
		showBusyLoading(true);
		checkOutService
			.cancelQuotation()
			.then(() => {
				showBusyLoading(false);
				dispatch(clearCart());
			})
			.catch(error => {
				showBusyLoading(false);
				AppLogger.error(`Cancel Quotation failed:${error.message}`, "CancelQuotation");
				showNotification("error", messages.SG_LBL_FailedToCancelQuotation || "failed to cancel quotation");
			});
	}

	async function showQuotationView(quoteDays) {
		var result = await getQuotationModel("quotationDialog", {
			messages: messages,
			quoteDays: quoteDays
		});
		if (result) {
			if (result.type === OrderTypes.SHLIST) {
				createShoppingList();
			} else if (result.type === OrderTypes.Quotation) {
				createQuotation(result.quotation);
			}
		}
	}

	function createQuotation(quotation) {
		showBusyLoading(true);
		checkOutService
			.createQuotation(quotation)
			.then(response => {
				var quotation = response.quotations.length > 0 && response.quotations[0];
				return {
					...quotation,
					quotationRefNo: quotation.quotationReferenceNumber
				};
			})
			.then(quot => {
				showBusyLoading(false);
				dispatch(clearCart());
				recentorder && recentorder.removeDraftOrder(cartId);
				openOrderConfirmationView(quot, true);
			})
			.catch(() => {
				showBusyLoading(false);
				showNotification("error", "failed to create sales order");
			});
	}

	function updateQuote() {
		showBusyLoading(true);
		checkOutService
			.updateQuotation()
			.then(response => {
				showBusyLoading(false);
				if (response) {
					dispatch(updateQuotation(response));
				}
			})
			.catch(() => {
				showBusyLoading(false);
				showNotification("error", "failed to updateQuotation");
			});
	}

	async function openOrderConfirmationView(order, isQuotion) {
		if (isQuotion) {
			dispatch(
				push(`/confirmation/quotation/${order.orderNo}`, {
					exitToHome: true,
					customer: order.customer
				})
			);
		} else {
			dispatch(
				push(`/confirmation/order/${order.orderNo}`, {
					exitToHome: true,
					customer: order.customer,
					empuItems: order.empuItems
				})
			);
		}
	}

	function isLcdFullServePickup() {
		if (bookedServiceProposal) {
			let { bookedServices = [], hasServices = false } = bookedServiceProposal || {};
			let hasPickupService = bookedServices.find(x => !x.isDelivery && x.isPickingService === true) !== undefined;
			let hasFullServe =
				items && items.find(x => x.retailItem && x.retailItem.storeInfo && x.retailItem.storeInfo.SalesMethodCode === "FULL_SERVE") != undefined;
			if ((hasPickupService || !hasServices) && hasFullServe) {
				return true;
			}
		}

		return false;
	}

	if (isRestrictedInMarket) {
		return <GuestMode>{messages.SG_LBL_Restricted_Mode || "You are not allowed to create order in this market. Please check with super user"}</GuestMode>;
	}
	return (
		<>
			{guestMode && <GuestMode>{messages.SG_LBL_Guest || "You are in guest mode"}</GuestMode>}

			<OrderButtonsSection className="mrg-top-25">
				{updateQuoteNeeded ? (
					<Button
						disabled={guestMode || disabled}
						type="secondary"
						text={messages.SG_LBL_UpdateQuotation || "Update quotation"}
						onClick={() => updateQuote()}
					/>
				) : (
					<Button
						data-testid="ccorderbtn"
						disabled={!canCreateSalesOrder || guestMode || disabled}
						type="emphasised"
						text={messages.SG_BTN_CreateOrder || "Create order"}
						onClick={() => createOrder(OrderTypes.CCorder)}
					/>
				)}

				{isQuotation && order ? (
					<Button
						disabled={guestMode || disabled}
						type="secondary"
						text={messages.SG_BTN_CancelQuotation || "Cancel quotation"}
						onClick={() => cancelQuotation()}
					/>
				) : (
					<>
						{!isTransportDeskMode && (
							<>
								{quotationAllowed ? (
									<Button
										disabled={guestMode || disabled}
										type="secondary"
										text={messages.SG_BTN_Save || "Save"}
										onClick={() => showQuotationView()}
									/>
								) : (
									<>
										{!hideSaveShoppingList && (
											<Button
												data-testid="shoppinglistorderbtn"
												disabled={guestMode || onlyService || disabled}
												type="secondary"
												text={messages.SG_BTN_Save || "Save"}
												onClick={() => createShoppingList()}
											/>
										)}
									</>
								)}
							</>
						)}
					</>
				)}
			</OrderButtonsSection>
		</>
	);
};
export default CreateOrderCheckoutOption;
