import { SalesItemTypes } from "@coworker/sharedlibrary";
const proposalItemsBuilder = {
	createItems: cartItems => {
		let items = [];
		cartItems.forEach(cartItem => {
			if (cartItem.isCombination) {
				cartItem.childItems.forEach(subItem => {
					let bitems = buildItems(cartItem, subItem);

					bitems.forEach(itm => {
						items.push(itm);
					});
				});
			} else {
				let aItems = buildItems(null, cartItem);
				aItems.forEach(itm => {
					items.push(itm);
				});
			}
		});
		return items;
	}
};
export default proposalItemsBuilder;

const buildItems = (groupParent, cartItem) => {
	let items = [];
	if (cartItem.retailItem.ItemType === SalesItemTypes.ART) {
		let articleItem = createItem(cartItem);
		if (groupParent !== null) {
			articleItem.quantity = articleItem.quantity * groupParent.quantity;
		}
		items.push(articleItem);
	} else {
		let subItemList = [];

		let sprItem = createItem(cartItem);
		if (groupParent !== null) {
			sprItem.quantity = sprItem.quantity * groupParent.quantity;
		}

		cartItem.childItems.forEach(subItem => {
			let childItem = createItem(subItem);
			childItem.quantity = childItem.quantity * sprItem.quantity;
			subItemList.push(childItem);
		});
		let itemReferences = [];
		subItemList.forEach(x => {
			let refitem = {
				lineId: x.id
			};
			itemReferences.push(refitem);
		});
		sprItem.itemReferences = itemReferences;
		items = items.concat(subItemList);

		items.push(sprItem);
	}
	return items;
};

const createItem = shoppingCartItem => {
	let item = shoppingCartItem.retailItem;

	return {
		itemType: item.ItemType,
		itemNo: shoppingCartItem.itemId,
		itemName: item.Name,
		id: shoppingCartItem.id,
		nbrOfParcels: item.NumberOfPackages,
		quantity: shoppingCartItem.quantity,
		itemUnitCode: item.ItemUnitCode === "PIECES" ? "Piece" : "Meter",
		refId: shoppingCartItem.id,
		itemReferences: null
	};
};
