import React from "react";

import { getStoreConfigSelector } from "../../utils/shellObjectService";
import useMicrofrontend from "../../hooks/useMicroFrontend";

const ItemAvailabilityView = props => {
	let appConfig = getStoreConfigSelector();
	let config = { ...appConfig };
	let { appId, appUrl, appName } = config.environment.microapps && config.environment.microapps.pipApp;

	const { isLoaded, pip } = useMicrofrontend(appId, appUrl, appName);

	React.useEffect(() => {
		if (!pip) return;
		config.view = "locationandforecast";

		config.retailItem = props.retailItem;
		config.isStock = props.isStock;
		pip.SetSharedConfiguration(config);
		const { render, unMount } = pip;

		render(`locationandforecast-${appId}`, config);

		return () => {
			unMount(`locationandforecast-${appId}`);
		};
	}, [isLoaded, props.location]); // eslint-disable-line

	if (!isLoaded) return null;
	if (!pip) return <div />;

	return <div id={`locationandforecast-${appId}`} />;
};

export default ItemAvailabilityView;
