import { setSearch } from "./../../store/import/actions";
import { useSelector, useDispatch } from "react-redux";
import React from "react";

import { getStoreConfigSelector } from "../../utils/shellObjectService";
import useMicrofrontend from "../../hooks/useMicroFrontend";
const MicroCombinationView = props => {
	const dispatch = useDispatch();
	let appConfig = getStoreConfigSelector();
	let config = { ...appConfig };
	let { items, drawings } = useSelector(state => state.cartController);

	let { appId, appUrl, appName } = config.environment.microapps.pipApp;

	const { isLoaded, pip } = useMicrofrontend(appId, appUrl, appName);

	const setImportData = e => {
		dispatch(setSearch(e.detail.importData));
	};

	//refresh items
	React.useEffect(() => {
		pip && pip.SetSharedConfiguration(config);
		pip && pip.setCartItems(items);
		pip && pip.setCartDrawings(drawings);
	}, [items, drawings]);

	function getView(pathName) {
		if (pathName.includes("/kiosk/list") || pathName.includes("/web/list")) {
			return "webshoppinglistview";
		}
		if (pathName.includes("kitchenplanner")) {
			return "kitchenview";
		}
		if (pathName.includes("findreceipt")) {
			return "findreceipt";
		}
		if (pathName.includes("receipt")) {
			return "receipt";
		}

		if (pathName.includes("planner") || pathName.includes("ptag")) {
			return "combination";
		}
	}

	config.view = getView(props.location.pathname);
	let elementId = `${config.view}-${appId}`;
	React.useEffect(() => {
		if (!pip) return;
		config.isStandalone = props.isStandaloneMode;
		config.isStandaloneMode = props.isStandaloneMode;
		config.isFromKiosk = props.location.pathname.includes("kiosk");
		config.isPlanner = props.location.pathname.includes("planner") && !props.location.pathname.includes("kitchenplanner");
		config.isReceipt = props.location.pathname.includes("receipt");
		config.isPtag = props.location.pathname.includes("ptag");
		config.query = props.match.params.id;
		config.kitchen = props.location.state && props.location.state.query;
		config.shoppingcart = props.location.state && props.location.state.shoppinglist;

		config.customerid = props.location.state && props.location.state.customerid;

		config.receipt = props.location.state && props.location.state.receipt;

		pip.SetSharedConfiguration(config);
		const { render, unMount, subscribe, unSubscribe } = pip;

		subscribe("SEND_IMPORT_DATA", setImportData);

		render(elementId, config);

		return () => {
			unSubscribe("SEND_IMPORT_DATA", setImportData);
			unMount(elementId);
		};
	}, [isLoaded, props.location]); // eslint-disable-line

	if (!isLoaded) return null;
	if (!pip) return <div />;

	return <div id={elementId} />;
};

export default MicroCombinationView;
