import googlephoneLib from "google-libphonenumber";
const phoneUtil = googlephoneLib.PhoneNumberUtil.getInstance();

export const IsValidEmail = value => {
	// eslint-disable-next-line no-useless-escape
	var result = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gim.test(
		value
	);
	return result;
};

export const IsValidPhoneNumber = (value, countryCode, regExp) => {
	if (regExp) {
		const regx = new RegExp(regExp);
		const result = regx.test(value);
		return result;
	}

	try {
		if (value.length < 5) return false;
		var parsedNumber = phoneUtil.parse(value, countryCode);

		return (
			phoneUtil.isValidNumber(parsedNumber) &&
			(phoneUtil.getNumberType(parsedNumber) === googlephoneLib.PhoneNumberType.FIXED_LINE ||
				phoneUtil.getNumberType(parsedNumber) === googlephoneLib.PhoneNumberType.MOBILE ||
				phoneUtil.getNumberType(parsedNumber) === googlephoneLib.PhoneNumberType.FIXED_LINE_OR_MOBILE)
		);
	} catch (error) {
		return false;
	}
};

export const IsValidFamilyNumber = value => {
	var regx = new RegExp("^627598-?\\d*(\\.\\d+)?$");
	var result = regx.test(value);
	return result;
};

export const isFamilyNumber = value => {
	var regx = new RegExp("^627598-?\\d*(\\.\\d+)?$");
	var result = regx.test(value);
	return result;
};

export const getRegionNumberCode = countryCode => {
	try {
		const phonenumber = phoneUtil.getExampleNumber(countryCode);
		return phonenumber.getCountryCodeOrDefault();
	} catch (error) {
		return "";
	}
};

export const getNationalNumber = (value, countryCode) => {
	try {
		const parsedNumber = phoneUtil.parse(value, countryCode);
		return parsedNumber.getNationalNumberOrDefault();
	} catch (error) {
		return "";
	}
};

const ValidationHelper = {
	getRegionNumberCode,
	getNationalNumber,
	isFamilyNumber,
	IsValidFamilyNumber,
	IsValidPhoneNumber,
	IsValidEmail
};

export default ValidationHelper;
