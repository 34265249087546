import { APIEndpoints, URI, GUID, httpService, RequestHelper } from "@coworker/sharedlibrary";
import AppLogger from "./../../logger/appLogger";
import HomeUser from "../../factory/homeUser";
import { DfpKey } from "@coworker/sharedlibrary";
import SalesCoworkerConfiguration from "../../store/salesCoworkerConfiguration";

class HomeService {
	constructor() {
		this.EXPIRY = "expiry";
		this.coworkerData = new HomeUser();
		this.environment = {};
		this.preferences = [];
		this.countryTopCategories = [];
		this.countryFields = {};
		this.customerSchema = {
			isMfsProfile: true,
			constraints: undefined
		};
		this.storeInfo = {};
		this.sessionId = GUID.Guid();
	}

	maybeRedirectToPro = (userData, redirectMarketList) => {
		const regex = new RegExp(/(external)|(webviews)/);
		const referer = window.location.href;
		const [language, country] = userData?.locale?.split("-");
		if (redirectMarketList.includes(country) && !regex.test(referer)) {
			window.location.assign(`https://salja.ingka.com/${country.toLowerCase()}/${language}${window.location.pathname}${window.location.search}`);
		}
	};

	getUserInfo = async (userid, storeNo, externalClient = false) => {
		const homeUrl = new URI(APIEndpoints.homeUser, true).fill({
			userid: userid,
			override: externalClient
		});
		const config = SalesCoworkerConfiguration.getUserConfig();
		const userData = await httpService.get(homeUrl, {
			headers: {
				Authorization: `Bearer ${RequestHelper.getToken()}`,
				...config.headers
			}
		});

		if (userData !== undefined) {
			if (storeNo && storeNo != "" && storeNo !== userData.buCode && userData.code !== 400) {
				this.coworkerData = await this.getHomeByStore(storeNo, userid);
				this.coworkerData.name = userData.name;
			} else {
				this.coworkerData = Object.assign({}, userData);
			}
			this.preferences = this.coworkerData.preferences || [];
			if (this.coworkerData.storeDetail) {
				this.storeInfo = this.coworkerData.storeDetail;
			}
			const [, country] = userData?.locale?.split("-");
			if (country === "HR") {
				window.location.assign(`https://www.ikea.com/hr/hr`);
			}
			// Enabling Redirects from GO --> PRO.
			if (this.environment?.saljaProMarkets && !this.userIsDeveloper()) {
				this.maybeRedirectToPro(this.coworkerData, this.environment?.saljaProMarkets);
			}
			this.setcustomerSchema();
			return this.coworkerData;
		}
	};
	userIsDeveloper = () => {
		return this.environment?.devList && this.environment?.devList?.find(userId => userId === this.coworkerData.userId);
	};

	getHomeByStore = async (buNumber, userid) => {
		const homeUrl = new URI(APIEndpoints.homeStore, true).fill({
			bu: buNumber,
			userid: userid
		});
		const config = SalesCoworkerConfiguration.getUserConfig();
		const coworkerData = await httpService.get(homeUrl, {
			headers: {
				Authorization: `Bearer ${RequestHelper.getToken()}`,
				...config.headers
			}
		});
		this.coworkerData = coworkerData;
		this.preferences = this.coworkerData.preferences || [];
		this.setcustomerSchema();
		if (this.coworkerData.storeDetail) this.storeInfo = this.coworkerData.storeDetail;
		return this.coworkerData;
	};

	getappPreferrences = async (buCode, countryCode) => {
		let storePreferencesUrl = new URI(APIEndpoints.storePreferences, true).fill({
			bu: buCode,
			country: countryCode,
			forceRefresh: false
		});
		try {
			const config = SalesCoworkerConfiguration.getUserConfig();
			const result = await httpService.get(storePreferencesUrl, {
				headers: {
					Authorization: `Bearer ${RequestHelper.getToken()}`,
					...config.headers
				}
			});
			this.preferences = result.preferences;
			if (this.preferences && this.preferences.length > 0) {
				this.setcustomerSchema();
			}
			return this.preferences;
		} catch (error) {
			return [];
		}
	};

	getPreference = key => {
		if (this.preferences !== undefined) {
			const preference = this.preferences.find(x => x !== undefined && x.name === key);
			return preference;
		}
		return null;
	};

	setcustomerSchema = async () => {
		const preference = this.getPreference(DfpKey.ICM_PROFILE_ENABLED);
		if (preference !== undefined) {
			this.customerSchema = {
				isMfsProfile: preference.value === "false"
			};
			return this.customerSchema;
		}
		return this.customerSchema;
	};

	getStoreInfo = async (buCode, forceFetch = false) => {
		try {
			if (!forceFetch) return this.storeInfo;
			const url = new URI(APIEndpoints.storeInfo, true).fill({
				buCode: buCode
			});
			const config = SalesCoworkerConfiguration.getUserConfig();
			const storedetails = await httpService.get(url, {
				headers: {
					Authorization: `Bearer ${RequestHelper.getToken()}`,
					...config.headers
				}
			});
			this.storeInfo = storedetails;
		} catch (error) {
			AppLogger.error(`GetStoreInfo failed:${error.message}`, "GetStoreInfo");
		}
		return this.storeInfo;
	};

	initConfig = async () => {
		const config = SalesCoworkerConfiguration.getUserConfig();
		this.environment = await httpService.get(APIEndpoints.envConfig, {
			headers: {
				Authorization: `Bearer ${RequestHelper.getToken()}`,
				...config.headers
			}
		});
		this.environment.mfscnurl = this.environment.mfschinaurl;
	};

	setStoreUserSettings = async (userid, data) => {
		try {
			const homeUrl = new URI(APIEndpoints.setUserSetting, true).fill({
				userid: userid
			});
			const config = SalesCoworkerConfiguration.getUserConfig();
			const response = await httpService.post(homeUrl, {
				body: data,
				headers: {
					Authorization: `Bearer ${RequestHelper.getToken()}`,
					...config.headers
				}
			});
			return response && response.message === "success";
		} catch (error) {
			AppLogger.error(`setStoreUserSettings failed:${error.message}`, "setStoreUserSettings");
		}
	};

	getUserPrivileges = async buCode => {
		try {
			const homeUrl = new URI(APIEndpoints.userPrivileges, true).fill({
				buCode: buCode
			});
			const config = SalesCoworkerConfiguration.getUserConfig();

			const response = await httpService.get(homeUrl, {
				headers: {
					Authorization: `Bearer ${RequestHelper.getToken()}`,
					...config.headers
				}
			});
			return response && response.privileges;
		} catch (error) {
			AppLogger.error(`getUserPrivileges failed:${error.message}`, "getUserPrivileges");
		}
	};
}

export default HomeService;
