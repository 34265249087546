import React, { useState } from "react";
import styled from "styled-components";
import { device } from "@coworker/sharedlibrary";
import { getMessage } from "@coworker/sharedlibrary";

const Container = styled.div`
  border-radius: 28px;
  border: solid 1px #d8d8d8;
  width: 240px;
  height: 55px;
  padding: 10px 12px 10px 11px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${props => props.disabled && "opacity: 0.4;pointer-events:none;"}
  ${props => props.compact && "width: 120px; height:40px;"}

  @media ${device.medium} {
    padding: 10px;
  }
`;

const QntyInput = styled.input`
	background: transparent;
	width: 60px !important;
	height: 21px;
	color: #111111;
	text-align: center;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	padding: 0 !important;
	border: none !important;

	@media ${device.medium} {
		width: 30px !important;
	}

	:active,
	:focus-visible {
		color: #111111 !important;
		border: none !important;
		box-shadow: none !important;
	}
`;

const PlusMinuButton = styled.button`
	width: 16px;
	height: 21px;
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.31;
	letter-spacing: normal;
	color: #111111;
	background: transparent;
	border: none;
	:disabled {
		opacity: 0.4;
	}
`;

const QuantityField = (props, messages) => {
	const {
		value,
		min = 0,
		max = 100,
		onChange,
		disabled = false,
		compact,
		itemId,
		valueOfMaxDisplay = false,
		canIncreaseQuantity = true,
		canDecreaseQuantity = true
	} = props;

	const [quantity, setQuantity] = useState(value || min);

	function handleOnClick(incValue) {
		let num = (valueOfMaxDisplay ? parseInt(quantity.split("/")[0]) : quantity) + incValue;
		if (num === -1) {
			num = 0;
		}
		const newValue = valueOfMaxDisplay ? `${num}/${max}` : num;

		setQuantity(newValue);
		onChange && onChange(num);
	}

	function handleInputOnClick(e) {
		e.target.select();
	}

	function handleInputOnChange(e, allowCallback) {
		let num = valueOfMaxDisplay ? parseInt(e.target.value.split("/")[0]) : e.target.value;
		num = isNaN(num) ? 0 : parseInt(num);
		num = num >= min ? (num <= max ? num : max) : min;

		const newValue = valueOfMaxDisplay ? `${num}/${max}` : num;

		setQuantity(newValue);
		allowCallback && onChange && onChange(num);
	}

	function handleInputOnKeyDown(e, allowCallback) {
		if (e.key === "Enter") {
			handleInputOnChange(e, allowCallback);
		}
	}
	let itmQuantity = valueOfMaxDisplay ? parseInt(quantity.split("/")[0]) : quantity;
	return (
		<Container disabled={disabled} compact={compact} data-testid={`cart-item-change-${itemId}`}>
			<PlusMinuButton
				alt={getMessage("SG_BTN_Minus", messages, "Remove a product")}
				data-testid="qntyminusbtn"
				onClick={() => handleOnClick(-1)}
				disabled={itmQuantity <= min || !canDecreaseQuantity}
			>
				-
			</PlusMinuButton>
			<QntyInput
				alt={getMessage("SG_LBL_Added", messages, "Added")}
				value={quantity}
				type="tel"
				disabled={!canDecreaseQuantity && !canIncreaseQuantity}
				onClick={e => handleInputOnClick(e)}
				onChange={e => handleInputOnChange(e)}
				onKeyDown={e => handleInputOnKeyDown(e, true)}
				onBlur={e => handleInputOnChange(e, true)}
			/>
			<PlusMinuButton
				alt={getMessage("SG_BTN_Plus", messages, "Add another product")}
				data-testid="qntyplusbtn"
				onClick={() => handleOnClick(1)}
				disabled={itmQuantity >= max || !canIncreaseQuantity}
			>
				+
			</PlusMinuButton>
		</Container>
	);
};

export default QuantityField;
