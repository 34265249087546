import { AppLogger, colors, device } from "@coworker/sharedlibrary";
import { BackButton, IconButton } from "./../Buttons/Buttons";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { ReactComponent as AccountIcon } from "./../../static/account.svg";
import { GaHelper } from "@coworker/sharedlibrary";
import { useModelPopup } from "../modelPopup";

import { SaljaLogoFrontpage } from "../sharedlibrary";

import { ReactComponent as SearchIcon } from "./../../static/search.svg";
import arrowBack from "@ingka/ssr-icon/paths/arrow-back";
import { history as browseHistory } from "./../../store/history";
import styled from "styled-components";
import locationIcon from "@ingka/ssr-icon/paths/location";
import Button from "@ingka/button/Button";
import { eventsToDispatch } from "@coworker/sharedlibrary";
import { getCustomerProfileObject } from "./../../utils/shellObjectService";
import SearchContainer from "./searchContainer";
import HelpIconExperiment from "./experiments/helpIconExperiment";
import useCurrentWidth from "../../hooks/useCurrentWidth";
const Header = styled.header`
	background: ${colors.blue};
	width: 100%;
	position: absolute;

	padding-top: ${props => (props.topPosition === 0 ? "calc(env(safe-area-inset-top))" : 0)};

	.header-content-wrapper {
		position: relative;
		width: 100%;
	}

	right: 0;
	z-index: 10;
	opacity: 1;
	transition: all ease 0.15s;

	@media ${device.medium} {
		width: 100%;
		position: fixed;

		&.hidden {
			height: 0;
			opacity: 0;

			* {
				visibility: hidden;
			}
		}
	}
	.i-hamburger {
		color: black !important;
	}
`;

const HeaderInner = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-flow: wrap;
	align-items: center;
	min-height: 72px;
	padding: 10px 25px;
	background: ${colors.blue};

	@media ${device.medium} {
		padding-left: 20px;
		padding-right: 15px;
		position: absolute;
		z-index: ${props => (props.isScanning ? "0" : "11")};
	}
`;

const IconWrapper = styled.div`
	display: flex;
	align-items: center;

	button {
		height: 32px;
		padding: 0 !important;
		color: white;

		&:focus-visible {
			outline: solid 2px white;
		}
	}
	svg {
		width: 32px;
		height: 32px;

		path {
			fill: #fff;
		}
	}

	&.search-icon {
		display: none;
		@media ${device.medium} {
			display: flex;
		}
	}

	.withTextIcon {
		flex-direction: row;
		align-items: center;
		span {
			display: none;
			color: white;
		}

		@media (min-width: 1200px) {
			display: flex;
			padding: 8px 20px 8px 10px;
			box-sizing: border-box;

			span {
				display: block;
			}
		}
	}

	.hideTextIcon {
		span {
			display: none !important;
			color: white;
		}
		border: none !important;
		padding: 0;
	}
`;

const ActionContainer = styled.div`
	margin-left: auto;
	display: flex;
`;

const BackButtonContainer = styled.div`
	transition: ease 0.3s all;
	overflow: hidden;
	height: 50px;
	width: ${props => (props.active ? "90px" : "0")};
	opacity: ${props => (props.active ? "1" : "0")};
	position: relative;
	button {
		position: absolute;
		background: transparent;
		height: 50px;

		&:hover,
		.btn__inner--icon-tertiary:hover,
		&:active {
			background: transparent;
		}
		&:focus-visible {
			border: white 1px solid;
			height: 50px !important;
			width: 50px !important;
		}
	}

	svg {
		color: black !important;
		width: 32px;
		height: 32px;

		fill: #fff !important;
	}
`;

const TopHeader = ({ showHeader, history, offsetLeft, topPosition = 0, visbilityCallback }) => {
	const width = useCurrentWidth();
	const { isModifyOrderinProgress } = useSelector(state => state.cartController);
	const {
		messages = {
			SG_LBL_SearchIconHint: "Search"
		}
	} = useSelector(state => state.intl);
	const [routeAreaScrollTop, setRouteAreaScrollTop] = useState(0);
	const [routeAreaLastScrollTop, setRouteAreaLastScrollTop] = useState(0);
	const [routeAreaScrollDirection, setRouteAreaScrollDirection] = useState("up");
	const [isScanning, setIsScanning] = useState(false);
	const [showSearchInput, setShowSearchInput] = useState(false);
	const [backButtonEnabled, setBackButtonEnabled] = useState(false);
	const [canShowAddZipCodeText, setCanShowAddZipCodeTest] = useState(true);

	const determineScrollEvent = React.useCallback(() => {
		let supportPageOffset = window.pageXOffset !== undefined;
		let isCSS1Compat = (document.compatMode || "") === "CSS1Compat";
		let scrollY = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;

		setRouteAreaScrollTop(scrollY);
	}, []);

	useEffect(() => {
		if (routeAreaScrollTop < 50) {
			setRouteAreaScrollDirection("up");
		} else {
			if ((routeAreaLastScrollTop > routeAreaScrollTop || routeAreaScrollTop < 50) && routeAreaScrollDirection !== "up") {
				setRouteAreaScrollDirection("up");
			} else if (routeAreaLastScrollTop < routeAreaScrollTop && routeAreaScrollDirection !== "down") {
				setRouteAreaScrollDirection("down");
			}
		}

		if (routeAreaScrollDirection === "down" && !isScanning) {
			visbilityCallback && visbilityCallback(false);
		} else {
			visbilityCallback && visbilityCallback(true);
		}

		setRouteAreaLastScrollTop(routeAreaScrollTop);
	}, [routeAreaScrollTop, routeAreaLastScrollTop, routeAreaScrollDirection]);

	useEffect(() => {
		window.addEventListener("scroll", determineScrollEvent);
		window.addEventListener(eventsToDispatch.ON_SAVE_CUSTOMER, handleOnSaveCustomerEvent);
		window.addEventListener(eventsToDispatch.CLEAR_CUSTOMER, handleOnSaveCustomerEvent);

		return () => {
			window.removeEventListener("scroll", determineScrollEvent);
			window.removeEventListener(eventsToDispatch.ON_SAVE_CUSTOMER, handleOnSaveCustomerEvent);
			window.removeEventListener(eventsToDispatch.CLEAR_CUSTOMER, handleOnSaveCustomerEvent);
		};
	}, [determineScrollEvent]);

	browseHistory.listen(location => {
		if (!location.pathname.includes("search")) {
			setShowSearchInput(false);
		}
	});

	const { getModel } = useModelPopup();

	if (!showHeader) {
		return "";
	}

	async function onProfileClick() {
		GaHelper.SendPageView("/user");
		await getModel("userAccountPopup", {
			messages: messages
		});
	}

	async function onHelpClick() {
		await getModel("helpSectionDialog");
	}

	function showSearchBox() {
		AppLogger.info("Click on search icon", "OnSearchIconClick", {
			data: !showSearchInput
		});
		setShowSearchInput(!showSearchInput);
	}
	function onScanOpened(isScan) {
		setIsScanning(isScan);
		console.log(isScan);
	}
	async function onCustomerClick() {
		GaHelper.SendPageView("/user");
		await getModel("preSelectCustomerDialog");
	}

	function onLogoClick() {
		history.push("/");
	}

	function handleOnSaveCustomerEvent() {
		const { customer } = getCustomerProfileObject();

		if (customer && customer.zipCode) {
			setCanShowAddZipCodeTest(false);
		} else {
			setCanShowAddZipCodeTest(true);
		}
	}
	const isDesktop = width > 899;
	return (
		<Header
			className={`top-header${routeAreaScrollDirection === "down" && !isScanning ? " hidden" : ""}`}
			offsetLeft={offsetLeft + 70}
			topPosition={topPosition}
		>
			<div className="header-content-wrapper">
				<HeaderInner isScanning={isScanning}>
					<SaljaLogoFrontpage clickFunction={onLogoClick} />
					<BackButtonContainer active={backButtonEnabled}>
						<BackButton history={history} ssrIcon={arrowBack} enabled={isEnabled => setBackButtonEnabled(isEnabled)} />
					</BackButtonContainer>
					{isDesktop && <SearchContainer onScanOpened={onScanOpened} showSearchInput={showSearchInput} />}
					<ActionContainer>
						<IconWrapper className="search-icon">
							<IconButton onClick={showSearchBox} fontsize={"24px"}>
								<SearchIcon title={messages.SG_LBL_SearchIconHint || "Search"}></SearchIcon>
							</IconButton>
						</IconWrapper>
						<HelpIconExperiment onHelpClick={onHelpClick} IconButton={IconButton} IconWrapper={IconWrapper} />

						{!isModifyOrderinProgress && (
							<IconWrapper>
								<Button
									className={canShowAddZipCodeText}
									data-testid="prselectcustomerbtn"
									ssrIcon={locationIcon}
									text={messages.SG_BTN_Menu_AddZipcode || "Add zipcode or address"}
									type="plain"
									iconOnly={true}
									expanding={true}
									onClick={onCustomerClick}
								></Button>
							</IconWrapper>
						)}

						<IconWrapper>
							<IconButton onClick={onProfileClick} fontsize={"32px"} data-testid="userprofile">
								<AccountIcon title={messages.SG_BTN_UserProfile || "User Profile"}></AccountIcon>
							</IconButton>
						</IconWrapper>
					</ActionContainer>
				</HeaderInner>
				{!isDesktop && <SearchContainer mobileLayout={true} showSearchInput={showSearchInput} onScanOpened={onScanOpened} />}
			</div>
		</Header>
	);
};

export default TopHeader;
