import { device } from "@coworker/sharedlibrary";
import { useSelector } from "react-redux";
import { getMessage, OrderTypes } from "@coworker/sharedlibrary";
import React from "react";
import styled from "styled-components";
import Button from "@ingka/button";
import { useCartModelPopup } from "./modelPopup";
import { useHotKeys } from "../../../hooks/useHotKeys";
import { GaHelper } from "@coworker/sharedlibrary";

const ItemsTitle = styled.h6`
	margin-bottom: 0px;
	font-size: 24px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #111111;
`;

const ItemsSubTitle = styled.span`
	display: block;
	margin: 10px 0 0;
	font-size: 16px;
`;

const ShoppingBagHeader = styled.div`
	min-height: 60px;
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	@media ${device.small} {
		margin: 15px 0px;
	}
`;

const CartBagHeader = () => {
	const { order, isQuotation, isTransportDeskMode } = useSelector(state => state.cartController);
	const { messages } = useSelector(state => state.intl);

	return (
		<ShoppingBagHeader data-testid="shoppingbag">
			<div>
				<ItemsTitle>{getShoppingBagTitle(order, isQuotation, isTransportDeskMode, messages)}</ItemsTitle>
				{getShoppingBagOrderStatus && <ItemsSubTitle data-testid="subtitle">{getShoppingBagOrderStatus(order, messages)}</ItemsSubTitle>}
			</div>
			<NewBagMenu messages={messages} />
		</ShoppingBagHeader>
	);
};

export default CartBagHeader;

const getShoppingBagTitle = (order, isQuotation, isTransportDeskMode, messages) => {
	if (isTransportDeskMode) {
		return `${getMessage("SG_LBL_TransportDeskOrder", messages, "Transport Desk order")} ${(order && order.orderNo) || ""}`;
	}

	if (isQuotation && order) {
		return `${getMessage("SG_LBL_Quotation", messages, "Quotation")} ${(order && order.orderNo) || ""}`;
	}

	if (!order) return getMessage("SG_LBL_ShoppingBag", messages, "Shopping bag");

	if (order.type === OrderTypes.SHLIST || order.type === OrderTypes.ShoppingList) {
		return `${getMessage("SG_LBL_OrderTypeShoppingList", messages, "Shopping list")} ${order.orderNo}`;
	}

	if (order.type === OrderTypes.IsomOrder) {
		return `${getMessage("SG_LBL_DistributionOrder", messages, "Distribution order")} ${order.orderNo}`;
	}

	return `${getMessage("SG_LBL_SalesOrder", messages, "Sales order")} ${order.orderNo}`;
};

const getShoppingBagOrderStatus = (order, messages) => {
	if (order && order.orderStatus) {
		return getMessage(`SG_LBL_ORDER_STATUS_${order.orderStatus}`, messages, order.orderStatus);
	}
};

const NewBagMenu = props => {
	const { getCartModel } = useCartModelPopup();
	const { totalItemCount, order, canCreateOrder } = useSelector(state => state.cartController);
	const { messages } = props;

	useHotKeys(["cntrl+n", "F3"], () => {
		if (totalItemCount > 0 || order || canCreateOrder) showBagDialog();
		GaHelper.SendEvent("Cart", "NewShoppingBag", "User Selected New Shopping Bag");
	});

	async function showBagDialog() {
		await getCartModel("newShoppingBag");
	}

	return (
		<Button
			data-testid="newbagbtn"
			onClick={() => {
				if (totalItemCount > 0 || order || canCreateOrder) showBagDialog();
			}}
			small={true}
			type="secondary"
			text={getMessage("SG_BTN_CreateNewBag", messages, "New bag")}
		></Button>
	);
};
