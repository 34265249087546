import * as DeliveryAndServiceHelper from "./helpers/deliveryandserviceHelper";
import * as FacetFields from "./constants/facetFields";
import * as HttpUrlHelper from "./helpers/httpUrlHelper";
import * as ObjectHelper from "./helpers/objectHelper";
import * as PagePaths from "./constants/pagePaths";
import * as QuestionnaireSpecification from "./helpers/questionnaireHelper";
import * as RequestHelper from "./helpers/requestHelper";
import * as RetailItemHelper from "./helpers/retailItemInformationHelper";
import * as SearchHelper from "./helpers/searchHelper";
import * as StorageHelper from "./helpers/storageHelper";
import ProductService from "./service/productService";

import customStore from "./utils/customStore";

import { DfpKey, StorageKeys } from "./constants/dfpKey";
import { LayoutLeftBarWidth, LayoutPadding, device, mediaQuery, orientation, size } from "./helpers/layoutSizeHelper";
import RetailItemModel, { MeasureMentPropType, PricePropType, RetailItemPropType, UnitFactortPropType } from "./models/retailItemModel";
import { UserContext, UserContextProvider } from "./context/userContext";
import { dispatchEvent, eventsToDispatch } from "./utils/eventDispatcher";

import { HideBodyScrollStyle } from "./styles/globalStyle";

// import { ContainerLayout as Container } from "./components/ContainerLayout";

export { default as GUID } from "./helpers/uuidHelper";
export { default as getMessage } from "./helpers/translationHelper";
export { calcPrice } from "./helpers/bigDecimalHelper";

export { default as Planner } from "./models/planner";
export { default as PtagCombination } from "./models/ptag";
export { default as Roomset } from "./models/roomSet";
export { default as PriceModel } from "./models/priceModel";
export { default as LogModel } from "./models/logModel";
export { RetailItemModel, RetailItemPropType, PricePropType, MeasureMentPropType, UnitFactortPropType };

export { default as CustomerModel } from "./models/customerModel";
export { default as ServiceInfoModelList } from "./models/serviceInfoModelList";
export { default as ServiceInfoModel } from "./models/serviceInfoModel";
export { default as ServiceProposalModel } from "./models/serviceProposalModel";
export { default as IsomServiceInfoModel } from "./models/isomServiceModel";
export { default as QuestionModel } from "./models/questionModel";
export { default as OrderServiceList } from "./models/orderServicesList";
export { default as OrderServicesList } from "./models/orderServicesList";
export { default as AutoCompleteSuggestionModel } from "./models/autoCompleteSuggestionModel";

export { default as Specifications } from "./helpers/specHelper";
export { default as LocaleHelper } from "./helpers/localeHelper";
export { default as ValidationHelper } from "./helpers/validationHelper";
export { default as TaxCodeHelper } from "./helpers/taxCodeHelper";
export { default as GaHelper } from "./helpers/gaAnalyticsHelper";
export { default as BrowserHelper } from "./helpers/browserHelper";
export { default as JWTHelper } from "./helpers/jwtHelper";
export { default as colors } from "./helpers/colorHelper";
export { default as ColorHelper } from "./helpers/colorHelper";
export { default as DateTimeHelper } from "./helpers/dateTimeHelper";
export { default as URI } from "./helpers/uriLinkHelper";
export { default as SalesItemTypes } from "./constants/salesItemTypes";
export { default as APIEndpoints } from "./constants/apiEndpoints";
export { default as ServiceTypes } from "./constants/serviceTypes";
export { default as globalVariables } from "./constants/globalVariables";
export { default as httpService } from "./service/httpService";

export { default as AppLogger } from "./logger/appLogger";

export { default as useWindowEventListener } from "./utils/useWindowEventListener";

export { DfpKey, StorageKeys };
export { default as useMicrofrontend } from "./utils/useMicroFrontend";
export { default as getMicroFrontendInstance } from "./utils/microFrontentInstance";
export { default as Break } from "./components/common/Break";
export { default as Grid } from "./components/common/grid";
export { default as List } from "./components/common/list";
export { default as Label } from "./components/common/label";
export { default as PageContainer } from "./components/common/pageContainer";
export { default as NoResultsView } from "./components/common/noResultsView";
export { default as SaljaLogo } from "./components/common/saljaLogo";
export { default as SaljaLogoFrontpage } from "./components/common/saljaLogoFrontpage";
export { default as Container } from "./components/common/container";
export { default as AnimatedPlaceholder } from "./components/common/animatedPlaceholder";

// export {default as ContentPlaceholder } from "./components/contentPlaceholder/contentPlaceholder";
// export { default as ListLayout } from "./components/ListLayout";
// export { default as GridLayout } from "./components/GridLayout";

// // export { default as List } from "./components/ListLayout";
// // export { default as Grid } from "./components/GridLayout";
// // export { default as Label } from "./components/Label";
// export { default as NoResultsView } from "./components/NoResultView";
// export { default as NoDataFoundView } from "./components/NoResultView";

// export { default as ErrorContainer } from "./components/ErrorContainer";

export { ObjectHelper };
export { RetailItemHelper };
export { DeliveryAndServiceHelper };
export { StorageHelper };

export { dispatchEvent, eventsToDispatch };

export { device, size, mediaQuery, orientation, LayoutLeftBarWidth, LayoutPadding };
export { RequestHelper };

export { HideBodyScrollStyle };

export { HttpUrlHelper };
export { FacetFields };
export { QuestionnaireSpecification };
export { PagePaths };
export { default as useHotKeys } from "./hooks/useHotKeys";
export { default as hitType } from "./constants/searchHitType";
export { default as HitType } from "./constants/searchHitType";

export { default as FeaturesList } from "./constants/appFeatureList";
export { default as OrderTypes } from "./constants/orderTypes";
export { default as RecentOrderMapper } from "./helpers/orderMapper";
export { default as ImageResize } from "./helpers/image-resize";
export { SearchHelper };
export { default as ShoppingCartItemFactory } from "./models/shoppingCartItemFactory";
export { default as ShoppingCartItem } from "./models/shoppingCartItem";
export { default as TranslationService } from "./service/translationService";
export { default as UserHistoryService } from "./service/userHistoryService";
export { default as SharedConfiguration } from "./service/sharedConfiguration";
export { UserContextProvider };
export { UserContext };
export { default as useDebounce } from "./utils/useDebounce";
export { default as cartItemsBuilder } from "./helpers/cartItemsRequestBuilder";
export { default as isomItemsBuilder } from "./helpers/isomItemsBuilder";
export { default as SalesOrderItemsBuilder } from "./helpers/salesOrderItemsBuilder";
export { default as proposalItemsBuilder } from "./helpers/proposaItemBuilder";
export { default as isModelActive } from "./helpers/modelPopupHelper";
export { default as BarcodeReader } from "./components/BarcodeReader";
export { default as StockView } from "./components/product/stockView/index";
export { default as LocationView } from "./components/product/locationView/index";
export { default as ExperimentalFeatureId } from "./constants/expermintalFeatureIds";
export { default as fetchWithTimeout } from "./utils/fetchWithTimeout";
export { default as nativeLogger } from "./utils/nativeLogger";

const reduxStoreHelper = {
	customStore
};
export { reduxStoreHelper };

const services = {
	ProductService
};
export { services };
