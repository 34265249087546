//import * as uriTemplates from "@coworker/sharedlibrary/src/helpers/uritemplates";
let uriTemplates = require("@coworker/sharedlibrary/src/helpers/uritemplates");
class URI {
	constructor(uri, templated = false) {
		this.templated = templated;
		if (templated) {
			this.uriTemplates = uriTemplates(uri);
		} else {
			this.uri = uri;
		}
	}
	/**
   * Calls resourceURI params

   * @return {String} Returns String url formatted.
   */

	resourceURI() {
		if (this.templated) {
			if (this.fetchedURI != "") {
				return this.fetchedURI;
			}
			throw new Error("can not call delete on resource with templated link");
		} else {
			return this.uri;
		}
	}
	/**
	 * Calls fills params
	 *
	 * @param {Object} [params = {}]
	 *
	 * @return {String} Returns String url formatted.
	 */
	fill(params = {}) {
		if (this.templated && this.uriTemplates) {
			return this.uriTemplates.fill(params);
		} else {
			return this.uri;
		}
	}
}
export default URI;
