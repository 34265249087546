import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { colors, device, getMessage, GUID, LocationView, RetailItemHelper, Specifications, StockView } from "@coworker/sharedlibrary";

import { isFamilyPrice } from "./../../../../helpers/cartHelper";

import { updateGroupName } from "./../../../../store/cart/cartActions";

import Accordion from "@ingka/accordion";
import Checkbox from "@ingka/checkbox";
import ProductIdentifier from "@ingka/product-identifier";

import ItemImageControl from "../retailItem/itemImageControl";
import { Grid } from "./../../../../components/sharedlibrary";
import PriceControl from "./../../../../components/price/priceControl";

import CartImageList from "./../cartImageList";

import EditGroupNameView from "./editGroupNameView";
import ScrollContainer from "./scrollableContainer";

import {
	CartAccordionItem,
	Description,
	FoodStoreInfoContainer,
	ItemDesc,
	LocationInfo,
	MiddleSection,
	Name,
	PriceContainer,
	QuantityPrice,
	ResponsiveWidth,
	StockSection,
	TopSection
} from "./sharedStyles";

const CardDragContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  font-size: 12px;
  padding: 20px;
  border-top 1px solid ${colors.grey200};

  @media ${device.large} {
    padding: 20px;
  }
  @media ${device.medium} {
    padding: 20px 12px;
  }
`;

const CartSelectSection = styled.div`
	width: 25px;
	height: 25px;
	align-self: flex-start;
	margin: 7px 25px 0 20px;
`;

const DraggableCartItemCard = ({ cartItem, expandedItems, ischildItem = false, onItemExpanded, onItemSelected, parentEditing, selectedItems }) => {
	const dispatch = useDispatch();

	const { isFoodItemSpec } = Specifications;

	const item = cartItem.retailItem;
	const isFoodItem = isFoodItemSpec(item.storeInfo);
	const isChecked = selectedItems.find(x => x.id === cartItem.id) !== undefined;

	const { messages } = useSelector(state => state.intl);

	const [editGroupName, setEditGroupName] = useState(false);
	const [showChildItems, setshowChildItems] = useState(
		(parentEditing !== undefined && parentEditing.id === cartItem.id) ||
			(expandedItems && Array.isArray(expandedItems) && expandedItems.length && expandedItems.find(x => x.id === cartItem.id) !== undefined)
	);

	const updateItemGroupName = groupName => {
		if (groupName) {
			dispatch(updateGroupName(cartItem.id, groupName));
		}
	};

	const hasChildItems = cartItem.childItems && Array.isArray(cartItem.childItems) && cartItem.childItems.length > 0;

	return (
		<div>
			<CardDragContainer>
				<Grid width="100%">
					<CartSelectSection>
						<Checkbox checked={isChecked} onChange={e => onItemSelected(cartItem.id, e.target.checked)} value="  " />
					</CartSelectSection>
					{cartItem.isCombination ? (
						<CartImageList group={cartItem.retailItem} />
					) : (
						<ItemImageControl align="flex-start" breakpoint={device.large} height={"64px"} item={item} theme="itemCardTheme" width={"64px"} />
					)}
					<ResponsiveWidth width={"100%"}>
						<TopSection>
							<Description padding={"0px 0px 0px 20px"}>
								{!cartItem.isGroup && (
									<ProductIdentifier style={{ display: "block" }} subtle={true} value={RetailItemHelper.toItemFormaId(cartItem.itemId)} />
								)}
								<Grid
									minheight={cartItem.isGroup ? "60px" : "0px"}
									onClick={() => {
										if (cartItem.isGroup) {
											setEditGroupName(true);
										}
									}}
								>
									{editGroupName ? (
										<EditGroupNameView
											animateOnload={true}
											editGroupName={editGroupName}
											cartItem={cartItem}
											updateGroupName={name => {
												updateItemGroupName(name);
											}}
										/>
									) : (
										<Name>{cartItem.retailItem.Name}</Name>
									)}
								</Grid>
								<ItemDesc>{cartItem.itemDescription}</ItemDesc>
							</Description>
							<QuantityPrice className="wide">
								<PriceContainer className={!cartItem.isGroup && isFamilyPrice(cartItem) ? "multiplePrices price-container" : "price-container"}>
									{cartItem.totalFamilyPrice !== undefined &&
										cartItem.totalFamilyPrice.Value !== 0 &&
										cartItem.totalFamilyPrice.Value !== cartItem.totalPrice.Value && <PriceControl price={cartItem.totalFamilyPrice} />}
									<PriceControl price={cartItem.totalPrice} />
								</PriceContainer>
							</QuantityPrice>
						</TopSection>

						<StockSection className="narrow">
							<ScrollContainer>
								{!isFoodItem && <StockView disableForecast={cartItem.isCombination} messages={messages} retailItem={cartItem.retailItem} />}
								{!hasChildItems && (
									<LocationInfo>
										<LocationView disableForecast={cartItem.isCombination} messages={messages} retailItem={item} />
									</LocationInfo>
								)}
							</ScrollContainer>
						</StockSection>

						<MiddleSection>
							<StockSection className="wide">
								{isFoodItem ? (
									<FoodStoreInfoContainer>
										{getMessage("SG_LBL_FoodItemStoreInfoMessage", messages, "Don't have stock info for food products")}
									</FoodStoreInfoContainer>
								) : (
									<StockView
										buttonStyle={true}
										disableForecast={cartItem.isCombination}
										messages={messages}
										retailItem={cartItem.retailItem}
									/>
								)}
								{!hasChildItems && (
									<LocationInfo>
										<LocationView disableForecast={cartItem.isCombination} messages={messages} retailItem={item} />
									</LocationInfo>
								)}
							</StockSection>
							<QuantityPrice className="narrow" start="initial">
								<PriceContainer className={!cartItem.isGroup && isFamilyPrice(cartItem) ? "multiplePrices price-container" : "price-container"}>
									{cartItem.totalFamilyPrice !== undefined &&
										cartItem.totalFamilyPrice.Value !== 0 &&
										cartItem.totalFamilyPrice.Value !== cartItem.totalPrice.Value && <PriceControl price={cartItem.totalFamilyPrice} />}
									<PriceControl price={cartItem.totalPrice} />
								</PriceContainer>
							</QuantityPrice>
						</MiddleSection>
					</ResponsiveWidth>
				</Grid>
			</CardDragContainer>

			{hasChildItems && !ischildItem && cartItem.isGroup && (
				<Accordion>
					<CartAccordionItem
						key={GUID.Guid()}
						onHeadingClicked={() => {
							setshowChildItems(!showChildItems);
							onItemExpanded && onItemExpanded(cartItem, !showChildItems);
						}}
						open={showChildItems}
						title={`${cartItem.childItems.length} ${getMessage("SG_LBL_Articles", messages, "articles").toLowerCase()}`}
					>
						{cartItem.childItems.map(childItem => (
							<DraggableCartItemCard
								cartItem={childItem}
								ischildItem={true}
								key={GUID.Guid()}
								onItemSelected={(id, selected) => {
									onItemSelected(id, selected, true, cartItem);
								}}
								selectedItems={selectedItems}
							/>
						))}
					</CartAccordionItem>
				</Accordion>
			)}
		</div>
	);
};

export { DraggableCartItemCard };
