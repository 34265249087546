import AppLogger from "@coworker/sharedlibrary/src/logger/appLogger";
import LocaleHelper from "@coworker/sharedlibrary/src/helpers/localeHelper";
import httpService from "@coworker/sharedlibrary/src/service/httpService";

const getTranslations = (languageCode, showKeysAsValue) => {
	let javaLngCode = LocaleHelper.formatLocaleToJava(languageCode);
	const isoLngCode = LocaleHelper.formatLocaleIso(languageCode);
	// eslint-disable-next-line no-undef
	let baseUrl = process.env.REACT_APP_API_HOST || window.location.origin;
	const getMessages = async locale => {
		try {
			const response = await httpService.get(
				// eslint-disable-next-line no-undef
				`${baseUrl}/api/translation?locale=${locale}`
			);
			return response;
		} catch (error) {
			//
		}
	};

	return getMessages(isoLngCode)
		.then(msgs => {
			if (msgs[javaLngCode]) {
				if (showKeysAsValue) {
					Object.entries(msgs[javaLngCode]).forEach(([key]) => {
						msgs[javaLngCode][key] = key;
					});
				}
				return {
					locale: isoLngCode,
					locales: isoLngCode,
					messages: msgs[javaLngCode]
				};
			}
			return {
				locale: "en",
				locales: "en",
				messages: msgs["en"]
			};
		})
		.then(i18nConfig => {
			return i18nConfig;
		})
		.catch(error => {
			AppLogger.error(error.message, "TranlationError");
			return undefined;
		});
};

const translationService = {
	getTranslations
};

export default translationService;
