import { SET_USER } from "./types";

const UserType = {
	GUEST: "GUEST",
	SALESPERSON: "SALESPERSON"
};
const INITIAL_STATE = {
	user: undefined,
	userMode: UserType.SALESPERSON
};

const authReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_USER:
			return {
				...state,
				user: action.payload.user,
				userMode: action.payload.userMode
			};
		default:
			return state;
	}
};
export default authReducer;
export { UserType };
