import SalesItemTypes from "@coworker/sharedlibrary/src/constants/salesItemTypes";
const { ART } = SalesItemTypes;
const cartItemsBuilder = {
	createItems: (cartItems, includeSprs = false) => {
		let items = [];
		cartItems.forEach(cartItem => {
			if (cartItem.isCombination) {
				cartItem.childItems.forEach(subItem => {
					var bitems = buildItems(cartItem, subItem, includeSprs);
					items = items.concat(bitems);
				});
			} else {
				var aItems = buildItems(null, cartItem, includeSprs);

				items = items.concat(aItems);
			}
		});
		return items;
	},
	createItem: cartItem => {
		return createItem(cartItem);
	}
};
const buildItems = (groupParent, cartItem, includeSprs) => {
	let items = [];
	if (cartItem.retailItem && cartItem.retailItem.ItemType === ART) {
		var articleItem = createItem(cartItem);
		if (groupParent !== null) {
			articleItem.quantity = articleItem.quantity * groupParent.quantity;
		}
		items.push(articleItem);
	} else {
		var sprItem = createItem(cartItem);
		if (groupParent !== null) {
			sprItem.quantity = sprItem.quantity * groupParent.quantity;
		}
		if (includeSprs === true) {
			items.push(sprItem);
		}
		sprItem.itemLineReferences = [];
		cartItem.childItems.forEach(subItem => {
			var childItem = createItem(subItem);
			childItem.quantity = childItem.quantity * sprItem.quantity;
			items.push(childItem);
			sprItem.itemLineReferences.push(childItem.id);
		});
	}
	return items;
};

const createItem = shoppingCartItem => {
	let retailItem = shoppingCartItem.retailItem;

	return {
		ItemType: retailItem && retailItem.ItemType,
		Id: shoppingCartItem.id,
		//above two fields to b deprected and will remove
		itemType: retailItem && retailItem.ItemType,
		itemNo: shoppingCartItem.itemId,
		itemName: retailItem && retailItem.Name,
		id: shoppingCartItem.id,
		nbrOfParcels: retailItem && retailItem.NumberOfPackages,
		quantity: shoppingCartItem.quantity,
		itemUnitCode: retailItem && retailItem.ItemUnitCode === "PIECES" ? "Piece" : "Meter",
		refId: shoppingCartItem.id,
		isRefund: shoppingCartItem.isRefund,
		isOrderLine: shoppingCartItem.isOrderLine,
		soSalesItemNo: shoppingCartItem.soSalesItemNo,
		orderLineId: shoppingCartItem.isOrderLine ? shoppingCartItem.soSalesItemNo : shoppingCartItem.id,
		...shoppingCartItem,
		isAssemblyRequired: retailItem && retailItem.isAssemblyRequired
	};
};

export default cartItemsBuilder;
