import { loadMicroFrontEnds, registerGlobals } from "../../utils/shellObjectService";
import { AppLogger, GaHelper, LocaleHelper, RequestHelper } from "@coworker/sharedlibrary";
import { logStartup } from "../../logger/appLogger";
import LoadExternalAppAndUserConfiguration from "./loadExternalAppAndUserConfiguration";
import { UserType } from "../../store/auth/reducer";
import HomeService from "../../service/Home/homeService";
import { appactionCreators } from "../../store/shell/shellAction";
import {
	isExternalImportUrl,
	isExternalOrderServicesUrl,
	isExternalSearchUrl,
	isExternalCustomerUrl,
	isExternalReverseFlowUrl
} from "../../helpers/externalUrlHelper";
const LoadExternalUserConfig = async ({ setLogin, changeLocale, getTranslations, setUser, dispatch, sessionId }) => {
	setLogin(false);
	const user = RequestHelper.getLoggedInUser();

	let home = new HomeService();
	home.sessionId = sessionId;

	const apps = () => {
		if (isExternalImportUrl()) {
			return {
				pipApp: home.environment.microapps.pipApp,
				recentOrderApp: home.environment.microapps.recentOrderApp
			};
		} else if (isExternalCustomerUrl()) {
			return {
				customerProfileApp: home.environment.microapps.customerProfileApp
			};
		} else if (isExternalSearchUrl()) {
			return {
				pipApp: home.environment.microapps.pipApp,
				recentOrderApp: home.environment.microapps.recentOrderApp
			};
		} else if (isExternalOrderServicesUrl() || isExternalReverseFlowUrl()) {
			return {
				orderservices: home.environment.microapps.orderservices,
				customerProfileApp: home.environment.microapps.customerProfileApp
			};
		} else return home.environment.microapps;
	};

	//Set Home Context for users comming from external app

	let locationSearch = new URLSearchParams(window.location.search);
	let buCode = locationSearch.get("buCode") || "895";
	let overidedlocale = locationSearch.get("locale") || "en-GB";
	let countryCode = locationSearch.get("countryCode") || "GB";
	const sourceSystem = locationSearch.get("sourceSystem");
	if (sourceSystem) {
		dispatch(appactionCreators.setCallingApplication(sourceSystem));
	}
	if (locationSearch.get("clientid")) {
		//external client id : shared component scenario
		home.sessionId = locationSearch.get("clientid") || localStorage.getItem("sessionId");
		dispatch(appactionCreators.setHomeContext(home));
	} else {
		//will remove this soon
		if (localStorage.getItem("sessionId")) {
			home.sessionId = locationSearch.get("clientid") || localStorage.getItem("sessionId");
			dispatch(appactionCreators.setHomeContext(home));
		}
	}

	home.initConfig()
		.then(async () => {
			const { googleAnalyticsId } = home.environment;
			registerGlobals();
			//Load relevant MFE´s
			await loadMicroFrontEnds(apps());

			// eslint-disable-next-line no-undef
			if (process.env.NODE_ENV === "development") {
				//for test use this only
				GaHelper.InitGA("UA-147111709-1");
			} else {
				GaHelper.InitGA(googleAnalyticsId);
			}
			if (localStorage.getItem("x-user-id") && user) {
				user.ikealegacyuid = localStorage.getItem("x-user-id");
			}
			if (user !== undefined && user.ikealegacyuid !== "") {
				logStartup(user.ikealegacyuid, home.sessionId, true);

				if (buCode && overidedlocale) {
					let primaryLocale = overidedlocale.includes("xx-XX") ? `en-${countryCode}` : overidedlocale;

					const countryCodeDifferent = LocaleHelper.getCountryCode(overidedlocale) !== countryCode;
					if (countryCodeDifferent) {
						primaryLocale = `en-${countryCode}`;
					}
					let preferences = [];
					//Explict get user Privileges
					const prvilages = await home.getUserPrivileges(buCode);
					preferences = await home.getappPreferrences(buCode, countryCode);

					const userInfo = {
						userId: user.ikealegacyuid,
						buCode: buCode,
						primaryLocale: primaryLocale,
						supportedLocales: [primaryLocale],
						countryCode: countryCode,
						privileges: prvilages,
						preferences: preferences
					};
					home.coworkerData = userInfo;
					const userType = UserType.SALESPERSON;
					LoadExternalAppAndUserConfiguration({
						userInfo,
						userType,
						home,
						changeLocale,
						getTranslations,
						setUser,
						dispatch
					});
				} else {
					home.getUserInfo(user.ikealegacyuid, buCode, true)
						.then(async userInfo => {
							if (userInfo && !userInfo.code) {
								const userType = UserType.SALESPERSON;
								LoadExternalAppAndUserConfiguration({
									userInfo,
									userType,
									home,
									changeLocale,
									getTranslations,
									setUser,
									dispatch
								});
							}
						})
						.catch(error => {
							AppLogger.error(`LoadExternalAppAndUserConfiguration failed :${error.message}`, "LoadExternalAppAndUserConfiguration", user);
						});
				}
			}
		})
		.catch(error => {
			AppLogger.error(`HomeService initConfig failed :${error.message}`, "HomeService initConfig failed", user);
		});
};
export default LoadExternalUserConfig;
