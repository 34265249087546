import styled from "styled-components";

import { colors, device } from "@coworker/sharedlibrary";

import { AccordionItem } from "@ingka/accordion";
import { List } from "./../../../../components/sharedlibrary";
import QuantityField from "./../../../../components/shared/quantityField";

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  font-size: 12px;
  padding: 20px;
  border-top 1px solid ${colors.grey200};

  @media ${device.large} {
    padding: 20px;
  }

  @media ${device.medium} {
    padding: 20px 12px;
  }
`;

export const TopSection = styled.section`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 30px;

	@media ${device.large} {
		max-width: 80%;
	}
`;

export const Description = styled.div`
	width: ${props => props.width || "100%"};
	padding: ${props => props.padding || "0px"};

	@media ${device.small} {
		width: 100%;
	}

	@media ${device.large} {
		min-height: 64px;
		padding: 0;
	}
`;

export const Name = styled.h3`
	line-height: 1em;
`;

export const ItemDesc = styled.small`
	color: ${colors.colourTextDefault};
	width: 100%;
	max-width: 65%;
	height: ${props => props.height || "auto"};
	display: block;
	overflow: hidden;
	word-wrap: break-word;
	text-overflow: ellipsis;
`;

export const WarningText = styled.div`
	width: 120px;
	color: ${colors.red};
	font-size: 12px;
	line-height: 1em;
	margin-top: 8px;
`;

export const QuantityPrice = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-self: ${props => props.start || "flex-start"};

	span {
		color: ${colors.black} !important;
	}

	@media ${device.large} {
		&.wide {
			display: none;
		}
	}

	&.narrow {
		display: none;

		@media ${device.large} {
			display: block;
		}
	}
`;

export const PriceContainer = styled(List)`
	&.price-container {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		margin: ${props => props.margin || "0"};
		padding-right: ${props => props.padding || "5px"};

		&.multiplePrices {
			.normalPrice:not(.familyPrice) {
				font-size: 14px;
				font-weight: normal;
			}
		}

		@media ${device.large} {
			font-size: 14px;
		}
	}
`;

export const MiddleSection = styled.section`
	width: calc(100% + 10px);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 0 0 0 -10px;

	@media ${device.large} {
		width: 100%;
		margin: 10px 0 0 0;
	}
`;

export const StockSection = styled.div`
	width: auto;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding-left: 30px;

	@media ${device.large} {
		&.wide {
			display: none;
		}
	}

	&.narrow {
		display: none;

		@media ${device.large} {
			width: 100%;
			display: flex;
			padding-left: 0;
		}
	}
`;

export const LocationInfo = styled.div`
	width: auto;
	font-size: 12px;
`;

export const ResponsiveWidth = styled.div`
	width: ${props => props.width};
`;

export const QuantityComponent = styled(QuantityField)`
	input {
		border-radius: 28px !important;
	}
`;

export const CartAccordionItem = styled(AccordionItem)`
	.accordion__heading {
		padding: 0 20px !important;

		* {
			font-size: 12px;
		}
	}

	.accordion-item-header {
		border-top: none !important;
	}

	.accordion__content {
		padding-bottom: 0;
	}
`;

export const FoodStoreInfoContainer = styled.div`
	color: ${colors.grey800};
	font-size: 12px;
`;
