import AppLogger from "../../logger/appLogger";
import { useSelector } from "react-redux";
import React from "react";

import { getStoreConfigSelector } from "../../utils/shellObjectService";
import useMicrofrontend from "../../hooks/useMicroFrontend";
import SalesCoworkerConfiguration from "../../store/salesCoworkerConfiguration";

const ProductInformationView = props => {
	let { items } = useSelector(state => state.cartController);

	let appConfig = getStoreConfigSelector();
	let config = { ...appConfig };
	let { appId, appUrl, appName } = config.environment.microapps && config.environment.microapps.pipApp;

	const { isLoaded, pip } = useMicrofrontend(appId, appUrl, appName);
	// 1104359950
	const escFunctionCallback = React.useCallback(e => {
		if (SalesCoworkerConfiguration.isExternal()) {
			if (e.keyCode === 27) {
				window.sendToNative({ componentName: "pip", action: "CANCEL" });
				AppLogger.info("PIP view closed on EscapeKey event", "ClosePIPView", {
					KeyInterept: "ESC",
					Success: 200
				});
			}
		}
	}, []);
	React.useEffect(() => {
		document.addEventListener("keydown", escFunctionCallback, false);
		return () => {
			document.removeEventListener("keydown", escFunctionCallback, false);
		};
	}, []);
	React.useEffect(() => {
		pip && pip.setCartItems(items);
	}, [items]);
	React.useEffect(() => {
		if (!pip) return;
		config.view = props.view || "pip";

		config.itemId = props.match.params.id.substring(3, props.match.params.id.length);
		config.itemType = props.match.params.id.substring(0, 3);
		config.quantity = props.match.params.quantity;
		pip.SetSharedConfiguration(config);
		const { render, unMount } = pip;

		render(`pip-${appId}`, config);

		return () => {
			unMount(`pip-${appId}`);
		};
	}, [isLoaded, props.location]); // eslint-disable-line

	if (!isLoaded) return null;
	if (!pip) return <div />;

	return <div id={`pip-${appId}`} />;
};

export { ProductInformationView };
