const getMessage = (key, messages, defaultvalue) => {
	if (messages) {
		if (messages[key] && messages[key] !== "") {
			return messages[key];
		} else {
			return defaultvalue;
		}
	} else return defaultvalue;
};
export default getMessage;
