import { useSelector } from "react-redux";
import React from "react";
import useMicrofrontend from "../../../hooks/useMicroFrontend";
import { getStoreConfigSelector } from "../../../utils/shellObjectService";

const SharedOpenOrderView = props => {
	const { searchData } = useSelector(state => state.importController);

	let config = getStoreConfigSelector();

	let { appId, appUrl, appName } = config.environment.microapps.recentOrderApp;

	const { isLoaded, recentorder } = useMicrofrontend(appId, appUrl, appName);

	React.useEffect(() => {
		if (!recentorder) return;
		config.view = "orderview";
		config.exitToHome = true;
		config.order = searchData.data;
		config.compactMode = true;
		config.isStandalone = true;
		recentorder.SetSharedConfiguration(config);
		const { render, unMount } = recentorder;

		render(appId, config);

		return () => {
			unMount(appId);
		};
	}, [isLoaded, props.location]); // eslint-disable-line

	if (!isLoaded) return <div />;
	if (!recentorder) return <div />;
	return <div id={appId} />;
};
export default SharedOpenOrderView;
