import Enumerable from "linq";
import moment from "moment";
import { APIEndpoints, LocaleHelper, OrderTypes, SalesOrderItemsBuilder, SharedConfiguration, URI, httpService } from "@coworker/sharedlibrary";

import SalesCoworkerConfiguration from "../../store/salesCoworkerConfiguration";
import { getInternationalMobileFormat } from "./../../helpers/cartHelper";

class SalesOrderService {
	constructor() {
		this.config = SalesCoworkerConfiguration.getUserConfig();
	}
	createStoreOrder = async request => {
		const { buCode, countryCode, customerSchema } = this.config;

		let baseUrl = SharedConfiguration.getApiBaseUrl("cartservices");
		let url = new URI(`${baseUrl}${APIEndpoints.orderCapture}`, true).fill({
			orderType: "sales-order"
		});
		const { isMfsProfile } = customerSchema;

		let data = createSalesOrderRequest(countryCode, buCode, isMfsProfile, request);
		let orderRequest = {
			request: data,
			externalReference: request.externalReference
		};
		let order = await httpService.post(url, {
			body: orderRequest,
			headers: this.config.headers
		});

		let delivery = request.services.find(x => x.isDelivery);

		order.customer = {
			email: request.customer.email,
			mobilePhone: request.customer.mobilePhone
		};
		order.orderItems = request.cartItems;
		order.proposal = {
			bookedServices: delivery !== undefined ? [delivery] : [],
			hasDelivery: delivery !== undefined
		};
		order.type = OrderTypes.ServiceOrder;
		return order;
	};
}

// eslint-disable-next-line no-unused-vars
const createSalesOrderRequest = (countryCode, buCode, isMfsProfile, salesrequest) => {
	let cartItems = salesrequest.cartItems;
	let drawings = salesrequest.drawings;
	let services = salesrequest.services;
	let customer = salesrequest.customer;
	let customerBilling = salesrequest.customerBilling;
	//TODO : Refactor activeZipCode
	customer.zipCode = salesrequest.activeZipCode || customer.zipCode;
	customer.countryCode = countryCode;
	let allItems = SalesOrderItemsBuilder.createItems(cartItems);

	// eslint-disable-next-line no-unused-vars
	let request = {};
	let groups = cartItems.filter(x => x.isCombination);
	let planners = cartItems.filter(x => x.itemType === "PLANNER");

	if (planners && planners.length > 0) {
		request.planners = [];

		planners.forEach(grp => {
			let plnItem = {
				plannerName: grp.name,
				seqNo: grp.id,
				itemReferences: []
			};
			plnItem.itemReferences = grp.childItems.map(chid => {
				return {
					lineId: chid.id
				};
			});
			request.planners.push(plnItem);
		});
	}

	if (drawings && drawings) {
		request.drawings = drawings;
	}
	if (groups && groups.length > 0) {
		request.groups = [];
		groups.forEach(grp => {
			let grpItem = {
				groupNo: grp.id,
				groupName: grp.name,
				id: grp.id,
				seqNo: grp.id,
				referencedItems: []
			};
			grpItem.referencedItems = grp.childItems.map(chid => {
				return {
					itemReferenceId: chid.id,
					seqNo: chid.id
				};
			});
			request.groups.push(grpItem);
		});
	}
	request.orderCreationMethod = salesrequest.orderCreationMethod || "STORE";
	request.items = createItems(allItems);
	request.buCode = buCode;
	request.countryCode = countryCode;
	let delivery = Enumerable.from(services).firstOrDefault(x => x.isDelivery);
	let providedServices = Enumerable.from(services)
		.where(x => x.isProvidedService)
		.toArray();

	if (providedServices) {
		let servicesforDelivery = [];
		servicesforDelivery = mapProvidedServices(providedServices, allItems);
		if (salesrequest.selectedPickservice && delivery) {
			let picking = getPickingService(salesrequest.selectedPickservice, delivery.referenceItems, allItems);
			servicesforDelivery.push(picking);
		}
		request.providedServices = servicesforDelivery;
	}
	if (delivery) {
		request.deliveryServices = mapDeliveryServices([delivery], allItems);
	}
	if (customer) {
		request.deliveryContact = mapdeliveryContact(customer, request.countryCode);
		request.billingContact = mapdeliveryContact(customerBilling, customerBilling.foreignAddressCountryCode || request.countryCode);
	}
	if (isMfsProfile) {
		request.customerProfile = getCustomerProfile(customer, countryCode);
	}
	request.handoverLocation = "CUST_PLACE";
	if (salesrequest.questionaireData && salesrequest.questionaireData.deliveryQuestions && salesrequest.questionaireData.deliveryQuestions.length > 0) {
		request.questionnaire = mapDeliveryQuestionnaire(salesrequest.questionaireData);
	}
	request.questionnaires = mapQuestionnaires(salesrequest.questionaireData);
	request.familyCustomer = customer.ikeaFamilyNumber && customer.ikeaFamilyNumber !== "";

	if (!isMfsProfile) {
		request.customer = mapIcmCustomer(customer, customerBilling);
	}

	// let planners = getPlanners(cartItems, request.items);
	// if (planners.length > 0) {
	//   request.planners = planners;
	// }

	if (salesrequest.notifications && salesrequest.notifications.length > 0) {
		request.notifications = salesrequest.notifications;
	}
	return request;
};

const getPickingService = (picking, itemDeliveryReferences, items) => {
	try {
		let request = {
			mandatory: true,
			price: {
				currencyCode: picking.price.currencyCode,
				priceInclTax: picking.price.priceInclTax,
				priceExclTax: picking.price.priceExclTax
			},
			priceCalculation: picking.priceCalculationValue,
			serviceProductId: picking.serviceProductId,

			taxDetails: picking.price.taxDetails,
			capacityNeeded: picking.capacityNeeded.toString(),
			capacityTemplate: picking.capacityTemplate,
			capacityTemplateList: picking.capacityTemplateList,
			capacityUnit: picking.capacityUnit,
			serviceName: picking.serviceName,
			serviceType: picking.serviceType,
			itemReferences: Enumerable.from(itemDeliveryReferences)
				.select(function (item) {
					let delitem = items.find(x => x.refLineId.toString() === item.serviceReference.id.toString());
					return {
						lineId: delitem.lineId
					};
				})
				.toArray()
		};

		return request;
	} catch (error) {
		//
	}
};

const mapIcmCustomer = (customer, customerBilling) => {
	const config = SalesCoworkerConfiguration.getUserConfig();
	const { countryCode } = config;
	let prefferedlocale = LocaleHelper.formatLocaleIso(customer.preferredLocale);
	let internationalNumber = getInternationalMobileFormat(customer.mobilePhone, countryCode);
	let request = {
		customerType: customer.isBusinessCustomer ? "BUSINESS" : "PRIVATE",
		globalCustomerId: customer.partyId
	};

	if (customer.isBusinessCustomer) {
		request.businessCustomer = {
			businessCustomerDeliveryInfo: {
				businessCustomerBusinessInfo: {
					businessName: customer.businessName,
					organisationNo: customer.organizationNo
				},
				businessCustomerPersonalInfo: getCustomerPersonalInfo(customer, prefferedlocale),
				businessCustomerDeliveryAddress: getCustomerDeliveryAddress(customer),
				businessCustomerCommunication: {
					email: customer.email,
					mobile: internationalNumber,
					phone: customer.landlinePhone
				}
			}
		};
	} else {
		request.privateCustomer = {
			privateCustomerDeliveryInfo: {
				privateCustomerPersonalInfo: getCustomerPersonalInfo(customer, prefferedlocale),
				privateCustomerDeliveryAddress: getCustomerDeliveryAddress(customer),
				privateCustomerCommunication: {
					email: customer.email,
					mobile: internationalNumber,
					phone: customer.landlinePhone
				}
			},
			privateCustomerBillingInfo: {
				privateCustomerPersonalInfo: getCustomerPersonalInfo(customerBilling, prefferedlocale),
				privateCustomerBillingAddress: getCustomerDeliveryAddress(customerBilling),
				privateCustomerCommunication: {
					email: customerBilling.email,
					mobile: getInternationalMobileFormat(customerBilling.mobilePhone, customerBilling.foreignAddressCountryCode),
					phone: customerBilling.landlinePhone
				}
			}
		};
	}
	return request;
};
const getCustomerProfile = (customer, countryCode) => {
	if (customer === null) return null;
	let prefferedlocale = LocaleHelper.formatLocaleIso(customer.preferredLocale);
	let customerProfile = {
		customerType: customer.isBusinessCustomer ? "BUSINESS" : "PRIVATE",
		preferredLocale: prefferedlocale,
		familyNumber: customer.ikeaFamilyNumber,
		registeredType: customer.partyId ? "REGISTERED" : "GUEST",
		globalCustomerId: customer.partyId
	};

	//TODO: Only Russia for MFS profile
	if (countryCode === "RU") {
		customerProfile.familyNumber = undefined;
		customerProfile.registeredType = "GUEST";
		customerProfile.globalCustomerId = undefined;
	}

	return customerProfile;
};

const mapDeliveryQuestionnaire = cart => {
	let request = {};
	request.questionnaireType = cart.selectedDeliveryQuestionnaireType;
	let answers = [];

	cart.deliveryQuestions.forEach(que => {
		let answer = cart.questionaireAnswers.find(x => x.reference === que.reference);
		if (answer) {
			let questionAnswer = {
				questionReference: answer.reference,
				option: answer.option === "UNDEFINED" ? "FREE_TEXT" : answer.option,
				textValue: answer.freeText ? answer.freeText : ""
			};

			answers.push(questionAnswer);
		}
	});
	request.questionAnswers = answers;
	return request;
};
const mapQuestionnaires = cart => {
	let dtoQuestionnaires = [];
	if (cart.deliveryQuestions && cart.deliveryQuestions.length > 0) {
		dtoQuestionnaires.push(mapDeliveryQuestionnaire(cart));
	}

	if (cart.serviceQuestions && cart.serviceQuestions.length > 0) {
		let serviceTypequestions = Enumerable.from(cart.serviceQuestions)
			.groupBy(x => x.serviceType)
			.select(function (grp) {
				return {
					serviceType: grp.key(),
					questions: grp.toArray()
				};
			});
		serviceTypequestions.forEach(item => {
			let request = {};
			request.questionnaireType = item.serviceType;
			let answers = [];
			item.questions.forEach(que => {
				let answer = cart.questionaireAnswers.find(x => x.reference === que.reference);
				if (answer) {
					let questionAnswer = {
						questionReference: answer.reference,
						option: answer.option === "UNDEFINED" ? "FREE_TEXT" : answer.option,
						textValue: answer.freeText ? answer.freeText : ""
					};

					answers.push(questionAnswer);
				}
			});
			request.questionAnswers = answers;
			dtoQuestionnaires.push(request);
		});
	}
	return dtoQuestionnaires;
};

const mapdeliveryContact = (customer, countryCode) => {
	if (customer === null) return null;

	let contact = {
		firstName: customer.firstName,
		lastName: customer.lastName,
		phoneticName: customer.phoneticName,
		emailAddress: customer.email,
		mobilePhoneNo: getInternationalMobileFormat(customer.mobilePhone, countryCode),
		landlinePhoneNo: customer.landlinePhone,
		address: mapCustomerAddress(customer, countryCode),
		taxCodeType: customer.taxIdType && customer.taxIdType !== undefined && customer.taxIdType.includes("NA-") ? "NA" : customer.taxIdType,
		taxNo: customer.taxNo,
		foreignCountry: customer.foreignCountry,
		secondSurname: customer.secondSurname,
		recipientCodeType: customer.recipientCodeType,
		recipientCode: customer.recipientCode
	};

	if (customer.isBusinessCustomer) {
		//B2B
		contact.businessName = customer.businessName;
		contact.organisationNo = customer.organizationNo;
		contact.businessCardNo = customer.businessCardNo;
		contact.phoneticBusinessName = customer.phoneticBusinessName;
		contact.sezType = customer.sezType;
		contact.sezValidityFrom = customer.sezType !== "NO" ? getSezDate(customer.sezValidityFrom) : "";
		contact.sezValidityTo = customer.sezType !== "NO" ? getSezDate(customer.sezValidityTo) : "";
	}

	return contact;
};

const mapCustomerAddress = (customer, countryCode) => {
	let address = {
		addressLine1: customer.addressLine1,
		addressLine2: customer.addressLine2,
		addressLine3: customer.addressLine3,
		attention: customer.attention,
		careOf: customer.careOf,
		countyName: customer.county !== undefined ? customer.county.trim() : "",
		stateCode: customer.stateCode !== undefined ? customer.stateCode.trim() : "",
		zipCode: customer.zipCode !== undefined ? customer.zipCode.trim() : "",
		cityName: customer.city !== undefined ? customer.city.trim() : "",
		countryCode: customer.foreignAddressCountryCode || (customer.countryCode ? customer.countryCode.trim() : countryCode),
		prefecture: customer.prefecture,
		additionalInfo: customer.additionalInfo,
		streetNo: customer.streetNo
	};

	return address;
};
// eslint-disable-next-line no-unused-vars
const mapProvidedServices = (providedServices, items) => {
	let orderServices = [];
	providedServices.forEach(x => {
		let service = {
			mandatory: x.mandatory,
			price: {
				currencyCode: x.price.currencyCode,
				priceInclTax: x.price.priceInclTax,
				priceExclTax: x.price.priceExclTax
			},
			priceCalculation: x.priceCalculation,
			serviceProductId: x.serviceProductId,
			taxDetails: x.price.taxDetails,
			capacityNeeded: x.capacityNeeded.toString(),
			capacityTemplate: x.capacityTemplates,
			capacityTemplatesList: Enumerable.from(x.capacityTemplates)
				.select(function (x) {
					return {
						id: x
					};
				})
				.toArray(),
			capacityUnit: x.capacityUnit,
			serviceName: x.serviceName,
			serviceProviderId: x.serviceProviderId,
			serviceProviderName: x.serviceProviderName,
			servicePaymentTo: x.serviceProviderPreferredPayment,
			serviceTimeWindow: x.isPickingService
				? null
				: {
						serviceTimeWindowParts: Enumerable.from(x.timeWindows)
							.select(function (time) {
								return {
									capacityAllocated: x.capacityNeeded.toString(),
									dateTimeFrom: time.fromDateTime,
									dateTimeTo: time.toDateTime,
									timeWindowId: time.id
								};
							})
							.toArray()
				  },
			serviceType: x.serviceType,
			itemReferences: Enumerable.from(x.referenceItems)
				.select(function (item) {
					let delitem = items.find(x => x.refLineId.toString() === item.serviceReference.lineId.toString());
					return {
						lineId: delitem.lineId
					};
				})
				.toArray()
		};

		orderServices.push(service);
	});
	return orderServices;
};
// eslint-disable-next-line no-unused-vars
const mapDeliveryServices = (deliveryServices, items) => {
	let orderServices = [];
	deliveryServices.forEach(delivery => {
		let tax = Enumerable.from(delivery.price.taxDetails).firstOrDefault();

		let request = {
			deliveryMethod: delivery.deliveryMethod,
			timeWindows: Enumerable.from(delivery.timeWindows)
				.select(function (time) {
					return {
						id: time.id,
						deliveryDateTimeFrom: time.fromDateTime,
						deliveryDateTimeTo: time.toDateTime,
						cutOffDateTime: time.cutOffDateTime,
						serviceProviderId: time.serviceProviderId,
						serviceProviderName: time.serviceProviderName,
						capacityAvailable: time.capacityAvailable,
						price: {
							currencyCode: delivery.price.currencyCode,
							priceExclTax: delivery.price.priceExclTax,
							priceInclTax: delivery.price.priceInclTax
						},
						taxCode: tax.taxCode,
						taxPercentage: tax.taxPercentage
					};
				})
				.toArray(),
			itemReferences: Enumerable.from(delivery.referenceItems)
				.select(function (item) {
					let delitem = items.find(x => x.refLineId.toString() === item.serviceReference.id.toString());
					return {
						lineId: delitem.lineId
					};
				})
				.toArray()
		};

		orderServices.push(request);
	});
	return orderServices;
};
const createItems = allItems => {
	let items = [];
	allItems.forEach(item => {
		items.push({
			itemId: item.itemNo,
			lineId: item.lineId,
			itemReferenceId: item.refLineId,
			qty: item.quantity,
			seqNo: item.seqNo,
			refLineId: item.refLineId,
			itemType: item.itemType,
			qtyType: item.qtyType === "Piece" ? "PIECES" : "METER",
			itemName: item.itemName,
			itemReferences: item.itemReferences && item.itemReferences.length > 0 ? item.itemReferences : null
		});
	});

	return items;
};

const getCustomerPersonalInfo = (customer, prefferedlocale) => {
	return {
		genderCode: customer.genderCode,
		phoneticName: customer.phoneticName,
		phoneticFirstName: customer.phoneticFirstName,
		phoneticLastName: customer.phoneticLastName,
		title: customer.title,
		lastName: customer.lastName,
		firstName: customer.firstName,
		customerLocale: prefferedlocale,
		familyNo: customer.ikeaFamilyNumber,
		language: customer.language,
		middleName: customer.middleName,
		pan: customer.countryCode === "IN" ? customer.panNo : undefined,
		foreignCountry: customer.foreignCountry,
		secondSurname: customer.secondSurname,
		phoneticBusinessName: customer.phoneticBusinessName,
		recipientCodeType: customer.recipientCodeType,
		recipientCode: customer.recipientCode,
		taxCodeType: customer.taxIdType && customer.taxIdType !== undefined && customer.taxIdType.includes("NA-") ? "NA" : customer.taxIdType,
		taxCode: customer.taxNo,
		taxIdentifierNo: customer.taxIdentifierNo ? customer.taxIdentifierNo : customer.certificateNo
	};
};

const getCustomerDeliveryAddress = customer => {
	return {
		addressLine1: customer.addressLine1,
		addressLine2: customer.addressLine2,
		addressLine3: customer.addressLine3,
		prefecture: customer.prefecture,
		zipCode: customer.zipCode,
		stateCode: customer.stateCode,
		countryCode: customer.foreignAddressCountryCode || customer.countryCode,
		city: customer.city,
		attention: customer.attention,
		careOf: customer.careOf,
		countyName: customer.county
	};
};

const getSezDate = value => {
	if (value) {
		return moment(value, "YYYY-MM-DD").format("YYYY-MM-DDTHH:mm:ss");
	}

	return value;
};

export default SalesOrderService;
