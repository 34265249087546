import { DfpKey } from "@coworker/sharedlibrary";
import SalesCoworkerConfiguration from "../salesCoworkerConfiguration";
import { createSelector } from "reselect";

const preferencesSelector = state => state.appController.preferences;

export const isISomEnabledSelector = createSelector(preferencesSelector, preferences => {
	if (preferences !== undefined) {
		var pref = preferences.find(x => x !== undefined && x.name === DfpKey.ISOM_ALLOWED_USERS);
		var { userId, environment } = SalesCoworkerConfiguration.getUserConfig();
		var users = environment.devList;
		if (pref && pref.value) {
			users = users.concat(pref.value);
		}
		var allUsers = users.map(x => x.toLowerCase());
		console.log(userId);
		return allUsers.includes("*") || allUsers.includes(userId.toLowerCase());
	}
	return false;
});

export const isSterlingEnabledSelector = createSelector(preferencesSelector, preferences => {
	if (preferences !== undefined) {
		var pref = preferences.find(x => x !== undefined && x.name === DfpKey.PREFFERED_ORDER_MANAGEMENT_INTEGRATION_POINT);
		return pref && pref.value === "STERLING";
	}
	return false;
});
