const imperialUnitCountryList = ["US", "CA", "GB"];
const speCountryList = [
	"HU",
	"SI",
	"SE",
	"RS",
	"JP",
	"AU",
	"BE",
	"DE",
	"FI",
	"FR",
	"GB",
	"HR",
	"IE",
	"IT",
	"NL",
	"NO",
	"PL",
	"SK",
	"CA",
	"ES",
	"RU",
	"AT",
	"BE",
	"RO",
	"CZ",
	"DK",
	"PT",
	"CH",
	"KR",
	"UK",
	"US"
];

const rangeCategoryList = ["IN"];

const usesStateSpec = countryCode => {
	return countryCode === "US" || countryCode === "AU" || countryCode === "CA" || countryCode === "IN";
};

const stateMandtorySpec = countryCode => {
	return countryCode === "US" || countryCode === "AU" || countryCode === "CA";
};

const isIndiaSpec = countryCode => {
	return countryCode === "IN";
};

const usesStateForProposalSpec = countryCode => {
	return countryCode === "US";
};
const usesStateForIsom = countryCode => {
	return countryCode === "US" || countryCode === "CA";
};

const usesIndicativeTaxForIsom = countryCode => {
	return countryCode === "US" || countryCode === "CA" || countryCode === "RU" || countryCode === "HU" || countryCode === "SI";
};
const usesStateCodeForIndicativeTaxIsom = countryCode => {
	return countryCode === "US" || countryCode === "CA";
};

const useDeviatingShipmentIsom = countryCode => {
	return countryCode === "CA" || countryCode === "US";
};

const usesExclPriceMarket = countryCode => {
	return countryCode === "CA" || countryCode === "US";
};

const useTaxIdSpec = countryCode => {
	return countryCode === "ES" || countryCode === "PT" || countryCode === "SI";
};

const isPlannerSpec = plannerCode => {
	if (plannerCode.includes("@")) {
		return false;
	}
	var re = /.*?(?:[a-zA-Z].*?[0-9]|[0-9].*?[a-zA-Z]).*?/;
	var code = plannerCode.trim().toUpperCase();
	if (!code.includes(" ") && code.length >= 5 && code.length <= 8 && (re.test(code) || /^[A-Za-z]+$/.test(code))) {
		return true;
	}
	return false;
};

const isorderNumberSpec = number => {
	var re = /^[0-9]*$/;

	return !number.startsWith("0") && (number.length === 9 || number.length === 10 || number.length === 11) && re.test(number);
};

const isNumberSpec = number => {
	return !isNaN(number);
};

const isArticleNumberSpec = value => {
	return value.length === 8 && isNumberSpec(value);
};

const usesMetricCountrySpec = countryCode => {
	return !imperialUnitCountryList.includes(countryCode);
};

const usesPrefectureSpec = countryCode => {
	return countryCode === "JP";
};

const usesSpeCalcSpec = countryCode => {
	return speCountryList.includes(countryCode.toUpperCase());
};

const isKitchenPlannerSpec = (query, countryCode) => {
	return isKitchenPlannerIhp2Spec(query) || isKitchenPlannerIhp3Spec(query) || isCiamChinaSpec(query, countryCode);
};
const isKitchenPlannerIhp2Spec = query => {
	let value = query.replace(/-/gi, "");

	return (
		/[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/gim.test(value.trim()) ||
		(/^[a-zA-Z0-9_-]{9,11}$/.test(query) && !isNumberSpec(value)) ||
		(/[0-9]{4}?[0-9]{4}?[0-9]{4}/gim.test(value.trim()) && value.length === 12)
	);
};

const isKitchenPlannerIhp3Spec = query => {
	let value = query.replace(/-/gi, "");
	return (
		/[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/gim.test(value.trim()) ||
		(/[A-Z0-9]{8}?[A-Z0-9]{4}?[A-Z0-9]{4}-?[A-Z0-9]{4}-?[A-Z0-9]{12}/gim.test(value.toUpperCase().trim()) && value.length === 32)
	);
};

const isChinaSpec = countryCode => {
	return countryCode === "CN";
};

const isQuotationSpec = value => {
	return value.startsWith("Q-") || value.startsWith("q-");
};

const usesRangeCategorySpec = (value, rangeEnabledCountries) => {
	return (rangeEnabledCountries || rangeCategoryList).includes(value.toUpperCase());
};

const isICMCustomerIdentifierSpec = countryCode => {
	if (
		countryCode === "IN" ||
		countryCode === "PT" ||
		countryCode === "ES" ||
		countryCode === "SI" ||
		countryCode === "IT" ||
		countryCode === "SK" ||
		countryCode === "RO"
	)
		return true;

	return false;
};

const isUniqueCustomerCheckSpec = countryCode => {
	return countryCode === "SE" || countryCode === "ES" || countryCode === "PT";
};

const isTaxIdSpec = countryCode => {
	return countryCode === "ES" || countryCode === "PT" || countryCode === "SI" || countryCode === "IT" || countryCode === "RS" || countryCode === "RO";
};
const isFoodItemSpec = itemStoreInfo => {
	if (itemStoreInfo !== undefined && itemStoreInfo.PaNumber !== undefined && itemStoreInfo.PaNumber.includes("961")) return true;

	return false;
};
const isIkeaFoodHfbItemSpec = itemStoreInfo => {
	if (
		itemStoreInfo !== undefined &&
		itemStoreInfo.PaNumber !== undefined &&
		(itemStoreInfo.PaNumber.includes("961") || itemStoreInfo.PaNumber.includes("966"))
	) {
		return true;
	}

	return false;
};
// eslint-disable-next-line no-unused-vars
export const isReceiptSpec = (value, buCode) => {
	let query = value.toUpperCase();
	return (
		isCustomReceiptSpec(query) ||
		(isNumberSpec(query) && query.length === 18) ||
		query.startsWith(`0${buCode}`) ||
		query.startsWith(`00${buCode}`) ||
		query.startsWith(`9900${buCode}`) ||
		query.startsWith("REC") ||
		query.startsWith(`REC0${buCode}`) ||
		query.startsWith("9900") ||
		query.startsWith("990")
	);
};

// eslint-disable-next-line no-undef
const isCustomReceiptSpec = query => {
	let temp = query.replace(/ /gi, "");
	if (isNumberSpec(temp) && query.includes(" ")) {
		return true;
	}
	return false;
};

const isUsingDaumAPI = countryCode => {
	return countryCode === "KR";
};
const isCiamChinaSpec = (value, countryCode) => {
	//CIAM phone search
	if (countryCode === "CN" && value.length === 11 && /^[0-9]*$/.test(value)) {
		return true;
	}
	return false;
};

const isPlannerFeedBackSpec = countryCode => {
	if (countryCode === "IE" || countryCode === "AU" || countryCode === "US" || countryCode === "GB") return true;

	return false;
};

const isUpptackaCodeSpec = code => {
	let regex = new RegExp("^[a-zA-Z0-9_-]{5,6}$");
	return regex.test(code);
};
const isArticleLabel = code => {
	let query = code.toUpperCase();
	return query.startsWith("ART") || query.startsWith("QRT");
};

const checkIsArticleFromRdt = (e = "") => {
	const articleLbls = ["2", "9", "0"];

	if (e.length === 13 && isNumberSpec(e) && articleLbls.indexOf(e[12]) >= 0) {
		return e.substring(0, 8);
	} else if (e.length === 14 && isNumberSpec(e) && articleLbls.indexOf(e[13]) >= 0) {
		return e.substring(0, 8);
	}
};

const specHelpers = {
	usesStateSpec,
	isIndiaSpec,
	stateMandtorySpec,
	usesStateForProposalSpec,
	usesStateForIsom,
	usesIndicativeTaxForIsom,
	useDeviatingShipmentIsom,
	useTaxIdSpec,
	isPlannerSpec,
	isorderNumberSpec,
	isNumberSpec,
	isArticleNumberSpec,
	usesMetricCountrySpec,
	usesPrefectureSpec,
	usesSpeCalcSpec,
	isKitchenPlannerSpec,
	isKitchenPlannerIhp2Spec,
	isKitchenPlannerIhp3Spec,
	isChinaSpec,
	isQuotationSpec,
	usesRangeCategorySpec,
	isICMCustomerIdentifierSpec,
	isUniqueCustomerCheckSpec,
	isTaxIdSpec,
	isFoodItemSpec,
	isReceiptSpec,
	isCustomReceiptSpec,
	isIkeaFoodHfbItemSpec,
	isUsingDaumAPI,
	isPlannerFeedBackSpec,
	isCiamChinaSpec,
	usesExclPriceMarket,
	isUpptackaCodeSpec,
	usesStateCodeForIndicativeTaxIsom,
	isArticleLabel,
	checkIsArticleFromRdt
};
export default specHelpers;
