import React from "react";
import { useSelector } from "react-redux";
import { FeaturesList, device } from "@coworker/sharedlibrary";
import styled, { css } from "styled-components";
import Modal, { Sheets, ModalBody, ModalHeader } from "@ingka/modal";

import { OrderPriceSummaryView } from "../../orderpricesummary/orderPriceSummaryView";
import { DisCountView } from "../../discounts/discountsView";
import { useAllowedFeature } from "./../../../../../hooks/useAllowedFeature";
const ModalWindowContainer = styled.div`
  border-radius: 0px !important:
  .modal-body {
    overflow: auto !important;
  }
`;

const DiscountContainer = styled.div`
	padding: 0 0 80px;
	height: calc(100% - 80px);
	overflow-y: auto;

	${props =>
		props.isEditInFocus
			? css`
					@media ${device.small} {
						padding: 0px 20px 10px 20px;
						height: 100% !important;
						overflow-y: scroll !important;
					}
			  `
			: css`
					@media ${device.small} {
						padding: 0px 20px 80px 20px;
						height: calc(100% - 80px);
						overflow-y: scroll !important;
					}
			  `}
`;

const PriceAndDiscountDialog = ({ onClose }) => {
	const { messages } = useSelector(state => state.intl);
	const focus = {
		returnFocus: false,
		autoFocus: true
	};
	const [isEditInFocus, setIsEditInFocus] = React.useState(false);
	const { isModifyOrderinProgress, isTransportDeskMode, bookedServiceProposal } = useSelector(state => state.cartController);
	let isSalesDeskMode = !isTransportDeskMode;
	let showDiscount = useAllowedFeature(FeaturesList.DISCOUNT) && isSalesDeskMode;

	function notifyFocus(focus) {
		setIsEditInFocus(focus);
	}
	const { isCcdService } = bookedServiceProposal;
	return (
		<ModalWindowContainer>
			<Modal
				visible
				headers="ob"
				focusLockProps={focus}
				handleCloseBtn={() => {
					onClose();
				}}
			>
				<Sheets header={<ModalHeader title={messages.SG_LBL_OrderSummary || "Order summary"} />}>
					<ModalBody>
						<DiscountContainer isEditInFocus={isEditInFocus}>
							<OrderPriceSummaryView></OrderPriceSummaryView>
							{showDiscount && isCcdService && !isModifyOrderinProgress && <DisCountView notifyFocus={notifyFocus}></DisCountView>}
						</DiscountContainer>
					</ModalBody>
				</Sheets>
			</Modal>
		</ModalWindowContainer>
	);
};
export default PriceAndDiscountDialog;
