import { APIEndpoints, URI, httpService } from "@coworker/sharedlibrary";

import React from "react";
import SalesCoworkerConfiguration from "../store/salesCoworkerConfiguration";
import { useState } from "react";

function useFetchPreference(key, productName = "Salja") {
	const [preference, setPreference] = useState();
	React.useEffect(() => {
		const { buCode, locale, countryCode } = SalesCoworkerConfiguration.getUserConfig();

		const url = new URI(APIEndpoints.productConfig, true).fill({
			key,
			buCode,
			productName
		});

		let headers = {
			"x-request-locale": locale,
			"x-countrycode": countryCode
		};

		httpService
			.get(url, { headers: headers })
			.then(response => {
				if (response.dtoPreference) {
					setPreference(response.dtoPreference.value);
				}
			})
			.catch(() => {
				setPreference();
			});
	}, []);
	return preference;
}

export default useFetchPreference;
