import Enumerable from "linq";
import { DfpKey, cartItemsBuilder } from "@coworker/sharedlibrary";
import { LocaleHelper, ObjectHelper } from "@coworker/sharedlibrary";
import { ValidationHelper } from "@coworker/sharedlibrary";

import AppLogger from "../../logger/appLogger";
import SalesCoworkerConfiguration from "../../store/salesCoworkerConfiguration";
import { getCustomerProfileObject } from "../../utils/shellObjectService";

export const ITEMERRORCODES = {
	NOMETER: "NOMETER",
	NOSTOCK: "NOSTOCK",
	INVALIDPRICE: "INVALIDPRICE",
	NOTENOUGHSTOCK: "NOTENOUGHSTOCK",
	ITEMNOTSOLDINSTORE: "ITEMNOTSOLDINSTORE",
	DDS: "DDS",
	SALESSTOPPED: "SALESSTOPPED",
	CUSTOMERMISSING: "MISSINGCUSTOMER",
	QUESTIONNAIREMISSING: "QUESTIONNAIREMISSING",
	TRANSPORTDESK: "TRANSPORTDESK",
	MISSINGDELIVERY: "MISSINGDELIVERY",
	MISSING_CREDIT_INFO: "MISSING_CREDIT_INFO"
};

export const CanBeUsedOnSalesOrderValidator = candidate => {
	if (!candidate.retailItem) {
		return [];
	}
	let errors = [];
	try {
		if (candidate.retailItem.storeInfo === null || candidate.retailItem.storeInfo === undefined) {
			return [{ code: ITEMERRORCODES.NOSTOCK }];
		}
		if (candidate.retailItem && candidate.retailItem.ItemUnitCode !== "PIECES") {
			errors.push({
				code: ITEMERRORCODES.NOMETER,
				message: "Meter Items Not allowed"
			});
		}

		if (ObjectHelper.isEmpty(candidate.price)) {
			errors.push({
				code: ITEMERRORCODES.INVALIDPRICE,
				message: "Price is invalid"
			});
		}

		if (candidate.quantity > candidate.retailItem.availableStockToSell || candidate.retailItem.availableStockToSell <= 0) {
			errors.push({
				code: ITEMERRORCODES.NOTENOUGHSTOCK,
				message: `Not Enough Stock ${candidate.itemId}`
			});
		}

		if (candidate.retailItem.availableStockToSell <= 0 && candidate.retailItem.storeInfo.SalesMethodCode === "DDS") {
			errors.push({
				code: ITEMERRORCODES.DDS,
				message: `${candidate.itemId}DDS item only available for central delivery`
			});
		}

		if (
			!ObjectHelper.isEmpty(!candidate.retailItem.storeInfo.SalesRestrictions) &&
			!candidate.retailItem.storeInfo.SalesRestrictions.CanSellLocal &&
			candidate.retailItem.availableStockToSell <= 0
		) {
			errors.push({
				code: ITEMERRORCODES.ITEMNOTSOLDINSTORE,
				message: `DDS item only available for central delivery ${candidate.itemId}`
			});
		}

		if (!ObjectHelper.isEmpty(!candidate.retailItem.storeInfo.SalesRestrictions) && candidate.retailItem.storeInfo.SalesRestrictions.IsSaleStopped) {
			errors.push({
				code: ITEMERRORCODES.SALESSTOPPED,
				message: `Item sales stopped ${candidate.itemId}`
			});
		}
	} catch (error) {
		AppLogger.info(error.message, "CanBeUsedOnSalesOrderValidator", {
			candidate: candidate
		});
	}

	return errors;
};

/**
 * validates the order can be created or not
 *
 * @param {object} [state] use global state
 * @return {boolean} Returns order create or not
 *
 */

export const CanCreateSalesOrder = state => {
	const errors = GetCartErrors(state);
	return errors.length === 0;
};

export const CanBookService = state => {
	const errors = CanCreateServiceValidator(state);
	return errors.length === 0;
};

export const CanModifyOrder = state => {
	const { order } = state.cartController;

	if (order === undefined) {
		return true;
	}

	if (order.isQuotation) {
		return true;
	}

	if (order.isCcdService) {
		return !order.isPaid && !order.isCancelled;
	}
	return !order.isPaid && !order.isCancelled && !order.hasService;
};

export const CanModifyTimeWindow = state => {
	const { order } = state.cartController;

	if (order === undefined) {
		return true;
	}

	const hasOnlyIsomDelivery = order.orderType === "ISOM" && order.proposal && order.proposal.hasDelivery && !order.proposal.hasProvidedService;

	return !order.isPaid && !order.isCancelled && order.orderType === "ISOM" && hasOnlyIsomDelivery;
};

export const CanDeleteService = state => {
	const { order } = state.cartController;

	if (order === undefined) {
		return true;
	}

	return false;
};

export const CanAddItem = state => {
	const { order, isTransportDeskMode } = state.cartController;
	const { isCashLine } = SalesCoworkerConfiguration.getUserConfig();
	if (order === undefined) {
		if (isTransportDeskMode && !isCashLine) {
			return false;
		}

		return true;
	}

	if (order.isQuotation) {
		return true;
	}

	if (order.isCcdService) {
		if (isTransportDeskMode && !isCashLine) {
			return false;
		}
		return !order.isPaid && !order.isCancelled;
	}
	return !order.isPaid && !order.isCancelled && !order.hasService;
};

export const CanAddReceipt = state => {
	const { order } = state.cartController;

	if (order === undefined) {
		return true;
	}
	return order.canAddReceipt;
};

export const GetCartErrors = state => {
	let errors = CanCreateSalesOrderValidator(state);

	const { items, isTransportDeskMode, questionnaires, hasServices, bookedServiceProposal = {}, creditInfo, paymentType } = state.cartController;

	const { isMissingDeliveryForNewItems = false } = bookedServiceProposal;

	let questionNeeded = false;

	if (questionnaires) {
		questionNeeded = questionnaires.questionNeeded;
	}

	const { locale } = state.localeController;
	const { customerSchema } = state.appController;
	const countryCode = LocaleHelper.getCountryCode(locale);

	if (SalesCoworkerConfiguration.checkIsomEnabled()) {
		errors = Enumerable.from(errors)
			.where(function (it) {
				return (
					it.code !== ITEMERRORCODES.NOTENOUGHSTOCK &&
					it.code !== ITEMERRORCODES.ITEMNOTSOLDINSTORE &&
					it.code !== ITEMERRORCODES.DDS &&
					it.code !== ITEMERRORCODES.NOSTOCK &&
					it.code !== ITEMERRORCODES.SALESSTOPPED &&
					it.code !== ITEMERRORCODES.NOMETER
				);
			})
			.toArray();
	}

	if (hasServices) {
		const { customer, customerBilling, icmPreferredCommunication } = getCustomerProfileObject();

		const customers = customer ? [{ ...customer, isBillingAddress: false }] : [];

		if (customer && customer.isAddedBillAddress) {
			customers.push({ ...customerBilling, isBillingAddress: true });
		}

		let isCustomerInValid = false;

		if (customerSchema.isMfsProfile) {
			customers.forEach(cus => {
				if (
					(!cus.isBusinessCustomer && ObjectHelper.isEmpty(cus.firstName)) ||
					(!cus.isBusinessCustomer && ObjectHelper.isEmpty(cus.lastName)) ||
					ObjectHelper.isEmpty(cus.email) ||
					!ValidationHelper.IsValidEmail(customer.email) ||
					ObjectHelper.isEmpty(cus.mobilePhone) ||
					!ValidationHelper.IsValidPhoneNumber(customer.mobilePhone, countryCode) ||
					ObjectHelper.isEmpty(cus.city) ||
					ObjectHelper.isEmpty(cus.addressLine1)
				) {
					isCustomerInValid = true;
				}

				if (cus.isBusinessCustomer && ObjectHelper.isEmpty(cus.businessName)) {
					isCustomerInValid = true;
				}
			});
		} else {
			const invalidPreferredCommunications = [];
			const shouldRunIcmPreferredCommunication = icmPreferredCommunication && icmPreferredCommunication.length > 0;

			customers.forEach(cus => {
				const dfpFields = shouldRunIcmPreferredCommunication ? getDfpFields(cus, icmPreferredCommunication) : [];

				if (!shouldRunIcmPreferredCommunication || dfpFields.length === 0) {
					if (
						ObjectHelper.isEmpty(cus.email) ||
						!ValidationHelper.IsValidEmail(customer.email) ||
						ObjectHelper.isEmpty(cus.mobilePhone) ||
						!ValidationHelper.IsValidPhoneNumber(customer.mobilePhone, countryCode)
					) {
						isCustomerInValid = true;
					}
				} else {
					// ....else { we are running with preferred DFP settings for commuunication

					// Filter out non-empty dfpFields
					const filledInFields = dfpFields.filter(pref => !ObjectHelper.isEmpty(cus[pref.id]));

					// Set correct validation according to chosen filled in dfpFields
					let isInValid = undefined;
					let preferredAndValid = [];

					filledInFields.forEach(pref => {
						if (isInValid === undefined || isInValid === false) {
							switch (pref.id) {
								case "mobilePhone":
								case "landlinePhone":
									isInValid = !ValidationHelper.IsValidPhoneNumber(cus[pref.id], countryCode, pref.regex);
									break;
								case "email":
									isInValid = !ValidationHelper.IsValidEmail(cus[pref.id]);
									break;

								default:
									isInValid = true;
							}

							// Save preferred and valid field values
							if (pref.preferred && !isInValid) {
								preferredAndValid.push(pref.id);
							}
						}
					});

					// Since we're iterating over delivery, billing we store our [isInValid] result and assign later to [isCustomerInValid]
					invalidPreferredCommunications.push(isInValid === undefined ? true : isInValid || preferredAndValid.length === 0);
				}

				if (
					ObjectHelper.isEmpty(cus.firstName) ||
					ObjectHelper.isEmpty(cus.lastName) ||
					ObjectHelper.isEmpty(cus.city) ||
					ObjectHelper.isEmpty(cus.addressLine1)
				) {
					isCustomerInValid = true;
				}

				if (cus.isBusinessCustomer && ObjectHelper.isEmpty(cus.businessName)) {
					isCustomerInValid = true;
				}
			});

			if (isCustomerInValid || (shouldRunIcmPreferredCommunication && invalidPreferredCommunications.includes(true))) {
				isCustomerInValid = true;
			}
		}

		if (isCustomerInValid) {
			errors.push({
				code: ITEMERRORCODES.CUSTOMERMISSING,
				message: "Customer details missing"
			});
		}

		if (questionNeeded) {
			errors.push({
				code: ITEMERRORCODES.QUESTIONNAIREMISSING,
				message: "Questionnaire missing"
			});
		}

		if (isMissingDeliveryForNewItems) {
			errors.push({
				code: ITEMERRORCODES.MISSINGDELIVERY,
				message: "Current booked delivery is invalidated, Please re-book"
			});
		}
	} else {
		const canCreateItems = items.filter(x => !x.canCreateOrder).length === 0;

		if (!canCreateItems) {
			errors.push({
				code: ITEMERRORCODES.NOTENOUGHSTOCK,
				message: "Not Enough Stock"
			});
		}

		if (isTransportDeskMode) {
			errors = [
				{
					code: ITEMERRORCODES.TRANSPORTDESKDELIVERY,
					message: "Book Delivery to proceed"
				}
			];
		}
	}
	if (paymentType === "PAYMENT_IN_INVOICE" && !creditInfo) {
		errors = [
			{
				code: ITEMERRORCODES.MISSING_CREDIT_INFO,
				message: "Credit info is missing"
			}
		];
	}
	const uniqueErrors = Enumerable.from(errors)
		.distinct(x => x.code)
		.toArray();

	return uniqueErrors;
};

export const CanCreateSalesOrderValidator = state => {
	const { items, hasServices } = state.cartController;

	if (items.length === 0) {
		return [];
	}

	const allItems = cartItemsBuilder.createItems(items);

	let errors = Enumerable.from(allItems)
		.selectMany(x => CanBeUsedOnSalesOrderValidator(x))
		.toArray();
	if (hasServices) {
		if (SalesCoworkerConfiguration.checkIsomEnabled()) {
			errors = Enumerable.from(errors)
				.where(function (it) {
					return (
						it.code !== ITEMERRORCODES.NOTENOUGHSTOCK &&
						it.code !== ITEMERRORCODES.ITEMNOTSOLDINSTORE &&
						it.code !== ITEMERRORCODES.DDS &&
						it.code !== ITEMERRORCODES.NOSTOCK &&
						it.code !== ITEMERRORCODES.SALESSTOPPED
					);
				})
				.toArray();
		}
	}

	return errors;
};

export const CanCreateServiceValidator = state => {
	const { items, isTransportDeskMode } = state.cartController;

	if (items.length === 0) {
		return [];
	}

	let allItems = cartItemsBuilder.createItems(items);
	let errors = Enumerable.from(allItems)
		.selectMany(x => CanBeUsedOnSalesOrderValidator(x))
		.toArray();

	if (SalesCoworkerConfiguration.checkIsomEnabled()) {
		errors = Enumerable.from(errors)
			.where(function (it) {
				return (
					it.code !== ITEMERRORCODES.NOTENOUGHSTOCK &&
					it.code !== ITEMERRORCODES.ITEMNOTSOLDINSTORE &&
					it.code !== ITEMERRORCODES.DDS &&
					it.code !== ITEMERRORCODES.NOSTOCK &&
					it.code !== ITEMERRORCODES.SALESSTOPPED &&
					it.code !== ITEMERRORCODES.NOMETER
				);
			})
			.toArray();
	}

	if (isTransportDeskMode) {
		const checkZeroQuantity = items.filter(x => x.quantity === 0);

		if (checkZeroQuantity.length == items.length) {
			errors = [
				{
					code: ITEMERRORCODES.TRANSPORTDESK,
					message: "Please scan items to proceed"
				}
			];
		}
	}

	return errors;
};

const getDfpFields = (cus, icmPreferredCommunication) => {
	let dfpObject = undefined;
	let dfpKeyName = undefined;

	// Get backend generated field data for delivery(private or business) or billing(private or business)
	if (cus.isBillingAddress) {
		dfpKeyName = cus.isBusinessCustomer
			? DfpKey.ICM_EXCHANGE_BETWEEN_PREFERRED_WAY_OF_COMMUNICATION_BUSINESSCUST_BILLING
			: DfpKey.ICM_EXCHANGE_BETWEEN_PREFERRED_WAY_OF_COMMUNICATION_PRIVATECUST_BILLING;
	} else {
		dfpKeyName = cus.isBusinessCustomer
			? DfpKey.ICM_EXCHANGE_BETWEEN_PREFERRED_WAY_OF_COMMUNICATION_BUSINESSCUST_DELIVERY
			: DfpKey.ICM_EXCHANGE_BETWEEN_PREFERRED_WAY_OF_COMMUNICATION_PRIVATECUST_DELIVERY;
	}

	if (dfpKeyName) {
		dfpObject = icmPreferredCommunication.find(pref => pref.field.name === dfpKeyName);

		if (dfpObject && dfpObject.data) {
			return dfpObject.data;
		}
	}

	return [];
};

export const forTest = {
	getDfpFields: getDfpFields
};
