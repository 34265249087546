import { AppLogger, GaHelper, RequestHelper } from "@coworker/sharedlibrary";
import { loadMicroFrontEnds, registerGlobals } from "../utils/shellObjectService";

import HomeService from "../service/Home/homeService";
import LoadAppAndUserConfiguration from "./loadAppAndUserConfiguration";
import { UserType } from "../store/auth/reducer";
import { logStartup } from "../logger/appLogger";

const LoadUserConfig = async ({
	setLogin,
	setLoadingMessage,
	setIsLoadingMain,
	setShowRetryView,
	setIsAllowed,
	changeLocale,
	getTranslations,
	setUser,
	dispatch,
	sessionId
}) => {
	setLogin(false);
	setIsLoadingMain(true);
	setShowRetryView(false);

	const user = RequestHelper.getLoggedInUser();
	let home = new HomeService();
	home.sessionId = sessionId;

	home.initConfig()
		.then(async () => {
			const { googleAnalyticsId } = home.environment;
			registerGlobals();
			await loadMicroFrontEnds(home.environment.microapps);
			// eslint-disable-next-line no-undef
			if (process.env.NODE_ENV === "development") {
				//for test use this only
				GaHelper.InitGA("UA-147111709-1");
			} else {
				GaHelper.InitGA(googleAnalyticsId);
			}
			//If the user is defined
			if (user !== undefined && user.ikealegacyuid !== "") {
				logStartup(user.ikealegacyuid, home.sessionId, false);

				setLoadingMessage("Loading user details");
				const overidedStore = RequestHelper.getOverridedStore();

				home.getUserInfo(user.ikealegacyuid, overidedStore || "")
					.then(async userInfo => {
						if (userInfo === undefined || userInfo.buCode === undefined || userInfo.buCode === "") {
							setIsAllowed(false);
						} else {
							const userType = UserType.SALESPERSON;

							LoadAppAndUserConfiguration({
								userInfo,
								userType,
								home,
								changeLocale,
								getTranslations,
								setUser,
								dispatch
							});
						}
					})
					.catch(() => {
						setIsAllowed(false);
					});
			} else {
				setIsAllowed(false);
			}
		})
		.catch(error => {
			setShowRetryView(true);
			AppLogger.error(`HomeService initConfig failed :${error.message}`, "HomeService.initConfig", user);
		});
};
export default LoadUserConfig;
