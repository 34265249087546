import AppLogger from "../../logger/appLogger";
import moment from "moment";
const importService = {
	buildImportObject: async postData => {
		let responseData;
		try {
			const data = postData.searchData.data;
			switch (postData.searchData.hitType.toUpperCase()) {
				case "PLANNER":
					responseData = importService.convertPlannerData(data);
					break;
				case "PTAG":
					responseData = importService.convertPtagData(data);
					break;
				case "ORDER":
					responseData = importService.convertOrderData(data);
					break;
				case "WEBSHOPPINGLIST":
					responseData = importService.convertWebShoppingListData(data);
					break;
				case "KITCHENPLANNER":
					responseData = importService.convertIHP3Data(data);

					break;
			}
			return Promise.resolve(responseData);
		} catch (error) {
			AppLogger.error(`External import build failed :${error.message}`, "ExternalImport", {
				Success: false,
				StatusCode: 400,
				Type: postData.hitType
			});
		}
		return undefined;
	},
	convertPlannerData: plannerData => {
		let importObj = importObject();
		importObj.type = "planner";
		importObj.name = plannerData.plannerName;
		importObj.id = plannerData.plannerId;
		importObj.success = true;
		importObj.deepLink = plannerData.deepLink;
		importObj.shortLink = plannerData.shortLink;
		let itemListItemObj = itemListItem();
		itemListItemObj.name = plannerData.plannerName;
		itemListItemObj.itemList = [...plannerData.plannerItems];
		itemListItemObj.type = "";
		itemListItemObj.url = "";
		importObj.itemGroups.push(itemListItemObj);
		importObj.drawingList = [];
		if (importObj.shortLink) {
			importObj.drawingList.push({
				drawingUrl: importObj.shortLink.href,
				drawingId: importObj.id,
				drawingUrlType: "SHORT_URL",
				drawingIdSource: (plannerData.plannerName && plannerData.plannerName.split(" ")[0].toUpperCase()) || "VPC",
				roomType: "",
				createDateTime: moment().toISOString(),
				drawingName: importObj.name
			});
		}
		if (importObj.deepLink) {
			importObj.drawingList.push({
				drawingUrl: importObj.deepLink.href,
				drawingId: importObj.id,
				drawingUrlType: "APPLICATION_DEEPLINK",
				drawingIdSource: (plannerData.plannerName && plannerData.plannerName.split(" ")[0].toUpperCase()) || "VPC",
				roomType: "",
				createDateTime: moment().toISOString(),
				drawingName: importObj.name
			});
		}
		return importObj;
	},

	convertPtagData: ptagData => {
		let importObj = importObject();
		importObj.type = "ptag";
		importObj.name = ptagData.Name;
		importObj.id = ptagData.ItemId;
		importObj.success = true;
		let itemListItemObj = itemListItem();
		itemListItemObj.type = "";
		itemListItemObj.url = "";
		ptagData.childItems.forEach(item => {
			delete item.updateDateTime;
			delete item.insertDataTime;
			delete item.itemQuantity;
		});

		itemListItemObj.name = ptagData.Name;
		itemListItemObj.itemList = [...ptagData.childItems];

		importObj.itemGroups.push(itemListItemObj);

		return importObj;
	},

	convertIHP3Data: kitchenPlannerData => {
		let importObj = importObject();
		importObj.type = kitchenPlannerData.sourceSystem;
		importObj.name = kitchenPlannerData.name;
		importObj.id = kitchenPlannerData.serialNumber;
		importObj.success = true;
		importObj.deepLink = {
			href: kitchenPlannerData.designUrl
		};
		importObj.designLink = {
			href: kitchenPlannerData.designUrl
		};
		kitchenPlannerData.categories.forEach(category => {
			let itemListItemObj = itemListItem();
			itemListItemObj.name = category.name || "Others";
			itemListItemObj.type = category.type || "";
			itemListItemObj.url = category.url || "";
			category.items.forEach(item => {
				itemListItemObj.itemList.push({
					itemType: item.itemType,
					itemNo: item.itemNo,
					quantity: item.Quantity,
					tags: item.tags,
					isCustomWorktopItem: item.isCustomWorktopItem,
					url: item.url
				});
			});

			importObj.itemGroups.push(itemListItemObj);
		});
		importObj.drawingList = [];
		if (importObj.deepLink) {
			importObj.drawingList.push({
				drawingImageUrl: kitchenPlannerData.imageUrl,
				drawingUrl: kitchenPlannerData.designUrl,
				drawingUrlType: "APPLICATION_DEEPLINK",
				drawingId: importObj.id,
				drawingIdSource:
					(kitchenPlannerData.plannerSource && kitchenPlannerData.plannerSource.toUpperCase()) === "OFFICE"
						? "OFFICE"
						: kitchenPlannerData.sourceSystem,
				roomType: (kitchenPlannerData.plannerSource && kitchenPlannerData.plannerSource.toUpperCase()) || "KITCHEN",
				createDateTime: kitchenPlannerData.savedAt,
				drawingName: importObj.name,
				externalCustomerRef: kitchenPlannerData.userName,
				externalCustomerRefName: kitchenPlannerData.userName
			});
			importObj.drawingList.push({
				drawingImageUrl: kitchenPlannerData.imageUrl,
				drawingUrl: kitchenPlannerData.designUrl,
				drawingUrlType: "SHORT_URL",
				drawingId: importObj.id,
				drawingIdSource:
					(kitchenPlannerData.plannerSource && kitchenPlannerData.plannerSource.toUpperCase()) === "OFFICE"
						? "OFFICE"
						: kitchenPlannerData.sourceSystem,
				roomType: (kitchenPlannerData.plannerSource && kitchenPlannerData.plannerSource.toUpperCase()) || "KITCHEN",
				createDateTime: kitchenPlannerData.savedAt,
				drawingName: importObj.name,
				externalCustomerRef: kitchenPlannerData.userName,
				externalCustomerRefName: kitchenPlannerData.userName
			});
		}

		return importObj;
	},

	convertOrderData: orderdata => {
		try {
			console.log("orderdata", orderdata);
			let importObj = importObject();
			importObj.type = "order";
			importObj.name = orderdata.orderType;
			importObj.id = orderdata.id;
			importObj.orderNo = orderdata.orderNo;
			importObj.orderNoSource = orderdata.orderNoSource;
			importObj.success = true;
			let groupItems = [];
			if (orderdata.groups && orderdata.groups.length > 0) {
				orderdata.groups.forEach(grp => {
					let itemListItemObj = itemListItem();
					itemListItemObj.name = grp.groupName || grp.groupNo || "";
					itemListItemObj.type = "";
					itemListItemObj.url = "";
					itemListItemObj.seqNo = grp.seqNo;
					if (grp.referencedItems) {
						grp.referencedItems.forEach(item => {
							var grpItem = orderdata.orderItems.find(x => x.soSalesItemNo === item);
							groupItems.push(grpItem);

							if (grpItem) {
								if (!grpItem.retailItem) {
									console.log(grpItem);
								}
								itemListItemObj.itemList.push({
									itemType: grpItem.retailItem && grpItem.retailItem.ItemType,
									itemNo: grpItem.itemId,
									quantity: grpItem.quantity
								});
							} else {
								//TODO  handle
							}
						});
						importObj.itemGroups.push(itemListItemObj);
					} else {
						importObj.itemGroups.push(itemListItemObj);
					}
				});
			}

			orderdata.orderItems.forEach(orderItem => {
				let itemListItemObj = itemListItem();
				itemListItemObj.name = "";
				itemListItemObj.type = "";
				itemListItemObj.url = "";
				itemListItemObj.seqNo = orderItem.seqNo;
				if (groupItems.find(x => x.id === orderItem.id) === undefined) {
					itemListItemObj.itemList.push({
						itemType: orderItem.retailItem && orderItem.retailItem.ItemType,
						itemNo: orderItem.itemId,
						quantity: orderItem.quantity
					});
				}
				if (itemListItemObj.itemList.length > 0) importObj.itemGroups.push(itemListItemObj);
			});
			// if (orderdata.textLines && orderdata.textLines.length > 0) {
			//   orderdata.textLines.forEach(item => {
			//     let itemListItemObj = itemListItem();
			//     itemListItemObj.name = "";
			//     itemListItemObj.type = "";
			//     itemListItemObj.url = "";
			//     itemListItemObj.seqNo = item.seqNo;
			//     itemListItemObj.itemList.push({
			//       itemType: "TEXTLINE",
			//       itemNo: "",
			//       quantity: 0,
			//       text: item.name
			//     });
			//     importObj.itemGroups.push(itemListItemObj);
			//   });
			// }
			return importObj;
		} catch (e) {
			throw new Error(e);
		}
	},

	convertWebShoppingListData: orderdata => {
		try {
			let importObj = importObject();
			importObj.type = "webshoppinglist";
			importObj.name = orderdata.name || "webshoppinglist";
			importObj.id = orderdata.id;
			importObj.success = true;
			let itemListItemObj = itemListItem();
			itemListItemObj.name = orderdata.name || "webshoppinglist";
			itemListItemObj.type = "";
			itemListItemObj.url = "";
			orderdata.items.forEach(item => {
				itemListItemObj.itemList.push({
					itemType: item.type,
					itemNo: item.itemNo,
					quantity: item.quantity
				});
			});

			importObj.itemGroups.push(itemListItemObj);

			return importObj;
		} catch (e) {
			throw new Error(e);
		}
	}
};

export default importService;

let importObject = () => {
	return {
		success: false,
		sessionId: undefined,
		type: "",
		name: "",
		id: "",
		itemGroups: []
	};
};

let itemListItem = () => {
	return {
		name: "",
		itemList: []
	};
};
