import { device } from "@coworker/sharedlibrary";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import CustomerInfo from "./customer/customerInfo";
import OrderCheckOutOptions from "./orderCheckoutOptions";
import CartErrors from "./cartErrors";
import QuotationSummary from "../../quotation/quotationSummary";
import OrderAttributesSelection from "./orderAttributesSelections";
import OrderPriceSummarySelection from "./orderPriceSummarySelection";
import { OrderPriceSummaryView } from "./orderpricesummary/orderPriceSummaryView";
import { getCustomerProfileObject } from "../../../utils/shellObjectService";
import { CanBookService, CanCreateSalesOrder } from "../canCreateSalesOrderValidator";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 375px;
	position: absolute;
	right: 0;
	@media ${device.medium} {
		width: 100%;
		height: auto;
		justify-content: normal;
		position: relative;
		padding: 0px 12px 0px 12px;
		margin-right: 12px;
	}
	padding: 5px 0 0px 50px;
	margin-right: 50px;
	.addServiceSection {
		height: 55px;
		margin-top: 24px;
		border-radius: 25px;
		margin-bottom: 24px;
		span {
			padding-left: 5px;
		}
	}
	justify-content: space-between;
`;

const OrderSummary = styled.div`
	width: 100%;
	min-height: 60px;
	margin-top: 15px;
`;

const Footer = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	.totalSection {
		margin-top: 10px;
		padding: 10px 0;
		border-top: solid 1px black;
	}
`;

const HeaderSection = styled.div`
	display: flex;
	max-height: 550px;
	flex-direction: column;
	overflow-y: auto;
	@media ${device.medium} {
		min-height: 0px;
		max-height: initial;
		overflow-y: hidden;
	}
`;
const Section = styled.div`
	border-bottom: 1px solid #dfdfdf;
`;

const CartCheckoutSummary = ({ disabled }) => {
	const canBookService = useSelector(state => CanBookService(state));
	const { customerSchema } = useSelector(state => state.appController);
	const canCreateSalesOrder = useSelector(state => CanCreateSalesOrder(state));
	const { isMfsProfile } = customerSchema;
	const { order, isModifyOrderinProgress, items, hasItemsPicked, bookedServiceProposal = {} } = useSelector(state => state.cartController);
	const messages = useSelector(state => state.intl.messages);
	const { hasServices = false, isCcdService = false } = bookedServiceProposal;
	const { customer } = getCustomerProfileObject();
	const onlyService = items.length === 0 && hasServices;

	return (
		<Container>
			<HeaderSection>
				<CartErrors></CartErrors>
				<Section>
					<OrderAttributesSelection enabled={hasServices && isCcdService && !isModifyOrderinProgress} messages={messages} />
				</Section>

				{customer && (
					<Section>
						<CustomerContactInfo
							isMfsProfile={isMfsProfile}
							messages={messages}
							customer={customer}
							hasServices={hasServices}
							isModifyOrderinProgress={isModifyOrderinProgress}
						>
							<CartErrors code="MISSINGCUSTOMER"></CartErrors>
						</CustomerContactInfo>
					</Section>
				)}
				{order && order.isQuotation && <QuotationSummary></QuotationSummary>}
			</HeaderSection>
			{hasItemsPicked && (
				<OrderSummary>
					<OrderPriceSummarySelection></OrderPriceSummarySelection>
					<OrderPriceSummaryView showTotalSummaryOnly={true} />
				</OrderSummary>
			)}

			<OrderCheckOutOptions onlyService={onlyService} disabled={disabled} canCreateSalesOrder={canCreateSalesOrder} canBookService={canBookService} />
			<Footer></Footer>
		</Container>
	);
};

export default CartCheckoutSummary;

const CustomerContactInfo = props => {
	const { messages, isModifyOrderinProgress, customer, isMfsProfile } = props;

	return (
		<div className={isModifyOrderinProgress ? "disabled" : ""} disabled={isModifyOrderinProgress}>
			<CustomerInfo
				canEdit={!isModifyOrderinProgress}
				isMfsProfile={isMfsProfile}
				disabled={isModifyOrderinProgress}
				customer={customer}
				message={messages}
			>
				{props.children}
			</CustomerInfo>
		</div>
	);
};
