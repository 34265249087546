import SalesItemTypes from "@coworker/sharedlibrary/src/constants/salesItemTypes";
const SalesOrderItemsBuilder = {
	createItems: cartItems => {
		let items = [];
		let lineId = 1;
		cartItems.forEach(cartItem => {
			if (cartItem.isCombination) {
				cartItem.childItems.forEach(subItem => {
					let result = buildItems(cartItem, subItem, lineId);
					items = items.concat(result.items);
					lineId = result.lineId;
				});
			} else {
				let result = buildItems(null, cartItem, lineId);
				items = items.concat(result.items);
				lineId = result.lineId;
			}
		});
		return items;
	}
};

const buildItems = (groupParent, cartItem, lineId) => {
	let items = [];
	if (cartItem.retailItem.ItemType === SalesItemTypes.ART) {
		let articleItem = createDtoItem(cartItem, lineId);
		if (groupParent !== null) {
			articleItem.quantity = articleItem.quantity * groupParent.quantity;
		}
		items.push(articleItem);
		lineId = lineId + 1;
	} else {
		let subItemList = [];

		let sprItem = createDtoItem(cartItem, lineId);
		if (groupParent !== null) {
			sprItem.quantity = sprItem.quantity * groupParent.quantity;
		}
		lineId = lineId + 1;
		cartItem.childItems.forEach(subItem => {
			let childItem = createDtoItem(subItem, lineId);
			childItem.quantity = childItem.quantity * sprItem.quantity;
			subItemList.push(childItem);
			lineId = lineId + 1;
		});
		let itemReferences = [];
		subItemList.forEach(x => {
			let refitem = {
				lineId: x.lineId
			};
			itemReferences.push(refitem);
		});
		sprItem.itemReferences = itemReferences;
		items = items.concat(subItemList);

		items.push(sprItem);
	}
	return { items, lineId };
};

const createDtoItem = (cartItem, seqNo) => {
	let item = cartItem.retailItem;
	let unitCode = item.ItemUnitCode || "PIECES";

	return {
		itemId: cartItem.itemId,
		itemType: item.ItemType,
		itemNo: cartItem.itemId,
		lineId: seqNo,
		quantity: cartItem.quantity,
		qtyType: unitCode === "PIECES" ? "Piece" : "Meter",
		itemReferenceId: seqNo,
		seqNo: seqNo,
		refLineId: cartItem.id,
		itemReferences: null
	};
};

export default SalesOrderItemsBuilder;
