import React from "react";
import { Break, Grid, List, Label, PageContainer, NoResultsView, SaljaLogo, SaljaLogoFrontpage, Container, AnimatedPlaceholder } from "@coworker/sharedlibrary";

const SSaljaLogoFrontpage = props => {
	return <SaljaLogoFrontpage client={true} {...props} />;
};

const SSaljaLogo = props => {
	return <SaljaLogo client={true} {...props} />;
};

const SBreak = props => {
	return <Break client={true} {...props} />;
};

const SGrid = props => {
	return <Grid client={true} {...props} />;
};

const SList = props => {
	return <List client={true} {...props} />;
};

const SLabel = props => {
	return <Label client={true} {...props} />;
};

const SPageContainer = props => {
	return <PageContainer client={true} {...props} />;
};

const SNoResultsView = props => {
	return <NoResultsView client={true} {...props} />;
};

const SContainer = props => {
	return <Container client={true} {...props} />;
};

const SAnimatedPlaceholder = props => {
	return <AnimatedPlaceholder client={true} {...props} />;
};

export {
	SBreak as Break,
	SGrid as Grid,
	SList as List,
	SLabel as Label,
	SPageContainer as PageContainer,
	SNoResultsView as NoResultsView,
	SSaljaLogo as SaljaLogo,
	SSaljaLogoFrontpage as SaljaLogoFrontpage,
	SContainer as Container,
	SAnimatedPlaceholder as AnimatedPlaceholder
};
