import { logOutUser } from "./../../../store/auth/actions";
import { useSelector, useDispatch } from "react-redux";
import React from "react";
import Modal, { Prompt, ModalFooter } from "@ingka/modal";
import Button from "@ingka/button";

const UserIdleDialog = ({ onSubmit }) => {
	const { messages } = useSelector(state => state.intl);
	const dispatch = useDispatch();

	return (
		<Modal
			focusLockProps={focus}
			visible
			modalType="alert"
			light={"false"}
			handleCloseBtn={() => {
				onSubmit(true);
				dispatch(logOutUser());
			}}
		>
			<Prompt
				title={messages.SG_LBL_UserIdle || "You have been Idle!!"}
				footer={
					<ModalFooter compact={false}>
						<Button
							text={messages.SG_BTN_OK || "OK"}
							type="primary"
							onClick={() => {
								onSubmit(true);
								dispatch(logOutUser());
							}}
						/>
					</ModalFooter>
				}
			>
				<p>{messages.SG_LBL_SessionExpired || "You will get logged, out as your current session got expired"}</p>
			</Prompt>
		</Modal>
	);
};

export default UserIdleDialog;
const focus = {
	returnFocus: false,
	autoFocus: true
};
