import { useSelector, useDispatch } from "react-redux";
import React, { useState } from "react";
import { DfpKey } from "@coworker/sharedlibrary";
import { Dropdown, DropdownItem } from "@ingka/dropdown";
import styled from "styled-components";
import { useStorePreference } from "../../../../hooks/useStorePreference";
import { setCashLine } from "../../../../store/cart/cart.thunks";
const DropDownSelect = styled(Dropdown)`
	width: 100%;
	margin-bottom: 10px;
`;
const CashLineSelection = ({ selectedCallback }) => {
	const dispatch = useDispatch();
	const { messages } = useSelector(state => state.intl);
	const { selectedCashLine } = useSelector(state => state.cartController);
	const cashLinesPref = useStorePreference(DfpKey.TRANSPORT_DESK_CASHLINES);
	const [cashLines, setCashLines] = useState([
		{
			id: "SELECT",
			name: "Select"
		}
	]);
	React.useEffect(() => {
		let cLines = [];
		if (cashLinesPref && Array.isArray(cashLinesPref)) {
			cashLinesPref.forEach(({ key, value }) => {
				cLines.push({
					id: key,
					name: value
				});
			});
			setCashLines(cLines);
			dispatch(setCashLine(cLines[0]));
		}
	}, [cashLinesPref]);

	return (
		<section>
			<DropDownSelect
				id="cashlines"
				label={messages.SG_LBL_CashLine || "CashLine"}
				onChange={e => {
					let cLine = cashLines.find(x => x.id === e.target.value);
					if (selectedCallback) {
						selectedCallback(cLine);
					} else {
						dispatch(setCashLine(cLine));
					}
				}}
				defaultValue={selectedCashLine && selectedCashLine.id}
			>
				{cashLines.map(element => (
					<DropdownItem id={element.id} key={element.id} value={element.id} name={element.name} />
				))}
			</DropDownSelect>
		</section>
	);
};

export default CashLineSelection;
