import { OrderTypes } from "@coworker/sharedlibrary";
import { CustomerModel } from "@coworker/sharedlibrary";

const mapQuotationToOrder = response => {
	const { quotation, project, partyUID } = response;
	const {
		billingAddress,
		shippingAddress,
		items,
		services,
		quotationSummary,
		quotationRefNo,
		quotationName,
		quotationDescription,
		quotationSchedule,
		currencyCode,
		businessUnit,
		createdOn,
		assignedTo,
		deliveryArrangements,
		languageCode,
		providedServices
	} = quotation;

	var order = {
		project: project,
		customerPartyId: partyUID,
		quotationRefNo: quotationRefNo,
		quotationName: quotationName,
		quotationDescription: quotationDescription,
		quotationSchedule: quotationSchedule,
		assignedTo: assignedTo,
		customer: getCustomer(partyUID, shippingAddress, businessUnit.ctyCode, languageCode),
		customerBilling: getCustomer(partyUID, billingAddress, businessUnit.ctyCode, languageCode),
		deliveryArrangements: deliveryArrangements,
		providedServices: providedServices,
		services: services || [],
		items: buildItems(items),
		quotationSummary: quotationSummary,
		currencyCode: currencyCode,
		type: OrderTypes.Quotation,
		id: quotationRefNo,
		isPaid: false,
		isCancelled: false,
		hasService: deliveryArrangements && deliveryArrangements.length > 0,
		orderNo: quotationRefNo,
		orderNoSource: "",
		buCode: businessUnit.buCode,
		countryCode: businessUnit.ctyCode,
		orderType: OrderTypes.Quotation,
		orderCreationDate: createdOn,
		isQuotation: true,
		version: "1"
	};
	return order;
};
const mapQuotations = (bu, currencyCode, quotations) => {
	return quotations.map(
		({ quotationRefNo, quotationName, statusCode, quotationSchedule, customerName, updateDateTime, createdBy, assignedTo, updatedBy, quotationValue }) => {
			var order = {
				orderStatus: statusCode,
				quotationRefNo: quotationRefNo,
				quotationName: quotationName,
				quotationSchedule: quotationSchedule,
				currencyCode: currencyCode,
				type: OrderTypes.Quotation,
				orderType: OrderTypes.Quotation,
				id: quotationRefNo,
				isPaid: false,
				isCancelled: statusCode !== "Cancelled",
				orderNo: quotationRefNo,
				orderNoSource: "",
				buCode: bu,
				orderCreationDate: updateDateTime,
				isQuotation: true,
				version: "1",
				quotationValue: quotationValue,
				customer: {
					firstName: "",
					lastName: ""
				},
				customerName: customerName,
				createdBy,
				assignedTo,
				updatedBy,
				orderItems: [],
				cashAndCarryOrder: false,
				totalOrderAmount: quotationValue
			};
			return order;
		}
	);
};
export { mapQuotationToOrder, mapQuotations };
const getCustomer = (partyUID, address, countryCode, languageCode) => {
	var contact = address;
	if (contact === null) {
		return new CustomerModel();
	}
	try {
		var orderCustomer = {
			id: partyUID,
			partyId: partyUID || "",
			zipCode: contact.postalCode || "",
			preferredLocale: `${languageCode}-${countryCode}`
		};

		return orderCustomer;
	} catch (error) {
		return new CustomerModel();
	}
};

const buildItems = items => {
	var allItems = [];
	var sprs = items.filter(x => x.itemLineReferences);
	var childRefs = sprs
		.map(x => x.itemLineReferences)
		.reduce(function (a, b) {
			return a.concat(b);
		}, []);
	sprs.forEach(spr => {
		var childItems = spr.itemLineReferences.map(cline => {
			var item = items.find(x => x.lineId === cline);
			return item;
		});
		spr.childItems = childItems;
	});
	var articles = [];
	items
		.filter(x => x.itemType === "ART")
		.forEach(x => {
			if (!childRefs.includes(x.lineId)) {
				articles.push(x);
			}
		});
	allItems = allItems.concat(sprs).concat(articles);
	return allItems;
};
