import { DfpKey, ShoppingCartItemFactory, dispatchEvent, services } from "@coworker/sharedlibrary";
import { WarningAction, warningDone, warningUpdateStock } from "../../../store/warning/warningAction";
import { clearCart, openOrder, updateCartItemStock } from "../../../store/cart/cartActions";
import { setCartCurrency, setExternalClientReference, setServiceProposal } from "./../../../store/cart/cart.thunks";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import SalesCoworkerConfiguration from "../../../store/salesCoworkerConfiguration";
import _ from "lodash";
import { getOrderServicesObject } from "./../../../utils/shellObjectService";
import { useCartModelPopup } from "../components/modelPopup";

const useCartHook = props => {
	const dispatch = useDispatch();

	const { warningAction, showAppWarning } = useSelector(state => state.warningController);

	const { order, isModifyOrderinProgress, items, isTransportDeskMode, hasServices, isQuotation, selectedCashLine } = useSelector(
		state => state.cartController
	);
	const { getCartModel } = useCartModelPopup();
	const cartItems = items || [];

	const [editBag, setEditBag] = useState(false);
	const [updateDate, setUpdateDate] = useState(false);

	const config = SalesCoworkerConfiguration.getUserConfig();
	const isStockInSync = async () => {
		const retailItems = cartItems.map(cartItem => cartItem.retailItem);
		const itemsStock = retailItems.map(retailItem => retailItem.availableStockToSell);

		if (!showAppWarning) {
			let productService = new services.ProductService(config);
			const updatedItems = await productService.getUpdatedItemsStock(retailItems, true);

			const retailItemsStock = updatedItems.map(updatedItem => updatedItem.availableStockToSell);

			if (retailItemsStock.length && itemsStock.length && !_.isEqual(retailItemsStock, itemsStock)) {
				setUpdateDate(new Date());
				dispatch(warningUpdateStock({ updateDate }));
			}
		} else if (updateDate && updateDate !== undefined) {
			dispatch(warningUpdateStock({ updateDate }));
		}
	};

	const removeWarning = () => {
		dispatch(warningDone());
		dispatch(updateCartItemStock());

		setUpdateDate(undefined);
	};

	useEffect(() => {
		dispatch(updateCartItemStock());

		const stockInterval = setInterval(() => {
			if (!isTransportDeskMode) {
				isStockInSync();
			}
		}, 5 * 60 * 1000);
		if (props.match) {
			const orderNo = props.match.params.orderNo;

			if (orderNo && !order) {
				dispatch(openOrder(orderNo));
			}
		}
		const bookedServices = getOrderServicesObject();

		if (bookedServices) {
			dispatch(setServiceProposal(bookedServices));
		} else {
			dispatch(setServiceProposal(undefined));
		}

		return function cleanup() {
			clearInterval(stockInterval);

			dispatch(warningDone());
		};
	}, []);

	useEffect(() => {
		async function showCashLinePopup() {
			await getCartModel("selectCashLinePopup");
		}
		if (!selectedCashLine && config.isCashLine && items.length > 0) {
			showCashLinePopup();
		}
	}, []);

	useEffect(() => {
		let config = SalesCoworkerConfiguration.getUserConfig();
		let currency = "USD";
		const currencypref = config.preferences.find(x => x !== undefined && x.name === DfpKey.PRIMARY_CURRENCY);

		if (currencypref) {
			currency = currencypref.value || "USD";
		}
		dispatch(setCartCurrency(currency));
	}, []);

	useEffect(() => {
		if (window.location.href.includes("/order/")) {
			var paths = window.location.href.split("/");
			const orderNo = paths[paths.length - 1];
			if (orderNo) {
				dispatch(openOrder(orderNo));
			}
		}
		if (window.location.href.includes("/cart")) {
			var urlSearchParams = new URLSearchParams(window.location.search);
			var customerid = urlSearchParams.get("partyuid");
			var opportunityid = urlSearchParams.get("opportunityid");
			var clientSystem = urlSearchParams.get("clientsystem");
			var extId = urlSearchParams.get("extId");
			if (customerid) {
				dispatch(
					setExternalClientReference({
						partyuid: customerid,
						opportunityId: opportunityid,
						clientSystem: clientSystem,
						buCode: config.buCode,
						extId: extId
					})
				);
				dispatchEvent("LOAD_CUSTOMER", {
					countryCode: config.countryCode,
					locale: config.locale,
					customerId: customerid,
					contextType: "HOMEGRP_1"
				});
			}
			if (opportunityid) dispatch(clearCart());
		}
	}, []);

	useEffect(() => {
		if (warningAction === WarningAction.UpdateStock) {
			removeWarning();
		}
	}, [warningAction]);

	//
	useEffect(() => {
		//this means sams is opening salja for reverse flow
		const fetchItems = items => {
			const productService = new services.ProductService(config);
			let ids = items.map(x => x.ItemId);
			return productService.getRetailItemsByid(ids, false).then(retailItems => {
				if (retailItems && retailItems.length > 0) {
					return Promise.all(
						retailItems.map(async retailItem => {
							let existingItem = items.find(x => x.ItemId === retailItem.ItemId);
							if (retailItem.ItemType === "SPR") {
								return productService
									.getChildItems(
										{
											ItemId: retailItem.ItemId,
											ItemType: retailItem.ItemType
										},
										false
									)
									.then(childItems => {
										retailItem.childItems = childItems;
										return Promise.resolve({ ...retailItem, ...existingItem });
									})
									.then(retailItem => {
										return retailItem;
									});
							}

							return Promise.resolve({ ...retailItem, ...existingItem });
						})
					);
				}
			});
		};
		let items = [];
		if (window.request) {
			let orderRequest = JSON.parse(atob(window.request));
			items = orderRequest.items.map((itm, index) => {
				let retailItem = {
					ItemType: itm.itemType,
					NumberOfPackages: 1,
					quantity: parseInt(itm.requiredQty),
					Quantity: parseInt(itm.requiredQty),
					ItemUnitCode: "PIECES",
					id: parseInt(itm.id) || index + 1,
					ItemId: itm.itemNo
				};
				return retailItem;
			});
			if (items.length > 0) {
				let shp = new ShoppingCartItemFactory();
				let cartItems = [];
				fetchItems(items).then(retailItems => {
					if (retailItems && retailItems.length > 0) {
						retailItems.forEach(retailItm => {
							let cartItem = shp.createShoppingCartItem(retailItm, parseInt(retailItm.quantity));
							cartItem.setSoSalesItemNo(retailItm.id);
							cartItem.setCartItemId(retailItm.id);
							cartItems.push(cartItem);
						});
					}
					dispatchEvent("SET_CART_ITEMS", {
						cartItems: cartItems
					});
				});
			}
		}
	}, []);

	let isEmptyBag = cartItems.length === 0 && !hasServices;
	let canPerformDelete = !isModifyOrderinProgress && !isQuotation;
	let canPerformGroup = cartItems.length > 0 && !isModifyOrderinProgress && !isQuotation && !isTransportDeskMode;
	let canPerformShare = isModifyOrderinProgress && !isQuotation;
	return {
		isEmptyBag,
		editBag,
		canPerformDelete,
		canPerformShare,
		canPerformGroup,
		setEditBag
	};
};

export default useCartHook;
