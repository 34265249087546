class ServiceInfoModel {
	constructor(data, isdelivery, isProvidedService, cartItems, selectedCartItems, zipCode, pickingPrice) {
		/* eslint-disable no-prototype-builtins */
		for (var key in data) {
			if (data.hasOwnProperty(key)) {
				const element = data[key];
				this[key] = element;
			}
		}
		this.isDelivery = isdelivery;
		this.isProvidedService = isProvidedService;
		this.isSelected = false;
		if (this.isDelivery) {
			this.id = this["deliveryMethod"];
			this.deliveryType = "LCD";
		} else {
			this.id = this["serviceProductId"];
			this.isPickingService = this.id === "PICKING_IN_STORE_CUST_ISL";
		}
		this.cartItems = cartItems;
		this.selectedCartItems = selectedCartItems;
		this.zipCode = zipCode;
		this.showInputView = false;
		if (this.isProvidedService) {
			this.serviceName = data["serviceName"] ? data["serviceName"] : data["serviceProductId"];
			if (!this["itemRequiredForService"]) {
				this["capacityNeeded"] = "1";
			}

			if (isEmptyCapacity(this["capacityNeeded"])) {
				this["capacityNeeded"] = "1";
			}
		}
		if (this.isDelivery) this.pickingPrice = pickingPrice;
	}
}
const isEmptyCapacity = CapacityNeeded => {
	return CapacityNeeded === "0" || CapacityNeeded === "0.0";
};

export default ServiceInfoModel;
