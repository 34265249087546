export const HIDE_SEARCH = "HIDE_SEARCH";
export const SHOW_SEARCH = "SHOW_SEARCH";
export const SHOW_LOADING = "SHOW_LOADING";
export const SET_COWORKER_CONFIG = "SET_COWORKER_CONFIG";
export const SHOW_HEADER = "SHOW_HEADER";
export const SHOW_APP_NOTIFICATION = "SHOW_APP_NOTIFICATION";
export const HIDE_APP_NOTIFICATION = "HIDE_APP_NOTIFICATION";
export const EXPAND_APP_NOTIFICATION = "EXPAND_APP_NOTIFICATION";
export const CHANGE_ADDRESS_PROVIDER = "CHANGE_ADDRESS_PROVIDER";
export const CHANGE_SELECTED_HFB = "CHANGE_SELECTED_HFB";
export const CHANGE_MOST_ADDED_PRODUCTS = "CHANGE_MOST_ADDED_PRODUCTS";
export const CHANGE_TOP_MOST_ADDED_PRODUCTS = "CHANGE_TOP_MOST_ADDED_PRODUCTS";
export const CHANGE_SEARCH_HISTORY = "CHANGE_SEARCH_HISTORY";
export const CHANGE_TOP_SEARCH_HISTORY = "CHANGE_TOP_SEARCH_HISTORY";
export const SELECTED_ITEM_TYPE = "SELECTED_ITEM_TYPE";
export const SET_FEATURES = "SET_FEATURES";
export const SET_OFFSET = "SET_OFFSET";
export const SET_USER_STORES = "SET_USER_STORES";
export const SET_ORDER_MODIFICATION_QUEUE = "SET_ORDER_MODIFICATION_QUEUE";
export const SET_USER_LOCALE = "SET_USER_LOCALE";
export const SET_BU_CODE = "SET_BU_CODE";
export const SET_COWORKER_AREA = "SET_COWORKER_AREA";
export const SET_CALLING_APPLICATION = "SET_CALLING_APPLICATION";
