import * as RetailItemHelper from "@coworker/sharedlibrary/src/helpers/retailItemInformationHelper";

import Enumerable from "linq";
import GUID from "@coworker/sharedlibrary/src/helpers/uuidHelper";
import PriceModel from "@coworker/sharedlibrary/src/models/priceModel";
import SalesItemTypes from "@coworker/sharedlibrary/src/constants/salesItemTypes";
import ShoppingCartItem from "@coworker/sharedlibrary/src/models/shoppingCartItem";

const { getPackageWeight, getPackageWeightUnit, getStoreprice } = RetailItemHelper;
const { SPR, ART } = SalesItemTypes;
const NORMAL = "NORMAL",
	FAMILY = "FAMILY";
export default class ShoppingCartItemFactory {
	createShoppingCartItem(item, quantity) {
		let cartItem = new ShoppingCartItem();

		cartItem.initItem(this.parseRetailItem(item), quantity, [], false);
		cartItem.canCreateOrder = cartItem.quantity <= cartItem.availableStockToSell;
		if (item.childItems) {
			this.updateChildItems(cartItem, item.childItems);
		}
		return cartItem;
	}

	createShoppingCartItemFromOrder(orderItem) {
		let cartItem = new ShoppingCartItem();
		orderItem.retailItem = this.parseRetailItem(orderItem.retailItem);
		cartItem.initOrderItem(orderItem);
		cartItem.allowedModifications = orderItem.allowedModifications;
		cartItem.canCreateOrder = cartItem.quantity <= cartItem.availableStockToSell;
		if (orderItem.childItems) {
			cartItem.orderNo = orderItem.orderNo;
			this.updateOrderChildItems(cartItem, orderItem.childItems);
		}
		return cartItem;
	}

	createShoppingCartItemsfromOrder(orderItems, orderNo) {
		// console.log(orderItems);
		let cartItems = [];
		orderItems.forEach(orderItem => {
			orderItem.orderNo = orderNo;
			if (orderItem.importDetails) {
				orderItem.retailItem.availableStockToSell = orderItem.importDetails.originalQuantity;
			}
			let cartItem = this.createShoppingCartItemFromOrder(orderItem);
			this.assignOrderAttributes(cartItem, orderItem);
			cartItems.push(cartItem);
		});
		cartItems = this.reAssignCartItemId(cartItems);
		return cartItems;
	}
	assignOrderAttributes(cartItem, orderItem) {
		cartItem.soSalesItemNo = orderItem.soSalesItemNo;
		cartItem.seqNo = orderItem.seqNo;
		cartItem.isRefund = orderItem.isRefund;
		cartItem.salesPrice = orderItem.salesPrice;
		cartItem.isOrderLine = true;
		cartItem.orderNo = orderItem.orderNo;
		cartItem.price = orderItem.price;
		cartItem.orderItemReference = orderItem;
		cartItem.fulfillingUnit = orderItem.fulfillingUnit;
		if (orderItem.importDetails) {
			cartItem.availableStockToSell = orderItem.importDetails.originalQuantity;
			cartItem.allowedToDeliveryFromTransportDesk = true;
			cartItem.importDetails = orderItem.importDetails;
			if (cartItem.importDetails.orderReferenceNo === 0) {
				delete cartItem.importDetails.orderReferenceNo;
			}
		}
		if (orderItem.handInLocation && !orderItem.importDetails) {
			this.updateCashLineItem(cartItem, orderItem.handInLocation.split("|")[1]);
		}

		cartItem.receiptPriceDetails = orderItem.priceDetails;
		cartItem.handOverLocation = orderItem.handInLocation;
	}

	updateCashLineItem(cartItem, cashLine) {
		let handInLocation = cashLine ? `STORE_HANDIN|${cashLine.name}` : "STORE_HANDIN|Transport Desk";

		cartItem.isCashLine = true;
		cartItem.handOverLocation = handInLocation;
		cartItem.handedOverToTdDesk = true;
		cartItem.isTransportDeskItem = true;
		cartItem.allowedToDeliveryFromTransportDesk = true;
		cartItem.importDetails = {
			originalQuantity: 300
		};
	}

	updateOrderChildItems(cartItem, childItems) {
		let childid = 1;
		cartItem.childItems = [];
		childItems.forEach(childItem => {
			childItem.quantity = childItem.QuantityInSPR;
			childItem.orderNo = cartItem.orderNo;
			let childCartItem = this.createShoppingCartItemFromOrder(childItem);
			this.assignOrderAttributes(childCartItem, childItem);
			childCartItem.setCartItemId(childid);
			cartItem.childItems.push(childCartItem);
			childid = childid + 1;
		});
	}

	updateChildItems(cartItem, childItems) {
		let childid = 1;
		cartItem.childItems = [];
		childItems.forEach(childItem => {
			let childCartItem = this.createShoppingCartItem(childItem.retailItem, childItem.QuantityInSPR);
			childCartItem.setCartItemId(childid);
			cartItem.childItems.push(childCartItem);
			childid = childid + 1;
		});
	}

	setCartItemModificationData(cartItem) {
		cartItem.canIncreaseQuantity = (cartItem.allowedModifications && cartItem.allowedModifications.includes("ADD_QUANTITY")) || false;
		cartItem.canDecreaseQuantity = (cartItem.allowedModifications && cartItem.allowedModifications.includes("CANCEL_QUANTITY")) || false;
		cartItem.canChangePrice = (cartItem.allowedModifications && cartItem.allowedModifications.includes("PRICE")) || false;
		cartItem.canRemoveItem = (cartItem.allowedModifications && cartItem.allowedModifications.includes("CANCEL")) || false;
	}
	//TODO: handle subitems for group items
	createCombinationCartItem(item, quantity, subItems) {
		let groupItem = new ShoppingCartItem();
		let childItems = [];

		subItems.forEach(subitem => {
			let childCartItem = this.createShoppingCartItem(subitem.retailItem, parseFloat(subitem.itemQuantity));
			childItems.push(childCartItem);
			if (subitem.retailItem.childItems) {
				this.updateChildItems(childCartItem, subitem.retailItem.childItems);
			}
		});
		groupItem.initItem(item, quantity, childItems, true);
		groupItem.canCreateOrder = groupItem.quantity <= groupItem.availableStockToSell;

		return groupItem;
	}

	createGroupItem(group, quantity, subItems) {
		let groupItem = new ShoppingCartItem();
		groupItem.initItem(group, quantity, subItems, true);
		groupItem.canCreateOrder = groupItem.quantity <= groupItem.availableStockToSell;

		return groupItem;
	}

	updateQuantity(cartItem, newQuantity) {
		if (cartItem !== undefined) {
			cartItem.quantity = newQuantity;

			cartItem.price = new PriceModel({ ...cartItem.retailItem.price });
			cartItem.price.Type = NORMAL;
			cartItem.familyPrice = new PriceModel({
				...cartItem.retailItem.familyPrice
			});
			cartItem.familyPrice.Type = FAMILY;

			cartItem.canCreateOrder = cartItem.quantity <= cartItem.availableStockToSell;
			cartItem.totalPrice.Value = cartItem.price.Value * cartItem.quantity;

			cartItem.totalFamilyPrice.Value = cartItem.familyPrice.Value * cartItem.quantity;
			if (cartItem.retailItem.ItemType === ART) {
				cartItem.totalQuantity = newQuantity;
			}
			if (cartItem.retailItem.ItemType === SPR && cartItem.childItems.length > 0) {
				cartItem.childItems.forEach(cItem => {
					cItem.totalQuantity = cItem.quantity * newQuantity;
					cItem.totalPrice.Value = cItem.price.Value * cItem.totalQuantity;

					cItem.totalFamilyPrice.Value = cItem.familyPrice.Value * cItem.totalQuantity;
				});
			}
		}
	}

	updateGroupQuantity(cartItem, newQuantity, ignoreParentQuantity = false, childItemId) {
		if (cartItem !== undefined) {
			cartItem.quantity = newQuantity;
			cartItem.canCreateOrder = cartItem.quantity <= cartItem.availableStockToSell;
			cartItem.price.Value = 0;
			cartItem.familyPrice.Value = 0;
			if (cartItem.childItems.length > 0) {
				cartItem.childItems.forEach(cItem => {
					if (ignoreParentQuantity) {
						if (childItemId === cItem.id) {
							cItem.totalQuantity = cItem.quantity;
						}
					} else {
						cItem.totalQuantity = cItem.quantity * newQuantity;
					}

					cItem.totalPrice.Value = cItem.price.Value * cItem.totalQuantity;
					cItem.totalFamilyPrice.Value = cItem.familyPrice.Value * cItem.totalQuantity;

					cartItem.price.Value = cartItem.price.Value + cItem.totalPrice.Value;
					cartItem.familyPrice.Value = cartItem.familyPrice.Value + cItem.totalFamilyPrice.Value;
				});
			}
			cartItem.totalPrice.Value = cartItem.price.Value;
			cartItem.totalFamilyPrice.Value = cartItem.familyPrice.Value;
		}
	}
	updateGroupPrice(cartItem, newQuantity) {
		if (cartItem !== undefined) {
			cartItem.quantity = newQuantity;
			cartItem.canCreateOrder = cartItem.quantity <= cartItem.availableStockToSell;
			cartItem.totalPrice.Value = cartItem.price.Value * cartItem.quantity;
			cartItem.totalFamilyPrice.Value = cartItem.familyPrice.Value * cartItem.quantity;
			if (cartItem.childItems.length > 0) {
				cartItem.childItems.forEach(cItem => {
					cItem.totalQuantity = cItem.quantity * newQuantity;
					cItem.totalPrice.Value = cItem.price.Value * cItem.totalQuantity;
					cItem.totalFamilyPrice.Value = cItem.familyPrice.Value * cItem.totalQuantity;
				});
			}
		}
	}

	getTotal(items) {
		let total = 0;
		let currency = "USD";
		items.forEach(item => {
			if (item) {
				total = total + item.totalPrice.Value;
				currency = item.totalPrice.Currency;
			}
		});
		return new PriceModel({
			Value: total,
			Currency: currency,
			Type: NORMAL
		});
	}

	getTotalFamilyPrice(items) {
		let total = 0;
		let currency = "";

		items.forEach(item => {
			let val;
			if (item.totalFamilyPrice && item.totalFamilyPrice.Value && item.totalFamilyPrice.Value !== 0 && !isNaN(item.totalFamilyPrice.Value)) {
				val = item.totalFamilyPrice.Value;
			} else {
				val = item.totalPrice.Value;
			}
			total = total + val;
			currency = item.totalPrice.Currency;
		});
		return new PriceModel({
			Value: total,
			Currency: currency,
			Type: NORMAL
		});
	}

	getTotalItemCount(items) {
		let newTotalCount = 0;
		items.forEach(item => {
			newTotalCount = newTotalCount + item.quantity;
		});
		if (newTotalCount === 0) {
			newTotalCount = items.length;
		}
		return newTotalCount;
	}

	reAssignCartItemId(items, bookedServices = []) {
		let id = 0;
		let allItems = this.getFlatItems(items);
		let lineIds = allItems
			.map(x => {
				if (x.soSalesItemNo) {
					return parseInt(x.soSalesItemNo);
				}
				if (x.id && x.id !== "") {
					return parseInt(x.id);
				}
			})
			.filter(x => x !== undefined && x !== "");
		if (bookedServices) {
			bookedServices.forEach(service => {
				if (service.isCentralDelivery) {
					service.subDeliveries.forEach(subDel => {
						if (subDel.lineId) lineIds.push(parseInt(subDel.lineId));
					});
				}
				if (service.isProvidedService) {
					if (service.lineId) lineIds.push(parseInt(service.lineId));
				}
			});
			lineIds = lineIds.filter(x => !isNaN(x) && x !== undefined && x !== "");
			if (lineIds.length > 0) id = Math.max(...lineIds);
		}
		items.forEach(item => {
			if (item.isCombination) {
				id = id + 1;
				item.id = item.soSalesItemNo || id;
				item.soSalesItemNo = item.id;
				item.childItems.forEach(subItem => {
					id = id + 1;
					if (subItem.retailItem.ItemType === SPR) {
						subItem.id = subItem.soSalesItemNo || id;
						subItem.soSalesItemNo = subItem.id;
						subItem.childItems.forEach(subChildItem => {
							id = id + 1;
							subChildItem.id = subChildItem.soSalesItemNo || id;
							subChildItem.soSalesItemNo = subChildItem.id;
						});
					} else {
						subItem.id = subItem.soSalesItemNo || id;
						subItem.soSalesItemNo = subItem.id;
					}
				});
			} else {
				id = id + 1;
				if (item.retailItem.ItemType === SPR) {
					item.id = item.soSalesItemNo || id;
					item.soSalesItemNo = item.id;
					item.childItems.forEach(subChildItem => {
						id = id + 1;
						subChildItem.id = subChildItem.soSalesItemNo || id;
						subChildItem.soSalesItemNo = subChildItem.id;
					});
				} else {
					item.id = item.soSalesItemNo || id;
					item.soSalesItemNo = item.id;
				}
			}
		});
		return items;
	}
	getLatestItemId(items, bookedServices) {
		let id = 0;
		let allItems = this.getFlatItems(items);
		let lineIds = allItems
			.map(x => {
				if (x.soSalesItemNo) {
					return parseInt(x.soSalesItemNo);
				}
				if (x.id && x.id !== "") {
					return parseInt(x.id);
				}
			})
			.filter(x => x && x !== "");
		lineIds = lineIds.filter(x => !isNaN(x) && x !== undefined && x !== "");
		if (lineIds.length > 0) id = Math.max(...lineIds);

		if (bookedServices) {
			bookedServices.forEach(service => {
				if (service.isCentralDelivery) {
					service.subDeliveries.forEach(subDel => {
						if (subDel.lineId) lineIds.push(parseInt(subDel.lineId));
						if (subDel.deliveryNo) lineIds.push(parseInt(subDel.deliveryNo));
					});
				}
				if (service.isProvidedService) {
					if (service.lineId) lineIds.push(parseInt(service.lineId));
				}
			});
			lineIds = lineIds.filter(x => x && !isNaN(x) && x !== "");
			if (lineIds.length > 0) id = Math.max(...lineIds);
		}
		return id;
	}
	reAssignCartItemStock(items, stockItems, type) {
		items.forEach(item => {
			if (item.isCombination) {
				item.childItems.forEach(subItem => {
					if (subItem.retailItem.ItemType === SPR) {
						let stockItem = stockItems.find(x => x.ItemId === subItem.itemId);

						subItem = this.updateStock(subItem, stockItem, type);
						subItem.childItems.forEach(subChildItem => {
							let stockItem = stockItems.find(x => x.ItemId === subChildItem.itemId);
							subChildItem = this.updateStock(subChildItem, stockItem, type);
						});
					} else {
						let stockItem = stockItems.find(x => x.ItemId === subItem.itemId);
						subItem = this.updateStock(subItem, stockItem, type);
					}
				});
			} else {
				if (item.retailItem.ItemType === SPR) {
					let stockItem = stockItems.find(x => x.ItemId === item.itemId);
					item = this.updateStock(item, stockItem, type);
					item.childItems.forEach(subChildItem => {
						let stockItem = stockItems.find(x => x.ItemId === subChildItem.itemId);
						subChildItem = this.updateStock(subChildItem, stockItem, type);
					});
				} else {
					let stockItem = stockItems.find(x => x.ItemId === item.itemId);
					item = this.updateStock(item, stockItem, type);
				}
			}
		});
		return items;
	}
	/**
	 *
	 *
	 * @param {ShoppingCartItem} cartItem
	 * @param {Object} stock
	 * @param {String} type
	 * @returns
	 * @memberof ShoppingCartItemFactory
	 */
	updateStock(cartItem, stock, type) {
		if (type === "STOCK") {
			cartItem.retailItem.ServicePrices = stock.ServicePrices;
			let storePrice = getStoreprice(stock, NORMAL);

			if (storePrice) {
				if (cartItem.isRefund) {
					storePrice = { ...storePrice, Value: 0 };
				}
				cartItem.retailItem.price = { ...storePrice };

				cartItem.price = {
					...storePrice,
					...cartItem.price
				};

				cartItem.totalPrice = { ...storePrice };
				cartItem.totalPrice.Value = cartItem.totalPrice.Value * cartItem.quantity;
			}
			let familyPrice = getStoreprice(stock, FAMILY);
			if (familyPrice) {
				if (cartItem.isRefund) {
					familyPrice = { ...familyPrice, Value: 0 };
				}
				cartItem.retailItem.familyPrice = { ...familyPrice };
				cartItem.familyPrice = { ...familyPrice, ...cartItem.familyPrice };

				cartItem.totalFamilyPrice = { ...familyPrice };
				cartItem.totalFamilyPrice.Type = FAMILY;
				cartItem.totalFamilyPrice.Value = cartItem.totalFamilyPrice.Value * cartItem.quantity;
			}

			cartItem.retailItem.storeInfo = {
				SalesRestrictions: stock.SalesRestrictions,
				StockAvailabilities: stock.StockAvailabilities,
				SalesLocations: stock.SalesLocations,
				SalesMethodCode: stock.SalesMethodCode
			};
			let totalstock = stock.StockAvailabilities;

			if (totalstock) {
				cartItem.retailItem.availableStockToSell = totalstock.ToSell;
			} else {
				cartItem.retailItem.availableStockToSell = 0;
			}

			cartItem.availableStockToSell = cartItem.retailItem.availableStockToSell;

			cartItem.canCreateOrder = cartItem.quantity <= cartItem.availableStockToSell;
			return cartItem;
		}
		return cartItem;
	}
	canCreateOrder(items) {
		return Enumerable.from(items).all(x => x.canCreateOrder);
	}

	getTotalWeight(items, byUnit) {
		let weight = 0;

		items.forEach(cartItem => {
			if (cartItem.isCombination) {
				cartItem.childItems.forEach(chItem => {
					if (chItem.isProduct) {
						chItem.childItems.forEach(chsubItem => {
							weight += cartItem.quantity * chItem.quantity * chsubItem.quantity * chsubItem.retailItem.PackageWeight;
							getPackageWeight(chsubItem.retailItem, byUnit);
						});
					} else {
						weight += cartItem.quantity * chItem.quantity * getPackageWeight(chItem.retailItem, byUnit);
					}
				});
			} else if (cartItem.isProduct) {
				cartItem.childItems.forEach(childItem => {
					weight += cartItem.quantity * childItem.quantity * getPackageWeight(childItem.retailItem, byUnit);
				});
			} else {
				weight += cartItem.quantity * getPackageWeight(cartItem.retailItem, byUnit);
			}
		});

		return Math.round(weight);
	}

	getWeightUnit(items) {
		let unit = "";

		for (let i = 0; i < items.length; i++) {
			if (items[i].isCombination) {
				for (let ii = 0; ii < items[i].childItems.length; ii++) {
					if (items[i].childItems[ii].isProduct) {
						for (let iii = 0; iii < items[i].childItems[ii].childItems.length; iii++) {
							unit = getPackageWeightUnit(items[i].childItems[ii].childItems[iii].retailItem);
						}
					} else {
						unit = getPackageWeightUnit(items[i].childItems[ii].retailItem);
					}
				}
			} else if (items[i].isProduct) {
				for (let ii = 0; ii < items[i].childItems.length; ii++) {
					unit = getPackageWeightUnit(items[i].childItems[ii].retailItem);
				}
			} else {
				unit = getPackageWeightUnit(items[i].retailItem);
			}

			if (unit !== undefined && unit !== "") {
				break;
			}
		}

		return unit;
	}

	getTotalNoPackages(items) {
		let packages = 0;

		try {
			items.forEach(cartItem => {
				if (cartItem.isCombination) {
					cartItem.childItems.forEach(chItem => {
						if (chItem.isProduct) {
							chItem.childItems.forEach(chsubItem => {
								packages += cartItem.quantity * chItem.quantity * chsubItem.quantity * chsubItem.retailItem.NumberOfPackages;
							});
						} else {
							packages += cartItem.quantity * chItem.quantity * chItem.retailItem.NumberOfPackages;
						}
					});
				} else if (cartItem.isProduct) {
					cartItem.childItems.forEach(childItem => {
						const numPkg = childItem.retailItem.NumberOfPackages;

						let val = cartItem.quantity * childItem.quantity;
						packages += val * numPkg;
					});
				} else {
					packages += cartItem.quantity * cartItem.retailItem.NumberOfPackages;
				}
			});
		} catch (error) {
			//console.error(error);
		}
		return packages;
	}

	updateGroupImages(group) {
		try {
			let imageList = [];
			Enumerable.from(group.childItems)
				.take(4)
				.forEach(cartItem => {
					let imgItem = Enumerable.from(cartItem.retailItem.Images)
						.where(y => y && y.S3)
						.firstOrDefault();

					imageList.push({
						Id: GUID.Guid(),
						Url: imgItem && imgItem.S3 ? imgItem.S3.Url : ""
					});
				});

			group.retailItem.imageList = imageList;
		} catch (error) {
			//
		}
	}

	updateGroupStock(group) {
		try {
			let items = Enumerable.from(group.childItems)
				.where(x => x.retailItem.storeInfo)
				.toArray();
			let stock = Enumerable.from(items)
				.select(function (cartItem) {
					if (cartItem.retailItem.isPieces) {
						return Math.floor(cartItem.retailItem.availableStockToSell / cartItem.quantity);
					} else {
						return cartItem.retailItem.availableStockToSell / cartItem.quantity;
					}
				})
				.min();

			group.availableStockToSell = Math.floor(stock);
			group.retailItem.availableStockToSell = Math.floor(stock);
		} catch (error) {
			//
		}
	}

	reAlignCartItemswithoutUnAvailableLines(cartItems, unAvailableItems) {
		let newItems = [];
		let unAvailableCartItems = [];
		try {
			cartItems.forEach(cartItem => {
				if (cartItem.isCombination) {
					let subItems = [];
					cartItem.childItems.forEach(chItem => {
						if (chItem.isProduct) {
							let sprSubItems = [];
							let sprNotFull = false;
							chItem.childItems.forEach(chsubItem => {
								if (isAvailable(chsubItem, unAvailableItems)) {
									sprSubItems.push(chsubItem);
								} else {
									let availableQty = getAvailableQty(chsubItem, unAvailableItems);
									if (availableQty > 0) {
										chsubItem.quantity = availableQty;
										sprSubItems.push(chsubItem);
									} else {
										unAvailableCartItems.push(chsubItem);
									}
									sprNotFull = true;
								}
							});
							if (chItem.childItems.length !== sprSubItems.length || sprNotFull) {
								subItems = subItems.concat(sprSubItems);
							} else {
								subItems.push(chItem);
							}
						} else {
							if (isAvailable(chItem, unAvailableItems)) {
								subItems.push(chItem);
							} else {
								let availableQty = getAvailableQty(chItem, unAvailableItems);
								if (availableQty > 0) {
									chItem.quantity = availableQty;
									subItems.push(chItem);
								} else {
									unAvailableCartItems.push(chItem);
								}
							}
						}
					});
					if (subItems.length > 0) {
						cartItem.childItems = subItems;
						newItems.push(cartItem);
					}
				} else if (cartItem.isProduct) {
					let sprChildItems = [];
					let sprNotFull = false;
					cartItem.childItems.forEach(childItem => {
						if (isAvailable(childItem, unAvailableItems)) {
							sprChildItems.push(childItem);
						} else {
							let availableQty = getAvailableQty(childItem, unAvailableItems);
							if (availableQty > 0) {
								childItem.quantity = availableQty;
								sprChildItems.push(childItem);
							} else {
								unAvailableCartItems.push(childItem);
							}
							sprNotFull = true;
						}
					});
					if (cartItem.childItems.length !== sprChildItems.length || sprNotFull) {
						let group = {
							ItemType: "GROUP",
							Name: "Group",
							itemDescription: "",
							ItemId: ``,
							price: cartItem.price,
							familyPrice: cartItem.familyPrice,
							availableStockToSell: cartItem.availableStockToSell,
							isPlanner: false,
							imageList: []
						};
						let groupItem = this.createGroupItem(group, cartItem.quantity, sprChildItems);
						this.updateGroupStock(groupItem);
						this.updateGroupQuantity(groupItem, groupItem.quantity, true);
						this.updateGroupImages(groupItem);
						newItems.push(groupItem);
					} else {
						newItems.push(cartItem);
					}
				} else {
					if (isAvailable(cartItem, unAvailableItems)) {
						newItems.push(cartItem);
					} else {
						let availableQty = getAvailableQty(cartItem, unAvailableItems);
						if (availableQty > 0) {
							cartItem.quantity = availableQty;
							newItems.push(cartItem);
						} else {
							unAvailableCartItems.push(cartItem);
						}
					}
				}
			});
		} catch (error) {
			console.error(error);
		}
		newItems = this.reAssignCartItemId(newItems);
		return { newItems, unAvailableCartItems };
	}
	createServiceReferenceItem(cartItem, lineId, quantity) {
		return {
			serviceReference: {
				quantity: quantity,
				lineId: lineId,
				id: parseInt(lineId)
			},
			...cartItem
		};
	}

	unlockLines(items) {
		items.forEach(item => {
			if (item.isCombination) {
				item.isLocked = false;
				item.orderItemReference = undefined;
				item.childItems.forEach(subItem => {
					subItem.isLocked = false;
					subItem.orderItemReference = undefined;
					if (subItem.retailItem.ItemType === SPR) {
						subItem.childItems.forEach(subChildItem => {
							subChildItem.isLocked = false;
							subChildItem.orderItemReference = undefined;
						});
					} else {
						subItem.isLocked = false;
						subItem.orderItemReference = undefined;
					}
				});
			} else {
				if (item.retailItem.ItemType === SPR) {
					item.isLocked = false;
					item.orderItemReference = undefined;
					item.childItems.forEach(subChildItem => {
						subChildItem.isLocked = false;
						subChildItem.orderItemReference = undefined;
					});
				} else {
					item.isLocked = false;
					item.orderItemReference = undefined;
				}
			}
		});
		return items;
	}

	parseRetailItem(retailItem) {
		if (retailItem.Description) {
			return {
				Name: retailItem.Name,
				ItemId: retailItem.ItemId,
				ItemType: retailItem.ItemType,
				ItemUnitCode: retailItem.ItemUnitCode,
				IsMetric: retailItem.IsMetric,
				NumberOfPackages: retailItem.NumberOfPackages,
				itemDescription: retailItem.itemDescription,
				PackageWeight: retailItem.PackageWeight,
				PaNumber: retailItem.PaNumber,
				ProfessionalAssemblyTime: retailItem.ProfessionalAssemblyTime,
				PredictedAssemblyTime: retailItem.PredictedAssemblyTime,
				price: retailItem.price,
				webPrice: retailItem.webPrice,
				familyPrice: retailItem.familyPrice,
				imageUrl: retailItem.imageUrl,
				isAssemblyRequired: retailItem.IsAssemblyRequired
			};
		}
		return retailItem;
	}
	/*eslint-disable*/
	getFlatItems(items) {
		let allItems = [];
		items.forEach(item => {
			if (item.isCombination) {
				allItems.push({
					...item,
					childItems: []
				});
				item.childItems.forEach(subItem => {
					if (subItem.retailItem.ItemType === "SPR") {
						allItems.push({
							...subItem,
							childItems: []
						});
						subItem.childItems.forEach(subChildItem => {
							allItems.push({
								...subChildItem,
								childItems: []
							});
						});
					} else {
						allItems.push({
							...subItem,
							childItems: []
						});
					}
				});
			} else {
				if (item.retailItem.ItemType === "SPR") {
					allItems.push({
						...item,
						childItems: []
					});
					item.childItems.forEach(subChildItem => {
						allItems.push({
							...subChildItem,
							childItems: []
						});
					});
				} else {
					allItems.push({
						...item,
						childItems: []
					});
				}
			}
		});
		return allItems;
	}
}

const isAvailable = (cartItem, unAvailableItems) => {
	let unavaialbleItem = unAvailableItems.find(x => x.id.toString() === cartItem.id.toString());

	return unavaialbleItem === undefined;
};

const getAvailableQty = (cartItem, unAvailableItems) => {
	let unavaialbleItem = unAvailableItems.find(x => x.id.toString() === cartItem.id.toString());
	let availQuantity = unavaialbleItem ? parseInt(unavaialbleItem.unavailableQty) - parseInt(cartItem.quantity) : parseInt(cartItem.quantity);
	return availQuantity;
};
