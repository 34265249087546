import React from "react";

import Lightbox from "@ingka/lightbox";
import ModalWindow from "@ingka/modal-window";

import QuotationView from "./quotationView";
import UpdateQuotationView from "./updateQuotationView";

const QuotationDialog = props => {
	const { updateQuotation } = props;

	const closeView = () => {
		props.onClose(true);
	};

	return (
		<Lightbox
			focusLockProps={focus}
			handleCloseBtn={() => {
				closeView();
			}}
			light={"false"}
			visible
		>
			<ModalWindow isFullscreen>{updateQuotation ? <UpdateQuotationView {...props}></UpdateQuotationView> : <QuotationView {...props} />}</ModalWindow>
		</Lightbox>
	);
};

const focus = {
	returnFocus: false,
	autoFocus: true
};

export default QuotationDialog;
