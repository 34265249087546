import { applyMiddleware, compose, createStore } from "redux";
import * as reduxthunk from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import { persistStore } from "redux-persist";
const thunk = reduxthunk.default;
export default function customStore(history, reducers, initialState) {
	const middleware = [thunk, routerMiddleware(history)];
	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
	const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(...middleware, logger, crashReporter)));
	persistStore(store);
	return store;
}
// eslint-disable-next-line no-unused-vars
const logger = store => next => action => {
	let result = next(action);
	return result;
};
// eslint-disable-next-line no-unused-vars
const crashReporter = store => next => action => {
	try {
		return next(action);
	} catch (err) {
		//
	}
};
