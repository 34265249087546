/**
 * checks if the current route is in /external/*
 *
 *
 * @return {Boolean} Returns isinexternal or not
 */
const isExternalClientUrl = () => {
	return window.location.href.includes("external/") || window.location.href.includes("customer.html");
};
const isExternalCustomerUrl = () => {
	return window.location.href.includes("external/customer") || window.location.href.includes("customer.html");
};
const isExternalImportUrl = () => {
	return window.location.href.includes("external/import");
};
const isExternalSearchUrl = () => {
	return window.location.href.includes("external/search") || window.location.href.includes("external/productdetail");
};

const isExternalOrderServicesUrl = () => {
	return window.location.href.includes("external/orderservices");
};
const isExternalReverseFlowUrl = () => {
	return window.location.href.includes("external/reverseflow");
};
export { isExternalClientUrl, isExternalImportUrl, isExternalOrderServicesUrl, isExternalSearchUrl, isExternalCustomerUrl, isExternalReverseFlowUrl };
