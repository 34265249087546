import React from "react";
import styled from "styled-components";

const addStyles = styledObj => {
	return styledObj.div.attrs(props => ({
		id: props.id
	}))`
  cursor: ${props => props.cursor || "default"}!important;
  display: flex;
  align-items: ${props => props.align || "center"};
  flex-direction: row;
  flex-wrap: nowrap;
  min-height: ${props => props.minheight};
  margin-top: ${props => props.margintop};
  margin-bottom: ${props => props.marginbottom};
  margin-right: ${props => props.marginright};
  margin-left: ${props => props.marginleft};
  padding: ${props => props.padding};
  margin-right: ${props => props.marginright};
  align-items: ${props => props.verticalalign};
  min-width: ${props => props.minwidth};
  justify-content: ${props => props.justifyContent};
  width: ${props => props.width};
`;
};

const Client = window["styled-components"] ? addStyles(window["styled-components"]) : addStyles(styled);

const Mfe = addStyles(styled);

const Grid = props => {
	return props.client ? <Client {...props} /> : <Mfe {...props} />;
};

export default Grid;
