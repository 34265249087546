import { HideBodyScrollStyle } from "./../../../../globalStyle";
import React from "react";
import modelTypes from "./types";
// import styled from "styled-components";
// import posed, { PoseGroup } from "react-pose";
// import inputTypes from "./types";
const CartModelPopupContext = React.createContext(null);
const useCartModelPopup = () => {
	const [config, setConfig] = React.useContext(CartModelPopupContext);

	const getCartModel = React.useCallback(
		(type, conf = {}) => {
			let resolver = null;

			const resolve = value => {
				setConfig(null);
				if (resolver) {
					resolver(value);
				}
			};
			const cancel = () => {
				setConfig(null);
				if (resolver) {
					resolver();
				}
			};

			setConfig({
				type,
				...conf,
				resolve,
				cancel,
				test: "test"
			});
			return new Promise(resolve => {
				resolver = resolve;
			});
		},
		[setConfig]
	);
	return { getCartModel, modelOpen: !!config };
};
const CartModelPopup = () => {
	const [input] = React.useContext(CartModelPopupContext);
	const { type = "", cancel, resolve, ...config } = input || {};

	const popupConf = modelTypes[type] || {};
	const Component = popupConf.component;

	return (
		<>
			{input ? (
				<>
					<HideBodyScrollStyle />
					<Component {...config} onSubmit={resolve} onClose={cancel} />
				</>
			) : (
				""
			)}
		</>
	);
};

const CartModelPopupProvider = ({ children }) => {
	const [input, setInput] = React.useState(null);

	return <CartModelPopupContext.Provider value={[input, setInput]}>{children}</CartModelPopupContext.Provider>;
};

const modelViewType = {
	alert: "alert",
	informational: "informational"
};
const modelBehaviourType = {
	normal: "normal",
	popup: "normal"
};
export { useCartModelPopup, CartModelPopup, CartModelPopupProvider, modelBehaviourType, modelViewType, CartModelPopupContext };
