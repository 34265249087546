import { device } from "@coworker/sharedlibrary";
import styled from "styled-components";
import { changeQuantity, removeItem } from "./../../../store/cart/cartActions";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { GUID } from "@coworker/sharedlibrary";
import CartItemCard from "./cartItemCard/cartItemCard";
import TransportDeskCartItemCard from "./cartItemCard/transportDeskCartItemCard";
import { useCartModelPopup } from "./modelPopup";
import Accordion, { AccordionItem } from "@ingka/accordion";
import { getStoreConfigSelector } from "../../../utils/shellObjectService";
import { CanModifyOrder } from "../canCreateSalesOrderValidator";
import { isISomEnabledSelector } from "../../../store/shell/shellSelectors";
const Container = styled.div`
	.accordion {
		background: transparent;
		width: 100%;
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.accordion__heading {
		min-height: 90px;

		&.chunky-header {
			padding: 20px;
			border-top: none;

			@media ${device.medium} {
				width: calc(100% - 24px);
				padding: 12px;
			}
		}
	}

	.accordion-item-header {
		font-weight: 700;
		padding: 0 20px;
		border-top: none;
	}

	.accordion__item {
		min-height: 90px;
	}
`;

const CartItemsView = () => {
	const isIsomEnabled = useSelector(state => isISomEnabledSelector(state));

	const canModifyOrder = useSelector(state => CanModifyOrder(state));
	const { messages } = useSelector(state => state.intl);
	const { isTransportDeskMode, items, isModifyOrderinProgress, hasServices } = useSelector(state => state.cartController);
	const { isCashLine } = getStoreConfigSelector();
	const cartItems = items;
	const { getCartModel } = useCartModelPopup();
	const dispatch = useDispatch();
	if (!cartItems || cartItems.length === 0) {
		return "";
	}

	function onhandleUpdateQuantity(id, quantity) {
		dispatch(changeQuantity(id, quantity));
	}

	async function onItemRemove(cartItemId) {
		if (isModifyOrderinProgress && cartItems.length === 1) {
			await getCartModel("removeCartItemDialog", {
				showDelete: false
			});
		} else {
			var result = await getCartModel("removeCartItemDialog", {
				showDelete: true
			});

			if (result) {
				dispatch(removeItem(cartItemId));
			}
		}
	}

	return (
		<Container>
			<Accordion>
				<AccordionItem open={true} title={messages.SG_LBL_Items} key={GUID.Guid()}>
					{isCashLine && items.length === 0 ? (
						<div>{messages.SG_LBL_ScanItemsInCashLine || "Please scan items to start the order creation"}</div>
					) : (
						<CartItemsList
							isTransportDeskMode={isTransportDeskMode}
							hasServices={hasServices}
							isIsomEnabled={isIsomEnabled}
							canModifyOrder={canModifyOrder}
							isModifyOrderinProgress={isModifyOrderinProgress}
							messages={messages}
							handleUpdateQuantity={(id, quantity) => {
								onhandleUpdateQuantity(id, quantity);
							}}
							handleRemove={cartItem => {
								onItemRemove(cartItem);
							}}
							cartItems={cartItems}
						/>
					)}
				</AccordionItem>
			</Accordion>
		</Container>
	);
};

// eslint-disable-next-line react/display-name
const CartItemsList = React.memo(function CartItemsList({
	isModifyOrderinProgress,
	canModifyOrder,
	cartItems,
	handleRemove,
	handleUpdateQuantity,
	isIsomEnabled,
	hasServices,
	isTransportDeskMode
}) {
	if (isTransportDeskMode) {
		return cartItems.map(cartitem => (
			<TransportDeskCartItemCard
				key={`TransportDeskCartItemCard-${cartitem.id}`}
				handleUpdateQuantity={handleUpdateQuantity}
				handleRemove={handleRemove}
				cartItem={cartitem}
				isModifyOrderinProgress={isModifyOrderinProgress}
			/>
		));
	}
	return cartItems.map(cartitem => (
		<CartItemCard
			hasServices={hasServices}
			isIsomEnabled={isIsomEnabled}
			key={GUID.Guid()}
			canModifyOrder={canModifyOrder}
			isModifyOrderinProgress={isModifyOrderinProgress}
			handleUpdateQuantity={handleUpdateQuantity}
			handleRemove={handleRemove}
			cartItem={cartitem}
		/>
	));
});

export default CartItemsView;
