import React from "react";
import styled, { css } from "styled-components";
import { device } from "@coworker/sharedlibrary";

const addStyles = styledObj => {
	return styledObj.div`
  display: ${props => props.display || "flex"};
  ${props =>
		props.flexDirection &&
		css`
			flex-direction: ${props.narrowStyle ? props.mobileFlexDirection : props.flexDirection};
		`}

  ${props =>
		props.justifyContent &&
		css`
			justify-content: ${props.narrowStyle ? props.mobileJustifyContent : props.justifyContent};
		`}

    ${props =>
		props.alignItems &&
		css`
			align-items: ${props.narrowStyle ? props.mobileAlignItems : props.alignItems};
		`}

      ${props =>
			props.overflow &&
			css`
				overflow: ${props.overflow};
			`}
        ${props =>
			props.overflowY &&
			css`
				overflow-y: ${props.overflowY};
			`}

      ${props =>
			props.width &&
			css`
				width: ${props.narrowStyle ? props.mobileWidth : props.width};
			`}

        ${props =>
			props.minWidth &&
			css`
				min-width: ${props.minWidth};
			`}

          ${props =>
				props.maxWidth &&
				css`
					max-width: ${props.maxWidth};
				`}


        ${props =>
			props.height &&
			css`
				height: ${props.narrowStyle ? props.mobileHeight : props.height};
			`}

          ${props =>
				props.minHeight &&
				css`
					min-height: ${props.minHeight};
				`}


        ${props =>
			props.display &&
			css`
				display: ${props.narrowStyle ? props.mobileDisplay : props.display || "flex"};
			`}

          ${props =>
				props.flexWrap &&
				css`
					flex-wrap: ${props.narrowStyle ? props.mobileFlexWrap : props.flexWrap};
				`}

          ${props =>
				props.margin &&
				css`
					margin: ${props.narrowStyle ? props.mobileMargin : props.margin};
				`}

            ${props =>
				props.padding &&
				css`
					padding: ${props.narrowStyle ? props.mobilePadding : props.padding};
				`}

              

            ${props =>
				props.showMobile &&
				css`
					display: ${props.narrowStyle ? props.display || "flex" : "none"};
				`}

              ${props =>
					props.hideMobile &&
					css`
						display: ${props.narrowStyle ? "none" : props.display || "flex"};
					`}
                ${props =>
					props.custom &&
					css`
						${props.custom}
					`}
                  ${props =>
						props.textAlign &&
						css`
							text-align: ${props.narrowStyle ? props.mobileTextAlign : props.textAlign};
						`}
  @media ${props => props.mobileBreakpoint} {
    ${props =>
		props.mobileDisplay &&
		css`
			display: ${props.mobileDisplay};
		`}
    ${props =>
		props.mobileFlexDirection &&
		css`
			flex-direction: ${props.mobileFlexDirection};
		`}

      ${props =>
			props.mobileJustifyContent &&
			css`
				justify-content: ${props.mobileJustifyContent};
			`}

        ${props =>
			props.mobileAlignItems &&
			css`
				align-items: ${props.mobileAlignItems};
			`}

          ${props =>
				props.mobileFlexWrap &&
				css`
					flex-wrap: ${props.mobileFlexWrap};
				`}

          ${props =>
				props.mobileWidth &&
				css`
					width: ${props.mobileWidth};
				`}


          ${props =>
				props.mobileHeight &&
				css`
					height: ${props.mobileHeight};
				`}

            ${props =>
				props.mobileMargin &&
				css`
					margin: ${props.mobileMargin};
				`}

              ${props =>
					props.mobilePadding &&
					css`
						padding: ${props.mobilePadding};
					`}

              ${props =>
					props.showMobile &&
					css`
						display: ${props.display || "flex"};
					`}

                ${props =>
					props.hideMobile &&
					css`
						display: none;
					`}

                  ${props =>
						props.mobileTextAlign &&
						css`
							text-align: ${props.mobileTextAlign};
						`}
  }
  ${props =>
		props.mobileCustom &&
		css`
			${props.mobileCustom}
		`}
`;
};

const Client = window["styled-components"] ? addStyles(window["styled-components"]) : addStyles(styled);

const Mfe = addStyles(styled);

const Container = props => {
	const {
		styles,
		mobileStyles,
		narrowStyle,
		showMobile,
		hideMobile,
		mobileBreakpoint = device.small,
		children,
		jcCenter,
		jcSBetween,
		jcSAround,
		jcSEvenly,
		jcCenterMob,
		jcSBetweenMob,
		jcSAroundMob,
		jcSEvenlyMob,
		fdRow,
		fdRowRev,
		fdCol,
		fdColRev,
		fdRowMob,
		fdRowRevMob,
		fdColMob,
		fdColRevMob,
		aiStretch,
		aiCenter,
		aiFStart,
		aiFEnd,
		aiFBase,
		aiStretchMob,
		aiCenterMob,
		aiFStartMob,
		aiFEndMob,
		aiFBaseMob,
		width,
		minWidth,
		maxWidth,
		height,
		minHeight,
		maxHeight,
		widthMob,
		minWidthMob,
		maxWidthMob,
		heightMob,
		minHeightMob,
		maxHeightMob,
		padding,
		paddingMob,
		margin,
		marginMob,
		taLeft,
		taCenter,
		taRight,
		taLeftMob,
		taCenterMob,
		taRightMob,
		dBlock,
		dFlex,
		dNone,
		dInline,
		dBlockMob,
		dFlexMob,
		dNoneMob,
		dInlineMob,
		onClick
	} = props;
	let mobileStylesObj = {};
	if (mobileStyles) {
		for (let [key, value] of Object.entries(mobileStyles)) {
			const updatedKey = key.charAt(0).toUpperCase() + key.slice(1);
			mobileStylesObj = { ...mobileStylesObj, [`mobile${updatedKey}`]: value };
		}
	}

	const jcStyle = (jcCenter && "center") || (jcSBetween && "space-between") || (jcSAround && "space-around") || (jcSEvenly && "space-evenly"),
		jcStyleMob = (jcCenterMob && "center") || (jcSBetweenMob && "space-between") || (jcSAroundMob && "space-around") || (jcSEvenlyMob && "space-evenly"),
		fdStyle = (fdRow && "row") || (fdRowRev && "row-reverse") || (fdCol && "column") || (fdColRev && "column-reverse"),
		fdStyleMob = (fdRowMob && "row") || (fdRowRevMob && "row-reverse") || (fdColMob && "column") || (fdColRevMob && "column-reverse"),
		aiStyle = (aiStretch && "stretch") || (aiCenter && "center") || (aiFStart && "flex-start") || (aiFEnd && "flex-end") || (aiFBase && "baseline"),
		aiStyleMob =
			(aiStretchMob && "stretch") ||
			(aiCenterMob && "center") ||
			(aiFStartMob && "flex-start") ||
			(aiFEndMob && "flex-end") ||
			(aiFBaseMob && "baseline"),
		taStyle = (taLeft && "left") || (taCenter && "center") || (taRight && "right"),
		taStyleMob = (taLeftMob && "left") || (taCenterMob && "center") || (taRightMob && "right"),
		displayStyle = (dBlock && "block") || (dFlex && "flex") || (dInline && "inline") || (dNone && "none"),
		displayStyleMob = (dBlockMob && "block") || (dFlexMob && "flex") || (dInlineMob && "inline") || (dNoneMob && "none");

	const stylesObject = {
		...styles,
		...mobileStylesObj,
		...(jcStyle && { justifyContent: jcStyle }),
		...(jcStyleMob && { mobileJustifyContent: jcStyleMob }),
		...(fdStyle && { flexDirection: fdStyle }),
		...(fdStyleMob && { mobileFlexDirection: fdStyleMob }),
		...(aiStyle && { alignItems: aiStyle }),
		...(aiStyleMob && { alignItems: aiStyleMob }),
		...(width && { width: width }),
		...(minWidth && { minWidth: minWidth }),
		...(maxWidth && { maxWidth: maxWidth }),
		...(height && { height: height }),
		...(minHeight && { minHeight: minHeight }),
		...(maxHeight && { maxHeight: maxHeight }),
		...(widthMob && { mobileWidth: widthMob }),
		...(minWidthMob && { mobileMinWidth: minWidth }),
		...(maxWidthMob && { mobileMaxWidth: maxWidth }),
		...(heightMob && { mobileHeight: height }),
		...(minHeightMob && { mobileMinHeight: minHeight }),
		...(maxHeightMob && { mobileMaxHeight: maxHeight }),
		...(padding && { padding: padding }),
		...(paddingMob && { mobilePadding: paddingMob }),
		...(margin && { margin: margin }),
		...(marginMob && { mobileMargin: marginMob }),
		...(taStyle && { textAlign: taStyle }),
		...(taStyleMob && { mobileTextAlign: taStyleMob }),
		...(displayStyle && { display: displayStyle }),
		...(displayStyleMob && { mobileDisplay: displayStyleMob })
	};

	if (props.client) {
		return (
			<Client
				className={props.className}
				hideMobile={hideMobile}
				showMobile={showMobile}
				narrowStyle={narrowStyle}
				mobileBreakpoint={mobileBreakpoint}
				custom={props.custom}
				{...stylesObject}
				onClick={event => {
					event.stopPropagation();
					onClick && onClick();
				}}
			>
				{children}
			</Client>
		);
	}
	return (
		<Mfe
			className={props.className}
			hideMobile={hideMobile}
			showMobile={showMobile}
			narrowStyle={narrowStyle}
			mobileBreakpoint={mobileBreakpoint}
			custom={props.custom}
			{...stylesObject}
			onClick={event => {
				event.stopPropagation();
				onClick && onClick();
			}}
		>
			{children}
		</Mfe>
	);
};

export default Container;
