import React from "react";
import { connect } from "react-redux";

import { appactionCreators } from "./store/shell/shellAction";

class ErrorContainer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			error: null,
			errorInfo: null,
			isDisconnected: false,
			wasDisconnected: false
		};
	}

	componentDidMount() {
		this.handleConnectionChange();

		window.addEventListener("online", this.handleConnectionChange);
		window.addEventListener("offline", this.handleConnectionChange);
	}

	componentWillUnmount() {
		window.removeEventListener("online", this.handleConnectionChange);
		window.removeEventListener("offline", this.handleConnectionChange);
	}

	componentDidCatch(error, errorInfo) {
		this.setState({ error, errorInfo });
	}

	setDisconnected(messages) {
		this.setState({ isDisconnected: true }, () => {
			this.setState({ wasDisconnected: true }, () => {
				this.props.showNotification("error", messages.SG_LBL_Disconnected, messages.SG_LBL_Disconnected_Description);
				this.props.expandNotification(true);
			});
		});
	}

	handleConnectionChange = () => {
		const { messages } = this.props;

		const condition = navigator.onLine ? "online" : "offline";
		if (condition === "online") {
			const webPing = setInterval(() => {
				fetch("/api/test", {
					mode: "no-cors"
				})
					.then(() => {
						this.setState({ isDisconnected: false }, () => {
							if (this.state.wasDisconnected) {
								this.setState({ wasDisconnected: false }, () => {
									this.props.showNotification("success", messages.SG_LBL_Connected);
								});
							}

							return clearInterval(webPing);
						});
					})
					.catch(() => this.setDisconnected(messages));
			}, 20000);

			return;
		}

		return this.setDisconnected(messages);
	};

	render() {
		if (this.state.errorInfo) {
			return (
				<div>
					<h2>Something went wrong.</h2>
					<details style={{ whiteSpace: "pre-wrap" }}>
						{this.state.error && this.state.error.toString()}
						<br />
						{this.state.errorInfo.componentStack}
					</details>
				</div>
			);
		}

		return this.props.children;
	}
}

const mapDispatchToProps = dispatch => {
	return {
		showNotification: (type, title, message) => {
			dispatch(appactionCreators.showNotification(type, title, message));
		},
		expandNotification: expand => {
			dispatch(appactionCreators.expandNotification(expand));
		}
	};
};

const mapStateToProps = state => ({
	messages: state.intl.messages
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorContainer);
