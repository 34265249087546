import { dispatchEvent } from "@coworker/sharedlibrary";
import React from "react";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import useMicrofrontend from "../../hooks/useMicroFrontend";
import { useSelector } from "react-redux";
import { getStoreConfigSelector } from "../../utils/shellObjectService";

const CatalogView = () => {
	const dispatch = useDispatch();
	let appConfig = getStoreConfigSelector();
	const { locale } = useSelector(state => state.localeController);
	let config = { ...appConfig };
	const { appId, appUrl, appName } = config.environment.microapps.catalogApp;

	const { isLoaded, catalog } = useMicrofrontend(appId, appUrl, appName);

	const onSelectCategory = e => {
		const selectedCategory = e.detail.selectedCategory;
		const selectedTab = e.detail.selectedTab;

		if (selectedCategory) {
			dispatchEvent("CLEAR_FILTERS");
			dispatch(
				push(`/browse/` + selectedCategory.Id, {
					...selectedCategory,
					selectedTab: selectedTab,
					isHome: true
				})
			);
		}
	};

	React.useEffect(() => {
		let cataLogConfig = getStoreConfigSelector();
		cataLogConfig.view = "catalog";
		catalog && catalog.SetSharedConfiguration(cataLogConfig);

		catalog && catalog.reloadCatalogs && catalog.reloadCatalogs();
	}, [config.coworkerData, locale, config.coworkerData.primaryLocale]);

	React.useEffect(() => {
		if (!catalog) return;
		let cataLogConfig = getStoreConfigSelector();
		cataLogConfig.view = "catalog";
		catalog.SetSharedConfiguration(cataLogConfig);
		const { render, unMount, subscribe, unSubscribe } = catalog;
		subscribe("BROWSE", onSelectCategory);
		render(`catalog-${appId}`, config);
		return () => {
			unMount(`catalog-${appId}`);
			unSubscribe("BROWSE", onSelectCategory);
		};
	}, [isLoaded, config.selectedHfb, config.coworkerData, locale, config.coworkerData.primaryLocale]); // eslint-disable-line

	if (!isLoaded) return <div>Loading...</div>;
	if (!catalog) return <div>Loading...</div>;
	return <div id={`catalog-${appId}`} />;
};
export default CatalogView;
