import AppLogger from "../logger/appLogger";

const shellEventParser = (eventName, eventData) => {
	if (eventName === "LOG") {
		if (eventData.level === "ERROR") {
			AppLogger.error(eventData.message, eventData.event, {
				...eventData.logData
			});
		}
		if (eventData.level === "INFO") {
			AppLogger.info(eventData.message, eventData.event, {
				...eventData.logData
			});
		}
	}
};

export default shellEventParser;
