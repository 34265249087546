import React from "react";

import { dispatchEvent, eventsToDispatch, getMessage } from "@coworker/sharedlibrary";

import Button from "@ingka/button";

// TODO: Turn this into a styled component when import setup is maintained
import "./style.scss";

const StockView = props => {
	const { disableForecast, retailItem } = props;

	if (!retailItem) {
		return "";
	}

	const stock = retailItem.availableStockToSell || 0;
	const isInStock = parseInt(stock) > 0;
	const stockValue = isInStock ? stock : 0;

	const showForecast = async () => {
		if (!disableForecast) {
			dispatchEvent(eventsToDispatch.NAVIGATE, {
				view: "VIEW_SALES_LOCATION",
				retailItem: retailItem,
				isStock: true
			});
		}
	};

	return (
		<Button
			className="stockComponentButton"
			data-testid={"stockcomponent"}
			small={true}
			alt={getMessage("SG_BTN_StockInfoButton")}
			onClick={event => {
				event.stopPropagation();
				showForecast();
			}}
		>
			<span className={isInStock ? "in-stock" : "out-of-stock"}>{stockValue}</span>
		</Button>
	);
};

export default StockView;
