import React from "react";
import { useSelector } from "react-redux";
import Button from "@ingka/button/Button";
import Modal, { Prompt, ModalFooter } from "@ingka/modal";

const OrderModificationDialog = ({ onSubmit }) => {
	const { messages } = useSelector(state => state.intl);
	const { order, isTransportDeskMode } = useSelector(state => state.cartController);

	const getTitle = isTransportDeskMode => {
		if (isTransportDeskMode) {
			return `${messages.SG_LBL_DeleteShoppingBagTitle}`;
		}

		return `${messages.SG_LBL_ModificationOrderCloseTitle.replace("{order}", (order && order.orderNo) || "")}`;
	};

	const getText = isTransportDeskMode => {
		if (isTransportDeskMode) {
			return `${messages.SG_LBL_AddItemToNotEmptyShoppingBag}`;
		}

		return `${messages.SG_LBL_ModificationOrderCloseText.replace("{order}", (order && order.orderNo) || "")}`;
	};

	function getModelButtons() {
		return [
			{
				text: messages.SG_BTN_Close,
				type: "primary",
				onClick: () => {
					onSubmit(true);
				}
			},
			{
				text: messages.SG_BTN_Cancel,
				type: "secondary",
				onClick: () => {
					onSubmit(false);
				}
			}
		];
	}

	return (
		<Modal visible={true} escapable={false} handleCloseBtn={() => onSubmit(false)} focusLockProps={focus}>
			<Prompt
				title={getTitle(isTransportDeskMode)}
				footer={
					<ModalFooter compact={false}>
						{getModelButtons().map((button, i) => {
							return <Button key={i} {...button}></Button>;
						})}
					</ModalFooter>
				}
			>
				<p>{getText(isTransportDeskMode)}</p>
			</Prompt>
		</Modal>
	);
};
export default OrderModificationDialog;
const focus = {
	returnFocus: false,
	autoFocus: true
};
