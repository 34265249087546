import React from "react";
import styled from "styled-components";
import { colors } from "@coworker/sharedlibrary";
import { AnimatedPlaceholder } from "../../components/sharedlibrary";

const CustContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 30px;
`;

const ItemDiv = styled.div`
	height: 64px;
	padding: 20px 0;
	border-top: 1px solid ${colors.bordergray};
`;

const TextPlaceHolder = styled(AnimatedPlaceholder)`
	width: ${props => props.width};
	height: 25px;
`;

const LoadingPlaceHolder = () => {
	return (
		<CustContainer>
			<ItemDiv>
				<TextPlaceHolder width={"20%"} />
			</ItemDiv>
			<ItemDiv>
				<TextPlaceHolder width={"40%"} />
			</ItemDiv>
			<ItemDiv>
				<TextPlaceHolder width={"40%"} />
			</ItemDiv>
			<ItemDiv>
				<TextPlaceHolder width={"60%"} />
			</ItemDiv>
			<ItemDiv>
				<TextPlaceHolder width={"60%"} />
			</ItemDiv>
		</CustContainer>
	);
};

export default LoadingPlaceHolder;
