import { useEffect } from "react";
import AppLogger from "@coworker/sharedlibrary/src/logger/appLogger";
import GaHelper from "@coworker/sharedlibrary/src/helpers/gaAnalyticsHelper";
function useHotKeys(keycode, callback) {
	const handleKeyDown = e => {
		try {
			if (!keycode || e.repeat) return;

			let keycodes = [];
			if (!Array.isArray(keycode)) {
				keycodes.push(keycode);
			} else {
				keycodes = keycode;
			}

			keycodes.forEach(x => {
				const key = parseKeys(x);
				if (key.cntrlKey && e.ctrlKey && e.key === key.key) {
					disabledEventPropagation(e);
					callback({ event: e, keys: x });
					GaHelper.SendEvent("Hotkeys", "Key", x);
					return false;
				}
				if (key && !key.cntrlKey && e.key === key.key) {
					disabledEventPropagation(e);
					callback({ event: e, keys: x });
					GaHelper.SendEvent("Hotkeys", "Key", x);
					return false;
				}
				if (x === "ALLKEY" && !e.ctrlKey && !e.altKey && !e.shiftKey && !e.metaKey) {
					callback({ event: e, keys: x });
					//GaHelper.SendEvent("Hotkeys", "Key", "ALLKEY");
					return false;
				}
			});
		} catch (error) {
			AppLogger.error(`Hotkeys failed:${error.message}`, "handleKeyDown");
		}
	};
	useEffect(() => {
		document.addEventListener("keydown", handleKeyDown);
		return () => document.removeEventListener("keydown", handleKeyDown);
	});

	return true;
}
export default useHotKeys;

function disabledEventPropagation(e) {
	if (e) {
		if (e.stopPropagation) {
			e.stopPropagation();
		} else if (window.event) {
			window.event.cancelBubble = true;
		}
	}
	e.preventDefault();
}

function parseKeys(keys) {
	if (keys) {
		const allkeys = keys.split("+");
		if (allkeys.length > 1) {
			return {
				cntrlKey: allkeys[0],
				key: allkeys[1]
			};
		}

		return {
			key: allkeys[0]
		};
	}
	return;
}
