const hitType = {
	Undefined: "Undefined",
	Product: "product",
	Suggestion: "Suggestion",
	History: "History",
	Collection: "Collection",
	Department: "Department",
	DepartmentSubCategory: "DepartmentSubCategory",
	LocalStore: "local_store",
	ManualAc: "manual_ac",
	Series: "series",
	System: "System",
	Order: "Order",
	Planner: "Planner",
	Article: "article",
	Ptag: "ptag",
	KitchenPlanner: "kitchenplanner",
	Receipt: "receipt",
	webShoppingList: "webShoppingList",
	kioskCart: "kioskCart",
	quotation: "quotation",
	SpecialKey: "SpecialKey"
};
export default hitType;
