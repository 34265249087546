class LogModel {
	constructor(level, message, event, properties) {
		this.Event = event;
		this.Level = level;
		this.Message = message;

		for (var key in properties) {
			if (Object.prototype.hasOwnProperty.call(properties, key)) {
				const element = properties[key];
				if (element && element !== null) {
					var keyUpper = toUpper(key);
					this[keyUpper] = element;
				}
			}
		}
	}
}
function toUpper(data) {
	return data.charAt(0).toUpperCase() + data.slice(1);
}
export default LogModel;
