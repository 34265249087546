import { SET_IMPORT_DATA, SET_IMPORT_SCOPE, SET_SEARCH_DATA } from "./types";

const INITIAL_STATE = {
	isImportScope: false,
	countryCode: "",
	language: "",
	buCode: "",
	searchData: {},
	importData: {}
};

const importReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_IMPORT_DATA:
			return {
				...state,
				importData: {
					...action.payload
				}
			};
		case SET_IMPORT_SCOPE:
			return {
				...state,
				isImportScope: true,
				...action.payload
			};

		case SET_SEARCH_DATA:
			return {
				...state,
				searchData: {
					...action.payload
				}
			};
		default:
			return state;
	}
};

export default importReducer;
