import Checkbox from "@ingka/checkbox";
import ProductIdentifier from "@ingka/product-identifier";
import styled from "styled-components";
import React, { useEffect, useRef } from "react";
import { PagePaths, RetailItemHelper, colors, device, getMessage } from "@coworker/sharedlibrary";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";

import ItemImageControl from "../retailItem/itemImageControl";
import { CardContainer, Description, ItemDesc, Name, QuantityComponent, ResponsiveWidth, TopSection, WarningText } from "./sharedStyles";
import { Grid, Label } from "./../../../../components/sharedlibrary";

const FulfilledCheckbox = styled(Checkbox)`
	margin: 20px 20px 0 0;

	@media ${device.large} {
		&.wide {
			display: none;
		}
	}

	&.narrow {
		display: none;

		@media ${device.large} {
			display: flex;
			margin-top: 30px;
		}
	}
`;

const TransportQuantity = styled.div`
	width: ${props => props.width || "auto"};
	display: flex;
	flex-direction: column;
	align-items: ${props => props.alignitems || "flex-start"};
	align-self: ${props => props.start || "flex-start"};

	@media ${device.large} {
		&.wide {
			display: none;
		}
	}

	&.narrow {
		display: none;

		@media ${device.large} {
			display: flex;
		}
	}

	span {
		color: black !important;
	}
`;

export const BottomSection = styled.section`
	width: cacl(100% + 10px);
	display: flex;
	flex-wrap: wrap;
	margin: 15px 0 0 0;
`;

const TransportDeskCartItemCard = ({ cartItem, handleUpdateQuantity }) => {
	const dispatch = useDispatch();

	const checkboxRefOne = useRef();
	const checkboxRefTwo = useRef();
	const { messages } = useSelector(state => state.intl);

	const { DetailPage } = PagePaths;

	const item = cartItem.retailItem;
	const quantity =
		cartItem.importDetails && cartItem.quantity > cartItem.importDetails.originalQuantity ? cartItem.importDetails.originalQuantity : cartItem.quantity;

	const originalQuantity = (cartItem.importDetails && parseInt(cartItem.importDetails.originalQuantity)) || 100;

	useEffect(() => {
		if (quantity !== originalQuantity && quantity > 0) {
			if (checkboxRefOne.current) {
				checkboxRefOne.current.checkbox.indeterminate = true;
			}
			if (checkboxRefTwo.current) {
				checkboxRefTwo.current.checkbox.indeterminate = true;
			}
		}
	}, [checkboxRefOne, checkboxRefTwo, originalQuantity, quantity]);

	let canIncreaseQuantity = true;
	let canDecreaseQuantity = true;
	let canRemoveItem = true;
	if (cartItem.isOrderLine) {
		canIncreaseQuantity = cartItem.canIncreaseQuantity;
		canDecreaseQuantity = cartItem.canDecreaseQuantity;
		canRemoveItem = cartItem.canRemoveItem;
	}
	const inputProps = {
		canIncreaseQuantity: canIncreaseQuantity,
		canDecreaseQuantity: canDecreaseQuantity,
		canRemoveItem: canRemoveItem,
		compact: true,
		disabled: !cartItem.allowedToDeliveryFromTransportDesk,
		itemId: cartItem.itemId,
		max: originalQuantity,
		min: 0,
		onChange: x => {
			handleUpdateQuantity(cartItem.id, x === 0 ? 0 : x);
		},
		value: !cartItem.isCashLine ? `${quantity}/${originalQuantity}` : quantity,
		valueOfMaxDisplay: !cartItem.isCashLine
	};

	const onItemClick = async event => {
		event.stopPropagation();

		const item = cartItem.retailItem;

		dispatch(push(DetailPage + item.ItemType + item.ItemId, item));
	};

	const onCheckboxClick = event => {
		event.stopPropagation();

		let newQuantity = 0;

		if (quantity < originalQuantity && originalQuantity !== 0) {
			newQuantity = originalQuantity;
		}

		handleUpdateQuantity(cartItem.id, newQuantity);
	};

	const quantityFulfilled = () => {
		return quantity === originalQuantity ? true : false;
	};

	const checkboxDisabled = !cartItem.allowedToDeliveryFromTransportDesk || cartItem.isCashLine;

	return (
		<div data-testid={"transport-cart-item"}>
			<CardContainer onClick={event => onItemClick(event, cartItem)} onKeyPress={event => onItemClick(event, cartItem)}>
				<FulfilledCheckbox
					readOnly={true}
					disabled={checkboxDisabled}
					ref={checkboxRefOne}
					className="wide"
					checked={quantityFulfilled()}
					onClick={event => onCheckboxClick(event)}
				/>
				<ItemImageControl align="flex-start" breakpoint={device.large} height={"64px"} item={item} theme="itemCardTheme" width={"64px"} />
				<ResponsiveWidth width={"100%"}>
					<TopSection>
						<Description width={"100%"} padding={"0px 0px 0px 20px"}>
							<ProductIdentifier style={{ display: "block" }} subtle={true} value={RetailItemHelper.toItemFormaId(cartItem.itemId)} />
							<Name>{cartItem.retailItem.Name}</Name>
							<ItemDesc>{cartItem.itemDescription}</ItemDesc>

							{cartItem.handOverLocation && (
								<Label margintop={"8px"} color={colors.black} fontsize={"12px"} fontweight={"bold"}>
									{cartItem.handOverLocation || ""}
								</Label>
							)}
							{!cartItem.handedOverToTdDesk && (
								<Label color={colors.ikeacolorblue} fontsize={"12px"} fontweight={"bold"}>
									{messages.SG_LBL_NotHandedToTdDesk || "Not Handed to transport desk"}
								</Label>
							)}
							{!cartItem.allowedToDeliveryFromTransportDesk && (
								<WarningText>{getMessage("SG_LBL_DeliveryNotSupported", messages, "Article not eligible for delivery")}</WarningText>
							)}
						</Description>
						<TransportQuantity className="wide">
							<Label fontsize={"12px"} fontweight={"bold"} lineheight={"16px"} textalign={"center"}>
								{getMessage("SG_LBL_CollectForDelivery", messages, "Collect for delivery")}
							</Label>
							<div
								onClick={event => event.stopPropagation()}
								onKeyPress={event => event.stopPropagation()}
								role="button"
								style={{
									width: "100%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									marginTop: "20px"
								}}
								tabIndex="0"
							>
								<QuantityComponent key={item.ItemId} {...inputProps} />
							</div>
						</TransportQuantity>
					</TopSection>
					<BottomSection>
						<Grid justifyContent={"space-between"} minwidth={"100%"}>
							<FulfilledCheckbox
								disabled={checkboxDisabled}
								readOnly={true}
								ref={checkboxRefTwo}
								className="narrow"
								checked={quantityFulfilled()}
								onClick={event => onCheckboxClick(event)}
							/>
							<TransportQuantity alignitems={"center"} className="narrow">
								<Label fontsize={"12px"} fontweight={"bold"} lineheight={"16px"} textalign={"center"}>
									{getMessage("SG_LBL_CollectForDelivery", messages, "Collect for delivery")}
								</Label>

								<div
									onKeyPress={event => event.stopPropagation()}
									onClick={event => event.stopPropagation()}
									role="button"
									style={{ marginLeft: "20px", marginTop: "20px" }}
									tabIndex="0"
								>
									<QuantityComponent key={item.ItemId} {...inputProps} />
								</div>
							</TransportQuantity>
						</Grid>
					</BottomSection>
				</ResponsiveWidth>
			</CardContainer>
		</div>
	);
};

export default TransportDeskCartItemCard;
