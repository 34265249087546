// In production, we register a service worker to serve assets from local cache.

import { StorageHelper } from "@coworker/sharedlibrary";

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.
// import Notify from "./components/shared/notify";
// import ReactDOM from "react-dom";
// import React from "react";
const isLocalhost = Boolean(
	window.location.hostname === "localhost" ||
		// [::1] is the IPv6 localhost address.
		window.location.hostname === "[::1]" ||
		// 127.0.0.1/8 is considered localhost for IPv4.
		window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export default function register(config) {
	// eslint-disable-next-line no-undef
	let env = process.env.NODE_ENV;
	// eslint-disable-next-line no-undef
	let url = process.env.PUBLIC_URL;
	if (env === "production" && "serviceWorker" in navigator) {
		// The URL constructor is available in all browsers that support SW.
		const publicUrl = new URL(url, window.location);
		if (publicUrl.origin !== window.location.origin) {
			// Our service worker won't work if PUBLIC_URL is on a different origin
			// from what our page is served on. This might happen if a CDN is used to
			// serve assets; see https://github.com/facebookincubator/create-react-app/issues/2374
			return;
		}

		window.addEventListener("load", () => {
			const swUrl = `${url}/service-worker.js`;
			// console.log("servicewr");
			if (!isLocalhost) {
				// Is not local host. Just register service worker
				registerValidSW(swUrl, config);
			} else {
				// This is running on localhost. Lets check if a service worker still exists or not.
				checkValidServiceWorker(swUrl, config);
			}
		});
	}
}

function registerValidSW(swUrl, config) {
	navigator.serviceWorker
		.register(swUrl, { updateViaCache: "none", scope: "./" })
		.then(registration => {
			registration.onupdatefound = () => {
				const installingWorker = registration.installing;
				installingWorker.onstatechange = () => {
					if (installingWorker.state === "installed") {
						if (navigator.serviceWorker.controller) {
							console.log("New content is available; added to update_available -> true");
							StorageHelper.addToStorage("update_available", "true");
							if (config && config.onUpdate) {
								config.onUpdate(registration);
							}
						} else {
							// Execute callback
							if (config && config.onSuccess) {
								config.onSuccess(registration);
							}
						}
					}
				};
			};
		})
		.catch(() => {
			//console.error("Error during service worker registration:", error);
		});
}

function checkValidServiceWorker(swUrl, config) {
	// console.log(swUrl);
	// Check if the service worker can be found. If it can't reload the page.
	fetch(swUrl)
		.then(response => {
			// Ensure service worker exists, and that we really are getting a JS file.
			if (response.status === 404 || response.headers.get("content-type").indexOf("javascript") === -1) {
				// No service worker found. Probably a different app. Reload the page.
				navigator.serviceWorker.ready.then(registration => {
					registration.unregister().then(() => {
						window.location.reload(true);
					});
				});
			} else {
				// Service worker found. Proceed as normal.
				registerValidSW(swUrl, config);
			}
		})
		.catch(() => {
			// console.log(
			//   "No internet connection found. App is running in offline mode."
			// );
		});
}

export function unregister() {
	if ("serviceWorker" in navigator) {
		navigator.serviceWorker.ready.then(registration => {
			registration.unregister();
		});
	}
}

// function displayUpdateNotification() {
//   try {
//     var element = document.getElementById("updateAppInfo");
//     if (element !== undefined) {
//       ReactDOM.render(<Notify />, document.getElementById("updateAppInfo"));
//     }
//   } catch (error) {
//     console.error(`displayUpdateNotification failed : ${error.message}`);
//   }
// }
