import jwt_decode from "jwt-decode";
import moment from "moment";
const JWTHelper = {};
JWTHelper.isTokenValid = function (token) {
	try {
		if (!token) {
			return false;
		}
		let user = jwt_decode(token);
		let exp = moment.unix(user.exp);
		let todate = moment();
		let diffminute = Math.abs(moment.duration(exp.diff(todate)).asMinutes());

		return diffminute > 5 && !todate.isAfter(exp);
	} catch {
		return false;
	}
};
export default JWTHelper;
