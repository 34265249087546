import URI from "@coworker/sharedlibrary/src/helpers/uriLinkHelper";
/**
 * converts a url to https
 *

 * @param {String} url - url
 * @return {String} Returns url with https.
 */
export const toHttpsLink = url => {
	if (url === undefined || url === null) return url;

	return url.includes("https") ? url : url.replace("http", "https");
};

/**
   * parses a url from links
   *
   * @param {Object} links - dictionary of url links { href:"url",templated:true} "_links": {
                  "exampleRel": {
                      "href": "http://url",
                      "templated": true
                  }}
   * @param {String} relName - relName for a ref link
   * @return {URI} Returns uri.
   */
export const getLinkFromHalRef = (links, relName) => {
	var link = links && links[relName];
	if (link) {
		return new URI(link.href, link.templated || false);
	}
	return;
};
export const getUrlFromHalRef = (links, relName) => {
	var link = links && links[relName];
	if (link) {
		return link.href;
	}
};
