class AutoCompleteSuggestionModel {
	constructor() {
		this.partyUId = undefined;
		this.title = undefined;
		this.canAddDeliveryAddress = false;
		this.isIcmCustomer = false;
		this.isBcmBusinessCustomer = false;
		this.hasAddressInfo = undefined;
		this.icmCustomer = undefined;
		this.isAddressSearch = false;
		this.isCustomer = false;
		this.isLoqate = false;
		this.query = undefined;
		this.zipCode = undefined;
		this.contextType = undefined;
		this.id = undefined;
		this.subTitle = undefined;
		this.canCreateNewCustomer = false;
	}
}

export default AutoCompleteSuggestionModel;
