const ServicePriceCalculationType = {
	None: "NONE",
	PerKitchenUnit: "PER_KITCHEN_UNIT",
	KitchenUnitsInterval: "KITCHEN_UNITS_INTERVAL",
	ServiceGoodsValue: "SERVICE_GOODS_VALUE",
	PerMeter: "PER_METER",
	MetersInterval: "METERS_INTERVAL",
	PerSquareMeter: "PER_SQUARE_METER",
	SquareMetersInterval: "SQUARE_METERS_INTERVAL",
	PerHour: "PER_HOUR",
	HoursInterval: "HOURS_INTERVAL",
	PerPiece: "PER_PIECE",
	PiecesInterval: "PIECES_INTERVAL",
	PerService: "PER_SERVICE",
	ServicesInterval: "SERVICES_INTERVAL",
	ManualPrice: "MANUAL_PRICE",
	Weight: "WEIGHT",
	AssemblyTimeInterval: "ASSEMBLY_TIME_INTERVAL",
	AssemblyTimeCategoryInterval: "ASSEMBLY_TIME_CATEGORY_INTERVAL",
	AssemblyListPrice: "ASSEMBLY_LIST_PRICE"
};

const ServiceCapacityType = {
	GODDS_VALUE: "GOODS_VALUE",
	KITCHENUNITS: "KITCHEN_UNITS",
	HOURS: "HOURS",
	METERS: "METERS",
	SQUAREMETERS: "SQUARE_METERS",
	BOOKINGS: "BOOKINGS",
	PIECES: "PIECES",
	PATVALUE: "PAT_VALUE",
	NONE: "NONE",
	ORDERS: "ORDERS",
	WEIGHT: "WEIGHT",
	TRANSPORT_ORDERS: "TRANSPORT_ORDERS",
	ASSEMBLY_LIST_PRICE: "ASSEMBLY_LIST_PRICE"
};
const ServiceTypes = { ServiceCapacityType, ServicePriceCalculationType };
export default ServiceTypes;
