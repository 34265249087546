import { useSelector } from "react-redux";
import InputField from "@ingka/input-field";
import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Container } from "./../../../../components/sharedlibrary";
import FormField from "@ingka/form-field";
import RadioButtonGroup from "@ingka/radio-button-group";

const InputContainer = styled(InputField)`
	label {
		font-size: 14px !important;
	}
	padding: 0px 0px;
`;

const ManualDiscountView = ({ setManualDiscount, messages, manualDiscount, total, setShowDiscount }) => {
	var validationError = {
		msg: ""
	};
	const [validation, setValidation] = useState(validationError);

	const inputref = useRef();

	const { currency } = useSelector(state => state.cartController);
	const manualProp = {
		req: "*",
		type: "tel",
		autoComplete: "off",
		id: "percentage_discount",
		defaultValue: manualDiscount.value.toString(),
		label: manualDiscount.mode === "FLAT" ? messages.SG_LBL_Amount || "Amount" : messages.SG_LBL_Percentage || "Percentage",
		onBlur: e => {
			setShowDiscount && setShowDiscount(true);
			setValidation({
				msg: ""
			});
			if (isNaN(e.target.value) || parseInt(e.target.value) < 0) {
				setValidation({
					type: "error",
					msg: messages.SG_LBL_InvalidDiscountAmount || "Not a valid value"
				});
				setManualDiscount({
					...manualDiscount,
					value: 0
				});
				return;
			}

			if (manualDiscount.mode === "PERCENTAGE" && parseInt(e.target.value) >= 100) {
				setValidation({
					type: "error",
					msg: messages.SG_LBL_PercentageError || "Should be less than 100 percentage"
				});
				setManualDiscount({
					...manualDiscount,
					value: 0
				});
				return;
			}
			if (manualDiscount.mode === "FLAT" && total && parseInt(e.target.value) >= parseInt(total.Value)) {
				setValidation({
					type: "error",
					msg: (messages.SG_LBL_ManualAmountError || `Should be less than total amount : {value}`).replace("{value}", total.Value)
				});
				setManualDiscount({
					...manualDiscount,
					value: 0
				});
				return;
			}
			setManualDiscount({
				...manualDiscount,
				value: isNaN(e.target.value) ? 0 : parseInt(e.target.value)
			});
		},
		onFocus: () => {
			setShowDiscount && setShowDiscount(false);
		}
	};
	const RadioButtonsArray = [
		{
			id: "percent",
			name: "discountoption",
			label: "%",
			value: "PERCENTAGE",
			checked: manualDiscount.mode === "PERCENTAGE",
			onChange: () => {
				setValidation({
					msg: ""
				});
				setManualDiscount({
					...manualDiscount,
					mode: "PERCENTAGE",
					value: 0
				});
				inputref.current.value = 0;
			}
		},
		{
			id: "flat",
			name: "discountoption",
			label: currency,
			value: "FLAT",
			checked: manualDiscount.mode === "FLAT",
			onChange: () => {
				setValidation({
					msg: ""
				});
				setManualDiscount({
					...manualDiscount,
					mode: "FLAT",
					value: 0
				});
				inputref.current.value = 0;
			}
		}
	];

	return (
		<Container>
			<FormField shouldValidate={true} validation={validation}>
				<InputContainer ref={inputref} {...manualProp}></InputContainer>
			</FormField>
			<RadioButtonGroup
				name=""
				list={RadioButtonsArray}
				style={{
					display: "flex",
					width: "100%",
					justifyContent: "space-around"
				}}
			/>
		</Container>
	);
};

export default ManualDiscountView;
