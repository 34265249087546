import React from "react";
import styled from "styled-components";

const addStyles = styledObj => {
	return styledObj.div.attrs(props => ({
		id: props.id
	}))`
  height: ${props => props.height || "20px"};
`;
};

const Client = window["styled-components"] ? addStyles(window["styled-components"]) : addStyles(styled);

const Mfe = addStyles(styled);

const Break = props => {
	return props.client ? <Client {...props} /> : <Mfe {...props} />;
};

export default Break;
