import { RequiresFreeTextSpec, HasVaildAnswerSpec } from "@coworker/sharedlibrary/src/helpers/questionnaireHelper";

class QuestionModel {
	constructor(data, answer) {
		for (var key in data) {
			if (Object.prototype.hasOwnProperty.call(data, key)) {
				const element = data[key];
				this[key] = element;
			}
		}

		this.isValid = new HasVaildAnswerSpec(data).IsSatisfiedBy(answer || null);
		this.requireFreeText = new RequiresFreeTextSpec().IsSatisfiedBy(data, answer || null);
	}
}
export default QuestionModel;
