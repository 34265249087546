import "./style.scss";

import React, { Component } from "react";

import Hyperlink from "@ingka/hyperlink";
import SalesCoworkerConfiguration from "../../store/salesCoworkerConfiguration";
import { connect } from "react-redux";
import { getMessage } from "@coworker/sharedlibrary";
import onClickOutside from "react-onclickoutside";

class OseMenu extends Component {
	constructor(props) {
		super(props);

		this.showMenu = this.showMenu.bind(this);
		this.config = SalesCoworkerConfiguration.getUserConfig();
		let hideMenu = false;
		if (this.config.environment && this.config.environment.appSwitchExcludedMarkets && Array.isArray(this.config.environment.appSwitchExcludedMarkets)) {
			hideMenu = this.config.environment.appSwitchExcludedMarkets.includes(this.config.countryCode);
		}
		this.state = {
			show: false,
			hideMenu: hideMenu
		};
	}

	showMenu() {
		this.setState({ show: true });
	}

	handleClickOutside = () => {
		this.setState({ show: false });
	};

	render() {
		const { show, hideMenu } = this.state;

		const { messages } = this.props;

		if (hideMenu) {
			return <></>;
		}

		return (
			<div className="menu__ose">
				<div className="menu__ose__pop-up">
					{show && (
						<div className="menu__data">
							<Hyperlink url="https://iquote.ingka.com?channel=saljago" target="_blank" text="iQuote" />
							<Hyperlink url="https://salesassistant.ingka.com?channel=saljago" text="Sales Assistant" target="_blank" />
						</div>
					)}
					<div className="menu__ose__btn">
						<button className="menu__link menu__link--btn mb-0" onClick={this.showMenu}>
							<span className="menu__link__arrow" />
							<span className="menu__icon menu__icon--ose">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M4 4v7h7V4H4zm2 5V6h3v3H6zm7-5v7h7V4h-7zm2 5V6h3v3h-3zM4 13h7v7H4v-7zm2 2v3h3v-3H6zm7-2v7h7v-7h-7zm2 5v-3h3v3h-3z"
										fill="#111111"
									/>
								</svg>
							</span>
							<span className="menu__label menu__label--ose">{getMessage("SG_LBL_ChangeApp", messages, "Change App")}</span>
						</button>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	messages: state.intl.messages
});

export default connect(mapStateToProps, null)(onClickOutside(OseMenu));
