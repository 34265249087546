import * as ObjectHelper from "@coworker/sharedlibrary/src/helpers/objectHelper";

import { AppLogger, globalVariables } from "@coworker/sharedlibrary";
import {
	SellingOrderConversionConvertSlToCcForm,
	SellingOrderModificationAddItemForm,
	SellingOrderModificationCancelOrderForm
} from "@coworker/sharedlibrary/src/constants/resourceLinks";

import Enumerable from "linq";
import OrderTypes from "@coworker/sharedlibrary/src/constants/orderTypes";
import PriceModel from "@coworker/sharedlibrary/src/models/priceModel";
import ServiceProposalModel from "@coworker/sharedlibrary/src/models/serviceProposalModel";
import ShoppingCartItemFactory from "@coworker/sharedlibrary/src/models/shoppingCartItemFactory";
import cartItemsBuilder from "@coworker/sharedlibrary/src/helpers/cartItemsRequestBuilder";
import moment from "moment";

const NORMAL = "NORMAL";

export const RecentOrderMapper = {
	mapRecentOrder: (response, orderRetailItems) => {
		let dtoOrder = response._embedded;
		let links = response._links;
		let orderCurrencyCode = response.orderCurrencyCode;
		let _links = response._links;
		let retailItems = orderRetailItems || [];
		const { allowedOrderModification = {} } = response;
		const { deviations = [], items = [] } = allowedOrderModification || {
			deviations: []
		};
		if (retailItems.length === 0) {
			dtoOrder.Articles &&
				dtoOrder.Articles.map(function (art) {
					retailItems.push({
						SoSalesItemNo: art.soSalesItemNo,
						IsReferenceItem: false,
						IsReturn: false,
						ItemId: art.itemId,
						ItemType: art.itemType,
						ItemUnitCode: art.quantityType,
						Name: art.name,
						Quantity: art.quantity,
						SacLineNo: art.sacLineNo,
						price: {
							Currency: art.salesPrice.currency,
							Type: "NORMAL",
							Value: parseInt(art.salesPrice.value)
						},
						RetailUnitPrices: [
							{
								Currency: art.salesPrice.currency,
								Type: "NORMAL",
								Value: parseInt(art.salesPrice.value)
							}
						]
					});
				});

			dtoOrder.Products &&
				dtoOrder.Products.map(function (art) {
					retailItems.push({
						SoSalesItemNo: art.soSalesItemNo,
						IsReferenceItem: false,
						IsReturn: false,
						ItemId: art.itemId,
						ItemType: art.itemType,
						Name: art.name,
						Quantity: art.quantity,
						ItemUnitCode: art.quantityType,
						SacLineNo: art.sacLineNo,
						price: {
							Currency: art.salesPrice.currency,
							Type: "NORMAL",
							Value: parseInt(art.salesPrice.value)
						},
						RetailUnitPrices: [
							{
								Currency: art.salesPrice.currency,
								Type: "NORMAL",
								Value: parseInt(art.salesPrice.value)
							}
						]
					});
				});
		}
		let articles = retailItems
			? (dtoOrder.Articles &&
					dtoOrder.Articles.map(function (art) {
						let retailItem = retailItems.find(x => x.ItemId === art.itemId);
						let item = {
							isRefund: art.salesPrice.value === 0,
							...art,
							taxDetails:
								art.taxDetails &&
								art.taxDetails.map(tax => {
									return {
										...tax,
										taxPercent: tax.taxRate
									};
								}),
							price: {
								Value: art.salesPrice && art.salesPrice.value * art.quantity,
								Currency: orderCurrencyCode,
								Type: NORMAL
							},
							salesPrice: {
								...art.salesPrice,
								Value: art.salesPrice.Value
							},
							links: art._links,
							retailItem: retailItem,
							QuantityInSPR: art.quantity
						};
						let modifyitem = items.find(x => x.primeLineNo === art.soSalesItemNo);
						if (modifyitem && modifyitem.allowedModifications) {
							item.allowedModifications = modifyitem.allowedModifications;
						}
						return item;
					})) ||
			  []
			: [];

		let products = [];
		if (dtoOrder.Products && retailItems) {
			products = dtoOrder.Products.map(function (product) {
				let retailItem = retailItems.find(x => x.ItemId === product.itemId);
				let childArticles = articles
					.filter(arid => product.referencedItems.includes(arid.soSalesItemNo))
					.map(function (itm) {
						return itm;
					});
				let taxDetails = childArticles[0].taxDetails;
				return {
					...product,
					vatRate: taxDetails[0].taxRate,
					taxDetails:
						(taxDetails &&
							taxDetails.map(tax => {
								return {
									...tax,
									taxPercent: tax.taxRate
								};
							})) ||
						[],
					isRefund: product.salesPrice.value === 0,

					price: {
						Value: product.salesPrice && product.salesPrice.value * product.quantity,
						Currency: orderCurrencyCode,
						Type: NORMAL
					},
					salesPrice: {
						...product.salesPrice,
						Value: product.salesPrice.value
					},

					links: product._links,
					retailItem: retailItem,
					childItems: childArticles
				};
			});
		}

		let totalItemPrice =
			articles.length > 0
				? new PriceModel({
						Value: articles.map(item => item.salesPrice.value * item.quantity).reduce((prev, next) => prev + next),
						Currency: orderCurrencyCode,
						Type: NORMAL
				  })
				: new PriceModel({
						Value: 0,
						Currency: orderCurrencyCode,
						Type: NORMAL
				  });

		articles = Enumerable.from(articles)
			.except(Enumerable.from(products).selectMany(prod => prod.childItems))
			.toArray();
		let fulltitems = [];
		try {
			fulltitems = Enumerable.from(articles.concat(products))
				.orderBy(item => item.seqNo)
				.toArray();
		} catch (error) {
			AppLogger.error(error.message, "MapProductsFromOrder");
		}
		let sfFactory = new ShoppingCartItemFactory();

		let isCompleted = response.orderStatus === "COMPLETED";
		let isCancelled = response.orderStatus === "CANCELLED";
		let orderItems = sfFactory.createShoppingCartItemsfromOrder([...fulltitems], response.orderNo);

		orderItems.forEach(ordItem => {
			if (response.orderType === "ISOM") {
				sfFactory.setCartItemModificationData(ordItem);
				if (ordItem.childItems) {
					ordItem.childItems.forEach(chItm => {
						sfFactory.setCartItemModificationData(chItm);
					});
				}
			} else if (response.orderType === "SALESORDER") {
				ordItem.canIncreaseQuantity = isCancelled ? false : response.cashAndCarryOrder;
				ordItem.canDecreaseQuantity = isCancelled ? false : response.cashAndCarryOrder;
				ordItem.canChangePrice = isCancelled ? false : response.cashAndCarryOrder;
				ordItem.canRemoveItem = isCancelled ? false : response.cashAndCarryOrder;
			} else if (response.orderType === "SHLIST") {
				ordItem.canIncreaseQuantity = true;
				ordItem.canDecreaseQuantity = true;
				ordItem.canChangePrice = true;
				ordItem.canRemoveItem = true;
			}
		});

		let allItems = cartItemsBuilder.createItems(orderItems);

		let cancelRel = Enumerable.from(SellingOrderModificationCancelOrderForm)
			.where(x => !ObjectHelper.isNil(links[x]))
			.select(y => y)
			.firstOrDefault();

		let addItemRel = Enumerable.from(SellingOrderModificationAddItemForm)
			.where(x => !ObjectHelper.isNil(links[x]))
			.select(y => y)
			.firstOrDefault();
		let hasReceiptItems = allItems.filter(x => x.importDetails).length > 0;
		let isCashLineOrder = allItems.filter(x => x.isCashLine).length > 0;
		let selectedCashLine = {
			id: "",
			name: ""
		};
		if (isCashLineOrder) {
			let cashLine = allItems[0].handOverLocation.split("|")[1];
			selectedCashLine = {
				id: cashLine,
				name: cashLine
			};
		}
		let hasRefundItems = allItems.filter(x => x.isRefund).length > 0;
		let isPaid = response.orderPaid;
		let mapOrder = {
			selectedCashLine: selectedCashLine,
			barCode: response.barCode,
			hasBarCode: response.barCode !== "",
			deviations: deviations,
			hasReviewOrder: deviations.includes("MSG_REVIEW_ORDER") || response.hasChallengedPayment,
			hasChallengedPayment: response.hasChallengedPayment,
			type: RecentOrderMapper.getOrderType(response),
			id: response.orderNo,
			orderStatus: response.orderStatus,
			isPaid: isPaid,
			orderPaid: response.orderPaid,
			isCompleted: response.orderStatus === "COMPLETED",
			isCancelled: response.orderStatus === "CANCELLED",
			orderNo: response.orderNo,
			orderNoSource: response.orderNoSource,
			buCode: response.buCode,
			orderType: response.orderType,
			orderCurrencyCode: orderCurrencyCode,
			orderCreationDate: response.orderCreationDate,
			version: response.version,
			groups: dtoOrder.Groups || [],
			textLines: dtoOrder.TextLines || [],
			actualItems: items,
			orderItems: orderItems,
			totalItemPrice: totalItemPrice,
			canConvertToOrder: !ObjectHelper.isNil(links[SellingOrderConversionConvertSlToCcForm]),
			convertToOrderLink: !ObjectHelper.isNil(links[SellingOrderConversionConvertSlToCcForm])
				? links[SellingOrderConversionConvertSlToCcForm].href
				: undefined,
			cancelOrderLink: getCancelLink(response, links, cancelRel),
			canCancelOrder: getCancelLink(response, links, cancelRel) !== undefined,
			addItemLink: !ObjectHelper.isNil(links[addItemRel]) ? links[addItemRel].href : undefined,
			drawings: response.drawings,
			customer: response.customer,
			customerBilling: response.customerBilling,
			proposal: mapDeliveryAndServices(response, allItems),
			orderVersion: response.version,
			_links: _links,
			payOrderLink: response.payOrderLink,
			isTransportOrder: hasReceiptItems,
			canPayOnline: response.canPayOnline,
			canAddReceipt: hasReceiptItems && !isPaid && !isCompleted && !isCancelled,
			canAddService: response.canAddService,
			tapToPayUrl: response.tapToPayUrl,
			businessCardNo: response.businessCardNo
		};

		if (response.orderType === "ISOM") {
			mapOrder.isCcdService = true;
		}
		if (response.orderType === "SALESORDER" && !response.cashAndCarryOrder) {
			mapOrder.isLcdService = true;
		}
		mapOrder.totalPrice = {
			Value: (response.Amounts.Total && response.Amounts.Total.grossAmount) || 0,
			Currency: orderCurrencyCode,
			Type: "NORMAL"
		};
		if (hasReceiptItems && !isCashLineOrder) {
			let transportOrderAmount = 0;
			if (response.Amounts.Service) {
				transportOrderAmount += response.Amounts.Service.grossAmount;
			} else if (response.Amounts.DeliveryService) {
				transportOrderAmount += response.Amounts.DeliveryService.grossAmount;
			}
			mapOrder.totalPrice = {
				Value: transportOrderAmount,
				Currency: orderCurrencyCode,
				Type: "NORMAL"
			};
		}
		if (hasReceiptItems || isCashLineOrder) {
			mapOrder.isTransportDeskMode = true;
		} else {
			mapOrder.isSalesDeskMode = true;
		}
		if (hasRefundItems) {
			mapOrder.isRefundOrder = hasRefundItems;
		}
		mapOrder.hasFullDetails = true;
		mapOrder.orderReference = response;
		if (mapOrder.proposal && mapOrder.proposal.bookedServices && mapOrder.proposal.bookedServices.length > 0) {
			mapOrder.hasService = true;
		}
		let bookedServices = (mapOrder.proposal && mapOrder.proposal.bookedServices) || [];
		mapOrder.maxLineId = sfFactory.getLatestItemId(orderItems, bookedServices);

		return mapOrder;
	},

	/**
	 * parse customer data from the order
	 *
	 * @param orderResponse
	 *
	 * @return {Object} Returns {customer,customerBilling}
	 */
	mapCustomerOrder: orderResponse => {
		let customerData = {
			cusObj: orderResponse.customer,
			cusBillingObj: orderResponse.customerBilling
		};
		//to show delivery info
		if (customerData.cusObj) customerData.cusObj.isAddedDeliveryAddress = true;
		if (customerData.cusBillingObj) customerData.cusBillingObj.isAddedDeliveryAddress = true;

		return customerData;
	},
	getOrderType: dtoOrder => {
		switch (dtoOrder.orderType) {
			case "SHLIST":
			case "SHOPPINGLIST":
				return OrderTypes.ShoppingList;
			case "SALESORDER":
				if (dtoOrder.cashAndCarryOrder) {
					return OrderTypes.CCorder;
				}
				return OrderTypes.ServiceOrder;
			case "ISOM":
				return OrderTypes.IsomOrder;
			case "QUOTATION":
				return OrderTypes.Quotation;
			default:
				return OrderTypes.ServiceOrder;
		}
	},
	canPayOrderOnline: order => {
		return (
			order.orderType === OrderTypes.IsomOrder &&
			order.customer &&
			order.customer.email &&
			order.payOrderLink &&
			order.canPayOnline &&
			!order.isCancelled &&
			!order.isPaid &&
			!order.isCompleted
		);
	}
};

const mapDeliveryAndServices = (orderResponse, allItems) => {
	try {
		let hasRefundItems = allItems.filter(x => x.isRefund).length > 0;
		let hasReceiptItems = allItems.filter(x => x.importDetails).length > 0;
		let order = orderResponse._embedded;
		const { allowedOrderModification = {} } = orderResponse;
		const deliveryAllowedModifications = (allowedOrderModification && allowedOrderModification.deliveryServices) || [];
		const serviceAllowedModifications = (allowedOrderModification && allowedOrderModification.providedServices) || [];
		let pickingPrice;
		if (order.providedServices) {
			let picking = Enumerable.from(order.providedServices).firstOrDefault(x => x.serviceProductId === "PICKING_IN_STORE_CUST_ISL");
			if (picking) {
				pickingPrice = picking.price;
			}
		}

		let deliveryServices = [];
		let services = [];

		if (order.deliveryArrangements) {
			if (orderResponse.orderType === "ISOM") {
				order.deliveryArrangements.forEach(dlarrang => {
					let subDeliveries = Enumerable.from(dlarrang._embedded.deliveryServices);
					let serId = subDeliveries.firstOrDefault().serviceId;
					let solutionPrice = {
						currencyCode: orderResponse.orderCurrencyCode,
						priceExclTax: 0,
						priceInclTax: 0
					};
					let group = `ISOM-${dlarrang.serviceType}`;
					let dlSevice = {
						isLocked: true,
						customerServiceType: "Customer Delivery",
						group: group,
						id: `${group}-${dlarrang.serviceSubType}`,
						orderNo: orderResponse.orderNo,
						orderNoSource: orderResponse.orderNoSource,
						orderVersion: orderResponse.version,
						solutionId: dlarrang.serviceSubType,
						fulfillmentMethod: dlarrang.serviceType,
						fulfillmentType: dlarrang.serviceSubType,
						isCentralDelivery: true,
						deliveryMethod: serId,
						serviceItemId: serId,
						serviceName: serId,
						subDeliveries: [],
						serviceTypeId: dlarrang.serviceType,
						serviceidentifier: dlarrang.serviceSubType,
						service: dlarrang.serviceSubType,
						referenceItems: subDeliveries
							.selectMany(x => x.referencedItems)
							.toArray()
							.map(itm => {
								let cartitem = allItems.find(x => x.soSalesItemNo === itm);
								if (cartitem) {
									return getReferenceItem(cartitem);
								}
							})
					};
					dlSevice.selectedCartItems = dlSevice.referenceItems;

					for (let i = 0; i < dlarrang._embedded.deliveryServices.length; i++) {
						let dtoOrderDelivery = dlarrang._embedded.deliveryServices[i];
						let allowedModification = deliveryAllowedModifications.find(x => x.primeLineNo.toString() === dtoOrderDelivery.deliveryNo.toString());

						let allowedModifications = (allowedModification && allowedModification.allowedModifications) || [];
						let deviations = (allowedModification && allowedModification.deviations) || [];
						let taxDetails =
							dtoOrderDelivery.taxRates &&
							dtoOrderDelivery.taxRates.map(tax => {
								return {
									...tax,
									taxPercent: tax.taxPercentage
								};
							});
						let dlPrice = {
							...dtoOrderDelivery.price,
							amount: dtoOrderDelivery.price.priceInclTax || dtoOrderDelivery.price.priceExclTax,
							taxAmount: taxDetails && taxDetails[0].taxAmount,
							taxDetails: taxDetails
						};
						solutionPrice = {
							...dlPrice,
							taxAmount: taxDetails && taxDetails[0].taxAmount,
							priceExclTax: solutionPrice.priceExclTax + dlPrice.priceExclTax,
							priceInclTax: solutionPrice.priceInclTax + dlPrice.priceInclTax,
							amount: solutionPrice.priceInclTax + dlPrice.priceInclTax
						};
						let subService = {
							isLocked: true,
							customerServiceType: "Customer Delivery",
							wheelChairCapable: (dtoOrderDelivery.capabilities && dtoOrderDelivery.capabilities.includes("WHEEL_CHAIR")) || false,
							capabilityOptions: dtoOrderDelivery.capabilities || [],
							isRangeOfDays: (dtoOrderDelivery.capabilities && dtoOrderDelivery.capabilities.includes("RANGE_OF_DAYS")) || false,
							authoritytoleaveSelected: (dtoOrderDelivery.capabilities && dtoOrderDelivery.capabilities.includes("AUTH_LEAVE")) || false,
							hasNoStockDelivery: dtoOrderDelivery.serviceId === globalVariables.deliveryMethods.NO_STOCK_DELIVERY_ID,
							isTransportDeskDelivery: hasReceiptItems,
							orderVersion: orderResponse.version,
							deviations: deviations,
							allowedModifications: allowedModifications,
							canModifyTimeWindow: allowedModifications.includes("CHANGE_APPOINTMENT"),
							hasLineRelationShip: allowedModifications.includes("ADD_LINE_RELATIONSHIP"),
							canModifyPrice: allowedModifications.includes("CHANGE_FREIGHT"),
							canDeleteService: allowedModifications.includes("CANCEL_DELIVERY"),
							orderNo: orderResponse.orderNo,
							orderNoSource: orderResponse.orderNoSource,
							deliveryId: dtoOrderDelivery.deliveryNo,
							deliveryNo: dtoOrderDelivery.deliveryNo,
							lineId: dtoOrderDelivery.deliveryNo,
							workOrderNo: dtoOrderDelivery.workOrderNo,
							isCentralDelivery: true,
							referenceItems:
								(dtoOrderDelivery.referencedItems &&
									dtoOrderDelivery.referencedItems.map(itm => {
										let cartitem = allItems.find(x => x.soSalesItemNo === itm);
										if (cartitem) {
											let referecenItem = getReferenceItem(cartitem);
											if (cartitem.fulfillingUnit) {
												referecenItem.timeWindowLineItem = {
													shipNode: `${cartitem.fulfillingUnit.type}.${cartitem.fulfillingUnit.code}`
												};
											}
											return referecenItem;
										}
									})) ||
								[],
							price: dlPrice,
							timeWindows:
								(dtoOrderDelivery.timeWindows &&
									dtoOrderDelivery.timeWindows.map(time => {
										let timeWindow = {
											date: moment(time.fromLocalDateTime).format("YYYY-MM-DD"),
											resourcePoolId: time.resourcePoolId,
											resourceId: time.resourcePoolId,
											fromDateTime: time.fromLocalDateTime,
											toDateTime: time.toLocalDateTime,
											timeWindowId: time.timeWindowId,
											id: time.timeWindowId,
											timeZone: time.timeZone,
											timeZoneId: time.timeZone,
											orderNo: orderResponse.orderNo,
											orderNoSource: orderResponse.orderNoSource,
											workOrderNo: dtoOrderDelivery.workOrderNo,
											deliveryNo: dtoOrderDelivery.deliveryNo,
											isRangeDays: dtoOrderDelivery.capabilities && dtoOrderDelivery.capabilities.includes("RANGE_OF_DAYS"),
											transferringUnit: time.transferringUnit
										};
										if (time.transferringUnit) {
											timeWindow.node = `${time.transferringUnit.type}.${time.transferringUnit.code}`;
										}
										return timeWindow;
									})) ||
								[],
							unitOfMeasure: dtoOrderDelivery.unitOfMeasure,
							unitMeasure: dtoOrderDelivery.unitOfMeasure,
							isExceptionalVolume: dtoOrderDelivery.exceptionalVolume,
							serviceName: dtoOrderDelivery.serviceName,
							status: dtoOrderDelivery.status,
							statusTranslated: dtoOrderDelivery.statusText,
							serviceItemId: dtoOrderDelivery.serviceId,
							deliveryMethod: dtoOrderDelivery.serviceId,
							pickupPoint: dtoOrderDelivery.pickupPoint ? convertToPickupPoint(dtoOrderDelivery.pickupPoint) : undefined,
							hasPickupPoint: dtoOrderDelivery.pickupPoint !== undefined,
							statusIndicator: getColorCode(dtoOrderDelivery.status)
						};
						if (dtoOrderDelivery.mergeOrder) {
							dlSevice.mergeLocked = true;
							subService.mergeLocked = true;
							subService.mergeOrderSelected = true;
							subService.mergeValid = true;
							subService.mergeOrder = dtoOrderDelivery.mergeOrder;
							dlSevice.mergeOrderSelected = true;
							dlSevice.mergeValid = true;
							dlSevice.mergeOrder = dtoOrderDelivery.mergeOrder;
							dlSevice.mergePrice = solutionPrice;
						}
						subService.selectedCartItems = subService.referenceItems;
						subService.timeWindow = subService.timeWindows && subService.timeWindows.length > 0 && subService.timeWindows[0];

						dlSevice.subDeliveries.push(subService);
					}
					dlSevice.wheelChairCapable = dlSevice.subDeliveries.find(x => x.wheelChairCapable) !== undefined;

					dlSevice.price = solutionPrice;
					deliveryServices.push(dlSevice);
				});
			} else {
				let deliveries = Enumerable.from(order.deliveryArrangements).selectMany(x => x.deliveryServices);
				deliveryServices = deliveries
					.select(function (dtoOrderDelivery) {
						let taxDetails = dtoOrderDelivery.taxRates.map(tax => {
							return {
								...tax,
								taxPercent: tax.taxPercentage
							};
						});
						let dlPrice = {
							...dtoOrderDelivery.price,
							amount: dtoOrderDelivery.price.priceInclTax || dtoOrderDelivery.price.priceExclTax,
							taxAmount: taxDetails && taxDetails[0].taxAmount,
							taxDetails: taxDetails
						};
						if (hasRefundItems) {
							dlPrice = {
								...dlPrice,
								priceExclTax: 0,
								priceInclTax: 0
							};
						}
						let dlService = {
							isLocked: true,
							customerServiceType: "Customer Delivery",
							orderNo: orderResponse.orderNo,
							orderNoSource: orderResponse.orderNoSource,
							orderVersion: orderResponse.version,
							isCentralDelivery: false,
							canModifyTimeWindow: false,
							canDeleteService: false,
							referenceItems:
								(dtoOrderDelivery.referencedItems &&
									dtoOrderDelivery.referencedItems.map(itm => {
										let cartitem = allItems.find(x => x.soSalesItemNo === itm);
										if (cartitem) {
											return getReferenceItem(cartitem);
										}
									})) ||
								[],
							price: dlPrice,
							timeWindows:
								(dtoOrderDelivery.timeWindows &&
									dtoOrderDelivery.timeWindows.map(time => {
										return {
											date: moment(time.fromLocalDateTime).format("YYYY-MM-DD"),
											fromDateTime: time.fromLocalDateTime,
											toDateTime: time.toLocalDateTime,
											timeWindowId: time.timeWindowId,
											id: time.timeWindowId,
											timeZone: time.timeZone,
											timeZoneId: time.timeZone,
											resourcePoolId: time.resourcePoolId,
											resourceId: time.resourcePoolId,
											orderNo: orderResponse.orderNo,
											orderNoSource: orderResponse.orderNoSource,
											workOrderNo: dtoOrderDelivery.workOrderNo,
											deliveryNo: dtoOrderDelivery.deliveryNo
										};
									})) ||
								[],
							serviceName: dtoOrderDelivery.serviceName,
							status: dtoOrderDelivery.status,
							statusTranslated: dtoOrderDelivery.statusText,
							deliveryMethod: dtoOrderDelivery.serviceId,
							serviceItemNo: dtoOrderDelivery.serviceItemNo,
							serviceProviderId: dtoOrderDelivery.serviceProviderId,
							serviceProviderName: dtoOrderDelivery.serviceProviderName,
							deliveryDestinationType: dtoOrderDelivery.deliveryDestinationType
						};
						dlService.selectedCartItems = dlService.referenceItems;
						let dlProposal = new ServiceProposalModel(dlService, true, false, orderResponse.customer.zipCode, dlService, pickingPrice);
						dlProposal.canModifyTimeWindow = false;
						dlProposal.canDeleteService = false;
						return dlProposal;
					})
					.toArray();
			}
		}
		let selectedPickingService = null;
		if (order.providedServices) {
			order.providedServices.forEach(service => {
				let allowedModification = serviceAllowedModifications.find(x => x.primeLineNo.toString() === service.lineId.toString());

				let allowedModifications = (allowedModification && allowedModification.allowedModifications) || [];
				let taxDetails = service.taxRates.map(tax => {
					return {
						...tax,
						taxPercent: tax.taxPercentage
					};
				});
				let sPrice = {
					...service.price,
					amount: service.price.priceInclTax || service.price.priceExclTax,
					taxAmount: taxDetails && taxDetails[0].taxAmount,
					taxDetails: taxDetails
				};
				let deviations = (allowedModification && allowedModification.deviations) || [];
				if (service.serviceProductId !== "PICKING_IN_STORE_CUST_ISL") {
					let providedService = {
						isLocked: true,
						customerServiceType: service.serviceProductId,
						price: sPrice,
						orderNo: orderResponse.orderNo,
						orderNoSource: orderResponse.orderNoSource,
						capacityNeeded: service.capacityValue,
						capacityUnit: service.capacityUnit,
						priceCalculationType: service.priceCalculationType,
						priceCalculationValue: service.priceCalculationValue,
						deviations: deviations,
						allowedModifications: allowedModifications,
						orderVersion: orderResponse.version,
						serviceProviderPreferredPayment: service.servicePaymentTo,
						serviceItemId: `SGR${service.serviceItemNo}`,
						lineId: service.lineId,
						serviceId: service.lineId,
						canModifyTimeWindow: allowedModifications.includes("CHANGE_SP_DATE"),
						canModifyPrice: allowedModifications.includes("CHANGE_SERVICE_PRICE"),

						canDeleteService: allowedModifications.includes("CANCEL_SERVICE"),
						serviceName: service.serviceName,
						serviceItemNo: service.serviceItemNo,
						serviceProductId: service.serviceProductId,
						serviceProviderId: service.serviceProviderId,
						serviceProviderName: service.serviceProviderName,
						status: service.status,
						statusText: service.statusText,
						referenceItems:
							(service.referencedItems &&
								service.referencedItems.map(itm => {
									let cartitem = allItems.find(x => x.soSalesItemNo === itm);
									if (cartitem) {
										return getReferenceItem(cartitem);
									}
								})) ||
							[],
						timeWindows: Enumerable.from(service.timeWindows)
							.select(function (time) {
								return {
									date: moment(time.fromLocalDateTime).format("YYYY-MM-DD"),
									fromDateTime: time.fromLocalDateTime,
									toDateTime: time.toLocalDateTime,
									timeZone: time.timeZone,
									timeZoneId: time.timeZone,
									timeWindowId: time.timeWindowId,
									resourcePoolId: time.resourcePoolId,
									resourceId: time.resourcePoolId,
									id: time.timeWindowId,
									orderNo: orderResponse.orderNo,
									orderNoSource: orderResponse.orderNoSource,
									lineId: service.lineId
								};
							})
							.toArray(),
						serviceType: "Service"
					};
					providedService.itemRequiredForService = providedService.referenceItems.length > 0;
					providedService.selectedCartItems = providedService.referenceItems;
					let serviceOption = {
						priceCalculationType: service.priceCalculationType,
						priceCalculationValue: service.priceCalculationValue,
						orderNo: orderResponse.orderNo,
						orderNoSource: orderResponse.orderNoSource,
						capacityNeeded: service.capacityValue,
						capacityUnit: service.capacityUnit,
						lineId: service.lineId,
						serviceType: "Service",
						serviceId: service.lineId,
						serviceName: service.serviceName,
						serviceItemNo: service.serviceItemNo,
						serviceItemId: `SGR${service.serviceItemNo}`,
						serviceProductId: service.serviceProductId,
						serviceProviderId: service.serviceProviderId,
						serviceProviderName: service.serviceProviderName,
						serviceProviderPreferredPayment: service.servicePaymentTo,
						status: service.status,
						statusText: service.statusText,
						canModifyTimeWindow: false,
						canDeleteService: false,
						referenceItems: providedService.referenceItems,
						selectedCartItems: providedService.referenceItems,
						itemRequiredForService: providedService.referenceItems.length > 0
					};
					let prService = new ServiceProposalModel(providedService, false, true, orderResponse.customer.zipCode, serviceOption);
					services.push(prService);
				} else {
					let pickingService = {
						price: sPrice,
						isLocked: true,
						priceCalculationType: service.priceCalculationType,
						priceCalculationValue: service.priceCalculationValue,
						capacityNeeded: service.capacityValue,
						capacityUnit: service.capacityUnit,
						itemRequiredForService: true,
						serviceProviderPreferredPayment: service.servicePaymentTo,
						serviceItemId: `SGR${service.serviceItemNo}`,
						orderVersion: orderResponse.version,
						serviceName: service.serviceName,
						serviceItemNo: service.serviceItemNo,
						serviceProductId: service.serviceProductId,
						serviceProviderId: service.serviceProviderId,
						serviceProviderName: service.serviceProviderName,
						status: service.status,
						statusText: service.statusText,
						referenceItems:
							(service.referencedItems &&
								service.referencedItems.map(itm => {
									let cartitem = allItems.find(x => x.soSalesItemNo === itm);
									if (cartitem) {
										return getReferenceItem(cartitem);
									}
								})) ||
							[]
					};
					pickingService.selectedCartItems = pickingPrice.referenceItems;
					pickingService.canModifyTimeWindow = false;
					pickingService.canDeleteService = false;
					selectedPickingService = pickingService;
				}
			});
		}

		let allservices = deliveryServices.concat(services);
		if (allservices.length === 0 && selectedPickingService) {
			allservices = [selectedPickingService];
		}
		return {
			order: {
				orderVersion: orderResponse.version,
				orderNo: orderResponse.orderNo,
				orderNoSource: orderResponse.orderNoSource
			},
			isCcdService: orderResponse.orderType === "ISOM",
			isLcdService: orderResponse.orderType !== "ISOM",
			bookedServices: allservices,
			hasDelivery: deliveryServices.length > 0,
			hadProvidedService: services.length > 0,
			selectedPickingService: selectedPickingService
		};
	} catch (error) {
		AppLogger.error(error.message, "MapDelivery");
	}
	return undefined;
};

const convertToPickupPoint = pickupPoint => {
	return {
		identifier: pickupPoint.identifier,
		distance: pickupPoint.distance,
		name: pickupPoint.name,
		openingHoursSunTime: getOpenTime("SUNDAY", pickupPoint.openingHours),
		openingHoursMonTime: getOpenTime("MONDAY", pickupPoint.openingHours),
		openingHoursTueTime: getOpenTime("TUESDAY", pickupPoint.openingHours),
		openingHoursWedTime: getOpenTime("WEDNESDAY", pickupPoint.openingHours),
		openingHoursThuTime: getOpenTime("THURSDAY", pickupPoint.openingHours),
		openingHoursFriTime: getOpenTime("FRIDAY", pickupPoint.openingHours),
		openingHoursSatTime: getOpenTime("SATURDAY", pickupPoint.openingHours),
		addressLine1: pickupPoint.address.addressLine1,
		addressLine2: pickupPoint.address.addressLine2,
		addressLine3: pickupPoint.address.addressLine3,
		addressLine4: pickupPoint.address.addressLine4,
		city: pickupPoint.address.city,
		zipCode: pickupPoint.address.zipCode,
		country: pickupPoint.address.country,
		id: pickupPoint.pupId,
		pupId: pickupPoint.pupId,
		state: pickupPoint.address.state,
		lsc: `${pickupPoint.lsc.buType}.${pickupPoint.lsc.buCode}`
	};
};

const getOpenTime = (weekName, openingHours) => {
	const openhour = openingHours.find(x => x.dayOfWeek.toLowerCase() === weekName.toLowerCase());

	if (openhour) {
		return openhour.span;
	}
	return;
};

const getCancelLink = (dtoOrder, links, cancelRel) => {
	const link = !ObjectHelper.isNil(links[cancelRel]) ? links[cancelRel].href : undefined;
	const isPaid = dtoOrder.orderPaid;
	const isCancelled = dtoOrder.orderStatus === "CANCELLED";
	if (dtoOrder.orderType === OrderTypes.IsomOrder && !isPaid && !isCancelled) {
		return link;
	} else if (dtoOrder.orderType === OrderTypes.IsomOrder) {
		return undefined;
	} else {
		return link;
	}
};

/**
 * Get the color key based on ISOM service order status
 * @param {String} status
 * @returns Color key of ORGANGE , GREEN, RED
 */
const getColorCode = status => {
	//Status copied from iSELL
	const completedtatus = ["Delivered", "PickedUpByCustomer", "Completed"];

	if (completedtatus.indexOf(status) > -1) {
		return "GREEN";
	}

	//Cancelled  Missing Damaged stopped
	const failedstatus = status.toLocaleLowerCase();
	if (
		failedstatus.includes("cancelled") ||
		failedstatus.includes("failed") ||
		failedstatus.includes("missing") ||
		failedstatus.includes("damaged") ||
		failedstatus.includes("stopped")
	) {
		return "RED";
	}

	return "ORGANE";
};

export default RecentOrderMapper;

const getReferenceItem = cartItem => {
	let factory = new ShoppingCartItemFactory();
	return factory.createServiceReferenceItem(cartItemsBuilder.createItem(cartItem), cartItem.id, cartItem.quantity);
};
