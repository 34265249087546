import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Enumerable from "linq";
import styled from "styled-components";

import { colors, device, getMessage, mediaQuery } from "@coworker/sharedlibrary";

import { combineItemsToGroup, deleteChildItemsInGroup, removeItem, removeItems, updateCartTotal } from "./../../../../store/cart/cartActions";

import Button from "@ingka/button";
import closeIcon from "@ingka/ssr-icon/paths/close-large";

import { List } from "./../../../../components/sharedlibrary";

import EditCartItemsList from "./editCartItemList";

const Root = styled.div`
	background: ${colors.white};
	width: calc(100%);
	padding: 0px;
`;

const Header = styled(List)`
	&.header {
		background: ${colors.white};
		width: ${props => props.width + "px" || "auto"};
		position: absolute;
		top: 70px;
		z-index: 1;
		padding: 10px 0 0 10px;
		border-bottom: 1px solid #dfdfdf;

		@media ${device.medium} {
			width: 100%;
			right: 0;
			padding: 10px 20px 0;

			&.up {
				position: fixed;
				top: 0;
				right: 0;
				padding: 0px 12px;
			}

			&.down {
				position: fixed;
				top: 0;
				right: 0;
				padding: 0px 12px;
			}
		}

		@media ${mediaQuery.mediumLandscape} {
			width: calc(100% - 70px);
			left: 70px;
		}

		@media ${device.small} {
			padding: 0 12px;
		}
	}
`;

const EditHeader = styled.div`
	min-height: 60px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const EditTitle = styled.div`
	color: ${colors.grey900};
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
`;

const CloseButton = styled(Button)`
	background: transparent;
`;

const GroupButtonSection = styled.div`
	background: transparent;
	width: 400px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 30px;

	button {
		width: 48%;
	}

	@media ${device.small} {
		width: 100%;
	}
`;

const ItemDiv = styled.div`
	margin-top: 120px;
	padding-bottom: 50px;
`;

const EditCart = ({ onCloseEdit }) => {
	const dispatch = useDispatch();

	const itemDivRef = useRef(null);

	const [clientWidth, setClientWidth] = useState(undefined);
	const [expandedItems, setExpandedItems] = useState([]);
	const [parentEditing, setParentEditing] = useState(undefined);
	const [routeAreaLastScrollTop, setRouteAreaLastScrollTop] = useState(0);
	const [routeAreaScrollDirection, setRouteAreaScrollDirection] = useState("");
	const [routeAreaScrollTop, setRouteAreaScrollTop] = useState(0);
	const [selectedItems, setSelectedItems] = useState([]);
	const { messages } = useSelector(state => state.intl);

	const { items } = useSelector(state => state.cartController);

	const determineScrollEvent = useCallback(() => {
		const supportPageOffset = window.pageXOffset !== undefined;
		const isCSS1Compat = (document.compatMode || "") === "CSS1Compat";
		const scrollY = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;

		setRouteAreaScrollTop(scrollY);
	}, []);

	const closeView = () => {
		dispatch(updateCartTotal());

		setTimeout(() => {
			setSelectedItems([]);

			onCloseEdit() && onCloseEdit();
		}, 100);
	};

	const onChildItemSelected = (id, selected, parent) => {
		setParentEditing(parent);

		let selecteditems = [...selectedItems];

		if (!selected) {
			selecteditems = selecteditems.filter(x => x.id !== id);
		} else {
			selecteditems.push(parent.childItems.find(x => x.id === id));
		}

		setSelectedItems(selecteditems);
	};

	const onItemSelected = (id, selected, isChildItem, parent) => {
		if (isChildItem) {
			onChildItemSelected(id, selected, parent);
		} else {
			let selecteditems = [...selectedItems];

			if (!selected) {
				selecteditems = selecteditems.filter(x => x.id !== id);
			} else {
				selecteditems.push(items.find(x => x.id === id));
			}

			setSelectedItems(selecteditems);
		}
	};

	const createGroup = () => {
		let selecteditems = [...selectedItems];

		selecteditems = Enumerable.from(selecteditems)
			.orderByDescending(x => x.isGroup)
			.toArray();

		const parent = selecteditems[0];
		const remainingItems = selecteditems.filter(x => x.id !== parent.id);

		let childItems = createChildItemsOnDrop(parent);

		remainingItems.forEach(x => {
			childItems = childItems.concat(createChildItemsOnDrop(x));
		});

		const ids = remainingItems.map(x => x.id.toString());

		dispatch(combineItemsToGroup(parent.id, childItems, ids));

		closeView();
	};

	const createChildItemsOnDrop = cartItem => {
		const childItems = [];

		if (cartItem.isGroup) {
			cartItem.childItems.forEach(subItm => {
				childItems.push({
					retailItem: subItm.retailItem,
					itemQuantity: cartItem.quantity * subItm.quantity
				});
			});
		} else if (cartItem.isProduct) {
			cartItem.childItems.forEach(subItm => {
				childItems.push({
					retailItem: subItm.retailItem,
					itemQuantity: cartItem.quantity * subItm.quantity
				});
			});
		} else {
			childItems.push({
				retailItem: cartItem.retailItem,
				itemQuantity: cartItem.quantity
			});
		}

		return childItems;
	};

	const deleteItems = () => {
		const selecteditems = [...selectedItems];
		let ids = [];

		if (parentEditing) {
			if (parentEditing.childItems.length === 1) {
				dispatch(removeItem(parentEditing.id));
			} else {
				ids = selecteditems.map(x => x.id.toString());
				dispatch(deleteChildItemsInGroup(parentEditing.id, ids));
			}
		} else {
			ids = selecteditems.map(x => x.id.toString());
			dispatch(removeItems(ids));
		}

		if (parentEditing) {
			setParentEditing(undefined);
		}

		closeView();
	};

	const onItemExpanded = (item, isExpanded) => {
		let selecteditems = [...expandedItems];

		if (isExpanded) {
			selecteditems.push(item);
		} else {
			if (parentEditing && item.id === parentEditing.id) {
				setParentEditing(undefined);
				setSelectedItems([]);
			}

			selecteditems = selecteditems.filter(x => x.id !== item.id);
		}

		setExpandedItems(selecteditems);
	};

	useEffect(() => {
		if ((routeAreaScrollTop === 0 || routeAreaScrollTop < 60) && routeAreaScrollDirection !== "up") {
			setRouteAreaScrollDirection("");
		} else if (
			(routeAreaLastScrollTop > routeAreaScrollTop || (routeAreaScrollTop > 50 && routeAreaScrollTop < 100)) &&
			routeAreaScrollDirection !== "up"
		) {
			setRouteAreaScrollDirection("up");
		} else if (routeAreaLastScrollTop < routeAreaScrollTop && routeAreaScrollDirection !== "down") {
			setRouteAreaScrollDirection("down");
		}
		if (routeAreaScrollTop === 0) {
			setRouteAreaScrollDirection("");
		}

		setRouteAreaLastScrollTop(routeAreaScrollTop);
	}, [routeAreaScrollTop, routeAreaLastScrollTop, routeAreaScrollDirection]);

	useEffect(() => {
		window.addEventListener("scroll", determineScrollEvent);

		return () => {
			window.removeEventListener("scroll", determineScrollEvent);
		};
	}, [determineScrollEvent]);

	useEffect(() => {
		const { clientWidth } = itemDivRef.current;

		setClientWidth(clientWidth);
	});

	useEffect(() => {
		const onResize = () => setClientWidth(itemDivRef.current.clientWidth);

		window.addEventListener("resize", onResize);

		return () => window.removeEventListener("resize", onResize);
	}, []);

	return (
		<Root>
			<Header className={`header top-header${routeAreaScrollDirection !== "" ? " down" : ""}`} width={clientWidth}>
				<EditHeader>
					<EditTitle>{getMessage("SG_LBL_Edit_Bag", messages, "Edit Shopping bag")}</EditTitle>
					<CloseButton iconOnly={true} onClick={() => onCloseEdit()} ssrIcon={closeIcon} type="transparent" />
				</EditHeader>

				<GroupButtonSection>
					<Button
						disabled={selectedItems.length < 2 || parentEditing !== undefined}
						onClick={() => createGroup()}
						text={getMessage("SG_LBL_Group", messages, "Group")}
						type="primary"
					></Button>
					<Button
						disabled={selectedItems.length === 0}
						onClick={() => deleteItems()}
						text={getMessage("SG_BTN_Delete", messages, "Delete")}
						type="secondary"
					></Button>
				</GroupButtonSection>
			</Header>

			<ItemDiv ref={itemDivRef}>
				<EditCartItemsList
					cartItems={items}
					expandedItems={expandedItems}
					onItemExpanded={onItemExpanded}
					onItemSelected={onItemSelected}
					parentEditing={parentEditing}
					selectedItems={selectedItems}
				/>
			</ItemDiv>
		</Root>
	);
};

export default EditCart;
