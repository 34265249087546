class ServiceProposalModel {
	constructor(data, isDelivery, isProvidedService, zipCode, serviceOption, pickingPrice) {
		this.initData(data);
		this.canDeleteService = true;
		this.canModifyTimeWindow = true;
		this.isDelivery = isDelivery;
		this.isProvidedService = isProvidedService;
		if (this.isDelivery) {
			this.id = data.deliveryMethod;
		} else {
			this.id = data.serviceProductId;
			this.isPickingService = data.serviceProductId === "PICKING_IN_STORE_CUST_ISL";
			if (Object.prototype.hasOwnProperty.call(this, "serviceProviderPreferredPayment")) {
				let payment = data.serviceProviderPreferredPayment;
				if (payment === "PAY_TO_IKEA") {
					this.payToIkea = true;
				} else if (payment === "PAY_TO_SP") {
					this.payToIkea = false;
				}
			}
			if (data.serviceProviderPaymentList) {
				let paymentOptions = data.serviceProviderPaymentList;
				this.hasAlternatePaymentOption = paymentOptions.length > 1;
			}
			this.setAlternateServiceProviders();
		}
		this.setTimeWindowAndProvided();
		this.zipCode = zipCode;
		this.referenceItems = serviceOption && serviceOption.selectedCartItems;
		this.selectedCartItems = this.referenceItems;
		this.serviceOption = serviceOption;
		this.serviceItemNo = serviceOption && serviceOption.serviceItemNo;
		if (pickingPrice !== null) {
			this.pickingPrice = pickingPrice;
		}
		this.itemRequiredForService = serviceOption.itemRequiredForService;
	}
	initData(data) {
		for (var key in data) {
			if (Object.prototype.hasOwnProperty.call(data, key)) {
				const element = data[key];
				this[key] = element;
			}
		}
	}
	setTimeWindowAndProvided() {
		let timeWindow = this.timeWindows && this.timeWindows[0];
		if (timeWindow) {
			this.timeWindow = timeWindow;
			if (this.isDelivery) {
				this.providerName = timeWindow.serviceProviderName;
				this.cutOffTime = timeWindow.cutOffDateTime;
				this.paymentTime = timeWindow.latestPaymentDateTime;
				this.deliveryTime = timeWindow.fromDateTime;
			} else {
				this.providerName = this["serviceProviderName"];
			}
		}
	}

	setAlternateServiceProviders() {
		if (this.alternateServiceProviders && this.alternateServiceProviders.length > 0) {
			this.alternateServiceProviders.push({
				preferredPaymentTo: this.serviceProviderPreferredPayment,
				serviceProviderId: this.serviceProviderId,
				serviceProviderName: this.serviceProviderName,
				serviceProviderPaymentList: this.serviceProviderPaymentList
			});
		}
	}

	inValidate() {
		if (this.serviceProviderPreferredPayment) {
			let payment = this.serviceProviderPreferredPayment;
			if (payment === "PAY_TO_IKEA") {
				this.payToIkea = true;
			} else if (payment === "PAY_TO_SP") {
				this.payToIkea = false;
			}
		}
		if (this.serviceProviderPaymentList) {
			let paymentOptions = this.serviceProviderPaymentList;
			this.hasAlternatePaymentOption = paymentOptions.length > 1;
		}
	}

	updateTimeWindows(timeWindows) {
		this.timeWindows = timeWindows;
		let timeWindow = this.timeWindows && this.timeWindows[0];
		if (timeWindow) {
			this.timeWindow = timeWindow;
		}
	}
}
export default ServiceProposalModel;
