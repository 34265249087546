import React from "react";
import styled from "styled-components";
import { ReactComponent as SaljaPlus } from "../static/salja-logo.svg";

const addStyles = styledObj => {
	return styledObj.div`
  @keyframes plus {
    0% {
      transform: rotateY(180deg);
    }
    20% {
      transform: rotateY(180deg);
    }
    50% {
      transform: rotateY(270deg);
    }
    80% {
      transform: rotateY(180deg);
    }
    100% {
      transform: rotateY(180deg);
    }
  }
  background: transparent;
  height: 80px;
  width: 80px;
  align-self: center;
  margin: auto 0;
  .salja-plus {
    width: 100%;
  }
  &.loading {
    .salja-plus {
      .cls-3 {
        transform-box: fill-box;
        transform-origin: center;
        animation: plus 1.5s infinite ease-in-out;
      }
    }
    @supports(-ms-ime-align:auto){
      &.loading {
        .salja-plus {
          .cls-3 {            
            transform-origin: 85% 50% 0;            
          }
        }
    }
  }
`;
};

const Client = window["styled-components"] ? addStyles(window["styled-components"]) : addStyles(styled);

const Mfe = addStyles(styled);

const SaljaLogo = (props, loading) => {
	return props.client ? (
		<Client className={loading ? "loading" : ""} {...props}>
			<SaljaPlus className="salja-plus" />
		</Client>
	) : (
		<Mfe className={loading ? "loading" : ""} {...props}>
			<SaljaPlus className="salja-plus" />
		</Mfe>
	);
};

export default SaljaLogo;
