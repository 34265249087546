import {
	ADD_ITEM_TO_CART,
	ADD_LOADING,
	CLEAR_CART,
	CLEAR_CART_SERVICES,
	CLEAR_EXTERNAL_REFERENCE,
	CONVERT_ORDER_TODRAFT,
	COVERT_ITEM_TO_GROUP,
	REMOVE_ADDED_ITEM,
	REMOVE_ITEM,
	SET_BOOKED_SERVICE_PROPOSAL,
	SET_CART_CURRENCY,
	SET_CART_DISCOUNTS,
	SET_CASH_LINE,
	SET_CREDIT_INFO,
	SET_CUSTOMER_INFORMATION,
	SET_CUSTOMER_MEETING_POINT,
	SET_DRAWINGS,
	SET_KITCHEN_ITEMS_IN_ORDER,
	SET_ORDER_CREATION_METHOD,
	SET_ORDER_MODE,
	SET_ORDER_MODIFIED_STATE,
	SET_ORDER_TO_ACTIVE,
	SET_PAYMENT_TYPE,
	SET_SALES_EVENT_TOPIC,
	UPDATEFULLITEM,
	UPDATE_ALL_ITEMS,
	UPDATE_CART_ITEM_STOCK,
	UPDATE_CART_PRICE_DATA,
	UPDATE_CREDIT_INFO,
	UPDATE_ITEMS,
	UPDATE_ITEM_GROUP,
	UPDATE_QUANTITY,
	UPDATE_QUOTATION
} from "./action-types/cart-actions";
import { GUID, SalesItemTypes, ShoppingCartItemFactory } from "@coworker/sharedlibrary";

import { PropTypes } from "prop-types";
import _ from "lodash";

const { SPR } = SalesItemTypes;

export const cartProductPropType = PropTypes.shape({
	id: PropTypes.number.isRequired,
	quantity: PropTypes.number.isRequired,
	item: PropTypes.object,
	totalPrice: PropTypes.object.isRequired,
	totalFamilyPrice: PropTypes.object
});

const initState = {
	id: GUID.Guid(),
	items: [],
	total: undefined,
	totalItemCount: 0,
	canCreateOrder: true,
	isModifyOrderinProgress: false,
	order: undefined,
	addLoading: false,
	salesPriceData: undefined,
	isQuotation: false,
	addedItemId: undefined,
	orderCreationMethod: undefined,
	currency: "USD",
	kitchen: undefined,
	customerMeetingPoint: undefined,
	paymentType: "PAYMENT_IN_ADVANCE",
	discounts: [],
	orderMode: "SALESDESK", //can be normal SALESDESK or TRANSPORTDESK if transport DESK only import from reciept
	isTransportDeskMode: false,
	externalReference: undefined,
	hideSaveShoppingList: false,
	hasServices: false,
	bookedServiceProposal: {
		zipCode: "",
		bookedServices: [],
		hasServices: false,
		isCcdService: false,
		isLcdService: false,
		selectedPickservice: undefined
	},
	questionnaires: {
		questionNeeded: false,
		deliveryQuestions: [],
		serviceQuestions: [],
		questionaireAnswers: [],
		selectedDeliveryQuestionnaireType: "FLAT"
	},
	serviceManager: {},
	customerInformation: {},
	drawings: [],
	creditInfo: undefined
};

const factory = new ShoppingCartItemFactory();
const cartReducer = (state = initState, action) => {
	if (action.type === UPDATE_CREDIT_INFO) {
		return {
			...state,
			creditInfo: {
				...state.creditInfo,
				creditTransactions: action.payload.creditTransactions
			}
		};
	}
	if (action.type === SET_CREDIT_INFO) {
		return {
			...state,
			creditInfo: { ...action.payload.creditInfo, creditTransactions: [] }
		};
	}
	if (action.type === SET_BOOKED_SERVICE_PROPOSAL) {
		return {
			...state,
			bookedServiceProposal: action.payload.bookedServiceProposal,
			questionnaires: action.payload.questionnaires,
			serviceManager: action.payload.serviceManager,
			hasServices: action.payload.hasServices,
			customerInformation: action.payload.customerInformation
		};
	}

	if (action.type === SET_SALES_EVENT_TOPIC) {
		return {
			...state,
			externalReference: action.payload.externalReference
		};
	}
	if (action.type === SET_ORDER_MODE) {
		return {
			...state,
			orderMode: action.payload.orderMode,
			isTransportDeskMode: action.payload.orderMode === "TRANSPORTDESK"
		};
	}
	if (action.type === SET_KITCHEN_ITEMS_IN_ORDER) {
		return {
			...state,
			kitchen: action.payload
		};
	}
	if (action.type === SET_DRAWINGS) {
		let drawings = [...state.drawings];
		if (action.payload.drawings) {
			action.payload.drawings.forEach(drwng => {
				drawings.push(drwng);
			});
		}
		return {
			...state,
			drawings: drawings
		};
	}
	if (action.type === SET_CART_CURRENCY) {
		return {
			...state,
			currency: action.payload.currency
		};
	}
	if (action.type === CLEAR_CART) {
		return {
			...state,
			creditInfo: undefined,
			isQuotation: false,
			id: GUID.Guid(),
			items: [],
			total: undefined,
			totalItemCount: 0,
			canCreateOrder: true,
			isModifyOrderinProgress: false,
			order: undefined,
			addLoading: false,
			salesPriceData: undefined,
			orderCreationMethod: undefined,
			kitchen: undefined,
			customerMeetingPoint: undefined,
			discounts: [],
			paymentType: "PAYMENT_IN_ADVANCE",
			orderMode: "SALESDESK",
			isTransportDeskMode: false,
			hideSaveShoppingList: false,
			cancelOrderList: [],
			hasServices: false,
			bookedServiceProposal: {
				zipCode: "",
				bookedServices: [],
				hasServices: false,
				isCcdService: false,
				isLcdService: false,
				selectedPickservice: undefined
			},
			questionnaires: {
				questionNeeded: false,
				deliveryQuestions: [],
				serviceQuestions: [],
				questionaireAnswers: [],
				selectedDeliveryQuestionnaireType: "FLAT"
			},
			serviceManager: {},
			customerInformation: {},
			drawings: [],
			selectedCashLine: undefined
		};
	}
	if (action.type === CLEAR_EXTERNAL_REFERENCE) {
		return {
			...state,
			externalReference: undefined
		};
	}
	if (action.type === ADD_ITEM_TO_CART) {
		let newItems = [...state.items];
		let addedItem = state.items.find(cartItem => cartItem.itemId === action.payload.cartItem.itemId);

		if (!addedItem) {
			//add item to cart if not exist
			let id = state.items.length === 0 ? 1 : state.items.length + 1;
			let addedItem = action.payload.cartItem;
			addedItem.setCartItemId(id);
			factory.updateGroupQuantity(addedItem, action.payload.quantity);
			newItems.push(addedItem);
		} else {
			if (action.payload.quantity !== 0) {
				//update the quantity of the item if exist

				if (addedItem.isCombination) {
					factory.updateGroupQuantity(addedItem, action.payload.quantity + addedItem.quantity);
				} else {
					factory.updateQuantity(addedItem, action.payload.quantity + addedItem.quantity);
				}

				const index = newItems.indexOf(addedItem);
				newItems[index] = addedItem;
				// newItems[index].quantity = action.payload.quantity;
			} else {
				//if quantity 0 remove the items from cart
				newItems = newItems.filter(cartItem => action.payload.cartItem.itemId !== cartItem.itemId);
			}
		}
		let { bookedServices = [] } = state.bookedServiceProposal;
		return {
			...state,
			...getStateData(newItems, state.currency, bookedServices),
			addedItemId: action.payload.cartItem.itemId
		};
	}

	if (action.type === REMOVE_ITEM) {
		let { bookedServices = [] } = state.bookedServiceProposal;
		let new_items = state.items.filter(item => action.id !== item.id);
		return {
			...state,
			...getStateData(new_items, state.currency, bookedServices)
		};
	}

	if (action.type === REMOVE_ADDED_ITEM) {
		return {
			...state,
			addedItemId: undefined
		};
	}

	if (action.type === UPDATE_QUANTITY) {
		let new_items = [...state.items];
		let isTransportDeskMode = state.isTransportDeskMode;
		let addedItem = state.items.find(item => item.id === action.id);
		if (isTransportDeskMode || action.quantity !== 0) {
			if (addedItem === undefined) {
				addedItem = state.items.find(item => item.itemId === action.id);
			}
			if (addedItem.isCombination) {
				factory.updateGroupQuantity(addedItem, action.quantity);
				if (addedItem.isGroup) {
					factory.updateGroupStock(addedItem);
				}
			} else {
				factory.updateQuantity(addedItem, action.quantity);
			}
			const index = new_items.indexOf(addedItem);
			new_items[index] = addedItem;

			new_items[index].quantity = action.quantity;
		} else {
			if (addedItem !== undefined) {
				new_items = new_items.filter(cartItem => action.id !== cartItem.id);
			} else {
				new_items = new_items.filter(cartItem => action.id !== cartItem.itemId);
			}
		}
		let { bookedServices = [] } = state.bookedServiceProposal;
		return {
			...state,
			...getStateData(new_items, state.currency, bookedServices)
		};
	}

	if (action.type === UPDATEFULLITEM) {
		let existingItem = state.items.find(item => item.itemId === action.retailItem.ItemId);
		if (existingItem) {
			existingItem.retailItem = factory.parseRetailItem(action.retailItem);
			if (action.retailItem.ItemType === SPR) {
				let childItems = action.childItems;

				factory.updateChildItems(existingItem, childItems);
			}
			let new_items = [...state.items];
			const index = new_items.indexOf(existingItem);
			new_items[index] = existingItem;
			let { bookedServices = [] } = state.bookedServiceProposal;
			return {
				...state,
				...getStateData(new_items, state.currency, bookedServices)
			};
		}
	}

	if (action.type === UPDATE_CART_ITEM_STOCK) {
		let new_items = [...state.items];
		if (!_.isNil(action.payload.stockItems)) {
			new_items = factory.reAssignCartItemStock(new_items, action.payload.stockItems, "STOCK");
		}
		let { bookedServices = [] } = state.bookedServiceProposal;
		return {
			...state,
			...getStateData(new_items, state.currency, bookedServices)
		};
	}

	if (action.type === SET_ORDER_TO_ACTIVE) {
		return {
			...state,
			...getStateDataFromOrder(action.payload.order)
		};
	}

	if (action.type === COVERT_ITEM_TO_GROUP) {
		let new_items = [...state.items];
		var index = new_items.findIndex(x => x.id === action.payload.id);
		if (index !== -1) new_items[index] = action.payload.groupItem;
		let { bookedServices = [] } = state.bookedServiceProposal;
		return {
			...state,
			...getStateData(new_items, state.currency, bookedServices)
		};
	}

	if (action.type === UPDATE_ITEM_GROUP) {
		let new_items = [...state.items];

		let group = action.payload.group;
		let itemIndex = new_items.findIndex(x => x.id === group.id);
		if (itemIndex !== -1) new_items[itemIndex] = group;
		let { bookedServices = [] } = state.bookedServiceProposal;
		return {
			...state,
			...getStateData(new_items, state.currency, bookedServices)
		};
	}
	if (action.type === UPDATE_ITEMS) {
		let new_items = [...state.items];

		let updatedItem = action.payload.retailItem;
		let newitemIndex = new_items.findIndex(x => x.id === updatedItem.id);
		if (newitemIndex !== -1) if (updatedItem) new_items[newitemIndex] = updatedItem;

		let { bookedServices = [] } = state.bookedServiceProposal;
		return {
			...state,
			...getStateData(new_items, state.currency, bookedServices)
		};
	}
	if (action.type === ADD_LOADING) {
		return {
			...state,
			addLoading: action.payload
		};
	}
	if (action.type === UPDATE_ALL_ITEMS) {
		let { bookedServices = [] } = state.bookedServiceProposal;
		let { cartItems } = action.payload;
		return {
			...state,
			...getStateData(cartItems, state.currency, bookedServices)
		};
	}

	if (action.type === UPDATE_CART_PRICE_DATA) {
		let { salesPriceData } = action.payload;
		return {
			...state,
			salesPriceData: salesPriceData
		};
	}

	if (action.type === UPDATE_QUOTATION) {
		return {
			...state,
			order: action.payload
		};
	}

	if (action.type === SET_ORDER_CREATION_METHOD) {
		return {
			...state,
			orderCreationMethod: action.payload.orderCreationMethod
		};
	}
	if (action.type === SET_CUSTOMER_MEETING_POINT) {
		return {
			...state,
			customerMeetingPoint: action.payload.customerMeetingPoint
		};
	}
	if (action.type === SET_PAYMENT_TYPE) {
		return {
			...state,
			paymentType: action.payload.paymentType,
			creditInfo: action.payload.paymentType !== "PAYMENT_IN_INVOICE" ? undefined : state.creditInfo
		};
	}

	if (action.type === SET_CART_DISCOUNTS) {
		return {
			...state,
			discounts: action.payload
		};
	}
	if (action.type === CONVERT_ORDER_TODRAFT) {
		return {
			...state,
			hideSaveShoppingList: true,
			cancelOrderList: [
				{
					orderNo: state.order.orderNo,
					cancelOrderLink: state.order.cancelOrderLink
				}
			],
			...getStateDataFromOrder({
				...state.order,
				isDraft: true
			})
		};
	}

	if (action.type === CLEAR_CART_SERVICES) {
		return {
			...state,
			hasServices: false,
			bookedServiceProposal: {
				zipCode: "",
				bookedServices: [],
				hasServices: false,
				isCcdService: false,
				isLcdService: false,
				selectedPickservice: undefined
			},
			questionnaires: {
				questionNeeded: false,
				deliveryQuestions: [],
				serviceQuestions: [],
				questionaireAnswers: [],
				selectedDeliveryQuestionnaireType: "FLAT"
			},
			serviceManager: {},
			customerInformation: {}
		};
	}

	if (action.type === SET_CUSTOMER_INFORMATION) {
		return {
			...state,
			customerInformation: action.payload
		};
	}
	if (action.type === SET_ORDER_MODIFIED_STATE) {
		return {
			...state,
			order: { ...action.payload.order }
		};
	}
	if (action.type === SET_CASH_LINE) {
		return {
			...state,
			selectedCashLine: { ...action.payload.cashLine }
		};
	}
	return state;
};

const getStateDataFromOrder = order => {
	var new_items = [...order.orderItems];
	if (order.isDraft) {
		new_items = factory.unlockLines(new_items);
	}
	var price = factory.getTotal(new_items);
	price.Currency = order.orderCurrencyCode;
	let hasItemsPicked = new_items.filter(x => x.quantity > 0).length > 0;
	return {
		drawings: order.drawings,
		isModifyOrderinProgress: order.isDraft || order.isQuotation ? false : true,
		order: order.isQuotation ? order : order.isDraft ? undefined : order,
		isQuotation: order.isQuotation,
		items: new_items,
		total: price,
		isDraft: order.isDraft,
		totalFamilyPrice: factory.getTotalFamilyPrice(new_items),
		totalItemCount: factory.getTotalItemCount(new_items),
		canCreateOrder: factory.canCreateOrder(new_items),
		totalPackages: factory.getTotalNoPackages(new_items),
		totalWeight: factory.getTotalWeight(new_items),
		totalFullWeight: {
			metric: factory.getTotalWeight(new_items, "METRIC"),
			imperial: factory.getTotalWeight(new_items, "IMPERIAL")
		},
		weightUnit: factory.getWeightUnit(new_items),
		id: order.isQuotation ? order.orderNo : order.isDraft ? order.id : order.orderNo,
		hasItemsPicked: hasItemsPicked,
		isTransportDeskMode: order.isTransportDeskMode,
		selectedCashLine: order.selectedCashLine
	};
};
const getStateData = (new_items, currency, bookedServices = []) => {
	let newItems = factory.reAssignCartItemId(new_items, bookedServices);
	var total = factory.getTotal(new_items);
	total.Currency = currency;
	let hasItemsPicked = new_items.filter(x => x.quantity > 0).length > 0;
	return {
		items: newItems,
		total: total,
		totalFamilyPrice: factory.getTotalFamilyPrice(new_items),
		totalItemCount: factory.getTotalItemCount(new_items),
		canCreateOrder: factory.canCreateOrder(new_items),
		totalPackages: factory.getTotalNoPackages(new_items),
		totalWeight: factory.getTotalWeight(new_items),
		totalFullWeight: {
			metric: factory.getTotalWeight(new_items, "METRIC"),
			imperial: factory.getTotalWeight(new_items, "IMPERIAL")
		},
		weightUnit: factory.getWeightUnit(new_items),
		hasItemsPicked: hasItemsPicked || bookedServices.length > 0
	};
};

export default cartReducer;
