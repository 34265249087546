import { FeaturesList, HitType, PagePaths } from "@coworker/sharedlibrary";
import { openOrder, openQuotationToOrder } from "./../../store/cart/cartActions";
import { useDispatch, useSelector } from "react-redux";

import AppLogger from "../../logger/appLogger";
import React from "react";
import SalesCoworkerConfiguration from "../../store/salesCoworkerConfiguration";
import { getStoreConfigSelector } from "../../utils/shellObjectService";
import { actions as localeActions } from "../../store/locale";
import { push } from "connected-react-router";
import { removeVisualSearchFeature } from "./../../store/shell/shell.thunks";
import { setSearch } from "./../../store/import/actions";
import useMicrofrontend from "../../hooks/useMicroFrontend";

const MicroAutoCompleteView = ({ externalImport, id, onScanOpened, externalSearch = false, width = "auto" }) => {
	let appConfig = getStoreConfigSelector();
	let config = { ...appConfig };
	const { appId, appUrl, appName } = config.environment.microapps.pipApp;
	const dispatch = useDispatch();
	const { isLoaded, pip } = useMicrofrontend(appId, appUrl, appName);
	config.externalImport = externalImport;
	config.externalSearch = externalSearch;
	const { locale } = useSelector(state => state.localeController);
	const { isTransportDeskMode } = useSelector(state => state.cartController);
	config.view = "searchBox";
	const onScan = e => {
		let isScanning = e.detail.isScanning;
		onScanOpened(isScanning);
	};
	const onSelectSuggestion = e => {
		const suggestion = e.detail.suggestion;
		setSearchResult(suggestion);
		if (suggestion.hitType === HitType.SpecialKey) {
			if (suggestion.query === "SHOW_KEYS") {
				dispatch(localeActions.getTranslations(config.locale, true));
			}
			if (suggestion.query === "HIDE_KEYS") {
				dispatch(localeActions.getTranslations(config.locale, false));
			}
			if (suggestion.query === "USE_SCAN") {
				dispatch(removeVisualSearchFeature());
			}
		}
		if (suggestion.hitType === HitType.kioskCart) {
			dispatch(
				push(PagePaths.kioskpath, {
					customerid: suggestion.data.customerid
				})
			);
		}

		if (suggestion.hitType === HitType.Planner) {
			dispatch(
				push(externalImport ? PagePaths.ExternalPlannerPage + suggestion.text : PagePaths.PlannerPage + suggestion.text, {
					query: suggestion.text
				})
			);
		}
		if (suggestion.hitType === HitType.Ptag) {
			dispatch(
				push(externalImport ? PagePaths.ExternalPtagPage + suggestion.text : PagePaths.PtagPage + suggestion.text, {
					query: suggestion.text
				})
			);
		}
		if (suggestion.hitType === HitType.Order) {
			if (externalImport) {
				dispatch(
					push(`/external/import/order/${suggestion.orderNo}`, {
						orderNo: suggestion.orderNo
					})
				);
			} else {
				if (SalesCoworkerConfiguration.isAllowedFeature(FeaturesList.IMPORT_ORDER)) {
					dispatch(
						push(`/importorder/${suggestion.orderNo}`, {
							orderNo: suggestion.orderNo
						})
					);
				} else {
					dispatch(openOrder(suggestion.orderNo));
				}
			}
		}
		if (suggestion.hitType === HitType.webShoppingList) {
			dispatch(
				push(externalImport ? PagePaths.ExternalWebShoppingListPath : PagePaths.WebShoppingListPath, {
					shoppinglist: suggestion.data
				})
			);
		}

		if (suggestion.hitType === HitType.quotation) {
			dispatch(openQuotationToOrder(suggestion.orderNo));
		}
		if (suggestion.hitType === HitType.KitchenPlanner) {
			dispatch(
				push(
					`${externalImport ? PagePaths.ExternalKitchenPlannerPagePath : PagePaths.KitchenPlannerPagePath}${
						suggestion.serialNumber + "/" + suggestion.plannerSource
					}`,
					{
						query: suggestion,
						kitchen: suggestion
					}
				)
			);
		}
		if (suggestion.hitType === HitType.Receipt) {
			dispatch(
				push("/receipt/" + suggestion.id, {
					receipt: suggestion.data
				})
			);
		}

		if (
			suggestion.hitType === HitType.History ||
			suggestion.hitType === HitType.Product ||
			suggestion.hitType === HitType.Article ||
			suggestion.hitType === HitType.Series ||
			suggestion.hitType === HitType.Suggestion
		) {
			navigateToSearch(suggestion);
		}
	};
	function navigateToSearch(suggestion) {
		pip && pip.clearFilters && pip.clearFilters();
		if (suggestion.hitType === HitType.Product) {
			if (externalImport) {
				dispatch(push(PagePaths.ExternalDetailPage + suggestion.ItemType + suggestion.ItemId, suggestion));
			} else {
				dispatch(push(PagePaths.DetailPage + suggestion.ItemType + suggestion.ItemId, suggestion));
			}
		}
		if (suggestion.hitType === HitType.Article) {
			if (externalImport) {
				dispatch(
					push(PagePaths.ExternalSearchPage + suggestion.text, {
						query: suggestion.text,
						useLocalSearch: true
					})
				);
			} else {
				dispatch(
					push(PagePaths.SearchPage + suggestion.text, {
						query: suggestion.text,
						useLocalSearch: true
					})
				);
			}
		}
		if (suggestion.hitType === HitType.History) {
			if (externalImport) {
				dispatch(
					push(PagePaths.ExternalSearchPage + suggestion.text, {
						query: suggestion.text,
						useLocalSearch: true
					})
				);
			} else {
				dispatch(
					push(PagePaths.SearchPage + suggestion.text, {
						query: suggestion.text,
						useLocalSearch: true
					})
				);
			}
		}
		if (suggestion.hitType === HitType.Series) {
			if (externalImport) {
				dispatch(
					push(PagePaths.ExternalSearchPage + suggestion.name, {
						query: suggestion.id,
						useLocalSearch: false,
						useCategorySearch: true
					})
				);
			} else {
				dispatch(
					push(PagePaths.SearchPage + suggestion.name, {
						query: suggestion.id,
						useLocalSearch: false,
						useCategorySearch: true
					})
				);
			}
		}
		if (suggestion.hitType === HitType.Suggestion) {
			if (externalImport) {
				dispatch(
					push(PagePaths.ExternalSearchPage + suggestion.name, {
						query: suggestion.name,
						useLocalSearch: true
					})
				);
			} else {
				dispatch(
					push(PagePaths.SearchPage + suggestion.name, {
						query: suggestion.name,
						useLocalSearch: suggestion.useLocalSearch
					})
				);
			}
		}
	}
	function setSearchResult(importData) {
		try {
			let importModel = importData;
			if (importData && importData.isPlanner && importData.data && importModel.data.plannerItems) {
				importModel.data.plannerItems = importModel.data.plannerItems.map(itm => {
					return {
						itemType: itm.itemType,
						itemNo: itm.itemNo,
						quantity: itm.itemQuantity
					};
				});
			}
			dispatch(setSearch({ ...importModel }));
		} catch (error) {
			AppLogger.error(error.message, "setSearchResult");
		}
	}

	React.useEffect(() => {
		pip && pip.SetSharedConfiguration(appConfig);
	}, [config.coworkerData, config.coworkerData.primaryLocale, locale, config.environment, isTransportDeskMode, config.isCashLine, config.coworkerWorkArea]);

	React.useEffect(() => {
		if (!pip) return;
		pip.SetSharedConfiguration(config);
		const { render, unMount, subscribe, unSubscribe } = pip;
		unSubscribe(`NAVIGATE-${id}-microAutoCompleteView-${appId}`, onSelectSuggestion);
		subscribe(`NAVIGATE-${id}-microAutoCompleteView-${appId}`, onSelectSuggestion);
		subscribe(`SCANNING`, onScan);
		render(`${id}-microAutoCompleteView-${appId}`, config);
		return () => {
			unMount(`${id}-microAutoCompleteView-${appId}`);
			unSubscribe(`SCANNING`, onScan);
			unSubscribe(`NAVIGATE-${id}-microAutoCompleteView-${appId}`, onSelectSuggestion);
		};
	}, [isLoaded]); // eslint-disable-line

	if (!isLoaded) return null;
	if (!pip) return <div />;
	return (
		<div
			id={`${id}-microAutoCompleteView-${appId}`}
			style={{
				width: width
			}}
		/>
	);
};
export default MicroAutoCompleteView;
