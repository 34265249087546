import { GaHelper, dispatchEvent as eventDispatcher } from "@coworker/sharedlibrary";
import { LocaleHelper, SalesItemTypes, ShoppingCartItemFactory, Specifications, cartItemsBuilder, eventsToDispatch, services } from "@coworker/sharedlibrary";
import { push } from "connected-react-router";
import _ from "lodash";

import { CanAddItem } from "./../../containers/cart/canCreateSalesOrderValidator";
import { getMicroFrontendInstance } from "./../../utils/shellObjectService";
import AppLogger from "../../logger/appLogger";
import OrderAmountCalculatorService from "../../service/cart/orderAmountCalculatorService";
import OrderModificationService from "../../service/cart/orderModificationService";
import OrderRetreivalService from "../../service/orderRetrievalService";
import { getCustomerProfileObject } from "../../utils/shellObjectService";
import { NotificationType, appactionCreators } from "./../shell/shellAction";
import SalesCoworkerConfiguration from "../salesCoworkerConfiguration";
import {
	ADD_ITEM_TO_CART,
	ADD_LOADING,
	CLEAR_CART,
	COVERT_ITEM_TO_GROUP,
	REMOVE_ADDED_ITEM,
	REMOVE_ITEM,
	SET_CART_DISCOUNTS,
	SET_CUSTOMER_MEETING_POINT,
	SET_DRAWINGS,
	SET_ORDER_CREATION_METHOD,
	SET_ORDER_TO_ACTIVE,
	UPDATEFULLITEM,
	UPDATE_ALL_ITEMS,
	UPDATE_CART_ITEM_STOCK,
	UPDATE_CART_PRICE_DATA,
	UPDATE_ITEMS,
	UPDATE_ITEM_GROUP,
	UPDATE_QUANTITY,
	UPDATE_QUOTATION
} from "./action-types/cart-actions";
import { setServiceProposal, sethasKitchenItemsInOrder } from "./cart.thunks";

// import {
//   clearCartServices,
//   recalculateGetDeliveryAndServices
// } from "../cartManager/actions";

//import { updateRecentOrder } from "../recentOrder/recentOrderAction";

const { SPR, GROUP } = SalesItemTypes;
const factory = new ShoppingCartItemFactory();

export const addItemToCart = (item, qty, isCashLine) => async (dispatch, getState) => {
	const state = getState();
	const { messages } = state.intl;
	const cartItem = factory.createShoppingCartItem(item, qty);
	const { isModifyOrderinProgress, order, items, selectedCashLine } = getState().cartController;
	const canModifyOrder = CanAddItem(state);

	if (!canModifyOrder) {
		dispatch(appactionCreators.setOrderModificationQueue(item, qty));
		return Promise.reject();
	} else if (isModifyOrderinProgress) {
		dispatch(setLoading(true));
		dispatch(appactionCreators.showBusyLoading(true));

		if (order.isCcdService) {
			cartItem.isOrderLine = true;
			if (isCashLine) {
				factory.updateCashLineItem(cartItem, selectedCashLine);
			}
			dispatch({
				type: ADD_ITEM_TO_CART,
				payload: { cartItem: cartItem, quantity: qty }
			});
			dispatch(recalculateDelivery());
		} else {
			let recentOrder;
			const modifyservice = new OrderModificationService();
			const existingItem = items.find(x => x.itemId === cartItem.itemId);

			if (!_.isNil(existingItem)) {
				recentOrder = await modifyservice.changeQuantity(order, existingItem, qty);
			} else {
				recentOrder = await modifyservice.addNewOrderItem(order, 1, cartItem);
			}

			dispatch(appactionCreators.showBusyLoading(false));

			if (recentOrder !== null) {
				dispatch(clearAndReloadOrder(recentOrder));
			} else {
				dispatch(setLoading(false));
				dispatch(
					appactionCreators.showNotification(NotificationType.Error, messages.SG_LBL_ItemFailedToAdd || "OBS ! Unable to add item to the order ")
				);
				return Promise.reject();
			}
		}
	} else {
		dispatch(setLoading(true));
		if (isCashLine) {
			factory.updateCashLineItem(cartItem, selectedCashLine);
		}
		dispatch({
			type: ADD_ITEM_TO_CART,
			payload: { cartItem: cartItem, quantity: qty }
		});

		sendItemToAnalytics(cartItem, "add");
		//dont call on add to calulate price calls on update full item
		//dispatch(recalculateDelivery());
	}

	return Promise.resolve().then(() => {
		dispatch(setLoading(false));
	});
};

export const removeAddedItem = () => dispatch => {
	dispatch({
		type: REMOVE_ADDED_ITEM
	});
};

export const addPlannerToCart = (planner, qty) => dispatch => {
	let group = {
		itemDescription: planner.itemDescription,
		Name: planner.Name,
		ItemId: planner.ItemId,
		price: planner.price,
		familyPrice: planner.familyPrice,
		availableStockToSell: planner.availableStockToSell,
		imageList: planner.imageList,
		ItemType: "PLANNER"
	};

	let cartItem = factory.createCombinationCartItem(group, qty, planner.childItems);

	dispatch({
		type: ADD_ITEM_TO_CART,
		payload: { cartItem: cartItem, quantity: qty }
	});
	dispatch({
		type: SET_DRAWINGS,
		payload: {
			drawings: planner.drawingList
		}
	});
	AppLogger.info(`Add Planner to Cart ${cartItem.itemId}`, "AddItemToCart", {
		PlannerId: cartItem.itemId
	});
	dispatch(recalculateDelivery());
	sendItemToAnalytics(cartItem, "add");

	return Promise.resolve();
};

export const addRoomsetToCart = (roomset, qty) => dispatch => {
	let group = {
		ItemId: roomset.ItemId,
		ItemType: GROUP,
		Name: roomset.Name,
		itemDescription: roomset.itemDescription,
		price: roomset.price,
		familyPrice: roomset.familyPrice,
		availableStockToSell: roomset.availableStockToSell,
		imageList: roomset.imageList
	};

	let roomsetItem = factory.createCombinationCartItem(
		group,
		qty,
		roomset.childItems.map(childItem => {
			return {
				...childItem,
				retailItem: {
					...childItem.retailItem,
					ItemUnitCode: childItem.retailItem.ItemUnitCode || "PIECES"
				}
			};
		})
	);

	factory.updateGroupQuantity(roomsetItem, qty, true);
	factory.updateGroupImages(roomsetItem);
	factory.updateGroupStock(roomsetItem);

	dispatch({
		type: ADD_ITEM_TO_CART,
		payload: { cartItem: roomsetItem, quantity: qty }
	});

	AppLogger.info(`Add Roomset to Cart ${roomsetItem.itemId}`, "AddItemToCart", {
		RoomsetId: roomsetItem.itemId
	});
	dispatch(recalculateDelivery());
	sendItemToAnalytics(roomsetItem, "add");

	return Promise.resolve();
};

export const addPtagToCart = (ptag, qty) => dispatch => {
	let ptagGroup = {
		ItemId: ptag.ItemId,
		ItemType: "PTAG",
		Name: ptag.Name,
		itemDescription: ptag.itemDescription,
		price: ptag.price,
		familyPrice: ptag.familyPrice,
		availableStockToSell: ptag.availableStockToSell,
		imageList: ptag.imageList
	};
	let cartItem = factory.createCombinationCartItem(ptagGroup, qty, ptag.childItems);
	dispatch({
		type: ADD_ITEM_TO_CART,
		payload: { cartItem: cartItem, quantity: qty }
	});
	AppLogger.info(`Add Ptag to Cart ${cartItem.itemId}`, "AddItemToCart", {
		PtagId: cartItem.itemId
	});

	dispatch(recalculateDelivery());
	sendItemToAnalytics(cartItem, "add");

	return Promise.resolve();
};

export const clearCart = () => (dispatch, getState) => {
	dispatch({
		type: CLEAR_CART
	});
	dispatch(clearCartDeliveryServices()).then(() => {
		eventDispatcher(eventsToDispatch.CLEAR_ALL_CUSTOMER, {});
	});
	//set CustomerMeetingPoint and orderCreationMethod
	let state = getState();
	const { appController, cartController } = state;
	let { coworkerData, buCode } = appController;
	const { cancelOrderList = [] } = cartController;
	if (coworkerData && coworkerData.userSettings && coworkerData.userSettings.length > 0) {
		let usrSettings = coworkerData.userSettings.find(x => x.buCode === buCode);

		if (usrSettings && usrSettings.customerMeetingPoint) {
			dispatch({
				type: SET_CUSTOMER_MEETING_POINT,
				payload: { customerMeetingPoint: usrSettings.customerMeetingPoint }
			});
		}
		if (usrSettings && usrSettings.orderCreationMethod) {
			dispatch({
				type: SET_ORDER_CREATION_METHOD,
				payload: { orderCreationMethod: usrSettings.orderCreationMethod }
			});
		}
	}
	if (cancelOrderList && cancelOrderList.length > 0) {
		cancelOrderList.forEach(cancelOrder => {
			try {
				let orderModifyService = new OrderModificationService();
				orderModifyService.cancelOrder(cancelOrder);
			} catch {
				//
			}
		});
	}
	return Promise.resolve(true);
};

export const resetCart = (item, qty) => dispatch => {
	dispatch({
		type: CLEAR_CART
	});

	dispatch(clearCartDeliveryServices()).then(() => {
		eventDispatcher("CLEAR_CUSTOMER", {});
	});

	dispatch(addItemToCart(item, qty));
};

//remove item action
export const removeItem = id => async (dispatch, getState) => {
	let state = getState();
	const { cartController } = state;
	let { isModifyOrderinProgress, items, order } = cartController;
	let orderItem = items.find(x => x.id === id);

	if (isModifyOrderinProgress) {
		if (order.isCcdService) {
			dispatch({
				type: REMOVE_ITEM,
				id
			});
			dispatch(recalculateDelivery());
		} else {
			dispatch(appactionCreators.showBusyLoading(true));
			let modifyservice = new OrderModificationService();
			let recentorder = await modifyservice.removeOrderItem(order, orderItem);
			dispatch(appactionCreators.showBusyLoading(false));

			if (recentorder !== null) {
				dispatch(clearAndReloadOrder(recentorder));
			}
		}
	} else {
		dispatch({
			type: REMOVE_ITEM,
			id
		});
		dispatch(recalculateDeliveryOnItemRemove(orderItem));
		sendItemToAnalytics(orderItem, "remove");
	}
};

const reCheckCart = callback => (dispatch, getState) => {
	let { items } = getState().cartController;
	let { locale } = getState().localeController;
	let cnCode = LocaleHelper.getCountryCode(locale);
	if (items.length === 0) {
		dispatch(clearCart());
		//TODO: must call orderservice mfe
		//dispatch(clearCartServices());
	} else {
		if (Specifications.usesSpeCalcSpec(cnCode)) {
			dispatch(updateCartTotal()).then(res => {
				if (res && res.code === 0) callback && callback(res);
			});
		}
	}
};

export const changeQuantity = (id, quantity) => (dispatch, getState) => {
	let state = getState();
	let { isModifyOrderinProgress, isQuotation, hasServices } = state.cartController;
	if (isModifyOrderinProgress) {
		dispatch(updateOrderQuantity(id, quantity));
	} else {
		dispatch(updateQuantity(id, quantity)).then(() => {
			if (hasServices) {
				dispatch(appactionCreators.showBusyLoading(true));
			}
			dispatch(recalculateDelivery());
		});
		dispatch(
			reCheckCart(res => {
				if (res) {
					if (isQuotation) {
						//TODO:update quoteation
						// checkOutService.updateQuotation().then(resp => {
						//   console.log(resp);
						// });
					}
				}
			})
		);
	}

	return Promise.resolve();
};

const updateOrderQuantity = (id, quantity) => async (dispatch, getState) => {
	dispatch({
		type: UPDATE_QUANTITY,
		id,
		quantity
	});

	let state = getState();
	let { messages } = state.intl;
	let { items, order, isTransportDeskMode } = state.cartController;

	let orderItem = items.find(x => x.id === id || x.itemId === id);

	dispatch(appactionCreators.showBusyLoading(true));
	if (!isTransportDeskMode) {
		if (orderItem && orderItem.availableStockToSell < orderItem.quantity) {
			dispatch(
				appactionCreators.showNotification(
					NotificationType.Warn,
					messages.SG_LBL_NoStockWarning || "Item not in stock",
					`${orderItem.itemId} ${orderItem.name}`
				)
			);
		}
	}

	if (order.isCcdService) {
		dispatch(recalculateDelivery());
		if (quantity === 0) {
			dispatch(recalculateDeliveryOnItemRemove(orderItem));
		}
	} else {
		let modifyservice = new OrderModificationService();
		let recentorder = await modifyservice.changeQuantity(order, orderItem, quantity);
		//dispatch(updateRecentOrder(recentorder));
		dispatch(appactionCreators.showBusyLoading(false));

		if (recentorder !== null) {
			dispatch(clearAndReloadOrder(recentorder));
		}
		dispatch(reCheckCart());
		return Promise.resolve();
	}
};

const updateQuantity = (id, quantity) => (dispatch, getState) => {
	dispatch({
		type: UPDATE_QUANTITY,
		id,
		quantity
	});

	let { items } = getState().cartController;

	let cartItem = items.find(x => x.id === id);
	if (!cartItem) {
		cartItem = items.find(item => item.itemId === id);
	}
	if (cartItem) {
		sendItemToAnalytics(cartItem, "add");
	}
	return Promise.resolve();
};

export const convertItemToGroup = (id, childItems, shouldExpand = true) => (dispatch, getState) => {
	let { items } = getState().cartController;
	let groups = items.filter(x => x.isGroup);
	let newChildItems = [];

	childItems.forEach(child => {
		let existing = newChildItems.find(x => x.retailItem.ItemId === child.retailItem.ItemId);
		if (!existing) {
			newChildItems.push(child);
		} else {
			existing.itemQuantity = existing.itemQuantity + child.itemQuantity;
		}
	});
	let existingItem = items.find(x => x.id === id);

	let group = {
		ItemType: GROUP,
		Name: existingItem.isGroup ? existingItem.name : `Group${groups.length === 0 ? 1 : groups.length + 1}`,
		itemDescription: "",
		ItemId: ``,
		price: existingItem.price,
		familyPrice: existingItem.familyPrice,
		availableStockToSell: existingItem.availableStockToSell,
		isPlanner: false,
		imageList: []
	};

	let groupItem = factory.createCombinationCartItem(group, existingItem.quantity, newChildItems);
	groupItem.isExpanded = shouldExpand;
	factory.updateGroupStock(groupItem);
	factory.updateGroupQuantity(groupItem, groupItem.quantity, true);
	factory.updateGroupImages(groupItem);

	dispatch({
		type: COVERT_ITEM_TO_GROUP,
		payload: {
			id: existingItem.id,
			groupItem: groupItem
		}
	});
	dispatch(updateCartItemStock());
	dispatch(recalculateDelivery());
};

export const updateGroupChildItemQuantity = (groupId, childItemId, quantity) => (dispatch, getState) => {
	let { items } = getState().cartController;
	let group = items.find(x => x.id === groupId);
	let childItems = [...group.childItems];
	let index = childItems.findIndex(x => x.id === childItemId);
	let updatedChildItem = Object.assign({}, { ...childItems[index] });
	updatedChildItem.quantity = quantity;
	childItems[index] = updatedChildItem;
	group.childItems = childItems;
	group.isExpanded = true;
	factory.updateGroupQuantity(group, group.quantity, true, childItemId);
	factory.updateGroupStock(group);
	dispatch({
		type: UPDATE_ITEM_GROUP,
		payload: {
			group: group
		}
	});
	dispatch(updateCartItemStock());
	dispatch(recalculateDelivery());
};

export const deleteChildItemInGroup = (groupId, childId) => async (dispatch, getState) => {
	let { items } = getState().cartController;
	let existingItem = items.find(x => x.id === groupId);
	let newChildItems = existingItem.childItems.filter(x => x.id !== childId);
	let updatedItem = Object.assign({}, existingItem);
	updatedItem.childItems = newChildItems;
	factory.updateGroupQuantity(updatedItem, updatedItem.quantity, true);
	factory.updateGroupStock(updatedItem);
	factory.updateGroupImages(updatedItem);

	dispatch({
		type: UPDATE_ITEMS,
		payload: {
			retailItem: updatedItem
		}
	});
	dispatch(updateCartItemStock());
	dispatch(recalculateDelivery());
};

export const setisProductExpanded = (id, isExpanded) => async (dispatch, getState) => {
	let { items } = getState().cartController;
	let existingItem = items.find(x => x.id === id);
	existingItem.isExpanded = isExpanded;
};

export const updateGroupName = (id, name) => async (dispatch, getState) => {
	let { items } = getState().cartController;
	let existingItem = items.find(x => x.id === id);
	let updatedItem = Object.assign({}, existingItem);
	updatedItem.name = name;
	updatedItem.retailItem.Name = name;
	dispatch({
		type: UPDATE_ITEMS,
		payload: {
			retailItem: updatedItem
		}
	});
};

//add cart action
export const updateFullItem = item => async dispatch => {
	let config = SalesCoworkerConfiguration.getUserConfig();
	let productService = new services.ProductService(config);
	const retailItem = await productService.getRetailItem(item);
	if (retailItem !== null) {
		if (retailItem.ItemType === SPR) {
			let childItems;
			if (retailItem.childItems === undefined) {
				childItems = await productService.getChildItems(retailItem);
			} else {
				childItems = retailItem.childItems;
			}
			dispatch({
				type: UPDATEFULLITEM,
				retailItem: retailItem,
				childItems: childItems
			});
			dispatch(recalculateDelivery());
		} else {
			dispatch({
				type: UPDATEFULLITEM,
				retailItem: retailItem
			});
			dispatch(recalculateDelivery());
		}
	}
};

export const updateCartItemStock = () => async (dispatch, getState) => {
	let { items } = getState().cartController;
	if (items.length === 0) return;
	let allitems = cartItemsBuilder.createItems(items, true);

	let mappedItems = allitems.map(itm => {
		return {
			ItemType: itm.itemType,
			ItemId: itm.itemNo
		};
	});

	try {
		let service = new services.ProductService(SalesCoworkerConfiguration.getUserConfig());
		let stockItems = await service.getUpdatedItemsStock(mappedItems, true, true);
		stockItems = stockItems && stockItems.map(x => x.storeInfo);

		dispatch({
			type: UPDATE_CART_ITEM_STOCK,
			payload: { stockItems: stockItems }
		});
	} catch (error) {
		//
	}
	return Promise.resolve();
};

export const openOrder = (orderNo, fetchStock = true, fetchItems = true, navigate = true, clearItems = true) => async dispatch => {
	try {
		dispatch(appactionCreators.showBusyLoading(true));
		let orderRetrieval = new OrderRetreivalService();
		let orderResponse = await orderRetrieval.fetchOrder(orderNo);
		if (clearItems) dispatch(clearCart());
		if (orderResponse) {
			dispatch(appactionCreators.changeUserStore(orderResponse.buCode, false)).then(() => {
				orderRetrieval = new OrderRetreivalService();
				dispatch(appactionCreators.showBusyLoading(true));
				orderRetrieval.setOrder(orderResponse, fetchStock, fetchItems).then(recentorder => {
					if (recentorder !== null) {
						if (recentorder.customer) {
							dispatch(setItemsInOrder(recentorder)).then(() => {
								dispatch(restoreCartCustomerFromOrder(recentorder)).then(() => {
									dispatch(updateCartTotal());
								});
							});
						} else {
							dispatch(setItemsInOrder(recentorder)).then(() => {
								dispatch(updateCartTotal());
							});
						}
						if (navigate) dispatch(push("/cart"));
						dispatch(appactionCreators.showBusyLoading(false));
					}
				});
			});
		} else {
			dispatch(appactionCreators.showBusyLoading(false));
		}
	} catch (error) {
		//
	}
};

export const setDraftOrderToActive = draftOrder => async dispatch => {
	try {
		dispatch(clearCart());

		if (draftOrder !== null) {
			dispatch({
				type: SET_ORDER_TO_ACTIVE,
				payload: {
					order: draftOrder
				}
			});
			dispatch(push("/cart"));
		}
	} catch (error) {
		//
	}

	dispatch(appactionCreators.showBusyLoading(false));
};

const clearAndReloadOrder = recentorder => dispatch => {
	dispatch(clearCart());

	if (recentorder !== null) {
		dispatch({
			type: SET_ORDER_TO_ACTIVE,
			payload: {
				order: recentorder
			}
		});
	}
	dispatch(updateCartItemStock());
	dispatch(updateCartTotal());
};

const sendItemToAnalytics = (cartItem, action) => {
	try {
		if (cartItem.isCombination) {
			cartItem.childItems.forEach(subItem => {
				sendToAnaytics(subItem, action);
			});
		} else {
			sendToAnaytics(cartItem, action);
		}
	} catch (error) {
		//
	}
};

const sendToAnaytics = (cartItem, action) => {
	try {
		if (action === "add") {
			GaHelper.AddProductToEcommerce(cartItem.retailItem);
			GaHelper.ExecuteEcommerceAction("add", { id: cartItem.itemId });
			GaHelper.SendEvent("Add Item to cart", "click", `add to cart`);
			AppLogger.info(`Add Item to Cart ${cartItem.itemId}`, "AddItemToCart", {
				ItemId: cartItem.itemId
			});
		} else {
			GaHelper.RemoveProduct(cartItem.retailItem);
			GaHelper.ExecuteEcommerceAction("remove", { id: cartItem.itemId });
			GaHelper.SendEvent("Remove Itemfrom cart", "click", `remove from cart`);
			AppLogger.info(`Remove Item from  Cart ${cartItem.itemId}`, "RemoveItemCart", {
				ItemId: cartItem.itemId
			});
		}
	} catch (error) {
		//
	}
};

export const updateGroup = (id, newChildItems) => (dispatch, getState) => {
	let { items } = getState().cartController;

	let group = items.find(x => x.id === id);

	let childItems = [...group.childItems];

	newChildItems.forEach(child => {
		let existing = childItems.find(x => x.itemId === child.retailItem.ItemId);
		if (!existing) {
			let newchildItem = factory.createShoppingCartItem(child.retailItem, child.itemQuantity);
			childItems.push(newchildItem);
		} else {
			existing.quantity = existing.quantity + child.itemQuantity;
		}
	});
	group.isExpanded = false;
	group.childItems = childItems;
	factory.updateGroupQuantity(group, group.quantity, true);
	factory.updateGroupImages(group);
	factory.updateGroupStock(group);
	dispatch({
		type: UPDATE_ITEM_GROUP,
		payload: {
			group: group
		}
	});

	dispatch(recalculateDelivery());
};

export const updateCartTotal = (overrideInclusive = false) => (dispatch, getState) => {
	let { items, discounts, hasServices, currency, order, bookedServiceProposal = {} } = getState().cartController;
	let zipCode, isCcdService, selectedPickservice;
	if (bookedServiceProposal) {
		zipCode = bookedServiceProposal.zipCode;
		isCcdService = bookedServiceProposal.isCcdService;
		selectedPickservice = bookedServiceProposal.selectedPickservice;
	}
	const { countryCode } = SalesCoworkerConfiguration.getUserConfig();
	let fetchInclusiveTax = countryCode === "US" || countryCode === "CA" ? false : true;
	if (overrideInclusive) {
		fetchInclusiveTax = true;
	}
	let { customer = {} } = getCustomerProfileObject();
	let services = [];
	if (hasServices) {
		let { bookedServices } = bookedServiceProposal;
		services = [...bookedServices];
		if (selectedPickservice) {
			if (services.find(x => x.serviceProductId === "PICKING_IN_STORE_CUST_ISL") === undefined) services.push(selectedPickservice);
		}
	}
	let currentOrder;
	if (order) {
		currentOrder = {
			...order,
			currency: currency
		};
	} else {
		currentOrder = {
			currency: currency
		};
	}
	return OrderAmountCalculatorService.getCartPrice(items, services, zipCode, isCcdService, customer, discounts, currentOrder, fetchInclusiveTax)
		.then(({ cartItems, salesPriceData }) => {
			dispatch({
				type: UPDATE_ALL_ITEMS,
				payload: {
					cartItems
				}
			});
			dispatch(setcartSummary(salesPriceData));
			return Promise.resolve({
				status: "success",
				code: 0,
				salesPriceData: salesPriceData
			});
		})
		.catch(() => {
			return Promise.resolve({ status: "fail", code: -1 });
		});
};

export const addKitchenPlannerToCart = (planner, qty) => dispatch => {
	planner.categories.forEach(x => {
		if (x.items && x.items.length > 0) {
			let category = {
				ItemType: GROUP,
				Name: x.name || "Other articles",
				itemDescription: `${x.items.length} items`,
				ItemId: x.name || "Other articles",
				price: x.price,
				familyPrice: x.familyPrice,
				availableStockToSell: x.availableStockToSell,
				isPlanner: false,
				imageList: x.imageList
			};

			let cartItem = factory.createCombinationCartItem(category, qty, x.items);

			dispatch({
				type: ADD_ITEM_TO_CART,
				payload: { cartItem: cartItem, quantity: qty }
			});
			sendItemToAnalytics(cartItem, "add");
		}
	});

	dispatch(
		sethasKitchenItemsInOrder({
			id: planner.serialNumber
		})
	);
	dispatch({
		type: SET_DRAWINGS,
		payload: {
			drawings: planner.drawingList
		}
	});
	AppLogger.info(`Add Kitchen Planner to Cart ${planner.serialNumber}`, "AddItemToCart", {
		PlannerId: planner.serialNumber
	});

	dispatch(recalculateDelivery());

	return Promise.resolve();
};

export const setLoading = isLoading => async dispatch => {
	dispatch({
		type: ADD_LOADING,
		payload: isLoading
	});
};

export const removeItems = ids => async (dispatch, getState) => {
	let state = getState();
	const { cartController } = state;

	let { items } = cartController;
	let orderItems = items.filter(x => !ids.includes(x.id.toString()));
	dispatch({
		type: UPDATE_ALL_ITEMS,
		payload: {
			cartItems: orderItems
		}
	});
};

export const combineItemsToGroup = (id, childItems, remvedItems) => (dispatch, getState) => {
	let { items } = getState().cartController;
	let groups = items.filter(x => x.isGroup);
	let newChildItems = [];
	let existingItem = items.find(x => x.id === id);

	let orderItems = items.filter(x => !remvedItems.includes(x.id.toString()));

	let index = items.indexOf(existingItem);

	childItems.forEach(child => {
		let existing = newChildItems.find(x => x.retailItem.ItemId === child.retailItem.ItemId);
		if (!existing) {
			newChildItems.push(child);
		} else {
			existing.itemQuantity = existing.itemQuantity + child.itemQuantity;
		}
	});

	let group = {
		ItemType: GROUP,
		Name: existingItem.isGroup ? existingItem.name : `Group${groups.length === 0 ? 1 : groups.length + 1}`,
		itemDescription: "",
		ItemId: ``,
		price: existingItem.price,
		familyPrice: existingItem.familyPrice,
		availableStockToSell: existingItem.availableStockToSell,
		isPlanner: false,
		imageList: []
	};

	let groupItem = factory.createCombinationCartItem(
		group,
		1, //existingItem.quantity,
		newChildItems
	);
	groupItem.isExpanded = false;
	factory.updateGroupQuantity(groupItem, groupItem.quantity, true);
	factory.updateGroupImages(groupItem);
	factory.updateGroupStock(groupItem);
	orderItems[index] = { ...groupItem };

	dispatch({
		type: UPDATE_ALL_ITEMS,
		payload: {
			cartItems: orderItems
		}
	});
	dispatch(recalculateDelivery());
};

export const deleteChildItemsInGroup = (groupId, childIds) => async (dispatch, getState) => {
	let { items } = getState().cartController;
	let existingItem = items.find(x => x.id.toString() === groupId.toString());
	let newChildItems = existingItem.childItems.filter(x => !childIds.includes(x.id.toString()));
	let updatedItem = Object.assign({}, existingItem);
	updatedItem.childItems = newChildItems;
	factory.updateGroupQuantity(updatedItem, updatedItem.quantity, true);
	factory.updateGroupStock(updatedItem);
	factory.updateGroupImages(updatedItem);
	dispatch({
		type: UPDATE_ITEMS,
		payload: {
			retailItem: updatedItem
		}
	});

	dispatch(recalculateDelivery());
};

export const setcartSummary = salesPriceData => dispatch => {
	dispatch({
		type: UPDATE_CART_PRICE_DATA,
		payload: {
			salesPriceData: salesPriceData
		}
	});
};

export const openQuotationToOrder = quotNo => async dispatch => {
	try {
		dispatch(appactionCreators.showBusyLoading(true));

		let orderRetrieval = new OrderRetreivalService();
		let recentorder = await orderRetrieval.getQuotation(quotNo);

		dispatch(clearCart());

		if (recentorder !== null) {
			//TODO: must call orderservice mfe
			// dispatch(setOrderData(recentorder));
			dispatch({
				type: SET_ORDER_TO_ACTIVE,
				payload: {
					order: recentorder
				}
			});
			dispatch(push("/cart"));
		}
	} catch (error) {
		//
	}

	dispatch(appactionCreators.showBusyLoading(false));
};

export const updateQuotation = quotation => {
	return {
		type: UPDATE_QUOTATION,
		payload: quotation
	};
};

export const restoreCartCustomerFromOrder = order => dispatch => {
	if (order && order.customer) {
		let orderservices = getMicroFrontendInstance("orderservices");
		if (orderservices) {
			return orderservices.setServicesFromOrder(order).then(() => {
				let bookedServices = orderservices.getBookedOrderServices();
				if (bookedServices) {
					dispatch(setServiceProposal(bookedServices));
				}
				return Promise.resolve(true);
			});
		}
	}
	return Promise.resolve(true);
};

/**
 *imports webshoppinglistitems to cart
 *
 * @param {Array} shoppingListItems -list of items
 */
export const importCustomerShoppingList = shoppingListItems => (dispatch, getState) => {
	try {
		let state = getState();
		let { items } = state.cartController;
		let allItems = [...items];
		shoppingListItems.forEach(itm => {
			let existingItem = allItems.find(x => x.itemId === itm.retailItem.ItemId);
			if (existingItem) {
				let quantity = parseInt(existingItem.quantity) + parseInt(itm.quantity);
				factory.updateQuantity(existingItem, quantity);

				existingItem.totalQuantity = quantity;
				existingItem.kioskItemUserId = itm.kioskItemUserId;
			} else {
				let cartItem = factory.createShoppingCartItem(itm.retailItem, parseInt(itm.quantity));

				cartItem.kioskItemUserId = itm.kioskItemUserId;
				allItems.push(cartItem);
			}
		});
		dispatch({
			type: UPDATE_ALL_ITEMS,
			payload: {
				cartItems: allItems
			}
		});
		dispatch(recalculateDelivery());
		return Promise.resolve("OK");
	} catch (error) {
		return Promise.reject(error);
	}
};

/**
 *imports webshoppinglistitems to cart
 *
 * @param {Array} shoppingListItems -list of items
 */
export const importReceiptList = receiptItems => (dispatch, getState) => {
	try {
		let state = getState();
		let { coworkerWorkArea } = SalesCoworkerConfiguration.getUserConfig();
		let isEmpuWorker = coworkerWorkArea.includes("EMPU");
		let isCashLine = coworkerWorkArea.includes("CASHLINE");
		let { items, isModifyOrderinProgress } = state.cartController;
		let allItems = [...items];
		receiptItems.forEach(itm => {
			let cartItem = factory.createShoppingCartItem(itm.retailItem, parseInt(0));
			let handInLocation = itm.handOverLocation;
			if (isEmpuWorker) {
				handInLocation = `EMPU_COLLECT|${itm.handOverLocation.split("|")[1]}`;
			}
			cartItem.isCashLine = isCashLine;
			cartItem.handOverLocation = handInLocation;
			cartItem.referenceOrderNo = itm.referenceOrderNo;
			cartItem.handedOverToTdDesk = itm.handedOverToTdDesk;
			cartItem.retailItem.availableStockToSell = itm.importDetails.originalQuantity;
			cartItem.availableStockToSell = itm.importDetails.originalQuantity;
			cartItem.quantity = 0;
			cartItem.isTransportDeskItem = true;
			cartItem.importDetails = itm.importDetails;
			cartItem.receiptPriceDetails = itm.receiptPriceDetails;
			cartItem.allowedToDeliveryFromTransportDesk = true;

			if (Specifications.isIkeaFoodHfbItemSpec(cartItem.retailItem)) {
				cartItem.allowedToDeliveryFromTransportDesk = false;
			}

			allItems.push(cartItem);
		});

		dispatch({
			type: UPDATE_ALL_ITEMS,
			payload: {
				cartItems: allItems
			}
		});
		if (!isModifyOrderinProgress) {
			dispatch(recalculateDelivery());
		}

		return Promise.resolve("OK");
	} catch (error) {
		return Promise.reject(error);
	}
};

export const setCartDiscounts = discounts => {
	return {
		type: SET_CART_DISCOUNTS,
		payload: discounts
	};
};

const recalculateDelivery = () => {
	return (dispatch, getState) => {
		const { items } = getState().cartController;

		let orderservices = getMicroFrontendInstance("orderservices");
		if (orderservices) {
			orderservices
				.recalculateDelivery(items)
				.then(() => {
					dispatch(appactionCreators.showBusyLoading(false));
					dispatch(updateCartTotal());
				})
				.catch(() => {
					dispatch(appactionCreators.showBusyLoading(false));
				});
		} else {
			dispatch(appactionCreators.showBusyLoading(false));
		}
		return Promise.resolve();
	};
};

const recalculateDeliveryOnItemRemove = item => {
	return (dispatch, getState) => {
		const { items } = getState().cartController;

		let orderservices = getMicroFrontendInstance("orderservices");
		if (orderservices) {
			orderservices.removeItemOnQuantityChange(items, item).then(() => {
				dispatch(appactionCreators.showBusyLoading(false));
				dispatch(updateCartTotal());
			});
		}
		return Promise.resolve();
	};
};

const clearCartDeliveryServices = () => {
	// eslint-disable-next-line no-unused-vars
	return dispatch => {
		let orderservices = getMicroFrontendInstance("orderservices");
		if (orderservices) {
			orderservices.clearServices();
		}
		return Promise.resolve();
	};
};

const setItemsInOrder = order => dispatch => {
	dispatch({
		type: SET_ORDER_TO_ACTIVE,
		payload: {
			order: order
		}
	});
	return Promise.resolve(true);
};

export const reloadCurrentOrder = () => async (dispatch, getState) => {
	try {
		let state = getState();
		let { order } = state.cartController;
		dispatch(openOrder(order.orderNo, true, true, false, false));
	} catch (error) {
		//
	}
};

export const scanItem = item => {
	// eslint-disable-next-line no-unused-vars
	return (dispatch, getState) => {
		const state = getState();

		const { items, isTransportDeskMode } = state.cartController;
		const { messages } = state.intl;
		const { isCashLine } = SalesCoworkerConfiguration.getUserConfig();
		function checkItemExistinRecipts(item) {
			let itemExit = items.find(x => x.itemId === item);
			return itemExit !== undefined;
		}

		function onChangeQuantity(item) {
			let existingItem = items.find(x => x.itemId === item);
			let cartItem = items.find(x => x.itemId === item && x.quantity !== x.importDetails.originalQuantity);
			if (cartItem && cartItem.importDetails) {
				let maxQuantity = parseInt(cartItem.importDetails.originalQuantity);
				let quantity = parseInt(cartItem.quantity + 1);
				if (quantity > maxQuantity) {
					quantity = maxQuantity;
				}
				dispatch(changeQuantity(cartItem.id, quantity));
			}
			if (existingItem && !cartItem) {
				dispatch(
					appactionCreators.showNotification(
						"error",
						messages.SG_LBL_MaxQuantityInReceipt || "Attention ! The item scanned total quantity is higher than in receipt"
					)
				);
			}
		}
		const retailItem = {
			ItemId: item,
			ItemType: "ART"
		};
		if (isCashLine) {
			dispatch(addItemToCart(retailItem, 1));
		} else if (isTransportDeskMode) {
			if (checkItemExistinRecipts(item)) {
				onChangeQuantity(item);
			}
		} else {
			dispatch(addItemToCart(retailItem, 1));
		}
		return Promise.resolve();
	};
};
