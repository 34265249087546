import React from "react";
import styled from "styled-components";

const Container = styled.div`
	height: 40px;
	overflow: hidden;
	margin: 0 -15px;

	.inner {
		display: flex;
		overflow-x: scroll;
		overflow-y: hidden;
		padding: 0 0 20px 15px;
	}
`;

const ScrollableContainer = props => {
	return (
		<Container>
			<div className="inner">{props.children}</div>
		</Container>
	);
};

export default ScrollableContainer;
