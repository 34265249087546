import moment from "moment";
import "moment/locale/it";
import "moment/locale/nl-be";
import "moment/locale/be";
import "moment/locale/nl";
import "moment/locale/de";
import "moment/locale/ja";
import "moment/locale/ru";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/fi";
import "moment/locale/se";
import "moment/locale/da";
import "moment/locale/zh-cn";
import "moment/locale/de-at";
import "moment/locale/de-ch";
import "moment/locale/it-ch";
import "moment/locale/sr";
import "moment/locale/pl";
import "moment/locale/pt";
import "moment/locale/sv";
import "moment/locale/sk";
import "moment/locale/hu";
import "moment/locale/hr";
import "moment/locale/fr-ca";
import "moment/locale/es-us";
import "moment/locale/en-ca";
import "moment/locale/en-au";
import "moment/locale/en-gb";
import "moment/locale/en-ie";
import getMessage from "@coworker/sharedlibrary/src/helpers/translationHelper";
const DateTimeHelper = {
	getAllDatesInWeek: dateTime => {
		var startOfweekDay = startOfWeek(dateTime);
		var endOfWeekDay = endOfWeek(startOfweekDay);

		return getDateArray(startOfweekDay, endOfWeekDay);
	},
	getNextWeekday: dateTime => {
		var endOfWeekDay = endOfWeek(dateTime);
		return endOfWeekDay.setDate(endOfWeekDay.getDay() + 1);
	},
	toDateInt: date => {
		var dt = date; //new Date(parseInt(date));
		var dateString = moment(dt).format("YYYYMMDD"); //`${dt.getFullYear()}${dt.getMonth()}${dt.getDate()}`;

		return parseInt(dateString);
	},
	toDateFromInt: date => {
		return moment(date, "YYYYMMDD").toDate();
	},
	getEndOfWeek: date => {
		return endOfWeek(date);
	},
	getStartOfWeek: (date, startFromCurrentDate = false) => {
		const startDate = startOfWeek(date);
		if (startFromCurrentDate && startDate < Date.now()) {
			var clnDate = new Date();
			clnDate.setHours(0, 0, 0, 0);
			return clnDate;
		}
		return startOfWeek(date);
	},
	getCurrentDateOfWeek: date => {
		let clnDate = Date(date);
		if (clnDate < Date.now()) {
			clnDate = new Date();
			clnDate.setHours(0, 0, 0, 0);
			return clnDate;
		}

		return clnDate;
	},

	getAllDaysInNWeeks(startDate, noofWeeks = 1) {
		var startOfweekDay = startOfWeek(startDate);
		var dt = new Date(startDate);
		if (noofWeeks < 0) {
			let num = noofWeeks * 7;
			dt.setDate(dt.getDate() + num);
			let enddate = new Date(startDate);
			enddate.setDate(enddate.getDate() - 1);
			return getDateArray(dt, enddate);
		} else {
			dt.setDate(dt.getDate() + (noofWeeks - 1) * 7);
		}

		var endOfWeekDay = endOfWeek(dt);
		return getDateArray(startOfweekDay, endOfWeekDay);
	},
	getTodaysDate() {
		return moment().format("YYYY-MM-DD");
	},
	/**
	 * formats a order Date
	 *
	 * @param {string} [string] orderCreationDate = '2020-03-11T23:24:41Z'
	 * @param {object} [object] translation messages
	 *
	 * @return {string} [string] Returns formated date.
	 */
	getOrderFormatedDate: (orderCreationDate, messages, countryCode) => {
		let today = moment().startOf("day").format("YYYY-MM-DD");
		let yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
		let orderDate = moment(orderCreationDate);
		if (orderDate.format("YYYY-MM-DD") === today) {
			return getMessage("SG_LBL_Today", messages, "Today") + " " + orderDate.format("HH:mm");
		}
		if (orderDate.format("YYYY-MM-DD") === yesterday) {
			return getMessage("SG_LBL_Yesterday", messages, "Yesterday") + " " + orderDate.format("HH:mm");
		}
		return moment(orderDate).format(`${DateTimeHelper.getDateFormatByMarket(countryCode)} , HH:mm`);
	},

	getDateFormatByMarket: countryCode => {
		switch (countryCode) {
			case "JP":
				return "MM/DD/YYYY";
			default:
				return "DD-MM-YYYY";
		}
	},
	getMomentLocale: locale => {
		let locales = ["es-us", "en-ca", "en-au", "en-gb", "en-ie", "nl-be", "zh-cn", "de-at", "de-ch", "it-ch", "fr-ca", "es-us"];
		if (locales.includes(locale.toLowerCase())) {
			return locale.toLowerCase();
		}
		var localeCode = (locale && locale.split(/[-_]/)[0]) || "en";
		return localeCode.toLowerCase();
	},
	formatDateWithMoment: (date, format) => {
		return moment(date).format(format);
	}
};

const startOfWeek = date => {
	var clnDate = new Date(date);
	clnDate.setHours(0, 0, 0, 0);
	var diff = clnDate.getDate() - clnDate.getDay() + (clnDate.getDay() === 0 ? -6 : 1);

	return new Date(clnDate.setDate(diff));
};

const endOfWeek = date => {
	var clnDate = new Date(date);
	clnDate.setHours(0, 0, 0, 0);
	var lastday = clnDate.getDate() - (clnDate.getDay() - 1) + 6;
	return new Date(clnDate.setDate(lastday));
};

const getDateArray = (start, end) => {
	var arr = [];
	var dt = new Date(start);
	while (dt <= end) {
		arr.push(new Date(dt));
		dt.setDate(dt.getDate() + 1);
	}
	return arr;
};

export default DateTimeHelper;
