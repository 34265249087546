let globalSharedStore;
const sharedService = {
	initStore: storeData => {
		globalSharedStore = storeData;
		return Promise.resolve(true);
	},
	isInitialized: () => {
		return globalSharedStore !== undefined;
	},
	updateToken: token => {
		globalSharedStore = { ...globalSharedStore, token };
	},
	getUserConfig: () => {
		return getConfig();
	},
	getSessionId: () => {
		return getConfig().sessionId;
	},
	getPreference: key => {
		const { preferences } = getConfig();
		if (preferences !== undefined) {
			return preferences.find(x => x !== undefined && x.name === key);
		}
		return null;
	},
	getPreferenceValueBoolean: key => {
		const { preferences } = getConfig();
		let preference = preferences && preferences.find(x => x && x.name === key);
		return (preference && preference.value === "true") || false;
	},
	getPreferenceValueList: key => {
		const { preferences } = getConfig();
		let values = [];
		let preference = preferences && preferences.find(x => x && x.name === key);
		if (preference && preference.value) {
			values = values.concat(preference.value);
		}
		return values;
	},
	getPrivilege: key => {
		const { coworkerData } = getConfig();

		return coworkerData && coworkerData.privileges && coworkerData.privileges.find(priv => priv.code === key);
	},
	getbaseUrl: () => {
		var { countryCode, environment } = getConfig();
		return countryCode === "CN" ? environment.mfscnurl : environment.mfsurl;
	},
	getBaseMfsUrl: () => {
		var { countryCode, environment } = getConfig();
		return countryCode === "CN" ? environment.mfscnurl : environment.mfsurl;
	},
	getDfpAllowedServicesInStore: () => {
		let allowedList = [];
		allowedList.push("*");
		return allowedList;
	},

	getDfpAllowedDeliveriesInStore: () => {
		let allowedList = [];
		try {
			let del = sharedService.getPreferenceValueList("ikea.salja.settings.DELIVERYMETHODWHITELIST");
			if (del && del.length > 0) {
				allowedList = allowedList.concat(del);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);
			//
		}
		return allowedList;
	},
	getCoworkerWorkingArea: () => {
		let { selectedHfb } = getConfig();
		if (selectedHfb.includes("hfb_18") || selectedHfb.includes("transportdesk_empu") || selectedHfb.includes("transportdesk_cashline")) {
			return "TRANSPORT_DESK";
		} else {
			return "SALES_DESK";
		}
	},
	getHfb: (id = false) => {
		let { environment } = getConfig();
		if (id) {
			return environment.hfbList && environment.hfbList.filter(hfb => hfb.id === id);
		}

		return environment.hfbList || hfbList;
	}
};

const getConfig = () => {
	let {
		formattedLocales,
		appVersion,
		environment,
		token,
		locale,
		userId,
		buCode,
		countryCode,
		preferences,
		storeInfo,
		sessionId,
		guestMode,
		isStandalone,
		storeCurrency,
		isExternal,
		selectedHfb,
		coworkerData,
		backEndLocale,
		userMode,
		messages,
		cartItems,
		canAddItem,
		canImportReceipt,
		history,
		customerMeetingPoint,
		orderCreationMethod,
		customerSchema,
		coworkerWorkArea,
		isCashLine,
		isTransportDeskMode,
		isMetricUsingStore
	} = globalSharedStore;

	let timeZone = (storeInfo && storeInfo.timeZone) || "Europe/Stockholm";

	let option = {
		headers: {
			timeZone: timeZone,
			Authorization: `Bearer ${token}`,
			"Accept-Language": locale || "en-US",
			"x-request-locale": locale || "en-US",
			"x-session-id": sessionId,
			"x-bucode": buCode,
			"x-countrycode": countryCode,
			"x-trace-id": `${buCode}-${userId}-${sessionId}`
		}
	};
	if (isExternal) {
		option.headers["external-client"] = true;
	}

	return {
		formattedLocales,
		isMetricUsingStore,
		storeCurrency,
		environment,
		token,
		locale,
		userId,
		buCode,
		countryCode,
		preferences,
		storeInfo,
		sessionId,
		isStandalone,
		guestMode,
		languageCode: (locale && locale.split(/[-_]/)[0]) || "en",
		isExternal,
		selectedHfb,
		coworkerData,
		backEndLocale,
		userMode,
		messages,
		cartItems,
		canAddItem,
		canImportReceipt,
		history,
		isSalesMode: !isExternal,
		customerMeetingPoint,
		orderCreationMethod,
		appVersion,
		headers: option.headers,
		customerSchema,
		coworkerWorkArea: coworkerWorkArea,
		isCashLine,
		isTransportDeskMode
	};
};

const getSessionId = () => {
	const { sessionId } = globalSharedStore;
	return sessionId;
};

const getCurrentLocale = () => {
	const { locale = "en-US" } = globalSharedStore;
	return locale;
};

const getCurrentBuCode = () => {
	const { buCode = "" } = globalSharedStore;
	return buCode;
};

const getCurrentUser = () => {
	const { userId = "" } = globalSharedStore;
	return userId;
};

const getReqHeaderOption = () => {
	var { storeInfo, token, locale, isExternal, sessionId, buCode, countryCode, userId } = getConfig();
	var timeZone = (storeInfo && storeInfo.timeZone) || "Europe/Stockholm";
	if (!token) {
		token = localStorage.getItem(tokenKey);
	}
	var option = {
		headers: {
			timeZone: timeZone,
			Authorization: `Bearer ${token}`,
			"Accept-Language": locale || "en-US",
			"x-request-locale": locale || "en-US",
			"x-session-id": sessionId,
			"x-bucode": buCode,
			"x-countrycode": countryCode,
			"x-trace-id": `${buCode}-${userId}-${sessionId}`
		}
	};
	if (isExternal) {
		option.headers["external-client"] = true;
		if (userId) {
			option.headers["x-user-id"] = userId;
		}
	}

	return option;
};

const getTokenHeader = () => {
	var { token, isStandalone, isExternal } = getConfig();

	if (!token) {
		token = localStorage.getItem(tokenKey);
	}
	var option = {
		Authorization: `Bearer ${token}`
	};
	if (isStandalone || isExternal) {
		option.headers["external-client"] = true;
	}

	return option;
};

const getApiBaseUrl = (api = "server") => {
	const appName = `REACT_APP_${api.toUpperCase()}_API_HOST`;
	const overrideUrl =
		localStorage.getItem(appName) ||
		localStorage.getItem(`import-map-override:${api.toUpperCase()}_API`) ||
		localStorage.getItem(`${api.toUpperCase()}_API`);
	switch (api) {
		case "cartservices":
			return (
				overrideUrl ||
				// eslint-disable-next-line no-undef
				process.env.REACT_APP_CARTSERVICES_API_HOST ||
				window.location.origin
			);
		case "catalog":
			return (
				overrideUrl || // eslint-disable-next-line no-undef
				process.env.REACT_APP_CATALOG_API_HOST ||
				window.location.origin
			);
		case "configuration":
			// eslint-disable-next-line no-undef
			return (
				overrideUrl || // eslint-disable-next-line no-undef
				process.env.REACT_APP_CONFIGURATION_API_HOST ||
				window.location.origin
			);
		case "planner":
			// eslint-disable-next-line no-undef
			return (
				overrideUrl || // eslint-disable-next-line no-undef
				process.env.REACT_APP_PLANNER_API_HOST ||
				window.location.origin
			);
		case "range":
			// eslint-disable-next-line no-undef
			return (
				overrideUrl || // eslint-disable-next-line no-undef
				process.env.REACT_APP_RANGE_API_HOST ||
				window.location.origin
			);
		case "user":
			// eslint-disable-next-line no-undef
			return (
				overrideUrl || // eslint-disable-next-line no-undef
				process.env.REACT_APP_USER_API_HOST ||
				window.location.origin
			);
		case "customer":
			// eslint-disable-next-line no-undef
			return (
				overrideUrl || // eslint-disable-next-line no-undef
				process.env.REACT_APP_CUSTOMER_API_HOST ||
				window.location.origin
			);
		case "bcmcustomer":
			// eslint-disable-next-line no-undef
			return (
				overrideUrl || // eslint-disable-next-line no-undef
				process.env.REACT_APP_BCMCUSTOMER_API_HOST ||
				window.location.origin
			);
		case "server":
		default:
			// eslint-disable-next-line no-undef
			return (
				overrideUrl || // eslint-disable-next-line no-undef
				process.env.REACT_APP_API_HOST ||
				window.location.origin
			);
	}
};

const checkIsomEnabled = key => {
	var { userId, environment, preferences } = SharedConfiguration.getUserConfig();
	if (preferences) {
		var pref = preferences.find(x => x !== undefined && x.name === key);

		var users = environment.devList;
		if (pref && pref.value) {
			users = users.concat(pref.value);
		}
		var allUsers = users.map(x => x.toLowerCase());
		return allUsers.includes("*") || allUsers.includes(userId.toLowerCase());
	}
	return false;
};
const isAllowedFeature = feature => {
	const { environment } = SharedConfiguration.getUserConfig();
	const features = environment.allowedFeatures;
	if (features.find(x => x.toLowerCase() === feature.toLowerCase())) {
		return true;
	} else {
		return false;
	}
};
const isGuestMode = () => {
	let { guestMode } = SharedConfiguration.getUserConfig();
	return guestMode;
};
const isSalesMode = () => {
	let { guestMode } = SharedConfiguration.getUserConfig();
	return !guestMode;
};

const isAllowedExperimentalFeature = (featureId, countryCode) => {
	const { environment } = SharedConfiguration.getUserConfig();
	const experimentalFeatures = environment && environment.experimentalFeatures;
	if (experimentalFeatures && experimentalFeatures.length > 0) {
		const feature = experimentalFeatures.find(x => x.id === featureId && x.countries.includes(countryCode));

		return feature !== undefined;
	}
	return false;
};

const isRestrictedInMarket = () => {
	const { environment, countryCode } = SharedConfiguration.getUserConfig();
	const { disabledMarkets = [] } = environment;
	return disabledMarkets.includes(countryCode);
};

const SharedConfiguration = {
	...sharedService,
	getSessionId,
	getCurrentLocale,
	getCurrentUser,
	getCurrentBuCode,
	getReqHeaderOption,
	getApiBaseUrl,
	getTokenHeader,
	isAllowedFeature,
	checkIsomEnabled,
	isGuestMode,
	isSalesMode,
	isAllowedExperimentalFeature,
	isRestrictedInMarket
};

export default SharedConfiguration;

const tokenKey = window.location.origin + "newapptoken";
const hfbList = [
	{
		id: "hfb_01",
		name: "HFB 01",
		descriptiveName: "Living room seating",
		availablePlanners: ["gronlid_planner", "lidhult_planner", "soderhamn_planner", "vallentuna_planner", "vimle_planner", "norsborg_planner"]
	},
	{
		id: "hfb_02",
		name: "HFB 02",
		descriptiveName: "Store and organise furniture",
		availablePlanners: ["build_your-own-office-chair-planner", "eket_planner", "stuva_planner", "besta_planner", "kallax_planner"]
	},
	{
		id: "hfb_03",
		name: "HFB 03",
		descriptiveName: "Workspaces",
		availablePlanners: ["build_your-own-office-chair-planner", "byod_planner"]
	},
	{
		id: "hfb_04",
		name: "HFB 04",
		descriptiveName: "Bedroom furniture",
		availablePlanners: ["algot_planner", "elvarli_planner", "nordli_planner", "platsa_planner", "stuva_planner", "pax_planner"]
	},
	{
		id: "hfb_05",
		name: "HFB 05",
		descriptiveName: "Beds & Mattresses",
		availablePlanners: ["completesleep_planner"]
	},
	{
		id: "hfb_06",
		name: "HFB 06",
		descriptiveName: "Bathroom",
		availablePlanners: ["bathroom_planner"]
	},
	{
		id: "hfb_07",
		name: "HFB 07",
		descriptiveName: "Kitchen",
		availablePlanners: ["configurable_room-kitchen", "custom_worktop-calculator"]
	},
	{
		id: "hfb_08",
		name: "HFB 08",
		descriptiveName: "Dining",
		availablePlanners: ["build_your-own-dining-chair-planner"]
	},
	{
		id: "hfb_09",
		name: "HFB 09",
		descriptiveName: "Children´s IKEA",
		availablePlanners: ["stuva_planner", "smastad_planner"]
	},
	{
		id: "hfb_12",
		name: "HFB 12",
		descriptiveName: "Home textiles",
		availablePlanners: ["vidga_planner"]
	},
	{
		id: "hfb_17",
		name: "HFB 17",
		descriptiveName: "Outdoor",
		availablePlanners: ["bror_planner"]
	},

	{
		id: "hfb_18",
		name: "",
		descriptiveName: "Transport Desk (Store)",
		availablePlanners: [
			"build_your_own_dining_chair_planner",
			"build_your_own_office_chair_planner",
			"byod_planner",
			"algot_planner",
			"besta_planner",
			"bror_planner",
			"bathroom_planner",
			"eket_planner",
			"elvarli_planner",
			"gronlid_planner",
			"kungshman_planner",
			"lidhult_planner",
			"nordli_planner",
			"pax_planner",
			"platsa_planner",
			"soderhamn_planner",
			"vallentuna_planner",
			"vimle_planner",
			"configurable_room_kitchen",
			"norsborg_planner",
			"stuva_planner",
			"custom_worktop_calculator",
			"henriksdal_planner",
			"complete_sleep_bed_configurator",
			"complete_sleep_comfort_guide",
			"kallax_planner",
			"vidga_planner",
			"jonaxel_planner",
			"smastad_planner",
			"boaxel_planner",
			"completesleep_planner"
		]
	},
	{
		id: "hfb_19",
		name: "",
		descriptiveName: "Customer Service",
		availablePlanners: [
			"build_your_own_dining_chair_planner",
			"build_your_own_office_chair_planner",
			"byod_planner",
			"algot_planner",
			"besta_planner",
			"bror_planner",
			"bathroom_planner",
			"eket_planner",
			"elvarli_planner",
			"gronlid_planner",
			"kungshamn_planner",
			"lidhult_planner",
			"nordli_planner",
			"pax_planner",
			"platsa_planner",
			"soderhamn_planner",
			"vallentuna_planner",
			"vimle_planner",
			"configurable_room_kitchen",
			"norsborg_planner",
			"stuva_planner",
			"custom_worktop_calculator",
			"henriksdal_planner",
			"complete_sleep_bed_configurator",
			"complete_sleep_comfort_guide",
			"kallax_planner",
			"vidga_planner",
			"jonaxel_planner",
			"smastad_planner",
			"boaxel_planner",
			"completesleep_planner"
		]
	},
	{
		id: "transportdesk_empu",
		name: "",
		descriptiveName: "TransportDesk (EMPU)",
		availablePlanners: [
			"build_your_own_dining_chair_planner",
			"build_your_own_office_chair_planner",
			"byod_planner",
			"algot_planner",
			"besta_planner",
			"bror_planner",
			"bathroom_planner",
			"eket_planner",
			"elvarli_planner",
			"gronlid_planner",
			"kungshamn_planner",
			"lidhult_planner",
			"nordli_planner",
			"pax_planner",
			"platsa_planner",
			"soderhamn_planner",
			"vallentuna_planner",
			"vimle_planner",
			"configurable_room_kitchen",
			"norsborg_planner",
			"stuva_planner",
			"custom_worktop_calculator",
			"henriksdal_planner",
			"complete_sleep_bed_configurator",
			"complete_sleep_comfort_guide",
			"kallax_planner",
			"vidga_planner",
			"jonaxel_planner",
			"smastad_planner",
			"boaxel_planner",
			"completesleep_planner"
		]
	}
];
