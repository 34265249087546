import bigDecimal from "js-big-decimal";
const calcPrice = (grossAmount, netamount, taxAmount, qty, reqQty = 1) => {
	let unitExlc = new bigDecimal(bigDecimal.multiply(parseFloat(bigDecimal.divide(netamount, qty)), reqQty))
		.round(2, bigDecimal.RoundingModes.HALF_UP)
		.getValue();

	let unittax = new bigDecimal(bigDecimal.multiply(parseFloat(bigDecimal.divide(taxAmount, qty)), reqQty))
		.round(2, bigDecimal.RoundingModes.HALF_DOWN)
		.getValue();

	let untp = parseFloat(unitExlc) + parseFloat(unittax);

	let actualUprice = (grossAmount / qty) * reqQty;
	if (untp !== actualUprice) {
		unitExlc = actualUprice - parseFloat(unittax);
		untp = actualUprice;
	}
	return {
		priceInclTax: parseFloat(untp),
		priceExclTax: parseFloat(unitExlc),
		indicativeTax: parseFloat(unittax)
	};
};

const calcTotalPrice = (unitGrossAmount, unitNetamount, unitTaxAmount, reqQty) => {
	let unitExlc = parseFloat(bigDecimal.multiply(unitNetamount, reqQty));

	let unittax = parseFloat(bigDecimal.multiply(parseFloat(unitTaxAmount), reqQty));
	let untp = parseFloat(bigDecimal.multiply(unitGrossAmount, reqQty));

	return {
		priceInclTax: untp,
		priceExclTax: unitExlc,
		indicativeTax: unittax
	};
};
export { calcPrice, calcTotalPrice };
