import { useState, useEffect } from "react";
import SalesCoworkerConfiguration from "../store/salesCoworkerConfiguration";
function useAllowedFeature(feature) {
	const [featureAllowed, setFeatureAllowed] = useState(true);
	useEffect(() => {
		var isAllowed = SalesCoworkerConfiguration.isAllowedFeature(feature);
		setFeatureAllowed(isAllowed);
	}, [feature]);
	return featureAllowed;
}
export { useAllowedFeature };
