import React from "react";
import { Container } from "./../../../../../components/sharedlibrary";
import { SummaryPriceControl } from "./../../../../../components/price/summaryPriceControl";

const OrderPriceSummaryTransportLayout = ({ subtotalPrivatePrice, savings, currency, messages, TotalPriceStyle, servicePrice }) => {
	return (
		<Container fdCol>
			{subtotalPrivatePrice && subtotalPrivatePrice.delivery && (
				<SummaryPriceControl price={subtotalPrivatePrice.delivery} title={messages.SG_LBL_Delivery || "Delivery"} />
			)}
			{subtotalPrivatePrice && subtotalPrivatePrice.service && (
				<SummaryPriceControl price={subtotalPrivatePrice.service} title={messages.SG_LBL_Service || "Service"} />
			)}
			{savings && savings.discounts && (
				<SummaryPriceControl
					price={{
						Value: -1 * savings.discounts,
						Currency: currency
					}}
					prefix={"-"}
					title={messages.SG_LBL_DiscountsAndPromotions || "Discounts & promotions"}
					minimumFractionDigits={2}
				/>
			)}
			{servicePrice && servicePrice.totalServicePrice && (
				<Container
					styles={{
						margin: "10px 0px",
						alignItems: "center",
						height: "100px",
						width: "100%",
						custom: "border-top: 1px solid #111111; border-bottom: 1px solid #111111;"
					}}
				>
					<SummaryPriceControl
						testid="totalpricelbl"
						container={TotalPriceStyle}
						price={servicePrice.totalServicePrice}
						bold={true}
						title={messages.SG_LBL_Total || "Total:"}
					/>
				</Container>
			)}
		</Container>
	);
};

export { OrderPriceSummaryTransportLayout };
