import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { UserType } from "../store/auth/reducer";
function useSalesPerson() {
	const [salesMode, setSalesMode] = useState(false);
	var { userMode } = useSelector(state => state.authController);

	useEffect(() => {
		setSalesMode(userMode === UserType.SALESPERSON);
	}, [userMode, salesMode]);

	return [salesMode];
}
export { useSalesPerson };
