import React, { createContext, useState } from "react";

import AppLogger from "@coworker/sharedlibrary/src/logger/appLogger";
import TranslationService from "@coworker/sharedlibrary/src/service/translationService";
import httpService from "@coworker/sharedlibrary/src/service/httpService";
import APIEndpoints from "@coworker/sharedlibrary/src/constants/apiEndpoints";
import { updateIntl } from "react-intl-redux";

// src/context/main.js

const UserContext = createContext();
const UserContextProvider = ({ children, defaultContext, store, sessionCallback }) => {
	let [userContext, setUserContext] = useState(undefined);
	React.useEffect(() => {
		getUserContext(
			// eslint-disable-next-line no-undef
			(defaultContext && defaultContext.token) || process.env.defaultContext
		)
			.then(user => {
				if (user) {
					var userData = Object.assign({}, { ...defaultContext }, { ...user });

					TranslationService.getTranslations(userData.locale).then(ii8Config => {
						store.dispatch(updateIntl(ii8Config));
					});
					sessionCallback && sessionCallback(userData);
					setUserContext(userData);
				}
			})
			.catch(() => {
				setUserContext(undefined);
			});
	}, []);
	return (
		<UserContext.Provider value={{ userContext }}>{userContext === undefined ? <div>{"loading user"}</div> : <div>{children}</div>}</UserContext.Provider>
	);
};

export { UserContext, UserContextProvider };

const getUserContext = async token => {
	return httpService
		.get(APIEndpoints.userSessionContext, {
			headers: {
				Authorization: `Bearer ${token}`,
				"external-client": "true"
			}
		})
		.then(response => {
			if (response.success) {
				return getAppConfig(token).then(environment => {
					if (environment) {
						return Promise.resolve({
							...response,
							environment,
							coworkerData: response
						});
					}
					return Promise.reject(undefined);
				});
			}
			return Promise.reject(undefined);
		})
		.catch(error => {
			AppLogger.error(error.message, "GetUserContext");
			return Promise.reject(undefined);
		});
};

const getAppConfig = async token => {
	return httpService
		.get(APIEndpoints.envConfig, {
			headers: {
				Authorization: `Bearer ${token}`,
				"external-client": "true"
			}
		})
		.then(response => {
			return Promise.resolve(response);
		})
		.catch(error => {
			AppLogger.error(error.message, "GetUserContext");
			return Promise.reject(undefined);
		});
};
