import { appactionCreators } from "./shellAction";
import { resetCart } from "./../cart/cartActions";
import { FeaturesList } from "@coworker/sharedlibrary";
export const removeVisualSearchFeature = () => {
	return (dispatch, getState) => {
		var state = getState();
		var { environment } = state.appController;
		var { allowedFeatures = [] } = environment;
		var newFeatures = allowedFeatures.filter(x => x !== FeaturesList.VISUALSEARCH);
		dispatch(appactionCreators.setFeatures(newFeatures));
		return Promise.resolve(newFeatures);
	};
};

export const addQuotationFeature = () => {
	return (dispatch, getState) => {
		var state = getState();
		var { environment } = state.appController;
		var { allowedFeatures = [] } = environment;
		var newFeatures = allowedFeatures || [];
		newFeatures.push(FeaturesList.QUOTATIONS);
		dispatch(appactionCreators.setFeatures(newFeatures));
		return Promise.resolve(newFeatures);
	};
};

export const addIsomServicesFeature = () => {
	return (dispatch, getState) => {
		var state = getState();
		var { environment } = state.appController;
		var { allowedFeatures = [] } = environment;
		var newFeatures = allowedFeatures || [];
		if (!newFeatures.includes(FeaturesList.ISOMWITHSERVICES)) newFeatures.push(FeaturesList.ISOMWITHSERVICES);
		dispatch(appactionCreators.setFeatures(newFeatures));
		return Promise.resolve(newFeatures);
	};
};

export const setOrderModificationQueue = (item = null, qty = null) => {
	return dispatch => {
		dispatch(appactionCreators.setOrderModificationQueue(item, qty));
	};
};

export const resetOrderModificationQueue = (item, qty) => {
	return dispatch => {
		dispatch(resetCart(item, qty));
	};
};
