import React from "react";
import SSRIcon from "@ingka/ssr-icon";
import styled from "styled-components";
import rightIcon from "@ingka/ssr-icon/paths/chevron-right";

const SectionButton = styled.div`
	* {
		cursor: pointer !important;
	}

	span {
		padding: 0;

		&:hover {
			background: white;
		}
	}
`;

const SelectorSection = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	text-align: left;
	align-items: center;
	p {
		line-height: 1.71;
		margin-bottom: 0 !important;
	}

	.instore {
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		color: #484848;
	}
`;

const SummaryTitle = styled.p`
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.71;
	letter-spacing: normal;
	color: #111111;
	margin=bottom: 0px;
`;

const Section = ({ title, contentTxt, onSelect }) => {
	return (
		<span>
			<SectionButton data-testid="helpsection" onClick={onSelect}>
				<SelectorSection>
					<div>
						<SummaryTitle>{title}</SummaryTitle>
						<p className="instore">{contentTxt}</p>
					</div>
					<SSRIcon paths={rightIcon}></SSRIcon>
				</SelectorSection>
			</SectionButton>
			<hr></hr>
		</span>
	);
};

export default Section;
