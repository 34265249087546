const addMfeMaps = mfeMaps => {
	const apps = Object.entries(mfeMaps);
	const importMaps = {
		imports: {}
	};
	apps.forEach(([key, value]) => {
		importMaps.imports[key] = value.appUrl;
	});

	const script = document.createElement("script");
	script.type = "systemjs-importmap";
	script.text = JSON.stringify(importMaps);
	document.head.appendChild(script);
};

export default addMfeMaps;
