class CustomerModel {
	constructor() {
		this.isNewDeliveryAddress = false;

		this.customerIdentifierId = undefined;

		this.addressId = undefined;

		this.addressContextType = undefined;

		this.partyId = undefined;

		this.title = undefined;

		this.nameSuffix = undefined;

		this.namePrefix = undefined;

		this.firstName = undefined;

		this.lastName = undefined;

		this.phoneticName = undefined;

		this.phoneticFirstName = undefined;

		this.phoneticLastName = undefined;

		this.informalName = undefined;

		this.middleName = undefined;

		this.birthDate = undefined;

		this.genderCode = undefined;

		this.email = undefined;

		this.mobilePhone = undefined;

		this.landlinePhone = undefined;

		this.ikeaFamilyNumber = undefined;

		this.addressLine1 = undefined;

		this.addressLine2 = undefined;

		this.addressLine3 = undefined;

		this.addressInformation = undefined;

		this.attention = undefined;

		this.careOf = undefined;

		this.zipCode = undefined;

		this.city = undefined;

		this.street = undefined;

		this.prefecture = undefined;

		this.additionalInfo = undefined;

		this.provinceCode = undefined;

		this.streetNo = undefined;

		this.floorNo = undefined;

		this.county = undefined;

		this.stateCode = undefined;

		this.countryCode = undefined;

		this.preferredLocale = undefined;

		this.language = undefined;

		this.panNo = undefined;

		this.taxIdType = undefined;

		this.taxNo = undefined;

		this.foreignCountry = undefined;

		this.secondSurname = undefined;

		this.isNewCustomer = undefined;

		this.isBusinessCustomer = undefined;

		this.businessName = undefined;

		this.organizationNo = undefined;

		this.businessCardNo = undefined;

		this.phoneticBusinessName = undefined;

		this.sezType = undefined;

		this.sezValidityFrom = undefined;

		this.sezValidityTo = undefined;

		this.isAddedBillAddress = false;

		this.isAddedDeliveryAddress = false;

		this.noOfFamilyCards = 0;

		this.noOfBusinessCards = 0;

		this.geoLocation = undefined;

		this.certificateNo = undefined;

		this.foreignAddressCountryCode = undefined;

		this.recipientCodeType = undefined;

		this.recipientCode = undefined;

		this.taxIdentifierNo = undefined;

		this.vatPayer = "NA";

		this.preferredContactMethod = undefined;

		this.subjectiveCondition = undefined;
	}
}
export default CustomerModel;
