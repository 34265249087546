import axios from "axios";
import https from "https";
import { dispatchEvent, eventsToDispatch } from "@coworker/sharedlibrary/src/utils/eventDispatcher";
import GUID from "@coworker/sharedlibrary/src/helpers/uuidHelper";
const httpService = axios.create({
	httpsAgent: new https.Agent({
		rejectUnauthorized: false
	}),
	withCredentials: false
});

const call = method => (url, { body, headers, token } = {}) => {
	return httpService({
		method,
		url: url,
		data: body,
		headers: {
			...headers,
			"x-request-id": GUID.Guid()
		},
		cancelToken: token
	})
		.then(response => {
			if (!url.includes("splunklog")) {
				logSuccess(url, response, headers);
			}

			return {
				...response.data,
				success: true
			};
		})
		.catch(error => {
			if (!url.includes("splunklog")) {
				logError(url, error.response, headers);
			}
			let errorData = (error.response && error.response.data) || {
				success: false
			};
			return Promise.reject({
				...errorData,
				success: false,
				error: {
					success: false
				}
			});
		});
};

const logSuccess = (url, response, headers) => {
	var logObj = {
		Url: url,
		Success: "true",
		StatusCode: response && response.status,
		StatusText: response && response.statusText
	};

	if (headers && headers["x-request-id"]) {
		logObj.RequestId = headers && headers["x-request-id"];
	}
	dispatchEvent(eventsToDispatch.LOG, {
		level: "INFO",
		event: "HttpService",
		message: "Http Call Success",
		logData: logObj
	});
};

const logError = (url, response, headers) => {
	var logObj = {
		Url: url,
		Success: false,
		StatusCode: response && response.status,
		StatusText: response && response.statusText,
		ErrorData: response && response.data
	};

	if (headers && headers["x-request-id"]) {
		logObj.RequestId = headers && headers["x-request-id"];
	}
	dispatchEvent(eventsToDispatch.LOG, {
		level: "ERROR",
		event: "HttpService",
		message: "Http Call Failed",
		logData: logObj
	});
};

const getCancelTokenSource = () => {
	var cancelToken = axios.CancelToken;
	return cancelToken.source();
};

export default {
	httpService,
	get: call("GET"),
	post: call("POST"),
	put: call("PUT"),
	delete: call("DELETE"),
	getCancelTokenSource: getCancelTokenSource
};
