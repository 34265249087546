import React from "react";
import MicroAutoCompleteView from "../microfrontends/microAutoCompleteView";
import styled from "styled-components";
import { ThemeProvider } from "styled-components";
import { colors, device } from "@coworker/sharedlibrary";
import { getUA } from "react-device-detect";

const isRDT = getUA.toUpperCase().includes("CT40") || getUA.toUpperCase().includes("D75E");

const SearchContainerDiv = styled.div`
	width: 100%;
	max-width: ${props => (props.mobileLayout ? "100%" : "530px")};
	@media ${device.medium} {
		display: ${props => (props.mobileLayout ? "block" : "none")};
	}
`;
const HeaderCurtain = styled.div`
	display: none;
	@media ${device.medium} {
		display: block;
	}
	background: ${colors.blue};
	position: absolute;
	width: 100%;
	z-index: 0;
	padding: 12px 20px;
	padding-top: ${props => props.theme.paddingTop};
	top: ${props => props.theme.top};
	transition: cubic-bezier(0.42, 0, 0.6, 1.13) 0.3s top;
`;

const themeDefault = {
	paddingTop: props => (props.active ? "20px" : "12px"),
	top: props => (props.active ? "66px" : "0")
};
const themeRdt = {
	paddingTop: "12px",
	top: props => (props.active ? "calc(68px)" : "calc(-10px)")
};

const SearchContainer = ({ showSearchInput, onScanOpened, mobileLayout }) => {
	if (mobileLayout) {
		return (
			<ThemeProvider theme={isRDT ? themeRdt : themeDefault}>
				<HeaderCurtain active={showSearchInput}>
					<SearchContainerDiv mobileLayout={true}>
						<MicroAutoCompleteView id="searchmobile" onScanOpened={onScanOpened} />
					</SearchContainerDiv>
				</HeaderCurtain>
			</ThemeProvider>
		);
	} else {
		return (
			<SearchContainerDiv>
				<MicroAutoCompleteView id="searchdesktop" onScanOpened={onScanOpened} />
			</SearchContainerDiv>
		);
	}
};

export default SearchContainer;
