import SplitText from "react-pose-text";
import { Dropdown, DropdownItem } from "@ingka/dropdown";
import { APIEndpoints, LocaleHelper, URI, httpService, colors } from "@coworker/sharedlibrary";
import Button from "@ingka/button";
import styled from "styled-components";
import React from "react";
import { List, SaljaLogo } from "../../components/sharedlibrary";
import { useState } from "react";
import SalesCoworkerConfiguration from "../../store/salesCoworkerConfiguration";
const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: ${colors.appColor};
	height: 100%;
`;
const AppTitle = styled(List)`
	&.app-title {
		max-width: 90%;
	}
`;
const GuestModeLayout = styled(List)`
	&.guest-mode-layout {
		background: #fff;
		padding: 25px 40px 40px;
		margin-top: 55px;
		border-radius: 4px;

		h1 {
			text-align: center;
			color: white;
			font-size: 72px;
			margin-bottom: 10px;
			letter-spacing: 2px;
			text-align: center;
		}
		p {
			text-align: center;
			color: white;
		}
		select {
			height: 64px;
		}
		.btn {
			margin-top: 35px;
		}
	}
`;

const IkeaTitle = styled.div`
	margin-top: 10px;
	text-align: center !important;
	color: ${colors.white} !important;
	width: 150px;
	height: 23px;
	align-self: center;
	font-size: 12px;
	font-weight: bold !important;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff;
`;
const charPoses = {
	exit: { opacity: 0, y: 20 },

	enter: {
		opacity: 1,
		y: 0,
		delay: ({ charIndex }) => charIndex * 30
	}
};

const GuestModeView = ({ loginAsGuest }) => {
	const countries = LocaleHelper.getAllLanguageNames();
	const config = SalesCoworkerConfiguration.getUserConfig();
	var defaultCountry = countries && countries.find(x => x.id.toLowerCase() === navigator.language.toLowerCase());
	var countryId = (defaultCountry && defaultCountry.id) || "en-GB";

	const [selectedCountry, setSelectedCountry] = useState(countryId);
	const [stores, setStores] = useState([]);
	const [selectedStore, setselectedStore] = useState(undefined);
	React.useEffect(() => {
		setStores([]);
		const getStoresByLocale = async locale => {
			const url = new URI(APIEndpoints.storesByLocale, true).fill({ locale });

			const data = await httpService.get(url, {
				headers: config.headers
			});
			const allstores = data._embedded;

			let stores = [];
			allstores.storeList.forEach(store => {
				stores.push({
					...store
				});
			});

			return stores;
		};
		getStoresByLocale(selectedCountry)
			.then(stores => {
				setStores(stores);
				setselectedStore(stores && stores[0].No);
			})
			.catch(() => {
				setStores([]);
			});
	}, [selectedCountry]);

	return (
		<Container>
			<AppTitle className="app-title">
				<SaljaLogo />
				<IkeaTitle>
					<SplitText initialPose="exit" pose="enter" charPoses={charPoses}>
						SÄLJA GO!
					</SplitText>
				</IkeaTitle>

				<GuestModeLayout className="guest-mode-layout">
					<List margintop={"20px"}>
						{countries && countries.length > 0 && (
							<Dropdown
								defaultValue={selectedCountry}
								label="Select country"
								onChange={e => {
									setSelectedCountry(e.target.options[e.target.selectedIndex].value);
								}}
							>
								{countries.map(element => (
									<DropdownItem key={element.id} value={element.id} name={element.name} />
								))}
							</Dropdown>
						)}
					</List>

					<List margintop={"20px"}>
						{stores && stores.length > 0 && (
							<Dropdown
								defaultValue=""
								label="Select store"
								onChange={e => {
									setselectedStore(e.target.options[e.target.selectedIndex].value);
								}}
							>
								{stores.map(element => (
									<DropdownItem key={element.No} value={element.No} name={element.Name} />
								))}
							</Dropdown>
						)}
					</List>

					<Button
						disabled={selectedStore === undefined}
						type="primary"
						text="select"
						onClick={() => {
							loginAsGuest(selectedStore);
						}}
					/>
				</GuestModeLayout>
			</AppTitle>
		</Container>
	);
};

export default GuestModeView;
