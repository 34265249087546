import {
	APIEndpoints,
	GUID,
	LocaleHelper,
	OrderTypes,
	SharedConfiguration,
	Specifications,
	URI,
	cartItemsBuilder,
	httpService,
	isomItemsBuilder
} from "@coworker/sharedlibrary";

import AppLogger from "../../logger/appLogger";
import Enumerable from "linq";
import SalesCoworkerConfiguration from "../../store/salesCoworkerConfiguration";
import { getInternationalMobileFormat } from "./../../helpers/cartHelper";
import moment from "moment";

const FAMILY_TYPE = "Family";
const PRIVATE_TYPE = "Private";
const EMPLOYEE_TYPE = "Employee";
const Business_TYPE = "Business";
class IsomOrderCreationService {
	constructor() {
		this.config = SalesCoworkerConfiguration.getUserConfig();
	}
	createSomOrder = async request => {
		this.config = SalesCoworkerConfiguration.getUserConfig();
		const { countryCode } = this.config;
		const { solutions = [] } = request.salesPriceData || {};
		let hasStandaloneService = request.services.find(x => x.isCentralDelivery) === undefined;
		let hasSPEEnabled = Specifications.usesSpeCalcSpec(countryCode);
		if (request.isTransportDeskMode && solutions && solutions.length === 0) {
			AppLogger.warn("SPE FAILURE", "createSomOrder");
		}
		if ((hasSPEEnabled && solutions.length > 0) || request.isTransportDeskMode || hasStandaloneService) {
			return await this.createSomOrderV2(request);
		}

		let baseUrl = SharedConfiguration.getApiBaseUrl("cartservices");
		let url = new URI(`${baseUrl}${APIEndpoints.orderCapture}`, true).fill({
			orderType: "isom-order"
		});
		let data = somRequestBuilder(request);
		let orderRequest = {
			request: data,
			externalReference: request.externalReference
		};
		let response = await httpService.post(url, {
			body: orderRequest,
			headers: this.config.headers
		});

		let order = response;
		order.type = OrderTypes.IsomOrder;

		order.customer = {
			email: request.customer.email,
			mobilePhone: request.customer.mobilePhone
		};
		order.orderItems = request.items;
		return order;
	};
	createSomOrderV2 = async request => {
		let baseUrl = SharedConfiguration.getApiBaseUrl("cartservices");
		let url = new URI(`${baseUrl}${APIEndpoints.orderCapture}`, true).fill({
			orderType: "isom-order-v2"
		});
		let data = somRequestBuilderv2(request);
		let externalOrderKey = data.externalOrderKey;
		try {
			let orderRequest = {
				request: data,
				creditInfo: request.creditInfo && {
					...request.creditInfo,
					code: undefined,
					isBlackListed: undefined,
					isBlocked: undefined,
					maxCreditLimit: undefined,
					availableCredit: undefined
				},
				externalReference: request.externalReference,
				buCode: this.config.buCode
			};
			let response = await httpService.post(url, {
				body: orderRequest,
				headers: this.config.headers
			});

			let order = response;
			order.type = OrderTypes.IsomOrder;

			order.customer = {
				email: request.customer.email,
				mobilePhone: request.customer.mobilePhone
			};
			order.orderItems = request.items;

			let empuItems = request.items.filter(x => x.handedOverToTdDesk === false);
			if (empuItems) {
				let fullItems = cartItemsBuilder.createItems(empuItems);
				order.empuItems = fullItems.map(x => {
					return {
						orderNumber: x.referenceOrderNo,
						isFullServe: x.retailItem && x.retailItem.storeInfo && x.retailItem.storeInfo.SalesMethodCode === "FULL_SERVE",
						id: x.itemNo,
						name: x.itemName,
						qty: x.quantity
					};
				});
			}

			order.externalOrderKey = externalOrderKey;
			return order;
		} catch (error) {
			return Promise.reject({ ...error, externalOrderKey });
		}
	};
}

const somRequestBuilder = request => {
	const config = SalesCoworkerConfiguration.getUserConfig();
	const { countryCode, buCode, locale } = config;
	let { items, customer, services, totalPrice, totalFamilyPrice, customerBilling, paymentType } = request;
	let isFamily = (customer.ikeaFamilyNumber && customer.ikeaFamilyNumber !== "") || false;
	items = items.filter(x => x.quantity !== 0);
	let fullItems = cartItemsBuilder.createItems(items);
	let allItems = isomItemsBuilder.createItems(items);
	let isomrequest = {
		orderCreationMethod: request.orderCreationMethod,
		countryCode: countryCode,
		salesOrderGUId: GUID.Guid(),
		clientSystem: {
			name: "SALJA",
			version: "1.0.0"
		},
		businessUnit: {
			type: "STO",
			code: buCode
		},
		customer: mapIsomCustomer(customer, customerBilling),
		itemLines: allItems.map(item => {
			return {
				itemType: item.itemType,
				itemNo: item.itemNo,
				itemName: item.itemName,
				id: item.id,
				orderedQuantity: item.orderedQuantity,
				itemLineReferences: item.itemLineReferences,
				itemReferences: item.itemReferences,
				unitSalesPrice: item.unitSalesPrice
			};
		}),
		providedServices: getProvidedServices(
			services.filter(x => x.isProvidedService),
			allItems,
			locale
		),
		deliveryArrangements: getDeliveryArrangements(
			services.filter(x => x.isCentralDelivery),
			allItems
		),
		questionnaires: getQuestionnaires(request.questionaireData),
		orderPayment: {}
	};
	if (paymentType === "PAYMENT_IN_ADVANCE") {
		isomrequest.orderPayment.paymentInAdvance = {
			paymentDetails: getOrderPaymentForSomV1(fullItems, totalPrice, totalFamilyPrice, isFamily)
		};
	}

	if (paymentType === "PAYMENT_IN_DELIVERY") {
		isomrequest.orderPayment.paymentOnDelivery = {
			paymentDetails: getOrderPaymentForSomV1(fullItems, totalPrice, totalFamilyPrice, isFamily)
		};
	}
	if (paymentType === "PAYMENT_ON_COLLECT") {
		isomrequest.orderPayment.paymentOnCollect = {
			paymentDetails: getOrderPaymentForSomV1(fullItems, totalPrice, totalFamilyPrice, isFamily)
		};
	}
	return isomrequest;
};

const getOrderPaymentForSomV1 = (items, totalPrice, totalFamilyPrice, isfamilyMember) => {
	if (!isfamilyMember) {
		return {
			amount: totalPrice.Value,
			currencyCode: totalPrice.Currency
		};
	}
	let hasFamilyItems = Enumerable.from(items).any(x => x.totalFamilyPrice && x.totalFamilyPrice.Value && x.totalFamilyPrice.Value !== 0);
	if (hasFamilyItems) {
		return {
			amount: totalFamilyPrice.Value,
			currencyCode: totalFamilyPrice.Currency
		};
	}
	return {
		amount: totalPrice.Value,
		currencyCode: totalPrice.Currency
	};
};
const somRequestBuilderv2 = request => {
	//salesPriceData {}   orderSummary,
	// orderSubtotal,
	// timezoneId,
	// deliveryServices,
	// solutions
	let isomrequest;
	try {
		const config = SalesCoworkerConfiguration.getUserConfig();

		const { countryCode, buCode, locale, coworkerWorkArea } = config;
		const useExclusive = countryCode === "CA" || countryCode === "US";
		const isEmpuWorker = coworkerWorkArea && coworkerWorkArea.includes("EMPU");
		let {
			items,
			customer,
			services,
			customerBilling,
			salesPriceData,
			customerMeetingPoint,
			isTransportDeskMode,
			paymentType = "PAYMENT_IN_ADVANCE",
			drawings,
			creditInfo
		} = request;

		let hasStandaloneService = request.services.find(x => x.isCentralDelivery) === undefined;
		let priceViewType = PRIVATE_TYPE;
		const { orderSummary, orderSubtotal, orderSavings, couponInfo, currencyCode } = salesPriceData;
		let isEmployee = orderSummary && orderSummary.summary.find(x => x.viewType === EMPLOYEE_TYPE);
		let isFamily = (customer.ikeaFamilyNumber && customer.ikeaFamilyNumber !== "") || false;
		if (isTransportDeskMode) {
			isFamily = false;
		}
		if (isEmployee) {
			priceViewType = EMPLOYEE_TYPE;
		}
		if (customer.isBusinessCustomer) {
			priceViewType = Business_TYPE;
		}
		items = items.filter(x => x.quantity !== 0);
		let allItems = isomItemsBuilder.createItems(items, isFamily, priceViewType);
		if (hasStandaloneService) {
			allItems.forEach(itm => {
				itm.isreferenceItem = true;
				itm.handInLocation = undefined;
				itm.importDetails = undefined;
			});
		}
		let groups = items.filter(x => x.isCombination);
		if (!customer.countryCode) {
			customer.countryCode = countryCode;
		}
		if (!customerBilling.countryCode) {
			customerBilling.countryCode = countryCode;
		}
		let orderId = GUID.Guid();
		let requestItems = allItems.map(item => {
			return {
				itemType: item.itemType,
				itemNo: item.itemNo,
				itemName: item.itemName,
				id: item.id,
				orderedQuantity: item.orderedQuantity,
				unitPrice: item.itemUnitPrice,
				itemPriceDetails: item.itemPriceDetails,
				itemReferences: item.itemReferences,
				itemLineReferences: item.itemReferences,
				importDetails: config.isCashLine ? undefined : item.importDetails,
				isCustomerPicked: isTransportDeskMode ? "Y" : "N",
				handInLocation: item.handInLocation,
				isReferenceItem: item.isreferenceItem
			};
		});
		if (isTransportDeskMode) {
			if (requestItems.filter(x => x.handInLocation).length !== requestItems.length) {
				AppLogger.warn("Missing Handin Location for items", "MISSING_HAND_IN_LOCATION", {
					items: requestItems.map(x => {
						return {
							itemNo: x.itemNo,
							handInLocation: x.handInLocation
						};
					})
				});
				requestItems.forEach(x => {
					if (!x.handInLocation) {
						x.handInLocation = isEmpuWorker ? "EMPU_COLLECT|Transport Desk" : "STORE_HANDIN|Transport Desk";
					}
				});
			}
		}

		isomrequest = {
			orderCreationMethod: request.orderCreationMethod,
			countryCode: countryCode,
			salesOrderGUId: orderId,
			clientSystem: {
				name: "SALJA",
				version: "1.0.0"
			},
			businessUnit: {
				type: "STO",
				code: buCode
			},

			customer: mapIsomCustomer(customer, customerBilling),
			itemLines: requestItems,
			providedServices: getProvidedServicesV2(
				salesPriceData,
				services.filter(x => x.isProvidedService),
				allItems,
				locale,
				isFamily,
				priceViewType
			),
			deliveryArrangements: getDeliveryArrangementsv2(
				services.filter(x => x.isCentralDelivery),
				allItems,
				salesPriceData,
				isFamily,
				priceViewType
			),
			questionnaires: getQuestionnaires(request.questionaireData),
			orderPayment: {},
			orderSummary: getOrderSummary(orderSummary, orderSubtotal, isFamily, hasStandaloneService, priceViewType),
			orderSummaryExclSavings: getOrderSummaryExclusive(orderSummary, orderSubtotal, isFamily, hasStandaloneService, priceViewType)
		};
		if (drawings && drawings.length > 0) {
			isomrequest.drawings = drawings;
		}
		if (groups && groups.length > 0) {
			isomrequest.groups = [];
			groups.forEach(grp => {
				let grpItem = {
					groupNo: grp.id,
					groupName: grp.name,
					id: grp.id,
					referencedItems: []
				};
				grpItem.referencedItems = grp.childItems.map(chid => {
					return chid.id;
				});
				isomrequest.groups.push(grpItem);
			});
		}
		if (orderSavings && orderSavings.length > 0) {
			isomrequest.orderSavings = orderSavings.map((sv, index) => {
				return {
					id: sv.id,
					sequence: index,
					type: sv.type,
					discountDetails: {
						count: sv.discountDetails.count,
						value: sv.discountDetails.value,
						type: sv.discountDetails.type,
						applyOn: sv.discountDetails.applyOn
					}
				};
			});
		}
		if (couponInfo) {
			isomrequest.couponInformations = [];
			couponInfo.forEach(cpn => {
				let viewType = isFamily ? "Family" : priceViewType;

				let cpnInfo = cpn.discountAmount.find(x => x.viewType === viewType);
				if (cpnInfo) {
					let cpnReq = {
						discountCode: cpn.discountCode,
						discountAmount: cpnInfo.amount
					};
					isomrequest.couponInformations.push(cpnReq);
				}
			});
		}
		if (isTransportDeskMode) {
			isomrequest.handInLocation = "Transport Desk";
		}
		isomrequest.externalOrderKey = {
			orderNo: orderId,
			orderNoSource: LocaleHelper.getOrderSource(countryCode)
		};
		if (paymentType === "PAYMENT_IN_ADVANCE") {
			isomrequest.orderPayment.paymentInAdvance = {
				paymentDetails: getOrderPayment(orderSummary, currencyCode, isFamily, priceViewType, useExclusive)
			};
		}

		if (paymentType === "PAYMENT_IN_DELIVERY") {
			isomrequest.orderPayment.paymentOnDelivery = {
				paymentDetails: getOrderPayment(orderSummary, currencyCode, isFamily, priceViewType, useExclusive)
			};
		}
		if (paymentType === "PAYMENT_ON_COLLECT") {
			isomrequest.orderPayment.paymentOnCollect = {
				paymentDetails: getOrderPayment(orderSummary, currencyCode, isFamily, priceViewType, useExclusive)
			};
		}
		if (paymentType === "PAYMENT_IN_INVOICE") {
			if (isTransportDeskMode && !config.isCashLine) {
				isomrequest.orderPayment.paymentInAdvance = {
					paymentDetails: getOrderPaymentInAdvanceForGoods(orderSubtotal, currencyCode, priceViewType)
				};
			}
			isomrequest.orderPayment.invoice = {
				paymentTransactions: creditInfo.creditTransactions || [],
				paymentDetails: {
					amount: creditInfo.amount,
					requisitionNo: creditInfo.invoiceRef1,
					referenceNo2: creditInfo.invoiceRef2,
					referenceNo3: creditInfo.invoiceRef3,
					currencyCode: creditInfo.currency
				}
			};
		}

		if (customerMeetingPoint && customerMeetingPoint.id) {
			isomrequest.customerMeetingPoint = customerMeetingPoint;
		}
	} catch (error) {
		console.log(error);
		AppLogger.error(error.message, "ISOM_REQUEST_ERROR");
	}
	return isomrequest;
};

const getOrderPaymentInAdvanceForGoods = (orderSubtotal, currency, priceViewType, isFamily) => {
	let viewType = priceViewType;
	let type = isFamily ? FAMILY_TYPE : viewType;
	let subTotal = orderSubtotal.find(x => x.viewType === type);
	const { product } = subTotal;
	let goodsavings = product && product.inclSavings;

	return {
		amount: (goodsavings && goodsavings.priceInclTax) || 0,
		currencyCode: currency
	};
};
const getOrderPayment = (orderSummary, currencyCode, isFamily, priceViewType, useExclusive) => {
	let viewType = priceViewType;
	let type = isFamily ? FAMILY_TYPE : viewType;
	let summary = orderSummary.summary.find(x => x.viewType === type);

	return {
		amount: useExclusive ? summary.inclSavings.priceExclTax : summary.inclSavings.priceInclTax,
		currencyCode: currencyCode
	};
};

const getQuestionnaires = questionaireData => {
	let request = {
		deliveryQuestionnaires: [],
		serviceQuestionnaires: []
	};
	console.log(questionaireData);
	if (questionaireData.deliveryQuestions && questionaireData.deliveryQuestions.length > 0) {
		let groupedServices = Enumerable.from(questionaireData.deliveryQuestions)
			.groupBy(x => x.serviceReference)
			.select(function (grp) {
				return {
					id: grp.key(),
					questions: grp.toArray()
				};
			})
			.toArray();
		console.log(groupedServices);
		groupedServices.forEach(grpService => {
			let answers = [];
			grpService.questions.forEach(que => {
				let answer = Enumerable.from(questionaireData.questionaireAnswers).firstOrDefault(x => x.reference === que.reference);
				if (answer != null) {
					let queAns = {
						option: answer.option,
						questionReference: answer.reference,
						textValue: answer.freeText
					};
					answers.push(queAns);
				}
			});

			let dtoIsomQuestionnaire = {
				deliveryServiceItemId: grpService.id,
				type: questionaireData.selectedDeliveryQuestionnaireType,
				questionnaire: {
					answers: answers
				}
			};
			request.deliveryQuestionnaires.push(dtoIsomQuestionnaire);
		});
	}
	if (questionaireData.serviceQuestions && questionaireData.serviceQuestions.length > 0) {
		let groupedServiceQuestions = Enumerable.from(questionaireData.serviceQuestions)
			.groupBy(x => x.providedServiceItemId)
			.select(function (grp) {
				return {
					id: grp.key(),
					type: questionaireData.selectedDeliveryQuestionnaireType || "FLAT",
					questions: grp.toArray()
				};
			})
			.toArray();
		groupedServiceQuestions.forEach(grpService => {
			const answers = [];
			grpService.questions.forEach(que => {
				const answer = Enumerable.from(questionaireData.questionaireAnswers).firstOrDefault(x => x.reference === que.reference);
				if (answer) {
					const queAns = {
						option: answer.option,
						questionReference: answer.reference,
						textValue: answer.freeText
					};
					const exist = answers.find(x => x.questionReference === answer.reference);
					if (!exist) {
						answers.push(queAns);
					}
				}
			});

			let dtoIsomQuestionnaire = {
				providedServiceItemId: grpService.id.includes("SGR") ? grpService.id : `SGR${grpService.id}`,
				type: grpService.type,
				questionnaire: {
					answers: answers
				}
			};
			request.serviceQuestionnaires.push(dtoIsomQuestionnaire);
		});
	}

	return request;
};

const mapIsomCustomer = (customer, customerBilling) => {
	let billingContact;
	let deliveryContact;

	billingContact = {
		address: getAddress(customerBilling),
		contactDetails: getContactDetails(customerBilling),
		personalInfo: getPersonalInfo(customerBilling)
	};

	deliveryContact = {
		address: getAddress(customer),
		contactDetails: getContactDetails(customer),
		personalInfo: getPersonalInfo(customer)
	};

	let prefferedlocale = LocaleHelper.formatLocaleIso(customer.preferredLocale);
	let cusData = {
		globalCustomerId: customer.partyId,
		preferredLocale: prefferedlocale,
		registeredType: customer.partyId && customer.partyId !== "" ? "REGISTERED" : "GUEST",
		isSameBillingAndDeliveryContact: !customer.isAddedBillAddress,
		billingContact: billingContact,
		deliveryContact: deliveryContact
	};
	let customerData = {};

	if (customer.isBusinessCustomer) {
		customerData.businessCustomer = cusData;
		customerData.businessCustomer.businessInfo = {
			businessName: customer.businessName,
			organizationNo: customer.organizationNo
		};
	} else {
		customerData.privateCustomer = cusData;
	}

	return customerData;
};

const getAddress = customer => {
	return {
		addressLine1: customer.addressLine1,
		addressLine2: customer.addressLine2,
		addressLine3: customer.addressLine3,
		attention: customer.attention,
		careOfName: customer.careOfName,
		city: customer.city,
		countryCode: customer.foreignAddressCountryCode || customer.countryCode,
		countyName: customer.countyName,
		prefecture: customer.prefecture,
		stateCode: Specifications.usesPrefectureSpec(customer.countryCode) ? customer.prefecture || customer.stateCode : customer.stateCode,
		zipCode: customer.zipCode
	};
};

const getPersonalInfo = customer => {
	let personalInfo = {
		familyNo: customer.ikeaFamilyNumber,
		firstName: customer.firstName,
		foreignCountry: customer.foreignCountry,
		genderCode: customer.genderCode,
		lastName: customer.lastName,
		middleName: customer.middleName,
		pan: customer.panNo,
		phoneticName: customer.phoneticName,
		title: customer.title,
		phoneticBusinessName: customer.isBusinessCustomer ? customer.phoneticBusinessName : "",
		phoneticLastName: customer.phoneticLastName,
		phoneticFirstName: customer.phoneticFirstName,
		recipientCodeType: customer.recipientCodeType,
		recipientCode: customer.recipientCode,
		taxCodeType: customer.taxIdType && customer.taxIdType !== undefined && customer.taxIdType.includes("NA-") ? "NA" : customer.taxIdType,
		taxCode: customer.taxNo,
		taxIdentifierNo: customer.taxIdentifierNo ? customer.taxIdentifierNo : customer.certificateNo
	};
	//for non icm business customer , firstname and lastname wont be available in that case send empty personalinfo
	return personalInfo && personalInfo.firstName && personalInfo.lastName ? personalInfo : undefined;
};

const getContactDetails = customer => {
	const config = SalesCoworkerConfiguration.getUserConfig();
	const { countryCode } = config;
	return {
		email: customer.email,
		mobile: getInternationalMobileFormat(customer.mobilePhone, countryCode),
		phone: customer.landlinePhone
	};
};
const getOrderSummary = (orderSummary, orderSubtotal, isFamily, hasStandaloneService, priceViewType) => {
	let viewType = priceViewType;
	let type = isFamily ? FAMILY_TYPE : viewType;
	let subTotal = orderSubtotal.find(x => x.viewType === type);
	let totalPrice = orderSummary.summary.find(x => x.viewType === type);
	let totalAmount = {
		...totalPrice.inclSavings
	};
	if (totalPrice.savings) {
		totalAmount.savingSummary = totalPrice.savings;
	}
	if (totalAmount.totalSavings) {
		let familyPrice = totalAmount.totalSavings.familyPrice || 0;
		let savingsAmount = totalAmount.totalSavings.all - familyPrice;
		totalAmount.savingAmount = savingsAmount || 0;
	}
	const { product, delivery, service } = subTotal;
	let goodsavings = (product && product.inclSavings && product.inclSavings.totalSavings && product.inclSavings.totalSavings.all) || 0;

	let deliverysavings = (delivery && delivery.inclSavings && delivery.inclSavings.totalSavings && delivery.inclSavings.totalSavings.all) || 0;
	let servicesavings = (service && service.inclSavings && service.inclSavings.totalSavings && service.inclSavings.totalSavings.all) || 0;
	let orderSummaryies = {
		subTotals: {},
		totalAmount: totalAmount
	};
	if (hasStandaloneService) {
		if (product) {
			orderSummaryies.subTotals.goodsAmount = {
				...product.inclSavings,
				savingAmount: goodsavings
			};
		}
		if (service) {
			orderSummaryies.subTotals.serviceAmount = {
				...service.inclSavings,
				savingAmount: servicesavings
			};
		}
		return orderSummaryies;
	}
	if (product) {
		orderSummaryies.subTotals.goodsAmount = {
			...product.inclSavings,
			savingAmount: goodsavings
		};
	}
	if (delivery) {
		orderSummaryies.subTotals.deliveryAmount = {
			...delivery.inclSavings,
			savingAmount: deliverysavings
		};
	}
	if (service) {
		orderSummaryies.subTotals.serviceAmount = {
			...service.inclSavings,
			savingAmount: servicesavings
		};
	}
	return orderSummaryies;
};

// eslint-disable-next-line no-unused-vars
const getOrderSummaryExclusive = (orderSummary, orderSubtotal, isFamily, hasStandaloneService, priceViewType) => {
	let viewType = priceViewType;
	let type = isFamily ? FAMILY_TYPE : viewType;
	let subTotal = orderSubtotal.find(x => x.viewType === type);
	let totalPrice = orderSummary.summary.find(x => x.viewType === type);
	let totalAmount = {
		...totalPrice.exclSavings
	};
	if (totalPrice.savings) {
		totalAmount.savingSummary = totalPrice.savings;
	}
	if (totalAmount.totalSavings) {
		totalAmount.savingAmount = totalAmount.totalSavings.all || 0;
	}
	const { product, delivery, service } = subTotal;
	let goodsavings = (product && product.exclSavings && product.exclSavings.totalSavings && product.exclSavings.totalSavings.all) || 0;

	let deliverysavings = (delivery && delivery.exclSavings && delivery.exclSavings.totalSavings && delivery.exclSavings.totalSavings.all) || 0;
	let servicesavings = (service && service.exclSavings && service.exclSavings.totalSavings && service.exclSavings.totalSavings.all) || 0;

	let orderSummaryies = {
		subTotals: {},
		totalAmount: totalAmount
	};
	if (hasStandaloneService) {
		if (product) {
			orderSummaryies.subTotals.goodsAmount = {
				...product.exclSavings,
				savingAmount: goodsavings
			};
		}
		if (service) {
			orderSummaryies.subTotals.serviceAmount = {
				...service.exclSavings,
				savingAmount: servicesavings
			};
		}
		return orderSummaryies;
	}
	if (product) {
		orderSummaryies.subTotals.goodsAmount = {
			...product.exclSavings,
			savingAmount: goodsavings
		};
	}
	if (delivery) {
		orderSummaryies.subTotals.deliveryAmount = {
			...delivery.exclSavings,
			savingAmount: deliverysavings
		};
	}
	if (service) {
		orderSummaryies.subTotals.serviceAmount = {
			...service.exclSavings,
			savingAmount: servicesavings
		};
	}
	return orderSummaryies;
};

const buildPickPoint = pickupPoint => {
	if (pickupPoint) {
		return {
			address: {
				addressLine1: pickupPoint.addressLine1,
				addressLine2: pickupPoint.addressLine2,
				addressLine3: pickupPoint.addressLine3,
				addressLine4: pickupPoint.addressLine4,
				city: pickupPoint.city,
				country: pickupPoint.country,
				state: pickupPoint.state,
				zipCode: pickupPoint.zipCode
			},
			distance: pickupPoint.distance,
			distanceUnitOfMeasure: "KM",
			id: pickupPoint.id,
			pupId: pickupPoint.id,
			identifier: pickupPoint.identifier,
			lsc: {
				code: pickupPoint.lsc.split(".")[1],
				type: pickupPoint.lsc.split(".")[0]
			},
			name: pickupPoint.name,
			openingHours: getOpenHours(pickupPoint)
		};
	}
	return;
};

const getOpenHours = pickupPoint => {
	const openHours = [];
	const weekdays = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];

	[
		pickupPoint.openingHoursSunTime,
		pickupPoint.openingHoursMonTime,
		pickupPoint.openingHoursTueTime,
		pickupPoint.openingHoursWedTime,
		pickupPoint.openingHoursThuTime,
		pickupPoint.openingHoursFriTime,
		pickupPoint.openingHoursSatTime
	].forEach((x, index) => {
		openHours.push({
			dayOfWeek: weekdays[index],
			timeSlot: {
				end: x ? x.split("-")[1] : "closed",
				start: x ? x.split("-")[0] : "closed"
			}
		});
	});

	return openHours;
};

const getProvidedServices = (selectedServices, items, locale) => {
	return selectedServices.map(srv => {
		return {
			serviceItemId: srv.serviceItemNo.includes("SGR") ? srv.serviceItemNo : `SGR${srv.serviceItemNo}`,
			serviceProductId: srv.serviceProductId,
			priceCalculationValue: parseInt(srv.priceCalculationValue),
			servicePrice: {
				amount: srv.price.priceInclTax || srv.price.priceExclTax,
				currencyCode: srv.price.currencyCode,
				taxDetails: srv.price.taxDetails
			},
			serviceProviderId: srv.serviceProviderId,
			capacityUnitCode: srv.capacityUnit,
			serviceProviderName: srv.serviceProviderName,
			servicePaymentTo: srv.serviceProviderPreferredPayment,
			serviceTimeWindows: srv.timeWindows.map(tw => {
				return {
					id: tw.id,
					capacityAllocated: parseInt(srv.capacityNeeded),
					fromDateTime: tw.fromDateTime,
					toDateTime: tw.toDateTime,
					timeZone: tw.timeZone
				};
			}),

			itemLineReferenceIds: srv.referenceItems.map(item => {
				let delitem = items.find(x => x.refId.toString() === item.serviceReference.lineId.toString());
				return delitem.id;
			}),
			serviceRequestedLocale: locale
		};
	});
};

const getProvidedServicesV2 = (orderSummaryData, selectedServices, items, locale, isFamily, priceViewType) => {
	const { providedServices } = orderSummaryData;
	if (selectedServices.length === 0) {
		return;
	}
	let viewType = priceViewType;
	let type = isFamily ? FAMILY_TYPE : viewType;
	return selectedServices.map(srv => {
		let speProvidedService = providedServices.find(x => x.serviceProductId === srv.serviceProductId);
		let srvPrice = srv.price;
		srvPrice = {
			amount: srv.price.priceInclTax || srv.price.priceExclTax,
			currencyCode: srv.price.currencyCode,
			taxDetails: srv.price.taxDetails
		};
		if (speProvidedService) {
			let speProvidedServicePrice = speProvidedService.servicePrice.find(x => x.viewType === type);
			if (!speProvidedServicePrice) {
				speProvidedServicePrice = speProvidedService.servicePrice.find(x => x.viewType === type);
			}
			const { exclSavings, inclSavings, savings } = speProvidedServicePrice;
			srvPrice = {
				exclSavings,
				inclSavings,
				savings:
					savings &&
					savings.map(x => {
						return {
							...x,
							code: x.code || x.id,
							id: x.id || x.code
						};
					})
			};
		}
		return {
			serviceItemId: srv.serviceItemNo.includes("SGR") ? srv.serviceItemNo : `SGR${srv.serviceItemNo}`,
			serviceProductId: srv.serviceProductId,
			priceCalculationValue: parseInt(srv.priceCalculationValue),
			servicePriceDetails: srvPrice,
			serviceProviderId: srv.serviceProviderId,
			capacityUnitCode: srv.capacityUnit,
			serviceProviderName: srv.serviceProviderName,
			servicePaymentTo: srv.serviceProviderPreferredPayment,
			serviceTimeWindows: srv.timeWindows.map(tw => {
				return {
					id: tw.id,
					capacityAllocated: parseInt(srv.capacityNeeded),
					fromDateTime: tw.fromDateTime,
					toDateTime: tw.toDateTime,
					timeZone: tw.timeZone
				};
			}),
			itemLineReferenceIds: srv.referenceItems.map(item => {
				let delitem = items.find(x => x.refId.toString() === item.serviceReference.lineId.toString());
				return delitem.id;
			}),
			serviceRequestedLocale: locale
		};
	});
};

const getProvidedServicesForIQuote = (orderSummaryData, selectedServices, items, locale, isFamily) => {
	const { providedServices } = orderSummaryData;

	return selectedServices.map(srv => {
		let speProvidedService = providedServices.find(x => x.serviceProductId === srv.serviceProductId);
		let srvPrice = srv.price;
		srvPrice = {
			amount: srv.price.priceInclTax || srv.price.priceExclTax,
			currencyCode: srv.price.currencyCode,
			taxDetails: srv.price.taxDetails
		};
		if (speProvidedService) {
			let type = isFamily ? FAMILY_TYPE : PRIVATE_TYPE;
			let speProvidedServicePrice = speProvidedService.servicePrice.find(x => x.viewType === type);
			const { exclSavings, inclSavings, savings } = speProvidedServicePrice;
			srvPrice = {
				exclSavings,
				inclSavings,
				savings
			};
		}
		return {
			serviceItemId: srv.serviceItemNo.includes("SGR") ? srv.serviceItemNo : `SGR${srv.serviceItemNo}`,
			serviceProductId: srv.serviceProductId,
			priceCalculation: {
				calculationType: srv.priceCalculationType,
				calculationValue: parseInt(srv.priceCalculationValue)
			},
			servicePriceDetails: srvPrice,
			serviceProviderId: srv.serviceProviderId,
			capacityUnitCode: srv.capacityUnit,
			serviceProviderName: srv.serviceProviderName,
			servicePaymentTo: srv.serviceProviderPreferredPayment,
			serviceTimeWindows: srv.timeWindows.map(tw => {
				return {
					id: tw.id,
					capacityAllocated: parseInt(srv.capacityNeeded),
					fromDateTime: tw.fromDateTime,
					toDateTime: tw.toDateTime,
					timeZone: tw.timeZone
				};
			}),
			itemLineReferenceIds: srv.referenceItems.map(item => {
				let delitem = items.find(x => x.refId.toString() === item.serviceReference.lineId.toString());
				return delitem.id;
			}),
			serviceRequestedLocale: locale
		};
	});
};
const getDeliveryArrangements = (bookedServices, items) => {
	let solutions = bookedServices;
	if (solutions.length === 0) {
		return;
	}
	let cartItems = Enumerable.from(items);
	let arrangements = [];
	solutions.forEach(sol => {
		let arrgenmentDeliveries = [];
		let solprice = sol.price;
		sol.subDeliveries.forEach(subdel => {
			let delItems = Enumerable.from(subdel.referenceItems)
				.select(function (delRefitem) {
					return {
						refLineId: delRefitem.serviceReference.lineId,
						itemLineId: cartItems.firstOrDefault(y => y.refId.toString() === delRefitem.serviceReference.lineId.toString()).id,
						quantity: delRefitem.serviceReference.quantity,
						fulfillingUnit: {
							type: delRefitem.timeWindowLineItem && delRefitem.timeWindowLineItem.shipNode.split(".")[0],
							code: delRefitem.timeWindowLineItem && delRefitem.timeWindowLineItem.shipNode.split(".")[1]
						},
						isExceptionalQty: delRefitem.timeWindowLineItem && delRefitem.timeWindowLineItem.isExceptionalQty
					};
				})
				.toArray();
			let timeWindow = Enumerable.from(subdel.timeWindows).first();

			let delPrice = subdel.price;
			let arrangementDel = {
				serviceItemId: subdel.serviceItemId,
				deliveryPrice: {
					amount: delPrice.amount,
					currencyCode: delPrice.currencyCode,
					taxDetails: delPrice.taxDetails
				},
				unitOfMeasure: subdel.unitMeasure,
				timeWindow: {
					fromLocalDateTime: moment(timeWindow.fromDateTime).format("YYYY-MM-DDTHH:mm:ss"),
					toLocalDateTime: moment(timeWindow.toDateTime).format("YYYY-MM-DDTHH:mm:ss"),

					id: timeWindow.id,
					resourcePoolId: timeWindow.resourceId,
					transferringUnit: {
						type: timeWindow.node.split(".")[0],
						code: timeWindow.node.split(".")[1]
					},
					timeZone: timeWindow.timeZoneId
				},
				deliveryItems: delItems,
				isExceptionalVolume: timeWindow.isExceptionalVolume,
				pickupPoint: buildPickPoint(subdel.pickupPoint)
			};

			arrgenmentDeliveries.push(arrangementDel);
		});

		let arrangement = {
			fulfillmentMethod: sol.fulfillmentMethod,
			type: sol.fulfillmentType,
			deliveryServices: arrgenmentDeliveries,
			arrangementPrice: {
				amount: solprice.amount,
				currencyCode: solprice.currencyCode,
				taxDetails: solprice.taxDetails
			}
		};
		arrangements.push(arrangement);
	});
	return arrangements;
};
const getDeliveryArrangementsv2 = (bookedServices, items, speDeliveryData, isFamily, priceViewType) => {
	if (bookedServices.length === 0) {
		return [];
	}
	const { deliveryServices, solutions } = speDeliveryData;
	let viewType = priceViewType;
	let type = isFamily ? FAMILY_TYPE : viewType;
	let cartItems = Enumerable.from(items);
	let arrangements = [];
	bookedServices.forEach(sol => {
		let solprice;

		let speSolution = solutions.find(sl => sl.solutionId === sol.solutionId);
		if (speSolution) {
			let solPrices = speSolution.price.find(x => x.viewType === type);
			if (!solPrices) {
				solPrices = speSolution.price.find(x => x.viewType === type);
			}
			let { inclSavings } = solPrices;
			solprice = { ...inclSavings };
		}

		let arrgenmentDeliveries = [];

		sol.subDeliveries.forEach(subdel => {
			let speDeliveryService = deliveryServices.find(x => x.deliveryMethodId === subdel.serviceItemId && x.lineId === subdel.deliveryId);
			let delPrice = subdel.price;
			delPrice = {
				amount: delPrice.amount,
				currencyCode: delPrice.currencyCode,
				taxDetails: delPrice.taxDetails
			};
			if (speDeliveryService) {
				let speDelPrice = speDeliveryService.deliveryPrice.find(x => x.viewType === type);
				if (!speDelPrice) {
					speDelPrice = speDeliveryService.deliveryPrice.find(x => x.viewType === type);
				}
				const { exclSavings, inclSavings, savings } = speDelPrice;
				delPrice = {
					exclSavings,
					inclSavings,
					savings:
						savings &&
						savings.map(x => {
							return {
								...x,
								code: x.code || x.id,
								id: x.id || x.code
							};
						})
				};
			}
			let delItems = Enumerable.from(subdel.referenceItems)
				.select(function (delRefitem) {
					return {
						refLineId: delRefitem.serviceReference.lineId,
						itemLineId: cartItems.firstOrDefault(y => y.refId.toString() === delRefitem.serviceReference.lineId.toString()).id,
						quantity: parseInt(delRefitem.serviceReference.quantity),
						fulfillingUnit: {
							type: delRefitem.timeWindowLineItem.shipNode.split(".")[0],
							code: delRefitem.timeWindowLineItem.shipNode.split(".")[1]
						},
						isExceptionalQty: delRefitem.timeWindowLineItem.isExceptionalQty
					};
				})
				.toArray();
			let timeWindow = Enumerable.from(subdel.timeWindows).first();

			let arrangementDel = {
				serviceItemId: subdel.serviceItemId,
				deliveryPriceDetails: delPrice,
				unitOfMeasure: subdel.unitMeasure,
				timeWindow: {
					fromLocalDateTime: moment(timeWindow.fromDateTime).format("YYYY-MM-DDTHH:mm:ss"),
					toLocalDateTime: moment(timeWindow.toDateTime).format("YYYY-MM-DDTHH:mm:ss"),

					id: timeWindow.id,
					resourcePoolId: timeWindow.resourceId,
					transferringUnit: {
						type: timeWindow.node.split(".")[0],
						code: timeWindow.node.split(".")[1]
					},
					timeZone: timeWindow.timeZoneId
				},
				deliveryItems: delItems,
				isExceptionalVolume: timeWindow.isExceptionalVolume,
				pickupPoint: buildPickPoint(subdel.pickupPoint)
			};
			arrangementDel.capabilities = [];
			if (subdel.authoritytoleaveSelected) {
				arrangementDel.capabilities.push("AUTH_TO_LEAVE");
			}
			if (subdel.wheelChairCapable) {
				arrangementDel.capabilities.push("WHEELCHAIR");
			}
			if (timeWindow.isRangeOfDays) {
				arrangementDel.capabilities.push("RANGE_OF_DAYS");
			}
			if (subdel.mergeOrder && subdel.mergeOrderSelected && subdel.mergeValid) {
				arrangementDel.mergeOrderNo = subdel.mergeOrder.mergeOrderNo;
				arrangementDel.mergeDeliveryNo = subdel.mergeOrder.mergeDeliveryNo;
			}
			arrangementDel.paymentCutOffDateTime = timeWindow.paymentCutOffDateTime;
			arrgenmentDeliveries.push(arrangementDel);
		});

		let arrangement = {
			fulfillmentMethod: sol.fulfillmentMethod,
			type: sol.fulfillmentType,
			deliveryServices: arrgenmentDeliveries,
			arrangementPrice: solprice
		};
		arrangements.push(arrangement);
	});
	return arrangements;
};
const getDeliveryArrangementsForIQuote = (bookedServices, items, speDeliveryData, isFamily) => {
	const { deliveryServices, solutions } = speDeliveryData;

	let cartItems = Enumerable.from(items);
	let arrangements = [];
	bookedServices.forEach(sol => {
		let solprice = {
			amount: sol.price.amount,
			currencyCode: sol.price.currencyCode,
			taxDetails: sol.price.taxDetails
		};

		let speSolution = solutions.find(sl => sl.solutionId === sol.solutionId);
		if (speSolution) {
			let type = isFamily ? FAMILY_TYPE : PRIVATE_TYPE;
			let solPrices = speSolution.price.find(x => x.viewType === type);
			solprice = solPrices;
		}

		let arrgenmentDeliveries = [];

		sol.subDeliveries.forEach((subdel, index) => {
			let speDeliveryService = deliveryServices.find(x => x.deliveryMethodId === subdel.serviceItemId);
			let delPrice = subdel.price;
			delPrice = {
				amount: delPrice.amount,
				currencyCode: delPrice.currencyCode,
				taxDetails: delPrice.taxDetails
			};
			if (speDeliveryService) {
				let type = isFamily ? FAMILY_TYPE : PRIVATE_TYPE;
				let speDelPrice = speDeliveryService.deliveryPrice.find(x => x.viewType === type);
				const { exclSavings, inclSavings, savings } = speDelPrice;
				delPrice = {
					exclSavings,
					inclSavings,
					savings
				};
			}
			let delItems = Enumerable.from(subdel.referenceItems)
				.select(function (delRefitem) {
					return {
						refLineId: delRefitem.serviceReference.lineId,
						itemLineId: cartItems.firstOrDefault(y => y.refId.toString() === delRefitem.serviceReference.lineId.toString()).id,
						quantity: parseInt(delRefitem.serviceReference.quantity),
						fulfillingUnit: {
							type: delRefitem.timeWindowLineItem.shipNode.split(".")[0],
							code: delRefitem.timeWindowLineItem.shipNode.split(".")[1]
						},
						isExceptionalQty: delRefitem.timeWindowLineItem.isExceptionalQty
					};
				})
				.toArray();
			let timeWindow = Enumerable.from(subdel.timeWindows).first();

			let arrangementDel = {
				lineId: parseInt(index + 1),
				serviceItemId: subdel.serviceItemId,
				deliveryPriceDetails: delPrice,
				unitOfMeasure: subdel.unitMeasure,
				timeWindow: {
					fromLocalDateTime: moment(timeWindow.fromDateTime).format("YYYY-MM-DDTHH:mm:ss"),
					toLocalDateTime: moment(timeWindow.toDateTime).format("YYYY-MM-DDTHH:mm:ss"),

					id: timeWindow.id,
					resourcePoolId: timeWindow.resourceId,
					transferringUnit: {
						type: timeWindow.node.split(".")[0],
						code: timeWindow.node.split(".")[1]
					},
					timeZone: timeWindow.timeZoneId
				},
				deliveryItems: delItems,
				isExceptionalVolume: timeWindow.isExceptionalVolume,
				pickupPoint: buildPickPoint(subdel.pickupPoint)
			};
			arrgenmentDeliveries.push(arrangementDel);
		});

		let arrangement = {
			fulfillmentMethod: sol.fulfillmentMethod,
			type: sol.fulfillmentType,
			deliveryServices: arrgenmentDeliveries,
			arrangementPrice: solprice
		};
		arrangements.push(arrangement);
	});
	return arrangements;
};
export default IsomOrderCreationService;

export { getDeliveryArrangementsv2, getProvidedServicesV2, getDeliveryArrangementsForIQuote, getProvidedServicesForIQuote };
