import UserLogoutDialog from "./userLogoutDialog";
import HelpSectionDialog from "./helpSectionDialog";
import UserAccountDialog from "./userAccountDialog";
import PreSelectCustomerDialog from "./preSelectCustomerDialog";
import UserIdleDialog from "./userIdleDialog";
import OrderModificationDialog from "./orderModificationDialog";

//popupcomponents should be refered here
const types = {
	userLogoutDialog: { component: UserLogoutDialog },
	userAccountPopup: { component: UserAccountDialog },
	helpSectionDialog: { component: HelpSectionDialog },
	preSelectCustomerDialog: { component: PreSelectCustomerDialog },
	userIdleDialog: { component: UserIdleDialog },
	orderModificationDialog: { component: OrderModificationDialog }
};

export default types;
