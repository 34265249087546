import Enumerable from "linq";
import { GaHelper } from "@coworker/sharedlibrary";
import { ObjectHelper } from "@coworker/sharedlibrary";
import { OrderTypes } from "@coworker/sharedlibrary";

import AppLogger from "../../logger/appLogger";
import IsomOrderCreationService from "./isomOrderCreationService";
import IsomOrderModificationService from "./isomOrderModificationService";
import OrderModificationService from "./orderModificationService";
import PubSubService from "./../pubSubService";
import QuotationWrapperService from "../quotation/quoationApi";
import SalesCoworkerConfiguration from "../../store/salesCoworkerConfiguration";
import SalesOrderService from "./salesOrderService";
import ShoppingListAndCcOrderService from "./shoppingListAndCcOrderService";
import { getCustomerProfileObject } from "../../utils/shellObjectService";
import { getOrderAmountSummarySelector } from "./../../store/cart/cartSelectors";

const publishToPubSub = (items, orderType, orderValue) => {
	let userId = undefined;

	const kioskItems = items.map(item => {
		if (item.kioskItemUserId !== undefined) {
			userId = item.kioskItemUserId;
			return item.itemId;
		}
	});

	if (userId !== undefined && kioskItems.length) {
		PubSubService.publish("create-order", userId, kioskItems, orderType, orderValue);
	}
};

const createOrder = async (type, pickupNotification) => {
	let store = SalesCoworkerConfiguration.getStore();
	const state = store.getState();
	const { cartController } = state;
	const {
		items,
		salesPriceData,
		isQuotation,
		order,
		orderCreationMethod,
		externalReference,
		isTransportDeskMode,
		hasServices,
		bookedServiceProposal,
		kitchen,
		drawings,
		creditInfo
	} = cartController;
	const { isCcdService } = bookedServiceProposal;

	const { customer } = getCustomerProfileObject();
	//checkif icm profile enabled on store to create icm customer

	if (type === OrderTypes.ShoppingList) {
		return await createShoppingList(items, externalReference, drawings);
	} else {
		if (hasServices) {
			const { totalPrice, totalFamilyPrice } = getOrderAmountSummarySelector(state);
			const deliveryserviceInfo = getSalesRequestData(state);

			const newCustomer = { ...customer };

			let deliveryData = { ...deliveryserviceInfo, salesPriceData };
			deliveryData.kitchen = kitchen;
			deliveryData.drawings = drawings;
			deliveryData.customer = newCustomer;
			deliveryData.isTransportDeskMode = isTransportDeskMode;
			deliveryData.quotationNo = isQuotation ? order && order.orderNo : undefined;
			deliveryData.externalReference = externalReference;
			deliveryData.creditInfo = creditInfo;
			if (isCcdService) {
				deliveryData.orderCreationMethod = orderCreationMethod || "STORE";

				publishToPubSub(items, "Isom", totalPrice);

				return await createIsomOrder(items, deliveryData, totalFamilyPrice, totalPrice);
			} else {
				deliveryData.orderCreationMethod = orderCreationMethod || "STORE";

				publishToPubSub(items, "LocalCustomOrder", totalPrice);

				deliveryData.notifications = pickupNotification;

				return await createLocalCustomerOrder(items, deliveryData);
			}
		} else {
			publishToPubSub(items, "CCOrder", 0);

			return await createCCOrder(items, externalReference, pickupNotification, drawings);
		}
	}
};

const sendToGoogleEcommerce = (orderNo, orderType) => {
	try {
		let store = SalesCoworkerConfiguration.getStore();
		const state = store.getState();
		const { totalPrice, servicePrice } = getOrderAmountSummarySelector(state);
		const { cartController, hasServices } = state;
		const { items, bookedServiceProposal } = cartController;
		let { selectedPickingService = undefined } = bookedServiceProposal;
		let newservices = [];

		let delivery = hasServices ? servicePrice.totalServicePrice.Value : 0;
		let cartPrice = {
			total: totalPrice.Value,
			currency: totalPrice.Currency,
			delivery: delivery
		};

		if (!ObjectHelper.isNil(bookedServiceProposal)) {
			let { bookedServices = [], hasDelivery = false } = bookedServiceProposal || {};
			if (ObjectHelper.isNil(selectedPickingService)) {
				selectedPickingService = bookedServiceProposal.selectedPickingService || undefined;
			}

			if (hasServices) {
				newservices = bookedServices
					.filter(x => x !== undefined)
					.map(x => {
						return x;
					});
				if (hasDelivery && !selectedPickingService) {
					newservices.push(selectedPickingService);
				}
			}
			newservices = newservices
				.filter(x => x !== undefined)
				.map(x => {
					return x;
				});
		}
		GaHelper.ExecuteShoppingCartPayment(orderNo, items, cartPrice, [...newservices], orderType);
	} catch (error) {
		console.log(error);
	}
};

const createIsomOrder = async (items, salesRequestData, totalFamilyPrice, totalPrice) => {
	try {
		let isom = new IsomOrderCreationService();
		let order = await isom.createSomOrder({
			items: items,
			...salesRequestData,
			totalFamilyPrice: totalFamilyPrice,
			totalPrice: totalPrice
		});
		let reciepts =
			salesRequestData.isTransportDeskMode &&
			items.filter(itm => itm.importDetails && itm.importDetails.receiptReferenceNo).map(x => x.importDetails.receiptReferenceNo);
		const { isCashLine } = SalesCoworkerConfiguration.getUserConfig();
		let isomDelivery = salesRequestData.services.find(x => x.isCentralDelivery);

		AppLogger.info("CreateIsomOrder", "CreateIsomOrder", {
			OrderNo: order.orderNo,
			OrderNoSource: order.orderNoSource,
			NoOfItems: items.length,
			ExternalOrderKey: order.externalOrderKey,
			SalesOrderkey: order.salesOrderKey,
			IsCashLine: isCashLine,
			IsTransportDeskOrder: salesRequestData.isTransportDeskMode,
			Receipts: reciepts && Enumerable.from(reciepts).distinct().toArray(),
			DeliveryArrangmentId: isomDelivery && isomDelivery.deliveryArrangementsId
		});

		if (salesRequestData.isTransportDeskMode) {
			sendToGoogleEcommerce(order.orderNo, isCashLine ? "CashlineTransportDeskOrder" : "TransportDeskOrder");
		} else {
			sendToGoogleEcommerce(order.orderNo, "DistributionOrder");
		}

		if (salesRequestData.quotationNo) {
			let request = {
				quotation: {
					salesOrderRefNo: order.orderNo
				}
			};
			await QuotationWrapperService.updateQuotation(salesRequestData.quotationNo, request);
		}
		return Promise.resolve(order);
	} catch (error) {
		AppLogger.error(`CreateIsomOrder failed:${error.message}`, "CreateIsomOrder", {
			IsTransportDeskOrder: salesRequestData && salesRequestData.isTransportDeskMode,
			ErrorData: error.response && error.response.data,
			ExternalOrderKey: error.externalOrderKey,
			SalesOrderkey: error.salesOrderKey
		});
		return Promise.reject(error);
	}
};

const createLocalCustomerOrder = async (items, salesRequest) => {
	let request = {
		cartItems: items,

		...salesRequest
	};

	try {
		let lcdOrder = new SalesOrderService();
		let order = await lcdOrder.createStoreOrder(request);
		AppLogger.info("CreateSalesOrderAsync", "CreateSalesOrder", {
			OrderNo: order.orderNo,
			OrderNoSource: order.orderNoSource,
			NoOfItems: items.length
		});
		sendToGoogleEcommerce(order.orderNo, "SalesOrder");
		return Promise.resolve(order);
	} catch (error) {
		AppLogger.error(`CreateSalesOrder failed:${error.message}`, "CreateSalesOrder");
		return Promise.reject(error);
	}
};

const createShoppingList = async (items, externalReference, drawings) => {
	let shoppingListAndCcOrderService = new ShoppingListAndCcOrderService();
	try {
		let order = await shoppingListAndCcOrderService.createShoppingList(items, externalReference, drawings);
		AppLogger.info("SaveShoppingList", "CreateShoppingList", {
			OrderNo: order.orderNo,
			OrderNoSource: order.orderNoSource,
			NoOfItems: items.length
		});
		sendToGoogleEcommerce(order.orderNo, "ShoppingList");
		return Promise.resolve(order);
	} catch (error) {
		AppLogger.error(`ShoppingList failed:${error.message}`, "ShoppingList");
		return Promise.reject(error);
	}
};

const createCCOrder = async (items, externalReference, pickupNotification, drawings) => {
	let shoppingListAndCcOrderService = new ShoppingListAndCcOrderService();
	try {
		let order = await shoppingListAndCcOrderService.createCCOrderList(items, externalReference, pickupNotification, drawings);
		AppLogger.info("CreateCashAndCarryOrder", "CreateCashAndCarryOrder", {
			OrderNo: order.orderNo,
			OrderNoSource: order.orderNoSource,
			NoOfItems: items.length
		});
		sendToGoogleEcommerce(order.orderNo, "CashAndCarryOrder");
		return Promise.resolve(order);
	} catch (error) {
		AppLogger.error(`CreateCashAndCarryOrder failed:${error.message}`, "CreateCashAndCarryOrder");
		return Promise.reject(error);
	}
};

const cancelOrder = async reasonCode => {
	try {
		let store = SalesCoworkerConfiguration.getStore();
		const state = store.getState();
		const { cartController } = state;
		const { order } = cartController;
		AppLogger.info("CancellingOrder", "CancelOrder", {
			orderNo: order.orderNo
		});
		let orderModService = new OrderModificationService();
		await orderModService.cancelOrder(order, reasonCode);
		return Promise.resolve(true);
	} catch (error) {
		AppLogger.error(`CancelOrder failed:${error.message}`, "CancelOrder");
		return Promise.reject(error);
	}
};

const convertOrder = async () => {
	try {
		let store = SalesCoworkerConfiguration.getStore();
		const state = store.getState();
		const { cartController } = state;
		const { order, items } = cartController;
		let orderModService = new OrderModificationService();
		let newOrder = await orderModService.convertOrder(order);
		AppLogger.info("CreateCashAndCarryOrder", "CreateCashAndCarryOrder", {
			OrderNo: order.orderNo,
			OrderNoSource: order.orderNoSource,
			NoOfItems: items.length
		});
		return Promise.resolve(newOrder);
	} catch (error) {
		AppLogger.error(`CoverttoOrder failed:${error.message}`, "CovertOrder");
		return Promise.reject(error);
	}
};

const createQuotation = async quotation => {
	try {
		let store = SalesCoworkerConfiguration.getStore();
		const state = store.getState();
		const { cartController } = state;
		const { items, salesPriceData, hasServices } = cartController;
		const { timezoneId } = salesPriceData;
		const deliveryserviceInfo = getSalesRequestData(state);

		const newCustomer = { ...deliveryserviceInfo.customer };

		deliveryserviceInfo.customerBilling.addressContextType = "HOME_GRP1";

		let services = [];
		if (hasServices) {
			services = deliveryserviceInfo.services;
		}

		let { buCode, countryCode } = SalesCoworkerConfiguration.getUserConfig();
		let buArea = {
			buCode: buCode,
			buType: "STO",
			countryCode: countryCode
		};
		let customerData = {
			customer: newCustomer,
			customerBilling: deliveryserviceInfo.customerBilling
		};
		let response = await QuotationWrapperService.createQuotation({ ...quotation, timezoneId }, buArea, items, customerData, salesPriceData, services);
		return response;
	} catch (error) {
		console.log(error);
		return null;
	}
};

const cancelQuotation = async () => {
	try {
		let store = SalesCoworkerConfiguration.getStore();
		const state = store.getState();
		const { cartController } = state;
		const { order } = cartController;
		AppLogger.info("CancellingQuotation", "CancelQuotation", {
			orderNo: order.orderNo
		});
		await QuotationWrapperService.deleteQuotation(order.orderNo);
		return Promise.resolve(true);
	} catch (error) {
		AppLogger.error(`CancelQuotation failed:${error.message}`, "CancelQuotation");
		return Promise.reject(error);
	}
};

const updateQuotation = async () => {
	let store = SalesCoworkerConfiguration.getStore();
	const state = store.getState();
	const { cartController } = state;
	const { items, salesPriceData, order, hasServices } = cartController;

	let services = [];
	if (hasServices) {
		const deliveryserviceInfo = getSalesRequestData(state);
		services = deliveryserviceInfo.services;
	}
	let response = await QuotationWrapperService.updateQuoteItems(order.orderNo, items, services, salesPriceData);
	if (response) {
		let quotation = await QuotationWrapperService.viewQuotation(order.orderNo);
		return quotation;
	}
	return undefined;
};

const updateIsomOrder = async () => {
	let store = SalesCoworkerConfiguration.getStore();
	const state = store.getState();
	const { cartController } = state;
	const { items, salesPriceData, order, orderCreationMethod, isTransportDeskMode } = cartController;

	const { customer } = getCustomerProfileObject();

	const { totalPrice, totalFamilyPrice } = getOrderAmountSummarySelector(state);
	const deliveryserviceInfo = getSalesRequestData(state);

	const newCustomer = { ...customer };

	let deliveryData = { ...deliveryserviceInfo, salesPriceData };
	deliveryData.customer = newCustomer;
	deliveryData.isTransportDeskMode = isTransportDeskMode;
	deliveryData.orderCreationMethod = orderCreationMethod || "STORE";
	let orderModificationService = new IsomOrderModificationService();
	return await orderModificationService.updateIsomOrder({
		items,
		...deliveryData,
		totalFamilyPrice,
		totalPrice,
		order
	});
};

const checkOutService = {
	createOrder,
	convertOrder,
	cancelOrder,
	sendToGoogleEcommerce,
	createQuotation,
	cancelQuotation,
	updateQuotation,
	updateIsomOrder
};
export default checkOutService;

export const getSalesRequestData = ({ appController, localeController, cartController }) => {
	const { customerSchema } = appController;
	const { locale } = localeController;
	const { customerMeetingPoint, paymentType = "PAYMENT_IN_ADVANCE", questionnaires, bookedServiceProposal } = cartController;

	const { customer, customerBilling } = getCustomerProfileObject();
	let customerDelivery = { ...customer };
	customerDelivery.preferredLocale = customerDelivery.preferredLocale || locale;

	let customerBilInfo = createCustomerBilling(customerBilling, customerDelivery, customerSchema);
	customerBilInfo.preferredLocale = customerBilInfo.preferredLocale || locale;

	return {
		questionaireData: {
			deliveryQuestions: questionnaires.deliveryQuestions,
			serviceQuestions: questionnaires.serviceQuestions,
			questionaireAnswers: questionnaires.questionaireAnswers,
			selectedDeliveryQuestionnaireType: questionnaires.selectedDeliveryQuestionnaireType
		},
		activeZipCode: bookedServiceProposal.activeZipCode,
		selectedPickservice: bookedServiceProposal.selectedPickservice,
		services: (bookedServiceProposal && bookedServiceProposal.bookedServices) || [],
		customer: customerDelivery,
		customerBilling: customerBilInfo,
		customerMeetingPoint: customerMeetingPoint || undefined,
		paymentType: paymentType
	};
};
const createCustomerBilling = (customerBilling, customer, customerSchema) => {
	if (!customer.isAddedBillAddress && (customer.partyId || customer.partyId === undefined) && !customer.partyUId) {
		return { ...customer };
	}
	const { isMfsProfile } = customerSchema;
	let newCustomer = { ...customerBilling };
	let excludedList = [
		"ikeaFamilyNumber",
		"language",
		"taxIdType",
		"taxNo",
		"foreignCountry",
		"genderCode",
		"panNo",
		"organizationNo",
		"businessCardNo",
		"sezValidityFrom",
		"sezValidityTo",
		"sezType"
	];
	if (isMfsProfile) {
		for (let key in customer) {
			const propkey = key;
			if (excludedList.find(x => x === propkey)) {
				newCustomer[propkey] = customer[propkey];
			}
		}
		if (!newCustomer.preferredLocale) newCustomer.preferredLocale = customer.preferredLocale;
	}

	newCustomer.isBusinessCustomer = customer.isBusinessCustomer;
	newCustomer.countryCode = customer.countryCode;
	if (!newCustomer.language) newCustomer.language = customer.language;
	//newCustomer.preferredLocale = customer.preferredLocale;
	newCustomer.foreignAddressCountryCode = customerBilling.foreignAddressCountryCode;
	return newCustomer;
};
