import React from "react";
import styled from "styled-components";
import { ReactComponent as SaljaPlus } from "../static/salja-logo.svg";
import { colors, device } from "@coworker/sharedlibrary";

const addStyles = styledObj => {
	return styledObj.div`
    cursor: pointer;
    align-items: center;
    min-width: 55px;
    display: flex;

    color: ${colors.yellow};
    font-weight: bold;
    span {
      font-size: 16px;
      display: block;
      height: 30px;
      line-height: 30px;
      min-width: 165px;
      @media ${device.large} {
        display: none;
      }
    }

    svg {
      width: 30px;
      height: 30px;
      margin: -7px 13px 0 0;
      path {
        fill: #fff;
      }
    }
  `;
};

const Client = window["styled-components"] ? addStyles(window["styled-components"]) : addStyles(styled);

const Mfe = addStyles(styled);

const SaljaLogoFrontpage = props => {
	return props.client ? (
		<Client
			onClick={() => {
				props.clickFunction();
			}}
			{...props}
		>
			<SaljaPlus className="salja-plus" />
			<span>Sälja Go</span>
		</Client>
	) : (
		<Mfe
			onClick={() => {
				props.clickFunction();
			}}
			{...props}
		>
			<SaljaPlus className="salja-plus" />
			<span>Sälja Go</span>
		</Mfe>
	);
};

export default SaljaLogoFrontpage;
