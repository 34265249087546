import { ObjectHelper, GaHelper } from "@coworker/sharedlibrary";
import { useSelector } from "react-redux";
import React from "react";
import styled from "styled-components";
import rightIcon from "@ingka/ssr-icon/paths/chevron-right";
import { device } from "@coworker/sharedlibrary";
import { Container } from "../../../../components/sharedlibrary";
import SSRIcon from "@ingka/ssr-icon";
import { useInputPopup } from "../../../../components/inputPopup";

const CustomerContainer = styled.section`
	display: flex;
	padding: 16px 0px;
	flex-direction: column;
	min-height: 100px;
	border-top: 1px solid #dfdfdf;
	border-bottom: 1px solid #dfdfdf;
	cursor: ${props => (props.canEdit ? "pointer" : "initial")};
	@media ${device.medium} {
		border-top: none;
	}
`;

const SummaryTitle = styled.p`
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.71;
	letter-spacing: normal;
	color: #111111;
	margin-bottom: 0px;
`;
const CustomerInfoSection = styled.section`
	flex-basis: 100%;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.71;
	letter-spacing: normal;
	color: #666666;
	p {
		margin: 0 !important;
	}
`;

const CustomerInfo = props => {
	const { customer, isMfsProfile, canEdit } = props;
	const { getInput } = useInputPopup();
	const messages = useSelector(state => state.intl.messages);
	var fullName = null;
	if (customer) {
		fullName = `${customer.firstName || ""} ${customer.lastName || ""}`;
		if (customer.countryCode === "JP") {
			fullName = isMfsProfile
				? `${customer.phoneticName ? customer.phoneticName + " " : ""}${customer.lastName}`
				: `${customer.lastName} ${customer.firstName}`;
		}
	}
	var isCustomerInValid = true;
	if (ObjectHelper.isEmpty(customer.zipCode)) {
		isCustomerInValid = false;
	}
	async function showCustomerView(view) {
		await getInput("orderService", {
			viewState: view || "CUSTOMER",
			view: "SERVICE_VIEW"
		});
		GaHelper.SendEvent("ShoppingBag", "ChangeCustomerAdress", "Customer address change from cart");
	}
	if (!isCustomerInValid) {
		return "";
	}
	return (
		<CustomerContainer canEdit={canEdit} onClick={() => (canEdit ? showCustomerView() : () => {})}>
			<Container jcSBetween onClick={() => (canEdit ? showCustomerView() : () => {})}>
				<SummaryTitle>{messages.SG_LBL_ContactInformation || "Contact Information"}</SummaryTitle>
				{canEdit && <SSRIcon paths={rightIcon}></SSRIcon>}
			</Container>
			{props.children}
			<CustomerInfoSection>
				{!ObjectHelper.isNil(customer.email) && customer.email.length > 0 && <p>{customer.email}</p>}
				{!ObjectHelper.isNil(customer.mobilePhone) && customer.mobilePhone.length > 0 && <p>{customer.mobilePhone}</p>}
				{!ObjectHelper.isNil(fullName) && fullName.trim().length > 0 && <p>{fullName}</p>}
				<p>
					{customer.attention} {customer.careOf}
					{customer.address1}
				</p>
				<p>
					{customer.zipCode} {customer.city}
				</p>
			</CustomerInfoSection>
		</CustomerContainer>
	);
};

export default CustomerInfo;
