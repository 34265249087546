import React from "react";
import Button from "@ingka/button/Button";
import Modal, { Prompt, ModalFooter } from "@ingka/modal";
import { GUID } from "@coworker/sharedlibrary";

const focus = {
	returnFocus: false,
	autoFocus: true
};

const CreateGroupDialog = ({ onSubmit, onClose, title, subTitle, positiveText, negativeText }) => {
	const buttons = [
		{
			text: positiveText,
			type: "primary",
			onClick: () => {
				onSubmit(true);
			}
		},
		{
			text: negativeText,
			type: "secondary",
			onClick: () => {
				onSubmit(false);
			}
		}
	];

	return (
		<Modal
			visible={true}
			escapable={true}
			handleCloseBtn={() => {
				onClose();
			}}
			focusLockProps={focus}
		>
			<Prompt
				title={title}
				footer={
					<ModalFooter compact={false}>
						{buttons.map(button => {
							return <Button key={GUID.Guid()} {...button} />;
						})}
					</ModalFooter>
				}
			>
				{subTitle}
			</Prompt>
		</Modal>
	);
};

export default CreateGroupDialog;
