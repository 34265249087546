import React from "react";
import styled from "styled-components";

const addStyles = styledObj => {
	return styledObj.div.attrs(props => ({
		id: props.id
	}))`

  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  background: #f3f3f3;
  animation-timing-function: ease-out;
  background: linear-gradient(to right, #f3f3f3 10%, #ecebeb 18%, #f3f3f3 33%);
  background-size: 400% 400%;

  @keyframes placeHolderShimmer {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: -100% 0;
    }
  }
`;
};

const Client = window["styled-components"] ? addStyles(window["styled-components"]) : addStyles(styled);

const Mfe = addStyles(styled);

const AnimatedPlaceholder = props => {
	return props.client ? <Client {...props} /> : <Mfe {...props} />;
};

export default AnimatedPlaceholder;
