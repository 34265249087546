import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { DfpKey } from "@coworker/sharedlibrary";

const ContentTxt = styled.p`
	font-size: 14px;
	line-height: 24px;
	color: #666666;
`;

const ContactTxt = styled.p`
	font-size: 14px;
	line-height: 21px;
	color: #484848;
	padding-top: 30px;
`;

const SuperUser = () => {
	const { preferences } = useSelector(state => state.appController);
	const [superUserMail, setSuperUserMail] = useState("");
	const { messages } = useSelector(state => state.intl);

	const getSupHeader = () => {
		return `${messages.SG_LBL_HelpSectionContactSubTitle || "Help with questions related to country-specification"}`;
	};

	React.useEffect(() => {
		const mail = preferences.find(x => x !== undefined && x.name === DfpKey.SUPERUSER_MAIL);

		try {
			if (mail !== undefined && mail !== null && mail.value) {
				setSuperUserMail(mail.value);
			}
		} catch {
			//
		}
	}, []);

	return (
		<>
			<ContentTxt data-testid="superuserHeader">{getSupHeader()}</ContentTxt>
			<ContactTxt data-testid="superuserEmail">{superUserMail}</ContactTxt>
		</>
	);
};

export default SuperUser;
