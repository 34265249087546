import { device, eventsToDispatch, dispatchEvent } from "@coworker/sharedlibrary";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import Button from "@ingka/button/Button";
import React from "react";
import styled from "styled-components";
import AddServiceButton from "./addServiceButton";
import { PageContainer, Container, NoResultsView } from "../../../components/sharedlibrary";
import { getStoreConfigSelector } from "../../../utils/shellObjectService";
import ArticleBarCodeScanner from "./articleBarCodeScanner";
import { isArticleScan } from "../../../helpers/cartHelper";

const ButtonContainer = styled(Container)`
	& > button:first-child {
		margin-right: 20px;
		@media ${device.small} {
			margin-right: 0;
		}
	}
`;
const EmptyCartContainer = styled.div`
	width: 100%;
	margin-top: 100px;
	display: flex;
	flex-direction: column;
	align-self: center;
	button {
		align-self: center;
		margin-top: 20px;
		width: 182px;
	}
	strong {
		align-self: center;
	}
	p {
		align-self: center;
		margin-top: 15px;
	}
`;
const EmptyCartView = () => {
	const { messages } = useSelector(state => state.intl);
	const { isCashLine } = getStoreConfigSelector();
	const dispatch = useDispatch();
	return (
		<PageContainer>
			<EmptyCartContainer>
				<NoResultsView />
				{isCashLine ? (
					<>
						<strong>{messages.SG_LBL_CashLine || "CashLine"}</strong>
						<p>{messages.SG_LBL_CashLineScanMessage}</p>
						<ArticleBarCodeScanner
							onScan={e => {
								let article = isArticleScan(e);
								if (article) {
									dispatchEvent(eventsToDispatch.ADD_ITEM_TO_CART, {
										retailItem: {
											ItemId: article,
											ItemType: "ART"
										},
										qty: 1
									});
								}
							}}
						></ArticleBarCodeScanner>
					</>
				) : (
					<>
						<strong>{messages.SG_LBL_EmptyShoppingBag || "Empty Shopping Bag"}</strong>
						<p>{messages.SG_LBL_AddCartItemsMessage || "Please add items to proceed."}</p>
						<ButtonContainer jcCenter fdRow fdColMob>
							<Button
								data-testid="startbrowseebtn"
								text={messages.SG_BTN_StartBrowsing || "Start browsing"}
								type="primary"
								onClick={() => dispatch(push("/"))}
							/>

							<AddServiceButton messages={messages}></AddServiceButton>
						</ButtonContainer>
					</>
				)}
			</EmptyCartContainer>
		</PageContainer>
	);
};
export default EmptyCartView;
