import React from "react";
import styled from "styled-components";

const addStyles = styledObj => {
	return styledObj.label.attrs(props => ({
		id: props.id
	}))`
  cursor: ${props => props.cursor || "default"} !important;
  width: ${props => props.width || "100%"};
  line-height: ${props => props.lineheight || "20px"};
  color: ${props => props.color || "#202020"} !important;
  word-wrap: ${props => props.wordwrap};
  min-width: ${props => props.minwidth};
  padding: ${props => props.padding || "0px"};
  margin-top: ${props => props.margintop || "0px"};
  margin-left: ${props => props.marginleft || "0px"};
  margin-bottom: ${props => props.marginbottom || "0px"};
  text-align: ${props => props.textalign || "Left"};
  font-size: ${props => props.fontsize || "16px"} !important;
  font-weight: ${props => props.fontweight};
`;
};

const Client = window["styled-components"] ? addStyles(window["styled-components"]) : addStyles(styled);

const Mfe = addStyles(styled);

const Label = props => {
	return props.client ? <Client {...props} /> : <Mfe {...props} />;
};

export default Label;
