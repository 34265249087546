import { clearCart, updateCartTotal } from "./../../../store/cart/cartActions";
import { appactionCreators } from "./../../../store/shell/shellAction";
import { useCanSaveOrderToQuotation, useQuotation } from "../../../hooks/useQuotation";
import { useDispatch, useSelector } from "react-redux";

import AppLogger from "../../../logger/appLogger";
import Button from "@ingka/button";
import { DfpKey, OrderTypes } from "@coworker/sharedlibrary";
import React from "react";
import checkOutService from "../../../service/cart/checkOutService";
import { push } from "connected-react-router";
import styled from "styled-components";
import useMicrofrontend from "../../../hooks/useMicroFrontend";
import { useCartModelPopup } from "./modelPopup";
import { useQuotationPopup } from "../../quotation";
import { useSalesPerson } from "../../../hooks/useSalesPerson";
import IsomOrderModificationService from "../../../service/cart/isomOrderModificationService";
import { getStoreConfigSelector } from "../../../utils/shellObjectService";
import SalesCoworkerConfiguration from "../../../store/salesCoworkerConfiguration";
const OrderButtonsSection = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	.btn {
		margin-bottom: 24px;
		border-radius: 25px;
		width: 100%;
	}
`;
const GuestMode = styled.h6`
	margin-top: 10px;
	margin-bottom: 0px;
	color: red;
	font-size: 16px;
	text-align: center;
`;
const ModifyOrderCheckOutOption = ({ disabled, canCreateSalesOrder }) => {
	const [salesMode] = useSalesPerson();
	let guestMode = !salesMode;
	let config = getStoreConfigSelector();
	let { messages } = useSelector(state => state.intl);
	var { appId, appUrl, appName } = config.environment.microapps.recentOrderApp;
	const isRestrictedInMarket = SalesCoworkerConfiguration.isRestrictedInMarket();
	const { recentorder } = useMicrofrontend(appId, appUrl, appName);

	const dispatch = useDispatch();
	const quotationAllowed = useQuotation();
	const canSaveOrderToQuotation = useCanSaveOrderToQuotation();
	const { getQuotationModel } = useQuotationPopup();
	const { getCartModel } = useCartModelPopup();

	const { cartId, order, bookedServiceProposal, isTransportDeskMode } = useSelector(state => state.cartController);

	function showBusyLoading(loading) {
		dispatch(appactionCreators.showBusyLoading(loading));
	}
	function showNotification(level, message, detail) {
		dispatch(appactionCreators.showNotification(level, message, detail));
	}
	function cancelOrder(reasonCode) {
		showBusyLoading(true);
		checkOutService
			.cancelOrder(reasonCode)
			.then(() => {
				showBusyLoading(false);
				dispatch(clearCart());
			})
			.catch(error => {
				showBusyLoading(false);
				AppLogger.error(`CancelOrder failed:${error.message}`, "CancelOrder");
				if (error.statusCode === 400 && error.detail === "Order is locked by another user") {
					showNotification("error", messages.SG_LBL_FailedToCancelOrderLockedbyAnotherUser || "failed to cancel order as its locked by another user");
				} else {
					showNotification("error", messages.SG_LBL_FailedToCancelOrder || "failed to cancel order");
				}
			});
	}

	function convertOrder() {
		showBusyLoading(true);

		checkOutService
			.convertOrder()
			.then(order => {
				showBusyLoading(false);
				recentorder && recentorder.removeDraftOrder(cartId);
				openOrderConfirmationView(order);
				dispatch(clearCart());
			})
			.catch(error => {
				showBusyLoading(false);
				AppLogger.error(`CoverttoOrder failed:${error.message}`, "CovertOrder");
				showNotification("error", messages.SG_LBL_FailedConvertOrder || "failed to convert to order");
			});
	}

	async function showQuotationView(quoteDays) {
		var result = await getQuotationModel("quotationDialog", {
			messages: messages,
			quoteDays: quoteDays
		});
		if (result && result.type === OrderTypes.Quotation) {
			createQuotation(result.quotation);
		}
	}

	function createQuotation(quotation) {
		showBusyLoading(true);
		checkOutService
			.createQuotation(quotation)
			.then(response => {
				var quotation = response.quotations.length > 0 && response.quotations[0];
				return {
					...quotation,
					quotationRefNo: quotation.quotationReferenceNumber
				};
			})
			.then(quot => {
				showBusyLoading(false);
				recentorder && recentorder.removeDraftOrder(cartId);
				openOrderConfirmationView(quot, true);
			})
			.catch(() => {
				showBusyLoading(false);
				showNotification("error", "failed to create sales order");
			});
	}

	async function openOrderConfirmationView(order, isQuotion) {
		if (isQuotion) {
			dispatch(
				push(`/confirmation/quotation/${order.orderNo}`, {
					exitToHome: true,
					customer: order.customer
				})
			);
		} else {
			dispatch(
				push(`/confirmation/order/${order.orderNo}`, {
					exitToHome: true,
					customer: order.customer
				})
			);
		}
	}

	async function showCancelOrderDialog() {
		var result = await getCartModel("orderReasonDialog", {
			reasonKey: DfpKey.REASON_CODES_CANCEL_SALES_ORDER,
			reasonTitle: messages.SG_LBL_ChooseCancelOrderReasonCode || "Choose oder cancellation reason",
			title: messages.SG_LBL_CancelOrderMessage || "Are you sure you want to cancel your order?"
		});
		if (result) {
			cancelOrder(result);
		}
	}
	async function showOrdeUpdateReasonDialog() {
		var result = await getCartModel("orderReasonDialog", {
			reasonKey: DfpKey.REASON_CODES_UPDATE_ORDER,
			reasonTitle: messages.SG_LBL_ChooseOrderUpdateReason || "Choose order modify reason",
			title: messages.SG_LBL_OrderUpdateMessage || "Are you sure you done with modify order?"
		});
		if (result) {
			await updateIsomOrder(result);
		}
	}
	async function updateIsomOrder(reasonCode) {
		dispatch(updateCartTotal(isTransportDeskMode)).then(async () => {
			showBusyLoading(true);
			let orderModificationService = new IsomOrderModificationService();
			try {
				if (order.modifiedTimeWindow && order.shouldUpdate && !order.modifiedOrder) {
					await orderModificationService.rescheduleIsomDelivery(bookedServiceProposal, reasonCode);
					showBusyLoading(false);
					openOrderConfirmationView(order, false);
				} else if (order.modifiedOrder && order.shouldUpdate) {
					await checkOutService.updateIsomOrder(bookedServiceProposal, reasonCode);
					showBusyLoading(false);
					openOrderConfirmationView(order, false);
				}
			} catch (exception) {
				if (exception.statusCode === 400) {
					showNotification("error", "Failed to reschedule");
				}
				if (exception.error) {
					showNotification("error", exception.error.title, `${exception.error.message} ${exception.error.detail}`);
				}
			}
			showBusyLoading(false);
		});
	}
	if (isRestrictedInMarket) {
		return <GuestMode>{messages.SG_LBL_Restricted_Mode || "You are not allowed to create order in this market. Please check with super user"}</GuestMode>;
	}
	return (
		<>
			{!guestMode && (
				<OrderButtonsSection className="mrg-top-25">
					{order.convertToOrderLink && (
						<Button
							disabled={!canCreateSalesOrder || guestMode || disabled}
							type="emphasised"
							text={messages.SG_BTN_ConvertOrder}
							onClick={() => convertOrder()}
						/>
					)}
					{(order.shouldUpdate || order.modifiedOrder) && (
						<Button type="emphasised" text={messages.SG_BTN_Save || "Save"} onClick={() => showOrdeUpdateReasonDialog()} />
					)}
					{quotationAllowed && canSaveOrderToQuotation && (
						<Button
							disabled={guestMode || disabled}
							type="secondary"
							text={messages.SG_LBL_SaveQuotation || "Save as quotation"}
							onClick={() => showQuotationView({ day: "15" })}
						/>
					)}
					<Button disabled={!order.canCancelOrder} type="secondary" text={messages.SG_BTN_CancelOrder} onClick={() => showCancelOrderDialog()} />
				</OrderButtonsSection>
			)}
		</>
	);
};
export default ModifyOrderCheckOutOption;
