import TestModel from "./testModel";
import RemoveCartItemDialog from "./dialogs/removeCartItemDialog";
import NewShoppingBagDialog from "./dialogs/newShoppingBagDialog";
import CreateGroupDialog from "./dialogs/createGroupDialog";
import ConvertOrderDialog from "./dialogs/convertOrderDialog";
import OrderAttributesDialog from "./dialogs/orderAttributesDialog";
import PriceAndDiscountDialog from "./dialogs/priceanddiscountDialog";
import OrderReasonDialog from "./dialogs/orderReasonDialog";
import OrderPickupNotifyDialog from "./dialogs/orderPickupNotifyDialog";
import ShoppingListConfirmationPopup from "./dialogs/shoppingListConfirmationPopup";
import NotAllowedToAddItemsDialog from "./dialogs/notAllowedToAddItemsDialog";
import SelectCashLinePopup from "./dialogs/selectCashLinePopup";

//popupcomponents should be refered here
const types = {
	testModel: {
		component: TestModel
	},
	removeCartItemDialog: {
		component: RemoveCartItemDialog
	},
	newShoppingBag: { component: NewShoppingBagDialog },
	createGroup: { component: CreateGroupDialog },
	orderAttributesDialog: { component: OrderAttributesDialog },
	priceAndDiscountDialog: { component: PriceAndDiscountDialog },
	convertOrderDialog: { component: ConvertOrderDialog },
	orderReasonDialog: { component: OrderReasonDialog },
	orderPickupNotifyDialog: { component: OrderPickupNotifyDialog },
	shoppingListDialog: { component: ShoppingListConfirmationPopup },
	notAllowedToAddItemsDialog: { component: NotAllowedToAddItemsDialog },
	selectCashLinePopup: { component: SelectCashLinePopup }
};

export default types;
