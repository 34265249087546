import React from "react";

import { dispatchEvent, eventsToDispatch, getMessage } from "@coworker/sharedlibrary";

import Button from "@ingka/button";

// TODO: Turn this into a styled component when import setup is maintained
import "./style.scss";

const SalesMethods = {
	DDS: "DDS",
	FULL_SERVE: "FULL_SERVE",
	SELF_SERVE: "SELF_SERVE",
	SATELLITE: "SATELLITE",
	UNDEFINED: "UNDEFINED"
};

const LocationView = props => {
	const { disableForecast, messages, retailItem } = props;

	if (!retailItem) {
		return "";
	}

	const showForecast = async () => {
		if (!disableForecast) {
			dispatchEvent(eventsToDispatch.NAVIGATE, {
				view: "VIEW_SALES_LOCATION",
				retailItem: retailItem,
				isStock: false
			});
		}
	};

	if (retailItem.ItemType === "SPR") {
		return (
			<Button
				className="locationComponentButton"
				data-testid={"locationcomponent"}
				small={true}
				onClick={event => {
					event.stopPropagation();
					showForecast();
				}}
			>
				{messages.SG_LBL_Product}
			</Button>
		);
	}

	if (retailItem.storeInfo && retailItem.storeInfo.SalesLocations && retailItem.storeInfo.SalesLocations.length > 0) {
		const locations = retailItem.storeInfo.SalesLocations;
		const primarySalesLocation = locations.find(x => x.IsHomeBase) || locations[0];

		return (
			<Button
				className="locationComponentButton"
				data-testid={"locationcomponent"}
				small={true}
				onClick={event => {
					event.stopPropagation();
					showForecast();
				}}
			>
				{getSalesLocation(primarySalesLocation, retailItem.storeInfo.SalesMethodCode, messages)}
			</Button>
		);
	} else {
		return "";
	}
};

const getSalesLocation = (firstPrimary, salesMethod, messages) => {
	if (salesMethod === SalesMethods.SELF_SERVE) {
		const selfServe = firstPrimary.SelfServeSalesLocations;

		if (selfServe && selfServe.Descriptive === undefined) {
			return (
				<span>
					{selfServe.Aisle &&
						selfServe.Bin &&
						`${getMessage("SG_LBL_Aisle", messages, "Aisle")}: ${selfServe.Aisle} ${getMessage("SG_LBL_Bin", messages, "Bin")}: ${selfServe.Bin}`}
				</span>
			);
		}

		if (selfServe && selfServe.Descriptive) {
			return <span>{selfServe.Descriptive}</span>;
		}
	} else if (salesMethod === SalesMethods.FULL_SERVE) {
		const fullServe = firstPrimary.FullServeSalesLocation;

		if (fullServe) {
			return fullServe.descriptive;
		}
	} else if (salesMethod === SalesMethods.SATELLITE) {
		const satelite = firstPrimary.SatelliteSalesLocation;

		if (satelite) {
			return satelite.Department;
		}
	} else if (salesMethod === SalesMethods.DDS) {
		return "DDS";
	} else {
		return "";
	}
};

export default LocationView;
