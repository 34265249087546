import React from "react";
import { useSelector } from "react-redux";
import { Experiment, Variant } from "react-optimize";
import { ReactComponent as HelpIcon } from "./../../../static/_message-help-24.svg";
import { ReactComponent as HelpPlain } from "./../../../static/help-large.svg";

const HelpIconExperiment = ({ onHelpClick, IconButton, IconWrapper }) => {
	const { messages } = useSelector(state => state.intl);

	return (
		<Experiment id="NAa0q8TQTc2Z-l5Srs8OKw">
			<Variant default id="0">
				<IconWrapper>
					<IconButton onClick={onHelpClick} fontsize={"24px"} data-testid="helpsection">
						<HelpIcon title={messages.SG_LBL_HelpTitle || "Help"}></HelpIcon>
					</IconButton>
				</IconWrapper>
			</Variant>
			<Variant id="1">
				<IconWrapper>
					<IconButton onClick={onHelpClick} fontsize={"24px"} data-testid="helpsection">
						<HelpPlain></HelpPlain>
					</IconButton>
				</IconWrapper>
			</Variant>
		</Experiment>
	);
};

export default HelpIconExperiment;
