import { CartModelPopup, CartModelPopupProvider } from "../containers/cart/components/modelPopup";
import { InputPopup, InputPopupProvider } from "../components/inputPopup";
import { ModelPopup, ModelPopupProvider } from "../components/modelPopup";
import { QuotationPopup, QuotationPopupProvider } from "../containers/quotation";

import { IntlProvider } from "react-intl";
import React from "react";
import SalesCoworkerConfiguration from "../store/salesCoworkerConfiguration";
import SharedComponetHolder from "./sharedComponentHolder";
import ShellEventReceiver from "../components/microfrontends/shellEventReceiver";
import { useSelector } from "react-redux";

const ExternalMain = () => {
	SalesCoworkerConfiguration.setIsExternal(true);
	const { messages, locale } = useSelector(state => state.intl);
	return (
		<>
			<IntlProvider locale={locale} messages={messages} defaultLocale="en">
				<CartModelPopupProvider>
					<QuotationPopupProvider>
						<ModelPopupProvider>
							<InputPopupProvider>
								<InputPopup></InputPopup>
								<ModelPopup></ModelPopup>
								<QuotationPopup></QuotationPopup>
								<CartModelPopup></CartModelPopup>
								<ShellEventReceiver></ShellEventReceiver>
								<SharedComponetHolder></SharedComponetHolder>
							</InputPopupProvider>
						</ModelPopupProvider>
					</QuotationPopupProvider>
				</CartModelPopupProvider>
			</IntlProvider>
		</>
	);
};
export default ExternalMain;
