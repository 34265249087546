import { APIEndpoints, SharedConfiguration } from "@coworker/sharedlibrary";
import { OrderTypes } from "@coworker/sharedlibrary";
import { URI, httpService } from "@coworker/sharedlibrary";
import { getCustomerProfileObject } from "../../utils/shellObjectService";
import SalesCoworkerConfiguration from "../../store/salesCoworkerConfiguration";
class ShoppingListAndCcOrderService {
	constructor() {
		this.config = SalesCoworkerConfiguration.getUserConfig();
	}
	createCCOrderList = async (items, externalReference, pickupNotification, drawings) => {
		let { customer } = getCustomerProfileObject();
		let data = this.getRequestData(items);
		if (pickupNotification && pickupNotification.length > 0) {
			data.notifications = pickupNotification;
		}
		if (drawings && drawings.length > 0) {
			data.drawings = drawings;
		}

		let baseUrl = SharedConfiguration.getApiBaseUrl("cartservices");
		let url = new URI(`${baseUrl}${APIEndpoints.orderCapture}`, true).fill({
			orderType: "cc-order"
		});
		var order = await httpService.post(url, {
			body: {
				request: data,
				externalReference: externalReference
			},
			headers: this.config.headers
		});
		if (customer && customer.email) {
			order.customer = {
				email: customer.email,
				mobilePhone: customer.mobilePhone
			};
		} else {
			order.customer = {
				email: "",
				mobilePhone: ""
			};
		}

		order.orderItems = items;
		order.type = OrderTypes.CCorder;
		return order;
	};

	createShoppingList = async (items, externalReference, drawings) => {
		let { customer } = getCustomerProfileObject();
		let data = this.getRequestData(items);
		if (drawings && drawings.length > 0) {
			data.drawings = drawings;
		}
		let baseUrl = SharedConfiguration.getApiBaseUrl("cartservices");
		let url = new URI(`${baseUrl}${APIEndpoints.orderCapture}`, true).fill({
			orderType: "shopping-list"
		});
		var order = await httpService.post(url, {
			body: {
				request: data,
				externalReference: externalReference
			},
			headers: this.config.headers
		});
		if (customer && customer.email) {
			order.customer = {
				email: customer.email,
				mobilePhone: customer.mobilePhone
			};
		} else {
			order.customer = {
				email: "",
				mobilePhone: ""
			};
		}
		order.orderItems = items;
		order.type = OrderTypes.ShoppingList;
		return order;
	};

	getRequestData(cartItems) {
		const { buCode, backEndLocale, userId } = this.config;
		var data = {
			buCode: buCode,
			locale: backEndLocale,
			userId: userId,
			items: [],
			planners: []
		};
		// console.log("cartItems", cartItems);
		let seqNo = 1;
		cartItems.forEach(cartItem => {
			if (cartItem.isCombination) {
				cartItem.childItems.forEach(cItem => {
					const item = this.getItem(cItem, seqNo);
					seqNo = seqNo + 1;
					data.items.push(item);
				});
			} else {
				const item = this.getItem(cartItem, seqNo);
				seqNo = seqNo + 1;
				data.items.push(item);
			}
		});
		var plnNo = 1;
		cartItems.forEach(cartItem => {
			if (cartItem.itemType === "PLANNER") {
				var planner = {
					plannerName: cartItem.retailItem.Name,
					itemReferences: [],
					plannerId: cartItem.retailItem.ItemId,
					seqNo: plnNo
				};
				plnNo = plnNo + 1;
				cartItem.childItems.forEach(cItem => {
					var item = data.items.find(x => x.itemReferenceId === cItem.id);
					planner.itemReferences.push({
						itemReferenceId: cItem.id,
						seqNo: item.seqNo
					});
				});
				data.planners.push(planner);
			}
		});
		return data;
	}

	getItem(cartItem, seqNo) {
		const item = {
			itemId: cartItem.retailItem.ItemId,
			type: cartItem.retailItem.ItemType,
			quantity: cartItem.quantity,
			measurement: cartItem.retailItem.ItemUnitCode || "PIECES",
			itemReferenceId: cartItem.id,
			seqNo: seqNo
		};
		return item;
	}
}

export default ShoppingListAndCcOrderService;
