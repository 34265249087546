import ReactGA from "react-ga";

const GaHelper = {
	InitGA: id => {
		ReactGA.initialize(id);
		ReactGA.plugin.require("ec");
		ReactGA.ga("require", "ec");
	},
	SendEvent: (category, action, label, value) => {
		ReactGA.event({
			category: category,
			action: action,
			label: label,
			value: value || 1
		});
	},
	AddProductToEcommerce: (item, quantity) => {
		try {
			var payload = {
				id: item.ItemId,
				name: item.Name,
				brand: "IKEA",
				variant: item.Colour || item.ProductType,
				category: item.ProductType,
				price: item.price.Value,
				quantity: quantity || 1,
				position: 1
			};
			ReactGA.ga("ec:addProduct", payload);
		} catch (error) {
			//
		}
	},
	RemoveProduct: item => {
		try {
			var payload = {
				id: item.ItemId,
				name: item.Name,
				brand: "IKEA",
				variant: item.Colour,
				category: item.ProductType,
				price: item.price.Value,
				quantity: 1,
				position: 1
			};

			ReactGA.ga("ec:addProduct", payload);
		} catch (error) {
			//
		}
	},
	AddService: item => {
		try {
			var payload = {
				id: item.id,
				serviceId: item.id,
				serviceName: item.serviceName,
				name: item.serviceName,
				brand: "IKEA",
				category: item.isProvidedService ? "Service" : "Delivery",
				price: item.price.priceInclTax || item.price.priceExclTax,
				quantity: 1,
				position: 1
			};

			ReactGA.ga("ec:addProduct", payload);
			ReactGA.ga("ec:setAction", "add");
			GaHelper.SendEvent("Add Service to cart", "click", `add to cart`);
		} catch (error) {
			//
		}
	},
	RemoveService: item => {
		try {
			var payload = {
				id: item.id,
				serviceId: item.id,
				serviceName: item.serviceName,
				name: item.serviceName,
				brand: "IKEA",
				category: item.isProvidedService ? "Service" : "Delivery",
				price: item.price.priceInclTax || item.price.priceExclTax,
				quantity: 1,
				position: 1
			};

			ReactGA.ga("ec:addProduct", payload);
			ReactGA.ga("ec:setAction", "remove");
			GaHelper.SendEvent("Remove Service to cart", "click", `remove from cart`);
		} catch (error) {
			//
		}
	},
	ExecuteEcommerceAction: (action, payload) => {
		try {
			if (payload) {
				ReactGA.ga("ec:setAction", action, payload);
			} else {
				ReactGA.ga("ec:setAction", action);
			}
		} catch (error) {
			//
		}
	},
	ExecuteShoppingCartPayment: (orderNo, cartItems, cartPrice, services, orderType = "ShoppingList") => {
		const { total, currency, delivery } = cartPrice;
		ReactGA.ga("set", "currencyCode", currency);
		ReactGA.ga("set", "dimension5", orderType);
		try {
			for (var i = 0; i < cartItems.length; i++) {
				var product = cartItems[i];
				ReactGA.ga("ec:addProduct", {
					id: product.itemId,
					name: product.name,
					category: product.retailItem.ProductType,
					brand: "IKEA",
					variant: product.retailItem.Colour,
					price: product.price.Value,
					quantity: product.quantity
				});
			}
			for (var j = 0; j < services.length; j++) {
				var item = services[j];
				var payload = {
					id: item.id,
					dimension4: item.id,
					dimension3: item.serviceName,
					name: item.serviceName,
					brand: "IKEA",
					category: item.isProvidedService ? "Service" : "Delivery",
					price: item.price.priceInclTax || item.price.priceExclTax,
					quantity: 1,
					position: 1
				};
				ReactGA.ga("ec:addProduct", payload);
			}
		} catch (error) {
			console.log(error);
		}
		ReactGA.ga("ec:setAction", "checkout", {
			step: 1, // A value of 1 indicates this action is first checkout step.
			option: orderType // Used to specify additional info about a checkout stage, e.g. payment method.
		});

		ReactGA.ga("ec:setAction", "checkout", {
			step: 2,
			option: orderType
		});
		ReactGA.ga("send", "pageview", "/cart");
		ReactGA.ga("send", "event", "Checkout", "Option", {
			hitCallback: function () {
				ReactGA.ga("ec:setAction", "checkout_option", {
					step: 2,
					option: orderType
				});
				ReactGA.ga("send", "pageview", "/cart");
				try {
					for (var i = 0; i < cartItems.length; i++) {
						var product = cartItems[i];
						ReactGA.ga("ec:addProduct", {
							id: product.itemId,
							name: product.name,
							category: product.retailItem.ProductType,
							brand: "IKEA",
							variant: product.retailItem.Colour,
							price: product.price.Value,
							quantity: product.quantity
						});
					}
					for (var j = 0; j < services.length; j++) {
						var item = services[j];
						ReactGA.ga("ec:addProduct", {
							id: item.id,
							dimension4: item.id,
							dimension3: item.serviceName,
							name: item.serviceName,
							brand: "IKEA",
							category: item.isProvidedService ? "Service" : "Delivery",
							price: item.price.priceInclTax || item.price.priceExclTax,
							quantity: 1,
							position: 1
						});
					}
				} catch (error) {
					console.log(error);
				}
				ReactGA.ga("ec:setAction", "purchase", {
					id: orderNo,
					affiliation: "IKEA STORE",
					revenue: total,
					shipping: delivery,
					dimension5: orderType
				});
				ReactGA.ga("send", "pageview", "/cart");
			}
		});
	},
	SendPageView: pageName => {
		ReactGA.set({ page: pageName });
		ReactGA.pageview(pageName);

		var runningPwa = window.matchMedia("(display-mode: standalone)").matches;
		if (runningPwa) {
			ReactGA.ga("send", "SaljaMobile", {
				appName: "SaljaGO",
				screenName: pageName
			});
		}
	},
	SetGaUserId: userId => {
		ReactGA.set({
			userId: userId
			// any data that is relevant to the user session
			// that you would like to track with google analytics
		});
		ReactGA.ga("send", "pageview", "/");
	},
	SendDimenstion: (key, value) => {
		ReactGA.ga("set", key, value);
	}
};
export default GaHelper;
