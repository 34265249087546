const SPR = "SPR";
const ART = "ART";
const GEN = "GEN";
const COMPLEMENTORY = "complementory";
const SIMILAR = "similar";
const GROUP = "GROUP";
export default {
	SPR,
	ART,
	GROUP,
	SIMILAR,
	GEN,
	COMPLEMENTORY
};
