import { GaHelper } from "@coworker/sharedlibrary";
import axios from "axios";

const imageAddr = `${window.location.origin}/assets/images/speedTest.png`;
const InitiateSpeedDetection = () => {
	DownloadConnectSpeed();
};

async function DownloadConnectSpeed() {
	try {
		let startTime, endTime;
		startTime = new Date().getTime();
		const { headers } = await axios({
			url: imageAddr,
			method: "GET",
			responseType: "stream"
		});

		const totalLength = headers["content-length"];
		endTime = new Date().getTime();
		let duration = (endTime - startTime) / 1000;
		let bitsLoaded = totalLength * 8;
		let speedBps = (bitsLoaded / duration).toFixed(2);
		let speedKbps = (speedBps / 1024).toFixed(2);
		let speedMbps = (speedKbps / 1024).toFixed(2);
		GaHelper.SendEvent("DeviceMetrics", "DownloadBandwidthSpeed", `${speedMbps} Mbps`, speedMbps);
	} catch (error) {
		//Ignore error
	}
}

export { InitiateSpeedDetection };
