class PriceModel {
	constructor(data) {
		for (var key in data) {
			if (Object.prototype.hasOwnProperty.call(data, key)) {
				const element = data[key];
				this[key] = element;
			}
		}
	}
}
export default PriceModel;
