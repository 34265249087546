import { GUID } from "@coworker/sharedlibrary";

export const convertTermToIndex = (term = "") => {
	if (term !== "") {
		term = term.trim();
		term = term.toLowerCase();
		term = term.replace(/\s/g, "-");
	}

	return term;
};

export const convertIndexToTerm = (index = "") => {
	if (index !== "") {
		index = index.replace(/-/g, " ");
		index = index.toUpperCase();
	}

	return index;
};

export const getTopMostAddedProducts = (mostAddedProducts, count = 8) => {
	let topMostAddedProducts = [];
	let topSorted = [];
	let counter = 0;

	for (const productId in mostAddedProducts) {
		topSorted.push([productId, mostAddedProducts[productId].count]);
	}

	topSorted.sort(function (a, b) {
		return b[1] - a[1];
	});

	topSorted.map(sorted => {
		if (counter < count) {
			topMostAddedProducts.push(sorted[0]);
			counter++;
		}
	});

	return topMostAddedProducts;
};

export const getTopSearchHistory = (searchHistory, count = 8) => {
	let topSearchHistory = [];
	let topSorted = [];
	let counter = 0;

	for (const term in searchHistory) {
		topSorted.push([term, searchHistory[term].count]);
	}

	topSorted.sort(function (a, b) {
		return b[1] - a[1];
	});

	topSorted.map(sorted => {
		if (counter < count) {
			topSearchHistory.push({
				name: convertIndexToTerm(sorted[0]),
				text: convertIndexToTerm(sorted[0]),
				title: "Seach History",
				hitype: "SearchHistory",
				id: GUID.Guid(),
				query: convertIndexToTerm(sorted[0]),
				data: {}
			});
			counter++;
		}
	});

	return topSearchHistory;
};
