import { RequestHelper } from "@coworker/sharedlibrary";
import { SET_USER } from "./types";
import AzureAuthService from "../../service/azureAuthService";
import { UserType } from "./reducer";

export const setUser = (user, userMode = UserType.SALESPERSON) => {
	return {
		payload: { user, userMode },
		type: SET_USER
	};
};

export const logOutUser = () => dispatch => {
	dispatch({
		payload: { undefined },
		type: SET_USER
	});
	var config = RequestHelper.getAuthConfig();
	var authService = new AzureAuthService(config.authConfig);
	localStorage.clear();
	authService.logOut();
	return Promise.resolve(true);
};
