import React, { useEffect } from "react";
import { Router, Switch } from "react-router";
import { setImportData, setImportScope } from "../../../store/import/actions";
import { useDispatch, useSelector } from "react-redux";

import AppLogger from "../../../logger/appLogger";
import { AppliedRoute } from "../../../components/AppliedRoute";
import { BackButton } from "./../../../components/Buttons/Buttons";
import { Container } from "../../../components/sharedlibrary";
import { HideBodyScrollStyle } from "./../../../globalStyle";
import { LocaleHelper } from "@coworker/sharedlibrary";
import MicroAutoCompleteView from "../../../components/microfrontends/microAutoCompleteView";
import MicroCombinationView from "../../../components/microfrontends/microCombinationView";
import { PagePaths } from "@coworker/sharedlibrary";
import { ProductInformationView } from "../../../components/microfrontends/productInformationView";
import SearchPageView from "../../../components/microfrontends/searchPageView";
import SharedOpenOrderView from "../sharedOrderView/sharedOpenOrderView";
import arrowBack from "@ingka/ssr-icon/paths/arrow-back";
import { getStoreConfigSelector } from "../../../utils/shellObjectService";
import { history } from "../../../store/history";
import importService from "../../service/importService";
import useMicrofrontend from "../../../hooks/useMicroFrontend";
import { useWindowEventListener } from "@coworker/sharedlibrary";

const {
	ExternalPlannerPagePath,
	ExternalPtagPagePath,
	ExternalKitchenPlannerPagePath,
	ExternalWebShoppingListPath,
	ExternalSearchPagePath,
	ExternalDetailPagePath,
	ExternalProductDetailPagePath
} = PagePaths;

const SharedImport = props => {
	const locationSearch = new URLSearchParams(window.location.search);
	const { externalPip } = props;
	const canShowSearch = !externalPip;
	const buCode = locationSearch.get("buCode");
	const { messages } = useSelector(state => state.intl);
	const { locale } = useSelector(state => state.localeController);
	const dispatch = useDispatch();
	const { sessionId } = useSelector(state => state.appController);
	let config = getStoreConfigSelector();

	const pipApp = config.environment.microapps.pipApp;
	useMicrofrontend(pipApp.appId, pipApp.appUrl, pipApp.appName);

	let itemsQuery = locationSearch.get("item");
	let quantity = locationSearch.get("quantity");
	let pipprops = {
		match: {
			params: { id: itemsQuery, quantity: quantity }
		}
	};
	const externalSearch = props.externalSearch;
	const recentOrderApp = config.environment.microapps.recentOrderApp;
	useMicrofrontend(recentOrderApp.appId, recentOrderApp.appUrl, recentOrderApp.appName);

	useWindowEventListener("IMPORT_ITEMS", event => {
		let importModel = event.detail.importData;
		if (importModel && importModel.isPlanner && importModel.data) {
			importModel.data.plannerItems = importModel.data.plannerItems.map(itm => {
				return {
					itemType: itm.itemType,
					itemNo: itm.itemNo,
					quantity: itm.itemQuantity
				};
			});
		}
		selectImport({
			searchData: importModel
		});
	});

	//eslint-disable-next-line
	let routeprops = {
		messages,
		locale,
		history,
		...props,
		selectImport,
		standaloneMode: true,
		isStandaloneMode: true
	};
	useEffect(() => {
		const countryCode = LocaleHelper.getCountryCode(locale);
		const language = LocaleHelper.getLanguageCode(locale);
		dispatch(setImportScope({ buCode, countryCode, language }));
		AppLogger.info(`Opened import view -${window.location.href}`, "OpenImport", {
			buCode,
			countryCode,
			language,
			Success: 200
		});
	}, [dispatch, buCode, locale]);

	async function selectImport(importdata) {
		importService.buildImportObject(importdata).then(result => {
			dispatch(setImportData(result));
			AppLogger.info(`Successfully imported`, "IMPORT_ITEMS", {
				HitType: importdata.searchData.hitType,
				...result
			});
			setTimeout(() => {
				broadCast(result);

				//Notify client
				console.log("IMPORT_READY");
			}, 200);
		});
	}

	function broadCast(result) {
		try {
			result.success = true;
			result.sessionId = sessionId;
			result.name = "import";
			// eslint-disable-next-line no-undef
			window.sendToNative({ data: result, componentName: "import" });
		} catch {
			//
		}
	}
	// 1104359950
	const escFunctionCallback = React.useCallback(e => {
		if (e.keyCode === 27) {
			console.log("IMPORT_CANCEL");
			window.sendToNative({ componentName: "import", action: "CANCEL" });
			AppLogger.info("Import view closed on EscapeKey event", "CloseImportView", {
				KeyInterept: "ESC",
				Success: 200
			});
		}
	}, []);
	useEffect(() => {
		document.addEventListener("keydown", escFunctionCallback, false);
		return () => {
			document.removeEventListener("keydown", escFunctionCallback, false);
		};
	}, []);

	return (
		<Container fdRow padding="20px 10px" width="100%">
			<Container fdCol width="100%">
				{canShowSearch && (
					<Container fdRow width="100%" jcSBetween>
						<BackButton history={history} ssrIcon={arrowBack} />
						<MicroAutoCompleteView width={"100%"} id="searchbox" externalSearch={externalSearch} externalImport={true}></MicroAutoCompleteView>
					</Container>
				)}
				<div className="routeArea">
					<HideBodyScrollStyle />
					<div
						style={{
							height: "90vh",
							overflow: "auto",
							paddingBottom: "80px"
						}}
					>
						<Router history={history}>
							<Switch>
								<AppliedRoute component={SharedOpenOrderView} path={"/external/import/order/:orderNo"} props={routeprops} />
								<AppliedRoute component={MicroCombinationView} path={ExternalPlannerPagePath} props={routeprops} />
								<AppliedRoute component={MicroCombinationView} path={ExternalPtagPagePath} props={routeprops} />
								<AppliedRoute props={routeprops} component={MicroCombinationView} path={ExternalKitchenPlannerPagePath} />
								<AppliedRoute props={routeprops} component={MicroCombinationView} path={ExternalWebShoppingListPath}></AppliedRoute>
								<AppliedRoute props={routeprops} component={SearchPageView} path={ExternalSearchPagePath}></AppliedRoute>

								<AppliedRoute props={routeprops} component={ProductInformationView} path={ExternalDetailPagePath} />

								<AppliedRoute
									props={{ ...routeprops, view: "pipexternal", ...pipprops }}
									component={ProductInformationView}
									path={ExternalProductDetailPagePath}
								/>
							</Switch>
						</Router>
					</div>
				</div>
			</Container>
		</Container>
	);
};
SharedImport.displayName = "SharedImport";
export default SharedImport;
