import { APIEndpoints, httpService } from "@coworker/sharedlibrary";
import SalesCoworkerConfiguration from "../store/salesCoworkerConfiguration";

const PubSubService = {
	publish: (type = undefined, userId = undefined, items = undefined, orderType = undefined, orderValue = undefined) => {
		let config = SalesCoworkerConfiguration.getUserConfig();
		httpService.post(`${APIEndpoints.pubSubPublish}/${type}`, {
			body: {
				userId,
				items,
				orderType,
				orderValue
			},
			headers: config.headers
		});

		return;
	}
};

export default PubSubService;
