import TestModel from "./testModel";
import ConfirmDialog from "./dialogs/confirmDialog";
import StockAndLocationDialog from "./dialogs/stockAndLocationDialog";

//popupcomponents should be refered here
const types = {
	testModel: {
		component: TestModel
	},
	confirmDialog: { component: ConfirmDialog },
	stockAndLocationDialog: { component: StockAndLocationDialog }
};

export default types;
