import PropTypes from "prop-types";
export const MeasureMentPropType = PropTypes.shape({
	TranslatedType: PropTypes.string,
	Unit: PropTypes.string,
	Value: PropTypes.number,
	ValueLabel: PropTypes.string
});
export const UnitFactortPropType = PropTypes.shape({
	UnitTextMetric: PropTypes.string,
	UnitPriceGroupCode: PropTypes.string,
	IsMultiPack: PropTypes.bool
});
export const PricePropType = PropTypes.shape({
	Type: PropTypes.string,

	Value: PropTypes.number,
	Currency: PropTypes.string,
	VatCode: PropTypes.string,
	VatRate: PropTypes.number
});
export const RetailItemPropType = PropTypes.shape({
	ItemId: PropTypes.string,
	Name: PropTypes.string,
	Images: PropTypes.array,
	AssembledMeasurements: PropTypes.shape({
		Height: MeasureMentPropType,
		Width: MeasureMentPropType,
		Depth: MeasureMentPropType
	}),
	Description: PropTypes.object,
	Colour: PropTypes.string,
	IsBreathTakingItem: PropTypes.bool,
	ItemMeasureReferenceTextImperial: PropTypes.string,
	ItemMeasureReferenceTextMetric: PropTypes.string,
	ItemType: PropTypes.string,
	ItemUnitCode: PropTypes.string,
	NumberOfChildArticles: PropTypes.number,
	NumberOfPackages: PropTypes.number,
	OnlineSellable: PropTypes.string,
	PaNumber: PropTypes.string,
	PredictedAssemblyTime: PropTypes.number,
	ProductType: PropTypes.string,
	RetailUnitPrices: PropTypes.array,
	UnitPriceFactor: UnitFactortPropType,
	description: PropTypes.string,
	familyPrice: PropTypes.any,
	isMetric: PropTypes.bool,
	key: PropTypes.string,
	price: PricePropType,
	storeInfo: PropTypes.any,
	priceFactor: UnitFactortPropType,
	availableStockToSell: PropTypes.number,
	Colours: PropTypes.array
});

class RetailItemModel {
	constructor(data) {
		for (var key in data) {
			if (Object.prototype.hasOwnProperty.call(data, key)) {
				const element = data[key];
				this[key] = element;
			}
		}
	}
}
RetailItemModel.propTypes = RetailItemPropType;
export default RetailItemModel;
