class Planner {
	constructor(response) {
		if (response === null) {
			return;
		}
		let plannerItems = response.plannerItems;
		if (!plannerItems) {
			let sections = response.plannerSections;
			sections.forEach(section => {
				section._embedded.plannerItems.forEach(pItem => {
					plannerItems.push({ ...pItem });
				});
			});
		}

		this.plannerType = response.plannerType;
		this.plannerSource = response.plannerSource;
		this.itemDescription = "";
		this.Name = response.plannerName;
		this.ItemId = response.plannerId;
		this.plannerCreationDate = response.plannerCreationDate;
		this.childItems = plannerItems;

		this.price = { Type: "NORMAL", Currency: "", Value: 0 };
		this.familyPrice = { Type: "FAMILY", Currency: "", Value: 0 };
		this.availableStockToSell = 0;
		this.isPlanner = true;
		this.deepLink = response.deepLink;
		this.shortLink = response.shortLink;
		this.drawingList = response.drawingList;
	}
}
export default Planner;
