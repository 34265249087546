import { useSelector, useDispatch } from "react-redux";
import Button from "@ingka/button";
import React, { useEffect } from "react";
import rightIcon from "@ingka/ssr-icon/paths/chevron-right";
import SSRIcon from "@ingka/ssr-icon";
import styled from "styled-components";

import { getDefaultOrderMethodSelector } from "./../../../store/cart/cartSelectors";
import { getMessage } from "@coworker/sharedlibrary";
import { OrderMethodTransKeys } from "./../../../constants/orderMethodTransKeys";
import { useCartModelPopup } from "./modelPopup";
import { setOrderCreationMethod } from "./../../../store/cart/cart.thunks";
import { getCurrentBuCode } from "../../../store/salesCoworkerConfiguration";

const OrderMethodButton = styled(Button)`
	width: 100%;
	* {
		cursor: pointer !important;
	}

	&.btn {
		border-radius: 0;
	}

	span {
		padding: 0;
		&:hover {
			background: white;
		}
	}
`;

const OrderMethodSection = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	text-align: left;
	align-items: center;

	p {
		line-height: 1.71;
		margin-bottom: 0 !important;
	}

	.instore {
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		color: #484848;
	}
`;

const SummaryTitle = styled.p`
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.71;
	letter-spacing: normal;
	color: #111111;
	margin=bottom: 0px;
`;

const OrderAttributesSelection = ({ enabled }) => {
	const { messages } = useSelector(state => state.intl);
	const { getCartModel } = useCartModelPopup();
	const dispatch = useDispatch();

	const buCode = getCurrentBuCode();
	const { orderCreationMethod, customerMeetingPoint, selectedCashLine, order } = useSelector(state => state.cartController);

	const defaultOrderMethod = useSelector(state => getDefaultOrderMethodSelector(state));

	async function showOrderMethod(event) {
		event.stopPropagation();
		await getCartModel("orderAttributesDialog");
	}

	useEffect(() => {
		if (orderCreationMethod === undefined) {
			dispatch(setOrderCreationMethod(defaultOrderMethod));
		}
	}, []);

	return (
		<OrderMethodButton type="tertiary" disabled={!enabled} onClick={showOrderMethod} data-testid="ordermethodbtn">
			<OrderMethodSection>
				<div>
					<SummaryTitle>{getMessage("SG_LBL_OrderAttributes", messages, "Order attribues")}</SummaryTitle>
					<p className="instore">
						{buCode +
							", " +
							messages[
								OrderMethodTransKeys.find(t => t.includes(orderCreationMethod)) || `SG_LBL_SALES_ORDER_CREATION_METHOD_${orderCreationMethod}`
							] || ""}
						{order && order.orderReference && order.orderReference.customerMeetingPointName
							? ` , ${order.orderReference.customerMeetingPointName} `
							: enabled && customerMeetingPoint && customerMeetingPoint.name && ` , ${customerMeetingPoint.name}`}{" "}
						{selectedCashLine ? `, ${selectedCashLine.name}` : ""}
					</p>
				</div>
				<SSRIcon paths={rightIcon}></SSRIcon>
			</OrderMethodSection>
		</OrderMethodButton>
	);
};

export default OrderAttributesSelection;
