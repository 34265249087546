import { setOrderCreationMethod, setBusinessUnitSelector, setPaymentType, setCreditInfo, setCashLine } from "./../../../../../store/cart/cart.thunks";
import { useSelector, useDispatch } from "react-redux";
import { getMessage } from "@coworker/sharedlibrary";

import Modal, { Sheets, ModalFooter, ModalBody } from "@ingka/modal";

import React from "react";
import Button from "@ingka/button";
import PaymentTypesSelection from "../../paymentTypes/paymentTypesSelection";
import MicroUserAccountView from "./../../../../../components/microfrontends/microUserAccountView";
import { appactionCreators } from "../../../../../store/shell/shellAction";
import { getCustomerProfileObject, getStoreConfigSelector } from "../../../../../utils/shellObjectService";
import CashLineSelection from "../../cashLIneSelection";

const OrderAttributesDialog = ({ onSubmit }) => {
	const { messages } = useSelector(state => state.intl);
	const { customer } = getCustomerProfileObject();
	const [credit, setCredit] = React.useState();
	const [canSave, setCanSave] = React.useState(true);
	let showPayment = true;
	const dispatch = useDispatch();
	const cusMeetingPoint = React.useRef();
	const selectedOrderCreationMethod = React.useRef();
	const paymentMentMethod = React.useRef();
	const selectedCashLine = React.useRef();
	const { isCashLine } = getStoreConfigSelector();
	function onSaveChanges() {
		if (!selectedOrderCreationMethod.current) {
			selectedOrderCreationMethod.current = "STORE";
		}
		if (cusMeetingPoint.current) {
			dispatch(setBusinessUnitSelector(cusMeetingPoint.current));
		}

		if (selectedOrderCreationMethod.current) {
			dispatch(setOrderCreationMethod(selectedOrderCreationMethod.current));
		}

		if (paymentMentMethod.current) {
			dispatch(setPaymentType(paymentMentMethod.current));
			if (paymentMentMethod.current === "PAYMENT_IN_INVOICE") {
				if (!credit || !credit.creditExpiry) {
					dispatch(
						appactionCreators.showNotification(
							"error",
							messages.SG_LBL_CreditExpiryDate || "Please select card expiry month and year",
							messages.SG_LBL_InvoiceReferrnceMissing || "Please select card expiry month and year"
						)
					);
					return;
				}
				if (!credit || !credit.invoiceRef1) {
					dispatch(
						appactionCreators.showNotification(
							"error",
							messages.SG_LBL_InvoiceReferrnceMissing || "Please enter invoice reference details",
							messages.SG_LBL_InvoiceReferrnceMissing || "Please enter invoice reference details"
						)
					);
					return;
				}
				if (customer && customer.creditBillingInfo) {
					dispatch(
						setCreditInfo({
							...credit,
							...customer.creditBillingInfo
						})
					);
				}
			}
		}
		if (selectedCashLine.current) {
			dispatch(setCashLine(selectedCashLine.current));
		}
		onSubmit(true);
	}
	return (
		<Modal
			visible
			focusLockProps={focus}
			handleCloseBtn={() => {
				onSubmit(false);
			}}
		>
			<Sheets
				footer={
					<ModalFooter>
						<Button
							disabled={!canSave}
							data-testid="attributeSave"
							type="primary"
							text={getMessage("SG_BTN_Save", messages, "Save")}
							onClick={() => onSaveChanges()}
						/>
					</ModalFooter>
				}
			>
				<ModalBody>
					<MicroUserAccountView
						view="userstoreattributesview"
						autoSave={false}
						onBusinessChange={meetingPoint => {
							cusMeetingPoint.current = meetingPoint;
						}}
						onOrderCreationMethodChage={orderMethod => {
							selectedOrderCreationMethod.current = orderMethod;
						}}
					></MicroUserAccountView>
					{isCashLine && (
						<CashLineSelection
							selectedCallback={cline => {
								selectedCashLine.current = cline;
							}}
						></CashLineSelection>
					)}
					{showPayment && (
						<PaymentTypesSelection
							credit={credit}
							setCredit={setCredit}
							setCanSave={setCanSave}
							onPaymentMethodChange={payment => {
								paymentMentMethod.current = payment;
							}}
						></PaymentTypesSelection>
					)}
				</ModalBody>
			</Sheets>
		</Modal>
	);
};
export default OrderAttributesDialog;
const focus = {
	returnFocus: false,
	autoFocus: true
};
