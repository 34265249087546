import { getUA, isMobileOnly } from "react-device-detect";
const BrowserHelper = {
	IsIE: () => {
		// Internet Explorer 6-11
		const isIE = /*@cc_on!@*/ false || !!document.documentMode;

		return isIE;
	},
	IsEdge: () => {
		// Internet Explorer 6-11
		const isIE = /*@cc_on!@*/ false || !!document.documentMode;

		// Edge 20+
		const isEdge = !isIE && !!window.StyleMedia;

		return isEdge;
	},
	IsRDT: () => {
		return getUA.toUpperCase().includes("CT40") || getUA.toUpperCase().includes("D75E");
	},
	IsDesktop: () => {
		return !isMobileOnly;
	},
	IsMobile: () => {
		return isMobileOnly;
	},

	getBrowserName: () => {
		try {
			if (false || !!document.documentMode) return "IE";

			if (false || (!!document.documentMode && !!window.StyleMedia)) return "Edge";

			if (!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)) return "chrome";

			// Opera 8.0+
			if (!!window.opr || !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0) return "opera";

			// Firefox 1.0+
			if (typeof InstallTrigger !== "undefined") return "Firefox";

			// Safari 3.0+ "[object HTMLElementConstructor]"
			if (
				/constructor/i.test(window.HTMLElement) ||
				(function (p) {
					return p.toString() === "[object SafariRemoteNotification]";
				})(!window["safari"] || typeof safari !== "undefined")
			)
				return "Safari";

			// Chrome 1 - 79
			if (!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)) return "Chrome";

			// Edge (based on chromium) detection

			if (!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime) && navigator.userAgent.indexOf("Edg") != -1) return "EdgeChromium";
		} catch (error) {
			//ignore error
		}

		return "";
	}
};
export default BrowserHelper;
