import { SET_IMPORT_DATA, SET_IMPORT_SCOPE, SET_SEARCH_DATA } from "./types";

export const setImportData = data => {
	return {
		type: SET_IMPORT_DATA,
		payload: data
	};
};

export const setImportScope = data => {
	return {
		type: SET_IMPORT_SCOPE,
		payload: data
	};
};

export const setSearchData = data => {
	return {
		type: SET_SEARCH_DATA,
		payload: data
	};
};

export const setSearch = data => async dispatch => {
	dispatch({
		type: SET_SEARCH_DATA,
		payload: data
	});
	return Promise.resolve();
};
