import React, { useState } from "react";
import styled from "styled-components";

import FormField from "@ingka/form-field";
import InputField from "@ingka/input-field";

import { List } from "../../components/sharedlibrary";

const QuoteDetailSection = styled(List)`
	&.quote-detail-section {
		width: 320px;
		margin-top: 50px;
		margin-bottom: 20px;
	}
`;

const InputContainer = styled(InputField)`
	label {
		font-size: 14px !important;
	}
`;

const QuoteDescriptionView = ({ messages, onQuoteTextChange }) => {
	const [quoteName, setQuoteName] = useState(undefined);

	const quoteProptext = {
		autoComplete: "off",
		defaultValue: "",
		id: "quoteNameText",
		label: messages.SG_LBL_QuoteName || "Quote name",
		onBlur: e => {
			onQuoteTextChange && onQuoteTextChange(e.target.value);
			setQuoteName(e.target.value);
		},
		req: "*",
		type: "text"
	};

	const validationError = {
		msg: messages.SG_LBL_EnterQuoteName || "Please enter a quote name"
	};

	return (
		<QuoteDetailSection className="quote-detail-section">
			<h6 className={"quotation-title"}>{messages.SG_LBL_QuoteDetails}</h6>
			<FormField shouldValidate={!quoteName} validation={validationError}>
				<InputContainer {...quoteProptext} className={`mrg-top-15`} />
			</FormField>
		</QuoteDetailSection>
	);
};

export default QuoteDescriptionView;
