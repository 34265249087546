import React from "react";
import styled from "styled-components";

const addStyles = styledObj => {
	return styledObj.div`
  display: block;
  margin: 0 auto;
  max-width: 100%;
  padding-left: 0px;
  padding-right: 0px;

  .toggle-hide-button {
    background: none;
    border: none;
    text-decoration: underline;
    font-size: 14px;
    padding: 0;
  }
`;
};

const Client = window["styled-components"] ? addStyles(window["styled-components"]) : addStyles(styled);

const Mfe = addStyles(styled);

const PageContainer = props => {
	return props.client ? <Client {...props} /> : <Mfe {...props} />;
};

export default PageContainer;
