export const ADD_TO_CART = "ADD_TO_CART";

export const ADD_ITEM_TO_CART = "ADD_ITEM_TO_CART";

export const ADD_GROUP_TO_CART = "ADD_GROUP_TO_CART";

export const REMOVE_ITEM = "REMOVE_ITEM";

export const INCREMENT_QUANTITY = "INCREMENT_QUANTITY";

export const DECREMENT_QUANTITY = "DECREMENT_QUANTITY";

export const UPDATE_QUANTITY = "UPDATE_QUANTITY";

export const UPDATE_ITEM = "UPDATE_ITEM";

export const ADD_SHIPPING = "ADD_SHIPPING";

export const ADD_SERVICE = "ADD_SERVICE";

export const CREATE_ORDER = "CREATE_ORDER";

export const CREATE_SHOPPINGLIST = "CREATE_SHOPPINGLIST";

export const SHOW_LOADING = "SHOW_LOADING";

export const UPDATEFULLITEM = "UPDATEFULLITEM";

export const CLEAR_CART = "CLEAR_CART";

export const UPDATE_CART_ITEM_STOCK = "UPDATE_CART_ITEM_STOCK";

export const SET_ORDER_TO_ACTIVE = "SET_ORDER_TO_ACTIVE";

export const COVERT_ITEM_TO_GROUP = "COVERT_ITEM_TO_GROUP";

export const UPDATE_ITEM_GROUP = "UPDATE_ITEM_GROUP";

export const UPDATE_ITEMS = "UPDATE_ITEMS";

export const UPDATE_ALL_ITEMS = "UPDATE_ALL_ITEMS";

export const ADD_LOADING = "ADD_LOADING";

export const UPDATE_CART_PRICE_DATA = "UPDATE_CART_PRICE_DATA";

export const UPDATE_QUOTATION = "UPDATE_QUOTATION";

export const REMOVE_ADDED_ITEM = "REMOVE_ADDED_ITEM";

export const SET_ORDER_CREATION_METHOD = "SET_ORDER_CREATION_METHOD";

export const SET_CART_CURRENCY = "SET_CART_CURRENCY";

export const SET_KITCHEN_ITEMS_IN_ORDER = "SET_KITCHEN_ITEMS_IN_ORDER";
export const SET_DRAWINGS = "SET_DRAWINGS";
export const SET_CUSTOMER_MEETING_POINT = "SET_CUSTOMER_MEETING_POINT";

export const SET_CART_DISCOUNTS = "SET_CART_DISCOUNTS";
export const SET_PAYMENT_TYPE = "SET_PAYMENT_TYPE";

export const SET_ORDER_MODE = "SET_ORDER_MODE"; //SALESDESK || TRANSPORTDESK

export const SET_SALES_EVENT_TOPIC = "SET_SALES_EVENT_TOPIC";

export const CONVERT_ORDER_TODRAFT = "CONVERT_ORDER_TODRAFT";

export const SET_BOOKED_SERVICE_PROPOSAL = "SET_BOOKED_SERVICE_PROPOSAL";

export const CLEAR_CART_SERVICES = "CLEAR_CART_SERVICES";

export const SET_CUSTOMER_INFORMATION = "SET_CUSTOMER_INFORMATION";

export const SET_ORDER_MODIFIED_STATE = "SET_ORDER_MODIFIED_STATE";

export const SET_CREDIT_INFO = "SET_CREDIT_INFO";

export const UPDATE_CREDIT_INFO = "UPDATE_CREDIT_INFO";

export const SET_CASH_LINE = "SET_CASH_LINE";

export const CLEAR_EXTERNAL_REFERENCE = "CLEAR_EXTERNAL_REFERENCE";
