import { useSelector } from "react-redux";
import React from "react";
import Modal, { Prompt, ModalFooter } from "@ingka/modal";
import Button from "@ingka/button";

import { GUID } from "@coworker/sharedlibrary";
import CashLineSelection from "../../cashLIneSelection";

const SelectCashLinePopup = ({ onSubmit, onClose }) => {
	const { messages } = useSelector(state => state.intl);
	const { selectedCashLine } = useSelector(state => state.cartController);

	function getModelButtons() {
		return [
			{
				disabled: selectedCashLine === undefined,
				text: messages.SG_BTN_Save,
				type: "primary",
				onClick: () => {
					onSubmit(true);
				}
			}
		];
	}

	return (
		<Modal
			visible={true}
			escapable={true}
			handleCloseBtn={() => {
				onClose();
			}}
			focusLockProps={focus}
		>
			<Prompt
				title={messages.SG_LBL_Select_CashLine || "Please select the cashline"}
				footer={
					<ModalFooter compact={false}>
						{getModelButtons().map(button => {
							return <Button key={GUID.Guid()} {...button} />;
						})}
					</ModalFooter>
				}
			>
				<CashLineSelection></CashLineSelection>
			</Prompt>
		</Modal>
	);
};

export default SelectCashLinePopup;
const focus = {
	returnFocus: false,
	autoFocus: true
};
