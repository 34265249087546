import React from "react";
import { useIdleTimer } from "react-idle-timer";

import { useModelPopup } from "../components/modelPopup";
import AppLogger from "../logger/appLogger";
const AppIdleCheck = () => {
	const { getModel } = useModelPopup();
	const handleOnIdle = async () => {
		AppLogger.info("User is idle", "USER_IDLE");
		// eslint-disable-next-line no-undef
		if (process.env.NODE_ENV === "production") {
			setTimeout(() => {
				getModel("userIdleDialog");
			}, 100);
		}
	};

	const handleOnActive = () => {
		reset();
	};
	const { reset } = useIdleTimer({
		timeout: 10800000,
		onIdle: handleOnIdle,
		onActive: handleOnActive,
		onAction: handleOnActive,
		debounce: 500
	});
	return <div></div>;
};
export default AppIdleCheck;
