import { DfpKey, SharedConfiguration, getMessage } from "@coworker/sharedlibrary";

import store from "../../../../store/store";
import { getSelectedDeliveryMethodIds, hasPaytoIkeaServiceMethods } from "./../../../../store/cart/cartSelectors";

function getPaymentTypes() {
	const state = store.getState();
	const { cartController, intl } = state;
	const { messages } = intl;
	let isTransportDeskMode = cartController.items.find(x => x.importDetails) !== undefined;
	let deliveryMethods = getSelectedDeliveryMethodIds(state);
	let hasPaytoIkeaServices = hasPaytoIkeaServiceMethods(state);
	let paymentTypesList = [
		{
			id: "PAYMENT_IN_ADVANCE",
			name: getMessage("SG_LBL_PaymentOption1", messages, "Pay in Advance")
		}
	];
	if (deliveryMethods.length === 0) {
		return paymentTypesList;
	}
	const { preferences } = SharedConfiguration.getUserConfig();
	const allowedpayondeliverypreference = storePreference(preferences, DfpKey.ALLOWED_PAYMENT_DELIVERY_CODES) || [...deliveryMethods];

	const ispayondeliveryallowed = storePreference(preferences, DfpKey.ENABLE_CASH_ON_DELIVERY);

	const payondeliveryallowed = ispayondeliveryallowed === "true";

	const allowedcashoncollectpreference = storePreference(preferences, DfpKey.ALLOWED_DELIVERY_SERVICES_FOR_CASH_ON_COLLECT) || [...deliveryMethods];

	const iscashoncollectallowed = storePreference(preferences, DfpKey.ENABLE_CASH_ON_COLLECT);

	let canPayOnDelivery = !isTransportDeskMode && deliveryMethods && allowedpayondeliverypreference && payondeliveryallowed;

	if (canPayOnDelivery) {
		const found = deliveryMethods.some(r => allowedpayondeliverypreference.includes(r));

		if (found && !hasPaytoIkeaServices) {
			paymentTypesList.push({
				id: "PAYMENT_IN_DELIVERY",
				name: getMessage("SG_LBL_PaymentOption2", messages, "Pay on Delivery")
			});
		}
	}

	let canPayOnCollect = !isTransportDeskMode && deliveryMethods && allowedcashoncollectpreference && iscashoncollectallowed === "true";

	if (canPayOnCollect) {
		const found = deliveryMethods.some(r => allowedcashoncollectpreference.includes(r));

		if (found) {
			paymentTypesList.push({
				id: "PAYMENT_ON_COLLECT",
				name: getMessage("SG_LBL_PaymentOption3", messages, "Pay on collect")
			});
		}
	}
	return paymentTypesList;
}

function storePreference(preferences, prefCode) {
	let pref = preferences && preferences.find(pre => pre.name === prefCode);
	return pref && pref.value;
}

export default getPaymentTypes;
