import { StorageHelper, RequestHelper, APIEndpoints, httpService } from "@coworker/sharedlibrary";

export const AppConfigService = {
	getAuthConfig: async () => {
		try {
			let existingConfig = RequestHelper.getAuthConfig();

			let config = await httpService.get(APIEndpoints.authConfig);
			if (config.authConfig) {
				if (existingConfig && existingConfig.authConfig.client_id !== config.authConfig.client_id) {
					StorageHelper.clearAllFromStorage();
				}
				if (config.authConfig) RequestHelper.setAuthConfig(config);
				return Promise.resolve(config);
			} else {
				return Promise.reject({
					error: "server not running or failed to contact"
				});
			}
		} catch (error) {
			console.error(error);
			return Promise.reject({
				error: "server not running or failed to contact"
			});
		}
	}
};
