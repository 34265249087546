import { useSelector } from "react-redux";

import React from "react";
import { getStoreConfigSelector } from "../../utils/shellObjectService";
import useMicrofrontend from "./../../hooks/useMicroFrontend";

const MicroBrowseView = ({ location, history }) => {
	const config = getStoreConfigSelector();
	const { items } = useSelector(state => state.cartController);

	const { appId, appUrl, appName } = config.environment.microapps.pipApp;

	const { isLoaded, pip } = useMicrofrontend(appId, appUrl, appName);

	React.useEffect(() => {
		pip && pip.setCartItems(items);
	}, [items]);

	React.useEffect(() => {
		if (!pip) return;
		config.view = "browse";
		config.location = location;
		config.navigationAction = history.action;
		pip.SetSharedConfiguration(config);

		const { render, unMount } = pip;

		render(`browse-${appId}`, config);

		return () => {
			unMount(`browse-${appId}`);
		};
	}, [isLoaded, location]); // eslint-disable-line

	if (!isLoaded) return null;
	if (!pip) return <div />;

	return <div id={`browse-${appId}`} />;
};

export default MicroBrowseView;
