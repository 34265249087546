import TestPrompt from "./testPrompt";
import MicroOrderServicesView from "../microfrontends/microOrderServicesView";

//popupcomponents should be refered here
const types = {
	test: {
		component: TestPrompt
	},
	orderService: {
		component: MicroOrderServicesView
	}
};

export default types;
