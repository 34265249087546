import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

const ContentTxt = styled.p`
	font-size: 14px;
	line-height: 24px;
	color: #666666;
`;

const KnowledgeBase = () => {
	const { messages } = useSelector(state => state.intl);

	const getSupHeader = () => {
		return `${
			messages.SG_LBL_HelpSectionKnowledgeBaseSubTitles ||
			"Collection of information about SÄLJA GO that you may be interested in. Please click on link below to know more: "
		}`;
	};

	return (
		<>
			<ContentTxt data-testid="superuserHeader">{getSupHeader()}</ContentTxt>
			<div className="LinkOpen">
				<a href="https://iweof.sharepoint.com/teams/o365g_saljagowiki_irssemal" target="_blank" rel="noreferrer">
					<br />
					https://iweof.sharepoint.com/teams/
					<br />
					o365g_saljagowiki_irssemal
				</a>
			</div>
		</>
	);
};

export default KnowledgeBase;
