import { GaHelper, RequestHelper } from "@coworker/sharedlibrary";
import { LocaleHelper } from "@coworker/sharedlibrary";

import { appactionCreators } from "../store/shell/shellAction";

const LoadAppAndUserConfiguration = async ({ userInfo, userType, home, changeLocale, getTranslations, setUser, dispatch }) => {
	GaHelper.SendDimenstion("dimension6", userType);
	changeLocale(userInfo.primaryLocale);

	if (home.preferences && home.preferences.length === 0) await home.getappPreferrences(userInfo.buCode, userInfo.countryCode);
	if (!home.storeInfo) await home.getStoreInfo(userInfo.buCode, true);

	GaHelper.SendEvent("UserLocation", "Country", LocaleHelper.getCountryName(userInfo.countryCode));
	GaHelper.SendDimenstion("dimension1", userInfo.countryCode);
	changeLocale(userInfo.primaryLocale);

	GaHelper.SendEvent("UserLocation", "BuCode", userInfo.buCode);
	GaHelper.SendDimenstion("dimension2", userInfo.buCode);

	getTranslations(userInfo.primaryLocale).then(() => {
		dispatch(
			appactionCreators.setHomeContext({
				...home
			})
		).then(() => {
			setUser(RequestHelper.getLoggedInUser(), userType);
		});
	});
};
export default LoadAppAndUserConfiguration;
