import React, { useEffect } from "react";
import CustomerView from "../components/microfrontends/customerView";
import MicroOrderServicesView from "../components/microfrontends/microOrderServicesView";
import CartView from "../containers/cart/cartView";
import SharedImport from "./components/sharedImport/sharedImport";

const SharedComponetHolder = () => {
	useEffect(() => {
		const isLocalhost = window.location.hostname === "localhost";

		if (isLocalhost) {
			localStorage.setItem("devtools", "true");
			localStorage.setItem("import-map-override:customerProfileApp", "http://localhost:4002");
		}
	}, []);
	if (window.location.href.includes("search")) {
		return <SharedImport externalSearch={true}></SharedImport>;
	} else if (window.location.href.includes("productdetail") || window.location.href.includes("detail")) {
		return <SharedImport externalSearch={false} externalPip={true}></SharedImport>;
	} else if (window.location.href.includes("import")) {
		return <SharedImport externalSearch={false}></SharedImport>;
	} else if (window.location.href.includes("customer")) {
		return <CustomerView isStandalone={true}></CustomerView>;
	} else if (window.location.href.includes("orderservices")) {
		return <MicroOrderServicesView isExternal={true} view={"EXTERNAL_SERVICE_VIEW"} viewState={"BEFOREPROPOSAL"}></MicroOrderServicesView>;
	} else if (window.location.href.includes("reverseflow")) {
		return <CartView></CartView>;
	} else {
		return <div>{"No component"}</div>;
	}
};

export default SharedComponetHolder;
