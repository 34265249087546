import React from "react";
import styled from "styled-components";

const addStyles = styledObj => {
	return styledObj.div`
  display: flex;
  cursor: ${props => props.cursor || "default"}!important;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: ${props => props.margintop};
  padding: ${props => props.padding};
  margin-right: ${props => props.marginright};
  margin-left: ${props => props.marginleft};
  min-width: ${props => props.minwidth};
  max-width: ${props => props.maxwidth};
  ${props => props.position && `position:${props.position}`};
  ${props => props.width && `width:${props.width}`};
`;
};

const Client = window["styled-components"] ? addStyles(window["styled-components"]) : addStyles(styled);

const Mfe = addStyles(styled);

const List = props => {
	return props.client ? <Client {...props} /> : <Mfe {...props} />;
};

export default List;
