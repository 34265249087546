import React from "react";
import { FormattedNumber } from "react-intl";
import styled from "styled-components";

const PriceSection = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
	margin: 10px 0px;

	p {
		color: ${props => (props.isFamily ? "#007dc7" : props.bold ? props.color || "#484848" : "#111111")} !important;
		font-size: ${props => (props.bold ? "16px" : "14px")} !important;
		font-weight: normal !important;
		font-stretch: normal !important;
		font-style: normal;
		font-weight: ${props => (props.bold ? "bold" : "normal")}!important;
		letter-spacing: normal;
		line-height: 1.5;
	}

	.sub-title {
		display: block;
	}
`;

const PriceStyle = styled.div`
  color: ${props => (props.isFamily ? "#007dc7" : "#484848")} !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal;
  font-weight: ${props => props.fontweight || "normal"};
  letter-spacing: normal;
  line-height: 1.5;
}
`;

const formatProps = {
	style: "currency"
};

const hasPrice = price => {
	return price && price.Currency && price.Currency !== "" && price.Value && !isNaN(price.Value);
};

const SummaryPriceControl = ({ title, bold, price, container, minimumFractionDigits, isFamily, subTitle, testid, color }) => {
	const PriceContainer = container || PriceStyle;

	if (!price || !hasPrice(price)) {
		return "";
	}

	return (
		<PriceSection bold={bold} color={color} data-testid={testid} isFamily={isFamily}>
			<p>
				{title}
				{subTitle && <span className="sub-title">{subTitle}</span>}
			</p>
			<PriceContainer>
				<FormattedNumber {...formatProps} currency={price.Currency} minimumFractionDigits={minimumFractionDigits || 0} value={price.Value} />
			</PriceContainer>
		</PriceSection>
	);
};

export { SummaryPriceControl };
