import "@ingka/core/dist/style.css";
import "@ingka/typography/dist/style.css";
import "../App.scss";

import { CartModelPopup, CartModelPopupProvider } from "./cart/components/modelPopup";
import { DfpKey, GaHelper, JWTHelper, LayoutLeftBarWidth, RequestHelper, getMessage, mediaQuery } from "@coworker/sharedlibrary";
import { InputPopup, InputPopupProvider } from "../components/inputPopup";
import { ModelPopup, ModelPopupProvider } from "../components/modelPopup";
import { QuotationPopup, QuotationPopupProvider } from "./quotation";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import AppIdleCheck from "../components/appIdleCheck";
import AzureAuthService from "../service/azureAuthService";
import CustomerView from "./../components/microfrontends/customerView";
import Hyperlink from "@ingka/hyperlink";
import { InitiateSpeedDetection } from "../helpers/speedTestHelper";
import { IntlProvider } from "react-intl";
import MorphingChair from "../components/morphingChair";
import NavBar from "../components/navigation/navBar";
import OrderModificationPopup from "../components/shell/orderModificationPopup";
import OseMenu from "../components/navigation/oseMenu";
import Routes from "../Routes";
import SalesCoworkerConfiguration from "../store/salesCoworkerConfiguration";
import ShellEventReceiver from "../components/microfrontends/shellEventReceiver";
import TopBanner from "../components/shell/TopBanner";
import TopHeader from "../components/shell/topHeader";
import { appactionCreators } from "../store/shell/shellAction";
import { bindActionCreators } from "redux";
import packageJson from "../../package.json";
import { push } from "connected-react-router";
import { setCartCurrency } from "./../store/cart/cart.thunks";
import styled from "styled-components";
import { updateMfeConfigs } from "./../utils/shellObjectService";
import useFetchPreference from "../hooks/useFetchPreference";

const AppLayout = styled.main`
	height: 100%;
	min-height: 100%;
	padding: 0px;
	/* iOS Safari 11.4+, Safari 11.1+, Chrome 69+, Opera 56+ */
	padding-left: calc(env(safe-area-inset-left));
	/* iOS Safari 11.2, Safari 11 */
	padding-right: calc(constant(safe-area-inset-right));
	/* iOS Safari 11.4+, Safari 11.1+, Chrome 69+, Opera 56+ */
	padding-right: calc(env(safe-area-inset-right));
	padding-bottom: calc(constant(safe-area-inset-bottom));
	/* iOS Safari 11.4+, Safari 11.1+, Chrome 69+, Opera 56+ */
	padding-bottom: calc(env(safe-area-inset-bottom));
`;

const MainContainer = styled.div`
	display: flex;
	min-height: 100%;
	flex-direction: row;
	height: 100%;
	padding-top: ${props => (props.topBannerHeight ? props.topBannerHeight + "px" : "0")};
	@media ${mediaQuery.mediumPortrait} {
		flex-direction: column;
	}
	${({ active }) =>
		active &&
		`
  display: none;
`}
`;

const Footer = styled.footer`
	padding: 20px;
	height: 50px;
	align-self: center;
	align-items: center;
	text-align: center;
	@media ${mediaQuery.largeLandscape} {
		margin-bottom: 5px;
		padding: 0;
		height: auto;
		.pkg-version {
			display: block;
		}
	}
`;

const LeftNavBar = styled.div`
	display: flex;
	width: ${LayoutLeftBarWidth};
	flex-direction: column;
	justify-content: space-between;
	border-right: 1px solid #dfdfdf;
	min-height: 100vh;
	position: fixed;
	z-index: 1;
	@media ${mediaQuery.mediumPortrait} {
		display: none;
	}
	@media ${mediaQuery.largeLandscape} {
		width: 70px;
	}
	@media ${mediaQuery.smallLandscape} {
		display: none;
	}
	${({ active }) =>
		active &&
		`
    display: none;
`}
`;

const BottomNavBar = styled.div`
	position: sticky;
	height: 50px;
	width: 100%;
	display: none;
	@media ${mediaQuery.mediumPortrait} {
		display: block;
	}
	@media ${mediaQuery.smallLandscape} {
		position: absolute;
		bottom: 0;
		display: block;
	}
	${({ active }) =>
		active &&
		`
    display: none;
`}
`;

const ContentContainer = styled.div`
	width: 100%;
	min-height: 100%;
	padding-left: ${LayoutLeftBarWidth};
	@media ${mediaQuery.mediumPortrait} {
		width: 100%;
		padding-left: 0px;
	}
	@media ${mediaQuery.largeLandscape} {
		padding-left: 70px;
	}
	@media ${mediaQuery.smallLandscape} {
		padding-left: 0px;
	}
`;

const RouteArea = styled.div`
	height: 100%;
	min-height: 100%;
	.routeArea {
		height: 100%;
		min-height: 100%;
		padding-top: 72px;
		overflow-y: auto;
	}
	@media ${mediaQuery.mediumPortrait} {
		padding-bottom: 50px;
		.routeArea {
			height: auto;
			overflow-y: hidden;
			padding-bottom: 90px;
		}
	}
	padding-top: calc(env(safe-area-inset-top));
`;

const FirstTabContainer = styled.div`
	a {
		position: absolute;
		top: -40px;
		z-index: 99;
	}
	a:focus {
		top: 1%;
		left: 1%;
		a > div {
			opacity: 1;
		}
		& {
			padding: 10px;
			background-color: white;
			border: 1px solid;
		}
	}
`;

const Main = props => {
	let isRefreshingToken = false;
	let timerId;
	const dispatch = useDispatch();
	const currency = useFetchPreference(DfpKey.PRIMARY_CURRENCY, "iSell");

	let { guestMode } = SalesCoworkerConfiguration.getUserConfig();
	const [topBannerHeight, setTopBannerHeight] = useState(false);

	function handleFocus(e) {
		document.getElementById("searchField").focus();
		e.preventDefault();
		e.stopPropagation();
	}

	const cusRef = React.useRef();

	useEffect(() => {
		if (currency) {
			dispatch(setCartCurrency(currency));
		}
	}, [currency]);

	useEffect(() => {
		GaHelper.SetGaUserId(props.analyticsUid);

		GaHelper.SendEvent("PwaStatus", "AppMode", window.matchMedia("(display-mode: standalone)").matches ? "true" : "false");
		InitiateSpeedDetection();
		setRefreshInterval(6000);
		props.showorHideAppHeader(true);
		initDevTools();
	}, []);

	function setRefreshInterval(time) {
		if (timerId) {
			clearInterval(timerId);
		}
		let isUitest = localStorage.getItem("UITEST") || "false";
		if (isUitest === "true") {
			return;
		}
		timerId = setInterval(async () => {
			let token = RequestHelper.getToken();
			if (token) {
				if (!JWTHelper.isTokenValid(token) && !isRefreshingToken) {
					isRefreshingToken = true;
					let config = RequestHelper.getAuthConfig();
					let authService = new AzureAuthService(config.authConfig);
					await authService.refreshAccessToken();
					updateMfeConfigs();
					isRefreshingToken = false;
				}
			}
		}, time);
	}

	const { showHeader, totalItemCount, offsetLeft, showLoading, history, messages } = props;

	return (
		<IntlProvider defaultLocale="en" locale={props.locale} messages={props.messages}>
			<CartModelPopupProvider>
				<QuotationPopupProvider>
					<ModelPopupProvider>
						<InputPopupProvider>
							<CartModelPopup />
							<QuotationPopup />
							<ModelPopup />
							<InputPopup />
							<OrderModificationPopup />
							<AppLayout>
								{showLoading && <MorphingChair showLoading={showLoading} history={history} />}
								<AppIdleCheck></AppIdleCheck>
								<ShellEventReceiver></ShellEventReceiver>

								<TopBanner guestMode={guestMode} topBannerHeight={height => setTopBannerHeight(height)} messages={props.messages}></TopBanner>

								<FirstTabContainer>
									<Hyperlink
										button={true}
										onClick={event => handleFocus(event)}
										text={getMessage("SG_LBL_SkipToSearch", messages, "Skip to search")}
									/>
								</FirstTabContainer>

								<MainContainer active={!showHeader} topBannerHeight={topBannerHeight}>
									<LeftNavBar active={!showHeader}>
										<NavBar history={props.history} iconOnly={false} alignHorizontal={false} badgeCount={totalItemCount} />
										<FooterArea />
									</LeftNavBar>
									<ContentContainer>
										<TopHeader
											totalItemCount={totalItemCount}
											usingNewSearch={props.usingNewSearch}
											messages={props.messages}
											showHeader={showHeader}
											history={props.history}
											offsetLeft={offsetLeft}
											visbilityCallback={x => {
												cusRef && cusRef.current && cusRef.current.hideview(!x);
											}}
										/>
										<RouteArea>
											<div className="routeArea">
												<CustomerView view="CustomerBanner" ref={cusRef} />
												<Routes {...props} />
											</div>
										</RouteArea>
									</ContentContainer>
									<BottomNavBar active={!showHeader}>
										<NavBar badgeCount={totalItemCount} iconOnly={true} alignHorizontal={true} history={props.history}>
											<OseMenu></OseMenu>
										</NavBar>
									</BottomNavBar>
								</MainContainer>
							</AppLayout>
						</InputPopupProvider>
					</ModelPopupProvider>
				</QuotationPopupProvider>
			</CartModelPopupProvider>
		</IntlProvider>
	);
};

const mapStateToProps = state => ({
	locale: state.localeController.locale,
	messages: state.intl.messages,
	showLoading: state.appController.showLoading,
	showHeader: state.appController.showHeader,
	showAppNotification: state.appController.showAppNotification,
	buCode: state.appController.buCode,
	analyticsUid: state.appController.analyticsUid,
	usingNewSearch: true,
	totalItemCount: state.cartController.totalItemCount,
	offsetLeft: state.appController.offsetLeft
});
export default connect(mapStateToProps, dispatch =>
	bindActionCreators(
		{
			...appactionCreators,
			push
		},
		dispatch
	)
)(Main);
export { Main };

const FooterArea = () => {
	return (
		<Footer>
			<OseMenu></OseMenu>
			<small>
				© 2020 Salja,
				<span className="pkg-version">(V {packageJson.version})</span>
			</small>
		</Footer>
	);
};

const initDevTools = () => {
	const isLocaleHost = window.location.hostname === "localhost";
	if (isLocaleHost) {
		localStorage.setItem("devtools", "true");
	}
};
