import React from "react";
import styled from "styled-components";

import { colors } from "@coworker/sharedlibrary";

import Button from "@ingka/button";

const SaveQuote = styled.div`
	background: ${colors.white};
	height: 60px;
	position: absolute;
	bottom: 20px;
	right: 50px;
`;

const SaveQuoteButton = ({ loading, messages, onSave }) => {
	return (
		<SaveQuote>
			<Button loading={loading} onClick={onSave} text={messages.SG_BTN_Save} type="transactional" />
		</SaveQuote>
	);
};

export default SaveQuoteButton;
