import React from "react";

import { getStoreConfigSelector } from "../../utils/shellObjectService";
import useMicrofrontend from "../../hooks/useMicroFrontend";

const WebPlannersView = props => {
	const { selectedHfb } = props;

	let config = getStoreConfigSelector();

	const { appId, appUrl, appName } = config.environment.microapps.webplannersApp;

	const { isLoaded, webplanners } = useMicrofrontend(appId, appUrl, appName);
	React.useEffect(() => {
		config.view = "webplanners";
		config.selectedHfb = selectedHfb;
		if (!webplanners) return;
		webplanners.SetSharedConfiguration(config);
		const { render, unMount } = webplanners;

		render(`webplanners-${appId}`, config);

		return () => {
			unMount(`webplanners-${appId}`);
		};
	}, [isLoaded]); // eslint-disable-line

	if (!isLoaded) return null;
	if (!webplanners) return <div />;
	return <div id={`webplanners-${appId}`} />;
};

export default WebPlannersView;
