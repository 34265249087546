import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import Button from "@ingka/button";
import warning from "@ingka/ssr-icon/paths/warning";
import IconWithText from "@ingka/icon-with-text";

import { colors, device } from "@coworker/sharedlibrary";
import { warningActionCreators } from "./../../store/warning/warningAction";

const hiddenTheme = {
	height: "0px",
	opacity: "0",
	padding: "0",
	delay: "0"
};

const visibleTheme = {
	height: "auto",
	opacity: "1",
	padding: "5px 50px 5px 20px",
	delay: "0.5"
};

const WarningContainer = styled.aside`
  // background: ${props => props.background};
  width: 100%;
  height: ${props => props.theme.height} !important;
  display: flex;
  flex-direction: column;
  //position: fixed;
  top: 20px;
  right: 30px;
  z-index: 1200;
  opacity: ${props => props.theme.opacity};
  font-size: 12px;
  padding: ${props => props.theme.padding};
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  transition: height 0ms ${props => props.theme.delay}s,
    opacity ${props => props.theme.delay}s 0ms;

  @media ${device.medium} {
    width: 100%;
    top: 0;
    left: 0;
    right: 10px;
    border-radius: 0;
  }

  * {
    color: inherit;
  }

  section {
    width: 100%;
    height: auto;

    small {
      height: auto;
      margin-top: 10px;
    }
  }

  svg {
    color: #ffa524;
  }
`;

const Title = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	button {
		background: transparent;
		font-size: inherit;
		font-weight: bold;
		margin: 0;
		padding: 0;
	}
`;

const Warning = () => {
	const dispatch = useDispatch();
	const { showAppWarning, warningData, isExpandAppWarning } = useSelector(state => state.warningController);
	let ref = React.useRef(null);

	function showMoreInfo() {
		dispatch(warningActionCreators.expandWarning(!isExpandAppWarning));
	}

	function getWarning(warningData, hidden) {
		let bgcolor = colors.black;
		let iconType = "test";

		if (warningData.type === "error") {
			bgcolor = colors.error;
			iconType = "test1";
		}

		if (warningData.type === "warn") {
			bgcolor = colors.warning;
			iconType = "test2";
		}

		if (warningData.type === "success") {
			bgcolor = colors.success;
			iconType = "test3";
		}

		return (
			<WarningContainer background={bgcolor} icon={iconType} theme={hidden ? hiddenTheme : visibleTheme} ref={ref} alt="Warning container">
				{hidden ? <div></div> : visibleWarningData(warningData)}
			</WarningContainer>
		);
	}

	function visibleWarningData(warningData) {
		return (
			<>
				<Title role="button" onKeyPress={() => showMoreInfo()} onClick={() => showMoreInfo()}>
					<div>
						<IconWithText ssrIcon={warning} title="" />
					</div>
					<div>{warningData.title}</div>
					<div>
						<Button
							type="tertiary"
							onClick={() => {
								dispatch(warningActionCreators.doActionWarning(warningData.action));
							}}
						>
							<span>{warningData.actionTitle}</span>
						</Button>
					</div>
				</Title>
				{isExpandAppWarning && (
					<section>
						<small>{warningData.message}</small>
					</section>
				)}
			</>
		);
	}

	if (!showAppWarning) {
		return getWarning(warningData, true);
	}

	return getWarning(warningData, false);
};
export default Warning;
