import HomeService from "../../service/Home/homeService";
import {
	CHANGE_ADDRESS_PROVIDER,
	CHANGE_MOST_ADDED_PRODUCTS,
	CHANGE_SEARCH_HISTORY,
	CHANGE_SELECTED_HFB,
	CHANGE_TOP_MOST_ADDED_PRODUCTS,
	CHANGE_TOP_SEARCH_HISTORY,
	EXPAND_APP_NOTIFICATION,
	HIDE_APP_NOTIFICATION,
	HIDE_SEARCH,
	SELECTED_ITEM_TYPE,
	SET_BU_CODE,
	SET_COWORKER_AREA,
	SET_COWORKER_CONFIG,
	SET_FEATURES,
	SET_OFFSET,
	SET_ORDER_MODIFICATION_QUEUE,
	SET_USER_LOCALE,
	SET_USER_STORES,
	SHOW_APP_NOTIFICATION,
	SHOW_HEADER,
	SHOW_LOADING,
	SHOW_SEARCH,
	SET_CALLING_APPLICATION
} from "./shellActionTypes";

const home = new HomeService();
const initialState = {
	homeService: home,
	showSearch: true,
	showHeader: true,
	preferences: [],
	countryTopCategories: [],
	countryFields: {},
	customerSchema: {
		isMfsProfile: true,
		constraints: []
	},
	showAppNotification: false,
	isExpandAppNotification: false,
	notificationData: {
		type: "",
		title: "",
		messge: ""
	},

	coworkerData: undefined,
	addressProvider: "GOOGLE",
	selectedHfb: undefined,
	mostAddedProducts: undefined,
	topMostAddedProducts: undefined,
	searchHistory: undefined,
	topSearchHistory: undefined,
	sessionId: home.sessionId,
	buCode: "",
	userId: "",
	supportedLocales: [],
	links: undefined,
	name: "",
	analyticsUid: "",
	mapId: "",
	guestMode: false,
	selectedItemType: "ALL",
	environment: undefined,
	offsetLeft: undefined,
	userStores: [],
	orderModificationQueue: {
		item: null,
		qty: null
	},
	showLoading: false,
	callingApplication: ""
};

const AppReducer = (state, action) => {
	state = state || initialState;
	switch (action.type) {
		case SET_CALLING_APPLICATION: {
			return {
				...state,
				callingApplication: action.payload
			};
		}
		case HIDE_SEARCH: {
			return {
				...state,
				showSearch: false
			};
		}
		case SHOW_SEARCH: {
			return {
				...state,
				showSearch: true
			};
		}
		case SHOW_HEADER: {
			return {
				...state,
				showHeader: action.payload
			};
		}
		case SET_USER_LOCALE: {
			return {
				...state,
				selectedHfb: state.selectedHfb,
				coworkerData: {
					...state.coworkerData,
					primaryLocale: action.payload,
					isTransportDeskUser: isTransportDeskUser(state.coworkerData)
				}
			};
		}
		case SET_BU_CODE: {
			return {
				...state,
				coworkerData: {
					...state.coworkerData,
					buCode: action.payload.storeNo,
					isTransportDeskUser: isTransportDeskUser(state.coworkerData)
				}
			};
		}
		case SET_COWORKER_AREA: {
			return {
				...state,
				coworkerData: {
					...state.coworkerData,
					coworkerWorkArea: action.payload
				}
			};
		}
		case SHOW_LOADING: {
			return {
				...state,
				showLoading: action.payload
			};
		}
		case HIDE_APP_NOTIFICATION: {
			return {
				...state,
				showAppNotification: false,
				isExpandAppNotification: false,
				notificationData: action.payload
			};
		}
		case SHOW_APP_NOTIFICATION: {
			return {
				...state,
				showAppNotification: true,
				isExpandAppNotification: false,
				notificationData: action.payload
			};
		}
		case EXPAND_APP_NOTIFICATION: {
			return {
				...state,
				isExpandAppNotification: action.payload
			};
		}
		case CHANGE_ADDRESS_PROVIDER: {
			return {
				...state,
				addressProvider: action.payload
			};
		}
		case CHANGE_SELECTED_HFB: {
			return {
				...state,
				selectedHfb: action.payload,
				coworkerData: {
					...state.coworkerData,
					isTransportDeskUser: isTransportDeskUser(state.coworkerData)
				}
			};
		}
		case CHANGE_MOST_ADDED_PRODUCTS: {
			return {
				...state,
				mostAddedProducts: action.payload
			};
		}
		case CHANGE_TOP_MOST_ADDED_PRODUCTS: {
			return {
				...state,
				topMostAddedProducts: action.payload
			};
		}
		case CHANGE_SEARCH_HISTORY: {
			return {
				...state,
				searchHistory: action.payload
			};
		}
		case CHANGE_TOP_SEARCH_HISTORY: {
			return {
				...state,
				topSearchHistory: action.payload
			};
		}
		case SELECTED_ITEM_TYPE: {
			return {
				...state,
				selectedItemType: action.payload
			};
		}
		case SET_FEATURES: {
			var environment = { ...state.environment };
			environment.allowedFeatures = action.payload;
			return {
				...state,
				environment: { ...environment }
			};
		}
		case SET_OFFSET: {
			return {
				...state,
				offsetLeft: action.payload
			};
		}
		case SET_USER_STORES: {
			return {
				...state,
				userStores: action.payload
			};
		}
		case SET_ORDER_MODIFICATION_QUEUE: {
			return {
				...state,
				orderModificationQueue: action.payload
			};
		}
		case SET_COWORKER_CONFIG: {
			const {
				coworkerData,
				environment,
				preferences,
				countryTopCategories,
				countryFields = [],
				storeInfo,
				customerSchema,
				sessionId,
				addressProvider = state.addressProvider
			} = action.payload;

			const { buCode, userId, supportedLocales, links, name } = coworkerData;

			return {
				...state,
				homeService: { ...action.payload },
				coworkerData: {
					...coworkerData,
					isTransportDeskUser: isTransportDeskUser(state.selectedHfb)
				},
				environment: environment,
				preferences: preferences,
				countryTopCategories: countryTopCategories,
				countryFields: countryFields,
				storeInfo: storeInfo,
				customerSchema: customerSchema,
				sessionId: sessionId,
				buCode: buCode,
				userId: userId,
				supportedLocales: supportedLocales,
				links: links,
				name: name,
				analyticsUid: environment.analyticsUid,
				mapId: environment.googleMapApiKey,
				addressProvider: addressProvider
			};
		}
		default:
			return state;
	}
};

export default AppReducer;

function isTransportDeskUser(coworkerData) {
	return coworkerData && coworkerData.coworkerWorkArea && coworkerData.coworkerWorkArea.includes("TRANSPORT_DESK");
}
