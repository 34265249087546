import { useDispatch, useSelector } from "react-redux";
import Button from "@ingka/button/Button";
import { device, LayoutLeftBarWidth, mediaQuery, GaHelper } from "@coworker/sharedlibrary";
import React, { useState } from "react";
import styled from "styled-components";
import homeIcon from "@ingka/ssr-icon/paths/home";
import globeIcon from "@ingka/ssr-icon/paths/globe";
import bagIcon from "@ingka/ssr-icon/paths/bag-default";
import documentIcon from "@ingka/ssr-icon/paths/document-checkmark";
//import SalesCoworkerConfiguration from "../../store/salesCoworkerConfiguration";
import { getMessage } from "@coworker/sharedlibrary";
import "./../../styles/badge.scss";
import { useHotKeys } from "../../hooks/useHotKeys";
import { useModelPopup } from "../modelPopup";
const Nav = styled.nav`
	display: flex;

	@media ${device.medium} {
		min-height: 50px;
		height: 80px;
		padding: 20px 0;
	}
	flex-direction: column;
	width: ${LayoutLeftBarWidth};
	padding: 120px 15px 15px 15px;
	@media ${mediaQuery.largeLandscape} {
		width: 70px;
		padding: 120px 15px 15px 15px;
	}

	@media ${mediaQuery.smallLandscape} {
		width: 100%;
		padding: 20px 0;
	}
	margin-top: 0px;
	${({ alignHorizontal }) =>
		alignHorizontal &&
		`
  flex-direction: row;
  width: 100%;
  bottom: 0;
  border: 1px solid #dfdfdf;
  justify-content: space-around;
  position: fixed;
  background: white;
  margin: 0px;
  `}
`;

const NavButton = styled.div`
	position: relative;
	padding-bottom: 31px;

	outline: 0;
	background: none;
	border: none;
	text-decoration: none;
	text-align: center;
	cursor: pointer;
	font-size: 16px;
	div {
		display: flex;
		align-items: center;
		flex-direction: row;
		${({ active }) =>
			active
				? `
      button:nth-child(1) {
        background-color: #ffcd3f;
      }
      button:nth-child(1):focus-visible {
        outline: black solid;
      }
      span:nth-child(2) {
        font-weight:bold;
      }
`
				: `
button:nth-child(1) {
  background-color: transparent;
}

button:focus-visible{
  outline:black solid !important;
}
`}
	}
`;

const IconTitle = styled.span`
	margin-left: 16px;
	text-align: left;
	margin-bottom: 0px !important;

	@media ${mediaQuery.largeLandscape} {
		display: none;
	}
`;

export const NavIcon = styled(Button)``;

const home = {
	key: "HOME",
	path: "/"
};
const explore = {
	key: "EXPLORE",
	path: "/explore"
};
const recentHistory = {
	key: "HISTORY",
	path: "/recentorder"
};
const cart = {
	key: "CART",
	path: "/cart"
};
const other = {
	key: "other",
	path: "other"
};

const menuNames = [home, explore, recentHistory, cart];
//const { messages}=props ;
const buttonProps = {
	type: "tertiary",
	iconOnly: true,
	small: true
};
const NavBar = props => {
	const { iconOnly = false, alignHorizontal = false, history, badgeCount } = props;
	let menu = menuNames.find(x => x.path === history.location.pathname) || other;

	const [offsetLeft, setOffsetLeft] = useState();

	const [selectedMenu, setSelectedMenu] = useState(menu.path);
	const [bounce, setBounce] = useState(false);
	const [preBounce, setPreBounce] = useState(false);
	const [cartItemsCount, setCartItemsCount] = useState(badgeCount);
	const { messages } = useSelector(state => state.intl);
	const { getModel } = useModelPopup();
	const dispatch = useDispatch();
	useHotKeys(["cntrl+r", "cntrl+b", "cntrl+i"], e => {
		if (e.keys === "cntrl+r") {
			onSelected(recentHistory);
		}
		if (e.keys === "cntrl+b") {
			onSelected(cart);
		}
		if (e.keys === "cntrl+i") {
			GaHelper.SendPageView("/storeinfo");
			getModel("userAccountPopup", {
				messages: messages,
				activeTab: "storeinfo"
			});
		}
	});

	React.useEffect(() => {
		setSelectedMenu(menu.path);
	}, [menu.path]);

	React.useEffect(() => {
		dispatch({ type: "SET_OFFSET", payload: offsetLeft });
	}, [offsetLeft]);

	React.useEffect(() => {
		setTimeout(() => {
			if ((badgeCount === 1 && cartItemsCount === 0) || (badgeCount === 0 && cartItemsCount === 1)) {
				setCartItemsCount(badgeCount);
				setPreBounce(true);
				setTimeout(() => {
					setPreBounce(false);
				}, 500);
				setTimeout(() => {
					if (!bounce) {
						setBounce(true);
						setTimeout(() => {
							setBounce(false);
						}, 2000);
					}
				}, 500);
				clearTimeout();
			} else {
				setCartItemsCount(badgeCount);
				if (!bounce) {
					setBounce(true);
					setTimeout(() => {
						setBounce(false);
					}, 2000);
				}
			}
		}, 500);
	}, [badgeCount]);

	function onSelected(menu) {
		setSelectedMenu(menu.path);
		history.push(menu.path);
	}

	history.listen(menu => {
		setSelectedMenu(currentPath => {
			if (currentPath !== window.location.pathname) {
				return menu.path;
			} else {
				return currentPath;
			}
		});
	});

	const canShowExplore = false;

	const navbarElement = document.getElementById("navbar");

	if (navbarElement && offsetLeft !== navbarElement.getBoundingClientRect().left) {
		setOffsetLeft(navbarElement.getBoundingClientRect().left);
	}

	window.addEventListener("orientationchange", function () {
		if (navbarElement && offsetLeft !== navbarElement.getBoundingClientRect().left) {
			setTimeout(() => {
				setOffsetLeft(navbarElement.getBoundingClientRect().left);
			}, 250);
		}
	});

	return (
		<Nav alignHorizontal={alignHorizontal} id="navbar">
			<NavButton data-testid="navhome" active={selectedMenu === home.path} onClick={() => onSelected(home)}>
				<div>
					<NavIcon {...buttonProps} text={getMessage("SG_LBL_Home", messages, "Home")} ssrIcon={homeIcon} />

					{!iconOnly && <IconTitle>{getMessage("SG_LBL_Home", messages, "Home")}</IconTitle>}
				</div>
			</NavButton>
			{canShowExplore && (
				<NavButton data-testid="navexplore" active={selectedMenu === explore.path} onClick={() => onSelected(explore)}>
					<div>
						<NavIcon {...buttonProps} text={getMessage("SG_LBL_Explore", messages, "Explore")} ssrIcon={globeIcon} />

						{!iconOnly && <IconTitle>{getMessage("SG_LBL_Explore", messages, "Explore")}</IconTitle>}
					</div>
				</NavButton>
			)}
			<NavButton data-testid="navorders" active={selectedMenu === recentHistory.path} onClick={() => onSelected(recentHistory)}>
				<div>
					<NavIcon {...buttonProps} text={getMessage("SG_LBL_Orders", messages, "Orders")} ssrIcon={documentIcon} />
					{!iconOnly && <IconTitle>{getMessage("SG_LBL_Orders", messages, "Orders")}</IconTitle>}
				</div>
			</NavButton>
			<NavButton data-testid="navcart" active={selectedMenu === cart.path} onClick={() => onSelected(cart)}>
				<div>
					<div className="cart-icon-wrapper">
						<NavIcon {...buttonProps} text={getMessage("SG_LBL_Bag", messages, "Bag")} ssrIcon={bagIcon} />

						<div
							className={`badge ${preBounce ? "badge--pre-bounce" : ""} ${bounce ? "badge--bounce" : ""} ${badgeCount < 1 ? "badge--empty" : ""}`}
						>
							<span> {badgeCount < 1 ? "" : badgeCount}</span>
						</div>
					</div>

					{!iconOnly && <IconTitle>{getMessage("SG_LBL_Bag", messages, "Bag")}</IconTitle>}
				</div>
			</NavButton>
			{props.children}
		</Nav>
	);
};

export default NavBar;
