import { Specifications } from "@coworker/sharedlibrary";

import { LocaleHelper, FeaturesList } from "@coworker/sharedlibrary";
import { useSelector } from "react-redux";

import { useState, useEffect } from "react";
import { useAllowedFeature } from "./useAllowedFeature";
function useQuotation() {
	const [quotationAllowed, setQuotationAllowed] = useState(false);
	const featureAllowed = useAllowedFeature(FeaturesList.QUOTATIONS);
	var { locale } = useSelector(state => state.localeController);
	var countryCode = LocaleHelper.getCountryCode(locale);
	useEffect(() => {
		var canCreateQuotation = Specifications.usesSpeCalcSpec(countryCode) && featureAllowed;
		setQuotationAllowed(canCreateQuotation);
	}, [countryCode, featureAllowed]);

	return quotationAllowed;
}
function useCanUpdateQuotation() {
	const [updateQuoteNeeded, setUpdateQuoteNeeded] = useState(false);
	var { order, items } = useSelector(state => state.cartController);

	useEffect(() => {
		if (order && order.isQuotation) {
			var itemsQuantity = items
				.map(x => x.quantity)
				.reduce(function (a, b) {
					return a + b;
				}, 0);
			var quoteQuantity = order.items
				.map(x => x.quantity)
				.reduce(function (a, b) {
					return a + b;
				}, 0);
			if (quoteQuantity !== itemsQuantity) {
				setUpdateQuoteNeeded(true);
			} else {
				setUpdateQuoteNeeded(false);
			}
		}
	}, [items.count, order, items]);

	return updateQuoteNeeded;
}
function useCanSaveOrderToQuotation() {
	const [canSaveOrderToQuotation, setCanSaveOrderToQuotation] = useState(false);
	var { order, isModifyOrderinProgress } = useSelector(state => state.cartController);

	useEffect(() => {
		if (isModifyOrderinProgress && order) {
			var canSave = !order.hasService;
			setCanSaveOrderToQuotation(canSave);
		} else {
			setCanSaveOrderToQuotation(false);
		}
	}, [isModifyOrderinProgress, order]);

	return canSaveOrderToQuotation;
}
export { useCanUpdateQuotation, useQuotation, useCanSaveOrderToQuotation };
