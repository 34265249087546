import React from "react";
import styled from "styled-components";
import emptySvg from "../../static/emptybag.svg";

const addStyles = styledObj => {
	return styledObj.div.attrs(props => ({
		id: props.id
	}))`
  background-repeat: no-repeat !important;
  background-size: contain;
  height: 150px;
  background-position: center;
  width: 100%;
  align-self: center;
  background-color: white;
  margin: 10px;
  background-image: url(${emptySvg}) !important;
`;
};

const Client = window["styled-components"] ? addStyles(window["styled-components"]) : addStyles(styled);

const Mfe = addStyles(styled);

const NoResultsView = props => {
	return props.client ? <Client {...props} /> : <Mfe {...props} />;
};

export default NoResultsView;
