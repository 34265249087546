import { APIEndpoints, URI, httpService } from "@coworker/sharedlibrary";
import { OrderTypes, SharedConfiguration } from "@coworker/sharedlibrary";

import Enumerable from "linq";
import OrderRetreivalService from "../orderRetrievalService";
import SalesCoworkerConfiguration from "../../store/salesCoworkerConfiguration";

class OrderModificationService {
	constructor() {
		this.config = SalesCoworkerConfiguration.getUserConfig();
	}

	cancelOrder = async (order, reasonCode) => {
		if (order.type === "ISOM") {
			let baseUrl = SharedConfiguration.getApiBaseUrl("cartservices");
			const uri = new URI(`${baseUrl}${APIEndpoints.cancelOrder}`, true);
			const url = uri.fill({
				id: order.id,
				type: order.type
			});
			const data = {
				reasonCode: reasonCode || "CUSCHMIND",
				orderNoSource: order.orderNoSource
			};
			return httpService
				.post(url, {
					body: data,
					headers: this.config.headers
				})
				.then(data => {
					if (data.error) {
						throw new Error(data.error);
					}
					return data;
				})
				.catch(error => {
					throw error;
				});
		} else {
			await this.postFormResourceV1(order, "CANCEL_ORDER", {
				reasonCode: reasonCode || "CUSCHMIND"
			});
		}
	};

	convertOrder = async order => {
		let baseUrl = SharedConfiguration.getApiBaseUrl("cartservices");
		var response = await httpService.post(`${baseUrl}${APIEndpoints.converttoorder}`, {
			body: {
				url: order.convertToOrderLink
			},
			headers: this.config.headers
		});

		var orderNo = response.orderNo;

		var orderRetrieval = new OrderRetreivalService();
		var recentOrder = await orderRetrieval.getOrder(orderNo);
		var ccOrder = {
			orderNo: recentOrder.orderNo,
			orderNoSource: recentOrder.orderNoSource,
			_links: recentOrder._links
		};
		ccOrder.type = "CCORDER";
		ccOrder.customer = {
			email: "",
			mobilePhone: ""
		};
		ccOrder.orderItems = order.orderItems;
		ccOrder.type = OrderTypes.CCorder;
		return ccOrder;
	};

	changeQuantity = async (order, orderItem, newQuantity) => {
		try {
			await this.postFormResourceV1(order, "CHANGE_QUANTITY", {
				reasonCode: "CUSCHMIND",
				lineId: orderItem.soSalesItemNo,
				quantity: newQuantity
			});

			const orderRetrieval = new OrderRetreivalService();
			const recentOrder = await orderRetrieval.getOrder(order.orderNo);

			return recentOrder;
		} catch (error) {
			return null;
		}
	};

	removeOrderItem = async (order, orderItem) => {
		try {
			await this.postFormResourceV1(order, "REMOVE_ITEM", {
				reasonCode: "CUSCHMIND",
				lineId: orderItem.soSalesItemNo
			});

			const orderRetrieval = new OrderRetreivalService();
			const recentOrder = await orderRetrieval.getOrder(order.orderNo);

			return recentOrder;
		} catch (error) {
			return null;
		}
	};

	addNewOrderItem = async (order, id, newItem) => {
		try {
			await this.postFormResourceV1(
				order,
				"ADD_ITEM",
				{
					soSalesItemNoRef: id,
					position: newItem.position || "LastInGroup",
					itemId: newItem.itemId,
					itemType: newItem.itemType,
					reasonCode: "CUSCHMIND",
					quantity: newItem.quantity
				},
				{
					itemGroupRef: 0
				}
			);

			const orderRetrieval = new OrderRetreivalService();
			const recentOrder = await orderRetrieval.getOrder(order.orderNo);

			return recentOrder;
		} catch (error) {
			return null;
		}
	};
	postFormResource = async (url, rels, postData, additionalProp) => {
		let headers = {
			"x-ibm-client-id": this.config.environment.client_id,
			"x-ibm-client-secret": this.config.environment.client_secret,
			...this.config.headers
		};
		let formResponse = await httpService.get(url, {
			headers: { ...headers }
		});

		if (formResponse && formResponse._links && formResponse._templates && formResponse._templates.default && formResponse._templates.default.properties) {
			let relLink;
			if (rels.constructor === Array) {
				var rel = Enumerable.from(rels)
					.where(x => formResponse._links[x])
					.select(y => y)
					.firstOrDefault();
				relLink = formResponse._links[rel].href;
			} else {
				relLink = formResponse._links[rel].href;
			}

			let postProperties = formResponse._templates.default.properties;

			let postFormData = {};

			postProperties.forEach(prop => {
				postFormData[prop.name] = prop.value;
				if (!prop.readOnly && Object.prototype.hasOwnProperty.call(postData, prop.name)) {
					postFormData[prop.name] = postData[prop.name];
				}
			});
			if (additionalProp) {
				Object.entries(additionalProp).forEach(([key, value]) => {
					postFormData[key] = value;
				});
			}
			return await httpService.post(relLink, {
				body: postFormData,
				headers: headers
			});
		}

		throw new Error(`No templates found in form url:${url}`);
	};

	postFormResourceV1 = async (order, action, postData, additionalFields) => {
		const { headers } = SharedConfiguration.getUserConfig();
		return httpService.post(APIEndpoints.submitOrderForm, {
			body: {
				action,
				additionalFields,
				order: {
					cashAndCarryOrder: order.type === "CCORDER",
					version: order.orderVersion,
					orderNo: order.orderNo,
					orderNoSource: order.orderNoSource
				},
				businessUnit: {
					code: order.buCode
				},
				formData: postData
			},
			headers
		});
	};
}

export default OrderModificationService;
