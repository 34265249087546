import { useSelector } from "react-redux";
import React from "react";
import Modal, { Prompt, ModalFooter } from "@ingka/modal";
import Button from "@ingka/button";
import { GUID } from "@coworker/sharedlibrary";

const RemoveCartItemDialog = ({ showDelete, onSubmit, onClose }) => {
	const { messages } = useSelector(state => state.intl);
	function getModelButtons() {
		if (showDelete) {
			return [
				{
					text: messages.SG_BTN_Delete,
					type: "primary",
					onClick: () => {
						onSubmit(true);
					}
				},
				{
					text: messages.SG_BTN_Cancel,
					type: "secondary",
					onClick: () => {
						onSubmit(false);
					}
				}
			];
		} else {
			return [
				{
					text: messages.SG_BTN_OK || "OK",
					type: "primary",
					onClick: () => {
						onClose(false);
					}
				}
			];
		}
	}
	return (
		<Modal
			visible={true}
			escapable={true}
			handleCloseBtn={() => {
				onClose();
			}}
			focusLockProps={focus}
			scrollLock={false}
		>
			<Prompt
				title={showDelete ? messages.SG_LBL_DeleteItemWarning : messages.SG_LBL_PleaseCancelOrder}
				footer={
					<ModalFooter compact={false}>
						{getModelButtons().map(button => {
							return <Button key={GUID.Guid()} {...button} />;
						})}
					</ModalFooter>
				}
			/>
		</Modal>
	);
};

export default RemoveCartItemDialog;
const focus = {
	returnFocus: false,
	autoFocus: true
};
