import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
function useStorePreference(prefCode) {
	const [preference, setStorePreference] = useState(undefined);
	const { preferences } = useSelector(state => state.appController);
	useEffect(() => {
		var pref = preferences && preferences.find(pre => pre.name === prefCode);
		var value = pref && pref.value;
		setStorePreference(value || undefined);
	}, [prefCode, preferences]);
	return preference;
}
export { useStorePreference };
