import { HideBodyScrollStyle } from "./../../globalStyle";
import React from "react";
import modelTypes from "./types";
import shellDialogs from "./../shell/dialogs/";

const ModelPopupContext = React.createContext(null);
const useModelPopup = () => {
	const [config, setConfig] = React.useContext(ModelPopupContext);

	const getModel = React.useCallback(
		(type, conf = {}) => {
			let resolver = null;

			const resolve = value => {
				setConfig(null);
				if (resolver) {
					resolver(value);
				}
			};
			const cancel = () => {
				setConfig(null);
				if (resolver) {
					resolver();
				}
			};

			setConfig({
				type,
				...conf,
				resolve,
				cancel,
				test: "test"
			});
			return new Promise(resolve => {
				resolver = resolve;
			});
		},
		[setConfig]
	);
	return { getModel, modelOpen: !!config };
};
const ModelPopup = () => {
	const [input] = React.useContext(ModelPopupContext);
	const { type = "", cancel, resolve, ...config } = input || {};

	const allTypes = {
		...modelTypes,
		...shellDialogs
	};
	const popupConf = allTypes[type] || {};
	const Component = popupConf.component;

	return (
		<>
			{input ? (
				<>
					<HideBodyScrollStyle />
					<Component {...config} onSubmit={resolve} onClose={cancel} />
				</>
			) : (
				""
			)}
		</>
	);
};

const ModelPopupProvider = ({ children }) => {
	const [input, setInput] = React.useState(null);

	return <ModelPopupContext.Provider value={[input, setInput]}>{children}</ModelPopupContext.Provider>;
};

const modelViewType = {
	alert: "alert",
	informational: "informational"
};
const modelBehaviourType = {
	normal: "normal",
	popup: "normal"
};
export { useModelPopup, ModelPopup, ModelPopupProvider, modelBehaviourType, modelViewType, ModelPopupContext };
