import { DfpKey, LocaleHelper, RequestHelper, Specifications } from "@coworker/sharedlibrary";
import { parse } from "locale-string";

import packageJson from "../../package.json";
import { history } from "./history";

const TRANSPORT_DESK_CASHLINE = "TRANSPORT_DESK_CASHLINE";
let globalStore = {};
let token = "";
let isExternal = false;
const SalesCoworkerConfiguration = {
	initStore: storeData => {
		globalStore = storeData;
	},
	getStore: () => {
		return globalStore;
	},
	setIsExternal: external => {
		isExternal = external;
	},
	isExternal: () => {
		return isExternal;
	},
	setUserToken: usertoken => {
		token = usertoken;
	},
	getUserToken: () => {
		return RequestHelper.getToken() || token;
	},
	getUserConfig: () => {
		return getUserConfig();
	},
	getSessionId: () => {
		return getUserConfig();
	},

	getPreference: key => {
		const { preferences } = getUserConfig();
		if (preferences !== undefined) {
			let preference = preferences.find(x => x !== undefined && x.name === key);
			return preference;
		}
		return null;
	},
	getPrivilege: key => {
		const { coworkerData } = getUserConfig();

		return coworkerData && coworkerData.privileges && coworkerData.privileges.find(priv => priv.code === key);
	},

	checkIsomEnabled: () => {
		let { userId, environment, preferences } = SalesCoworkerConfiguration.getUserConfig();
		if (preferences) {
			let pref = preferences.find(x => x !== undefined && x.name === DfpKey.ISOM_ALLOWED_USERS);

			let users = environment.devList;
			if (pref && pref.value) {
				users = users.concat(pref.value);
			}
			if (userId) {
				let allUsers = users.map(x => x.toLowerCase());
				return allUsers.includes("*") || allUsers.includes(userId.toLowerCase());
			}
		}
		return false;
	},
	isAllowedFeature: feature => {
		let { environment } = SalesCoworkerConfiguration.getUserConfig();
		let features = environment.allowedFeatures;
		if (features.find(x => x.toLowerCase() === feature.toLowerCase())) {
			return true;
		} else {
			return false;
		}
	},

	getBaseMfsUrl: () => {
		const { countryCode, environment } = getUserConfig();
		return countryCode === "CN" ? environment.mfscnurl : environment.mfsurl;
	},
	isRestrictedInMarket: () => {
		const { environment, countryCode } = getUserConfig();
		const { disabledMarkets = [] } = environment;
		return disabledMarkets.includes(countryCode);
	}
};

const getUserConfig = () => {
	const state = globalStore.getState();

	const { locale } = state.localeController;
	let config = {
		selectedHfb: state.appController.selectedHfb || [],
		coworkerData: state.appController.coworkerData,
		buCode: state.appController.buCode,
		userId: state.appController.userId,
		environment: state.appController.environment,
		storeInfo: state.appController.storeInfo,
		preferences: state.appController.preferences,
		sessionId: state.appController.sessionId,
		topMostAddedProducts: state.appControllertopMostAddedProducts,
		topSearchHistory: state.appController.topSearchHistory,
		customerSchema: state.appController.customerSchema
	};
	const countryCode = LocaleHelper.getCountryCode(locale);
	const isoLocale = LocaleHelper.formatLocaleIso(locale);

	let option = {
		headers: {}
	};
	if (RequestHelper.getToken()) {
		option.headers = {
			Authorization: `Bearer ${RequestHelper.getToken()}`,
			"Accept-Language": locale || "en-US",
			"x-request-locale": locale || "en-US",
			"x-session-id": config.sessionId
		};
	}
	if (config.buCode) {
		option.headers["x-bucode"] = config.buCode;
		option.headers["x-countrycode"] = countryCode;
	}
	if (isExternal) {
		option.headers["external-client"] = true;
	}
	const canAddItem = state => {
		const { order, isTransportDeskMode, isCashLine } = state.cartController;

		if (order === undefined) {
			if (isTransportDeskMode && !isCashLine) {
				return false;
			}

			return true;
		}

		if (order.isQuotation) {
			return true;
		}

		if (order.isCcdService) {
			if (isTransportDeskMode) {
				return false;
			}
			return !order.isPaid && !order.isCancelled;
		}
		return !order.isPaid && !order.isCancelled && !order.hasService;
	};

	const canAddReceipt = state => {
		const { order } = state.cartController;

		if (order === undefined) {
			return true;
		}
		return order.canAddReceipt;
	};

	let userMode = state.authController.userMode;
	let currency = "USD";
	const currencypref = config.preferences.find(x => x !== undefined && x.name === DfpKey.PRIMARY_CURRENCY);

	if (currencypref) {
		currency = currencypref.value || "USD";
	}
	let cart = {
		items: state.cartController.items,
		isModifyOrderinProgress: state.cartController.isModifyOrderinProgress,
		canAddItem: canAddItem(state),
		canImportReceipt: canAddReceipt(state),
		customerMeetingPoint: state.cartController.customerMeetingPoint,
		orderCreationMethod: state.cartController.orderCreationMethod
	};
	let coworkerWorkArea = config.coworkerData && config.coworkerData.coworkerWorkArea;

	let formattedLocales = [];
	if (config.coworkerData && config.coworkerData.supportedLocales) {
		formattedLocales = config.coworkerData.supportedLocales.map(loc => {
			return {
				id: loc.replace("_", "-"),
				name: parse(loc.replace("_", "-")).language
			};
		});
	}
	return {
		formattedLocales: formattedLocales,
		coworkerWorkArea: coworkerWorkArea || "SALES_DESK",
		isCashLine: coworkerWorkArea === TRANSPORT_DESK_CASHLINE,
		customerSchema: config.customerSchema,
		isMetricUsingStore: Specifications.usesMetricCountrySpec(countryCode),
		selectedHfb: config.selectedHfb,
		coworkerData: config.coworkerData,
		storeCurrency: currency,
		sessionId: config.sessionId,
		preferences: config.preferences,
		buCode: config.buCode,
		countryCode: countryCode,
		isoLocale: isoLocale,
		backEndLocale: LocaleHelper.formatLocaleToJava(locale),
		languageCode: LocaleHelper.getLanguageCode(locale),
		locale: locale,
		userId: config.userId || (config.coworkerData && config.coworkerData.userId),
		environment: config.environment,
		storeInfo: config.storeInfo,
		token: RequestHelper.getToken(),
		guestMode: userMode === "GUEST",
		userMode: userMode,
		isStandalone: false,
		messages: state.intl.messages,
		cartItems: cart.items,
		canAddItem: cart.canAddItem,
		isExternal: SalesCoworkerConfiguration.isExternal(),
		canImportReceipt: cart.canImportReceipt,
		history: history,
		customerMeetingPoint: cart.customerMeetingPoint,
		orderCreationMethod: cart.orderCreationMethod,
		appVersion: packageJson.version,
		headers: option.headers,
		isTransportDeskMode: state.cartController.isTransportDeskMode
	};
};

const getSessionId = () => {
	const state = globalStore.getState();
	const { sessionId } = state.appController;

	return sessionId;
};

const getCurrentLocale = () => {
	const state = globalStore.getState();
	const { locale = "en-US" } = state.localeController;

	return RequestHelper.getLocale() || locale;
};

const getCurrentBuCode = () => {
	const state = globalStore.getState();
	const { buCode = "" } = state.appController;
	return buCode;
};

const getCurrentUser = () => {
	const state = globalStore.getState();
	const { userId = "" } = state.appController;
	return userId;
};

export default SalesCoworkerConfiguration;
export { getSessionId, getCurrentLocale, getCurrentUser, getCurrentBuCode };
