import { history } from "./history";
import getReducers from "./reducers/reducers";
import { reduxStoreHelper } from "@coworker/sharedlibrary";
import messages_en from "../translations/en.json";

const messages = {
	en: messages_en
};
const language = navigator.language.split(/[-_]/)[0];

const i18nConfig = {
	locale: language,
	messages: Object.prototype.hasOwnProperty.call(messages, language) ? messages[language] : messages["en"]
};
const initialState = {
	intl: {
		locale: i18nConfig.locale,
		messages: i18nConfig.messages
	}
};

const store = reduxStoreHelper.customStore(history, getReducers(history), initialState);
export default store;
