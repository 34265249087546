import { getOrderAmountSummarySelector } from "./../../store/cart/cartSelectors";
import { useSelector } from "react-redux";
import React from "react";

import { getStoreConfigSelector } from "../../utils/shellObjectService";
import useMicrofrontend from "../../hooks/useMicroFrontend";
import { CanCreateSalesOrder } from "../../containers/cart/canCreateSalesOrderValidator";

const MicroOrderServicesView = ({ reference, view, viewState, item, compactMode, canEditService, onClose, isExternal = false }) => {
	const appConfig = getStoreConfigSelector();
	let state = useSelector(state => state);
	let config = { ...appConfig };
	let { appId, appUrl, appName } = config.environment.microapps.orderservices;

	const { isLoaded, orderservices } = useMicrofrontend(appId, appUrl, appName);

	let { total, items, order, id, isTransportDeskMode, salesPriceData, isModifyOrderinProgress } = useSelector(state => state.cartController);
	config.order = order;
	config.cartId = id;
	config.items = items;
	config.isTransportDeskMode = isTransportDeskMode;
	config.view = view;
	if (viewState) config.viewState = viewState;
	config.canEditService = canEditService;
	config.compactMode = compactMode;
	config.reference = reference;
	if (item) config.item = item;

	const closeServiceView = () => {
		orderservices && orderservices.unMount(`orderservices-${appId}-${viewState}`);

		onClose && onClose();
	};

	//refresh items
	React.useEffect(() => {
		let cartSummary = getOrderAmountSummarySelector(state);
		let canCreateSalesOrder = CanCreateSalesOrder(state);
		orderservices &&
			orderservices.setCart(items, cartSummary, isTransportDeskMode, isModifyOrderinProgress, config.preferences, canCreateSalesOrder, order);
	}, [items, salesPriceData, total, order]);

	React.useEffect(() => {
		if (!orderservices) return;
		let cartSummary = getOrderAmountSummarySelector(state);
		let canCreateSalesOrder = CanCreateSalesOrder(state);
		orderservices.SetSharedConfiguration({ ...config, isExternal: isExternal });
		orderservices.setCart(items, cartSummary, isTransportDeskMode, isModifyOrderinProgress, config.preferences, canCreateSalesOrder, order);
		if (viewState) orderservices.setServiceViewState(viewState);
		const { render, unMount, subscribe, unSubscribe } = orderservices;
		subscribe("CLOSE_SERVICE_VIEW", closeServiceView);

		render(`orderservices-${appId}-${viewState}`, config);

		return () => {
			unSubscribe("CLOSE_SERVICE_VIEW", closeServiceView);
			unMount(`orderservices-${appId}-${viewState}`);
		};
	}, [isLoaded]); // eslint-disable-line

	if (!isLoaded) return null;
	if (!orderservices) return <div />;
	return <div id={`orderservices-${appId}-${viewState}`} />;
};
export default MicroOrderServicesView;
