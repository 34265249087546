import React from "react";
import Modal, { Sheets, ModalBody, ModalHeader, ModalFooter } from "@ingka/modal";
import OrderPickupNotification from "./../../orderPickupNotification";
import { useSelector } from "react-redux";
import Button from "@ingka/button";

const OrderPickupNotifyDialog = ({ onClose, onSubmit, customer }) => {
	const { messages } = useSelector(state => state.intl);
	const orderPickupRef = React.useRef();
	const focus = {
		returnFocus: false,
		autoFocus: true
	};
	function onSaveNotification() {
		const outputNotification = orderPickupRef && orderPickupRef.current.isValid();

		if (outputNotification && outputNotification.isValid) {
			onSubmit(outputNotification.notifications);
		}
	}
	return (
		<Modal
			visible
			escapable={false}
			focusLockProps={focus}
			handleCloseBtn={() => {
				onClose();
			}}
		>
			<Sheets
				header={<ModalHeader title={messages.SG_LBL_PickupNotification || "Pickup notification"} />}
				footer={
					<ModalFooter>
						<Button onClick={onSaveNotification} text={messages.SG_BTN_Save} type="primary" fluid></Button>
					</ModalFooter>
				}
			>
				<ModalBody>
					<OrderPickupNotification ref={orderPickupRef} customer={customer} />
				</ModalBody>
			</Sheets>
		</Modal>
	);
};
export default OrderPickupNotifyDialog;
