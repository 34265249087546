import Enumerable from "linq";
import moment from "moment";
import {
	APIEndpoints,
	DateTimeHelper,
	GUID,
	LocaleHelper,
	OrderTypes,
	SharedConfiguration,
	Specifications,
	URI,
	httpService,
	isomItemsBuilder
} from "@coworker/sharedlibrary";

import SalesCoworkerConfiguration from "../../store/salesCoworkerConfiguration";
import { getInternationalMobileFormat } from "./../../helpers/cartHelper";

const PRIVATE_TYPE = "Private";
const EMPLOYEE_TYPE = "Employee";
const Business_TYPE = "Business";
class IsomOrderModificationService {
	constructor() {
		this.config = SalesCoworkerConfiguration.getUserConfig();
	}

	async rescheduleIsomDelivery(proposal, reasonCode) {
		if (proposal.order) {
			let baseUrl = SharedConfiguration.getApiBaseUrl("cartservices");

			let request = createIsomRecheduleRequest(this.config.userId, proposal, reasonCode);
			await httpService.post(
				new URI(`${baseUrl}${APIEndpoints.isomorderreschedule}`, true).fill({
					orderNoSrc: proposal.order.orderNoSource,
					orderNo: proposal.order.orderNo
				}),
				{
					body: request,
					headers: this.config.headers
				}
			);
		}
	}
	async updateIsomOrder(request, reasonCode) {
		let baseUrl = SharedConfiguration.getApiBaseUrl("cartservices");
		const { orderNo, orderNoSource } = request.order;
		let url = new URI(`${baseUrl}${APIEndpoints.isomOrderModify}`, true).fill({
			orderNo: orderNo,
			orderNoSrc: orderNoSource
		});

		try {
			let orderRequest = somUpdateRequestBuilderv2(request, reasonCode);
			let response = await httpService.post(url, {
				body: orderRequest,
				headers: this.config.headers
			});
			if (response.status === 200) {
				let order = response;
				order.type = OrderTypes.IsomOrder;

				order.customer = {
					email: request.customer.email,
					mobilePhone: request.customer.mobilePhone
				};
				order.orderItems = request.items;
				return Promise.resolve(order);
			} else {
				return Promise.reject({
					...response
				});
			}
		} catch (error) {
			console.log(error);
			return Promise.reject(error);
		}
	}
}
const createIsomRecheduleRequest = (userId, bookedProposal, reasonCode) => {
	let request = {};

	let delivery = bookedProposal.bookedServices.find(x => x.isCentralDelivery);
	let deliveryServices = [];
	if (delivery)
		delivery.subDeliveries.forEach(updatedDelivery => {
			let capabilities = [];
			if (updatedDelivery.authoritytoleaveSelected) {
				capabilities.push("AUTH_TO_LEAVE");
			}
			if (updatedDelivery.wheelChairCapable) {
				capabilities.push("WHEELCHAIR");
			}
			if (updatedDelivery.timeWindow.isRangeOfDays) {
				capabilities.push("RANGE_OF_DAYS");
			}
			let timeWindowLineItem = updatedDelivery.referenceItems[0].timeWindowLineItem;
			let fulfillingUnit = {
				type: timeWindowLineItem.shipNode.split(".")[0],
				code: timeWindowLineItem.shipNode.split(".")[1]
			};
			let deliveryReq = {
				deliveryPrice: {
					amount: updatedDelivery.price.priceInclTax,
					currencyCode: updatedDelivery.price.currencyCode
				},
				id: updatedDelivery.deliveryNo,
				serviceItemId: updatedDelivery.serviceItemId,
				timeWindow: {
					fromLocalDateTime: DateTimeHelper.formatDateWithMoment(updatedDelivery.timeWindow.fromDateTime, "YYYY-MM-DDTHH:mm:ss[Z]"),
					toLocalDateTime: DateTimeHelper.formatDateWithMoment(updatedDelivery.timeWindow.toDateTime, "YYYY-MM-DDTHH:mm:ss[Z]"),
					reasonCode: reasonCode || "CUSCHMIND",
					id: updatedDelivery.timeWindow.id,
					resourcePoolId: updatedDelivery.timeWindow.resourceId,
					timeZone: updatedDelivery.timeWindow.timeZoneId,
					transferringUnit: updatedDelivery.timeWindow.transferringUnit,
					fulfillingUnit: fulfillingUnit
				},
				capabilities: capabilities
			};
			deliveryServices.push(deliveryReq);
		});
	request.deliveryServices = deliveryServices;

	let providedServices = bookedProposal.bookedServices.filter(x => x.isProvidedService);
	if (providedServices && providedServices.length > 0) {
		request.providedServices = [];
		providedServices.forEach(prvService => {
			let prvrequest = {
				id: prvService.lineId,
				serviceItemId: prvService.serviceItemId,
				servicePaymentTo: prvService.serviceProviderPreferredPayment,
				serviceProviderId: prvService.serviceProviderId,
				serviceProviderName: prvService.serviceProviderName,
				serviceTimeWindows: {
					reasonCode: reasonCode || "CUSCHMIND",
					capacityAllocated: parseInt(prvService.capacityNeeded),
					fromDateTime: prvService.timeWindow.fromDateTime,
					id: prvService.timeWindow.id,
					timeZone: prvService.timeWindow.timeZone,
					toDateTime: prvService.timeWindow.toDateTime
				}
			};
			request.providedServices.push(prvrequest);
		});
	}
	request.orderVersion = bookedProposal.order.orderVersion;
	request.userId = userId;
	return request;
};

const somUpdateRequestBuilderv2 = (request, reasonCode) => {
	const config = SalesCoworkerConfiguration.getUserConfig();
	const { countryCode, buCode, locale, userId, isCashLine } = config;
	let {
		items,
		customer,
		services,
		totalPrice,
		totalFamilyPrice,
		customerBilling,
		salesPriceData,
		customerMeetingPoint,
		isTransportDeskMode,
		paymentType = "PAYMENT_IN_ADVANCE",
		order
	} = request;

	const { orderSavings, couponInfo, orderSummary, currencyCode } = salesPriceData;
	const useExclusive = countryCode === "CA" || countryCode === "US";
	let priceViewType = PRIVATE_TYPE;
	let isEmployee = orderSummary && orderSummary.summary.find(x => x.viewType === EMPLOYEE_TYPE);
	let isFamily = (customer.ikeaFamilyNumber && customer.ikeaFamilyNumber !== "") || false;
	if (isTransportDeskMode) {
		isFamily = false;
	}
	if (isEmployee) {
		priceViewType = EMPLOYEE_TYPE;
	}
	if (customer.isBusinessCustomer) {
		priceViewType = Business_TYPE;
	}
	if (!totalFamilyPrice) {
		totalFamilyPrice = totalPrice;
	}
	items = items.filter(x => x.quantity !== 0);
	let allItems = isomItemsBuilder.createItems(items, isFamily);
	if (!customer.countryCode) {
		customer.countryCode = countryCode;
	}
	if (!customerBilling.countryCode) {
		customerBilling.countryCode = countryCode;
	}
	let orderId = GUID.Guid();
	let itemLines = allItems.map(item => {
		return {
			itemType: item.itemType,
			itemNo: item.itemNo,
			itemName: item.itemName,
			id: item.id,
			orderedQuantity: item.orderedQuantity,
			unitPrice: item.itemUnitPrice,
			itemReferences: item.itemReferences,
			itemLineReferences: item.itemReferences,
			importDetails: isCashLine ? undefined : item.importDetails,
			isCustomerPicked: isTransportDeskMode ? "Y" : "N",
			handInLocation: item.handInLocation
		};
	});
	let lineIds = itemLines.map(x => x.id);
	if (services) {
		services.forEach(x => {
			if (x.isCentralDelivery) {
				x.subDeliveries.forEach(y => {
					if (!isNaN(y.lineId)) {
						lineIds.push(parseInt(y.lineId, 10));
					}
				});
			} else {
				if (!isNaN(x.lineId)) {
					lineIds.push(x.lineId);
				}
			}
		});
	}
	if (order.maxLineId) {
		lineIds.push(order.maxLineId);
		lineIds.push(Math.floor(Math.random() * 100 + 1));
	}

	let isomrequest = {
		reasonCode: reasonCode || "CUSCHMIND",
		version: order.orderVersion,
		userId: userId,
		orderCreationMethod: request.orderCreationMethod,
		countryCode: countryCode,
		salesOrderGUId: orderId,
		clientSystem: {
			name: "SALJA",
			version: "1.0.0"
		},
		businessUnit: {
			type: "STO",
			code: buCode
		},

		customer: mapIsomCustomer(customer, customerBilling),
		itemLines: itemLines,
		providedServices: getProvidedServicesV2(
			services.filter(x => x.isProvidedService),
			allItems,
			locale,
			lineIds
		),
		deliveryArrangements: getDeliveryArrangementsv2(
			services.filter(x => x.isCentralDelivery),
			allItems,
			salesPriceData,
			isFamily
		),
		questionnaires: getQuestionnaires(request.questionaireData),
		orderPayment: {}
	};
	if (orderSavings && orderSavings.length > 0) {
		isomrequest.orderSavings = orderSavings.map((sv, index) => {
			return {
				id: sv.id,
				sequence: index,
				type: sv.type,
				discountDetails: {
					count: sv.discountDetails.count,
					value: sv.discountDetails.value,
					type: sv.discountDetails.type,
					applyOn: sv.discountDetails.applyOn
				}
			};
		});
	}
	if (couponInfo) {
		isomrequest.couponInformations = [];
		couponInfo.forEach(cpn => {
			let viewType = isFamily ? "Family" : "Private";

			let cpnInfo = cpn.discountAmount.find(x => x.viewType === viewType);
			if (cpnInfo) {
				let cpnReq = {
					discountCode: cpn.discountCode,
					discountAmount: cpnInfo.amount
				};
				isomrequest.couponInformations.push(cpnReq);
			}
		});
	}
	if (isTransportDeskMode) {
		isomrequest.handInLocation = "Transport Desk";
	}

	if (paymentType === "PAYMENT_IN_ADVANCE") {
		isomrequest.orderPayment.paymentInAdvance = {
			paymentDetails: getOrderPayment(orderSummary, currencyCode, priceViewType, useExclusive)
		};
	}

	if (paymentType === "PAYMENT_IN_DELIVERY") {
		isomrequest.orderPayment.paymentOnDelivery = {
			paymentDetails: getOrderPayment(orderSummary, currencyCode, priceViewType, useExclusive)
		};
	}
	if (paymentType === "PAYMENT_ON_COLLECT") {
		isomrequest.orderPayment.paymentOnCollect = {
			paymentDetails: getOrderPayment(orderSummary, currencyCode, priceViewType, useExclusive)
		};
	}

	if (customerMeetingPoint && customerMeetingPoint.id) {
		isomrequest.customerMeetingPoint = customerMeetingPoint;
	}
	return isomrequest;
};

const getQuestionnaires = questionaireData => {
	let request = {
		type: questionaireData.selectedDeliveryQuestionnaireType || "FLAT",
		deliveryQuestionnaires: [],
		serviceQuestionnaires: []
	};
	if (questionaireData.deliveryQuestions && questionaireData.deliveryQuestions.length > 0) {
		let groupedServices = Enumerable.from(questionaireData.deliveryQuestions)
			.groupBy(x => x.serviceReference)
			.select(function (grp) {
				return {
					id: grp.key(),
					questions: grp.toArray()
				};
			})
			.toArray();
		console.log(groupedServices);
		groupedServices.forEach(grpService => {
			let answers = [];
			grpService.questions.forEach(que => {
				let answer = Enumerable.from(questionaireData.questionaireAnswers).firstOrDefault(x => x.reference === que.reference);
				if (answer != null) {
					let queAns = {
						option: answer.option,
						questionReference: answer.reference,
						textValue: answer.freeText || ""
					};
					answers.push(queAns);
				}
			});

			let dtoIsomQuestionnaire = {
				deliveryServiceItemId: grpService.id,
				type: questionaireData.selectedDeliveryQuestionnaireType,
				questionnaire: {
					answers: answers
				}
			};
			request.deliveryQuestionnaires.push(dtoIsomQuestionnaire);
		});
	}
	if (questionaireData.serviceQuestions && questionaireData.serviceQuestions.length > 0) {
		let groupedServiceQuestions = Enumerable.from(questionaireData.serviceQuestions)
			.groupBy(x => x.providedServiceItemId)
			.select(function (grp) {
				return {
					id: grp.key(),
					type: questionaireData.selectedDeliveryQuestionnaireType || "FLAT",
					questions: grp.toArray()
				};
			})
			.toArray();
		groupedServiceQuestions.forEach(grpService => {
			let answers = [];
			grpService.questions.forEach(que => {
				let answer = Enumerable.from(questionaireData.questionaireAnswers).firstOrDefault(x => x.reference === que.reference);
				if (answer != null) {
					let queAns = {
						option: answer.option,
						questionReference: answer.reference,
						textValue: answer.freeText
					};
					answers.push(queAns);
				}
			});

			let dtoIsomQuestionnaire = {
				providedServiceItemId: grpService.id.includes("SGR") ? grpService.id : `SGR${grpService.id}`,
				type: grpService.type,
				questionnaire: {
					answers: answers
				}
			};
			request.serviceQuestionnaires.push(dtoIsomQuestionnaire);
		});
	}

	return request;
};

const getOrderPayment = (orderSummary, currencyCode, priceViewType, useExclusive) => {
	let summary = orderSummary.summary.find(x => x.viewType === priceViewType);

	return {
		amount: useExclusive ? summary.inclSavings.priceExclTax : summary.inclSavings.priceInclTax,
		currencyCode: currencyCode
	};
};

const mapIsomCustomer = (customer, customerBilling) => {
	let billingContact;
	let deliveryContact;

	billingContact = {
		address: getAddress(customerBilling),
		contactDetails: getContactDetails(customerBilling),
		personalInfo: getPersonalInfo(customerBilling)
	};

	deliveryContact = {
		address: getAddress(customer),
		contactDetails: getContactDetails(customer),
		personalInfo: getPersonalInfo(customer)
	};

	let prefferedlocale = LocaleHelper.formatLocaleIso(customer.preferredLocale);
	let cusData = {
		globalCustomerId: customer.partyId,
		preferredLocale: prefferedlocale,
		registeredType: customer.partyId && customer.partyId !== "" ? "REGISTERED" : "GUEST",
		isSameBillingAndDeliveryContact: !customer.isAddedBillAddress,
		billingContact: billingContact,
		deliveryContact: deliveryContact
	};
	let customerData = {};

	if (customer.isBusinessCustomer) {
		customerData.businessCustomer = cusData;
		customerData.businessCustomer.businessInfo = {
			businessName: customer.businessName,
			organizationNo: customer.organizationNo
		};
	} else {
		customerData.privateCustomer = cusData;
	}

	return customerData;
};

const getAddress = customer => {
	return {
		addressLine1: customer.addressLine1,
		addressLine2: customer.addressLine2,
		addressLine3: customer.addressLine3,
		attention: customer.attention,
		careOfName: customer.careOfName,
		city: customer.city,
		countryCode: customer.foreignAddressCountryCode || customer.countryCode,
		countyName: customer.countyName,
		prefecture: customer.prefecture,
		stateCode: Specifications.usesPrefectureSpec(customer.countryCode) ? customer.prefecture || customer.stateCode : customer.stateCode,
		zipCode: customer.zipCode
	};
};

const getPersonalInfo = customer => {
	return {
		familyNo: customer.ikeaFamilyNumber,
		firstName: customer.firstName,
		foreignCountry: customer.foreignCountry,
		genderCode: customer.genderCode,
		lastName: customer.lastName,
		middleName: customer.middleName,
		pan: customer.panNo,
		phoneticName: customer.phoneticName,
		taxCode: customer.taxCode,
		taxCodeType: customer.taxIdType && customer.taxIdType !== undefined && customer.taxIdType.includes("NA-") ? "NA" : customer.taxIdType,
		title: customer.title,
		phoneticBusinessName: customer.isBusinessCustomer ? customer.phoneticBusinessName : "",
		phoneticLastName: customer.phoneticLastName,
		phoneticFirstName: customer.phoneticFirstName,
		recipientCodeType: customer.recipientCodeType,
		recipientCode: customer.recipientCode,
		taxIdentifierNo: customer.taxIdentifierNo
	};
};

const getContactDetails = customer => {
	const config = SalesCoworkerConfiguration.getUserConfig();
	const { countryCode } = config;
	return {
		email: customer.email,
		mobile: getInternationalMobileFormat(customer.mobilePhone, countryCode),
		phone: customer.landlinePhone
	};
};
const buildPickPoint = pickupPoint => {
	if (pickupPoint) {
		return {
			address: {
				addressLine1: pickupPoint.addressLine1,
				addressLine2: pickupPoint.addressLine2,
				addressLine3: pickupPoint.addressLine3,
				addressLine4: pickupPoint.addressLine4,
				city: pickupPoint.city,
				country: pickupPoint.country,
				state: pickupPoint.state,
				zipCode: pickupPoint.zipCode
			},
			distance: pickupPoint.distance,
			distanceUnitOfMeasure: "KM",
			id: pickupPoint.id,
			identifier: pickupPoint.identifier,
			lsc: {
				code: pickupPoint.lsc.split(".")[1],
				type: pickupPoint.lsc.split(".")[0]
			},
			name: pickupPoint.name,
			openingHours: getOpenHours(pickupPoint)
		};
	}
	return;
};

const getOpenHours = pickupPoint => {
	const openHours = [];
	const weekdays = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];

	[
		pickupPoint.openingHoursSunTime,
		pickupPoint.openingHoursMonTime,
		pickupPoint.openingHoursTueTime,
		pickupPoint.openingHoursWedTime,
		pickupPoint.openingHoursThuTime,
		pickupPoint.openingHoursFriTime,
		pickupPoint.openingHoursSatTime
	].forEach((x, index) => {
		openHours.push({
			dayOfWeek: weekdays[index],
			timeSlot: {
				end: x ? x.split("-")[1] : "closed",
				start: x ? x.split("-")[0] : "closed"
			}
		});
	});

	return openHours;
};

const getProvidedServicesV2 = (selectedServices, items, locale, lineIds) => {
	let id =
		lineIds.reduce(function (a, b) {
			return a + b;
		}, 0) + 1;

	return selectedServices.map(srv => {
		let srvPrice;
		srvPrice = {
			amount: srv.price.priceInclTax || srv.price.priceExclTax,
			currencyCode: srv.price.currencyCode
		};
		id = id + 1;
		return {
			serviceId: srv.lineId || id,
			serviceItemId: srv.serviceItemNo,
			serviceProductId: srv.serviceProductId,
			priceCalculationValue: parseInt(srv.priceCalculationValue),
			servicePrice: srvPrice,
			serviceProviderId: srv.serviceProviderId,
			capacityUnitCode: srv.capacityUnit,
			serviceProviderName: srv.serviceProviderName,
			servicePaymentTo: srv.serviceProviderPreferredPayment,
			serviceTimeWindows: srv.timeWindows.map(tw => {
				return {
					timeWindowId: tw.id,
					capacityAllocated: parseInt(srv.capacityNeeded),
					fromDateTime: tw.fromDateTime,
					toDateTime: tw.toDateTime,
					timeZone: tw.timeZone
				};
			}),
			itemLineReferenceIds: srv.referenceItems.map(item => {
				let delitem = items.find(x => x.refId.toString() === item.serviceReference.lineId.toString());
				return delitem.id;
			}),
			serviceRequestedLocale: locale
		};
	});
};

const getDeliveryArrangementsv2 = (bookedServices, items) => {
	if (bookedServices.length === 0) {
		return [];
	}
	let cartItems = Enumerable.from(items);
	let arrangements = [];
	bookedServices.forEach(sol => {
		let arrgenmentDeliveries = [];

		sol.subDeliveries.forEach(subdel => {
			let delPrice = subdel.price;
			delPrice = {
				amount: delPrice.amount || delPrice.priceInclTax,
				currencyCode: delPrice.currencyCode
			};

			let delItems = Enumerable.from(subdel.referenceItems)
				.select(function (delRefitem) {
					let orderItem = cartItems.firstOrDefault(y => y.refId.toString() === delRefitem.serviceReference.lineId.toString());
					return {
						refLineId: delRefitem.serviceReference.lineId,
						itemLineId: orderItem.id,
						quantity: parseInt(delRefitem.serviceReference.quantity),
						fulfillingUnit: {
							type: delRefitem.timeWindowLineItem.shipNode.split(".")[0],
							code: delRefitem.timeWindowLineItem.shipNode.split(".")[1]
						},
						exceptionalQty: delRefitem.timeWindowLineItem.isExceptionalQty
					};
				})
				.toArray();
			let timeWindow = Enumerable.from(subdel.timeWindows).first();

			let arrangementDel = {
				deliveryNo: isNaN(subdel.deliveryId) ? undefined : subdel.deliveryId,
				serviceItemId: subdel.serviceItemId,
				deliveryPrice: delPrice,
				unitOfMeasure: subdel.unitMeasure,
				timeWindow: {
					fromLocalDateTime: moment(timeWindow.fromDateTime).format("YYYY-MM-DDTHH:mm:ss"),
					toLocalDateTime: moment(timeWindow.toDateTime).format("YYYY-MM-DDTHH:mm:ss"),

					timeWindowId: timeWindow.id,
					resourcePoolId: timeWindow.resourceId,
					transferringUnit: {
						type: timeWindow.node.split(".")[0],
						code: timeWindow.node.split(".")[1]
					},
					timeZone: timeWindow.timeZoneId
				},
				deliveryItems: delItems,
				exceptionalVolume: timeWindow.isExceptionalVolume,
				pickupPoint: buildPickPoint(subdel.pickupPoint)
			};
			arrangementDel.capabilities = [];
			if (subdel.authoritytoleaveSelected) {
				arrangementDel.capabilities.push("AUTH_TO_LEAVE");
			}
			if (subdel.wheelChairCapable) {
				arrangementDel.capabilities.push("WHEEL_CHAIR");
			}
			if (timeWindow.isRangeOfDays) {
				arrangementDel.capabilities.push("RANGE_OF_DAYS");
			}
			if (subdel.mergeOrder && subdel.mergeOrderSelected && subdel.mergeValid) {
				arrangementDel.mergeOrderNo = subdel.mergeOrder.mergeOrderNo;
				arrangementDel.mergeDeliveryNo = subdel.mergeOrder.mergeDeliveryNo;
			}
			arrgenmentDeliveries.push(arrangementDel);
		});

		let arrangement = {
			deliveryArrangementId: sol.deliveryArrangementsId,
			fulfillmentMethod: sol.fulfillmentMethod,
			type: sol.fulfillmentType,
			deliveryServices: arrgenmentDeliveries
		};
		arrangements.push(arrangement);
	});
	return arrangements;
};

export default IsomOrderModificationService;
