import styled from "styled-components";
import React from "react";
import Grid from "./Grid";
import { device } from "@coworker/sharedlibrary";

const ImageGrid = styled(Grid)`
	width: ${props => props.width || "81px"};
	height: ${props => props.height || "61px"};
	align-self: ${props => props.align || "flex-start"};

	img {
		background-size: contain;
		width: ${props => props.width || "81px"};
		height: ${props => props.height || "61px"};
	}

	img:hover {
		cursor: pointer;
	}

	@media ${props => props.breakpoint || device.tablet} {
		position: ${props => props.theme.position};
		top: ${props => props.theme.top};
		right: ${props => props.theme.right};
	}
`;

const defaultTheme = {
	position: "static",
	top: "auto",
	right: "auto"
};

const itemCardTheme = {
	position: "absolute",
	top: "20px",
	right: "20px"
};

const ItemImageControl = ({ item, width, height, align, theme, breakpoint }) => {
	const imageurl = item.imageUrl;
	if (imageurl && imageurl.startsWith("http")) {
		return (
			<ImageGrid width={width} height={height} align={align} theme={theme === "itemCardTheme" ? itemCardTheme : defaultTheme} breakpoint={breakpoint}>
				<img src={imageurl} alt="" />
			</ImageGrid>
		);
	}
	return "";
};

export default ItemImageControl;
