import { updateMfeConfigs } from "./../../utils/shellObjectService";
import { push } from "connected-react-router";
import { setBusinessUnitSelector, setCartCurrency, setOrderCreationMethod } from "./../cart/cart.thunks";
import { GaHelper } from "@coworker/sharedlibrary";
import { LocaleHelper, RequestHelper } from "@coworker/sharedlibrary";

import { DfpKey } from "@coworker/sharedlibrary";
import { actions } from "./../locale/index";

import {
	SHOW_SEARCH,
	HIDE_SEARCH,
	SHOW_LOADING,
	SET_COWORKER_CONFIG,
	SHOW_HEADER,
	SHOW_APP_NOTIFICATION,
	HIDE_APP_NOTIFICATION,
	EXPAND_APP_NOTIFICATION,
	CHANGE_ADDRESS_PROVIDER,
	CHANGE_SELECTED_HFB,
	SELECTED_ITEM_TYPE,
	SET_FEATURES,
	SET_ORDER_MODIFICATION_QUEUE,
	SET_USER_LOCALE,
	SET_COWORKER_AREA,
	SET_CALLING_APPLICATION
} from "./shellActionTypes";
//import { clearAllRecentOrders } from "../recentOrder/recentOrderAction";
import { clearCart } from "../cart/cartActions";
import { dispatchEvent as eventDispatcher, eventsToDispatch } from "@coworker/sharedlibrary";
import AppLogger from "../../logger/appLogger";
import HomeService from "../../service/Home/homeService";
import { UserType } from "../auth/reducer";
export const appactionCreators = {
	setCallingApplication: callingApplication => dispatch => {
		dispatch({ type: SET_CALLING_APPLICATION, payload: callingApplication });
	},
	showSearch: () => dispatch => {
		dispatch({ type: SHOW_SEARCH });
	},
	hideSearch: () => dispatch => {
		dispatch({ type: HIDE_SEARCH });
	},
	showBusyLoading: isvisible => dispatch => {
		dispatch({ type: SHOW_LOADING, payload: isvisible });
	},
	setHomeContext: userData => dispatch => {
		const { coworkerData } = userData;
		dispatch(appactionCreators.updateShellAttributes(coworkerData));

		dispatch({ type: SET_COWORKER_CONFIG, payload: userData });
		return Promise.resolve();
	},
	updateShellAttributes: coworkerData => dispatch => {
		if (coworkerData && coworkerData.userSettings && coworkerData.userSettings.length > 0) {
			let setting = coworkerData.userSettings.find(x => x.buCode === coworkerData.buCode);
			if (setting) {
				if (setting.addressProvider) {
					dispatch(appactionCreators.changeAddressProvider(setting.addressProvider));
				}
				if (
					setting.selectedHfb &&
					setting.selectedHfb.length > 0 &&
					setting.selectedHfb.filter &&
					setting.selectedHfb.filter(x => x !== "").length > 0
				) {
					dispatch(appactionCreators.changeSelectedHfb(setting.selectedHfb));
				}
				if (setting.customerMeetingPoint) {
					dispatch(setBusinessUnitSelector(setting.customerMeetingPoint));
				}
				if (setting.orderCreationMethod) {
					dispatch(setOrderCreationMethod(setting.orderCreationMethod));
				}
			}
		}
	},
	showorHideAppHeader: show => dispatch => {
		dispatch({ type: SHOW_HEADER, payload: show });
	},

	changeUserStore: (storeNo, navigateToRoot = true) => async (dispatch, getState) => {
		let state = getState();

		let { homeService, userId, buCode } = state.appController;

		if (buCode === storeNo) {
			return Promise.resolve(true);
		}

		let { userMode } = state.authController;
		try {
			dispatch({ type: SHOW_LOADING, payload: true });

			let home = new HomeService();
			home.sessionId = homeService.sessionId;
			home.environment = homeService.environment;
			if (userMode === UserType.GUEST) {
				await home.getHomeByStore(storeNo, userId);
			} else {
				await home.getUserInfo(userId, storeNo);
			}

			let countryCode = home.coworkerData.countryCode;

			if (!home.preferences || home.preferences.length === 0) await home.getappPreferrences(storeNo, countryCode);
			if (!home.storeInfo) await home.getStoreInfo(storeNo, true);

			let currency = "USD";
			var currencypref = home.getPreference(DfpKey.PRIMARY_CURRENCY);
			if (currencypref) {
				currency = currencypref.value || "USD";
			}

			dispatch(appactionCreators.updateShellAttributes(home.coworkerData));
			dispatch({
				type: SET_COWORKER_CONFIG,
				payload: { ...home }
			});

			dispatch(actions.changeLocale(home.coworkerData.primaryLocale));

			AppLogger.info("User Changed Store", "STORECHANGE", {
				newBu: storeNo
			});

			dispatch(setCartCurrency(currency));

			RequestHelper.setOverridedStore(storeNo);

			GaHelper.SendEvent("UserLocation", "Country", LocaleHelper.getCountryName(home.coworkerData.countryCode));
			GaHelper.SendEvent("UserLocation", "BuCode", storeNo);
			GaHelper.SendEvent("ChangeStore", "ChangeStore", `user changed to store-${storeNo}`);
			GaHelper.SendDimenstion("dimension1", home.coworkerData.countryCode);
			GaHelper.SendDimenstion("dimension2", storeNo);
			if (navigateToRoot) dispatch({ type: SHOW_LOADING, payload: false });

			eventDispatcher(eventsToDispatch.STORE_CHANGE, {
				storeNo: storeNo
			});
		} catch (error) {
			console.log(error);
			dispatch({ type: SHOW_LOADING, payload: false });
			AppLogger.info("User failed to swtich Store", "STORECHANGE", {
				newBu: storeNo
			});
			return Promise.reject("Failed");
		}
		if (navigateToRoot) {
			dispatch({ type: SHOW_LOADING, payload: false });
			dispatch(push("/"));
		}
		updateMfeConfigs();
		dispatch(clearCart());
		return Promise.resolve(true);
	},

	showNotification: (type, title, message) => (dispatch, getState) => {
		const { isExpandAppNotification } = getState().appController;

		if (isExpandAppNotification) {
			dispatch({ type: EXPAND_APP_NOTIFICATION, payload: false });
		}

		dispatch({
			type: SHOW_APP_NOTIFICATION,
			payload: {
				type: type,
				title: title,
				message: message
			}
		});

		let timer = setInterval(() => {
			const { isExpandAppNotification } = getState().appController;
			if (!isExpandAppNotification) {
				dispatch({ type: HIDE_APP_NOTIFICATION, payload: {} });
			}

			clearTimeout(timer);
		}, 4000);
	},
	hideNotification: () => dispatch => {
		dispatch({ type: HIDE_APP_NOTIFICATION, payload: {} });
	},
	expandNotification: isexpand => dispatch => {
		dispatch({
			type: EXPAND_APP_NOTIFICATION,
			payload: isexpand
		});
	},
	changeAddressProvider: addressProvider => {
		return {
			type: CHANGE_ADDRESS_PROVIDER,
			payload: addressProvider
		};
	},
	changeSelectedHfb: selectedHfb => {
		return {
			type: CHANGE_SELECTED_HFB,
			payload: selectedHfb
		};
	},
	setCoworkerWorkAreaHfb: workarea => {
		return {
			type: SET_COWORKER_AREA,
			payload: workarea
		};
	},
	setSelectedItemType: itemType => {
		return {
			type: SELECTED_ITEM_TYPE,
			payload: itemType
		};
	},
	setFeatures: features => {
		return {
			type: SET_FEATURES,
			payload: features
		};
	},

	setOrderModificationQueue: (retailItem = null, qty = null) => {
		return {
			type: SET_ORDER_MODIFICATION_QUEUE,
			payload: {
				retailItem: retailItem,
				qty: qty
			}
		};
	},
	setUserLocale: locale => dispatch => {
		dispatch({ type: SET_USER_LOCALE, payload: locale });
	}
};

export const NotificationType = {
	Error: "error",
	Warn: "warn",
	Success: "success"
};
