import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { device } from "@coworker/sharedlibrary";

import QuotationWrapperService from "../../service/quotation/quoationApi";

import { Dropdown, DropdownItem } from "@ingka/dropdown";

import { List } from "../../components/sharedlibrary";

const DropDownSelect = styled(Dropdown)`
	width: 400px;
	margin-top: 20px;
	margin-bottom: 20px;

	@media ${device.small} {
		width: 100%;
	}
`;

const ProjectsView = ({ customerId, messages, onProjectChange, projectId }) => {
	const [projects, setProjects] = useState([]);

	useEffect(() => {
		if (customerId) {
			QuotationWrapperService.getCusomerProjects(customerId).then(projects => {
				setProjects(projects);
			});
		}
	}, [customerId]);

	return (
		<List>
			<DropDownSelect
				defaultValue={projectId}
				id="prjectsList"
				label={messages.SG_LBL_Projects || "Projects"}
				onChange={e => {
					var prj = projects.find(x => x.projectRefNo === e.target.value);
					onProjectChange && onProjectChange(prj);
				}}
			>
				{projects.map(x => (
					<DropdownItem key={x.projectRefNo} name={x.projectName} value={x.projectRefNo} />
				))}
			</DropDownSelect>
		</List>
	);
};

export default ProjectsView;
