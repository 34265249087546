import Button from "@ingka/button";
import React from "react";
import { useInputPopup } from "../../../components/inputPopup";

const AddServiceButton = ({ messages }) => {
	const { getInput } = useInputPopup();

	async function showServiceView() {
		await getInput("orderService", {
			viewState: "ZIPCODE_VIEW",
			view: "SERVICE_VIEW"
		});
	}
	return (
		<Button
			data-testid="bookservicebtn"
			text={messages.SG_BTN_FindServices || "Book Services"}
			type="secondary"
			onClick={() => {
				showServiceView();
			}}
		/>
	);
};
export default AddServiceButton;
