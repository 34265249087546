import React from "react";

import Modal, { Sheets, ModalBody } from "@ingka/modal";

import ItemAvailabilityView from "../../microfrontends/itemAvailabilityView";

const focus = {
	returnFocus: false,
	autoFocus: true
};

const StockAndLocationDialog = ({ onClose, retailItem, isStock }) => {
	return (
		<Modal
			focusLockProps={focus}
			visible={true}
			handleCloseBtn={() => {
				onClose();
			}}
		>
			<Sheets>
				<ModalBody>
					<ItemAvailabilityView retailItem={retailItem} isStock={isStock} />
				</ModalBody>
			</Sheets>
		</Modal>
	);
};

export default StockAndLocationDialog;
