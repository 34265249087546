/* eslint-disable no-prototype-builtins */
class KeyValueModel {
	constructor(id, spanish, portuguese, slovenian, english) {
		this.id = id;
		this.spanish = spanish;
		this.portuguese = portuguese;
		this.slovenian = slovenian;
		this.english = english;
	}
}
const getCountryNames = () => {
	return [
		new KeyValueModel("AD", "Andorra", "Andorra", "Andora", "Andorra"),
		new KeyValueModel("AE", "Emiratos Árabes Unidos", "Emirados Árabes Unidos", "Združeni arabski emirati", "United Arab Emirates"),
		new KeyValueModel("AF", "Afganistán", "Afeganistão", "Afganistan", "Afghanistan"),
		new KeyValueModel("AG", "Antigua y Barbuda", "Antígua e Barbuda", "Antigva in Barbuda", "Antigua & Barbuda"),
		new KeyValueModel("AI", "Anguila", "Anguilla", "Angvila", "Anguilla"),
		new KeyValueModel("AL", "Albania", "Albânia", "Albanija", "Albania"),
		new KeyValueModel("AM", "Armenia", "Armênia", "Armenija", "Armenia"),
		new KeyValueModel("AO", "Angola", "Angola", "Angola", "Angola"),
		new KeyValueModel("AQ", "Antártida", "Antártida", "Antarktika", "Antarctica"),
		new KeyValueModel("AR", "Argentina", "Argentina", "Argentina", "Argentina"),
		new KeyValueModel("AS", "Samoa Americana", "Samoa Americana", "Ameriška Samoa", "American Samoa"),
		new KeyValueModel("AT", "Austria", "Áustria", "Avstrija", "Austria"),
		new KeyValueModel("AU", "Australia", "Austrália", "Avstralija", "Australia"),
		new KeyValueModel("AW", "Aruba", "Aruba", "Aruba", "Aruba"),
		new KeyValueModel("AX", "Åland", "Ilhas Åland", "Ålandski otoki", "Åland Islands"),
		new KeyValueModel("AZ", "Azerbaiyán", "Azerbaijão", "Azerbajdžan", "Azerbaijan"),
		new KeyValueModel("BA", "Bosnia y Herzegovina", "Bósnia e Herzegovina", "Bosna in Hercegovina", "Bosnia & Herzegovina"),
		new KeyValueModel("BB", "Barbados", "Barbados", "Barbados", "Barbados"),
		new KeyValueModel("BD", "Bangladés", "Bangladesh", "Bangladeš", "Bangladesh"),
		new KeyValueModel("BE", "Bélgica", "Bélgica", "Belgija", "Belgium"),
		new KeyValueModel("BF", "Burkina Faso", "Burkina Faso", "Burkina Faso", "Burkina Faso"),
		new KeyValueModel("BG", "Bulgaria", "Bulgária", "Bolgarija", "Bulgaria"),
		new KeyValueModel("BH", "Baréin", "Bahrein", "Bahrajn", "Bahrain"),
		new KeyValueModel("BI", "Burundi", "Burundi", "Burundi", "Burundi"),
		new KeyValueModel("BJ", "Benín", "Benim", "Benin", "Benin"),
		new KeyValueModel("BL", "San Bartolomé", "São Bartolomeu", "Saint Barthélemy", "St. Barthélemy"),
		new KeyValueModel("BM", "Bermudas", "Bermudas", "Bermudi", "Bermuda"),
		new KeyValueModel("BN", "Brunéi", "Brunei", "Brunej", "Brunei"),
		new KeyValueModel("BO", "Bolivia", "Bolívia", "Bolivija", "Bolivia"),
		new KeyValueModel("BQ", "Bonaire, San Eustaquio y Saba", "Países Baixos Caribenhos", "Nizozemski Karibi", "Caribbean Netherlands"),
		new KeyValueModel("BR", "Brasil", "Brasil", "Brazilija", "Brazil"),
		new KeyValueModel("BS", "Bahamas", "Bahamas", "Bahami", "Bahamas"),
		new KeyValueModel("BT", "Bután", "Butão", "Butan", "Bhutan"),
		new KeyValueModel("BV", "Isla Bouvet", "Ilha Bouvet", "Bouvetov otok", "Bouvet Island"),
		new KeyValueModel("BW", "Botsuana", "Botswana", "Bocvana", "Botswana"),
		new KeyValueModel("BY", "Bielorrusia", "Bielorrússia", "Belorusija", "Belarus"),
		new KeyValueModel("BZ", "Belice", "Belize", "Belize", "Belize"),
		new KeyValueModel("CA", "Canadá", "Canadá", "Kanada", "Canada"),
		new KeyValueModel("CC", "Islas Cocos", "Ilhas Cocos (Keeling)", "Kokosovi otoki", "Cocos (Keeling) Islands"),
		new KeyValueModel("CD", "República Democrática del Congo", "República Democrática do Congo", "Demokratična republika Kongo", "Congo - Kinshasa"),
		new KeyValueModel("CF", "República Centroafricana", "República Centro-Africana", "Centralnoafriška republika", "Central African Republic"),
		new KeyValueModel("CG", "República del Congo", "República do Congo", "Kongo - Brazzaville", "Congo - Brazzaville"),
		new KeyValueModel("CH", "Suiza", "Suíça", "Švica", "Switzerland"),
		new KeyValueModel("CI", "Costa de Marfil", "Costa do Marfim", "Slonokoščena obala", "Côte d’Ivoire"),
		new KeyValueModel("CK", "Islas Cook", "Ilhas Cook", "Cookovi otoki", "Cook Islands"),
		new KeyValueModel("CL", "Chile", "Chile", "Čile", "Chile"),
		new KeyValueModel("CM", "Camerún", "Camarões", "Kamerun", "Cameroon"),
		new KeyValueModel("CN", "China", "China", "Kitajska", "China"),
		new KeyValueModel("CO", "Colombia", "Colômbia", "Kolumbija", "Colombia"),
		new KeyValueModel("CR", "Costa Rica", "Costa Rica", "Kostarika", "Costa Rica"),
		new KeyValueModel("CU", "Cuba", "Cuba", "Kuba", "Cuba"),
		new KeyValueModel("CV", "Cabo Verde", "Cabo Verde", "Zelenortski otoki", "Cape Verde"),
		new KeyValueModel("CW", "Curazao", "Curaçao", "Curaçao", "Curaçao"),
		new KeyValueModel("CX", "Isla de Navidad", "Ilha do Natal", "Božični otok", "Christmas Island"),
		new KeyValueModel("CY", "Chipre", "Chipre", "Ciper", "Cyprus"),
		new KeyValueModel("CZ", "República Checa", "República Checa", "Češka", "Czechia"),
		new KeyValueModel("DE", "Alemania", "Alemanha", "Nemčija", "Germany"),
		new KeyValueModel("DJ", "Yibuti", "Djibouti", "Džibuti", "Djibouti"),
		new KeyValueModel("DK", "Dinamarca", "Dinamarca", "Danska", "Denmark"),
		new KeyValueModel("DM", "Dominica", "Dominica", "Dominika", "Dominica"),
		new KeyValueModel("DO", "República Dominicana", "República Dominicana", "Dominikanska republika", "Dominican Republic"),
		new KeyValueModel("DZ", "Argelia", "Argélia", "Alžirija", "Algeria"),
		new KeyValueModel("EC", "Ecuador", "Equador", "Ekvador", "Ecuador"),
		new KeyValueModel("EE", "Estonia", "Estónia", "Estonija", "Estonia"),
		new KeyValueModel("EG", "Egipto", "Egito", "Egipt", "Egypt"),
		new KeyValueModel("EH", "República Árabe Saharaui Democrática", "Saara Ocidental", "Zahodna Sahara", "Western Sahara"),
		new KeyValueModel("ER", "Eritrea", "Eritreia", "Eritreja", "Eritrea"),
		new KeyValueModel("ES", "España", "Espanha", "Španija", "Spain"),
		new KeyValueModel("ET", "Etiopía", "Etiópia", "Etiopija", "Ethiopia"),
		new KeyValueModel("FI", "Finlandia", "Finlândia", "Finska", "Finland"),
		new KeyValueModel("FJ", "Fiyi", "Fiji", "Fidži", "Fiji"),
		new KeyValueModel("FK", "Islas Malvinas", "Ilhas Malvinas", "Falklandski otoki", "Falkland Islands"),
		new KeyValueModel("FM", "Micronesia", "Estados Federados da Micronésia", "Mikronezija", "Micronesia"),
		new KeyValueModel("FO", "Islas Feroe", "Ilhas Feroé", "Ferski otoki", "Faroe Islands"),
		new KeyValueModel("FR", "Francia", "França", "Francija", "France"),
		new KeyValueModel("GA", "Gabón", "Gabão", "Gabon", "Gabon"),
		new KeyValueModel("GB", "Reino Unido", "Reino Unido", "Združeno kraljestvo", "United Kingdom"),
		new KeyValueModel("GD", "Granada", "Granada", "Grenada", "Grenada"),
		new KeyValueModel("GE", "Georgia", "Geórgia", "Gruzija", "Georgia"),
		new KeyValueModel("GF", "Guayana Francesa", "Guiana Francesa", "Francoska Gvajana", "French Guiana"),
		new KeyValueModel("GG", "Guernsey", "Guernsey", "Guernsey", "Guernsey"),
		new KeyValueModel("GH", "Ghana", "Gana", "Gana", "Ghana"),
		new KeyValueModel("GI", "Gibraltar", "Gibraltar", "Gibraltar", "Gibraltar"),
		new KeyValueModel("GL", "Groenlandia", "Gronelândia", "Grenlandija", "Greenland"),
		new KeyValueModel("GM", "Gambia", "Gâmbia", "Gambija", "Gambia"),
		new KeyValueModel("GN", "Guinea", "Guiné", "Gvineja", "Guinea"),
		new KeyValueModel("GP", "Guadalupe", "Guadalupe", "Guadeloupe", "Guadeloupe"),
		new KeyValueModel("GQ", "Guinea Ecuatorial", "Guiné Equatorial", "Ekvatorialna Gvineja", "Equatorial Guinea"),
		new KeyValueModel("GR", "Grecia", "Grécia", "Grčija", "Greece"),
		new KeyValueModel(
			"GS",
			"Islas Georgias del Sur y Sandwich del Sur",
			"Ilhas Geórgia do Sul e Sandwich do Sul",
			"Južna Georgia in Južni Sandwichevi otoki",
			"South Georgia & South Sandwich Islands"
		),
		new KeyValueModel("GT", "Guatemala", "Guatemala", "Gvatemala", "Guatemala"),
		new KeyValueModel("GU", "Guam", "Guam", "Guam", "Guam"),
		new KeyValueModel("GW", "Guinea-Bisáu", "Guiné-Bissau", "Gvineja Bissau", "Guinea-Bissau"),
		new KeyValueModel("GY", "Guyana", "Guiana", "Gvajana", "Guyana"),
		new KeyValueModel("HK", "Hong Kong", "Hong Kong", "Posebno administrativno območje LR Kitajske Hongkong", "Hong Kong SAR China"),
		new KeyValueModel("HM", "Islas Heard y McDonald", "Ilha Heard e Ilhas McDonald", "Heardov otok in McDonaldovi otoki", "Heard & McDonald Islands"),
		new KeyValueModel("HN", "Honduras", "Honduras", "Honduras", "Honduras"),
		new KeyValueModel("HR", "Croacia", "Croácia", "Hrvaška", "Croatia"),
		new KeyValueModel("HT", "Haití", "Haiti", "Haiti", "Haiti"),
		new KeyValueModel("HU", "Hungría", "Hungria", "Madžarska", "Hungary"),
		new KeyValueModel("ID", "Indonesia", "Indonésia", "Indonezija", "Indonesia"),
		new KeyValueModel("IE", "Irlanda", "Irlanda", "Irska", "Ireland"),
		new KeyValueModel("IL", "Israel", "Israel", "Izrael", "Israel"),
		new KeyValueModel("IM", "Isla de Man", "Ilha de Man", "Otok Man", "Isle of Man"),
		new KeyValueModel("IN", "India", "Índia", "Indija", "India"),
		new KeyValueModel(
			"IO",
			"Territorio Británico del Océano Índico",
			"Território Britânico do Oceano Índico",
			"Britansko ozemlje v Indijskem oceanu",
			"British Indian Ocean Territory"
		),
		new KeyValueModel("IQ", "Irak", "Iraque", "Irak", "Iraq"),
		new KeyValueModel("IR", "Irán", "Irão", "Iran", "Iran"),
		new KeyValueModel("IS", "Islandia", "Islândia", "Islandija", "Iceland"),
		new KeyValueModel("IT", "Italia", "Itália", "Italija", "Italy"),
		new KeyValueModel("JE", "Jersey", "Jersey", "Jersey", "Jersey"),
		new KeyValueModel("JM", "Jamaica", "Jamaica", "Jamajka", "Jamaica"),
		new KeyValueModel("JO", "Jordania", "Jordânia", "Jordanija", "Jordan"),
		new KeyValueModel("JP", "Japón", "Japão", "Japonska", "Japan"),
		new KeyValueModel("KE", "Kenia", "Quênia", "Kenija", "Kenya"),
		new KeyValueModel("KG", "Kirguistán", "Quirguistão", "Kirgizistan", "Kyrgyzstan"),
		new KeyValueModel("KH", "Camboya", "Camboja", "Kambodža", "Cambodia"),
		new KeyValueModel("KI", "Kiribati", "Kiribati", "Kiribati", "Kiribati"),
		new KeyValueModel("KM", "Comoras", "Comores", "Komori", "Comoros"),
		new KeyValueModel("KN", "San Cristóbal y Nieves", "São Cristóvão e Névis", "Saint Kitts in Nevis", "St. Kitts & Nevis"),
		new KeyValueModel("KP", "Corea del Norte", "Coreia do Norte", "Severna Koreja", "North Korea"),
		new KeyValueModel("KR", "Corea del Sur", "Coreia do Sul", "Južna Koreja", "South Korea"),
		new KeyValueModel("KW", "Kuwait", "Kuwait", "Kuvajt", "Kuwait"),
		new KeyValueModel("KY", "Islas Caimán", "Ilhas Cayman", "Kajmanski otoki", "Cayman Islands"),
		new KeyValueModel("KZ", "Kazajistán", "Cazaquistão", "Kazahstan", "Kazakhstan"),
		new KeyValueModel("LA", "Laos", "Laos", "Laos", "Laos"),
		new KeyValueModel("LB", "Líbano", "Líbano", "Libanon", "Lebanon"),
		new KeyValueModel("LC", "Santa Lucía", "Santa Lúcia", "Saint Lucia", "St. Lucia"),
		new KeyValueModel("LI", "Liechtenstein", "Liechtenstein", "Lihtenštajn", "Liechtenstein"),
		new KeyValueModel("LK", "Sri Lanka", "Sri Lanka", "Šrilanka", "Sri Lanka"),
		new KeyValueModel("LR", "Liberia", "Libéria", "Liberija", "Liberia"),
		new KeyValueModel("LS", "Lesoto", "Lesoto", "Lesoto", "Lesotho"),
		new KeyValueModel("LT", "Lituania", "Lituânia", "Litva", "Lithuania"),
		new KeyValueModel("LU", "Luxemburgo", "Luxemburgo", "Luksemburg", "Luxembourg"),
		new KeyValueModel("LV", "Letonia", "Letônia", "Latvija", "Latvia"),
		new KeyValueModel("LY", "Libia", "Líbia", "Libija", "Libya"),
		new KeyValueModel("MA", "Marruecos", "Marrocos", "Maroko", "Morocco"),
		new KeyValueModel("MC", "Mónaco", "Mónaco", "Monako", "Monaco"),
		new KeyValueModel("MD", "Moldavia", "Moldávia", "Moldavija", "Moldova"),
		new KeyValueModel("ME", "Montenegro", "Montenegro", "Črna gora", "Montenegro"),
		new KeyValueModel("MF", "San Martín", "São Martinho", "Saint Martin", "St. Martin"),
		new KeyValueModel("MG", "Madagascar", "Madagáscar", "Madagaskar", "Madagascar"),
		new KeyValueModel("MH", "Islas Marshall", "Ilhas Marshall", "Marshallovi otoki", "Marshall Islands"),
		new KeyValueModel("MK", "Macedonia del Norte", "Macedônia do Norte", "Severna Makedonija", "North Macedonia"),
		new KeyValueModel("ML", "Malí", "Mali", "Mali", "Mali"),
		new KeyValueModel("MM", "Birmania", "Mianmar", "Mjanmar (Burma)", "Myanmar (Burma)"),
		new KeyValueModel("MN", "Mongolia", "Mongólia", "Mongolija", "Mongolia"),
		new KeyValueModel("MO", "Macao", "Macau", "Posebno administrativno območje LR Kitajske Macao", "Macao SAR China"),
		new KeyValueModel("MP", "Islas Marianas del Norte", "Marianas Setentrionais", "Severni Marianski otoki", "Northern Mariana Islands"),
		new KeyValueModel("MQ", "Martinica", "Martinica", "Martinik", "Martinique"),
		new KeyValueModel("MR", "Mauritania", "Mauritânia", "Mavretanija", "Mauritania"),
		new KeyValueModel("MS", "Montserrat", "Montserrat", "Montserrat", "Montserrat"),
		new KeyValueModel("MT", "Malta", "Malta", "Malta", "Malta"),
		new KeyValueModel("MU", "Mauricio", "Maurícia", "Mauritius", "Mauritius"),
		new KeyValueModel("MV", "Maldivas", "Maldivas", "Maldivi", "Maldives"),
		new KeyValueModel("MW", "Malaui", "Malawi", "Malavi", "Malawi"),
		new KeyValueModel("MX", "México", "México", "Mehika", "Mexico"),
		new KeyValueModel("MY", "Malasia", "Malásia", "Malezija", "Malaysia"),
		new KeyValueModel("MZ", "Mozambique", "Moçambique", "Mozambik", "Mozambique"),
		new KeyValueModel("NA", "Namibia", "Namíbia", "Namibija", "Namibia"),
		new KeyValueModel("NC", "Nueva Caledonia", "Nova Caledônia", "Nova Kaledonija", "New Caledonia"),
		new KeyValueModel("NE", "Níger", "Níger", "Niger", "Niger"),
		new KeyValueModel("NF", "Norfolk", "Ilha Norfolk", "Norfolški otok", "Norfolk Island"),
		new KeyValueModel("NG", "Nigeria", "Nigéria", "Nigerija", "Nigeria"),
		new KeyValueModel("NI", "Nicaragua", "Nicarágua", "Nikaragva", "Nicaragua"),
		new KeyValueModel("NL", "Países Bajos", "Países Baixos", "Nizozemska", "Netherlands"),
		new KeyValueModel("NO", "Noruega", "Noruega", "Norveška", "Norway"),
		new KeyValueModel("NP", "Nepal", "Nepal", "Nepal", "Nepal"),
		new KeyValueModel("NR", "Nauru", "Nauru", "Nauru", "Nauru"),
		new KeyValueModel("NU", "Niue", "Niue", "Niue", "Niue"),
		new KeyValueModel("NZ", "Nueva Zelanda", "Nova Zelândia", "Nova Zelandija", "New Zealand"),
		new KeyValueModel("OM", "Omán", "Omã", "Oman", "Oman"),
		new KeyValueModel("PA", "Panamá", "Panamá", "Panama", "Panama"),
		new KeyValueModel("PE", "Perú", "Peru", "Peru", "Peru"),
		new KeyValueModel("PF", "Polinesia Francesa", "Polinésia Francesa", "Francoska Polinezija", "French Polynesia"),
		new KeyValueModel("PG", "Papúa Nueva Guinea", "Papua-Nova Guiné", "Papua Nova Gvineja", "Papua New Guinea"),
		new KeyValueModel("PH", "Filipinas", "Filipinas", "Filipini", "Philippines"),
		new KeyValueModel("PK", "Pakistán", "Paquistão", "Pakistan", "Pakistan"),
		new KeyValueModel("PL", "Polonia", "Polónia", "Poljska", "Poland"),
		new KeyValueModel("PM", "San Pedro y Miquelón", "Saint-Pierre e Miquelon", "Saint Pierre in Miquelon", "St. Pierre & Miquelon"),
		new KeyValueModel("PN", "Islas Pitcairn", "Pitcairn", "Pitcairn", "Pitcairn Islands"),
		new KeyValueModel("PR", "Puerto Rico", "Porto Rico", "Portoriko", "Puerto Rico"),
		new KeyValueModel("PS", "Palestina", "Palestina", "Palestinsko ozemlje", "Palestinian Territories"),
		new KeyValueModel("PT", "Portugal", "Portugal", "Portugalska", "Portugal"),
		new KeyValueModel("PW", "Palaos", "Palau", "Palau", "Palau"),
		new KeyValueModel("PY", "Paraguay", "Paraguai", "Paragvaj", "Paraguay"),
		new KeyValueModel("QA", "Catar", "Catar", "Katar", "Qatar"),
		new KeyValueModel("RE", "Reunión", "Reunião", "Reunion", "Réunion"),
		new KeyValueModel("RO", "Rumania", "Roménia", "Romunija", "Romania"),
		new KeyValueModel("RS", "Serbia", "Sérvia", "Srbija", "Serbia"),
		new KeyValueModel("RU", "Rusia", "Rússia", "Rusija", "Russia"),
		new KeyValueModel("RW", "Ruanda", "Ruanda", "Ruanda", "Rwanda"),
		new KeyValueModel("SA", "Arabia Saudita", "Arábia Saudita", "Saudova Arabija", "Saudi Arabia"),
		new KeyValueModel("SB", "Islas Salomón", "Ilhas Salomão", "Salomonovi otoki", "Solomon Islands"),
		new KeyValueModel("SC", "Seychelles", "Seicheles", "Sejšeli", "Seychelles"),
		new KeyValueModel("SD", "Sudán", "Sudão", "Sudan", "Sudan"),
		new KeyValueModel("SE", "Suecia", "Suécia", "Švedska", "Sweden"),
		new KeyValueModel("SG", "Singapur", "Singapura", "Singapur", "Singapore"),
		new KeyValueModel("SH", "Santa Elena, Ascensión y Tristán de Acuña", "Santa Helena, Ascensão e Tristão da Cunha", "Sveta Helena", "St. Helena"),
		new KeyValueModel("SI", "Eslovenia", "Eslovênia", "Slovenija", "Slovenia"),
		new KeyValueModel("SJ", "Svalbard y Jan Mayen", "Svalbard e Jan Mayen", "Svalbard in Jan Mayen", "Svalbard & Jan Mayen"),
		new KeyValueModel("SK", "Eslovaquia", "Eslováquia", "Slovaška", "Slovakia"),
		new KeyValueModel("SL", "Sierra Leona", "Serra Leoa", "Sierra Leone", "Sierra Leone"),
		new KeyValueModel("SM", "San Marino", "San Marino", "San Marino", "San Marino"),
		new KeyValueModel("SN", "Senegal", "Senegal", "Senegal", "Senegal"),
		new KeyValueModel("SO", "Somalia", "Somália", "Somalija", "Somalia"),
		new KeyValueModel("SR", "Surinam", "Suriname", "Surinam", "Suriname"),
		new KeyValueModel("SS", "Sudán del Sur", "Sudão do Sul", "Južni Sudan", "South Sudan"),
		new KeyValueModel("ST", "Santo Tomé y Príncipe", "São Tomé e Príncipe", "Sao Tome in Principe", "São Tomé & Príncipe"),
		new KeyValueModel("SV", "El Salvador", "El Salvador", "Salvador", "El Salvador"),
		new KeyValueModel("SX", "Sint Maarten", "São Martinho", "Sint Maarten", "Sint Maarten"),
		new KeyValueModel("SY", "Siria", "Síria", "Sirija", "Syria"),
		new KeyValueModel("SZ", "Suazilandia", "Suazilândia", "Esvatini", "Eswatini"),
		new KeyValueModel("TC", "Islas Turcas y Caicos", "Turks e Caicos", "Otoki Turks in Caicos", "Turks & Caicos Islands"),
		new KeyValueModel("TD", "Chad", "Chade", "Čad", "Chad"),
		new KeyValueModel(
			"TF",
			"Tierras Australes y Antárticas Francesas",
			"Terras Austrais e Antárticas Francesas",
			"Francosko južno ozemlje",
			"French Southern Territories"
		),
		new KeyValueModel("TG", "Togo", "Togo", "Togo", "Togo"),
		new KeyValueModel("TH", "Tailandia", "Tailândia", "Tajska", "Thailand"),
		new KeyValueModel("TJ", "Tayikistán", "Tajiquistão", "Tadžikistan", "Tajikistan"),
		new KeyValueModel("TK", "Tokelau", "Toquelau", "Tokelau", "Tokelau"),
		new KeyValueModel("TL", "Timor Oriental", "Timor-Leste", "Timor-Leste", "Timor-Leste"),
		new KeyValueModel("TM", "Turkmenistán", "Turquemenistão", "Turkmenistan", "Turkmenistan"),
		new KeyValueModel("TN", "Túnez", "Tunísia", "Tunizija", "Tunisia"),
		new KeyValueModel("TO", "Tonga", "Tonga", "Tonga", "Tonga"),
		new KeyValueModel("TR", "Turquía", "Turquia", "Turčija", "Turkey"),
		new KeyValueModel("TT", "Trinidad y Tobago", "Trinidad e Tobago", "Trinidad in Tobago", "Trinidad & Tobago"),
		new KeyValueModel("TV", "Tuvalu", "Tuvalu", "Tuvalu", "Tuvalu"),
		new KeyValueModel("TW", "Taiwán (República de China)", "Taiwan", "Tajvan", "Taiwan"),
		new KeyValueModel("TZ", "Tanzania", "Tanzânia", "Tanzanija", "Tanzania"),
		new KeyValueModel("UA", "Ucrania", "Ucrânia", "Ukrajina", "Ukraine"),
		new KeyValueModel("UG", "Uganda", "Uganda", "Uganda", "Uganda"),
		new KeyValueModel(
			"UM",
			"Islas ultramarinas de Estados Unidos",
			"Ilhas Menores Distantes dos Estados Unidos",
			"Stranski zunanji otoki Združenih držav",
			"U.S. Outlying Islands"
		),
		new KeyValueModel("US", "Estados Unidos", "Estados Unidos", "Združene države Amerike", "United States"),
		new KeyValueModel("UY", "Uruguay", "Uruguai", "Urugvaj", "Uruguay"),
		new KeyValueModel("UZ", "Uzbekistán", "Uzbequistão", "Uzbekistan", "Uzbekistan"),
		new KeyValueModel("VA", "Vaticano, Ciudad del", "Vaticano", "Vatikan", "Vatican City"),
		new KeyValueModel("VC", "San Vicente y las Granadinas", "São Vicente e Granadinas", "Saint Vincent in Grenadine", "St. Vincent & Grenadines"),
		new KeyValueModel("VE", "Venezuela", "Venezuela", "Venezuela", "Venezuela"),
		new KeyValueModel("VG", "Islas Vírgenes Británicas", "Ilhas Virgens Britânicas", "Britanski Deviški otoki", "British Virgin Islands"),
		new KeyValueModel("VI", "Islas Vírgenes de los Estados Unidos", "Ilhas Virgens Americanas", "Ameriški Deviški otoki", "U.S. Virgin Islands"),
		new KeyValueModel("VN", "Vietnam", "Vietnã", "Vietnam", "Vietnam"),

		new KeyValueModel("WF", "Wallis y Futuna", "Wallis e Futuna", "Wallis in Futuna", "Wallis & Futuna"),
		new KeyValueModel("WS", "Samoa", "Samoa", "Samoa", "Samoa"),
		new KeyValueModel("XK", "Kosovo", "Kosovo", "Kosovo", "Kosovo"),
		new KeyValueModel("YE", "Yemen", "Iêmen", "Jemen", "Yemen"),
		new KeyValueModel("YT", "Mayotte", "Mayotte", "Mayotte", "Mayotte"),
		new KeyValueModel("ZA", "Sudáfrica", "África do Sul", "Južnoafriška republika", "South Africa"),
		new KeyValueModel("ZM", "Zambia", "Zâmbia", "Zambija", "Zambia"),
		new KeyValueModel("ZW", "Zimbabue", "Zimbabwe", "Zimbabve", "Zimbabwe")
	];
};

var isoCountries = {
	AF: "Afghanistan",
	AX: "Aland Islands",
	AL: "Albania",
	DZ: "Algeria",
	AS: "American Samoa",
	AD: "Andorra",
	AO: "Angola",
	AI: "Anguilla",
	AQ: "Antarctica",
	AG: "Antigua And Barbuda",
	AR: "Argentina",
	AM: "Armenia",
	AW: "Aruba",
	AU: "Australia",
	AT: "Austria",
	AZ: "Azerbaijan",
	BS: "Bahamas",
	BH: "Bahrain",
	BD: "Bangladesh",
	BB: "Barbados",
	BY: "Belarus",
	BE: "Belgium",
	BZ: "Belize",
	BJ: "Benin",
	BM: "Bermuda",
	BT: "Bhutan",
	BO: "Bolivia",
	BA: "Bosnia And Herzegovina",
	BW: "Botswana",
	BV: "Bouvet Island",
	BR: "Brazil",
	IO: "British Indian Ocean Territory",
	BN: "Brunei Darussalam",
	BG: "Bulgaria",
	BF: "Burkina Faso",
	BI: "Burundi",
	KH: "Cambodia",
	CM: "Cameroon",
	CA: "Canada",
	CV: "Cape Verde",
	KY: "Cayman Islands",
	CF: "Central African Republic",
	TD: "Chad",
	CL: "Chile",
	CN: "China",
	CX: "Christmas Island",
	CC: "Cocos (Keeling) Islands",
	CO: "Colombia",
	KM: "Comoros",
	CG: "Congo",
	CD: "Congo, Democratic Republic",
	CK: "Cook Islands",
	CR: "Costa Rica",
	CI: "Cote D'Ivoire",
	HR: "Croatia",
	CU: "Cuba",
	CY: "Cyprus",
	CZ: "Czech Republic",
	DK: "Denmark",
	DJ: "Djibouti",
	DM: "Dominica",
	DO: "Dominican Republic",
	EC: "Ecuador",
	EG: "Egypt",
	SV: "El Salvador",
	GQ: "Equatorial Guinea",
	ER: "Eritrea",
	EE: "Estonia",
	ET: "Ethiopia",
	FK: "Falkland Islands (Malvinas)",
	FO: "Faroe Islands",
	FJ: "Fiji",
	FI: "Finland",
	FR: "France",
	GF: "French Guiana",
	PF: "French Polynesia",
	TF: "French Southern Territories",
	GA: "Gabon",
	GM: "Gambia",
	GE: "Georgia",
	DE: "Germany",
	GH: "Ghana",
	GI: "Gibraltar",
	GR: "Greece",
	GL: "Greenland",
	GD: "Grenada",
	GP: "Guadeloupe",
	GU: "Guam",
	GT: "Guatemala",
	GG: "Guernsey",
	GN: "Guinea",
	GW: "Guinea-Bissau",
	GY: "Guyana",
	HT: "Haiti",
	HM: "Heard Island & Mcdonald Islands",
	VA: "Holy See (Vatican City State)",
	HN: "Honduras",
	HK: "Hong Kong",
	HU: "Hungary",
	IS: "Iceland",
	IN: "India",
	ID: "Indonesia",
	IR: "Iran, Islamic Republic Of",
	IQ: "Iraq",
	IE: "Ireland",
	IM: "Isle Of Man",
	IL: "Israel",
	IT: "Italy",
	JM: "Jamaica",
	JP: "Japan",
	JE: "Jersey",
	JO: "Jordan",
	KZ: "Kazakhstan",
	KE: "Kenya",
	KI: "Kiribati",
	KR: "Korea",
	KW: "Kuwait",
	KG: "Kyrgyzstan",
	LA: "Lao People's Democratic Republic",
	LV: "Latvia",
	LB: "Lebanon",
	LS: "Lesotho",
	LR: "Liberia",
	LY: "Libyan Arab Jamahiriya",
	LI: "Liechtenstein",
	LT: "Lithuania",
	LU: "Luxembourg",
	MO: "Macao",
	MK: "Macedonia",
	MG: "Madagascar",
	MW: "Malawi",
	MY: "Malaysia",
	MV: "Maldives",
	ML: "Mali",
	MT: "Malta",
	MH: "Marshall Islands",
	MQ: "Martinique",
	MR: "Mauritania",
	MU: "Mauritius",
	YT: "Mayotte",
	MX: "Mexico",
	FM: "Micronesia, Federated States Of",
	MD: "Moldova",
	MC: "Monaco",
	MN: "Mongolia",
	ME: "Montenegro",
	MS: "Montserrat",
	MA: "Morocco",
	MZ: "Mozambique",
	MM: "Myanmar",
	NA: "Namibia",
	NR: "Nauru",
	NP: "Nepal",
	NL: "Netherlands",
	AN: "Netherlands Antilles",
	NC: "New Caledonia",
	NZ: "New Zealand",
	NI: "Nicaragua",
	NE: "Niger",
	NG: "Nigeria",
	NU: "Niue",
	NF: "Norfolk Island",
	MP: "Northern Mariana Islands",
	NO: "Norway",
	OM: "Oman",
	PK: "Pakistan",
	PW: "Palau",
	PS: "Palestinian Territory, Occupied",
	PA: "Panama",
	PG: "Papua New Guinea",
	PY: "Paraguay",
	PE: "Peru",
	PH: "Philippines",
	PN: "Pitcairn",
	PL: "Poland",
	PT: "Portugal",
	PR: "Puerto Rico",
	QA: "Qatar",
	RE: "Reunion",
	RO: "Romania",
	RU: "Russian Federation",
	RW: "Rwanda",
	BL: "Saint Barthelemy",
	SH: "Saint Helena",
	KN: "Saint Kitts And Nevis",
	LC: "Saint Lucia",
	MF: "Saint Martin",
	PM: "Saint Pierre And Miquelon",
	VC: "Saint Vincent And Grenadines",
	WS: "Samoa",
	SM: "San Marino",
	ST: "Sao Tome And Principe",
	SA: "Saudi Arabia",
	SN: "Senegal",
	RS: "Serbia",
	SC: "Seychelles",
	SL: "Sierra Leone",
	SG: "Singapore",
	SK: "Slovakia",
	SI: "Slovenia",
	SB: "Solomon Islands",
	SO: "Somalia",
	ZA: "South Africa",
	GS: "South Georgia And Sandwich Isl.",
	ES: "Spain",
	LK: "Sri Lanka",
	SD: "Sudan",
	SR: "Suriname",
	SJ: "Svalbard And Jan Mayen",
	SZ: "Swaziland",
	SE: "Sweden",
	CH: "Switzerland",
	SY: "Syrian Arab Republic",
	TW: "Taiwan",
	TJ: "Tajikistan",
	TZ: "Tanzania",
	TH: "Thailand",
	TL: "Timor-Leste",
	TG: "Togo",
	TK: "Tokelau",
	TO: "Tonga",
	TT: "Trinidad And Tobago",
	TN: "Tunisia",
	TR: "Turkey",
	TM: "Turkmenistan",
	TC: "Turks And Caicos Islands",
	TV: "Tuvalu",
	UG: "Uganda",
	UA: "Ukraine",
	AE: "United Arab Emirates",
	GB: "United Kingdom",
	US: "United States",
	UM: "United States Outlying Islands",
	UY: "Uruguay",
	UZ: "Uzbekistan",
	VU: "Vanuatu",
	VE: "Venezuela",
	VN: "Viet Nam",
	VG: "Virgin Islands, British",
	VI: "Virgin Islands, U.S.",
	WF: "Wallis And Futuna",
	EH: "Western Sahara",
	YE: "Yemen",
	ZM: "Zambia",
	ZW: "Zimbabwe"
};

const LocaleHelper = {
	getlocaleLanguageNames: () => {
		return localeLanguageNames;
	},
	getCountryName: countryCode => {
		if (isoCountries.hasOwnProperty(countryCode)) {
			return isoCountries[countryCode];
		} else {
			return countryCode;
		}
	},
	formatLocaleToJava: locale => {
		return locale.replace("-", "_");
	},
	formatLocaleIso: locale => {
		return locale.replace("_", "-");
	},
	getCountryCode: locale => {
		return locale.split(/[-_]/)[1];
	},
	getLanguageCode: locale => {
		return locale.split(/[-_]/)[0];
	},
	getNativeLanguage: locale => {
		const languages = localeLanguageNames;
		return languages[locale.replace("_", "-").toLowerCase()];
	},
	getLocaleCountryNamesForESPT: countryCode => {
		const countryNames = getCountryNames();
		return countryNames.map(x => {
			return {
				id: x.id,
				name: countryCode === "PT" ? x.portuguese : x.spanish
			};
		});
	},
	getLocalCountryNames: (countryCode, messages, ignorecurrentCountryCode = true) => {
		const countryNames = getAllCountries(messages);
		if (ignorecurrentCountryCode) return countryNames.filter(x => x.key !== countryCode);

		return countryNames;
	},
	getAllLanguageNames: () => {
		const localeList = [
			"cs-CZ",
			"da-DK",
			"de-AT",
			"de-CH",
			"de-DE",
			"en-CA",
			"en-GB",
			"en-IE",
			"en-US",
			"es-ES",
			"fi-FI",
			"fr-FR",
			"it-IT",
			"ja-JP",
			"ko-KR",
			"nl-BE",
			"nl-NL",
			"no-NO",
			"pl-PL",
			"pt-PT",
			"ro-RO",
			"ru-RU",
			"sk-SK",
			"sv-SE",
			"zh-CN",
			"hr-HR",
			"en-IN",
			"en-AU",
			"sr-RS"
		];
		return localeList.map(item => {
			return {
				id: item,
				name: localeLanguageNames[item.toLowerCase()]
			};
		});
	},
	getRegion: countryCode => {
		if (!countryCode) return "EU";

		var Eu = [
			"AT",
			"BE",
			"CH",
			"CZ",
			"DE",
			"DK",
			"ES",
			"FI",
			"FR",
			"GB",
			"HU",
			"IE",
			"IT",
			"NL",
			"NO",
			"PL",
			"PT",
			"RO",
			"RS",
			"RU",
			"SE",
			"SK",
			"HR",
			"A01"
		];

		var Na = ["CA", "US", "A03"];

		var Cn = ["CN", "A04"];

		var Ap = ["JP", "KR", "IN", "AU", "A02"];

		if (Eu.find(x => x === countryCode.toUpperCase())) return "EU";

		if (Na.find(x => x === countryCode.toUpperCase())) return "NA";

		if (Cn.find(x => x === countryCode.toUpperCase())) return "CN";

		if (Ap.find(x => x === countryCode.toUpperCase())) return "AP";

		return "EU";
	},
	formatLocaleToLangCodeFirst: locale => {
		let value = locale && locale.replace("_", "-");

		if (!value) return;
		let values = value.split("-");
		return `${values[1]}-${values[0]}`;
	},
	getOrderSource: countryCode => {
		if (!countryCode) return "A01";

		var Eu = [
			"AT",
			"BE",
			"CH",
			"CZ",
			"DE",
			"DK",
			"ES",
			"FI",
			"FR",
			"GB",
			"HU",
			"IE",
			"IT",
			"NL",
			"NO",
			"PL",
			"PT",
			"RO",
			"RS",
			"RU",
			"SE",
			"SK",
			"HR",
			"A01"
		];

		var Ru = ["RU", "A05"];
		var Na = ["CA", "US", "A03"];

		var Cn = ["CN", "A04"];

		var Ap = ["JP", "KR", "IN", "AU", "A02"];

		if (Eu.find(x => x === countryCode.toUpperCase())) return "A01";

		if (Ap.find(x => x === countryCode.toUpperCase())) return "A02";

		if (Na.find(x => x === countryCode.toUpperCase())) return "A03";

		if (Cn.find(x => x === countryCode.toUpperCase())) return "A04";

		if (Ru.find(x => x === countryCode.toUpperCase())) return "A05";

		return "A01";
	}
};

const localeLanguageNames = {
	"pt-pt": "português (Portugal)",
	"en-pt": "English (en-PT)",
	"en-ca": "English (Canada)",
	"fr-ca": "français (Canada)",
	"da-dk": "dansk (Danmark)",
	"en-au": "English (Australia)",
	"no-no": "no-NO (no-NO)",
	"fi-fi": "suomi (Suomi)",
	"sv-se": "svenska (Sverige)",
	"en-se": "English (Sweden)",
	"en-fr": "English (en-FR)",
	"fr-fr": "français (France)",
	"hr-hr": "hrvatski (Hrvatska)",
	"en-hr": "en-HR (en-HR)",
	"es-us": "español (Estados Unidos)",
	"en-us": "English (United States)",
	"en-it": "English (en-IT)",
	"it-it": "italiano (Italia)",
	"es-es": "español (España, alfabetización internacional)",
	"ca-es": "català (català)",
	"en-es": "en-ES (en-ES)",
	"en-ie": "English (Ireland)",
	"en-rs": "en-RS (en-RS)",
	"sr-rs": "sr-RS (sr-RS)",
	"zh-cn": "中文(中国)",
	"en-cn": "English (en-CN)",
	"ro-ro": "română (România)",
	"en-ro": "en-RO (en-RO)",
	"de-de": "Deutsch (Deutschland)",
	"de-ch": "Deutsch (Schweiz)",
	"en-ch": "English (Switzerland)",
	"fr-ch": "français (Suisse)",
	"it-ch": "italiano (Svizzera)",
	"de-at": "Deutsch (Österreich)",
	"en-at": "English (Austria)",
	"it-at": "it-AT (it-AT)",
	"sl-at": "sl-AT (sl-AT)",
	"de-nl": "de-NL (de-NL)",
	"en-nl": "English (Netherlands)",
	"nl-nl": "Nederlands (Nederland)",
	"en-gb": "English (United Kingdom)",
	"en-be": "English (Belgium)",
	"fr-be": "français (Belgique)",
	"nl-be": "Nederlands (België)",
	"cs-cz": "čeština (Česko)",
	"en-cz": "English",
	"hu-hu": "magyar (Magyarország)",
	"pl-pl": "polski (Polska)",
	"en-pl": "English (en-PL)",
	"ru-pl": "ru-PL (ru-PL)",
	"en-jp": "English (en-JP)",
	"ja-jp": "日本語 (日本)",
	"ru-ru": "русский (Россия)",
	"en-ru": "English (en-RU)",
	"ko-kr": "한국어(대한민국)",
	"en-kr": "English (en-KR)",
	"fi-se": "fi-SE (fi-SE)",
	"sk-sk": "slovenčina (Slovensko)",
	"en-in": "English (India)",
	"eu-es": "Basque",
	"gl-es": "Galician",
	"sl-si": "Slovenian",
	"en-si": "English (Slovenian)"
};

export default LocaleHelper;

const getAllCountries = messages => {
	let values = [];
	countryMessageLabels.forEach(x => {
		values.push({
			key: x.replace("SG_LBL_Country_", ""),
			value: messages[x] || x.replace("SG_LBL_Country_", "")
		});
	});

	return values;
};

// const getCounryName = (countryCode, value) => {
//   if (countryCode === "ES") return value.spanish;
//   if (countryCode === "PT") return value.portuguese;
//   if (countryCode === "SI") return value.slovenian;

//   return value.english;
// };

const countryMessageLabels = [
	"SG_LBL_Country_AF",
	"SG_LBL_Country_AD",
	"SG_LBL_Country_AE",
	"SG_LBL_Country_AG",
	"SG_LBL_Country_AI",
	"SG_LBL_Country_AL",
	"SG_LBL_Country_AM",
	"SG_LBL_Country_AO",
	"SG_LBL_Country_AQ",
	"SG_LBL_Country_AR",
	"SG_LBL_Country_AS",
	"SG_LBL_Country_AT",
	"SG_LBL_Country_AU",
	"SG_LBL_Country_AW",
	"SG_LBL_Country_AX",
	"SG_LBL_Country_AZ",
	"SG_LBL_Country_BA",
	"SG_LBL_Country_BB",
	"SG_LBL_Country_BD",
	"SG_LBL_Country_BE",
	"SG_LBL_Country_BF",
	"SG_LBL_Country_BG",
	"SG_LBL_Country_BH",
	"SG_LBL_Country_BI",
	"SG_LBL_Country_BJ",
	"SG_LBL_Country_BL",
	"SG_LBL_Country_BM",
	"SG_LBL_Country_BN",
	"SG_LBL_Country_BO",
	"SG_LBL_Country_BQ",
	"SG_LBL_Country_BR",
	"SG_LBL_Country_BS",
	"SG_LBL_Country_BT",
	"SG_LBL_Country_BV",
	"SG_LBL_Country_BW",
	"SG_LBL_Country_BY",
	"SG_LBL_Country_BZ",
	"SG_LBL_Country_CA",
	"SG_LBL_Country_CC",
	"SG_LBL_Country_CD",
	"SG_LBL_Country_CF",
	"SG_LBL_Country_CG",
	"SG_LBL_Country_CH",
	"SG_LBL_Country_CI",
	"SG_LBL_Country_CK",
	"SG_LBL_Country_CL",
	"SG_LBL_Country_CM",
	"SG_LBL_Country_CN",
	"SG_LBL_Country_CO",
	"SG_LBL_Country_CR",
	"SG_LBL_Country_CU",
	"SG_LBL_Country_CV",
	"SG_LBL_Country_CW",
	"SG_LBL_Country_CX",
	"SG_LBL_Country_CY",
	"SG_LBL_Country_CZ",
	"SG_LBL_Country_DE",
	"SG_LBL_Country_DJ",
	"SG_LBL_Country_DK",
	"SG_LBL_Country_DM",
	"SG_LBL_Country_DO",
	"SG_LBL_Country_DZ",
	"SG_LBL_Country_EC",
	"SG_LBL_Country_EE",
	"SG_LBL_Country_EG",
	"SG_LBL_Country_EH",
	"SG_LBL_Country_ER",
	"SG_LBL_Country_ES",
	"SG_LBL_Country_ET",
	"SG_LBL_Country_FI",
	"SG_LBL_Country_FJ",
	"SG_LBL_Country_FK",
	"SG_LBL_Country_FM",
	"SG_LBL_Country_FO",
	"SG_LBL_Country_FR",
	"SG_LBL_Country_GA",
	"SG_LBL_Country_GB",
	"SG_LBL_Country_GD",
	"SG_LBL_Country_GE",
	"SG_LBL_Country_GF",
	"SG_LBL_Country_GG",
	"SG_LBL_Country_GH",
	"SG_LBL_Country_GI",
	"SG_LBL_Country_GL",
	"SG_LBL_Country_GM",
	"SG_LBL_Country_GN",
	"SG_LBL_Country_GP",
	"SG_LBL_Country_GQ",
	"SG_LBL_Country_GR",
	"SG_LBL_Country_GS",
	"SG_LBL_Country_GT",
	"SG_LBL_Country_GU",
	"SG_LBL_Country_GW",
	"SG_LBL_Country_GY",
	"SG_LBL_Country_HK",
	"SG_LBL_Country_HM",
	"SG_LBL_Country_HN",
	"SG_LBL_Country_HR",
	"SG_LBL_Country_HT",
	"SG_LBL_Country_HU",
	"SG_LBL_Country_ID",
	"SG_LBL_Country_IE",
	"SG_LBL_Country_IL",
	"SG_LBL_Country_IM",
	"SG_LBL_Country_IN",
	"SG_LBL_Country_IO",
	"SG_LBL_Country_IQ",
	"SG_LBL_Country_IR",
	"SG_LBL_Country_IS",
	"SG_LBL_Country_IT",
	"SG_LBL_Country_JE",
	"SG_LBL_Country_JM",
	"SG_LBL_Country_JO",
	"SG_LBL_Country_JP",
	"SG_LBL_Country_KE",
	"SG_LBL_Country_KG",
	"SG_LBL_Country_KH",
	"SG_LBL_Country_KI",
	"SG_LBL_Country_KM",
	"SG_LBL_Country_KN",
	"SG_LBL_Country_KP",
	"SG_LBL_Country_KR",
	"SG_LBL_Country_KW",
	"SG_LBL_Country_KY",
	"SG_LBL_Country_KZ",
	"SG_LBL_Country_LA",
	"SG_LBL_Country_LB",
	"SG_LBL_Country_LC",
	"SG_LBL_Country_LI",
	"SG_LBL_Country_LK",
	"SG_LBL_Country_LR",
	"SG_LBL_Country_LS",
	"SG_LBL_Country_LT",
	"SG_LBL_Country_LU",
	"SG_LBL_Country_LV",
	"SG_LBL_Country_LY",
	"SG_LBL_Country_MA",
	"SG_LBL_Country_MC",
	"SG_LBL_Country_MD",
	"SG_LBL_Country_ME",
	"SG_LBL_Country_MF",
	"SG_LBL_Country_MG",
	"SG_LBL_Country_MH",
	"SG_LBL_Country_MK",
	"SG_LBL_Country_ML",
	"SG_LBL_Country_MM",
	"SG_LBL_Country_MN",
	"SG_LBL_Country_MO",
	"SG_LBL_Country_MP",
	"SG_LBL_Country_MQ",
	"SG_LBL_Country_MR",
	"SG_LBL_Country_MS",
	"SG_LBL_Country_MT",
	"SG_LBL_Country_MU",
	"SG_LBL_Country_MV",
	"SG_LBL_Country_MW",
	"SG_LBL_Country_MX",
	"SG_LBL_Country_MY",
	"SG_LBL_Country_MZ",
	"SG_LBL_Country_NA",
	"SG_LBL_Country_NC",
	"SG_LBL_Country_NE",
	"SG_LBL_Country_NF",
	"SG_LBL_Country_NG",
	"SG_LBL_Country_NI",
	"SG_LBL_Country_NL",
	"SG_LBL_Country_NO",
	"SG_LBL_Country_NP",
	"SG_LBL_Country_NR",
	"SG_LBL_Country_NU",
	"SG_LBL_Country_NZ",
	"SG_LBL_Country_OM",
	"SG_LBL_Country_PA",
	"SG_LBL_Country_PE",
	"SG_LBL_Country_PF",
	"SG_LBL_Country_PG",
	"SG_LBL_Country_PH",
	"SG_LBL_Country_PK",
	"SG_LBL_Country_PL",
	"SG_LBL_Country_PM",
	"SG_LBL_Country_PN",
	"SG_LBL_Country_PR",
	"SG_LBL_Country_PS",
	"SG_LBL_Country_PT",
	"SG_LBL_Country_PW",
	"SG_LBL_Country_PY",
	"SG_LBL_Country_QA",
	"SG_LBL_Country_RE",
	"SG_LBL_Country_RO",
	"SG_LBL_Country_RS",
	"SG_LBL_Country_RU",
	"SG_LBL_Country_RW",
	"SG_LBL_Country_SA",
	"SG_LBL_Country_SB",
	"SG_LBL_Country_SC",
	"SG_LBL_Country_SD",
	"SG_LBL_Country_SE",
	"SG_LBL_Country_SG",
	"SG_LBL_Country_SH",
	"SG_LBL_Country_SI",
	"SG_LBL_Country_SJ",
	"SG_LBL_Country_SK",
	"SG_LBL_Country_SL",
	"SG_LBL_Country_SM",
	"SG_LBL_Country_SN",
	"SG_LBL_Country_SO",
	"SG_LBL_Country_SR",
	"SG_LBL_Country_SS",
	"SG_LBL_Country_ST",
	"SG_LBL_Country_SV",
	"SG_LBL_Country_SX",
	"SG_LBL_Country_SY",
	"SG_LBL_Country_SZ",
	"SG_LBL_Country_TC",
	"SG_LBL_Country_TD",
	"SG_LBL_Country_TF",
	"SG_LBL_Country_TG",
	"SG_LBL_Country_TH",
	"SG_LBL_Country_TJ",
	"SG_LBL_Country_TK",
	"SG_LBL_Country_TL",
	"SG_LBL_Country_TM",
	"SG_LBL_Country_TN",
	"SG_LBL_Country_TO",
	"SG_LBL_Country_TR",
	"SG_LBL_Country_TT",
	"SG_LBL_Country_TV",
	"SG_LBL_Country_TW",
	"SG_LBL_Country_TZ",
	"SG_LBL_Country_UA",
	"SG_LBL_Country_UG",
	"SG_LBL_Country_UM",
	"SG_LBL_Country_US",
	"SG_LBL_Country_UY",
	"SG_LBL_Country_UZ",
	"SG_LBL_Country_VA",
	"SG_LBL_Country_VC",
	"SG_LBL_Country_VE",
	"SG_LBL_Country_WF",
	"SG_LBL_Country_VG",
	"SG_LBL_Country_VI",
	"SG_LBL_Country_VN",
	"SG_LBL_Country_WS",
	"SG_LBL_Country_VU",
	"SG_LBL_Country_XK",
	"SG_LBL_Country_YE",
	"SG_LBL_Country_YT",
	"SG_LBL_Country_ZA",
	"SG_LBL_Country_ZM",
	"SG_LBL_Country_ZW"
];
