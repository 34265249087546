import jwt_decode from "jwt-decode";
import moment from "moment";
import { GUID, RequestHelper, APIEndpoints, httpService } from "@coworker/sharedlibrary";
import { isEdge, isIE } from "react-device-detect";
import { isExternalClientUrl } from "../helpers/externalUrlHelper";
import SalesCoworkerConfiguration from "../store/salesCoworkerConfiguration";
export default class AzureAuthService {
	constructor(authConfig) {
		this.applicationConfig = authConfig;
	}
	logOut = () => {
		const logoutUrl = `${this.applicationConfig.authority}/oauth2/v2.0/logout?post_logout_redirect_uri=${window.location.origin}`;
		window.location = logoutUrl;
	};
	login = () => {
		let devredirectUri = "http://localhost:3000";
		let redirectUri = devredirectUri;

		var stateData = {
			state: window.location.href,
			verifier: "azure"
		};
		var stateurl = btoa(JSON.stringify(stateData));
		redirectUri = window.location.origin;

		var graphScope = this.applicationConfig.graphScopes[0];
		var scope = `offline_access+${graphScope}+openid+profile`;
		var tenant = graphScope.includes("ppe") ? "iweofpp.onmicrosoft.com" : "iweof.onmicrosoft.com";
		var domainHint = graphScope.includes("ppe") ? "ikeadt.com" : "ikea.com";
		const url = `${this.applicationConfig.authority}/oauth2/v2.0/authorize?response_type=code&client_id=${
			this.applicationConfig.client_id
		}&redirect_uri=${redirectUri}&scope=${scope}&state=${stateurl}&nonce=${GUID.Guid()}&tenant=${tenant}&domain_hint=${domainHint}`;
		if (isEdge || isIE) {
			window.location.href = url;
		} else {
			window.location = url;
		}
	};

	async getAccessToken(code, referer) {
		var envurl = APIEndpoints.accessToken;
		var data = await httpService.post(envurl, {
			body: {
				code: code,
				refresh: false,
				referer: referer,
				provider: "azure"
			}
		});
		if (data.access_token) {
			RequestHelper.setToken(data.access_token);
			RequestHelper.setTokenResponse(data);
			return data;
		}
	}

	async refreshAccessToken() {
		var envurl = APIEndpoints.accessToken;
		var tokenReponse = RequestHelper.getTokenResponse();
		var response = await httpService.post(envurl, {
			body: {
				code: tokenReponse.refresh_token,
				refresh: true,
				referer: window.location.origin,
				provider: "azure"
			}
		});
		if (response.access_token) {
			var tokenData = response;
			RequestHelper.setTokenResponse(response);
			RequestHelper.setToken(response.access_token);
			SalesCoworkerConfiguration.setUserToken(response.access_token);
			return tokenData;
		}
	}

	async checkTokenValid() {
		if (isExternalClientUrl()) {
			return true;
		}
		var token = RequestHelper.getToken();

		if (this.checkIsExpired(token)) {
			try {
				var response = await this.refreshAccessToken();
				if (response.access_token) {
					RequestHelper.setTokenResponse(response);
					RequestHelper.setToken(response.access_token);
					SalesCoworkerConfiguration.setUserToken(response.access_token);
					return true;
				}
			} catch (error) {
				return false;
			}
		}
		return true;
	}

	checkIsExpired(token) {
		var tokenDecode = jwt_decode(token);
		var todate = moment();
		var exp = moment.unix(tokenDecode.exp);

		return todate.isAfter(exp);
	}
}
