import { getMessage } from "@coworker/sharedlibrary";

const icmCustomerIdentifierCustomerIdType = [
	"EMAIL_LOGINID",
	"FACEBOOK_LOGINID",
	"CARD_LOGINID",
	"PHONE_LOGINID",
	"TWITTER_LOGINID",
	"BUSINESSPAN",
	"NIF",
	"PASSPORT",
	"FOREIGN_VAT",
	"NIE",
	"DNI",
	"TAXNO_SHIPPING_GRP1",
	"TAXNO_SHIPPING_GRP2",
	"TAXNO_SHIPPING_GRP3",
	"TAXNO_SHIPPING_GRP4",
	"TAXNO_SHIPPING_GRP5",
	"TAXNO_SHIPPING_GRP6",
	"TAXNO_SHIPPING_GRP7",
	"TAXNO_SHIPPING_GRP8",
	"TAXNO_SHIPPING_GRP9",
	"TAXNO_SHIPPING_GRP10",
	"GovEconomicZoneCode",
	"CompanySizeEmployeeCode",
	"DIPLOMAT",
	"REVERSECHARGE",
	"PEC",
	"SDI",
	"NCI",
	"EMPTY_INVOICE"
];

const salesOrderTaxCodeTypes = ["CIF", "FOREIGN_VAT", "DNI_NIF", "NIE", "PASSPORT_ID_CARD", "NIF", "CODE", "EMAIL", "NONE"];

const taxCodeDfpTypesPrivate = ["NIF", "DNI", "NIE", "PASSPORT"];
const taxCodeDfptypesbusiness = ["NIF", "CIF", "FOREIGN_VAT", "NORMAL", "DIPLOMAT_NATO", "REVERSE_CHARGE", "TIN", "PIBK", "JMBG"];

const getOrderTaxCodeType = icmtaxtypeId => {
	if (icmtaxtypeId === "CIF") return "CIF";

	if (icmtaxtypeId === "PASSPORT") return "PASSPORT_ID_CARD";

	if (icmtaxtypeId === "FOREIGN_VAT") return "FOREIGN_VAT";

	if (icmtaxtypeId === "NIE") return "NIE";

	if (icmtaxtypeId === "DNI" || icmtaxtypeId === "DNI_NIF") return "DNI_NIF";

	if (icmtaxtypeId === "NIF") return "NIF";

	if (icmtaxtypeId === "DIPLOMAT_NATO") return "DIPLOMAT_NATO";

	if (icmtaxtypeId === "REVERSE_CHARGE") return "REVERSE_CHARGE";

	if (icmtaxtypeId === "NA" || icmtaxtypeId === "NORMAL") return "NA-taxIdType";

	if (icmtaxtypeId === "PEC") return "EMAIL";

	if (icmtaxtypeId === "SDI") return "CODE";

	if (icmtaxtypeId === "NCI") return "NONE";

	if (icmtaxtypeId === "NO_INVOICE") return "NO_INVOICE";

	if (icmtaxtypeId === "REVERSE_CHARGE") return "REVERSE_CHARGE";

	if (icmtaxtypeId === "TIN") return "TIN";

	if (icmtaxtypeId === "PIBK") return "PIBK";

	if (icmtaxtypeId === "JMBG") return "JMBG";

	if (icmtaxtypeId === "NA") return "NA-taxIdType";
};

const getICMCustomerTaxIdType = orderTaxCodeType => {
	if (orderTaxCodeType === "CIF") return "CIF";

	if (orderTaxCodeType === "PASSPORT_ID_CARD") return "PASSPORT";

	if (orderTaxCodeType === "FOREIGN_VAT") return "FOREIGN_VAT";

	if (orderTaxCodeType === "NIE") return "NIE";

	if (orderTaxCodeType === "DNI_NIF") return "DNI";

	if (orderTaxCodeType === "DNI_NIF") return "DNI_NIF";

	if (orderTaxCodeType === "NIF") return "NIF";

	if (orderTaxCodeType === "DIPLOMAT_NATO") return "DIPLOMAT_NATO";

	if (orderTaxCodeType === "REVERSE_CHARGE") return "REVERSE_CHARGE";

	if (orderTaxCodeType === "NA-taxIdType" || orderTaxCodeType === "NA") return "NA";

	if (orderTaxCodeType === "EMAIL") return "PEC";

	if (orderTaxCodeType === "CODE") return "SDI";

	if (orderTaxCodeType === "NO_INVOICE") return "NO_INVOICE";

	if (orderTaxCodeType === "NONE" || orderTaxCodeType === "EMPTY_INVOICE" || !orderTaxCodeType) {
		return "NCI";
	}

	if (orderTaxCodeType === "TIN") return "TIN";

	if (orderTaxCodeType === "PIBK") return "PIBK";

	if (orderTaxCodeType === "JMBG") return "JMBG";
};

const getCustomerIdentifierIdType = addressContext => {
	if (addressContext === "SHIPPING_GRP1") return "TAXNO_SHIPPING_GRP1";

	if (addressContext === "SHIPPING_GRP2") return "TAXNO_SHIPPING_GRP2";

	if (addressContext === "SHIPPING_GRP3") return "TAXNO_SHIPPING_GRP3";

	if (addressContext === "SHIPPING_GRP4") return "TAXNO_SHIPPING_GRP4";

	if (addressContext === "SHIPPING_GRP5") return "TAXNO_SHIPPING_GRP5";

	if (addressContext === "SHIPPING_GRP6") return "TAXNO_SHIPPING_GRP6";

	if (addressContext === "SHIPPING_GRP7") return "TAXNO_SHIPPING_GRP7";

	if (addressContext === "SHIPPING_GRP8") return "TAXNO_SHIPPING_GRP8";

	if (addressContext === "SHIPPING_GRP9") return "TAXNO_SHIPPING_GRP9";

	if (addressContext === "SHIPPING_GRP10") return "TAXNO_SHIPPING_GRP10";
};

const getTaxIdTypes = (countryCode, messages, isBusinessCustomer, dfpvalues) => {
	if (countryCode === "SI") {
		let taxids = [];
		taxids.push({
			key: "NA-taxIdType",
			value: getMessage("SG_LBL_NA", messages, "NA")
		});
		if (dfpvalues && dfpvalues.length > 0) {
			dfpvalues.forEach(tx => {
				switch (tx) {
					case "DIPLOMAT_NATO":
						taxids.push({
							key: "DIPLOMAT_NATO",
							value: getMessage("SG_LBL_Diplomat_NATO", messages, "Diplomat/NATO")
						});
						break;

					case "REVERSE_CHARGE":
						taxids.push({
							key: "REVERSE_CHARGE",
							value: getMessage("SG_LBL_ReverseCharge", messages, "Reverse Charge")
						});
						break;
					case "NIF":
						taxids.push({
							key: "NIF",
							value: getMessage("SG_LBL_NIF", messages, "NIF")
						});
						break;

					case "NIE":
						taxids.push({
							key: "NIE",
							value: getMessage("SG_LBL_NIE", messages, "NIE")
						});
						break;

					case "DNI_NIF":
					case "DNI":
						taxids.push({
							key: "DNI_NIF",
							value: getMessage("SG_LBL_DNI_NIF", messages, "DNI/NIF")
						});
						break;

					case "PASSPORT":
						taxids.push({
							key: "PASSPORT_ID_CARD",
							value: getMessage("SG_LBL_PassportIdCard", messages, "Passport/Id Card")
						});
						break;

					case "CIF":
						taxids.push({
							key: "CIF",
							value: getMessage("SG_LBL_CIF", messages, "CIF")
						});
						break;

					case "FOREIGN_VAT":
						taxids.push({
							key: "FOREIGN_VAT",
							value: getMessage("SG_LBL_ForeignVat", messages, "Foreign VAT")
						});
						break;

					case "TIN":
						taxids.push({
							key: "TIN",
							value: getMessage("SG_LBL_TIN", messages, "TIN")
						});
						break;

					// case "NORMAL":
					//   taxids.push({
					//     key: "NA-taxIdType",
					//     value: getMessage("SG_LBL_NA", messages, "NA")
					//   });
					//   break;
				}
			});
			taxids.sort(a => {
				if (a.key === "NA-taxIdType") return -1;
				if (a.key === "PASSPORT_ID_CARD" || a.key === "REVERSE_CHARGE") return 1;

				return 1;
			});
			return taxids;
		} else {
			return [
				{
					key: "NA-taxIdType",
					value: getMessage("SG_LBL_NA", messages, "NA")
				}
			];
		}
	}

	if (countryCode === "IT") {
		return [
			{
				key: "NO_INVOICE",
				value: getMessage("SG_LBL_RecipientType_NoInvoice", messages, "No invoice")
			},
			{
				key: "CODE",
				value: getMessage("SG_LBL_RecipientType_Code", messages, "Code")
			},
			{
				key: "EMAIL",
				value: getMessage("SG_LBL_RecipientType_Email", messages, "Email")
			},
			{
				key: "NONE",
				value: getMessage("SG_LBL_RecipientType_EmptyInvoice", messages, "Empty invoice")
			}
		];
	}

	let selectList = [];

	if (countryCode === "ES" && !isBusinessCustomer) {
		selectList.push({
			key: "NA-taxIdType",
			value: getMessage("SG_LBL_NA", messages, "NA")
		});
	}

	if (countryCode === "ES" || countryCode === "PT") {
		selectList.push({
			key: "NIF",
			value: getMessage("SG_LBL_NIF", messages, "NIF")
		});
	}

	if (countryCode === "ES" && !isBusinessCustomer) {
		selectList.push({
			key: "DNI_NIF",
			value: getMessage("SG_LBL_DNI_NIF", messages, "DNI/NIF")
		});
		selectList.push({
			key: "NIE",
			value: getMessage("SG_LBL_NIE", messages, "NIE")
		});
	}

	if (countryCode === "ES" || countryCode === "PT") {
		if (isBusinessCustomer) {
			selectList.push({
				key: "FOREIGN_VAT",
				value: getMessage("SG_LBL_ForeignVat", messages, "Foreign VAT")
			});
		} else {
			selectList.push({
				key: "PASSPORT_ID_CARD",
				value: getMessage("SG_LBL_PassportIdCard", messages, "Passport/Id Card")
			});
		}
	}

	if (countryCode === "RS" && isBusinessCustomer) {
		selectList.push({
			key: "PIBK",
			value: getMessage("SG_LBL_PIBK", messages, "PIB kupca")
		});
		selectList.push({
			key: "JMBG",
			value: getMessage("SG_LBL_JMBG", messages, "JMBG")
		});
		selectList.push({
			key: "TIN",
			value: getMessage("SG_LBL_TIN", messages, "Poreski ID iz strane države (TIN)")
		});
	}
	if (countryCode === "RO") {
		return [
			{
				key: "CNP",
				value: getMessage("SG_LBL_CNP", messages, "CNP-Personal Numerical Code")
			}
		];
	}
	return selectList;
};

const TaxCodeHelper = {
	getCustomerIdentifierIdType,
	getICMCustomerTaxIdType,
	getOrderTaxCodeType,
	salesOrderTaxCodeTypes,
	icmCustomerIdentifierCustomerIdType,
	getTaxIdTypes,
	taxCodeDfpTypesPrivate,
	taxCodeDfptypesbusiness
};

export default TaxCodeHelper;
