class PtagCombination {
	constructor(response) {
		if (response === null) {
			return;
		}
		let group = response.retailItemGroupNameList[0];
		let ptagItems = [];
		let sections = response.retailItemGroupChildList;
		sections.forEach(pItem => {
			ptagItems.push({ ...pItem, itemQuantity: pItem.quantity });
		});
		this.ptagType = response.groupType;
		this.ptagSource = response.groupType;
		this.itemDescription = response.groupType;
		this.Name = group.groupName;
		this.ItemId = response.groupId;

		this.childItems = ptagItems;

		this.price = { Type: "NORMAL", Currency: "", Value: 0 };
		this.familyPrice = { Type: "FAMILY", Currency: "", Value: 0 };
		this.availableStockToSell = 0;
		this.isPtag = true;
	}
}
export default PtagCombination;
