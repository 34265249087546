import React, { useState } from "react";
import { useSelector } from "react-redux";
import posed from "react-pose";
import styled from "styled-components";

import { getMessage, GUID } from "@coworker/sharedlibrary";

import cross from "@ingka/ssr-icon/paths/close-small";
import check from "@ingka/ssr-icon/paths/check";
import InputField from "@ingka/input-field";
import SSRIcon from "@ingka/ssr-icon";

import { Grid } from "./../../../../components/sharedlibrary";

export const GroupNameAnim = posed.div({
	visible: {
		height: "32",
		opacity: 1,
		delay: 50,
		transition: {
			opacity: { ease: "easeOut", duration: 300 },
			default: { ease: "linear", duration: 500 }
		}
	},
	hidden: { height: "0px", opacity: 0 }
});

export const GroupNameEditSection = styled(GroupNameAnim)`
	width: 230px;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: -30px;
	padding: 0px 0px;

	input {
		width: 160px;
		font-size: 12px;
	}

	svg {
		width: 30px;
	}
`;

const EditGroupNameView = ({ animateOnload, cartItem, editGroupName, updateGroupName }) => {
	const { messages } = useSelector(state => state.intl);

	const groupTextProp = {
		defaultValue: cartItem.name,
		id: GUID.Guid(),
		maxLength: "100",
		onChange: e => {
			setName(e.target.value);
		},
		placeholder: getMessage("SG_LBL_EnterGroupName", messages, "Enter group name")
	};

	const [name, setName] = useState(cartItem.name);

	if (animateOnload) {
		return (
			<GroupNameEditSection pose={editGroupName ? "visible" : "hidden"}>
				<InputField type="text" {...groupTextProp} />
				<SSRIcon
					onClick={() => {
						updateGroupName(name);
					}}
					paths={cross}
				/>
				<SSRIcon
					onClick={() => {
						updateGroupName(name);
					}}
					paths={check}
				/>
			</GroupNameEditSection>
		);
	} else {
		return (
			<Grid>
				<InputField type="text" {...groupTextProp} />
				<SSRIcon
					onClick={() => {
						updateGroupName(name);
					}}
					paths={cross}
				/>
				<SSRIcon
					onClick={() => {
						updateGroupName(name);
					}}
					paths={check}
				/>
			</Grid>
		);
	}
};

export default EditGroupNameView;
