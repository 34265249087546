import { ObjectHelper } from "@coworker/sharedlibrary";
import { createSelector } from "reselect";
import { DfpKey } from "@coworker/sharedlibrary";
import { PriceModel } from "@coworker/sharedlibrary";

const NORMAL = "NORMAL";
const FAMILY = "FAMILY";

const preferencesSelector = state => state.appController.preferences;

export const getDefaultOrderMethodSelector = createSelector(preferencesSelector, preferences => {
	if (preferences) {
		const creationMethodPref = preferences && preferences.find(x => x && x.name === DfpKey.DEFAULT_SALES_ORDER_CREATION_METHOD);
		if (creationMethodPref && creationMethodPref !== null && creationMethodPref.value) {
			return creationMethodPref.value;
		}
	}
	return "STORE";
});

export const getOrderAmountSummarySelector = state => {
	var servicePrices = getAllServicesPrice(state);
	var totalPrice = getCartTotalPrice(state);
	var totalFamilyPrice = getCartTotalFamilyPrice(state);
	var itemTotalPrice = state.cartController.total;
	var itemTotalFamilyPrice = state.cartController.totalFamilyPrice;

	var { salesPriceData, currency } = state.cartController;
	if (salesPriceData && Object.keys(salesPriceData).length > 0) {
		return {
			currency,
			...salesPriceData
		};
	}

	return {
		currency,
		totalPrice,
		servicePrice: servicePrices,
		totalFamilyPrice,
		itemTotalPrice,
		itemTotalFamilyPrice,
		subtotalPrivatePrice: {
			product: itemTotalPrice,
			delivery: servicePrices && servicePrices.delivery,
			service: servicePrices && servicePrices.service,
			payToSpPrice: servicePrices && servicePrices.payToSpPrice
		},
		subtotalFamilyPrice: {
			product: itemTotalFamilyPrice,
			delivery: servicePrices && servicePrices.delivery,
			service: servicePrices && servicePrices.service,
			payToSpPrice: servicePrices && servicePrices.payToSpPrice
		}
	};
};

const getCartTotalPrice = state => {
	var cartController = state.cartController;
	var { currency } = cartController;
	var sericePrices = getAllServicesPrice(state);
	var itemTotal = cartController.total && !isNaN(cartController.total.Value) && cartController.total.Value !== 0 ? cartController.total.Value : 0;
	var fullPrice = {
		Value: (sericePrices ? sericePrices.totalServicePrice.Value : 0) + itemTotal,
		Currency: currency,
		Type: NORMAL
	};

	return new PriceModel(fullPrice);
};

const getCartTotalFamilyPrice = state => {
	var cartController = state.cartController;
	var { currency } = cartController;
	var sericePrices = getAllServicesPrice(state);
	var itemTotal = !ObjectHelper.isNil(cartController.totalFamilyPrice) ? cartController.totalFamilyPrice.Value : 0;
	var fullPrice = {
		Value: (!ObjectHelper.isNil(sericePrices) ? sericePrices.totalServicePrice.Value : 0) + itemTotal,
		Currency: currency,
		Type: FAMILY
	};

	return new PriceModel(fullPrice);
};

const getAllServicesPrice = state => {
	var { currency, bookedServiceProposal } = state.cartController;
	var price = 0;
	var paytoServiceProviderPrice = 0;
	var deliveryPrice = 0;
	var serviceprice = 0;

	if (bookedServiceProposal && bookedServiceProposal.bookedServices) {
		var { bookedServices } = bookedServiceProposal;
		var paytoIkeaServices = bookedServices.filter(x => x.isCentralDelivery || x.isDelivery || (x.isProvidedService && x.payToIkea && !x.isPickingService));
		var deliveryservices = bookedServices.filter(x => x.isCentralDelivery || x.isDelivery);

		var providedServices = bookedServices.filter(x => x.isProvidedService);
		deliveryservices.forEach(ser => {
			if (ser.price && ser.price.priceInclTax) {
				deliveryPrice += ser.price.priceInclTax;
			} else if (ser.price && ser.price.priceExclTax) {
				deliveryPrice += ser.price.priceExclTax;
			}
		});
		providedServices.forEach(ser => {
			if (ser.price && ser.price.priceInclTax) {
				serviceprice += ser.price.priceInclTax;
			} else if (ser.price && ser.price.priceExclTax) {
				serviceprice += ser.price.priceExclTax;
			}
		});

		var paytoSpervices = bookedServices.filter(x => x.isProvidedService && !x.payToIkea);
		paytoSpervices.forEach(ser => {
			if (ser.price && ser.price.priceInclTax) {
				paytoServiceProviderPrice += ser.price.priceInclTax;
			} else if (ser.price && ser.price.priceExclTax) {
				paytoServiceProviderPrice += ser.price.priceExclTax;
			}
		});
		paytoIkeaServices.forEach(ser => {
			if (ser.price && ser.price.priceInclTax) {
				price += ser.price.priceInclTax;
			} else if (ser.price && ser.price.priceExclTax) {
				price += ser.price.priceExclTax;
			}
		});
		if (!ObjectHelper.isNil(bookedServiceProposal.selectedPickservice) && bookedServiceProposal.selectedPickservice.price) {
			if (!ObjectHelper.isNil(bookedServiceProposal.selectedPickservice.price.priceInclTax)) {
				price += bookedServiceProposal.selectedPickservice.price.priceInclTax;
			} else if (!ObjectHelper.isNil(bookedServiceProposal.selectedPickservice.price.priceExclTax)) {
				price += bookedServiceProposal.selectedPickservice.price.priceExclTax;
			}
		}

		var servicePrice = {
			totalServicePrice: new PriceModel({
				Value: price + paytoServiceProviderPrice,
				Currency: currency,
				Type: NORMAL
			}),
			delivery: new PriceModel({
				Value: deliveryPrice,
				Currency: currency,
				Type: NORMAL
			}),
			service: new PriceModel({
				Value: serviceprice,
				Currency: currency,
				Type: NORMAL
			}),
			payToSpPrice: new PriceModel({
				Value: paytoServiceProviderPrice,
				Currency: currency,
				Type: NORMAL
			})
		};
		return servicePrice;
	}
	return null;
};
export const getSelectedDeliveryMethodIds = state => {
	var { cartController } = state;
	var { bookedServiceProposal } = cartController;
	var { bookedServices = [] } = bookedServiceProposal;
	var delivery = bookedServices && bookedServices.find(x => x.isCentralDelivery);
	return (delivery && delivery.subDeliveries.map(x => x.serviceItemId)) || [];
};
export const hasPaytoIkeaServiceMethods = state => {
	let { cartController } = state;
	let { bookedServiceProposal = {} } = cartController;
	let { bookedServices = [] } = bookedServiceProposal;
	let services = bookedServices && bookedServices.filter(x => x.isProvidedService && x.serviceProviderPreferredPayment === "PAY_TO_IKEA");
	return services.length > 0;
};
