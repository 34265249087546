import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setOrderModificationQueue, resetOrderModificationQueue } from "./../../store/shell/shell.thunks";

import { useModelPopup } from "./../modelPopup";

const OrderModificationPopUp = () => {
	const dispach = useDispatch();
	const { getModel } = useModelPopup();
	const { orderModificationQueue } = useSelector(state => state.appController);

	async function showOrderModificationDialog() {
		const result = await getModel("orderModificationDialog");

		if (result) {
			dispach(resetOrderModificationQueue(orderModificationQueue.retailItem, orderModificationQueue.qty));
		}

		dispach(setOrderModificationQueue());
	}

	useEffect(() => {
		if (orderModificationQueue && orderModificationQueue.retailItem) {
			showOrderModificationDialog();
		}
	}, [orderModificationQueue]);

	return null;
};

export default OrderModificationPopUp;
