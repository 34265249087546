import React from "react";
import { Container } from "./../../../../../components/sharedlibrary";
import { SummaryPriceControl } from "./../../../../../components/price/summaryPriceControl";

const OrderPriceSummaryTotalTransportLayout = ({ messages, TotalPriceStyle, servicePrice }) => {
	return (
		<Container fdCol>
			{servicePrice && servicePrice.totalServicePrice && (
				<Container aiCenter margin="10px 0px" height="60px" width="100%">
					<SummaryPriceControl
						testid="totalpricelbl"
						container={TotalPriceStyle}
						price={servicePrice.totalServicePrice}
						bold={true}
						title={messages.SG_LBL_Total || "Total:"}
					/>
				</Container>
			)}
		</Container>
	);
};

export { OrderPriceSummaryTotalTransportLayout };
