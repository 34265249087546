export const OrderMethodTransKeys = [
	"SG_LBL_SALES_ORDER_CREATION_METHOD_LETTER",
	"SG_LBL_SALES_ORDER_CREATION_METHOD_PHONE",
	"SG_LBL_SALES_ORDER_CREATION_METHOD_TELANSMACH",
	"SG_LBL_SALES_ORDER_CREATION_METHOD_STORE",
	"SG_LBL_SALES_ORDER_CREATION_METHOD_FAX",
	"SG_LBL_SALES_ORDER_CREATION_METHOD_INTERNET",
	"SG_LBL_SALES_ORDER_CREATION_METHOD_IBUY",
	"SG_LBL_SALES_ORDER_CREATION_METHOD_CUSTOMER_SUPPORT",
	"SG_LBL_SALES_ORDER_CREATION_METHOD_CUSTOMERPICKED",
	"SG_LBL_SALES_ORDER_CREATION_METHOD_CHAT",
	"SG_LBL_SALES_ORDER_CREATION_METHOD_WRITTEN",
	"SG_LBL_SALES_ORDER_CREATION_METHOD_DIGITAL_MEETING",
	"SG_LBL_SALES_ORDER_CREATION_METHOD_REMOTE_EMAIL_SALES",
	"SG_LBL_SALES_ORDER_CREATION_METHOD_REMOTE_KITCHEN",
	"SG_LBL_SALES_ORDER_CREATION_METHOD_REMOTE_BEDROOM",
	"SG_LBL_SALES_ORDER_CREATION_METHOD_REMOTE_LIVINGROOM",
	"SG_LBL_SALES_ORDER_CREATION_METHOD_REMOTE_INT_DESIGN",
	"SG_LBL_SALES_ORDER_CREATION_METHOD_REMOTE_BATHROOM",
	"SG_LBL_SALES_ORDER_CREATION_METHOD_REMOTE_OTHER_RANGE",
	"SG_LBL_SALES_ORDER_CREATION_METHOD_OUTBOUND_LEAD_SALES"
];
