import * as RetailItemHelper from "@coworker/sharedlibrary/src/helpers/retailItemInformationHelper";

import ServiceTypes from "@coworker/sharedlibrary/src/constants/serviceTypes";

const { ServiceCapacityType, ServicePriceCalculationType } = ServiceTypes;

export const fortmatZipCode = (zipcode, countryCode) => {
	if (!zipcode) return;
	switch (countryCode.toUpperCase()) {
		case "PT":
			if (zipcode.length === 7 && !zipcode.includes("-")) {
				const code = zipcode;
				return [code.slice(0, 4), "-", code.slice(4)].join("").toUpperCase();
			}
			break;
		case "SE":
		case "JP":
		case "NL":
		case "CA":
		case "CZ":
		case "SK":
		case "HU":
			return removeSpecialCharacters(zipcode, false).toUpperCase();
		default:
			return zipcode.toUpperCase();
	}
	return zipcode.toUpperCase();
};

export const removeSpecialCharacters = (value, keepSpaceChar = false) => {
	if (!value) return;

	if (keepSpaceChar) {
		return value.replace(/[^0-9a-zA-Z ]/, "");
	}

	return value.replace(/[^0-9a-zA-Z]/, "");
};
/**
 * return latest timewindows
 *
 * @param {object} [ selectedSolution ]
 * @param {Object[]} selectedSolution.subDeliveries - list of sub deliveries
 * @return {string} Returns transportTimeWindowId
 */
const getLatestIsomDeliveryTimeWindowHelper = selectedSolution => {
	if (!selectedSolution) {
		return undefined;
	}
	let sortedTimes = selectedSolution.subDeliveries
		.map(function (p) {
			return p.timeWindows;
		})
		.reduce(function (a, b) {
			return a.concat(b);
		}, [])
		.sort(function (a, b) {
			let dateA = new Date(a.fromDateTime),
				dateB = new Date(b.fromDateTime);
			return dateA - dateB;
		});

	let transportTimeWindowId = sortedTimes && sortedTimes[0].id;

	return transportTimeWindowId;
};
export { getLatestIsomDeliveryTimeWindowHelper };

export const getNumberOfCapacityUnits = (items, selectedService) => {
	let pCalcType = selectedService.capacityUnit;
	let service = { ...selectedService };
	if (!service.itemRequiredForService) {
		return service.capacityNeeded;
	}

	switch (pCalcType) {
		case ServiceCapacityType.KITCHENUNITS:
		case ServiceCapacityType.PIECES:
			return getNumberOfPieces(items);

		case ServiceCapacityType.GODDS_VALUE:
			return getItemsValue(items);
		case ServiceCapacityType.BOOKINGS:
			return "1";
		case ServiceCapacityType.PATVALUE: {
			if (items.length === 0) {
				return "0";
			}
			let patValue = getNumberOfMinutes(items);
			return patValue !== 0 ? patValue : service.capacityNeeded;
		}
		case ServiceCapacityType.ASSEMBLY_LIST_PRICE: {
			if (items.length === 0) {
				return "0";
			}
			let ppatValue = getNumberOfPpatMinutes(items);
			return ppatValue !== 0 ? ppatValue : service.capacityNeeded;
		}

		default:
			break;
	}
	return service.capacityNeeded;
};

export const getNumberOfServicePriceUnits = (items, selectedService) => {
	let pCalcType = selectedService.priceCalculationType;
	let service = { ...selectedService };
	switch (pCalcType) {
		case ServicePriceCalculationType.PerPiece:
		case ServicePriceCalculationType.PiecesInterval:
			return getItemsCount(items);

		case ServicePriceCalculationType.ServiceGoodsValue:
			return getItemsValue(items);

		case ServicePriceCalculationType.PerService:
		case ServicePriceCalculationType.ServicesInterval:
			return "1.0";
		case ServicePriceCalculationType.Weight:
			return getItemsWeight(items);

		case ServicePriceCalculationType.AssemblyTimeInterval:
			return getNumberOfMinutes(items);

		case ServicePriceCalculationType.AssemblyTimeCategoryInterval:
			return getNumberOfPieces(items);
		case ServicePriceCalculationType.KitchenUnitsInterval:
			return getNumberOfPieces(items);

		case ServicePriceCalculationType.AssemblyListPrice: {
			if (items.length === 0) {
				return "0";
			}
			let listPrices =
				items.map(x => {
					let retailItem = x.retailItem;
					let servicePrice =
						retailItem && retailItem.ServicePrices && retailItem.ServicePrices.find(x => x.serviceProductId === selectedService.serviceProductId);
					return (servicePrice && servicePrice.priceInclTax * x.quantity) || 0;
				}) || [];
			let sum = listPrices.reduce(function (a, b) {
				return a + b;
			}, 0);
			if (service.assemblyMinimumPrice > 0) {
				return Math.max(sum, service.assemblyMinimumPrice || 0);
			}
			return Math.max(sum + service.basePrice || 0);
		}

		default:
			break;
	}
	return service.priceCalculationValue;
};

export const getItemsCount = items => {
	let count = 0;

	items.forEach(x => {
		count += x.quantity;
	});

	return count;
};
export const getItemsValue = items => {
	let price = 0;
	items.forEach(x => {
		price += x.quantity * x.price.Value;
	});

	return price;
};

export const getItemsWeight = items => {
	let weight = 0;

	items.forEach(x => {
		weight += x.quantity * RetailItemHelper.getPackageWeight(x.retailItem);
	});

	return Math.round(weight);
};

export const getItemsPackages = items => {
	let packages = 0;
	items.forEach(x => {
		packages += x.quantity * RetailItemHelper.getPackages(x.retailItem);
	});

	return packages;
};

export const getNumberOfMinutes = items => {
	let value = 0;
	items.forEach(x => {
		value += x.quantity * x.retailItem.PredictedAssemblyTime || 0;
	});
	return value;
};

export const getNumberOfPpatMinutes = items => {
	let value = 0;
	items.forEach(x => {
		value += x.quantity * x.retailItem.ProfessionalAssemblyTime || 0;
	});
	return value;
};

export const getNumberOfPieces = items => {
	let value = 0;
	items.forEach(x => {
		value += x.quantity;
	});

	return value;
};

export const getAssemblyListPrice = (service, items) => {
	let listPrices =
		items.map(x => {
			let retailItem = x.retailItem;
			let servicePrice = retailItem && retailItem.ServicePrices && retailItem.ServicePrices.find(x => x.serviceProductId === service.serviceProductId);
			return (servicePrice && servicePrice.priceInclTax * x.quantity) || 0;
		}) || [];
	let sum = listPrices.reduce(function (a, b) {
		return a + b;
	}, 0);
	return sum.toFixed(2);
};
