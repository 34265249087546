export const clearFromStorage = name => {
	try {
		for (var key in localStorage) {
			if (key.includes(name)) {
				localStorage.removeItem(key);
			}
		}
	} catch {
		//
	}
};

export const clearAllFromStorage = () => {
	try {
		for (var key in localStorage) {
			localStorage.removeItem(key);
		}
	} catch {
		//
	}
};

export const addToStorage = (key, value) => {
	try {
		localStorage.removeItem(key);
		localStorage.setItem(key, value);
	} catch (error) {
		console.log(error);
	}
};

export const getFromStorage = key => {
	try {
		return localStorage.getItem(key);
	} catch {
		//
	}
};
