import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import moment from "moment";

import { colors, device, dispatchEvent as eventDispatcher, OrderTypes, useWindowEventListener } from "@coworker/sharedlibrary";

import { getCustomerProfileObject } from "../../utils/shellObjectService";

import { Dropdown, DropdownItem } from "@ingka/dropdown";

import AddProjectView from "./addProjectView";
import CustomerView from "../../components/microfrontends/customerView";
import { List } from "../../components/sharedlibrary";
import LoadingPlaceHolder from "./loadingPlaceHolder";
import QuoteDescriptionView from "./quoteDescriptionView";
import SaveQuoteButton from "./saveQuoteButton";
import Switch from "@ingka/switch";
import { useModelPopup } from "../../components/modelPopup";

const Container = styled.div`
	padding: 40px 80px 60px 80px;

	.quotation-title {
		color: ${colors.primaryTextColor};
		font-size: 24px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		line-height: 1;
	}

	@media ${device.small} {
		width: 100%;
		height: 100%;
		padding: 12px;
	}
`;

const DropDownSelect = styled(Dropdown)`
	width: 320px;
	margin-top: 30px;

	@media ${device.small} {
		width: 100%;
	}
`;

const CustomerInfo = styled.div`
	margin-top: 10px;
`;

const AddressSuggestionContainer = styled.div`
	width: 400px;
	position: relative;
	z-index: 1;
	margin: 40px 0px 0px 0px;

	@media ${device.small} {
		width: 100%;
	}
`;

const AddBusinesswitch = styled(Switch)`
	.toggle-container {
		margin: 40px 0px 0px 0px !important;
	}
`;

const QuotationView = ({ onSubmit, quoteDays }) => {
	const cusRef = React.useRef(null);
	const { getModel } = useModelPopup();

	const { customer } = getCustomerProfileObject();

	const { messages } = useSelector(state => state.intl);
	const [selectedDays, setSelectedDays] = useState(quoteDays ? quoteDays.day : "1");
	const [canShowCustomer, setCanShowCustomer] = useState(selectedDays !== "1");
	const [quoteName, setQuoteName] = useState(undefined);
	const [project, setProject] = useState(undefined);
	const [isCustomerLoading, setIsCustomerLoading] = useState(false);
	const { addressProvider, storeInfo } = useSelector(state => state.appController);

	const quoteDates = [
		{
			id: "1",
			value: "One day"
		},
		{
			id: "15",
			value: "15 days"
		},
		{
			id: "30",
			value: "30 days"
		},
		{
			id: "90",
			value: "3 months"
		}
	];

	const isBuisnessOption = {
		checked: customer.isBusinessCustomer,
		id: "businesscustomer",
		label: messages.SG_LBL_BusinessCustomer || "Business Customer",
		onChange: e => {
			setIsCustomerLoading(true);

			let cusObj = {};
			cusObj.isBusinessCustomer = e.target.checked;

			let cusBillObj = {};
			cusBillObj.isBusinessCustomer = e.target.checked;

			eventDispatcher("SET_CUSTOMER", cusObj);
			eventDispatcher("SET_CUSTOMER_BILLING", cusBillObj);

			setTimeout(() => {
				setIsCustomerLoading(false);
			}, 300);
		}
	};

	const onQuoteDateChange = e => {
		const item = quoteDates.find(x => x.id === e.target.value);

		if (item) {
			if (item.id !== "1") {
				setCanShowCustomer(item.id !== "1" ? true : false);
				setSelectedDays(item.id);
			} else {
				setCanShowCustomer(false);
				setSelectedDays(item.id);
			}
		}
	};

	const onSave = () => {
		const cusValid = cusRef && cusRef.current && cusRef.current.isValid();
		let title = messages.SG_LBL_Customer;
		let desc = messages.SG_LBL_CustomerDetailsMissing;

		if (selectedDays !== "1") {
			if (!cusValid) {
				getModel("serviceError", {
					alertTitle: title,
					alertDesc: desc
				});

				return;
			}

			if (!quoteName) {
				title = messages.SG_LBL_EnterQuoteName;
				desc = "";

				getModel("serviceError", {
					alertTitle: title,
					alertDesc: desc
				});

				const elmnt = document.getElementById("quoteName");
				elmnt && elmnt.scrollIntoView();

				return;
			}

			const today = moment();
			const endDay = today.add(parseInt(selectedDays), "days").format("YYYY-MM-DD");

			onSubmit &&
				onSubmit({
					type: OrderTypes.Quotation,
					quotation: {
						description: "",
						endDate: endDay,
						name: quoteName,
						project: project,
						startDate: moment().format("YYYY-MM-DD")
					}
				});
		} else {
			//call shoppinglist
			onSubmit && onSubmit({ type: OrderTypes.SHLIST });
		}
	};

	const onQuoteTextChange = (value = false) => {
		setQuoteName(value);
	};

	useWindowEventListener("GET_ADDRESS_SUGGESTION", () => {
		setIsCustomerLoading(true);

		setTimeout(() => {
			setIsCustomerLoading(false);
		}, 300);
	});

	return (
		<Container>
			<List>
				<h6 className={"quotation-title"}>{messages.SG_LBL_TimeFrame}</h6>

				<DropDownSelect defaultValue={selectedDays} id="languageBox" label={messages.SG_LBL_ExpiresIn} onChange={e => onQuoteDateChange(e)}>
					{quoteDates.map(element => (
						<DropdownItem id={element.id} key={element.id} name={element.value} value={element.id} />
					))}
				</DropDownSelect>

				{canShowCustomer && (
					<>
						<>
							<AddressSuggestionContainer>
								<CustomerView
									addressProvider={addressProvider}
									elementId="addressSuggestion"
									messages={messages}
									storeInfo={storeInfo}
									view="AddressSuggestion"
								/>
							</AddressSuggestionContainer>
							{!isCustomerLoading ? (
								<>
									<AddBusinesswitch {...isBuisnessOption} />

									<CustomerInfo>
										<CustomerView ref={cusRef} view="CustomerForm" />
									</CustomerInfo>
								</>
							) : (
								<LoadingPlaceHolder></LoadingPlaceHolder>
							)}
						</>
						<div id="quoteName">
							<QuoteDescriptionView messages={messages} onQuoteTextChange={onQuoteTextChange()}></QuoteDescriptionView>
						</div>

						<AddProjectView
							customerId={customer.id}
							messages={messages}
							onProjectChange={proj => {
								setProject(proj);
							}}
							selectedDays={selectedDays}
						></AddProjectView>
					</>
				)}
			</List>
			<SaveQuoteButton messages={messages} onSave={onSave} />
		</Container>
	);
};

export default QuotationView;
