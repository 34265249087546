import { useEffect, useState } from "react";
import styled, { keyframes, css, createGlobalStyle, ThemeProvider } from "styled-components";
import React from "react";
import ReactDOM from "react-dom";
import moment from "moment";
const useMicrofrontend = (id, host, appname) => {
	window["React"] = React;
	window["moment"] = moment;
	window["ReactDOM"] = ReactDOM;
	window["styled-components"] = styled;
	window["styled-components"].keyframes = keyframes;
	window["styled-components"].css = css;
	window["styled-components"].createGlobalStyle = createGlobalStyle;
	window["styled-components"].ThemeProvider = ThemeProvider;

	const scriptId = `${id}Bundle`;
	const [isLoaded, setLoaded] = useState(window[id]);

	const handleLoad = () => {
		setLoaded(true);
	};

	useEffect(() => {
		const existingScript = document.getElementById(scriptId);
		if (existingScript) {
			existingScript.addEventListener("load", handleLoad);

			return () => existingScript.removeEventListener("load", handleLoad);
		}
		fetch(`${host}/asset-manifest.json`)
			.then(res => res.json())
			.then(manifest => {
				if (manifest && manifest.files) {
					const linkpath = `${host}${manifest["files"][`${appname}.css`]}`;
					if (!linkpath.includes("undefined")) {
						let link = document.createElement("link");
						link.href = linkpath;
						link.type = "text/css";
						link.rel = "stylesheet";
						document.head.appendChild(link);
					}

					let existingScript = document.getElementById(scriptId);
					if (!existingScript) {
						const script = document.createElement("script");
						script.id = scriptId;
						script.src = `${host}${manifest["files"][`${appname}.js`]}`;
						//script.crossOrigin = "";
						document.body.appendChild(script);
						script.onload = handleLoad;
					}
				}
			});
	}, []);
	// eslint-disable-next-line no-undef
	return { isLoaded, [id]: global[id] };
};

export default useMicrofrontend;
