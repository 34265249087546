import React from "react";
import styled from "styled-components";
import Section from "../../components/help/section";
import { useSelector } from "react-redux";

const ContentContainer = styled.div`
	width: 100%;
	min-width: 100%;
	background-color: white;

	.btn {
		width: 100%;
		&:focus-visible {
			text-decoration: underline;
		}
	}

	.hr {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}
`;

const HelpView = props => {
	const onSectionPicked = selected => {
		props.onSelectSection(selected);
	};
	const { messages } = useSelector(state => state.intl);
	return (
		<ContentContainer>
			<Section
				data-testid="faqSection"
				title={messages.SG_LBL_HelpSectionFAQTittle || "FAQ"}
				contentTxt={messages.SG_LBL_HelpSectionFAQSubTitle || "Learn how to use Sälja GO"}
				onSelect={() => onSectionPicked("faq")}
			></Section>
			<Section
				data-testid="superuserSection"
				title={messages.SG_LBL_HelpSectionContactTitle || "Contact Super-user"}
				contentTxt={messages.SG_LBL_HelpSectionContactSubTitle || "Help with questions related to country-specifications"}
				onSelect={() => onSectionPicked("superuser")}
			></Section>
			<Section
				data-testid="knowledgebaseSection"
				title={messages.SG_LBL_HelpSectionKnowledgeBaseTitle || "Knowledge Base"}
				contentTxt={messages.SG_LBL_HelpSectionKnowledgeBaseSubTitle || "Know more about SÄLJA GO"}
				onSelect={() => onSectionPicked("knowledgebase")}
			></Section>
		</ContentContainer>
	);
};

export default HelpView;
