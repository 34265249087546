import moment from "moment";
import styled, { keyframes, css, createGlobalStyle, ThemeProvider } from "styled-components";
import React from "react";
import ReactDOM from "react-dom";
import * as Shared from "@coworker/sharedlibrary";
import SalesCoworkerConfiguration from "../store/salesCoworkerConfiguration";
import addMfeMaps from "./mfeMap";
export const getCustomerProfileObject = () => {
	const moduleName = "customerprofile";
	return (window[moduleName] && window[moduleName].getExposedObjects()) || {};
};

export const getMicroFrontendInstance = appName => {
	const instance = window[appName];
	return instance;
};
export const registerGlobals = () => {
	window["React"] = React;
	window["ReactDOM"] = ReactDOM;
	window["moment"] = moment;
	window["styled-components"] = styled;
	window["styled-components"].keyframes = keyframes;
	window["styled-components"].css = css;
	window["styled-components"].createGlobalStyle = createGlobalStyle;
	window["styled-components"].ThemeProvider = ThemeProvider;
	window["@coworker/sharedlibrary"] = Shared;
};
export const loadMicroFrontEnds = async microApps => {
	const apps = Object.entries(microApps);
	addMfeMaps(microApps);
	await AsyncForEach(apps, async ([key, value]) => {
		try {
			console.log(key, value);
			let { appId, appName, appUrl } = value;
			const overridedUrl = localStorage.getItem(`import-map-override:${key}`);
			if (overridedUrl) {
				appUrl = overridedUrl;
			} else {
				const isLocalhost = window.location.hostname === "localhost";
				if (isLocalhost && process.env.REACT_APP_API_HOST && !appUrl.includes("http")) {
					appUrl = `${process.env.REACT_APP_API_HOST}${appUrl}`;
				}
			}

			if (appId !== "atp") {
				const scriptId = `${appId}Bundle`;

				const res = await fetch(`${appUrl}/asset-manifest.json`);

				const manifest = await res.json();

				const linkpath = `${appUrl}${manifest["files"][`${appName}.css`]}`;
				if (!linkpath.includes("undefined")) {
					const link = document.createElement("link");
					link.href = linkpath;
					link.type = "text/css";
					link.rel = "stylesheet";
					document.head.appendChild(link);
				}

				const existingScript = document.getElementById(scriptId);
				if (!existingScript) {
					const script = document.createElement("script");
					const scriptUrl = `${appUrl}${manifest["files"][`${appName}.js`]}`;
					script.id = scriptId;
					script.src = scriptUrl;
					//script.crossOrigin = "";
					document.body.appendChild(script);
				}
			}
		} catch {
			//
		}
	});
};

export const updateMfeConfigs = () => {
	try {
		let store = SalesCoworkerConfiguration.getStore();
		const state = store.getState();
		const appController = state.appController;
		const { environment } = appController;
		const { microapps } = environment;
		const apps = Object.entries(microapps);
		apps.forEach(([key, value]) => {
			const { appId } = value;
			console.log(key);
			const app = window && window[appId];
			if (app) {
				let appConfig = getStoreConfigSelector();
				appConfig = { ...appConfig, token: Shared.RequestHelper.getToken() };
				app.SetSharedConfiguration && app.SetSharedConfiguration({ ...appConfig });
			}
		});
	} catch (error) {
		//
	}
};

export const getOrderServicesObject = () => {
	const instance = getMicroFrontendInstance("orderservices");
	if (instance) {
		return instance.getBookedOrderServices();
	}
	return undefined;
};

export const getOrderServiceInstaance = () => {
	return getMicroFrontendInstance("orderservices");
};

export const getRecentOrderServiceInstance = () => {
	return getMicroFrontendInstance("recentorder");
};
export const getCustomerProfileInstance = () => {
	return getMicroFrontendInstance("customerprofile");
};

export const getCustomerForExternalClient = ({ bcmData }) => {
	const moduleName = "customerprofile";
	return (window[moduleName] && window[moduleName].getCustomerForExternalClient({ bcmData: bcmData })) || undefined;
};

async function AsyncForEach(array, callback) {
	for (let index = 0; index < array.length; index++) {
		await callback(array[index], index, array);
	}
}
export const getStoreConfigSelector = () => {
	let config = SalesCoworkerConfiguration.getUserConfig();
	return config;
};
