import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import styled, { keyframes } from "styled-components";

import { colors, device, getMessage, GUID, LocationView, PagePaths, RetailItemHelper, Specifications, StockView } from "@coworker/sharedlibrary";

import { isFamilyPrice } from "./../../../../helpers/cartHelper";

import {
	convertItemToGroup,
	deleteChildItemInGroup,
	setisProductExpanded,
	updateGroupChildItemQuantity,
	updateGroupName
} from "./../../../../store/cart/cartActions";

import Accordion from "@ingka/accordion";
import Button from "@ingka/button";
import CommercialMessage from "@ingka/commercial-message";
import ProductIdentifier from "@ingka/product-identifier";
import SSRIcon from "@ingka/ssr-icon";
import trashCan from "@ingka/ssr-icon/paths/trash-can";

import { Grid } from "./../../../../components/sharedlibrary";
import ItemImageControl from "../retailItem/itemImageControl";
import PriceControl from "./../../../../components/price/priceControl";
import { useCartModelPopup } from "./../modelPopup";

import CartImageList from "./../cartImageList";

import EditGroupNameView from "./editGroupNameView";
import ScrollContainer from "./scrollableContainer";

import {
	CardContainer,
	CartAccordionItem,
	Description,
	FoodStoreInfoContainer,
	ItemDesc,
	LocationInfo,
	MiddleSection,
	Name,
	PriceContainer,
	QuantityComponent,
	QuantityPrice,
	ResponsiveWidth,
	StockSection,
	TopSection,
	WarningText
} from "./sharedStyles";

export const fadeIn = keyframes`
  from { opacity: 0; }
    to { opacity: 1; }
`;

export const Messages = styled.div`
	> * {
		margin: 0 0 5px;
	}
`;

export const WarningSection = styled.div`
	background: ${colors.red};
	width: 120px;
	height: 5px;
	margin-top: 4px;
	animation: ${fadeIn} 3s;
`;

export const ActionSection = styled.div`
	width: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
`;

export const IconButton = styled(Button)`
	&.btn {
		span {
			padding: 0 ${props => (props.small ? "10px" : "1em")};
		}

		.svg-icon {
			width: ${props => (props.small ? "20px" : "2em")};
		}
	}
`;

export const ChildItemsContainer = styled.div`
	background-color: ${colors.grey100};
`;

const CartItemCard = props => {
	const dispatch = useDispatch();

	const { getCartModel } = useCartModelPopup();

	const { isModifyOrderinProgress, canModifyOrder, cartItem, handleUpdateQuantity, handleRemove, isIsomEnabled, ischildItem = false } = props;

	const { DetailPage, PlannerPage, PtagPage } = PagePaths;
	const { isFoodItemSpec } = Specifications;

	const item = cartItem.retailItem;
	const isFoodItem = isFoodItemSpec(item.storeInfo);
	const quantity = cartItem.isGroup ? cartItem.quantity : cartItem.totalQuantity > 1 ? cartItem.totalQuantity : cartItem.quantity;

	const { messages } = useSelector(state => state.intl);

	const [editGroupName, setEditGroupName] = useState(false);
	const [showChildItems, setshowChildItems] = useState(cartItem.isExpanded || false);

	let canIncreaseQuantity = true;
	let canDecreaseQuantity = true;
	let canRemoveItem = true;
	if (cartItem.isOrderLine) {
		canIncreaseQuantity = cartItem.canIncreaseQuantity;
		canDecreaseQuantity = cartItem.canDecreaseQuantity;
		canRemoveItem = cartItem.canRemoveItem;
	}
	const inputProps = {
		canIncreaseQuantity: canIncreaseQuantity,
		canDecreaseQuantity: canDecreaseQuantity,
		canRemoveItem: canRemoveItem,
		compact: true,
		disabled: false,
		max: 300,
		min: 1,
		onChange: x => {
			if (x === 0) {
				handleRemove(cartItem.id);
			} else {
				handleUpdateQuantity(cartItem.id, x);
			}
		},
		value: quantity
	};

	const showUnGroup = async () => {
		return await getCartModel("createGroup", {
			title: getMessage("SG_LBL_WantToUngroup", messages, "Do you want to ungroup the item"),
			subTitle: getMessage("SG_LBL_CanNotUnDone", messages, "The action can't be undone"),
			positiveText: getMessage("SG_BTN_UnGroup", messages, "UnGroup"),
			negativeText: getMessage("SG_LBL_Cancel", messages, "Cancel")
		});
	};

	const onChildQuantityChange = async (childItem, quantity) => {
		if (cartItem.isGroup) {
			dispatch(updateGroupChildItemQuantity(cartItem.id, childItem.id, quantity));
		} else {
			const confirm = await showUnGroup();

			if (confirm) {
				const childItems = [];

				cartItem.childItems.forEach(itm => {
					if (itm.isProduct) {
						itm.childItems.forEach(subItm => {
							childItems.push({
								retailItem: subItm.retailItem,
								itemQuantity: itm.id === childItem.id ? quantity * subItm.quantity : itm.quantity * subItm.quantity
							});
						});
					} else {
						childItems.push({
							retailItem: itm.retailItem,
							itemQuantity: itm.id === childItem.id ? quantity : itm.quantity
						});
					}
				});

				dispatch(convertItemToGroup(cartItem.id, childItems));
			}
		}
	};

	const handleChildQntyChange = (id, quantity) => {
		const childItem = cartItem.childItems.find(x => x.id === id);

		onChildQuantityChange(childItem, quantity);
	};

	const handleRemoveChild = id => {
		const childItem = cartItem.childItems.find(x => x.id === id);

		onRemoveChildItem(childItem);
	};

	const onRemoveChildItem = async childItem => {
		let quantity = 1;

		if (cartItem.isGroup) {
			if (cartItem.childItems.length === 1) {
				handleRemove(cartItem.id);

				return;
			}

			const result = await getCartModel("removeCartItemDialog", {
				showDelete: true
			});

			if (result) {
				dispatch(deleteChildItemInGroup(cartItem.id, childItem.id));
			}
		} else {
			const confirm = await showUnGroup();

			if (confirm) {
				const newChildItems = cartItem.childItems.filter(x => x.id !== childItem.id);

				const childItems = [];

				newChildItems.forEach(itm => {
					if (itm.isProduct) {
						itm.childItems.forEach(subItm => {
							childItems.push({
								retailItem: subItm.retailItem,
								itemQuantity: itm.id === childItem.id ? quantity * subItm.quantity : itm.quantity * subItm.quantity
							});
						});
					} else {
						childItems.push({
							retailItem: itm.retailItem,
							itemQuantity: itm.id === childItem.id ? quantity : itm.quantity
						});
					}
				});

				dispatch(convertItemToGroup(cartItem.id, childItems));
			}
		}
	};

	const onItemClick = async (event, cartItem) => {
		if (cartItem.isGroup) {
			setEditGroupName(true);

			return;
		}

		event.stopPropagation();

		if (cartItem.itemType === "PLANNER") {
			dispatch(
				push(PlannerPage + cartItem.itemId, {
					query: cartItem.itemId
				})
			);
		} else if (cartItem.itemType === "PTAG") {
			dispatch(
				push(PtagPage + cartItem.itemId, {
					query: cartItem.itemId
				})
			);
		} else {
			const item = cartItem.retailItem;

			dispatch(push(DetailPage + item.ItemType + item.ItemId, item));
		}
	};

	const updateItemGroupName = groupName => {
		if (groupName) {
			dispatch(updateGroupName(cartItem.id, groupName));
		}
	};

	return (
		<div data-testid={"cart-item"}>
			<CardContainer onClick={event => onItemClick(event, cartItem)} onKeyPress={event => onItemClick(event, cartItem)}>
				{cartItem.isCombination ? (
					<CartImageList group={cartItem.retailItem} />
				) : (
					<ItemImageControl align="flex-start" breakpoint={device.large} height={"64px"} item={item} theme="itemCardTheme" width={"64px"} />
				)}
				<ResponsiveWidth width={"100%"}>
					<TopSection>
						<Description padding={"0px 0px 0px 20px"}>
							<Messages>
								{isFamilyPrice(cartItem) && (
									<CommercialMessage variant="family" message={getMessage("SG_LBL_FamilyPrice", messages, "Ikea Family Price")} />
								)}
								{!cartItem.isGroup && (
									<ProductIdentifier style={{ display: "block" }} subtle={true} value={RetailItemHelper.toItemFormaId(cartItem.itemId)} />
								)}
							</Messages>
							<Grid minheight={cartItem.isGroup ? "60px" : "0px"}>
								{editGroupName ? (
									<EditGroupNameView
										animateOnload={true}
										cartItem={cartItem}
										editGroupName={editGroupName}
										updateGroupName={name => {
											updateItemGroupName(name);
										}}
									/>
								) : (
									<Name>{cartItem.retailItem.Name}</Name>
								)}
							</Grid>
							<ItemDesc>{cartItem.itemDescription}</ItemDesc>
							{!isFoodItem && !cartItem.canCreateOrder && (
								<>
									{isIsomEnabled ? (
										<WarningText>{getMessage("SG_LBL_DistributionOnly", messages, "Distribution only")}</WarningText>
									) : (
										<WarningSection isIsomEnabled />
									)}
								</>
							)}
						</Description>
						<QuantityPrice className="wide">
							<PriceContainer className={!cartItem.isGroup && isFamilyPrice(cartItem) ? "multiplePrices price-container" : "price-container"}>
								{cartItem.totalFamilyPrice !== undefined &&
									cartItem.totalFamilyPrice.Value !== 0 &&
									cartItem.totalFamilyPrice.Value !== cartItem.totalPrice.Value && <PriceControl price={cartItem.totalFamilyPrice} />}
								<PriceControl price={cartItem.totalPrice} />
							</PriceContainer>
						</QuantityPrice>
					</TopSection>

					<StockSection className="narrow">
						{isFoodItem && (
							<FoodStoreInfoContainer>
								{getMessage("SG_LBL_FoodItemStoreInfoMessage", messages, "Don't have stock info for food products")}
							</FoodStoreInfoContainer>
						)}
						<ScrollContainer>
							{!isFoodItem && (
								<StockView buttonStyle={true} disableForecast={cartItem.isCombination} messages={messages} retailItem={cartItem.retailItem} />
							)}
							{cartItem.childItems.length === 0 && (
								<LocationInfo>
									<LocationView disableForecast={cartItem.isCombination} messages={messages} retailItem={item} />
								</LocationInfo>
							)}
						</ScrollContainer>
					</StockSection>

					<MiddleSection>
						<StockSection className="wide">
							{isFoodItem ? (
								<FoodStoreInfoContainer>
									{getMessage("SG_LBL_FoodItemStoreInfoMessage", messages, "Don't have stock info for food products")}
								</FoodStoreInfoContainer>
							) : (
								<StockView buttonStyle={true} disableForecast={cartItem.isCombination} messages={messages} retailItem={cartItem.retailItem} />
							)}
							{cartItem.childItems.length === 0 && (
								<LocationInfo>
									<LocationView disableForecast={cartItem.isCombination} messages={messages} retailItem={item} />
								</LocationInfo>
							)}
						</StockSection>
						<QuantityPrice className="narrow" start="initial">
							<PriceContainer className={!cartItem.isGroup && isFamilyPrice(cartItem) ? "multiplePrices price-container" : "price-container"}>
								{cartItem.totalFamilyPrice !== undefined &&
									cartItem.totalFamilyPrice.Value !== 0 &&
									cartItem.totalFamilyPrice.Value !== cartItem.totalPrice.Value && <PriceControl price={cartItem.totalFamilyPrice} />}
								<PriceControl price={cartItem.totalPrice} />
							</PriceContainer>
						</QuantityPrice>
						<ActionSection>
							{canModifyOrder && (
								<IconButton
									onClick={event => {
										event.stopPropagation();
										handleRemove(cartItem.id);
									}}
									small={true}
									style={{ marginRight: "15px" }}
									type="secondary"
								>
									<SSRIcon paths={trashCan} />
								</IconButton>
							)}
							{!cartItem.isGroup && (
								<div
									onKeyPress={event => event.stopPropagation()}
									onClick={event => {
										event.stopPropagation();
									}}
									role="button"
									style={{ marginLeft: "20px" }}
									tabIndex="0"
								>
									<QuantityComponent key={item.ItemId} {...inputProps} />
								</div>
							)}
						</ActionSection>
					</MiddleSection>
				</ResponsiveWidth>
			</CardContainer>
			{cartItem.childItems.length > 0 && !ischildItem && (
				<Accordion>
					<CartAccordionItem
						key={GUID.Guid()}
						onHeadingClicked={() => {
							setshowChildItems(!showChildItems);
							dispatch(setisProductExpanded(cartItem.id, !showChildItems));
						}}
						open={showChildItems}
						title={`${cartItem.childItems.length} ${getMessage("SG_LBL_Articles", messages, "articles").toLowerCase()}`}
					>
						<ChildItemsContainer>
							{cartItem.childItems.map(childItem => (
								<CartItemCard
									cartItem={childItem}
									canModifyOrder={canModifyOrder}
									handleRemove={handleRemoveChild}
									handleUpdateQuantity={handleChildQntyChange}
									ischildItem={true}
									isModifyOrderinProgress={isModifyOrderinProgress}
									key={GUID.Guid()}
									messages={messages}
								/>
							))}
						</ChildItemsContainer>
					</CartAccordionItem>
				</Accordion>
			)}
		</div>
	);
};

export default CartItemCard;
