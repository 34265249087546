const OrderTypes = {
	ShoppingList: "SHOPPINGLIST",
	SHLIST: "SHLIST",
	CCorder: "CCORDER",
	ServiceOrder: "SALESORDER",
	IsomOrder: "ISOM",
	Draft: "DRAFT",
	Quotation: "QUOTATION"
};

export default OrderTypes;
