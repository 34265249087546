import { useSelector, useDispatch } from "react-redux";
import React from "react";

import { getStoreConfigSelector } from "../../utils/shellObjectService";
import { openOrder, setDraftOrderToActive } from "../../store/cart/cartActions";
import useMicrofrontend from "../../hooks/useMicroFrontend";
import { getCurrentCartDraftOrder } from "../../store/cart/cart.thunks";

const RecentOrderView = () => {
	const dispatch = useDispatch();

	var config = getStoreConfigSelector();

	var { appId, appUrl, appName } = config.environment.microapps.recentOrderApp;

	const { isLoaded, recentorder } = useMicrofrontend(appId, appUrl, appName);

	var { items, order, id } = useSelector(state => state.cartController);

	config.view = "recentorder";
	config.hasActiveOrder = order !== undefined;
	config.hasActiveDraft = order === undefined && items.length > 0;
	config.activeOrderId = id;
	const openDraftOrder = e => {
		var draft = e.detail;
		dispatch(setDraftOrderToActive(draft));
	};
	const openExistingOrder = e => {
		var { orderNo, saveCurrentAsDraft } = e.detail;
		if (saveCurrentAsDraft) {
			dispatch(getCurrentCartDraftOrder()).then(draftOrder => {
				recentorder && recentorder.saveCurrentCarttoDraftOrder(draftOrder);
			});
		}
		dispatch(openOrder(orderNo));
	};

	React.useEffect(() => {
		if (!recentorder) return;
		recentorder.SetSharedConfiguration(config);
		const { render, unMount, subscribe, unSubscribe } = recentorder;
		render(`recentorder-${appId}`, config);
		subscribe("OPEN_DRAFT_ORDER", openDraftOrder);
		subscribe("OPEN_ORDER", openExistingOrder);
		return () => {
			unMount(`recentorder-${appId}`);

			unSubscribe("OPEN_DRAFT_ORDER", openDraftOrder);
			unSubscribe("OPEN_ORDER", openExistingOrder);
		};
	}, [isLoaded]); // eslint-disable-line

	if (!isLoaded) return null;
	if (!recentorder) return <div />;
	return <div id={`recentorder-${appId}`} />;
};
export default RecentOrderView;
