import React from "react";

import { GUID } from "@coworker/sharedlibrary";

import { DraggableCartItemCard } from "./../cartItemCard/draggableCartItemCard";

const EditCartItemsList = ({ cartItems, expandedItems, onItemExpanded, onItemSelected, parentEditing, selectedItems }) => {
	return cartItems.map(cartItem => (
		<DraggableCartItemCard
			cartItem={cartItem}
			expandedItems={expandedItems}
			key={GUID.Guid()}
			onItemExpanded={onItemExpanded}
			onItemSelected={onItemSelected}
			parentEditing={parentEditing}
			selectedItems={selectedItems}
		/>
	));
};

export default EditCartItemsList;
