import { SHOW_WARNING, HIDE_WARNING, EXPAND_WARNING, DO_ACTION_WARNING } from "./warningActionTypes";
const initialState = {
	showAppWarning: false,
	isExpandAppWarning: false,
	warningAction: undefined,
	warningData: {
		type: "",
		title: "",
		messge: "",
		action: ""
	}
};

const warningReducer = (state, action) => {
	state = state || initialState;

	if (action.type === HIDE_WARNING) {
		return {
			...state,
			showAppWarning: false,
			isExpandAppWarning: false,
			warningData: action.payload,
			warningAction: undefined
		};
	}
	if (action.type === SHOW_WARNING) {
		return {
			...state,
			showAppWarning: true,
			isExpandAppWarning: false,
			warningData: action.payload
		};
	}
	if (action.type === EXPAND_WARNING) {
		return {
			...state,
			isExpandAppWarning: action.payload
		};
	}
	if (action.type === DO_ACTION_WARNING) {
		return {
			...state,
			warningAction: action.payload
		};
	}
	return state;
};

export default warningReducer;
