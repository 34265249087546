import React, { useState } from "react";
import Modal, { Sheets, ModalBody } from "@ingka/modal";
import UserAccountView from "../../microfrontends/microUserAccountView";
import styled from "styled-components";

const ModalWindowContainer = styled.div`
	.modal-header {
		background: #151e44;
	}
	.btn__inner--icon-primary-inverse {
		background: transparent !important;
		color: #fff !important;
	}
`;

const UserAccountDialog = ({ messages, activeTab, onClose }) => {
	const [sectionopened, setSectionopened] = useState(false);
	const onSectionopened = () => {
		setSectionopened(!sectionopened);
	};
	return (
		<ModalWindowContainer>
			<Modal
				visible
				handleCloseBtn={() => {
					onClose();
				}}
			>
				<Sheets>
					<ModalBody>
						<UserAccountView view={"userprofile"} messages={messages} onSectionClicked={onSectionopened} activeTab={activeTab} />
					</ModalBody>
				</Sheets>
			</Modal>
		</ModalWindowContainer>
	);
};

export default UserAccountDialog;
