import React from "react";
import MicroSearchView from "./microSearchView";

const SearchPageView = props => {
	const location = props.location;
	const [startSearch, setStartSearch] = React.useState(false);
	React.useEffect(() => {
		setStartSearch(false);
		setTimeout(() => {
			setStartSearch(true);
		}, 30);
	}, [props.location]);
	if (!startSearch) {
		return <div />;
	}
	return <MicroSearchView location={location} />;
};
export default SearchPageView;
