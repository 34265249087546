import React from "react";
import { useSelector } from "react-redux";
import styled, { createGlobalStyle } from "styled-components";
import { device } from "@coworker/sharedlibrary";
import BookedServices from "./components/bookedServices";
import CartBagHeader from "./components/cartBagHeader";
import CartCheckoutSummary from "./components/cartCheckOutSummary";
import CartEditSection from "./components/cartEditIconSection";
import CartItemsView from "./components/cartItemsList";
import CartPackageSummary from "./components/cartPackageSummary";
import EditCart from "./components/editCart/editCart";
import EmptyCartView from "./components/EmptyCartView";
import ScanArticlesNotifier from "./components/scanArticlesNotifier";
import WarningContainer from "../../components/warning/warning";
import useCartHook from "./hooks/useCartHook";

const CartViewContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	overflow-y: hidden !important;

	@media ${device.medium} {
		width: 100%;
		height: auto;
		flex-direction: column;
	}
`;

const CartLeftContainer = styled.div`
	width: 100%;
	height: 100%;
	overflow-y: auto !important;
	padding: 31px 406px 0 0;

	@media ${device.large} {
		padding-right: 48%;
	}

	@media ${device.medium} {
		width: 100%;
		height: 100%;
		overflow-y: hidden !important;
		padding-right: 0;
	}
`;

const CartTopSummarySection = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 20px 5px;

	@media ${device.medium} {
		margin: 0 12px;
		padding: 0;
	}
`;

const CartBagHeaderExtras = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 20px;

	@media ${device.small} {
		margin-top: 15px;
	}
`;

const CartItemsSection = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const HideBodyScrollStyle = createGlobalStyle`
  body {
    overflow-y: hidden;

    @media ${device.medium} {
      overflow-y: auto;
    }
  }
`;

// TODO: FIX FROM HERE

const CartView = props => {
	const { showBusyLoading, showNotification, standaloneMode } = props;
	const { messages } = useSelector(state => state.intl);
	const { isEmptyBag, editBag, canPerformDelete, canPerformGroup, canPerformShare, setEditBag } = useCartHook(props);
	if (isEmptyBag) {
		return <EmptyCartView></EmptyCartView>;
	} else {
		return (
			<>
				<WarningContainer style={{ margin: "10px 0" }} />
				<CartViewContainer>
					<HideBodyScrollStyle />
					<CartLeftContainer>
						{!editBag && (
							<CartTopSummarySection>
								<CartBagHeader />
								<CartBagHeaderExtras>
									<CartPackageSummary />
									<CartEditSection
										canDelete={canPerformDelete}
										canGroup={canPerformGroup}
										canShare={canPerformShare}
										messages={messages}
										onEditBag={() => {
											setEditBag(true);
										}}
									/>
								</CartBagHeaderExtras>
								<ScanArticlesNotifier></ScanArticlesNotifier>
							</CartTopSummarySection>
						)}
						{editBag ? (
							<EditCart
								onCloseEdit={() => {
									setEditBag(false);
								}}
							/>
						) : (
							<CartItemsSection>
								<BookedServices />

								<CartItemsView />
							</CartItemsSection>
						)}
					</CartLeftContainer>
					<CartCheckoutSummary
						disabled={editBag}
						showBusyLoading={showBusyLoading}
						showNotification={showNotification}
						standaloneMode={standaloneMode}
					/>
				</CartViewContainer>
			</>
		);
	}
};

export default CartView;
