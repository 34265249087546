import { AppLogger, StorageHelper } from "@coworker/sharedlibrary";
import { colors } from "@coworker/sharedlibrary";
import { connect } from "react-redux";
import { setUser, logOutUser } from "./../store/auth/actions";
import { bindActionCreators } from "redux";
import { AppConfigService } from "./../service/appConfigService";
import { useSelector } from "react-redux";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Login from "./login.js";
import AzureAuthService from "../service/azureAuthService";
import SplitText from "react-pose-text";
import { List, SaljaLogo } from "../components/sharedlibrary";
import { appactionCreators } from "../store/shell/shellAction";
import { actions as localeActions } from "../store/locale/index";
import RetryModeView from "./startup/retryModeView";
import GuestModeView from "./startup/guestMode";
import SalesCoworkerConfiguration from "../store/salesCoworkerConfiguration";
import LoadUserConfig from "../service/loadUserConfig";
import LoadExternalUserConfig from "../external/service/loadExternalUserConfig";
import setUpGuestLogin from "../service/setUpGuestLogin";
import { RequestHelper } from "@coworker/sharedlibrary";
import { isExternalClientUrl } from "../helpers/externalUrlHelper";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: ${colors.appColor};
	height: 100vh;
`;

const AppTitle = styled(List)`
	&.app-title {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;

const IkeaTitle = styled.div`
	margin-top: 10px;
	text-align: center !important;
	color: ${colors.white} !important;
	width: 150px;
	height: 23px;
	align-self: center;
	font-size: 12px;
	font-weight: bold !important;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff;
`;

const LoadingContainer = styled(List)`
	&.loading-container {
		height: 23px;
		position: relative;
	}
`;

const LoadingTitle = styled.p`
	margin-top: 10px;
	text-align: center !important;
	color: ${colors.white} !important;
	width: 200px;
	height: 23px;
	align-self: center;
	font-size: 14px;
	font-weight: normal !important;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff;
`;

const charPoses = {
	exit: { opacity: 0, y: 20 },

	enter: {
		opacity: 1,
		y: 0,
		delay: ({ charIndex }) => charIndex * 30
	}
};

const SplashView = ({ changeLocale, getTranslations, setUser, isExternalClient }) => {
	const dispatch = useDispatch();
	const [showLogin, setLogin] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState("");
	const [isLoadingMain, setIsLoadingMain] = useState(false);
	const [showRetryView, setShowRetryView] = useState(false);
	const [isAllowed, setIsAllowed] = useState(true);
	const [azureConfig, setAzureConfig] = useState(null);
	const { sessionId } = useSelector(state => state.appController);

	React.useEffect(() => {
		SalesCoworkerConfiguration.setIsExternal(isExternalClient);
		if (!isExternalClient) {
			StorageHelper.clearFromStorage("externalurl");
		}
		AppConfigService.getAuthConfig()
			.then(result => {
				setAzureConfig(result);
				const token = RequestHelper.getToken();
				if (token) {
					const authService = new AzureAuthService(result.authConfig);
					authService.checkTokenValid().then(valid => {
						if (valid === true) {
							UserConfig();
						} else {
							setLogin(true);
						}
					});
				} else {
					setLogin(true);
				}
			})
			.catch(error => {
				setLogin(true);
				AppLogger.error(`AppConfigService.getAuthConfig failed :${error.message}`, "AppConfigService.getAuthConfig", sessionId);
			});
	}, []);

	const UserConfig = () => {
		if (isExternalClient) {
			LoadExternalUserConfig({
				setLogin,
				changeLocale,
				getTranslations,
				setUser,
				dispatch,
				sessionId
			});
		} else {
			LoadUserConfig({
				setLogin,
				setLoadingMessage,
				setIsLoadingMain,
				setShowRetryView,
				setIsAllowed,
				changeLocale,
				getTranslations,
				setUser,
				dispatch,
				sessionId
			});
		}
	};
	const GuestLogin = bu => {
		setUpGuestLogin({
			bu,
			changeLocale,
			getTranslations,
			setUser,
			dispatch
		});
	};

	if (isExternalClient) {
		if (isExternalClientUrl() && !window.location.href.includes("code=")) {
			localStorage.setItem("externalurl", window.location.href);
			SalesCoworkerConfiguration.setIsExternal(true);
		}
	}
	if (!isAllowed) {
		return <GuestModeView loginAsGuest={GuestLogin} />;
	}

	if (showRetryView) {
		return (
			<RetryModeView
				onRetry={() => {
					UserConfig();
				}}
			/>
		);
	}

	return (
		<Container>
			{!isExternalClient && (
				<AppTitle className="app-title">
					<SaljaLogo loading={isLoadingMain ? true : false} />
					<IkeaTitle>
						<SplitText initialPose="exit" pose="enter" charPoses={charPoses}>
							SÄLJA GO!
						</SplitText>
					</IkeaTitle>
					<LoadingContainer className="loading-container">{isLoadingMain && <LoadingTitle>{loadingMessage}</LoadingTitle>}</LoadingContainer>
				</AppTitle>
			)}
			{showLogin && (
				<>
					{azureConfig && azureConfig.authConfig ? (
						<Login autoLogin={isExternalClient} onLoginSuccess={() => UserConfig()} config={azureConfig} visible={showLogin} />
					) : (
						<div
							style={{
								display: "flex",
								alignItems: "center",
								height: "120px",
								padding: "10px",
								zIndex: 100,
								background: "red",
								color: "white"
							}}
						>
							{"Config not loaded ,Check server is started correctly"}
						</div>
					)}
				</>
			)}
		</Container>
	);
};

export default connect(null, dispatch => bindActionCreators({ setUser, ...appactionCreators, ...localeActions, logOutUser }, dispatch))(SplashView);
