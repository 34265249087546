class Roomset {
	constructor(roomset) {
		if (roomset === null) {
			return;
		}

		this.ItemId = roomset.id;
		this.Name = roomset.name;
		this.itemDescription = "";
		this.childItems = roomset.childItems;
		this.price = { Type: "NORMAL", Currency: "", Value: 0 };
		this.familyPrice = { Type: "FAMILY", Currency: "", Value: 0 };
		this.availableStockToSell = 0;
	}
}

export default Roomset;
