const searchBoxVariables = {
	hfbValues: {
		TRANSPORT_DESK: "hfb_18",
		TRANSPORT_DESK_EMPU: "transportdesk_empu",
		CUSTOMER_SUPPORT_CENTER: "hfb_19"
	},
	deliveryMethods: {
		NO_STOCK_DELIVERY_ID: "SGR30000937"
	},
	userSettings: {
		coworker_work_area: "coworkerWorkArea"
	}
};

export default searchBoxVariables;
