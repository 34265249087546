import { createGlobalStyle } from "styled-components";

import loading from "./static/loading.svg";

import BoldFontTTF from "./assets/fonts/NotoIKEALatin/NotoIKEALatin-Bold.ttf";
import BoldFontWoff from "./assets/fonts/NotoIKEALatin/NotoIKEALatin-Bold.woff";
import BoldItalicFontTTF from "./assets/fonts/NotoIKEALatin/NotoIKEALatin-BoldItalic.ttf";
import BoldItalicFontWoff from "./assets/fonts/NotoIKEALatin/NotoIKEALatin-BoldItalic.woff";
import ItalicFontTTF from "./assets/fonts/NotoIKEALatin/NotoIKEALatin-Italic.ttf";
import ItalicFontWoff from "./assets/fonts/NotoIKEALatin/NotoIKEALatin-Italic.woff";
import RegularFontTTF from "./assets/fonts/NotoIKEALatin/NotoIKEALatin-Regular.ttf";
import RegularFontWoff from "./assets/fonts/NotoIKEALatin/NotoIKEALatin-Regular.woff";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'NotoIKEALatin';
    src: url(${BoldItalicFontWoff}) format('woff'),
     url(${BoldItalicFontTTF}) format('truetype');
    font-weight: bold;
    font-style: italic;
  }
  @font-face {
    font-family: 'NotoIKEALatin';
    src: url(${ItalicFontWoff}) format('woff'),
     url(${ItalicFontTTF}) format('truetype');
    font-style: italic;
  }
  @font-face {
    font-family: 'NotoIKEALatin';
    src: url(${BoldFontWoff}) format('woff'),
     url(${BoldFontTTF}) format('truetype');
    font-weight: bold;
  }
  @font-face {
    font-family: 'NotoIKEALatin';
    src: url(${RegularFontWoff}) format('woff'),
     url(${RegularFontTTF}) format('truetype');
  }

  body ,html, #root {
    background-color: #fff;
    width: 100%;
    height: 100%;
    min-height: 100%;
    font-family: 'NotoIKEALatin';
    overscroll-behavior-y: contain;
     margin: 0px;
  }

  * {
    -webkit-tap-highlight-color: transparent;
  }

  a  {
  text-decoration: none;
    user-select: none;
    overscroll-behavior-y: contain;
  }

  img {
    background-image: url(${loading});
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    background-size: 50%;
  }

  .btn--outlined, .btn--transactional, .btn--filled, .btn--text, .btn--danger {
    border-radius: 28px !important;
    :focus,:hover,:active {border-radius: 28px !important;}
  }

  .tabs__tab {
    color: #111111;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
  }

  .tabs__tab--active {
    color: #111111;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
  }

  * {
    .checkbox{
    input[type=checkbox]{
      transform: translateY(5%);
    }
  } 
}
`;

export const HideBodyScrollStyle = createGlobalStyle`
  body {
    overflow-y:hidden !important;
  }
`;
