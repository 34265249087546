import React, { useState, useImperativeHandle, forwardRef } from "react";
import Switch from "@ingka/switch";
import styled from "styled-components";
import { Container } from "../../../components/sharedlibrary";
import { useSelector } from "react-redux";
import { ValidationHelper, DfpKey } from "@coworker/sharedlibrary";
import RadioButton from "@ingka/radio-button";
import InputField from "@ingka/input-field";
import { useStorePreference } from "../../../hooks/useStorePreference";
import { getStoreConfigSelector } from "../../../utils/shellObjectService";

const NotifySwitch = styled(Switch)`
	margin: 40px 0 0 0;
`;

const NotifyCheckBox = styled(RadioButton)`
	margin: 35px 0 5px 0;
`;

const OutputContainerLayout = styled(Container)`
	&.output-container {
		${props => props.disabled && "opacity: 0.4;pointer-events:none;"}
	}
`;

const OrderPickupNotification = forwardRef((props, ref) => {
	useImperativeHandle(ref, () => ({
		isValid() {
			return isValid();
		}
	}));
	const { messages } = useSelector(state => state.intl);
	const config = getStoreConfigSelector();
	const defaultPreferredContact = useStorePreference(DfpKey.PREFERRED_CONTACT_METHOD_FOR_NOTIFICATION_PICKING_READY_FOR_HANDOUT);
	const { customer } = props;

	const [emailChecked, setEmailChecked] = useState(defaultPreferredContact === "EMAIL");
	const [smsChecked, setSmsChecked] = useState(defaultPreferredContact === "EMAIL" ? false : true);
	const [enabledNotification, setEnabledNotification] = useState(true);
	const [emailAddress, setEmailAddress] = useState(customer && customer.email);
	const [smsNumber, setSmsNumber] = useState(customer && customer.mobilePhone);
	const [validaEntry, setValidatEntry] = useState({
		isValidEmail: true,
		isValidSms: true
	});

	const emailProptext = {
		type: "email",
		autoComplete: "off",
		id: "emailText",
		defaultValue: emailAddress || "",
		shouldValidate: !validaEntry.isValidEmail,
		onBlur: e => {
			setEmailAddress(e.target.value);
			setValidatEntry({
				isValidEmail: isValidEmailAddress(e.target.value),
				isValidSms: validaEntry.isValidSms
			});
		},
		onChange: e => {
			setEmailAddress(e.target.value);
		},
		label: messages.SG_LBL_EnterEmail,
		validation: {
			body: messages.SG_LBL_EmailWarning,
			id: "invalidEmail"
		}
	};
	const smsNumberProptext = {
		type: "tel",
		autoComplete: "off",
		id: "smsText",
		defaultValue: smsNumber || "",
		onBlur: e => {
			setSmsNumber(e.target.value);
			setValidatEntry({
				isValidEmail: validaEntry.isValidEmail,
				isValidSms: isValidSms(e.target.value)
			});
		},
		onChange: e => {
			setSmsNumber(e.target.value);
		},
		label: messages.SG_LBL_MobilePhone,
		shouldValidate: !validaEntry.isValidSms,
		validation: {
			body: messages.SG_LBL_MobilePhoneNumberWarning,
			id: "invalidSms",
			type: "error"
		}
	};

	function isValidEmailAddress(emailvalue) {
		return emailvalue && emailvalue !== "" && ValidationHelper.IsValidEmail(emailvalue.trim());
	}

	function isValidSms(smsValue) {
		return smsValue && smsValue !== "" && ValidationHelper.IsValidPhoneNumber(smsValue.trim(), config.countryCode);
	}

	function isValid() {
		if (!enabledNotification) {
			return {
				isValid: true,
				notifications: []
			};
		}

		let isEmailValidEntry = true;
		let isSmsValidEntry = true;
		if (emailChecked) {
			isEmailValidEntry = isValidEmailAddress(emailAddress);
		}
		if (smsChecked) {
			isSmsValidEntry = smsNumber && smsNumber !== "" && ValidationHelper.IsValidPhoneNumber(smsNumber.trim(), config.countryCode);
		}
		setValidatEntry({
			isValidEmail: isEmailValidEntry,
			isValidSms: isSmsValidEntry
		});
		let pickupNotification = {
			isValid: isEmailValidEntry && isSmsValidEntry,
			emailAddress: emailAddress,
			smsNumber: smsNumber,
			enabledNotification: enabledNotification
		};

		let pickupData = getPickupNotifications(pickupNotification);
		return {
			isValid: pickupNotification.isValid,
			notifications: pickupData
		};
	}

	function getPickupNotifications(pickupNotification) {
		if (pickupNotification && pickupNotification.enabledNotification && pickupNotification.isValid) {
			let notifications = [];
			if (pickupNotification.emailAddress && emailChecked) {
				notifications.push({
					contactMethodData: pickupNotification.emailAddress,
					contactMethodType: "EMAIL",
					type: "PICKING_READY_FOR_HANDOUT"
				});
			}
			if (pickupNotification.smsNumber && smsChecked) {
				notifications.push({
					contactMethodData: pickupNotification.smsNumber,
					contactMethodType: "MOBILE",
					type: "PICKING_READY_FOR_HANDOUT"
				});
			}
			return notifications;
		}
		return [];
	}

	return (
		<Container
			styles={{
				width: "100%",
				flexDirection: "column"
			}}
		>
			<h2>{messages.SG_LBL_OrderPickupTitle || "How would you like to notify customer about pick-up?"}</h2>
			<NotifySwitch
				data-testid="notifySwitch"
				label={messages.SG_BTN_NotifyCustomer || "Notify customer"}
				checked={enabledNotification}
				onChange={e => {
					setEnabledNotification(e.target.checked);
				}}
			></NotifySwitch>
			<OutputContainerLayout
				className="output-container"
				disabled={!enabledNotification}
				styles={{
					width: "100%",
					flexDirection: "column"
				}}
			>
				<NotifyCheckBox
					data-testid="email"
					id="email"
					name="rdNotification"
					checked={emailChecked}
					disabled={!enabledNotification}
					onChange={e => {
						if (!e.target.checked) {
							setEmailAddress("");
						}
						setEmailChecked(e.target.checked);
						setSmsChecked(!e.target.checked);
					}}
					label={messages.SG_LBL_Email}
				/>
				{emailChecked && <InputField {...emailProptext} />}
				<NotifyCheckBox
					data-testid="sms"
					id="sms"
					name="rdNotification"
					checked={smsChecked}
					disabled={!enabledNotification}
					onChange={e => {
						if (!e.target.checked) {
							setSmsNumber("");
						}
						setSmsChecked(e.target.checked);
						setEmailChecked(!e.target.checked);
					}}
					label={messages.SG_BTN_SMS}
				/>
				{smsChecked && <InputField {...smsNumberProptext} />}
			</OutputContainerLayout>
		</Container>
	);
});

OrderPickupNotification.displayName = "OrderPickupNotification";
export default OrderPickupNotification;
export { OrderPickupNotification };
