import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Dropdown, DropdownItem } from "@ingka/dropdown";
import { getMessage } from "@coworker/sharedlibrary";
import { useSelector } from "react-redux";

import CustomerCreditCheck from "./customerCreditCheck";
import SalesCoworkerConfiguration from "../../../../store/salesCoworkerConfiguration";
import getPaymentTypes from "./getPaymentTypes";
import { Container } from "./../../../../components/sharedlibrary";
import { getCustomerProfileObject } from "./../../../../utils/shellObjectService";
import { getOrderAmountSummarySelector } from "./../../../../store/cart/cartSelectors";

const DropDownSelect = styled(Dropdown)`
	width: 100% !important;
	margin-bottom: 10px;
	margin-top: 10px;
`;

const PaymentTypesSelection = ({ onPaymentMethodChange, credit, setCredit }) => {
	const { environment, isCashLine } = SalesCoworkerConfiguration.getUserConfig();
	const { messages } = useSelector(state => state.intl);
	const { isTransportDeskMode } = useSelector(state => state.cartController);
	const { servicePrice, totalPrice: total } = useSelector(state => getOrderAmountSummarySelector(state));

	const [loading, setLoading] = React.useState(true);

	const { icmCustomer } = getCustomerProfileObject();

	let amount = 0;
	if (isTransportDeskMode && !isCashLine) {
		amount = servicePrice.totalServicePrice.Value;
	} else {
		amount = total.Value;
	}
	const { paymentType } = useSelector(state => state.cartController);

	const [paymentTypes, setPaymenttypes] = useState([]);
	const [selectedPayment, setSelectedPayment] = React.useState(paymentType);

	useEffect(() => {
		if (paymentType) onPaymentMethodChange && onPaymentMethodChange(paymentType);
	}, []);

	useEffect(() => {
		setLoading(true);
		let paymentTypesList = [];
		if (icmCustomer && icmCustomer.Customer && icmCustomer.Customer.isInvoiceCustomer && environment.invoiceCustomerPaymentEnabled && amount > 0) {
			paymentTypesList.push({
				id: "PAYMENT_IN_INVOICE",
				name: getMessage("cart_lbl_paymentoption4", messages, "Pay by Invoice")
			});
		}

		const paymentTypes = getPaymentTypes();
		if (paymentTypes) {
			paymentTypesList = paymentTypesList.concat(paymentTypes);
			setPaymenttypes(paymentTypesList);
		}
		setLoading(false);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function onPaymentchange(event) {
		let selectedMethod = event.target.value;
		setSelectedPayment(selectedMethod);
		onPaymentMethodChange && onPaymentMethodChange(selectedMethod);
	}

	if (loading) {
		return <div></div>;
	}
	return (
		<Container width="100%" fdCol>
			<DropDownSelect
				id="buSelect"
				label={getMessage("SG_LBL_PaymentOption", messages, "Payment option")}
				onChange={onPaymentchange}
				defaultValue={selectedPayment}
			>
				{paymentTypes && paymentTypes.map(element => <DropdownItem id={element.id} key={element.id} value={element.id} name={element.name} />)}
			</DropDownSelect>

			{selectedPayment === "PAYMENT_IN_INVOICE" && <CustomerCreditCheck setCredit={setCredit} credit={credit} amount={amount}></CustomerCreditCheck>}
		</Container>
	);
};
export default PaymentTypesSelection;
