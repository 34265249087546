import { useDispatch, useSelector } from "react-redux";
import { GaHelper } from "@coworker/sharedlibrary";
import Button from "@ingka/button";
import { device } from "@coworker/sharedlibrary";
import React from "react";
import Accordion, { AccordionItem } from "@ingka/accordion";
import { changeOrderToDraftOrder } from "./../../../store/cart/cart.thunks";
import styled from "styled-components";
import { useHotKeys } from "../../../hooks/useHotKeys";
import { useCartModelPopup } from "./modelPopup";
import { GUID } from "@coworker/sharedlibrary";
import MicroOrderServicesView from "../../../components/microfrontends/microOrderServicesView";
import { useInputPopup } from "../../../components/inputPopup";
import { getCustomerProfileObject, getStoreConfigSelector } from "../../../utils/shellObjectService";
import { CanBookService } from "../canCreateSalesOrderValidator";

const Container = styled.div`
	.accordion {
		background: transparent;
		width: 100%;
		list-style: none;
		margin: 0;
	}

	.accordion__heading {
		min-height: 90px;

		&.chunky-header {
			border-top: none;
			border-bottom: 1px solid #dfdfdf;
			padding: 20px;
		}
	}

	.accordion-item-header {
		font-weight: 700;
		padding: 0 20px;
		border-top: none;
	}

	.accordion__title {
		font-size: 14px;
		line-height: 1.57;
	}

	.accordion__content {
		padding-top: 0 !important;
	}

	.accordion__item {
		min-height: 90px;

		&--active {
			.accordion__heading {
				&.chunky-header {
					border-bottom: none;
				}
			}
		}
	}
`;

const AddService = styled.div`
	height: 90px;
	display: flex;
	align-items: center;
	padding: 20px;
	border-width: 0 0 1px 0;
	flex-direction: row;
	justify-content: ${props => (props.btnOnly ? "flex-end" : "space-between")};

	@media ${device.medium} {
		margin: 0 12px;
		padding: 0;
	}
`;

const BookedServices = () => {
	const { messages } = useSelector(state => state.intl);
	const canBookService = useSelector(state => CanBookService(state));
	const [showServicePopup, setshowServicePopup] = React.useState(false);
	const { getInput } = useInputPopup();
	const { getCartModel } = useCartModelPopup();
	const dispatch = useDispatch();

	const { customer } = getCustomerProfileObject();

	const { isModifyOrderinProgress, hasServices, order, bookedServiceProposal = {} } = useSelector(state => state.cartController);
	let collapsed = !hasServices;
	const { isMissingDeliveryForNewItems = false } = bookedServiceProposal;
	const { buCode } = getStoreConfigSelector();

	const showExperiments = buCode === "548";

	useHotKeys(["cntrl+d"], () => {
		if (!showServicePopup && canBookService && !isModifyOrderinProgress) {
			showServiceView("ZIPCODE_VIEW");
			GaHelper.SendEvent("Service", "AddService", "User Selected Add service");
		}
	});
	async function showServiceView(view) {
		setshowServicePopup(true);
		await getInput("orderService", {
			viewState: view || "ZIPCODE_VIEW",
			view: "SERVICE_VIEW",
			showScroll: false
		});
		setshowServicePopup(false);
	}
	async function onConvertDialog() {
		var result = await getCartModel("convertOrderDialog", {
			convertOrderDialogMessage: messages.SG_LBL_ConvertOrder
		});
		if (result) {
			dispatch(changeOrderToDraftOrder());
			showServiceView();
		}
	}
	let canAddService;
	if (isModifyOrderinProgress) {
		canAddService = order.canAddService;
	} else {
		canAddService = !isMissingDeliveryForNewItems;
	}
	if (!hasServices) {
		if (showExperiments && customer.zipCode !== undefined) {
			collapsed = false;

			return (
				<Container>
					<Accordion>
						<AccordionItem open={!collapsed} title={messages.SG_LBL_Services} key={GUID.Guid()}>
							<div>
								<MicroOrderServicesView currentView={"CART"} view={"CART_SUGGESTION_VIEW"}></MicroOrderServicesView>

								<AddService btnOnly>
									<Button
										data-testid="addServiceSection"
										className="addServiceSection"
										disabled={!canBookService}
										type="secondary"
										text={messages.SG_BTN_OtherServices || "Other Services"}
										small
										onClick={() => {
											if (isModifyOrderinProgress && order && !order.hasService) {
												onConvertDialog();
											} else {
												showServiceView();
												GaHelper.SendEvent("Service", "AddService", "User Selected Add service");
											}
										}}
									/>
								</AddService>
							</div>
						</AccordionItem>
					</Accordion>
				</Container>
			);
		}

		return (
			<AddService>
				<h6>{messages.SG_LBL_Services}</h6>
				<Button
					data-testid="addservicebtn"
					className="addServiceSection"
					disabled={!canBookService}
					type="primary"
					text={messages.SG_BTN_AddServices}
					small
					onClick={() => {
						if (isModifyOrderinProgress && order && !order.hasService) {
							onConvertDialog();
						} else {
							showServiceView();
							GaHelper.SendEvent("Service", "AddService", "User Selected Add service");
						}
					}}
				/>
			</AddService>
		);
	}
	return (
		<Container>
			<Accordion>
				<AccordionItem open={!collapsed} title={messages.SG_LBL_Services} key={GUID.Guid()}>
					<div>
						<MicroOrderServicesView currentView={"CART"} view={"PROPOSAL"} compactMode={true} canEditService={canAddService} />
						{canAddService && (
							<AddService btnOnly>
								<Button
									data-testid="showServiceView"
									disabled={!canBookService}
									type="secondary"
									text={messages.SG_BTN_AddServices}
									small
									onClick={() => {
										if (isModifyOrderinProgress && order && !order.hasService) {
											onConvertDialog();
										} else {
											showServiceView("BEFOREPROPOSAL");
											GaHelper.SendEvent("Service", "AddService", "User Selected Add service");
										}
									}}
								/>
							</AddService>
						)}
					</div>
				</AccordionItem>
			</Accordion>
		</Container>
	);
};

export default BookedServices;
