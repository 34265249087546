import { useDispatch } from "react-redux";
import React from "react";
import MicroUserAccountView from "../components/microfrontends/microUserAccountView";
import { appactionCreators } from "../store/shell/shellAction";
import { Container } from "../components/sharedlibrary";

const WelcomeView = () => {
	const dispatch = useDispatch();
	return (
		<Container
			styles={{
				width: "100%",
				display: "flex",
				height: "100%"
			}}
		>
			<MicroUserAccountView
				width="100%"
				view={"userhfbview"}
				onhfbCallback={selectedHfbList => {
					dispatch(appactionCreators.changeSelectedHfb(selectedHfbList));
				}}
			/>
		</Container>
	);
};

export default WelcomeView;
