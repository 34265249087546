import { dispatchEvent, eventsToDispatch } from "./eventDispatcher";

const NativeLogger = {
	warn: (message, event, properties) => {
		dispatchEvent(eventsToDispatch.LOG_TO_NATIVE, {
			level: "WARN",
			event: event,
			message: message,
			logData: properties
		});
	},
	info: (message, event, properties) => {
		dispatchEvent(eventsToDispatch.LOG_TO_NATIVE, {
			level: "INFO",
			event: event,
			message: message,
			logData: properties
		});
	},
	error: (message, event, properties) => {
		dispatchEvent(eventsToDispatch.LOG_TO_NATIVE, {
			level: "ERROR",
			event: event,
			message: message,
			logData: properties
		});
	}
};

export default NativeLogger;
