import { APIEndpoints, RecentOrderMapper, ShoppingCartItemFactory, services } from "@coworker/sharedlibrary";
import { CustomerModel, ObjectHelper, SharedConfiguration, ValidationHelper } from "@coworker/sharedlibrary";
import { URI, httpService } from "@coworker/sharedlibrary";

import AppLogger from "../logger/appLogger";
import OrderAmountCalculatorService from "./cart/orderAmountCalculatorService";
import QuotationWrapperService from "./quotation/quoationApi";
import SalesCoworkerConfiguration from "../store/salesCoworkerConfiguration";

class OrderRetreivalService {
	constructor() {
		this.config = SalesCoworkerConfiguration.getUserConfig();
	}
	/**
	 * Calls get order API to details of a order
	 *
	 *
	 * @param {String} orderno [RetailItem]
	 * @param {Boolean} fetchStock fetches Stock of retailItems if true
	 *
	 * @return {Object} Return Order
	 */
	async fetchOrder(orderno) {
		try {
			this.config = SalesCoworkerConfiguration.getUserConfig();
			if (isGuestMode()) {
				AppLogger.warn("In Guest mode order retreival not supported", "GETORDER");
				return undefined;
			}
			let isModificationAllowed = true;
			if (this.config.environment.isomAllowedModificationMarkets) {
				isModificationAllowed =
					this.config.environment.isomAllowedModificationMarkets.includes("*") ||
					this.config.environment.isomAllowedModificationMarkets.includes(this.config.countryCode);
			}

			let baseUrl = SharedConfiguration.getApiBaseUrl("cartservices");
			let url = new URI(`${baseUrl}${APIEndpoints.getOrder}`, true).fill({
				orderNo: orderno,
				fetchallowedModification: isModificationAllowed
			});
			let orderResponse = await httpService.get(url, {
				headers: { ...this.config.headers }
			});
			if (orderResponse.error) {
				AppLogger.error("Failed to fetch order", "GETORDER", {
					orderId: orderno
				});
				return undefined;
			}
			return orderResponse;
		} catch (error) {
			AppLogger.info(error.message, "GETORDER", { orderId: orderno });
			return undefined;
		}
	}
	/**
	 * Calls get order API to details of a order
	 *
	 *
	 * @param {String} orderno [RetailItem]
	 * @param {Boolean} fetchStock fetches Stock of retailItems if true
	 *
	 * @return {Object} Return Order
	 */
	async setOrder(orderResponse, fetchStock = true, fetchItems = true) {
		try {
			this.config = SalesCoworkerConfiguration.getUserConfig();
			let itemsIds = (orderResponse && orderResponse._embedded && orderResponse._embedded.itemsList) || [];
			let retailItems = [];
			if (itemsIds && itemsIds.length > 0 && fetchItems) {
				let productService = new services.ProductService(this.config);
				retailItems = await productService.getRetailItemsByid(itemsIds, fetchStock);
			}
			let order = RecentOrderMapper.mapRecentOrder(orderResponse, retailItems, this.config.isCashLine);
			if (order && order.hasReviewOrder) {
				//check user privileges
				let hasCanCancelReviewOrder = SalesCoworkerConfiguration.getPrivilege("ikea.mtc.mfs.sales.userpriv.CAN_CANCEL_REVIEW_ORDER");
				order.canCancelOrder = hasCanCancelReviewOrder !== undefined;
			}
			if (order) return order;
		} catch (error) {
			return undefined;
		}
	}
	/**
	 * Calls get order API to details of a order
	 *
	 *
	 * @param {String} orderno [RetailItem]
	 * @param {Boolean} fetchStock fetches Stock of retailItems if true
	 *
	 * @return {Object} Return Order
	 */
	async getOrder(orderno, fetchStock = true, fetchItems = true) {
		try {
			this.config = SalesCoworkerConfiguration.getUserConfig();
			if (isGuestMode()) {
				AppLogger.warn("In Guest mode order retreival not supported", "GETORDER");
				return undefined;
			}
			let baseUrl = SharedConfiguration.getApiBaseUrl("cartservices");
			let url = new URI(`${baseUrl}${APIEndpoints.getOrder}`, true).fill({
				orderNo: orderno,
				fetchallowedModification: fetchItems
			});
			let orderResponse = await httpService.get(url, {
				headers: { ...this.config.headers }
			});

			return this.setOrder(orderResponse, fetchStock, fetchItems);
		} catch (error) {
			AppLogger.info(error.message, "GETORDER", { orderId: orderno });
			return undefined;
		}
	}

	/**
	 * Calls get quotation api to get getQuotation
	 *
	 *
	 * @param {String} quotNo [Quotation]
	 * @return {Object} Return Quotation
	 */
	async getQuotation(quotNo) {
		try {
			this.config = SalesCoworkerConfiguration.getUserConfig();
			var factory = new ShoppingCartItemFactory();
			var quotation = await QuotationWrapperService.viewQuotation(quotNo);
			var itemIds = [];
			quotation.items.forEach(itm => {
				itemIds.push(`${itm.itemType}${itm.itemNo}`);
				if (itm.itemType === "SPR" && itm.childItems) {
					itm.childItems.forEach(citm => {
						itemIds.push(`${citm.itemType}${citm.itemNo}`);
					});
				}
			});
			let productService = new services.ProductService(this.config);
			var retailItems = await productService.getRetailItemsByid(itemIds);
			var orderItems = quotation.items.map(itm => {
				var retailItem = retailItems.find(x => x.ItemId === itm.itemNo);

				var cartItem = factory.createShoppingCartItem(retailItem, itm.quantity);
				var childItems = [];
				if (itm.itemType === "SPR" && itm.childItems) {
					itm.childItems.forEach(citm => {
						var childRetailItem = retailItems.find(x => x.ItemId === citm.itemNo);
						var cartChildItem = factory.createShoppingCartItem(childRetailItem, citm.quantity);
						cartChildItem = OrderAmountCalculatorService.updateCartPriceForQuotation(cartChildItem, citm, quotation.currencyCode);
						childItems.push(cartChildItem);
					});
					cartItem.childItems = childItems;
				}
				return OrderAmountCalculatorService.updateCartPriceForQuotation(cartItem, itm, quotation.currencyCode);
			});
			orderItems = factory.reAssignCartItemId(orderItems);

			var result = {
				...quotation,
				orderItems: orderItems
			};
			return result;
		} catch (error) {
			console.log(error);
			return undefined;
		}
	}

	/**
	 * Calls get coworkerorders to list  logged in coworker orders in 30 days
	 *
	 * @return {Object} Return Quotation
	 */
	async getCoworkerOrders() {
		try {
			this.config = SalesCoworkerConfiguration.getUserConfig();
			if (isGuestMode()) {
				AppLogger.warn("In Guest mode order retreival not supported", "getCoworkerOrders");
				return [];
			}
			var { storeInfo, buCode, countryCode, storeCurrency } = SalesCoworkerConfiguration.getUserConfig();
			var timeZone = (storeInfo && storeInfo.TimeZone) || "Europe/Stockholm";

			let baseUrl = SharedConfiguration.getApiBaseUrl("cartservices");

			let url = new URI(`${baseUrl}${APIEndpoints.coworkerOrdersByBu}`, true).fill({
				countryCode: countryCode,
				buCode: buCode
			});

			var result = await httpService.get(url, {
				headers: { ...this.config.headers, timeZone: timeZone }
			});
			if (result && result.orders && result.orders.length > 0)
				return result.orders.map(ord => {
					return {
						...ord,
						customerName: ord.customerName || "",
						currencyCode: ord.currencyCode || storeCurrency,
						id: ord.orderNo,
						orderAmount: ord.totalOrderAmount,
						customer: ord.customer ? ord.customer : new CustomerModel(),
						type: RecentOrderMapper.getOrderType(ord),
						orderStatus: ObjectHelper.ToFirstUpper(ord.orderStatus),
						orderItems: []
					};
				});
		} catch (error) {
			console.log(error);
		}
		return [];
	}

	/**
	 * Calls get quotation api to get getQuotation
	 *
	 *
	 * @param {String} quotNo [Quotation]
	 * @return {Object} Return Quotation
	 */
	async getQuotationLite(quotNo) {
		try {
			this.config = SalesCoworkerConfiguration.getUserConfig();
			var factory = new ShoppingCartItemFactory();
			var quotation = await QuotationWrapperService.viewQuotation(quotNo);
			var itemIds = [];
			quotation.items.forEach(itm => {
				itemIds.push(`${itm.itemType}${itm.itemNo}`);
				if (itm.itemType === "SPR" && itm.childItems) {
					itm.childItems.forEach(citm => {
						itemIds.push(`${citm.itemType}${citm.itemNo}`);
					});
				}
			});
			let productService = new services.ProductService(this.config);
			var retailItems = await productService.getRetailItemsByid(itemIds, false);
			var orderItems = quotation.items.map(itm => {
				var retailItem = retailItems.find(x => x.ItemId === itm.itemNo);

				var cartItem = factory.createShoppingCartItem(retailItem, itm.quantity);
				var childItems = [];
				if (itm.itemType === "SPR" && itm.childItems) {
					itm.childItems.forEach(citm => {
						var childRetailItem = retailItems.find(x => x.ItemId === citm.itemNo);
						var cartChildItem = factory.createShoppingCartItem(childRetailItem, citm.quantity);
						cartChildItem = OrderAmountCalculatorService.updateCartPriceForQuotation(cartChildItem, citm, quotation.currencyCode);
						childItems.push(cartChildItem);
					});
					cartItem.childItems = childItems;
				}
				return OrderAmountCalculatorService.updateCartPriceForQuotation(cartItem, itm, quotation.currencyCode);
			});
			orderItems = factory.reAssignCartItemId(orderItems);

			var result = {
				...quotation,
				orderItems: orderItems
			};
			return result;
		} catch (error) {
			console.log(error);
			return undefined;
		}
	}

	/**
	 * Calls get getCustomerOrders to list  fetch customer orders
	 *
	 * @param {String} customerId - customerid email or phoneno
	 * @return {Object} Return Quotation
	 */
	async getCustomerOrders(customerId) {
		try {
			this.config = SalesCoworkerConfiguration.getUserConfig();
			if (isGuestMode()) {
				AppLogger.warn("In Guest mode order retreival not supported", "getCustomerOrders");
				return [];
			}
			if (!canSearchCustomerorders(customerId, this.config.countryCode)) {
				return [];
			}
			var { countryCode } = SalesCoworkerConfiguration.getUserConfig();
			let baseUrl = SharedConfiguration.getApiBaseUrl("cartservices");
			var url = new URI(`${baseUrl}${APIEndpoints.customerOrdersUrl}`, true).fill({
				countryCode: countryCode,
				customerId: customerId
			});

			var response = await httpService.get(url, {
				headers: this.config.headers
			});

			if (response && response.orders) {
				return response.orders.map(ord => {
					return {
						...ord,
						id: ord.orderNo
					};
				});
			}
		} catch (error) {
			console.log(error);
			return [];
		}
	}
	/**
	 * parse customer data from the order
	 *
	 * @param orderResponse
	 *
	 * @return {Object} Returns {customer,customerBilling}
	 */
	async getCustomerFromOrder(orderno) {
		try {
			this.config = SalesCoworkerConfiguration.getUserConfig();
			let baseUrl = SharedConfiguration.getApiBaseUrl("cartservices");
			let url = new URI(`${baseUrl}${APIEndpoints.getOrder}`, true).fill({
				orderNo: orderno
			});
			var orderResponse = await httpService.get(url, {
				headers: this.config.headers
			});

			var customerData = RecentOrderMapper.mapCustomerOrder(orderResponse);
			return customerData;
		} catch (error) {
			return undefined;
		}
	}
	/**
	 * Calls get getCustomerWebShoppingList to list  fetch customer web shopping list
	 *
	 * @param {String} customerId - customerid email or phoneno
	 * @return {Object} Return Quotation
	 */
	async getCustomerWebShoppingList(customerId) {
		try {
			this.config = SalesCoworkerConfiguration.getUserConfig();
			if (isGuestMode()) {
				AppLogger.warn("In Guest mode order retreival not supported", "getCustomerWebShoppingList");
				return [];
			}
			if (!canSearchCustomerorders(customerId, this.config.countryCode)) {
				return [];
			}
			var { countryCode, buCode } = SalesCoworkerConfiguration.getUserConfig();
			let baseUrl = SharedConfiguration.getApiBaseUrl("cartservices");
			let url = new URI(`${baseUrl}${APIEndpoints.webshoppinglistUrl}`, true).fill({
				countryCode: countryCode,
				customerId: encodeURIComponent(customerId),
				buCode: buCode
			});

			var response = await httpService.get(url, {
				headers: this.config.headers
			});

			if (response && response.orders) {
				return response.orders.map(ord => {
					return {
						...ord,
						id: ord.orderNo
					};
				});
			}
		} catch (error) {
			console.log(error);
			return [];
		}
		//webshoppinglist
	}
}

const canSearchCustomerorders = (value, countryCode) => {
	return ValidationHelper.IsValidEmail(value) || ValidationHelper.IsValidPhoneNumber(value, countryCode);
};

const isGuestMode = () => {
	var config = SalesCoworkerConfiguration.getUserConfig();
	return config.guestMode;
};
export default OrderRetreivalService;
