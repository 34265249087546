import React from "react";

import { HideBodyScrollStyle } from "../../globalStyle";

import QuotationDialog from "./quotationDialog";

const QuotationPopupContext = React.createContext(null);

const useQuotationPopup = () => {
	const [config, setConfig] = React.useContext(QuotationPopupContext);

	const getQuotationModel = React.useCallback(
		(type, conf = {}) => {
			let resolver = null;

			const resolve = value => {
				setConfig(null);
				if (resolver) {
					resolver(value);
				}
			};

			const cancel = () => {
				setConfig(null);
				if (resolver) {
					resolver();
				}
			};

			setConfig({
				type,
				...conf,
				resolve,
				cancel,
				test: "test"
			});

			return new Promise(resolve => {
				resolver = resolve;
			});
		},
		[setConfig]
	);
	return { getQuotationModel, modelOpen: !!config };
};

const QuotationPopup = () => {
	const [input] = React.useContext(QuotationPopupContext);
	const { type = "", cancel, resolve, ...config } = input || {};

	const popupConf = types[type] || {};
	const Component = popupConf.component;

	return (
		<>
			{input ? (
				<>
					<HideBodyScrollStyle />
					<Component {...config} onClose={cancel} onSubmit={resolve} />
				</>
			) : (
				""
			)}
		</>
	);
};

const QuotationPopupProvider = ({ children }) => {
	const [input, setInput] = React.useState(null);

	return <QuotationPopupContext.Provider value={[input, setInput]}>{children}</QuotationPopupContext.Provider>;
};

export { useQuotationPopup, QuotationPopup, QuotationPopupProvider };

//popupcomponents should be refered here
const types = {
	quotationDialog: { component: QuotationDialog }
};
