import moment from "moment";
import { GUID, ShoppingCartItemFactory, httpService } from "@coworker/sharedlibrary";

import SalesCoworkerConfiguration from "../salesCoworkerConfiguration";
import {
	CLEAR_CART_SERVICES,
	CLEAR_EXTERNAL_REFERENCE,
	CONVERT_ORDER_TODRAFT,
	SET_BOOKED_SERVICE_PROPOSAL,
	SET_CART_CURRENCY,
	SET_CASH_LINE,
	SET_CREDIT_INFO,
	SET_CUSTOMER_INFORMATION,
	SET_CUSTOMER_MEETING_POINT,
	SET_KITCHEN_ITEMS_IN_ORDER,
	SET_ORDER_CREATION_METHOD,
	SET_ORDER_MODE,
	SET_ORDER_MODIFIED_STATE,
	SET_PAYMENT_TYPE,
	SET_SALES_EVENT_TOPIC,
	UPDATE_ALL_ITEMS,
	UPDATE_CREDIT_INFO
} from "./action-types/cart-actions";
import { setCartDiscounts, updateCartTotal } from "./cartActions";

export const setCartCurrency = currency => dispatch => {
	dispatch({
		type: SET_CART_CURRENCY,
		payload: {
			currency: currency
		}
	});
};

export const sethasKitchenItemsInOrder = kitchen => dispatch => {
	dispatch({
		type: SET_KITCHEN_ITEMS_IN_ORDER,
		payload: kitchen
	});
};

export const setOrderCreationMethod = orderMethod => dispatch => {
	dispatch({
		type: SET_ORDER_CREATION_METHOD,
		payload: { orderCreationMethod: orderMethod }
	});
};

export const setBusinessUnitSelector = customerPoint => dispatch => {
	dispatch({
		type: SET_CUSTOMER_MEETING_POINT,
		payload: { customerMeetingPoint: customerPoint }
	});
};

export const applyCartDiscounts = (discounts, callback) => dispatch => {
	dispatch(setCartDiscounts(discounts));
	dispatch(updateCartTotal()).then(cartTotalResposne => {
		callback &&
			callback({
				success: true,
				...cartTotalResposne
			});
	});
};

export const getvoucherCreditInfo = cardNumber => async (dispatch, getState) => {
	var state = getState();
	var { countryCode, buCode } = state.appController.coworkerData;
	const config = SalesCoworkerConfiguration.getUserConfig();
	var request = {
		buCode,
		cardNumber: cardNumber,
		countryCode: countryCode
	};
	var url = "api/card/voucher/credit-details";
	return httpService
		.post(url, {
			body: request,
			header: config.headers
		})
		.then(response => {
			return Promise.resolve(response);
		});
};

export const setPaymentType = paymentType => dispatch => {
	dispatch({
		type: SET_PAYMENT_TYPE,
		payload: { paymentType: paymentType }
	});
};

export const getCurrentCartDraftOrder = () => (dispatch, getState) => {
	var state = getState();
	var { items, id, serviceManager } = state.cartController;

	const { buCode } = state.appController;
	var draftOrder = {
		orderItems: items,
		serviceManager: serviceManager,
		id: id,
		isDraft: true,
		orderType: "DRAFT",
		orderStatus: "DRAFT",
		buCode: buCode,
		type: "DRAFT",
		orderCreationDate: moment().toISOString()
	};

	return Promise.resolve(draftOrder);
};

export const setOrderMode = orderMode => dispatch => {
	dispatch({
		type: SET_ORDER_MODE,
		payload: { orderMode: orderMode }
	});
};

export const setExternalClientReference = externalReference => dispatch => {
	dispatch({
		type: SET_SALES_EVENT_TOPIC,
		payload: { externalReference: externalReference }
	});
};

export const changeOrderToDraftOrder = () => dispatch => {
	dispatch({
		type: CONVERT_ORDER_TODRAFT,
		payload: true
	});
};

export const setServiceProposal = serviceproposal => {
	return dispatch => {
		dispatch({
			type: SET_BOOKED_SERVICE_PROPOSAL,
			payload: serviceproposal
		});
		return Promise.resolve();
	};
};

export const selectAllitemsInreceipt = () => (dispatch, getState) => {
	try {
		let sFactory = new ShoppingCartItemFactory();
		const state = getState();
		const { items } = state.cartController;
		let allItems = [...items];
		allItems.forEach(itm => {
			if (itm.allowedToDeliveryFromTransportDesk) sFactory.updateQuantity(itm, itm.importDetails.originalQuantity);
		});

		dispatch({
			type: UPDATE_ALL_ITEMS,
			payload: {
				cartItems: allItems
			}
		});
		dispatch(updateCartTotal());
		return Promise.resolve("OK");
	} catch (error) {
		return Promise.reject(error);
	}
};

export const clearCartServices = () => {
	return dispatch => {
		dispatch({
			type: CLEAR_CART_SERVICES,
			payload: ""
		});
	};
};

export const setCurrentCartItems = allItems => {
	return dispatch => {
		dispatch({
			type: UPDATE_ALL_ITEMS,
			payload: {
				cartItems: allItems
			}
		});
	};
};

export const createDraftOrder = items => (dispatch, getState) => {
	var state = getState();

	const { buCode } = state.appController;
	var draftOrder = {
		orderItems: items,
		id: GUID.Guid(),
		isDraft: true,
		orderType: "DRAFT",
		orderStatus: "DRAFT",
		buCode: buCode,
		type: "DRAFT",
		orderCreationDate: moment().toISOString()
	};

	return Promise.resolve(draftOrder);
};

export const setCustomerInformation = customerData => {
	return dispatch => {
		dispatch({
			type: SET_CUSTOMER_INFORMATION,
			payload: customerData
		});
		return Promise.resolve();
	};
};

export const setOrderModified = ({ modifiedTimeWindow, modifiedOrder }) => {
	return (dispatch, getState) => {
		let state = getState();
		let { order } = state.cartController;
		if (modifiedTimeWindow) {
			order = {
				...order,
				shouldUpdate: true,
				modifiedTimeWindow: modifiedTimeWindow
			};
		}
		if (modifiedOrder) {
			order = {
				...order,
				shouldUpdate: true,
				modifiedOrder: modifiedOrder
			};
		}
		dispatch({
			type: SET_ORDER_MODIFIED_STATE,
			payload: {
				order: order
			}
		});
		return Promise.resolve();
	};
};
export const setCreditInfo = credit => {
	return dispatch => {
		dispatch({
			type: SET_CREDIT_INFO,
			payload: {
				creditInfo: credit
			}
		});
		return Promise.resolve();
	};
};

export const updateCreditInfo = creditTransactions => {
	return dispatch => {
		dispatch({
			type: UPDATE_CREDIT_INFO,
			payload: {
				creditTransactions: creditTransactions
			}
		});
		return Promise.resolve();
	};
};
export const setCashLine = cashLine => {
	return (dispatch, getState) => {
		const state = getState();
		const { items } = state.cartController;
		let cartItems = [...items];
		cartItems.forEach(itm => {
			let handInLocation = cashLine ? `STORE_HANDIN|${cashLine.name}` : "STORE_HANDIN|Transport Desk";

			itm.isCashLine = true;
			itm.handOverLocation = handInLocation;
		});
		dispatch({
			type: SET_CASH_LINE,
			payload: {
				cashLine: cashLine
			}
		});

		dispatch({
			type: UPDATE_ALL_ITEMS,
			payload: {
				cartItems: cartItems
			}
		});

		return Promise.resolve();
	};
};

export const clearExternalReference = () => {
	return dispatch => {
		dispatch({
			type: CLEAR_EXTERNAL_REFERENCE
		});

		return Promise.resolve();
	};
};
