import AppLogger from "../../logger/appLogger";
import { useSelector } from "react-redux";
import React from "react";
import { getStoreConfigSelector } from "../../utils/shellObjectService";
import useMicrofrontend from "./../../hooks/useMicroFrontend";
import SalesCoworkerConfiguration from "../../store/salesCoworkerConfiguration";

const MicroSearchView = ({ location }) => {
	const config = getStoreConfigSelector();
	const { items } = useSelector(state => state.cartController);

	const { appId, appUrl, appName } = config.environment.microapps.pipApp;

	const { isLoaded, pip } = useMicrofrontend(appId, appUrl, appName);
	// 1104359950
	const escFunctionCallback = React.useCallback(e => {
		if (SalesCoworkerConfiguration.isExternal()) {
			if (e.keyCode === 27) {
				window.sendToNative({ componentName: "pip", action: "CANCEL" });
				AppLogger.info("PIP view closed on EscapeKey event", "CloseSearchView", {
					KeyInterept: "ESC",
					Success: 200
				});
			}
		}
	}, []);
	React.useEffect(() => {
		document.addEventListener("keydown", escFunctionCallback, false);
		return () => {
			document.removeEventListener("keydown", escFunctionCallback, false);
		};
	}, []);
	React.useEffect(() => {
		pip && pip.setCartItems(items);
	}, [items]);

	React.useEffect(() => {
		if (!pip) return;
		config.view = "search";
		config.location = location;
		pip.SetSharedConfiguration(config);
		const { render, unMount } = pip;

		render(`search-${appId}`, config);

		return () => {
			unMount(`search-${appId}`);
		};
	}, [isLoaded]); // eslint-disable-line

	if (!isLoaded) return null;
	if (!pip) return <div />;

	return <div id={`search-${appId}`} />;
};

export default MicroSearchView;
