import React from "react";
import { GaHelper, dispatchEvent, eventsToDispatch, useMicrofrontend, useWindowEventListener } from "@coworker/sharedlibrary";
import { goBack, push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";

import PubSubService from "./../../service/pubSubService";
import SalesCoworkerConfiguration from "../../store/salesCoworkerConfiguration";
import shellEventParser from "../../utils/shellEventParser";
import { UPDATE_ALL_ITEMS } from "../../store/cart/action-types/cart-actions";
import { actions } from "../../store/locale";
import {
	addItemToCart,
	addKitchenPlannerToCart,
	addPlannerToCart,
	addPtagToCart,
	changeQuantity,
	importCustomerShoppingList,
	importReceiptList,
	updateCartTotal,
	updateFullItem
} from "../../store/cart/cartActions";
import { appactionCreators } from "./../../store/shell/shellAction";
import {
	clearCartServices,
	createDraftOrder,
	setBusinessUnitSelector,
	setCurrentCartItems,
	setCustomerInformation,
	setOrderCreationMethod,
	setOrderModified,
	setPaymentType,
	setServiceProposal
} from "./../../store/cart/cart.thunks";
import {
	getCustomerProfileObject,
	getOrderServiceInstaance,
	getOrderServicesObject,
	getRecentOrderServiceInstance,
	getStoreConfigSelector
} from "./../../utils/shellObjectService";
import { getOrderAmountSummarySelector } from "../../store/cart/cartSelectors";
import { setOrderMode } from "../../store/cart/cart.thunks";
import { updateCartItemStock } from "./../../store/cart/cartActions";
import { useCartModelPopup } from "../../containers/cart/components/modelPopup";
import { useInputPopup } from "../inputPopup";
import { useModelPopup } from "../modelPopup";
import { DfpKey } from "@coworker/sharedlibrary";

const ShellEventReceiver = () => {
	const dispatch = useDispatch();
	const { messages } = useSelector(state => state.intl);
	const { callingApplication } = useSelector(state => state.appController);
	const { getModel } = useModelPopup();
	const { getInput } = useInputPopup();
	let config = getStoreConfigSelector();
	const { getCartModel } = useCartModelPopup();
	const { appId, appUrl, appName } = config.environment.microapps.pipApp;

	const { pip } = useMicrofrontend(appId, appUrl, appName);
	const handleAddItem = async e => {
		config = getStoreConfigSelector();

		let store = SalesCoworkerConfiguration.getStore();
		const state = store.getState();
		let cart = state.cartController;
		let { items, isModifyOrderinProgress, selectedCashLine } = cart;
		if (config.isCashLine) {
			if (e.detail.retailItem && e.detail.retailItem.ItemType === "ART") {
				dispatch(setOrderMode("TRANSPORTDESK"));

				if (!selectedCashLine) {
					await getCartModel("selectCashLinePopup");
				}
				dispatch(addItemToCart(e.detail.retailItem, e.detail.qty, config.isCashLine)).then(() => {
					dispatch(updateFullItem(e.detail.retailItem));
				});
				return;
			}
			dispatch(setOrderMode("TRANSPORTDESK"));
			await getCartModel("notAllowedToAddItemsDialog");
			return;
		} else {
			if (e.detail.retailItem) {
				if (SalesCoworkerConfiguration.isExternal()) {
					try {
						let item = e.detail.retailItem;
						window.sendToNative({
							componentName: "search",
							data: {
								name: "search",
								success: true,
								message: "success",
								item: {
									itemId: item.ItemId,
									itemName: item.Name,
									itemType: item.ItemType,
									quantity: 1,
									gprId: item.gprId
								}
							}
						});
					} catch (error) {
						//
					}
				}

				dispatch(addItemToCart(e.detail.retailItem, e.detail.qty, false)).then(() => {
					dispatch(updateFullItem(e.detail.retailItem));
				});
			}

			let isImportOrder = e.detail.isImportOrder;
			if (isImportOrder) {
				let { importData } = e.detail;
				if (importData.isTransportDeskMode) {
					let hasDraftItemsinCart = items && items.length !== 0 && items.filter(x => x.importDetails === undefined).length > 0;
					if (hasDraftItemsinCart) {
						await getCartModel("newShoppingBag");
					}

					let anyOrderItems = items.filter(
						x => x.importDetails && x.importDetails.orderReferenceNo && x.importDetails.orderReferenceNo.toString() === e.detail.orderNo.toString()
					);

					if (anyOrderItems.length === importData.shoppingListItems.length) {
						//TODO : add check if already order is imported alert user
						await getModel("confirmDialog", {
							title: messages.SG_LBL_AlreadyImported || "Order Imported Already",
							text: messages.SG_LBL_OrderImportedAlready || "Order is already imported",
							showOnlyYes: true
						});
					} else {
						let checkExistingOrderItems = items.filter(
							x =>
								x.importDetails &&
								x.importDetails.orderReferenceNo &&
								x.importDetails.orderReferenceNo.toString() === e.detail.orderNo.toString()
						);
						if (checkExistingOrderItems && checkExistingOrderItems.length > 0) {
							checkExistingOrderItems.forEach(itm => {
								importData.shoppingListItems = importData.shoppingListItems.filter(
									x => x.importDetails.originalLineId !== itm.importDetails.originalLineId
								);
							});
						}
						dispatch(importReceiptList(importData.shoppingListItems));
						dispatch(goBack());
						setTimeout(() => {
							dispatch(setOrderMode("TRANSPORTDESK"));
							dispatch(push("/cart"));
							config = getStoreConfigSelector();
							pip && pip.SetSharedConfiguration(config);
						}, 200);
					}
				} else if (importData.isShoppingListMode) {
					dispatch(goBack());
					let importItems = true;
					if (isModifyOrderinProgress) {
						importItems = await getCartModel("newShoppingBag");
					}
					if (!importItems) {
						return;
					}
					dispatch(importCustomerShoppingList(importData.shoppingListItems));

					setTimeout(() => {
						dispatch(setOrderMode("SALESMODE"));
						dispatch(push("/cart"));
					}, 100);
				}
				if (importData.associatedCustomer && !isModifyOrderinProgress) {
					let addCustomer = true;
					let { customer } = getCustomerProfileObject();
					if (customer && customer.zipCode) {
						let result = await getModel("confirmDialog", {
							title: messages.SG_LBL_OverrideCustomerInfo || "Override  Customer",
							text: messages.SG_LBL_OverrideCustomerReceiptMessage || "Would you like to use the customer from the receipt?"
						});
						addCustomer = result;
					}
					if (addCustomer) {
						dispatchEvent("SET_ASSOCIATED_CUSTOMER", {
							associatedCustomer: importData.associatedCustomer
						});
					}
				}
			}
			let isReceipt = e.detail.isReceipt;
			if (isReceipt) {
				let hasDraftItemsinCart = items && items.length !== 0 && items.filter(x => x.importDetails === undefined).length > 0;
				if (hasDraftItemsinCart) {
					await getCartModel("newShoppingBag");
				}

				if (e.detail.combination.associatedCustomer && !isModifyOrderinProgress) {
					let addCustomer = true;
					let { customer } = getCustomerProfileObject();
					if (customer && customer.zipCode) {
						let result = await getModel("confirmDialog", {
							title: messages.SG_LBL_OverrideCustomerInfo || "Override  Customer",
							text: messages.SG_LBL_OverrideCustomerReceiptMessage || "Would you like to use the customer from the receipt?"
						});
						addCustomer = result;
					}
					if (addCustomer) {
						dispatchEvent("SET_ASSOCIATED_CUSTOMER", {
							associatedCustomer: e.detail.combination.associatedCustomer
						});
					}
				}
				dispatch(importReceiptList(e.detail.combination.shoppingListItems));
				setTimeout(() => {
					dispatch(setOrderMode("TRANSPORTDESK"));
					dispatch(push("/cart"));
				}, 500);
			}

			let isWebShoppingList = e.detail.isWebShoppingList;
			if (isWebShoppingList) {
				dispatch(importCustomerShoppingList(e.detail.combination.shoppingListItems)).then(() => {
					PubSubService.publish("add-to-cart", e.detail.combination.userId);
				});
			}

			let isKitchen = e.detail.isKitchen;
			if (isKitchen) {
				dispatch(addKitchenPlannerToCart(e.detail.combination, e.detail.qty));

				if (e.detail.combination.associatedCustomer && !isModifyOrderinProgress) {
					let addCustomer = true;
					let { customer } = getCustomerProfileObject();
					if (customer && customer.zipCode) {
						let result = await getModel("confirmDialog", {
							title: messages.SG_LBL_OverrideCustomerInfo || "Override  Customer",
							text: messages.SG_LBL_OverrideCustomerMessage || "Would you like to use the customer from the planner?"
						});
						addCustomer = result;
					}
					if (addCustomer) {
						dispatchEvent("SET_ASSOCIATED_CUSTOMER", {
							associatedCustomer: e.detail.combination.associatedCustomer
						});
					}
				}
			}

			let isPlanner = e.detail.isPlanner;
			if (isPlanner) {
				console.log(e.detail.combination);
				dispatch(addPlannerToCart(e.detail.combination, e.detail.qty));
			}

			let isPtag = e.detail.isPtag;
			if (isPtag) dispatch(addPtagToCart(e.detail.combination, e.detail.qty));
			config = getStoreConfigSelector();
			pip && pip.SetSharedConfiguration(config);
		}
	};
	const handleUpdateItemQuantity = e => {
		if (e.detail.combination) {
			dispatch(changeQuantity(e.detail.combination.ItemId, e.detail.qty));
		} else if (e.detail.retailItem) {
			if (SalesCoworkerConfiguration.isExternal()) {
				try {
					let item = e.detail.retailItem;
					window.sendToNative({
						componentName: "search",
						data: {
							name: "search",
							success: true,
							message: "success",
							item: {
								itemId: item.ItemId,
								itemName: item.Name,
								itemType: item.ItemType,
								quantity: e.detail.qty,
								gprId: item.gprId
							}
						}
					});
				} catch (error) {
					//
				}
			}
			dispatch(changeQuantity(e.detail.retailItem.ItemId, e.detail.qty));
		}
	};
	const navigate = async e => {
		let eventDetail = e.detail;

		if (eventDetail.view === "DETAIL" && eventDetail.itemId) {
			if (SalesCoworkerConfiguration.isExternal()) {
				dispatch(push(`/external/detail/${eventDetail.itemType}${eventDetail.itemId}`));
			} else {
				dispatch(push(`/detail/${eventDetail.itemType}${eventDetail.itemId}`));
			}
		}
		if (eventDetail.view === "BROWSE" && eventDetail.category) {
			if (pip) {
				pip.unMount && pip.unMount(`browse-${appId}`);
			}
			dispatch(push(`/browse/${eventDetail.category.Id}`, eventDetail.category));
		}
		if (eventDetail.view === "RECEIPT") {
			dispatch(
				push(`/receipt/${eventDetail.id}`, {
					receipt: eventDetail.receipt
				})
			);
		}
		if (eventDetail.view === "VIEW_SALES_LOCATION") {
			await getModel("stockAndLocationDialog", {
				retailItem: eventDetail.retailItem,
				isStock: eventDetail.isStock
			});
		}
		if (eventDetail.view === "VIEW_ITEM_DELIVERY") {
			await getInput("orderService", {
				viewState: "ITEM_DELIVERY_VIEW",
				view: "ITEM_SERVICE_VIEW",
				item: eventDetail.cartItem
			});
		}
		if (eventDetail.view === "VIEW_ITEM_SERVICE") {
			await getInput("orderService", {
				viewState: "ITEM_SERVICE_VIEW",
				view: "ITEM_SERVICE_VIEW",
				item: eventDetail.cartItem
			});
		}
		if (eventDetail.view === "SERIES") {
			dispatch(
				push("/search/" + eventDetail.seriesId, {
					query: eventDetail.seriesId,
					useLocalSearch: false,
					useCategorySearch: true
				})
			);
		}
	};
	const setCartItemsEvent = event => {
		let cartItems = event.detail.cartItems;
		dispatch(setCurrentCartItems(cartItems));
		dispatch(updateCartItemStock());
	};
	const setProposalEvent = () => {
		config = getStoreConfigSelector();

		let bookedServices = getOrderServicesObject();
		let { customer } = getCustomerProfileObject();

		if (bookedServices) {
			dispatch(setServiceProposal(bookedServices));
			dispatch(updateCartTotal()).then(() => {
				let store = SalesCoworkerConfiguration.getStore();
				const state = store.getState();
				const { isTransportDeskMode } = state.cartController;
				const { servicePrice, totalPrice } = getOrderAmountSummarySelector(state);
				let amount = 0;
				if (isTransportDeskMode && !config.isCashLine) {
					amount = servicePrice.totalServicePrice.Value;
				} else {
					amount = totalPrice.Value;
				}
				if (customer && customer.isInvoiceCustomer && config.environment.invoiceCustomerPaymentEnabled && amount > 0) {
					dispatch(setPaymentType("PAYMENT_IN_INVOICE"));
				}
			});
		}
	};

	const setUpdatedCartItemsEvent = event => {
		let orderServices = getOrderServiceInstaance();
		if (orderServices) {
			let { newItems, unAvailableCartItems } = event.detail;
			dispatch({
				type: UPDATE_ALL_ITEMS,
				payload: {
					cartItems: newItems
				}
			});
			if (unAvailableCartItems && unAvailableCartItems.length > 0) {
				dispatch(createDraftOrder(unAvailableCartItems)).then(draftOrder => {
					let recentOrder = getRecentOrderServiceInstance();
					recentOrder && recentOrder.saveCurrentCarttoDraftOrder(draftOrder);
				});
			}
			orderServices.setCartItems(newItems);
		}
	};
	function disablekeyHandler(event) {
		let store = SalesCoworkerConfiguration.getStore();
		const state = store.getState();
		let cart = state.cartController;
		let app = state.appController;
		let { isTransportDeskMode } = cart;
		let {
			coworkerData = {
				isTransportDeskUser: false
			}
		} = app;
		let { isTransportDeskUser } = coworkerData;
		if (isTransportDeskMode || isTransportDeskUser) {
			switch (event.keyCode) {
				case 116: // 'F5'
				case 122:
					event.preventDefault();
					window.status = "F5 disabled";
					break;
			}
		}
	}

	//Customer MFE Event
	function handleCustomerCloseAction() {
		dispatch(clearCartServices());
	}

	const undefinedReplacer = (key, value) => (value === "undefined" ? null : value);

	// eslint-disable-next-line no-unused-vars
	const logToNative = event => {
		const message = JSON.stringify(event.detail.logData, undefinedReplacer) + event.detail.message;
		const level = event.detail.level;

		if (callingApplication !== "ISELLCLIENT") return;

		const hostLoggingPreference = SalesCoworkerConfiguration.getPreference(DfpKey.ENABLE_WEBVIEWS_LOGGING_TO_ISELL);
		const isHostLoggingEnabled = hostLoggingPreference !== undefined && hostLoggingPreference.value === "true";

		if (!isHostLoggingEnabled) return;
		const isellMessage = {
			action: "LOGGING",
			loggingLevel: level,
			loggingMessage: message
		};
		window.chrome.webview.postMessage(JSON.stringify(isellMessage));
	};

	function handleOnSaveCustomerInfo() {
		//set cart customerInformation to ensure cartview recieve latest customer info
		let customerData = getCustomerProfileObject();
		dispatch(setCustomerInformation(customerData));
	}
	const setOrderMofifiedState = event => {
		let bookedServices = getOrderServicesObject();
		if (bookedServices) {
			dispatch(setOrderModified(event.detail));
		}
	};
	const handleRebookService = async () => {
		await getInput("orderService", {
			viewState: "BEFOREPROPOSAL",
			view: "SERVICE_VIEW",
			showScroll: false
		});
	};

	const handleUserProfileAttributes = async event => {
		if (event.detail.logoutUser) {
			await getModel("userLogoutDialog");
		}
		if (event.detail.locale) {
			dispatch(actions.changeLocale(event.detail.locale));
			dispatch(appactionCreators.setUserLocale(event.detail.locale));
			dispatch(actions.getTranslations(event.detail.locale));
		}
		if (event.detail.selectedHfbList) {
			dispatch(appactionCreators.changeSelectedHfb(event.detail.selectedHfbList));
		}
		if (event.detail.cusMeetingPoint) {
			dispatch(setBusinessUnitSelector(event.detail.cusMeetingPoint));
		}
		if (event.detail.orderCreationMethod) {
			dispatch(setOrderCreationMethod(event.detail.orderCreationMethod));
		}
		if (event.detail.coworkerWorkArea) {
			dispatch(appactionCreators.setCoworkerWorkAreaHfb(event.detail.coworkerWorkArea));
		}

		if (event.detail.newStore) {
			let newStore = event.detail.newStore;
			dispatch(appactionCreators.changeUserStore(newStore.No))
				.then(() => {
					dispatch(actions.changeLocale(newStore.PrimaryLocale));
					dispatch(appactionCreators.setUserLocale(newStore.PrimaryLocale));
					dispatch(actions.getTranslations(newStore.PrimaryLocale));
					GaHelper.SendEvent("Store", "ChangeStore", `User changed store to ${newStore.No}`);
					event.detail.onSuccess && event.detail.onSuccess();
				})
				.catch(error => {
					console.error(error);
					dispatch(appactionCreators.showNotification("error", "Failed to switch store"));
				});
		}
	};
	const handleNotification = e => {
		let { notification } = e.detail;
		dispatch(appactionCreators.showNotification(notification.type, notification.title, notification.message || ""));
	};

	useWindowEventListener("HOST_LOG", e => {
		let logData = e.detail;
		shellEventParser("LOG", logData);
	});
	useWindowEventListener("SET_CART_ITEMS", setCartItemsEvent);
	useWindowEventListener("ADD_ITEM_TO_CART", handleAddItem);
	useWindowEventListener("UPDATE_ITEM_QUANTITY", handleUpdateItemQuantity);
	useWindowEventListener("NAVIGATE", navigate);
	useWindowEventListener("ON_SERVICE_PROPOSAL_UPDATED", setProposalEvent);
	useWindowEventListener("UPDATE_ORDER_MODIFIED_STATE", setOrderMofifiedState);
	useWindowEventListener(eventsToDispatch.ON_CLOSE_CUSTOMER_BANNER, handleCustomerCloseAction);
	useWindowEventListener("LOG_TO_NATIVE", logToNative);
	useWindowEventListener(eventsToDispatch.UPDATE_CART_ITEMS, setUpdatedCartItemsEvent);
	useWindowEventListener(eventsToDispatch.ON_SAVE_CUSTOMER, handleOnSaveCustomerInfo);
	useWindowEventListener(eventsToDispatch.REBOOK_SERVICE, handleRebookService);

	useWindowEventListener(eventsToDispatch.USER_PROFILE_ATTRIBUTE_CHANGE, handleUserProfileAttributes);
	useWindowEventListener("SHOW_HOST_NOTIFICATION", handleNotification);
	React.useEffect(() => {
		document.addEventListener("keydown", disablekeyHandler);
		return () => document.removeEventListener("keydown", disablekeyHandler);
	}, []);
	return <div></div>;
};
export default ShellEventReceiver;
