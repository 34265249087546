import { APIEndpoints, SharedConfiguration } from "@coworker/sharedlibrary";

import { Specifications, isomItemsBuilder, httpService } from "@coworker/sharedlibrary";

import { ValidationHelper, ObjectHelper, SalesItemTypes, RequestHelper } from "@coworker/sharedlibrary";
import SalesCoworkerConfiguration from "../../store/salesCoworkerConfiguration";
import { URI } from "@coworker/sharedlibrary";
import { mapQuotationToOrder, mapQuotations } from "./quotationToOrderMapper";
import moment from "moment";
import { getDeliveryArrangementsForIQuote, getProvidedServicesForIQuote } from "../cart/isomOrderCreationService";
const FAMILY_TYPE = "Family";
const PRIVATE_TYPE = "Private";
const CLIENT_SYSTEM = "Salja";
const CHANNEL = "store";

const createQuotation = async (quotation, businessArea, items, customerdata, salesPriceData, orderServices = []) => {
	let { headers } = SalesCoworkerConfiguration.getUserConfig();
	let createUrl = APIEndpoints.createQuotation;
	let request = createQuotationBuilder(quotation, businessArea, items, customerdata, salesPriceData, orderServices);

	let options = {
		"content-type": "application/json",
		timezone: quotation.timezoneId,
		...headers
	};
	let createquotationdetails = await httpService.post(createUrl, {
		body: request,
		headers: options
	});
	return createquotationdetails;
};

const deleteQuotation = async quotationNo => {
	let { storeInfo, headers } = SalesCoworkerConfiguration.getUserConfig();
	let { preferred_username } = RequestHelper.getLoggedInUser();
	let baseUrl = SharedConfiguration.getApiBaseUrl("cartservices");
	let url = new URI(`${baseUrl}${APIEndpoints.cancelQuotation}`, true).fill({
		quoteNo: quotationNo
	});
	let option = {
		headers: {
			timezone: storeInfo.TimeZone,
			"x-user-id": preferred_username,
			"content-type": "application/json",
			...headers
		}
	};
	let response = await httpService.post(url, {
		body: {},
		headers: option.headers
	});

	return response;
};

const viewQuotation = async quotationNo => {
	let { storeInfo, headers } = SalesCoworkerConfiguration.getUserConfig();
	let view = APIEndpoints.viewQuotation;
	let url = new URI(view, true).fill({ quoteNo: quotationNo });
	let option = {
		timezone: storeInfo.TimeZone,
		"content-type": "application/json",
		...headers
	};

	let viewquotationdetails = await httpService.get(url, {
		headers: option
	});
	if (viewquotationdetails && !viewquotationdetails.error) {
		return mapQuotationToOrder(viewquotationdetails);
	}
	return undefined;
};

const searchQuotation = async query => {
	let { storeInfo, buCode, storeCurrency, countryCode, headers } = SalesCoworkerConfiguration.getUserConfig();
	let view = APIEndpoints.searchQuotation;
	let url = new URI(view, true).fill({ query: query });
	let option = {
		timezone: storeInfo.TimeZone,
		"content-type": "application/json",
		...headers
	};
	if (!canSearchQuot(query, countryCode)) {
		return [];
	}

	let quotations = await httpService.get(url, { headers: option });

	if (quotations && quotations.length > 0) {
		quotations = quotations.filter(x => x.statusCode !== "Completed");
	}
	return mapQuotations(buCode, storeCurrency, quotations);
};

const updateQuotation = async (quotNo, request) => {
	let { storeInfo, headers } = SalesCoworkerConfiguration.getUserConfig();
	let url = APIEndpoints.updateQuotation;
	let option = {
		timezone: storeInfo.TimeZone,
		"content-type": "application/json",
		...headers
	};
	let updateRequest = {
		quotationNo: quotNo,
		quotation: request.quotation
	};
	let result = await httpService.post(url, {
		body: updateRequest,
		headers: option
	});
	return result;
};

const getCusomerProjects = async partyId => {
	let baseUrl = SharedConfiguration.getApiBaseUrl("cartservices");
	let url = new URI(`${baseUrl}${APIEndpoints.getQuotationProjects}`, true).fill({ partyUID: partyId });

	let { storeInfo, headers } = SalesCoworkerConfiguration.getUserConfig();
	let option = {
		timezone: storeInfo.TimeZone,
		"content-type": "application/json",
		...headers
	};
	let response = await httpService.get(url, { headers: option });
	let result = response.projects;
	return result;
};

const updateQuoteItems = (quoteNo, items, orderServices, orderSummaries) => {
	const { deliveryServices } = orderSummaries;
	let services = orderServices && orderServices.length > 0 ? mapServices(orderServices, deliveryServices) : [];
	let request = {
		quotation: {
			items: mapItems(items),
			services: services
		}
	};
	return updateQuotation(quoteNo, request);
};

const QuotationWrapperService = {
	createQuotation,
	deleteQuotation,
	viewQuotation,

	searchQuotation,
	updateQuotation,
	getCusomerProjects,
	updateQuoteItems
};

export default QuotationWrapperService;

const canSearchQuot = (value, countryCode) => {
	return Specifications.isQuotationSpec(value) || ValidationHelper.IsValidEmail(value) || ValidationHelper.IsValidPhoneNumber(value, countryCode);
};
const createQuotationBuilder = (quotation, businessArea, items, customerData, salesPriceData, orderServices) => {
	try {
		let { customer, customerBilling } = customerData;

		let isFamily = customer.ikeaFamilyNumber || false;

		let allItems = isomItemsBuilder.createItems(items);

		let quoteItems = mapItems(allItems);

		let type = isFamily ? FAMILY_TYPE : PRIVATE_TYPE;

		let { project } = quotation;
		const { orderSummary, orderSubtotal } = salesPriceData;
		let services = [];
		let deliveryArrangements = getDeliveryArrangementsForIQuote(
			orderServices.filter(x => x.isCentralDelivery),
			allItems,
			salesPriceData,
			isFamily
		);
		let providedServices = getProvidedServicesForIQuote(
			salesPriceData,
			orderServices.filter(x => x.isProvidedService),
			allItems,
			customer.prefferedlocale,
			isFamily
		);
		const { summary } = orderSummary;
		let orderSubTotalPrice = orderSubtotal.find(x => x.viewType === type);
		let orderSummaryPrice = summary.find(x => x.viewType === type);
		let request = {
			partyUID: customer.partyId,
			quotation: {
				projectRefNo: (project && project.projectRefNo) || "",
				quotationName: quotation.name,
				quotationDescription: quotation.description,
				quantity: 1,
				businessUnit: {
					buCode: businessArea.buCode,
					buType: "STO",
					ctyCode: businessArea.countryCode
				},
				clientSystem: CLIENT_SYSTEM,
				salesChannel: CHANNEL,
				quotationSchedule: {
					startDate: quotation.startDate,
					endDate: quotation.endDate
				},

				isBothAddressSame: customer.isBothAddressSame,
				languageCode: customer.language,
				currencyCode: salesPriceData.currencyCode,
				customerInfo: {
					contextType: customerBilling.addressContextType,
					customerType: 1,
					firstName: customer.firstName,
					lastName: customer.lastName
				},
				shippingAddress: {
					postalCode: customer.zipCode,
					contextType: customer.addressContextType
				},
				items: quoteItems,
				services: services,
				deliveryArrangements: deliveryArrangements,
				providedServices: providedServices,
				quotationSummary: {
					quotationSubTotal: orderSubTotalPrice,
					quotationTotal: {
						summary: {
							...orderSummaryPrice,
							savings: {
								promotions: 0
							}
						}
					}
				}
			}
		};
		if (project && !project.projectRefNo) {
			request.project = {
				projectName: project.projectName,
				projectDescription: project.projectDescription,
				projectSchedule: project.projectSchedule,

				businessUnit: {
					buCode: businessArea.buCode,
					buType: "STO",
					ctyCode: businessArea.countryCode
				}
			};
		}
		return request;
	} catch (error) {
		console.log(error);
	}
};

const mapItems = allItems => {
	let totalItems = [];

	let mappedItems = allItems;
	totalItems = mappedItems.map(itm => {
		return {
			lineId: itm.id,
			itemNo: itm.itemNo,
			itemName: itm.itemName,
			itemType: itm.itemType,
			quantity: itm.orderedQuantity,
			unitPrice: itm.unitPrice,
			subtotals: itm.subtotals,
			itemDescription: itm.itemDescription,
			itemLineReferences: itm.itemType === SalesItemTypes.SPR ? itm.itemReferences.map(x => x.lineId) : undefined,
			itemImages: itm.itemImages
		};
	});
	return totalItems;
};

const mapServices = (services, servicesPrices, isFamily) => {
	let orderServices = [];
	let lineId = 1;
	let solutionId = 1;
	let type = isFamily ? "Family" : "Private";
	services.forEach(srv => {
		if (srv.isCentralDelivery) {
			srv.subDeliveries.forEach(subDel => {
				let refDeivery = servicesPrices.find(srPrice => srPrice.deliveryMethod === subDel.serviceItemId);
				let delPrices = refDeivery && refDeivery.deliveryPrice;
				let deliveryPrice = delPrices.find(x => x.viewtype === type);
				let timeWindow = subDel.timeWindows && subDel.timeWindows[0];
				let refItems = [];
				if (!ObjectHelper.isNil(subDel.referenceItems)) {
					subDel.referenceItems.forEach(refitem => {
						refItems.push(refitem.serviceReference.lineId);
					});
				}
				orderServices.push({
					lineId: lineId,
					serviceNumber: subDel.serviceItemId,
					serviceName: subDel.serviceItemId,
					serviceType: "DELIVERY",
					salesOrderRefNo: null,
					serviceSchedule: {
						startDate: moment(timeWindow.fromDateTime).format("YYYY-MM-DDTHH:mm:ss"),
						endDate: moment(timeWindow.toDateTime).format("YYYY-MM-DDTHH:mm:ss")
					},
					servicePrice: deliveryPrice,
					itemLineReferences: refItems,
					solutionReference: solutionId
				});
				lineId++;
			});
			solutionId++;
		}
	});
	return orderServices;
};
