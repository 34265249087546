import Enumerable from "linq";

class IsomServiceInfoModel {
	constructor(solution, deliveryArrangementsId, solutionId, selectedCartItems, zipCode, subDeliveries, serviceTypeId) {
		for (var key in solution) {
			if (Object.prototype.hasOwnProperty.call(solution, key)) {
				const element = solution[key];
				this[key] = element;
			}
		}
		let group = `ISOM-${serviceTypeId}`;
		this.service = solution.service;
		this.isCentralDelivery = true;
		this.isSelected = false;
		this.solutionId = solutionId;
		this.id = `${group}-${solution.service}`;
		this.serviceName = ToFirstUpper(solution.service);
		this.hasLocker = this.id.includes("LOCKER");
		this.deliveryArrangementsId = deliveryArrangementsId;
		this.deliveryType = "ISOM";
		this.zipCode = zipCode;
		this.showInputView = false;
		this.subDeliveries = subDeliveries;
		this.hasNoStockDelivery = subDeliveries && subDeliveries.filter(x => x.serviceItemId === "SGR30000937").length > 0;
		this.selectedCartItems = selectedCartItems;
		this.serviceTypeId = serviceTypeId;
		this.group = group;
		this.serviceidentifier = solution.service;
		this.isRangeOfDays = false;

		try {
			this.hasUnavailableItems =
				solution.unavailableLines !== undefined &&
				solution.unavailableLines.unavailableLine !== undefined &&
				solution.unavailableLines.unavailableLine.length > 0;
			this.unavailableItems = this.hasUnavailableItems
				? Enumerable.from(solution.unavailableLines.unavailableLine)
						.select(function (itm) {
							let selectedItem = selectedCartItems.find(x => x.id.toString() === itm.itemLineId);
							return {
								...selectedItem,
								unavailableQty: parseInt(itm.unavailableQty),
								availableQuantity: parseInt(selectedItem.quantity) - parseInt(itm.unavailableQty),
								unavailableReasonCode: itm.unavailableReasonCode
							};
						})
						.toArray()
				: [];

			this.unAvailableCount = this.unavailableItems.length;
			this.totalItemCount = this.selectedCartItems.length;
			this.availableCount = this.totalItemCount - this.unAvailableCount;
		} catch (error) {
			console.error(error);
		}
		if (this.subDeliveries && this.subDeliveries.length > 0 && this.subDeliveries[0].pickupPoints && this.subDeliveries[0].pickupPoints.length > 0) {
			this.hasPickUpPoints = true;
			this.pickupPoints = this.subDeliveries[0].pickupPoints[0];
			this.allPickupPoints = Enumerable.from(this.subDeliveries)
				.selectMany(x => x.pickupPoints)
				.toArray();

			this.id = `${group}-${solution.service}${this.pickupPoints.identifier}`;
		}

		if (this.subDeliveries && this.subDeliveries.length > 0) {
			this.isRangeOfDays = Enumerable.from(this.subDeliveries)
				.where(x => x.isRangeOfDays === true)
				.any();
		}
	}
}
const ToFirstUpper = data => {
	return data.charAt(0).toUpperCase() + data.toLowerCase().slice(1);
};
export default IsomServiceInfoModel;
