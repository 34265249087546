import InputField from "@ingka/input-field";
import React, { useState } from "react";
import styled from "styled-components";
import { Container, Label } from "./../../../../components/sharedlibrary";
import FormField from "@ingka/form-field";

const InputContainer = styled(InputField)`
	label {
		font-size: 14px !important;
	}
	padding: 0px 0px;
`;

const EmployeeDiscountView = ({ setEmployeeDiscount, messages, employeeDiscount, setShowDiscount }) => {
	const [validation, setValidation] = useState({ msg: "" });

	const empProp = {
		req: "*",
		type: "tel",
		autoComplete: "off",
		id: "empPropName",
		defaultValue: employeeDiscount.value.toString(),
		label: messages.SG_LBL_EmployeeNumber + " *" || "Employee Number *",
		onBlur: e => {
			setShowDiscount && setShowDiscount(true);
			setValidation({
				msg: ""
			});
			if (isNaN(e.target.value) || e.target.value.length < 8) {
				setValidation({
					type: "error",
					msg: messages.SG_LBL_InvalidEmployeeNumber || "Not a valid employee number"
				});
				setEmployeeDiscount({
					...employeeDiscount,
					value: 0
				});
				return;
			}
			setEmployeeDiscount({
				...employeeDiscount,
				value: parseInt(e.target.value)
			});
		},
		onFocus: () => {
			setShowDiscount && setShowDiscount(false);
		}
	};
	return (
		<Container fdCol>
			<Label fontsize={"12px"}>{messages.SG_LBL_RequiresEmployeeNumber || "Requires employee number"}</Label>

			<FormField shouldValidate={true} validation={validation}>
				<InputContainer {...empProp}></InputContainer>
			</FormField>
		</Container>
	);
};
export default EmployeeDiscountView;
