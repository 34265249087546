import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import moment from "moment";

import { colors } from "@coworker/sharedlibrary";

import { updateQuotation } from "./../../store/cart/cartActions";

import { useQuotationPopup } from ".";

import { ReactComponent as EditIcon } from "../../static/edit.svg";

const QuotationContainer = styled.section`
	min-height: 100px;
	display: flex;
	flex-direction: column;
	padding: 16px 0px;
	border-bottom: 1px solid ${colors.bordergray};
`;

const QuotationHeaderSection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const SummaryTitle = styled.p`
	color: ${colors.grey900};
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	line-height: 1.71;
	margin-bottom: 0px;
`;

const InfoSection = styled.section`
	color: ${colors.grey700};
	flex-basis: 100%;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	line-height: 1.71;

	p {
		margin: 0 !important;
	}
`;

const Logo = styled.div`
	width: 40px;
	height: 40px;
	display: flex;
	align-self: flex-start;
	justify-content: center;
	cursor: pointer;

	svg {
		width: 16px;
		height: 16px;
	}

	path {
		fill: ${props => (props.disabled ? colors.grey150 : "")};
	}
`;

const QuotationSummary = () => {
	const dispatch = useDispatch();
	const { getQuotationModel } = useQuotationPopup();

	const { order } = useSelector(state => state.cartController);
	const { messages } = useSelector(state => state.intl);

	const { endDate } = quotationSchedule;
	const { quotationSchedule } = order;

	const expiresIn = `${messages.SG_LBL_ExpiresIn} : ${moment(endDate).format("MMMM Do YYYY")}`;

	const editQuotation = useCallback(async () => {
		const result = await getQuotationModel("quotationDialog", {
			messages: messages,
			quotation: order,
			updateQuotation: true
		});

		if (result) {
			dispatch(updateQuotation(result));
		}
	}, [dispatch, getQuotationModel, messages, order]);

	return (
		<QuotationContainer>
			<QuotationHeaderSection>
				<SummaryTitle>{messages.SG_LBL_Quotation || "Quotation"}</SummaryTitle>

				<Logo onClick={() => editQuotation()}>
					<EditIcon></EditIcon>
				</Logo>
			</QuotationHeaderSection>
			<InfoSection>
				<p>{order.quotationName}</p>
				<p>{order.quotationDescription || ""}</p>
				<p>{expiresIn}</p>
			</InfoSection>
		</QuotationContainer>
	);
};

export default QuotationSummary;
