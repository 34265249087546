import { appactionCreators } from "./../../../../store/shell/shellAction";
import { getOrderAmountSummarySelector } from "./../../../../store/cart/cartSelectors";
import { applyCartDiscounts } from "./../../../../store/cart/cart.thunks";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { device, ObjectHelper } from "@coworker/sharedlibrary";
import { useStorePreference } from "./../../../../hooks/useStorePreference";
import { Container } from "./../../../../components/sharedlibrary";
import PrimaryButton from "./../../../../components/Buttons/primaryButton";
import { DfpKey } from "@coworker/sharedlibrary";
import ManualDiscountView from "./manualDiscountView";
import CouponDiscountView from "./couponDiscountView";
import EmployeeDiscountView from "./employeeDiscountView";
import Choice, { ChoiceItem } from "@ingka/choice";
import SalesCoworkerConfiguration from "./../../../../store/salesCoworkerConfiguration";
// import { VoucherDiscountView } from "./voucherDiscountView";
const DiscountContainer = styled.div`
	.accordion__item:first-child button {
		border: none;
	}
	padding: 0px;
`;
const BtnContainer = styled.div`
	position: absolute;
	bottom: 0px;
	padding-bottom: 20px;
	width: 100%;
	background: #fff;
	.btn {
		width: 100%;
	}
	left: 0;
	padding: 0 1.25rem 10px;
	@media (min-width: 37.5em) {
		padding: 0 1.875rem 10px;
	}
	@media (min-width: 56.25em) {
		padding: 0 3.125rem 10px;
	}
	${({ isEditInFocus }) =>
		isEditInFocus &&
		`
@media ${device.small} {
  position: relative;
}
`}
`;

const Separator = styled.hr`
	width: 100%;
	padding: 8px 0px;
`;

const DisCountView = () => {
	const [addingDiscount, setAddingDiscount] = useState(false);
	const { coworkerData } = SalesCoworkerConfiguration.getUserConfig();
	const { messages } = useSelector(state => state.intl);
	const { discounts } = useSelector(state => state.cartController);
	const allowedDiscountTypes = useStorePreference(DfpKey.DISCOUNT_TYPES);
	const { totalPrice } = useSelector(state => getOrderAmountSummarySelector(state));

	const canGiveEmployeeDiscount = allowedDiscountTypes && allowedDiscountTypes.includes && allowedDiscountTypes.includes("EMPLOYEE");

	const canGiveManualDiscount =
		coworkerData && coworkerData.canGiveDiscount && allowedDiscountTypes && allowedDiscountTypes.includes && allowedDiscountTypes.includes("MANUAL");
	const canGiveCouponDiscount = allowedDiscountTypes && allowedDiscountTypes.includes && allowedDiscountTypes.includes("COUPON");

	const [employeeDiscount, setEmployeeDiscount] = useState({
		type: "EMPLOYEE",
		value: ""
	});
	const [canAddDiscount, setCanAddDiscount] = useState(false);
	const [manualDiscount, setManualDiscount] = useState({
		type: "MANUAL",
		value: 0,
		mode: "PERCENTAGE"
	});
	const [couponDiscount, setCouponDiscount] = useState({
		type: "COUPON",
		value: ""
	});
	const [showManualInput, setShowManualinput] = useState(false);

	const dispatch = useDispatch();

	function addDiscount() {
		const discounts = [];
		let empDiscount;
		if (employeeDiscount && employeeDiscount.value !== "") {
			empDiscount = {
				description: `Apply employee discount on goods`,
				type: "Employee",
				discountDetails: {
					value: employeeDiscount.value,
					type: "PERCENTAGE_OFF",
					applyOn: "GOODS_VALUE"
				}
			};
			discounts.push(empDiscount);
		}
		const manualDiscountReq = {
			description: `Apply ${manualDiscount.value} as manual discount on goods`,
			type: "Manual",
			discountDetails: {
				value: manualDiscount.value,
				type: manualDiscount.mode === "FLAT" ? "FIXED_PRICE_OFF" : "PERCENTAGE_OFF",
				applyOn: "GOODS_VALUE"
			}
		};
		if (manualDiscount.value > 0) discounts.push(manualDiscountReq);
		if (couponDiscount && couponDiscount.value !== "") {
			const couponDiscounts = {
				description: `Apply ${couponDiscount.value} as manual discount on goods`,
				type: "Coupon",
				value: couponDiscount.value,
				discountDetails: {
					value: couponDiscount.value,
					type: "FIXED_PRICE_OFF",
					applyOn: "GOODS_VALUE"
				}
			};
			discounts.push(couponDiscounts);
		}

		setAddingDiscount(true);
		dispatch(
			applyCartDiscounts(discounts, resposne => {
				if (resposne && resposne.salesPriceData && couponDiscount && couponDiscount.value !== "") {
					if (discounts.find(x => x.type === "Coupon") && resposne.salesPriceData.couponInfo) {
						let couponExist = resposne.salesPriceData.couponInfo.find(x => x.discountCode === couponDiscount.value);
						if (couponExist && couponExist.infoMessages) {
							let checkMessage = couponExist.infoMessages.find(
								x => x.messageId === "COUPON_NOT_EXISTS" || x.messageId === "COUPON_NOT_QUALIFIED_FOR_TCHPNT_CTXT"
							);
							if (checkMessage) {
								dispatch(appactionCreators.showNotification("error", checkMessage.messageText));
							}
						}
					}
				}
				setAddingDiscount(false);
			})
		);
	}

	useEffect(() => {
		let employeeDiscount = discounts && discounts.find(x => x.type === "Employee");
		if (employeeDiscount) {
			setEmployeeDiscount({
				value: employeeDiscount.discountDetails.value
			});
		}
		setShowManualinput(!showManualInput);
		let manualDiscount = discounts && discounts.find(x => x.type === "Manual");
		if (manualDiscount) {
			setManualDiscount({
				value: manualDiscount.discountDetails.value,
				mode: manualDiscount.discountDetails.type === "FIXED_PRICE_OFF" ? "FLAT" : "PERCENTAGE"
			});
		}
		let coupon = discounts && discounts.find(x => x.type === "Coupon");
		if (coupon) {
			setCouponDiscount({
				value: coupon.discountDetails.value,
				mode: coupon.discountDetails.type
			});
		}
	}, []);

	useEffect(() => {
		let canAddDiscount =
			(!ObjectHelper.isEmpty(employeeDiscount.value) && employeeDiscount.value > 0) || couponDiscount.value !== "" || manualDiscount.value !== "0 ";
		setCanAddDiscount(canAddDiscount);
	}, [employeeDiscount, manualDiscount, couponDiscount]);

	return (
		<DiscountContainer>
			<Container fdCol>
				{canGiveEmployeeDiscount && (
					<Choice deselectable={true}>
						<ChoiceItem
							id="canGiveEmployeeDiscount"
							selected={false}
							title={messages.SG_LBL_CoworkerDiscount || "Coworker discount"}
							className="firstAccordionDiscount"
						>
							<EmployeeDiscountView messages={messages} setEmployeeDiscount={setEmployeeDiscount} employeeDiscount={employeeDiscount} />
						</ChoiceItem>
					</Choice>
				)}
				{canGiveManualDiscount && (
					<Choice deselectable={true}>
						<ChoiceItem id="canGiveManualDiscount" selected={false} title={messages.SG_LBL_ManualDiscount || "Manual discount"}>
							<ManualDiscountView total={totalPrice} setManualDiscount={setManualDiscount} manualDiscount={manualDiscount} messages={messages} />
						</ChoiceItem>
					</Choice>
				)}
				{canGiveCouponDiscount && (
					<Choice preselectedIndex={null} deselectable={true}>
						<ChoiceItem id="canGiveCouponDiscount" selected={false} title={messages.SG_LBL_CouponDiscount || "Coupon discount"}>
							<CouponDiscountView setCouponDiscount={setCouponDiscount} couponDiscount={couponDiscount} messages={messages} />
						</ChoiceItem>
					</Choice>
				)}
			</Container>
			<BtnContainer>
				<Separator></Separator>
				{(canGiveCouponDiscount || canGiveManualDiscount || canGiveEmployeeDiscount) && (
					<PrimaryButton
						loading={addingDiscount}
						type={"primary"}
						disabled={!canAddDiscount}
						id={"adddiscount"}
						style={{
							marginTop: "10px",
							marginBottom: "10px",
							mobileWidth: "100%",
							deskTopwidth: "100%"
						}}
						onClick={addDiscount}
					>
						<p>{messages.SG_LBL_AddDiscount || "Add Discount"}</p>
					</PrimaryButton>
				)}
			</BtnContainer>
		</DiscountContainer>
	);
};

export { DisCountView };
