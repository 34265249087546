import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";

import QuotationWrapperService from "../../service/quotation/quoationApi";

import { Dropdown, DropdownItem } from "@ingka/dropdown";
import InputField from "@ingka/input-field";

import { List } from "../../components/sharedlibrary";
import Switch from "@ingka/switch";

const InputContainer = styled(InputField)`
	label {
		font-size: 14px !important;
	}
`;

const AddProjectSwitch = styled(Switch)`
	margin-top: 10px;
`;

const DropDownSelect = styled(Dropdown)`
	width: 100%;
	margin-top: 20px;
	margin-bottom: 20px;
`;

const QuoteDetailSection = styled(List)`
	&.quote-details-section {
		width: 320px;
		margin-top: 10px;
		margin-bottom: 50px;
	}
`;

const AddProjectsView = ({ customerId, messages, onProjectChange, selectedDays }) => {
	const [addProject, setAddProject] = useState(false);
	const [projects, setProjects] = useState([]);

	const projectProptext = {
		autoComplete: "off",
		defaultValue: "",
		id: "projName",
		label: messages.SG_LBL_ProjectName,
		type: "text",
		onBlur: e => {
			const today = moment();
			const endDay = today.add(parseInt(selectedDays), "days").format("YYYY-MM-DD");

			onProjectChange &&
				onProjectChange({
					projectRefNo: undefined,
					projectName: e.target.value,
					projectSchedule: {
						endDate: endDay,
						startDate: today.format("YYYY-MM-DD")
					}
				});
		}
	};

	const projectOption = {
		checked: addProject,
		id: "addproject",
		label: messages.SG_LBL_AddToProject,
		onChange: e => {
			setAddProject(e.target.checked);
			const firstProject = projects && projects.length > 0 ? projects[0] : undefined;

			onProjectChange && onProjectChange(firstProject);
		}
	};

	useEffect(() => {
		if (customerId) {
			QuotationWrapperService.getCusomerProjects(customerId).then(projects => {
				setProjects(projects);
			});
		}
	}, [customerId]);

	return (
		<QuoteDetailSection className="quote-details-section ">
			<AddProjectSwitch {...projectOption} />
			{addProject && (
				<>
					{!projects || projects.length === 0 ? (
						<InputContainer {...projectProptext} className={`mrg-top-15`} />
					) : (
						<DropDownSelect
							id="prjectsList"
							label={messages.SG_LBL_Projects || "Projects"}
							onChange={e => {
								var prj = projects.find(x => x.projectRefNo === e.target.value);
								onProjectChange && onProjectChange(prj);
							}}
						>
							{projects.map(x => (
								<DropdownItem key={x.projectRefNo} name={x.projectName} value={x.projectRefNo} />
							))}
						</DropDownSelect>
					)}
				</>
			)}
		</QuoteDetailSection>
	);
};

export default AddProjectsView;
