import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "@coworker/sharedlibrary";

import Button from "@ingka/button";

const buttonProps = {
	type: "icon-secondary",
	iconOnly: true
};

const CrossIcon = styled.div`
	width: 54px;
	height: 54px;
	text-align: right;
	align-self: flex-end;

	span {
		color: ${colors.black}!important;
	}

	button {
		width: 48px;
		height: 48px;
		float: right;
		margin-left: 0px;
	}
`;

export const CloseBar = styled.div`
	position: sticky;
	margin-right: 10px;
	top: 20px;
	z-index: 10;

	button {
		background: rgba(204, 204, 204, 0.5);
	}
`;

export const CloseButton = ({ onClick }) => {
	return (
		<CrossIcon>
			<Button
				{...buttonProps}
				onClick={() => {
					onClick();
				}}
			>
				<span className="i-crossBold" />
			</Button>
		</CrossIcon>
	);
};

export const IconButton = styled.button`
	background: none;
	color: ${colors.black}!important;
	width: 48px;
	height: 48px;
	position: relative;
	text-decoration: none;
	text-align: center;
	cursor: pointer;
	outline: 0;
	border: none;

	span {
		color: ${colors.black}!important;
		font-size: ${props => props.fontsize};
	}
`;

export const BackButton = ({ history, ssrIcon, enabled }) => {
	const [showBackButton, setShowBackButton] = useState(false);

	history.listen(location => {
		if (location.pathname.includes("detail")) {
			enabled && enabled(true);
			setShowBackButton(true);
		} else {
			enabled && enabled(false);
			setShowBackButton(false);
		}
	});

	if (showBackButton) {
		return (
			<Button
				type="tertiary"
				iconOnly={true}
				ssrIcon={ssrIcon}
				onClick={() => {
					history.goBack();
				}}
			/>
		);
	}

	return null;
};
