import { APIEndpoints, httpService } from "@coworker/sharedlibrary";
import { useSelector } from "react-redux";
import { getCustomerProfileObject } from "./../../../../utils/shellObjectService";
import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Dropdown, DropdownItem } from "@ingka/dropdown";
import { Container } from "../../../../components/sharedlibrary";
import InputField from "@ingka/input-field";
import FormField from "@ingka/form-field";
import SalesCoworkerConfiguration from "../../../../store/salesCoworkerConfiguration";
const DropDownSelect = styled(Dropdown)`
	width: 100% !important;
	margin-bottom: 10px;
	margin-top: 10px;
`;

const InputContainer = styled(InputField)`
	label {
		font-size: 14px !important;
	}
	padding: 0px 0px;
`;

const CustomerCreditCheck = ({ credit, setCredit, amount }) => {
	const { customer } = getCustomerProfileObject();
	const { buCode, countryCode, headers } = SalesCoworkerConfiguration.getUserConfig();
	const { messages } = useSelector(state => state.intl);
	const { currency, creditInfo } = useSelector(state => state.cartController);

	const [validation, setValidation] = useState();

	const [selectedPayment, setSelectedPayment] = useState();
	const inputref = useRef();
	const inputref1 = useRef();

	/*eslint-disable*/
	const inputref2 = useRef();
	/*eslint-disable*/
	const inputref3 = useRef();

	const inputrefdate = useRef();
	let cards = (customer && customer.businessCards) || [];

	const [isLoading, setIsLoading] = useState(true);
	const [businessCards, setBusinessCards] = useState([...cards]);
	React.useEffect(() => {
		if (cards && cards.length > 0) {
			httpService
				.post(APIEndpoints.paymentCreditCheck, {
					body: {
						cards: cards.map(card => {
							return card.cardNumber;
						}),
						countryCode: countryCode,
						buCode: buCode
					},
					headers: headers
				})
				.then(response => {
					setIsLoading(false);
					response.businessCards.forEach(card => {
						card.currency = currency;
						let existingCard = cards.find(x => x.cardNumber === card.cardNumber);
						if (existingCard) {
							card.organizationNo = existingCard.organizationNo;
						}
					});
					setBusinessCards([...response.businessCards]);
					if (creditInfo) {
						let card = response.businessCards.find(x => x.cardNumber === creditInfo.cardNumber);
						if (card) {
							setSelectedPayment({ ...creditInfo, ...card });
						}
					} else {
						let card = response.businessCards[0];
						setSelectedPayment(card);
						setCredit({
							...card,
							amount
						});
					}
				})
				.catch(() => {
					setIsLoading(false);
					setBusinessCards([]);
				});
		}
	}, []);

	React.useEffect(() => {
		if (selectedPayment) {
			if (selectedPayment.availableCredit < amount) {
				setValidation({
					type: "error",
					msg: messages.SG_LBL_NotEnoughCredit || "Not Enough Credit to create order"
				});
			}
			setCredit({
				...selectedPayment,
				amount: amount
			});
		}
	}, [selectedPayment]);
	const manualProp = {
		req: "*",
		type: "number",
		autoComplete: "off",
		id: "credit_limit",
		defaultValue: selectedPayment && `${selectedPayment.availableCredit} ${currency}`,
		label: messages.SG_LBL_UnUsedCreditLimit || "Unused Limit",
		readOnly: true
	};
	const manualProp1 = {
		req: "*",
		type: "text",
		autoComplete: "off",
		defaultValue: (selectedPayment && selectedPayment.invoiceRef1 && `${selectedPayment.invoiceRef1}`) || "",

		id: "credit_limit_ref1",
		label: messages.SG_LBL_RequisitionNo || "Requistion No",
		onBlur: e => {
			setCredit({
				...credit,
				invoiceRef1: e.target.value
			});
		}
	};
	// will use this once SAPI enables this in request
	/*eslint-disable*/

	const manualProp2 = {
		req: "*",
		type: "text",
		autoComplete: "off",
		defaultValue: (selectedPayment && selectedPayment.invoiceRef2 && `${selectedPayment.invoiceRef2}`) || "",
		id: "credit_limit_ref2",
		label: messages.SG_LBL_InvoiceReferenceNo2 || "Invoice Reference 2",
		onBlur: e => {
			setCredit({
				...credit,
				invoiceRef2: e.target.value
			});
		}
	};
	// will use this once SAPI enables this in request
	/*eslint-disable*/
	const manualProp3 = {
		req: "*",
		type: "text",
		autoComplete: "off",
		defaultValue: (selectedPayment && selectedPayment.invoiceRef3 && `${selectedPayment.invoiceRef3}`) || "",
		id: "credit_limit_ref3",
		label: messages.SG_LBL_InvoiceReferenceNo3 || "Invoice Reference 3",
		onBlur: e => {
			setCredit({
				...credit,
				invoiceRef3: e.target.value
			});
		}
	};
	const dateCheck = {
		req: "*",
		type: "text",
		label: `${messages.SG_LBL_CardExpiry}(MM/YYYY)`,
		autoComplete: "off",
		placeholder: "MM/YYYY",
		defaultValue: selectedPayment && `${selectedPayment.creditExpiry || ""}`,
		id: "credit_limit_date",
		onChange: e => {
			if (e.target.value && e.target.value.length > 2) {
				let query = e.target.value.replace(/\//gi, "").substring(0, 6);
				let newValue = `${query.substring(0, 2)}/${query.substring(2, query.length)}`;
				if (inputrefdate && inputrefdate.current) {
					inputrefdate.current.value = newValue;
				}
			}
		},
		onBlur: e => {
			if (e.target.value) {
				// let date = moment(e.target.value).format("MM/YYYY");

				setCredit({
					...credit,
					creditExpiry: e.target.value
				});
			}
		}
	};
	function maskNumber(cardNumber = "") {
		let totallength = cardNumber.length;
		return cardNumber.substring(0, 3) + "***" + cardNumber.substring(7, totallength);
	}
	if (isLoading) {
		return <div>{messages.SG_LBL_LoadingValidCards || "loading valid cards"}</div>;
	}

	return (
		<Container width="100%" fdCol>
			<DropDownSelect
				id="buSelect"
				label={messages.SG_LBL_CardNumber || "Card number"}
				onChange={event => {
					let card = businessCards.find(x => x.cardNumber === event.target.value);
					if (creditInfo && creditInfo.cardNumber === card.cardNumber) {
						card = {
							...creditInfo,
							...card
						};
					}
					setSelectedPayment(businessCards.find(x => x.cardNumber === event.target.value));
				}}
				defaultValue={selectedPayment && selectedPayment.cardNumber}
			>
				{businessCards &&
					businessCards.map(element => (
						<DropdownItem id={element.cardNumber} key={element.cardNumber} value={element.cardNumber} name={maskNumber(element.cardNumber)} />
					))}
			</DropDownSelect>
			<Container
				width="100%"
				styles={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between"
				}}
			>
				<FormField shouldValidate={true} validation={validation}>
					<InputContainer ref={inputref} {...manualProp}></InputContainer>
				</FormField>
				<div
					style={{
						width: "10px"
					}}
				></div>
				{/* <label htmlFor="credit_limit_date">
          {messages.SG_LBL_CardExpiry || "Expiry : "}
        </label> */}
				<InputContainer ref={inputrefdate} {...dateCheck}></InputContainer>
			</Container>
			<FormField shouldValidate={true} validation={validation}>
				<InputContainer ref={inputref1} {...manualProp1}></InputContainer>
			</FormField>
			{/* <FormField shouldValidate={true} validation={validation}>
        <InputContainer ref={inputref2} {...manualProp2}></InputContainer>
      </FormField>
      <FormField shouldValidate={true} validation={validation}>
        <InputContainer ref={inputref3} {...manualProp3}></InputContainer>
      </FormField> */}
		</Container>
	);
};
export default CustomerCreditCheck;
