import React, { useState } from "react";
import Modal, { Sheets, ModalBody, ModalHeader } from "@ingka/modal";
import HelpView from "../../../containers/help/helpview";
import SuperUser from "../../../components/help/superuser";
import styled, { css } from "styled-components";
import Faq from "../../../components/help/faq";
import { useSelector } from "react-redux";
import { device } from "@coworker/sharedlibrary";
import KnowledgeBase from "../../help/knowledgeBase";

const focus = {
	returnfocus: "false",
	autoFocus: true
};

const StyledModalHeader = styled(ModalHeader)`
	${props =>
		props.bigHeader &&
		css`
			background: #161e44 !important;
			height: 20rem !important;
			top: -50px;

			.modal-header__title {
				color: #ffcd3f;
				font-size: 24px;
				font-weight: bold;
				&-wrapper {
					text-align: left;
					margin: 0;
				}
			}
			button {
				position: absolute;
				top: 25px;

				.btn__inner {
					color: #fff;
				}
			}

			@media ${device.medium} {
				height: 10rem !important;
			}
		`}
	button {
		.btn__inner {
			background-color: transparent;
		}
	}
`;

const HelpSectionDialog = ({ onClose, section = "help", goto }) => {
	const [selectedSection, setSelectedSection] = useState(section);
	const { messages } = useSelector(state => state.intl);
	const [backFunc, setBackFunc] = useState(null);

	const onSectionSelected = selected => {
		setSelectedSection(selected);
	};

	const getTitle = selectedSection => {
		if (selectedSection === "faq") {
			return `${messages.SG_LBL_HelpSectionFAQTittle || "FAQ"}`;
		} else if (selectedSection === "superuser") {
			return `${messages.SG_LBL_HelpSectionContactTitle || "Contact super-user"}`;
		} else if (selectedSection === "knowledgebase") {
			return `${messages.SG_LBL_HelpSectionKnowledgeBaseTitle || "Knowledge Base"}`;
		}

		return `${messages.SG_LBL_HelpTitle || "Help"}`;
	};

	React.useEffect(() => {
		if (selectedSection !== "help") {
			setBackFunc({
				backBtnClick: e => {
					e.preventDefault();
					onSectionSelected("help");
				}
			});
		} else {
			setBackFunc(null);
		}
	}, [selectedSection]);

	const SelectedSection = () => {
		if (selectedSection === "help") {
			return <HelpView messages={messages} onSelectSection={onSectionSelected}></HelpView>;
		}
		if (selectedSection === "faq") {
			return <Faq onBackBtnClick={onSectionSelected} goto={goto}></Faq>;
		}
		if (selectedSection === "superuser") {
			return <SuperUser onBackBtnClick={onSectionSelected}></SuperUser>;
		}
		if (selectedSection === "knowledgebase") {
			return <KnowledgeBase onBackBtnClick={onSectionSelected}></KnowledgeBase>;
		}
	};

	return (
		<Modal
			visible
			focusLockProps={focus}
			handleCloseBtn={() => {
				onClose();
			}}
		>
			<Sheets header={<StyledModalHeader {...backFunc} bigHeader={selectedSection === "help"} title={getTitle(selectedSection)} />}>
				<ModalBody>
					<SelectedSection></SelectedSection>
				</ModalBody>
			</Sheets>
		</Modal>
	);
};

export default HelpSectionDialog;
