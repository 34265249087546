import Button from "@ingka/button";
import styled from "styled-components";
import React from "react";
import { colors } from "@coworker/sharedlibrary";
import { Label, List } from "../../components/sharedlibrary";
import emptySvg from "../../static/emptybag.svg";

const EmptyImage = styled.img`
	background-repeat: no-repeat !important;
	background-size: contain;
	height: 250px;
	width: 100%;
	align-self: center;
	background-color: white;
	margin: 10px;
	background-image: url(${emptySvg}) !important;
`;
const RetryModeContainer = styled.div`
	background-color: ${colors.appColor};
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	min-height: 100%;
	align-items: center;
	div {
		border-radius: 8px;
		padding: 12px;
		justify-content: center;

		background: #fff;
		margin: auto;
		align-self: center;
	}
	button {
		margin-top: 24px;
	}
	label {
		text-align: center;
	}
`;
const RetryModeView = ({ onRetry }) => {
	return (
		<RetryModeContainer>
			<List>
				<EmptyImage />
				<Label fontweight={"bold"} width={"300px"}>
					Opps! sorry we are unable to contact the server at the moment...
				</Label>
				<Button
					type="outlined"
					text="retry"
					onClick={() => {
						onRetry && onRetry();
					}}
				/>
			</List>
		</RetryModeContainer>
	);
};

export default RetryModeView;
