import { getMessage, GUID } from "@coworker/sharedlibrary";
import { GetCartErrors, CanModifyOrder } from "./../canCreateSalesOrderValidator";
import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { List } from "../../../components/sharedlibrary";

const ErrorList = styled(List)`
	&.error-list {
		margin: ${props => (props.code ? "0" : "10px 0")};
		align-self: flex-start;
		align-items: flex-start;
	}
`;
const ErrorItem = styled.div`
	color: red;
`;
const CartErrors = ({ code }) => {
	let errors = useSelector(state => GetCartErrors(state));
	let messages = useSelector(state => state.intl.messages);
	let canShowErrors = useSelector(state => CanModifyOrder(state));
	return (
		<>
			{errors && canShowErrors && (
				<ErrorList code={code} className="error-list">
					{errors
						.filter(error => {
							if ((!code && error.code !== "MISSINGCUSTOMER") || (code && code === error.code)) {
								return true;
							}
						})
						.map(error => (
							<div key={GUID.Guid()}>
								<ErrorItem>{getMessage(`SG_LBL_${error.code}`, messages, error.message)}</ErrorItem>
							</div>
						))}
				</ErrorList>
			)}
		</>
	);
};
export default CartErrors;

export const forTest = {
	ErrorItem: ErrorItem,
	ErrorList: ErrorList
};
