import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import Button from "@ingka/button";
import { colors } from "@coworker/sharedlibrary";
import { device } from "@coworker/sharedlibrary";
import { appactionCreators } from "./../../store/shell/shellAction";

const hiddenTheme = {
	height: "0px",
	opacity: "0",
	padding: "0",
	delay: "0"
};

const visibleTheme = {
	height: "auto",
	opacity: "1",
	padding: "31px 25px",
	delay: "0.5"
};

const NotificationContainer = styled.aside`
	background: ${props => props.background};
	color: ${colors.white};
	width: 400px;
	height: ${props => props.theme.height} !important;
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 20px;
	right: 30px;
	z-index: 1000000002;
	opacity: ${props => props.theme.opacity};
	font-size: 13px;
	padding: ${props => props.theme.padding};
	border-radius: 5px;
	transition: height 0ms ${props => props.theme.delay}s, opacity ${props => props.theme.delay}s 0ms;

	@media ${device.medium} {
		width: 100%;
		top: 0;
		left: 0;
		right: 10px;
		border-radius: 0;
	}

	* {
		color: inherit;
	}

	section {
		width: 100%;
		height: auto;

		small {
			height: auto;
			margin-top: 10px;
		}
	}
`;

const Title = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	button {
		background: transparent;
		font-size: inherit;
		font-weight: bold;
		margin: 0;
		padding: 0;
	}
`;

const Notification = props => {
	const { hideNotification, isExpandAppNotification, expandNotification, showAppNotification, notificationData } = props;

	let wrapperRef;

	useEffect(() => {
		setWrapperRef = setWrapperRef.bind(this);
		handleClickOutside = handleClickOutside.bind(this);
	}, []);

	let setWrapperRef = node => {
		wrapperRef = node;
	};

	const getNotification = (notificationData, hidden) => {
		var bgcolor = colors.black;

		if (notificationData.type === "error") {
			bgcolor = colors.error;
		}

		if (notificationData.type === "warn") {
			bgcolor = colors.warning;
		}

		if (notificationData.type === "success") {
			bgcolor = colors.success;
		}

		return (
			<NotificationContainer
				data-testid="notificationcontainer"
				background={bgcolor}
				theme={hidden ? hiddenTheme : visibleTheme}
				ref={setWrapperRef}
				alt="Notification container"
			>
				{hidden ? "" : visibleNotification(notificationData)}
			</NotificationContainer>
		);
	};

	const visibleNotification = notificationData => {
		return (
			<>
				<Title data-testid="notificationpopup" role="button" onKeyPress={() => showMoreInfo()} onClick={() => showMoreInfo()}>
					<div>{notificationData.title}</div>
					<div>
						<Button className="btn btn--plain toast__close" type="button" aria-label="close" id="01" onClick={() => hideNotification()}>
							<span className="btn__copy">
								<svg focusable="false" viewBox="0 0 24 24" className="svg-icon btn__icon" aria-hidden="true">
									<path d="M13.45,12,16.71,8.7,15.29,7.3,12,10.59,8.74,7.32,7.33,8.75,10.62,12,7.36,15.3l1.42,1.4L12,13.41l3.32,3.26,1.41-1.42Z" />
								</svg>
							</span>
						</Button>
					</div>
				</Title>
				{isExpandAppNotification && (
					<section>
						<small>{notificationData.message}</small>
					</section>
				)}
			</>
		);
	};

	const showMoreInfo = () => {
		expandNotification(!isExpandAppNotification);
	};

	let handleClickOutside = event => {
		if (wrapperRef && !wrapperRef.contains(event.target)) {
			hideNotification();
		}
	};

	if (!showAppNotification) {
		document.removeEventListener("click", handleClickOutside);

		return getNotification(notificationData, true);
	}

	document.addEventListener("click", handleClickOutside);

	return getNotification(notificationData, false);
};

const mapStateToProps = state => ({
	showAppNotification: state.appController.showAppNotification,
	notificationData: state.appController.notificationData,
	isExpandAppNotification: state.appController.isExpandAppNotification,
	messages: state.intl.messages
});

export default connect(mapStateToProps, dispatch =>
	bindActionCreators(
		{
			...appactionCreators
		},
		dispatch
	)
)(Notification);
