import { combineReducers } from "redux";
import AppReducer from "../shell/shellReducer";
import cartReducer from "../cart/cartReducer";
import { connectRouter } from "connected-react-router";
import { intlReducer } from "react-intl-redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "../auth/reducer";
import { localeReducer } from "../locale";
import warningReducer from "../warning/warningReducer";
import importReducer from "../import/reducer";

// eslint-disable-next-line no-undef
let env = process.env.NODE_ENV;
const isDevelopment = env === "development";
const rootPersistConfig = {
	key: "root",
	storage,
	blacklist: [
		"router",
		"appController",
		"intl",
		"searchController",
		"itemController",
		"browseController",
		"storeInfoController",
		"seriesController",
		"forecastController",
		"authController",
		"localeController",
		"clientFiltersController",
		"warningController",
		"importController"
	]
};

const cartConfig = {
	key: "cart-version-v2",
	storage: storage
};

const getReducers = history => {
	const reducer = combineReducers({
		router: connectRouter(history),
		appController: AppReducer,
		cartController: !isDevelopment ? cartReducer : persistReducer(cartConfig, cartReducer), // persistReducer(cartConfig, cartReducer),
		intl: intlReducer,
		authController: authReducer,
		localeController: localeReducer,
		warningController: warningReducer,
		importController: importReducer
	});

	return persistReducer(rootPersistConfig, reducer);
};
export default getReducers;
