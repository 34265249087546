import { BarcodeReader } from "@coworker/sharedlibrary";
import React from "react";

const ArticleBarCodeScanner = ({ onScan }) => {
	return (
		<BarcodeReader
			onError={e => {
				console.log(e);
			}}
			onScan={onScan}
		/>
	);
};
export default ArticleBarCodeScanner;
