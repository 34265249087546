import { device } from "@coworker/sharedlibrary";
import Button from "@ingka/button";
import React from "react";
import styled from "styled-components";

const IngkaButton = styled(Button)`
	width: ${props => props.style.deskTopwidth};

	@media ${device.small} {
		width: ${props => props.style.mobileWidth || "100%"} !important;
	}
`;

const PrimaryButton = ({ id, style, children, onClick, disabled = false, loading, type = "primary" }) => {
	return (
		<IngkaButton
			id={id}
			loading={loading}
			disabled={disabled}
			style={style}
			type={type}
			onClick={() => {
				onClick && onClick();
			}}
		>
			{children}
		</IngkaButton>
	);
};
export default PrimaryButton;
