import { appactionCreators } from "../../store/shell/shellAction";
import { RequestHelper } from "@coworker/sharedlibrary";
const LoadExternalAppAndUserConfiguration = async ({ userInfo, userType, home, changeLocale, getTranslations, setUser, dispatch }) => {
	changeLocale(userInfo.primaryLocale);
	let locationSearch = new URLSearchParams(window.location.search);
	let overidedlocale = locationSearch.get("locale");

	home.customerSchema = {
		isMfsProfile: false
	};

	const addresPref = "LOQATE";

	let settings = {
		locale: userInfo.primaryLocale,
		addressSuggestion: addresPref
	};
	if (overidedlocale && !overidedlocale.includes("xx-XX")) {
		settings.locale = overidedlocale;
	}

	if (settings.locale) {
		getTranslations(settings.locale, overidedlocale && overidedlocale.includes("xx-XX")).then(() => {
			dispatch(
				appactionCreators.setHomeContext({
					...home,
					addressProvider: settings.addressSuggestion
				})
			).then(() => {
				setUser(RequestHelper.getLoggedInUser(), userType);
			});
		});
	}
};
export default LoadExternalAppAndUserConfiguration;
