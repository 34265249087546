import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { colors, device } from "@coworker/sharedlibrary";

import QuotationWrapperService from "../../service/quotation/quoationApi";

import InputField from "@ingka/input-field";

import { List } from "../../components/sharedlibrary";
import ProjectsView from "./projectsView";
import SaveQuoteButton from "./saveQuoteButton";

const QuoteDetailSection = styled(List)`
	&.quote-detail-section {
		width: 400px;
		margin-top: 20px;
		margin-bottom: 90px;

		@media ${device.small} {
			width: 100%;
		}
	}
`;

const InputContainer = styled(InputField)`
	label {
		font-size: 14px !important;
	}
`;

const Container = styled.div`
	padding: 40px 80px 60px 80px;

	@media ${device.small} {
		width: 100%;
		height: 100%;
		padding: 12px;
	}
`;

const InfoSection = styled.section`
	color: ${colors.grey700};
	flex-basis: 100%;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.71;
	letter-spacing: normal;

	.quotation-title {
		color: ${colors.primaryTextColor};
		font-size: 24px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		line-height: 1;
	}

	p {
		margin: 0 !important;
	}
`;

const EditSection = styled.div`
	margin-top: 20px;
`;

const UpdateQuotationView = ({ onSubmit, quotation }) => {
	const { quotationSchedule, quotationName, customer, project } = quotation;

	const { messages } = useSelector(state => state.intl);

	const [loading, setLoading] = useState(false);
	const [quoteName, setQuoteName] = useState(quotationName);
	const [projectId, setProjectId] = useState((project && project.projectRefNo) || "");
	const [startDate, setQuoteStartDate] = useState(quotationSchedule.startDate);
	const [endDate, setQuoteEndDate] = useState(quotationSchedule.endDate);
	// const [quoteAssignee, setAssignedTo] = useState(assignedTo);assignedTo

	const quoteNameProptext = {
		autoComplete: "off",
		defaultValue: quoteName || "",
		id: "quoteNameText",
		label: messages.SG_LBL_QuoteName,
		onBlur: e => {
			setQuoteName(e.target.value);
		},
		type: "text"
	};

	const quoteStartDateProptext = {
		autoComplete: "off",
		defaultValue: startDate,
		id: "quotDateStart",
		label: messages.SG_LBL_StartDate,
		onBlur: e => {
			setQuoteStartDate(e.target.value);
		},
		type: "date"
	};

	const quoteEndProptext = {
		autoComplete: "off",
		defaultValue: endDate,
		id: "quotEndStart",
		label: messages.SG_LBL_EndDate,
		onBlur: e => {
			setQuoteEndDate(e.target.value);
		},
		type: "date"
	};

	// const quoteAssignToProptext = {
	//   type: "text",
	//   autoComplete: "off",
	//   id: "assignedTo",
	//   defaultValue: quoteAssignee || "",
	//   label: messages.SG_LBL_AssignTo,
	//   onBlur: e => {
	//     setAssignedTo(e.target.value);
	//   }
	// };

	const saveQuotation = useCallback(async () => {
		setLoading(true);

		const request = {
			projectRefNo: projectId,
			quotation: {
				quotationName: quoteName,
				quotationSchedule: {
					endDate: endDate,
					startDate: startDate
				}
			}
		};

		QuotationWrapperService.updateQuotation(quotation.orderNo, request).then(() => {
			QuotationWrapperService.viewQuotation(quotation.orderNo).then(response => {
				setLoading(false);

				onSubmit && onSubmit(response);
			});
		});
	}, [endDate, startDate, quoteName, quotation.orderNo, projectId, onSubmit]);

	return (
		<Container>
			<QuoteDetailSection className="quote-detail-section">
				<InfoSection>
					<h6 className={"quotation-title"}> {messages.SG_LBL_Quotation}</h6>
				</InfoSection>
				<EditSection>
					<InputContainer {...quoteNameProptext} />
				</EditSection>

				<ProjectsView
					customerId={customer.id}
					messages={messages}
					onProjectChange={proj => {
						setProjectId(proj.projectRefNo);
					}}
					projectId={projectId}
				></ProjectsView>

				<EditSection>
					<InputContainer {...quoteStartDateProptext}></InputContainer>
				</EditSection>
				<EditSection>
					<InputContainer {...quoteEndProptext}></InputContainer>
				</EditSection>
			</QuoteDetailSection>
			<SaveQuoteButton loading={loading} messages={messages} onSave={saveQuotation} />
		</Container>
	);
};

export default UpdateQuotationView;
