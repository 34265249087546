class OrderServicesList {
	constructor(group, translated, data) {
		this.selectedServiceCount = 0;
		this.group = group;
		this.translated = ToFirstUpper(translated.toLowerCase());
		this.services = data;
		this.isVisible = true;
	}
}
const ToFirstUpper = data => {
	return data.charAt(0).toUpperCase() + data.toLowerCase().slice(1);
};
export default OrderServicesList;
