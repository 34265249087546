export const size = {
	tablet: "899px",
	small: "599px",
	medium: "899px",
	large: "1199px"
};

export const device = {
	tablet: `(max-width: ${size.tablet})`,
	small: `(max-width: ${size.small})`,
	medium: `(max-width: ${size.medium})`,
	large: `(max-width: ${size.large})`
};

export const LayoutPadding = {
	landscape: "31px",
	portrait: "12px"
};

export const LayoutLeftBarWidth = "210px";

export const orientation = {
	landscape: `(orientation: landscape)`,
	portrait: `(orientation: portrait)`
};

export const mediaQuery = {
	smallLandscape: `${device.small} and ${orientation.landscape}`,
	mediumLandscape: `${device.medium} and ${orientation.landscape}`,
	largeLandscape: `${device.large} and ${orientation.landscape}`,
	smallPortrait: `${device.small} and ${orientation.portrait}`,
	mediumPortrait: `${device.medium} and ${orientation.portrait}`,
	largePortrait: `${device.large} and ${orientation.portrait}`
};
