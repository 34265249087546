import { ToFirstUpper } from "@coworker/sharedlibrary/src/helpers/objectHelper";
class ServiceInfoModelList {
	constructor(group, translated, data, isDeliveryService) {
		this.selectedServiceCount = 0;
		this.group = group;
		this.translated = ToFirstUpper(translated.toLowerCase());
		this.services = data;
		this.isVisible = true;
		this.isDeliveryService = isDeliveryService;
		this.isProvidedService = !isDeliveryService;
	}
}

export default ServiceInfoModelList;
