import HomeService from "../service/Home/homeService";
import { UserType } from "../store/auth/reducer";
import LoadAppAndUserConfiguration from "../service/loadAppAndUserConfiguration";
import { RequestHelper } from "@coworker/sharedlibrary";
const SetUpGuestLogin = ({ bu, changeLocale, getTranslations, setUser, dispatch }) => {
	let home = new HomeService();
	const user = RequestHelper.getLoggedInUser();
	home.initConfig().then(async () => {
		home.getHomeByStore(bu, user.ikealegacyuid).then(storeInfo => {
			const userInfo = { ...storeInfo };
			if (user) {
				userInfo.userId = user.ikealegacyuid;
			}
			userInfo.name = "Guest User";
			const userType = UserType.GUEST;
			LoadAppAndUserConfiguration({
				userInfo,
				userType,
				home,
				changeLocale,
				getTranslations,
				setUser,
				dispatch
			});
		});
	});
};
export default SetUpGuestLogin;
