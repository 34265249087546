import { useSelector } from "react-redux";
import React from "react";
import Modal, { Prompt, ModalFooter } from "@ingka/modal";
import Button from "@ingka/button";
import { GUID } from "@coworker/sharedlibrary";

const NotAllowedToAddItemsDialog = ({ onClose }) => {
	const { messages } = useSelector(state => state.intl);
	function getModelButtons() {
		return [
			{
				text: messages.SG_BTN_OK || "OK",
				type: "primary",
				onClick: () => {
					onClose(false);
				}
			}
		];
	}
	return (
		<Modal
			visible={true}
			escapable={true}
			handleCloseBtn={() => {
				onClose();
			}}
			focusLockProps={focus}
			scrollLock={false}
		>
			<Prompt
				title={messages.SG_LBL_NotAllowedToAddItemsInCashLine || "In the Transport desk cash line you can add only items"}
				footer={
					<ModalFooter compact={false}>
						{getModelButtons().map(button => {
							return <Button key={GUID.Guid()} {...button} />;
						})}
					</ModalFooter>
				}
			/>
		</Modal>
	);
};

export default NotAllowedToAddItemsDialog;
const focus = {
	returnFocus: false,
	autoFocus: true
};
