const ColorHelper = {
	gray: "#F5F5F5",
	bordergray: "#DFDFDF",
	appColor: "#151E44",
	red: "red",
	gray500: "#959595",
	ikeacolorblue: "#0058A3",
	yellow: "#FFCD3F",
	blue: "#151E44",
	white: "#FFFFFF",
	black: "#000000",
	darkblue: "#111836",
	grey900: "#111111",
	grey800: "#333333",
	grey700: "#666666",
	grey500: "#959595",
	grey200: "#DFDFDF",
	grey150: "#F2F2F2",
	grey100: "#F5F5F5",
	overflowblack: "#030301",
	success: "#0A8A00",
	warning: "#FFA524",
	error: "#E00751",
	instock: "#009669",
	relatedblue: "#007AFF",
	primaryTextColor: "#111111",
	colourTextDefault: "#484848",
	colourBgAlternative: "#f5f5f5"
};

export default ColorHelper;
