export const ToFirstUpper = data => {
	return data.charAt(0).toUpperCase() + data.toLowerCase().slice(1);
};

export const appendComma = values => {
	let retValue = [];

	values.forEach(element => {
		if (element) {
			retValue.push(element);
		}
	});

	return retValue.join(",");
};

export const lowerCaseAllButFirstLetters = string => {
	return string.replace(/\S*/g, function (word) {
		return word.charAt(0) + word.slice(1).toLowerCase();
	});
};

export const isNotNull = value => {
	return value && value !== null;
};

export const isNull = value => {
	return isNil(value) || value === null;
};

export const isNil = value => {
	return value === undefined || value == null || value === "";
};

export const isEmpty = value => {
	return isNull(value) || value === "";
};

const isObject = value => typeof value === "object" && value !== null;

const compareObjects = (A, B) => {
	const keysA = Object.keys(A);
	const keysB = Object.keys(B);

	if (keysA.length !== keysB.length) {
		return false;
	}

	return !keysA.some(key => !Object.prototype.hasOwnProperty.call(B, key) || A[key] !== B[key]);
};

export const shallowEqual = (A, B) => {
	if (A === B) {
		return true;
	}

	if ([A, B].every(Number.isNaN)) {
		return true;
	}

	if (![A, B].every(isObject)) {
		return false;
	}

	return compareObjects(A, B);
};
export const isNaN = value => {
	return Number.isNaN(value);
};
