import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { DfpKey } from "@coworker/sharedlibrary";
import Markdown from "markdown-to-jsx";

const HeadlineSubText = styled.p`
	font-size: 14px;
	line-height: 24px;
	margin-bottom: 10px;
	color: #666666;
`;
const MarkDownContainer = styled.div`
	word-break: break-word;
	padding: 2em 0;
	h3 {
		margin-bottom: 0.4em;
	}
`;

const Faq = ({ goto }) => {
	const { preferences } = useSelector(state => state.appController);
	const [contextText, setContextText] = useState("");
	const { messages } = useSelector(state => state.intl);
	const guestModeRef = React.useRef();

	const AnchorElement = ({ children, ...props }) => (
		<a {...props} target="_blank">
			{children}
		</a>
	);

	const getfaqHeader = () => {
		return `${messages.SG_LBL_HelpSectionFAQSubTitle || "Learn how to use Sälja Go"}`;
	};

	React.useEffect(() => {
		if (goto === "guestMode") {
			setTimeout(() => {
				if (guestModeRef && guestModeRef.current) {
					guestModeRef.current.scrollIntoView(true);
				}
			}, 0);
		}
	}, []);

	React.useEffect(() => {
		const faqLinksPref = preferences.find(x => x !== undefined && x.name === DfpKey.FAQ_SECTION_LINKS);

		try {
			if (faqLinksPref !== undefined && faqLinksPref !== null && faqLinksPref.value) {
				setContextText(faqLinksPref.value);
			}
		} catch {
			//
		}
	}, []);

	return (
		<>
			<HeadlineSubText>{getfaqHeader()}</HeadlineSubText>
			<MarkDownContainer ref={guestModeRef} data-testid="faqContainer">
				<Markdown
					options={{
						overrides: {
							a: {
								component: AnchorElement
							}
						}
					}}
				>
					{contextText}
				</Markdown>
			</MarkDownContainer>
		</>
	);
};

export default Faq;
