import { updateMfeConfigs } from "./../../utils/shellObjectService";
import { httpService, LocaleHelper, RequestHelper } from "@coworker/sharedlibrary";
import { updateIntl } from "react-intl-redux";

import AppLogger from "./../../logger/appLogger";

const CHANGE_LOCALE = "CHANGE_LOCALE";
const GET_TRANSLATIONS_REQUEST = "GET_TRANSLATIONS_REQUEST";
const GET_TRANSLATIONS_SUCCESS = "GET_TRANSLATIONS_SUCCESS";
const GET_TRANSLATIONS_FAILURE = "GET_TRANSLATIONS_FAILURE";

const initialState = { locale: "en" };
const getMessages = async locale => {
	try {
		const baseUrl = process.env.REACT_APP_API_HOST || "";
		const response = await httpService.get(`${baseUrl}/api/translation?locale=${locale}`);

		if (response) {
			return response;
		}
	} catch (error) {
		//log error
		AppLogger.error(`GetMessages failed : ${error.message}`, "GetMessages", {
			locale: locale
		});
	}

	return {
		en: {
			test: "Test"
		}
	};
};
export const simpleActionCreators = {
	setUILocale: language => {
		return { type: CHANGE_LOCALE, payload: language };
	},
	getTranslationsRequest: locale => {
		return { type: GET_TRANSLATIONS_REQUEST, payload: locale };
	},
	getTranslationsSuccess: locale => {
		return { type: GET_TRANSLATIONS_SUCCESS, payload: locale };
	},
	getTranslationsFailure: error => {
		return { type: GET_TRANSLATIONS_FAILURE, payload: error };
	}
};

const changeLocale = locale => dispatch => {
	const language = LocaleHelper.formatLocaleIso(locale);

	RequestHelper.setLocale(language);
	dispatch(simpleActionCreators.setUILocale(language));
};

const getTranslations = (languageCode, showKeysAsValue) => dispatch => {
	var javaLngCode = LocaleHelper.formatLocaleToJava(languageCode);
	const isoLngCode = LocaleHelper.formatLocaleIso(languageCode);
	dispatch(simpleActionCreators.getTranslationsRequest(javaLngCode));

	const importMomentLocaleFile = lngCode => {
		var languageCode = LocaleHelper.getLanguageCode(lngCode);
		var momentKey = map[languageCode.toLowerCase()] || map[lngCode.toLowerCase()] || map[lngCode] || "moment/locale/en.js";
		if (momentKey.includes("en-gb")) {
			momentKey = "moment/locale/en.js";
		}
		try {
			import(`${momentKey.replace(".js", "")}`)
				.then(() => {
					//
				})
				.catch(() => {
					//
				});
		} catch (error) {
			//
		}
	};
	return getMessages(isoLngCode)
		.then(msgs => {
			importMomentLocaleFile(isoLngCode);
			dispatch(simpleActionCreators.getTranslationsSuccess(javaLngCode));
			if (msgs[javaLngCode]) {
				if (showKeysAsValue) {
					Object.entries(msgs[javaLngCode]).forEach(([key]) => {
						msgs[javaLngCode][key] = key;
					});
				}
				return {
					locale: isoLngCode,
					locales: isoLngCode,
					messages: msgs[javaLngCode]
				};
			}
			return {
				locale: "en",
				locales: "en",
				messages: msgs["en"]
			};
		})
		.then(i18nConfig => {
			dispatch(updateIntl(i18nConfig));
			updateMfeConfigs(i18nConfig);
			return i18nConfig;
		})
		.catch(err => {
			AppLogger.error(`changeLocale failed : ${err.messages}`, "changeLocale", {
				isoLngCode
			});
			dispatch(simpleActionCreators.getTranslationsFailure(isoLngCode));
			return;
		});
};

export const actions = {
	changeLocale,
	getTranslations
};

export const localeReducer = (state = initialState, action) => {
	switch (action.type) {
		case CHANGE_LOCALE:
			return {
				...state,
				locale: action.payload
			};
		case GET_TRANSLATIONS_REQUEST:
			return {
				...state,
				isFetching: true,
				requestedLang: action.payload,
				errorLoading: false
			};
		case GET_TRANSLATIONS_SUCCESS:
			return { ...state, isFetching: false, errorLoading: false };
		case GET_TRANSLATIONS_FAILURE:
			return {
				...state,
				isFetching: false,
				errorLoading: true,
				error: action.payload
			};
		default:
			return state;
	}
};

var map = {
	af: "moment/locale/af.js",
	"af.js": "moment/locale/af.js",
	ar: "moment/locale/ar.js",
	"ar-dz": "moment/locale/ar-dz.js",
	"ar-dz.js": "moment/locale/ar-dz.js",
	"ar-kw": "moment/locale/ar-kw.js",
	"ar-kw.js": "moment/locale/ar-kw.js",
	"ar-ly": "moment/locale/ar-ly.js",
	"ar-ly.js": "moment/locale/ar-ly.js",
	"ar-ma": "moment/locale/ar-ma.js",
	"ar-ma.js": "moment/locale/ar-ma.js",
	"ar-sa": "moment/locale/ar-sa.js",
	"ar-sa.js": "moment/locale/ar-sa.js",
	"ar-tn": "moment/locale/ar-tn.js",
	"ar-tn.js": "moment/locale/ar-tn.js",
	"ar.js": "moment/locale/ar.js",
	az: "moment/locale/az.js",
	"az.js": "moment/locale/az.js",
	be: "moment/locale/be.js",
	"be.js": "moment/locale/be.js",
	bg: "moment/locale/bg.js",
	"bg.js": "moment/locale/bg.js",
	bm: "moment/locale/bm.js",
	"bm.js": "moment/locale/bm.js",
	bn: "moment/locale/bn.js",
	"bn.js": "moment/locale/bn.js",
	bo: "moment/locale/bo.js",
	"bo.js": "moment/locale/bo.js",
	br: "moment/locale/br.js",
	"br.js": "moment/locale/br.js",
	bs: "moment/locale/bs.js",
	"bs.js": "moment/locale/bs.js",
	ca: "moment/locale/ca.js",
	"ca.js": "moment/locale/ca.js",
	cs: "moment/locale/cs.js",
	"cs.js": "moment/locale/cs.js",
	cv: "moment/locale/cv.js",
	"cv.js": "moment/locale/cv.js",
	cy: "moment/locale/cy.js",
	"cy.js": "moment/locale/cy.js",
	da: "moment/locale/da.js",
	"da.js": "moment/locale/da.js",
	de: "moment/locale/de.js",
	"de-at": "moment/locale/de-at.js",
	"de-at.js": "moment/locale/de-at.js",
	"de-ch": "moment/locale/de-ch.js",
	"de-ch.js": "moment/locale/de-ch.js",
	"de.js": "moment/locale/de.js",
	dv: "moment/locale/dv.js",
	"dv.js": "moment/locale/dv.js",
	el: "moment/locale/el.js",
	"el.js": "moment/locale/el.js",
	"en-SG": "moment/locale/en-SG.js",
	"en-SG.js": "moment/locale/en-SG.js",
	"en-au": "moment/locale/en-au.js",
	"en-au.js": "moment/locale/en-au.js",
	"en-ca": "moment/locale/en-ca.js",
	"en-ca.js": "moment/locale/en-ca.js",
	"en-gb": "moment/locale/en-gb.js",
	"en-gb.js": "moment/locale/en-gb.js",
	"en-ie": "moment/locale/en-ie.js",
	"en-ie.js": "moment/locale/en-ie.js",
	"en-il": "moment/locale/en-il.js",
	"en-il.js": "moment/locale/en-il.js",
	"en-nz": "moment/locale/en-nz.js",
	"en-nz.js": "moment/locale/en-nz.js",
	eo: "moment/locale/eo.js",
	"eo.js": "moment/locale/eo.js",
	es: "moment/locale/es.js",
	"es-do": "moment/locale/es-do.js",
	"es-do.js": "moment/locale/es-do.js",
	"es-us": "moment/locale/es-us.js",
	"es-us.js": "moment/locale/es-us.js",
	"es.js": "moment/locale/es.js",
	et: "moment/locale/et.js",
	"et.js": "moment/locale/et.js",
	eu: "moment/locale/eu.js",
	"eu.js": "moment/locale/eu.js",
	fa: "moment/locale/fa.js",
	"fa.js": "moment/locale/fa.js",
	fi: "moment/locale/fi.js",
	"fi.js": "moment/locale/fi.js",
	fo: "moment/locale/fo.js",
	"fo.js": "moment/locale/fo.js",
	fr: "moment/locale/fr.js",
	"fr-ca": "moment/locale/fr-ca.js",
	"fr-ca.js": "moment/locale/fr-ca.js",
	"fr-ch": "moment/locale/fr-ch.js",
	"fr-ch.js": "moment/locale/fr-ch.js",
	"fr.js": "moment/locale/fr.js",
	fy: "moment/locale/fy.js",
	"fy.js": "moment/locale/fy.js",
	ga: "moment/locale/ga.js",
	"ga.js": "moment/locale/ga.js",
	gd: "moment/locale/gd.js",
	"gd.js": "moment/locale/gd.js",
	gl: "moment/locale/gl.js",
	"gl.js": "moment/locale/gl.js",
	"gom-latn": "moment/locale/gom-latn.js",
	"gom-latn.js": "moment/locale/gom-latn.js",
	gu: "moment/locale/gu.js",
	"gu.js": "moment/locale/gu.js",
	he: "moment/locale/he.js",
	"he.js": "moment/locale/he.js",
	hi: "moment/locale/hi.js",
	"hi.js": "moment/locale/hi.js",
	hr: "moment/locale/hr.js",
	"hr.js": "moment/locale/hr.js",
	hu: "moment/locale/hu.js",
	"hu.js": "moment/locale/hu.js",
	"hy-am": "moment/locale/hy-am.js",
	"hy-am.js": "moment/locale/hy-am.js",
	id: "moment/locale/id.js",
	"id.js": "moment/locale/id.js",
	is: "moment/locale/is.js",
	"is.js": "moment/locale/is.js",
	it: "moment/locale/it.js",
	"it-ch": "moment/locale/it-ch.js",
	"it-ch.js": "moment/locale/it-ch.js",
	"it.js": "moment/locale/it.js",
	ja: "moment/locale/ja.js",
	"ja.js": "moment/locale/ja.js",
	jv: "moment/locale/jv.js",
	"jv.js": "moment/locale/jv.js",
	ka: "moment/locale/ka.js",
	"ka.js": "moment/locale/ka.js",
	kk: "moment/locale/kk.js",
	"kk.js": "moment/locale/kk.js",
	km: "moment/locale/km.js",
	"km.js": "moment/locale/km.js",
	kn: "moment/locale/kn.js",
	"kn.js": "moment/locale/kn.js",
	ko: "moment/locale/ko.js",
	"ko.js": "moment/locale/ko.js",
	ku: "moment/locale/ku.js",
	"ku.js": "moment/locale/ku.js",
	ky: "moment/locale/ky.js",
	"ky.js": "moment/locale/ky.js",
	lb: "moment/locale/lb.js",
	"lb.js": "moment/locale/lb.js",
	lo: "moment/locale/lo.js",
	"lo.js": "moment/locale/lo.js",
	lt: "moment/locale/lt.js",
	"lt.js": "moment/locale/lt.js",
	lv: "moment/locale/lv.js",
	"lv.js": "moment/locale/lv.js",
	me: "moment/locale/me.js",
	"me.js": "moment/locale/me.js",
	mi: "moment/locale/mi.js",
	"mi.js": "moment/locale/mi.js",
	mk: "moment/locale/mk.js",
	"mk.js": "moment/locale/mk.js",
	ml: "moment/locale/ml.js",
	"ml.js": "moment/locale/ml.js",
	mn: "moment/locale/mn.js",
	"mn.js": "moment/locale/mn.js",
	mr: "moment/locale/mr.js",
	"mr.js": "moment/locale/mr.js",
	ms: "moment/locale/ms.js",
	"ms-my": "moment/locale/ms-my.js",
	"ms-my.js": "moment/locale/ms-my.js",
	"ms.js": "moment/locale/ms.js",
	mt: "moment/locale/mt.js",
	"mt.js": "moment/locale/mt.js",
	my: "moment/locale/my.js",
	"my.js": "moment/locale/my.js",
	nb: "moment/locale/nb.js",
	"nb.js": "moment/locale/nb.js",
	ne: "moment/locale/ne.js",
	"ne.js": "moment/locale/ne.js",
	nl: "moment/locale/nl.js",
	"nl-be": "moment/locale/nl-be.js",
	"nl-be.js": "moment/locale/nl-be.js",
	"nl.js": "moment/locale/nl.js",
	nn: "moment/locale/nn.js",
	"nn.js": "moment/locale/nn.js",
	"pa-in": "moment/locale/pa-in.js",
	"pa-in.js": "moment/locale/pa-in.js",
	pl: "moment/locale/pl.js",
	"pl.js": "moment/locale/pl.js",
	pt: "moment/locale/pt.js",
	"pt-br": "moment/locale/pt-br.js",
	"pt-br.js": "moment/locale/pt-br.js",
	"pt.js": "moment/locale/pt.js",
	ro: "moment/locale/ro.js",
	"ro.js": "moment/locale/ro.js",
	ru: "moment/locale/ru.js",
	"ru.js": "moment/locale/ru.js",
	sd: "moment/locale/sd.js",
	"sd.js": "moment/locale/sd.js",
	se: "moment/locale/se.js",
	"se.js": "moment/locale/se.js",
	si: "moment/locale/si.js",
	"si.js": "moment/locale/si.js",
	sk: "moment/locale/sk.js",
	"sk.js": "moment/locale/sk.js",
	sl: "moment/locale/sl.js",
	"sl.js": "moment/locale/sl.js",
	sq: "moment/locale/sq.js",
	"sq.js": "moment/locale/sq.js",
	sr: "moment/locale/sr.js",
	"sr-cyrl": "moment/locale/sr-cyrl.js",
	"sr-cyrl.js": "moment/locale/sr-cyrl.js",
	"sr.js": "moment/locale/sr.js",
	ss: "moment/locale/ss.js",
	"ss.js": "moment/locale/ss.js",
	sv: "moment/locale/sv.js",
	"sv.js": "moment/locale/sv.js",
	sw: "moment/locale/sw.js",
	"sw.js": "moment/locale/sw.js",
	ta: "moment/locale/ta.js",
	"ta.js": "moment/locale/ta.js",
	te: "moment/locale/te.js",
	"te.js": "moment/locale/te.js",
	tet: "moment/locale/tet.js",
	"tet.js": "moment/locale/tet.js",
	tg: "moment/locale/tg.js",
	"tg.js": "moment/locale/tg.js",
	th: "moment/locale/th.js",
	"th.js": "moment/locale/th.js",
	"tl-ph": "moment/locale/tl-ph.js",
	"tl-ph.js": "moment/locale/tl-ph.js",
	tlh: "moment/locale/tlh.js",
	"tlh.js": "moment/locale/tlh.js",
	tr: "moment/locale/tr.js",
	"tr.js": "moment/locale/tr.js",
	tzl: "moment/locale/tzl.js",
	"tzl.js": "moment/locale/tzl.js",
	tzm: "moment/locale/tzm.js",
	"tzm-latn": "moment/locale/tzm-latn.js",
	"tzm-latn.js": "moment/locale/tzm-latn.js",
	"tzm.js": "moment/locale/tzm.js",
	"ug-cn": "moment/locale/ug-cn.js",
	"ug-cn.js": "moment/locale/ug-cn.js",
	uk: "moment/locale/uk.js",
	"uk.js": "moment/locale/uk.js",
	ur: "moment/locale/ur.js",
	"ur.js": "moment/locale/ur.js",
	uz: "moment/locale/uz.js",
	"uz-latn": "moment/locale/uz-latn.js",
	"uz-latn.js": "moment/locale/uz-latn.js",
	"uz.js": "moment/locale/uz.js",
	vi: "moment/locale/vi.js",
	"vi.js": "moment/locale/vi.js",
	"x-pseudo": "moment/locale/x-pseudo.js",
	"x-pseudo.js": "moment/locale/x-pseudo.js",
	yo: "moment/locale/yo.js",
	"yo.js": "moment/locale/yo.js",
	"zh-cn": "moment/locale/zh-cn.js",
	"zh-cn.js": "moment/locale/zh-cn.js",
	"zh-hk": "moment/locale/zh-hk.js",
	"zh-hk.js": "moment/locale/zh-hk.js",
	"zh-tw": "moment/locale/zh-tw.js",
	"zh-tw.js": "moment/locale/zh-tw.js"
};
