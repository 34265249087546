const FeaturesList = {
	BROWSE: "BROWSE",
	SEARCH: "SEARCH",
	EXPLORE: "EXPLORE",
	QUOTATIONS: "QUOTATIONS",
	VISUALSEARCH: "VISUALSEARCH",
	ISOMLEADTIMEORCHESTRATION: "ISOMLEADTIMEORCHESTRATION",
	ISOMWITHSERVICES: "ISOMWITHSERVICES",
	PRIMETIME: "PRIMETIME",
	DISCOUNT: "DISCOUNT",
	MERGE_TRANSPORT_ORDER: "MERGE_TRANSPORT_ORDER",
	IMPORT_ORDER: "IMPORT_ORDER"
};

export default FeaturesList;
