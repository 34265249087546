import { Specifications } from "@coworker/sharedlibrary";

const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
export const getCartItemQnty = (itemId, cartItems) => {
	var cartItem = cartItems && cartItems.find(x => x.itemId === itemId);

	if (cartItem !== undefined) return cartItem.quantity;

	return 0;
};

export const cleanUpInternationalPhoneNumber = value => {
	let phoneNumber = value.replace(/\s/g, "");
	phoneNumber = phoneNumber.replace(/-/g, "");

	return phoneNumber;
};

export const getInternationalMobileFormat = (mobilePhone, countryCode) => {
	try {
		if (mobilePhone && mobilePhone.includes("+")) {
			return mobilePhone;
		}
		var parsedNumber = phoneUtil.parse(mobilePhone, countryCode);
		var internationalNumber = cleanUpInternationalPhoneNumber(phoneUtil.formatOutOfCountryCallingNumber(parsedNumber));

		return internationalNumber;
	} catch (error) {
		return mobilePhone;
	}
};

export const isFamilyPrice = cartItem => {
	const totalPrice = cartItem.totalPrice;
	const familyPrice = cartItem.totalFamilyPrice;

	if (!familyPrice || !familyPrice.Value || isNaN(familyPrice.Value) || familyPrice.Value === 0 || totalPrice.Value === familyPrice.Value) {
		return false;
	}

	return true;
};
export const isArticleScan = text => {
	if (Specifications.isArticleLabel(text)) {
		return text.substring(3, 11);
	}
	if (!text.includes("-") && text.length === 14 && Specifications.isNumberSpec(text)) {
		if (["2", "9", "0"].indexOf(text[13]) >= 0) {
			return text.substring(0, 8);
		}
	}
	return undefined;
};
