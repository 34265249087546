import React from "react";
import styled from "styled-components";
import { device } from "@coworker/sharedlibrary";
import { getMessage } from "@coworker/sharedlibrary";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";

import SalesCoworkerConfiguration from "../../../store/salesCoworkerConfiguration";
import { ReactComponent as Delete } from "../../../static/delete.svg";
import { ReactComponent as Edit } from "../../../static/edit.svg";
import { ReactComponent as SelectAll } from "../../../static/checkmark.svg";
import { ReactComponent as Share } from "../../../static/share.svg";
import { selectAllitemsInreceipt } from "../../../store/cart/cart.thunks";
import { useCartModelPopup } from "./modelPopup";

const Container = styled.div`
	width: 175px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-right: 3px;
	@media ${device.small} {
		justify-content: space-around;
	}
`;

const Logo = styled.div`
	width: 16px;
	height: 16px;
	svg {
		width: 16px;
		height: 16px;
	}
	path {
		fill: ${props => (props.disabled ? "#f2f2f2" : "")};
	}
	cursor: ${props => (props.disabled ? "initial" : "pointer")};
`;

const CartEditSection = ({ onEditBag, canGroup, canDelete, canShare }) => {
	const { isTransportDeskMode, order } = useSelector(state => state.cartController);
	const { isCashLine } = SalesCoworkerConfiguration.getUserConfig();
	const { messages } = useSelector(state => state.intl);
	const canSelectAll = isTransportDeskMode && !order && !isCashLine;
	const { getCartModel } = useCartModelPopup();
	const dispatch = useDispatch();
	async function showBagDialog() {
		await getCartModel("newShoppingBag", { deleteAllItems: true });
	}
	async function openOrderConfirmationView() {
		dispatch(
			push(`/confirmation/order/${order.orderNo}`, {
				exitToHome: false
			})
		);
	}
	return (
		<Container>
			<Logo
				data-testid="logocanDelete"
				disabled={!canDelete}
				onClick={() => {
					if (canDelete) {
						showBagDialog();
					}
				}}
			>
				<Delete title={getMessage("SG_LBL_HintDelete", messages, "Delete all")}></Delete>
			</Logo>
			<Logo
				data-testid="logocanGroup"
				disabled={!canGroup}
				onClick={() => {
					if (canGroup) onEditBag && onEditBag();
				}}
			>
				<Edit title={getMessage("SG_LBL_HintEdit", messages, "Edit shopping bag")}></Edit>
			</Logo>
			<Logo
				data-testid="logocanShare"
				disabled={!canShare}
				onClick={() => {
					if (canShare) {
						openOrderConfirmationView();
					}
				}}
			>
				<Share title={getMessage("SG_LBL_HintShare", messages, "Share the order")}></Share>
			</Logo>
			{canSelectAll && (
				<Logo
					onClick={() => {
						dispatch(selectAllitemsInreceipt());
					}}
				>
					<SelectAll title={getMessage("SG_BTN_SelectAll", messages, "Select all")}></SelectAll>
				</Logo>
			)}
		</Container>
	);
};
export default CartEditSection;
