import styled from "styled-components";
import Button from "@ingka/button/Button";
import React from "react";
import AzureAuthService from "../service/azureAuthService";
import { RequestHelper } from "@coworker/sharedlibrary";
import { Grid } from "../components/sharedlibrary";

import { mediaQuery, colors } from "@coworker/sharedlibrary";
import SalesCoworkerConfiguration from "../store/salesCoworkerConfiguration";
//import Auth0Service from "../service/auth0Service";

const LoginButton = styled(Button)`
	width: 344px;
	color: ${colors.black} !important;
	background: ${colors.yellow} !important;
	@media ${mediaQuery.smallPortrait} {
		width: 250px;
	}
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: 120px;
	transform: translate(-50%, -50%);
	visibillity: ${props => props.$visibillity};
	.btn__inner--secondary {
		border: 0px;
	}
	.btn__inner {
		color: ${colors.black} !important;
		background: ${colors.yellow} !important;
	}
`;

const Login = ({ config, onLoginSuccess, autoLogin, visible = true }) => {
	const [processingLogin, setProcessingLogin] = React.useState(false);
	// let authService = new Auth0Service(config.authConfig);
	let authService = new AzureAuthService(config.authConfig);
	const performCustomMicrosoftLogin = React.useCallback(() => {
		var isUiTest = localStorage.getItem("UITEST") || "false";
		if (isUiTest === "true") {
			SalesCoworkerConfiguration.setIsExternal(true);
			SalesCoworkerConfiguration.setUserToken(RequestHelper.getToken());
			onLoginSuccess(RequestHelper.getLoggedInUser());
		} else {
			SalesCoworkerConfiguration.setIsExternal(false);
			authService.login();
		}
	}, [onLoginSuccess, config]);

	React.useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const code = urlParams.get("code");
		if (!processingLogin && autoLogin && !code) {
			performCustomMicrosoftLogin();
		}
	}, [processingLogin, autoLogin, performCustomMicrosoftLogin]);

	React.useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const code = urlParams.get("code");
		const stateparam = urlParams.get("state");
		if (code) {
			var { state, verifier } = JSON.parse(atob(stateparam));

			console.log(state);
			console.log(verifier);
			var referer = window.location.origin;
			window.history.replaceState("", document.title, state);

			setProcessingLogin(true);

			authService
				.getAccessToken(code, referer, verifier)
				.then(data => {
					if (data) {
						SalesCoworkerConfiguration.setUserToken(data.access_token);
						onLoginSuccess(RequestHelper.getLoggedInUser());
					}
				})
				.catch(() => {});
		}
	}, [config.authConfig, onLoginSuccess]);
	if (autoLogin) {
		return <div></div>;
	}
	return (
		<Grid>
			<LoginButton
				data-testid="login"
				disabled={processingLogin}
				visibillity={visible ? "visible" : "hidden"}
				type="secondary"
				text="Login"
				onClick={() => performCustomMicrosoftLogin()}
			/>
		</Grid>
	);
};

export default Login;
