import React from "react";
import { Container } from "./../../../../../components/sharedlibrary";
import { SummaryPriceControl } from "./../../../../../components/price/summaryPriceControl";

const OrderPriceSummaryTotalLayout = ({
	totalPrice,
	totalFamilyPrice,
	messages,
	shouldShowFamily,
	hasPaytoSpPrice,
	paytoSpPrice,
	TotalPriceStyle,
	TotalFamilyPriceStyle
}) => {
	return (
		<Container fdCol>
			<Container aiCenter margin="10px 0px" height="60px" width="100%">
				<SummaryPriceControl
					testid="totalpricelbl"
					container={TotalPriceStyle}
					price={totalPrice}
					bold={true}
					title={hasPaytoSpPrice ? messages.SG_LBL_PayToIkea || "Pay to Ikea:" : messages.SG_LBL_Total || "Total:"}
				/>
			</Container>
			{hasPaytoSpPrice && (
				<Container aiCenter margin="10px 0px" height="60px" width="100%">
					<SummaryPriceControl
						testid="totalpricelbl"
						container={TotalPriceStyle}
						price={paytoSpPrice}
						bold={true}
						title={messages.SG_LBL_PaytoServiceProvider || "Pay to Service Provider:"}
					/>
				</Container>
			)}
			{shouldShowFamily && (
				<Container aiCenter margin="10px 0px" height="40px" width="100%">
					<SummaryPriceControl
						testid="totalpricefammlbl"
						container={TotalFamilyPriceStyle}
						price={totalFamilyPrice}
						bold={true}
						title={messages.SG_LBL_TotalFamilyOrderPrice || "Total after Family discount"}
					/>
				</Container>
			)}
		</Container>
	);
};

export { OrderPriceSummaryTotalLayout };
