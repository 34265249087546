import { useSelector } from "react-redux";
import React from "react";
import styled from "styled-components";
import { getOrderAmountSummarySelector } from "./../../../../store/cart/cartSelectors";

import { OrderPriceSummaryLayout } from "./layout/orderPriceSummaryLayout";
import { OrderPriceSummaryTransportLayout } from "./layout/orderPriceSummaryTransportLayout";
import { OrderPriceSummaryTotalLayout } from "./layout/orderPriceSummaryTotalLayout";
import { OrderPriceSummaryTotalTransportLayout } from "./layout/orderPriceSummaryTotalTransportLayout";
import SalesCoworkerConfiguration from "../../../../store/salesCoworkerConfiguration";

const TotalPriceStyle = styled.div`
	font-size: 24px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #111111;
`;

const TotalFamilyPriceStyle = styled.div`
	font-size: 24px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #007dc7;
`;

const OrderPriceSummaryView = ({ showTotalSummaryOnly = false }) => {
	const { messages } = useSelector(state => state.intl);
	const { isTransportDeskMode } = useSelector(state => state.cartController);
	const { isCashLine } = SalesCoworkerConfiguration.getUserConfig();
	const { subtotalPrivatePrice, totalPrice, totalFamilyPrice, vat, savings, currency, servicePrice } = useSelector(state =>
		getOrderAmountSummarySelector(state)
	);

	let shouldShowFamily;
	if (totalPrice && totalFamilyPrice) {
		shouldShowFamily = totalFamilyPrice.Value !== totalPrice.Value;
	}

	let hasPaytoSpPrice = subtotalPrivatePrice && subtotalPrivatePrice.payToSpPrice && subtotalPrivatePrice.payToSpPrice.Value > 0;
	let paytoSpPrice = subtotalPrivatePrice && subtotalPrivatePrice.payToSpPrice;

	if (showTotalSummaryOnly) {
		return isTransportDeskMode && !isCashLine ? (
			<OrderPriceSummaryTotalTransportLayout {...{ subtotalPrivatePrice, messages, TotalPriceStyle, servicePrice }} />
		) : (
			<OrderPriceSummaryTotalLayout
				{...{
					subtotalPrivatePrice,
					totalPrice,
					totalFamilyPrice,
					isTransportDeskMode,
					messages,
					shouldShowFamily,
					hasPaytoSpPrice,
					paytoSpPrice,
					TotalPriceStyle,
					TotalFamilyPriceStyle
				}}
			/>
		);
	}

	return isTransportDeskMode && !isCashLine ? (
		<OrderPriceSummaryTransportLayout
			{...{
				subtotalPrivatePrice,
				savings,
				currency,
				messages,
				TotalPriceStyle,
				servicePrice
			}}
		/>
	) : (
		<OrderPriceSummaryLayout
			{...{
				subtotalPrivatePrice,
				totalPrice,
				totalFamilyPrice,
				vat,
				savings,
				currency,
				messages,
				shouldShowFamily,
				hasPaytoSpPrice,
				paytoSpPrice,
				TotalPriceStyle,
				TotalFamilyPriceStyle
			}}
		/>
	);
};

export { OrderPriceSummaryView };
