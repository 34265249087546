import { SHOW_WARNING, HIDE_WARNING, EXPAND_WARNING, DO_ACTION_WARNING } from "./warningActionTypes";

import { getMessage } from "@coworker/sharedlibrary";

export const warningActionCreators = {
	showWarning: ({ type, title, message, action, actionTitle }) => (dispatch, getState) => {
		const { isExpandAppWarning } = getState().warningController;
		if (isExpandAppWarning) {
			dispatch({ type: EXPAND_WARNING, payload: false });
		}

		dispatch({
			type: SHOW_WARNING,
			payload: {
				type: type,
				title: title,
				message: message,
				action: action,
				actionTitle: actionTitle
			}
		});
	},
	hideWarning: () => dispatch => {
		dispatch({ type: HIDE_WARNING, payload: {} });
	},
	expandWarning: isexpand => dispatch => {
		dispatch({
			type: EXPAND_WARNING,
			payload: isexpand
		});
	},

	doActionWarning: action => dispatch => {
		dispatch({
			type: DO_ACTION_WARNING,
			payload: action
		});
	}
};

export const warningUpdateStock = () => (dispatch, getState) => {
	const { messages } = getState().intl;
	dispatch(
		warningActionCreators.showWarning({
			type: WarningType.Warn,
			title: getMessage("SG_LBL_AvailabilityInfoIsOutdated", messages, "The stock information was updated 10 minutes ago and is not relevant anymore"),
			action: WarningAction.UpdateStock,
			actionTitle: WarningActionTitle.Update
		})
	);
};

export const warningDone = () => async dispatch => {
	dispatch(warningActionCreators.hideWarning());
};

export const WarningType = {
	Error: "error",
	Warn: "warn",
	Success: "success"
};

export const WarningAction = {
	UpdateStock: "updateStock"
};

export const WarningActionTitle = {
	Update: "Update"
};
