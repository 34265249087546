import { Container, Label } from "../../../components/sharedlibrary";
import { colors, eventsToDispatch } from "@coworker/sharedlibrary";
import { useDispatch, useSelector } from "react-redux";

import ArticleBarCodeScanner from "./articleBarCodeScanner";
import React from "react";
import SSRIcon from "@ingka/ssr-icon";
import { appactionCreators } from "./../../../store/shell/shellAction";
import { changeQuantity } from "../../../store/cart/cartActions";
import cross from "@ingka/ssr-icon/paths/close-small";
import { getStoreConfigSelector } from "../../../utils/shellObjectService";
import { isArticleScan } from "../../../helpers/cartHelper";

const ScanArticlesNotifier = () => {
	const dispatch = useDispatch();
	const config = getStoreConfigSelector();
	const { order, items: cartItems, isTransportDeskMode } = useSelector(state => state.cartController);
	const { messages } = useSelector(state => state.intl);
	const [hideNotiifer, setHideNotifier] = React.useState(false);
	const [currentScanStatus, setCurrentScanStatus] = React.useState("");
	const [scannedItem, setScannedItem] = React.useState("");
	const [invalidScannedItem, setInValidScannedItem] = React.useState("");
	function checkItemExistinRecipts(item) {
		let itemExit = cartItems.find(x => x.itemId === item);
		return itemExit !== undefined;
	}
	function onChangeQuantity(item) {
		let existingItem = cartItems.find(x => x.itemId === item);
		let cartItem = cartItems.find(x => x.itemId === item && x.quantity !== x.importDetails.originalQuantity);
		if (cartItem && cartItem.importDetails) {
			let maxQuantity = parseInt(cartItem.importDetails.originalQuantity);
			let quantity = parseInt(cartItem.quantity + 1);
			if (quantity > maxQuantity) {
				quantity = maxQuantity;
			}
			dispatch(changeQuantity(cartItem.id, quantity));
		}
		if (existingItem && !cartItem) {
			dispatch(
				appactionCreators.showNotification(
					"error",
					messages.SG_LBL_MaxQuantityInReceipt || "Attention ! The item scanned total quantity is higher than in receipt"
				)
			);
			setInValidScannedItem(messages.SG_LBL_MaxQuantityInReceipt || "Attention ! The item scanned total quantity is higher than in receipt");
		}
	}
	React.useEffect(() => {
		let checkZeroQuantity = cartItems.filter(x => x.quantity === 0);
		if (checkZeroQuantity.length > 0) {
			setCurrentScanStatus(messages.SG_LBL_ScanPackagesMessage || "Scan  packages that have been collected from the customer");
		} else {
			setCurrentScanStatus(messages.SG_LBL_ScanPackagesCompletedMessage || "Scan completed , Please book a delivery to complete");
		}
	}, [cartItems, messages]);
	if (!isTransportDeskMode) {
		return "";
	}
	if (hideNotiifer) {
		return "";
	}
	if (order && order.isCompleted) {
		return "";
	}
	return (
		<Container fdCol jcSBetween>
			<div>
				<ArticleBarCodeScanner
					onScan={e => {
						let article = isArticleScan(e);
						if (article) {
							console.log("ARTICLE SCAN Detected", article);

							if (checkItemExistinRecipts(article)) {
								setScannedItem(article);
								onChangeQuantity(article);
							} else {
								scrollToMain();
								if (config.isCashLine) {
									dispatchEvent(eventsToDispatch.ADD_ITEM_TO_CART, {
										retailItem: {
											ItemId: article,
											ItemType: "ART"
										},
										qty: 1
									});
								} else {
									setInValidScannedItem(messages.SG_LBL_ScanItemNotInReciept || "Scanned item which is not in receipt");
								}
							}
						}
					}}
				></ArticleBarCodeScanner>
			</div>

			<Container
				styles={{
					minHeight: "100px",
					padding: "16px",
					flexDirection: "column",
					display: "flex",
					justifyContent: "space-between",
					margin: "10px 0px",
					custom: `box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1); border-radius: 5px;  border-left: 2px solid ${colors.appColor};`
				}}
			>
				<Container jcSBetween>
					<Container fdCol width="80%">
						<Label fontsize={"14px"} fontweight={"bold"} width={"auto"}>
							{messages.SG_LBL_ScanPackages || "Scan packages"}
						</Label>
						<Label margintop={"10px"} fontsize={"12px"} fontweight={"normal"} width={"auto"}>
							{currentScanStatus}
						</Label>
						{scannedItem !== "" && (
							<Label width={"auto"} color={`${colors.success}`} margintop={"5px"} fontsize={"12px"} fontweight={"normal"}>{`${
								messages.SG_LBL_LastScanItem || "last scanned item"
							} ${scannedItem}`}</Label>
						)}
						{invalidScannedItem !== "" && (
							<Label width={"auto"} color={`${colors.error}`} margintop={"5px"} fontsize={"12px"} fontweight={"normal"}>
								{invalidScannedItem}
							</Label>
						)}
					</Container>
					<Container
						styles={{
							justifyContent: "flex-end",
							width: "50px"
						}}
					>
						<SSRIcon
							paths={cross}
							onClick={() => {
								setHideNotifier(true);
							}}
						/>
					</Container>
				</Container>
			</Container>
		</Container>
	);
};
export default ScanArticlesNotifier;

const scrollToMain = () => {
	try {
		window.scroll({ top: 0, left: 0, behavior: "smooth" });
		var maindiv = document.getElementsByClassName("routeArea")[0];
		if (maindiv) maindiv.scroll({ top: 0, left: 0, behavior: "smooth" });
	} catch (error) {
		//
	}
};
