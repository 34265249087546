import PropTypes from "prop-types";
export default class HomeUser {
	constructor(userid, buCode, primaryLocale, supportedLocales, links, name) {
		this.userId = userid;
		this.buCode = buCode;
		this.primaryLocale = primaryLocale;
		this.supportedLocales = supportedLocales;
		this.links = links;
		this.name = name;
	}
	static propTypes = {
		userId: PropTypes.string.isRequired,
		buCode: PropTypes.string.isRequired,
		primaryLocale: PropTypes.string.isRequired,
		supportedLocales: PropTypes.arrayOf(PropTypes.instanceOf(PropTypes.string)),
		links: PropTypes.array
	};
}
