import APIEndpoints from "@coworker/sharedlibrary/src/constants/apiEndpoints";
import AppLogger from "@coworker/sharedlibrary/src/logger/appLogger";
import httpService from "@coworker/sharedlibrary/src/service/httpService";

const UserHistoryService = {
	get: async (bu = "", headers) => {
		try {
			const response = await httpService.get(`${APIEndpoints.userHistory}/${bu}`, {
				headers: headers
			});
			if (response) {
				const history = response.history;

				return {
					mostAddedProducts: history.mostAddedProducts,
					searchHistory: history.searchHistory
				};
			}
		} catch (error) {
			return {
				mostAddedProducts: {},
				searchHistory: {}
			};
		}
	},
	set: async (history, bu = "", headers) => {
		try {
			await httpService.post(`${APIEndpoints.userHistory}/${bu}`, {
				body: {
					history: history
				},
				headers: headers
			});
		} catch (error) {
			AppLogger.error("Failed to update user history", "UserHistoryService", history);
		}
	},

	saveSearchTerm: async (term, bu = "", headers) => {
		try {
			await httpService.post(`${APIEndpoints.userHistoryV1}/${bu}`, {
				body: {
					history: {
						term: term
					}
				},
				headers: headers
			});
		} catch (error) {
			AppLogger.error("Failed to update user history", "UserHistoryService", history);
		}
	},
	saveProductTerm: async (productId, bu = "", headers) => {
		try {
			await httpService.post(`${APIEndpoints.userHistoryV1}/${bu}`, {
				body: {
					history: {
						product: productId
					}
				},
				headers: headers
			});
		} catch (error) {
			AppLogger.error("Failed to update user history", "UserHistoryService", history);
		}
	}
};

export default UserHistoryService;
