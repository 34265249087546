import React from "react";
import { Container } from "./../../../../../components/sharedlibrary";
import { SummaryPriceControl } from "./../../../../../components/price/summaryPriceControl";

const OrderPriceSummaryLayout = ({
	subtotalPrivatePrice,
	totalPrice,
	totalFamilyPrice,
	vat,
	savings,
	currency,
	messages,
	shouldShowFamily,
	hasPaytoSpPrice,
	paytoSpPrice,
	TotalPriceStyle,
	TotalFamilyPriceStyle
}) => {
	return (
		<Container fdCol>
			{subtotalPrivatePrice && subtotalPrivatePrice.product && (
				<SummaryPriceControl price={subtotalPrivatePrice.product} title={messages.SG_LBL_ItemsTotal || "Items total"} />
			)}
			{subtotalPrivatePrice && subtotalPrivatePrice.delivery && (
				<SummaryPriceControl price={subtotalPrivatePrice.delivery} title={messages.SG_LBL_Delivery || "Delivery"} />
			)}
			{subtotalPrivatePrice && subtotalPrivatePrice.service && (
				<SummaryPriceControl price={subtotalPrivatePrice.service} title={messages.SG_LBL_Services || "Services"} />
			)}
			{savings && savings.employee && (
				<SummaryPriceControl
					prefix={"-"}
					price={{
						Value: -1 * savings.employee,
						Currency: currency
					}}
					title={messages.SG_LBL_CoWorkerDiscount || "Co-Worker Discount"}
					minimumFractionDigits={2}
				/>
			)}
			{savings && savings.manual && (
				<SummaryPriceControl
					prefix={"-"}
					price={{
						Value: -1 * savings.manual,
						Currency: currency
					}}
					title={messages.SG_LBL_ManualDiscount || "Manual Discount"}
					minimumFractionDigits={2}
				/>
			)}
			{savings && savings.coupons && (
				<SummaryPriceControl
					prefix={"-"}
					price={{
						Value: -1 * savings.coupons,
						Currency: currency
					}}
					title={messages.SG_LBL_CouponDiscount || "Coupon Discount"}
					minimumFractionDigits={2}
				/>
			)}
			{savings && savings.voucher && (
				<SummaryPriceControl
					prefix={"-"}
					price={{
						Value: -1 * savings.voucher,
						Currency: currency
					}}
					title={messages.SG_LBL_VoucherDiscount || "Voucher Discount"}
					minimumFractionDigits={2}
				/>
			)}
			{savings && savings.family && (
				<SummaryPriceControl
					price={{
						Value: -1 * savings.family,
						Currency: currency
					}}
					prefix={"-"}
					title={messages.SG_LBL_FamilySavings || "Family Savings"}
					minimumFractionDigits={2}
				/>
			)}
			{savings && savings.discounts && (
				<SummaryPriceControl
					price={{
						Value: -1 * savings.discounts,
						Currency: currency
					}}
					prefix={"-"}
					title={messages.SG_LBL_DiscountsAndPromotions || "Discounts & Promotions"}
					minimumFractionDigits={2}
				/>
			)}
			{vat && <SummaryPriceControl price={vat} title={messages.SG_LBL_VAT || "VAT"} minimumFractionDigits={2} />}
			<Container
				styles={{
					margin: "10px 0px",
					alignItems: "center",
					height: "100px",
					width: "100%",
					custom: "border-top: 1px solid #111111; border-bottom: 1px solid #111111;"
				}}
			>
				<SummaryPriceControl
					testid="totalpricelbl"
					container={TotalPriceStyle}
					price={totalPrice}
					bold={true}
					title={hasPaytoSpPrice ? messages.SG_LBL_PayToIkea || "Pay to Ikea:" : messages.SG_LBL_Total || "Total:"}
				/>
			</Container>
			{hasPaytoSpPrice && (
				<Container
					styles={{
						margin: "10px 0px",
						alignItems: "center",
						height: "60px",
						width: "100%"
					}}
				>
					<SummaryPriceControl
						testid="totalpricelbl"
						container={TotalPriceStyle}
						price={paytoSpPrice}
						bold={true}
						title={messages.SG_LBL_PaytoServiceProvider || "Pay to Service Provider:"}
					/>
				</Container>
			)}
			{shouldShowFamily && (
				<Container
					styles={{
						margin: "10px 0px",
						alignItems: "center",
						height: "50px",
						width: "100%"
					}}
				>
					<SummaryPriceControl
						testid="totalpricefammlbl"
						container={TotalFamilyPriceStyle}
						price={totalFamilyPrice}
						bold={true}
						title={messages.SG_LBL_TotalFamilyOrderPrice || "Total after Family discount"}
					/>
				</Container>
			)}
		</Container>
	);
};

export { OrderPriceSummaryLayout };
