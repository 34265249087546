import { device } from "@coworker/sharedlibrary";
import { useSelector } from "react-redux";
import { getMessage } from "@coworker/sharedlibrary";
import React from "react";
import styled from "styled-components";
const Container = styled.div`
  display: flex;
  min-height: 32px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media ${device.small} {
    flex-direction: column;
    align-items: flex-start;
    width:175px;
  }

}
`;

const ItemsPackageSummary = styled.article`
	margin-bottom: 10px;
	margin-top: 10px;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #111111;
	span {
		padding-right: 8px;
	}
`;

const CartPackageSummary = () => {
	const { items, totalFullWeight, totalPackages } = useSelector(state => state.cartController);
	const { messages } = useSelector(state => state.intl);
	let packageLabel = getMessage("SG_LBL_Packages", messages, "Packages:").replace(" ", "");
	let weightLabel = getMessage("SG_LBL_TotalWeight", messages, "Total Weight:");
	return (
		<Container>
			{items && items.length > 0 && (
				<>
					<ItemsPackageSummary>
						<p>
							<span>{`${packageLabel}:\xA0 ${totalPackages || ""}`}</span>
							{totalFullWeight && (
								<span>
									{` ${weightLabel}:\xA0 ${totalFullWeight.metric ? totalFullWeight.metric + " kg" : ""}`}
									{totalFullWeight.metric && totalFullWeight.imperial ? " - " : ""}
									{`${totalFullWeight.imperial ? totalFullWeight.imperial + " lb" : ""}`}{" "}
								</span>
							)}
						</p>
					</ItemsPackageSummary>
				</>
			)}
		</Container>
	);
};

export default CartPackageSummary;
