import React from "react";
const MorphingChair = props => {
	return (
		<div
			className={"chairLoading " + (props.showLoading === true ? "show" : "hide")}
			style={{
				top: props.history.location.pathname.includes("/cart") === false ? "70px" : "0"
			}}
		/>
	);
};
export default MorphingChair;
