import { CONVERSION_RATE_KG_TO_LB } from "../constants/conversionRates";
import Enumerable from "linq";
import { ToFirstUpper } from "@coworker/sharedlibrary/src/helpers/objectHelper";
import getMessage from "@coworker/sharedlibrary/src/helpers/translationHelper";

const NORMAL = "NORMAL";

const PackagePriceSupportedCountries = ["DE", "FR", "GB", "IE", "NO", "AU", "AT", "FI", "PL", "PT", "RS", "BE", "IT", "ES"];
const UnitPriceFactorEnum = {
	NONE: "NONE",
	AREA: "AREA",
	MULTIPACK: "MULTIPACK",
	WEIGHT_AND_VOLUME: "WEIGHT_AND_VOLUME"
};
const canShowPackagePrice = (priceFactor, locale) => {
	try {
		let countryCode = locale.toString().split("_")[1];
		if (PackagePriceSupportedCountries.some(x => x === countryCode.toUpperCase() && priceFactor.UnitPriceGroupCode !== UnitPriceFactorEnum.MULTIPACK)) {
			return true;
		}
		return false;
	} catch {
		return false;
	}
};
const getPackageMeasureList = pkgMeasures => {
	let measuresList = [];

	const metricSizeList = getMeasures(pkgMeasures, ["WIDTH", "HEIGHT", "LENGTH", "DIAMETER"], true);
	const imperalSizeList = getMeasures(pkgMeasures, ["WIDTH", "HEIGHT", "LENGTH", "DIAMETER"], false);
	const metricWeightList = getMeasures(pkgMeasures, ["WEIGHT"], true);
	const imperalWeightList = getMeasures(pkgMeasures, ["WEIGHT"], false);

	if (metricSizeList.length) {
		measuresList.push(`${metricSizeList.join("x")} cm`);
	}

	if (imperalSizeList.length) {
		measuresList.push(`(${imperalSizeList.join('"x')}")`);
	}

	if (metricWeightList.length) {
		measuresList.push(`${metricWeightList[0]} kg`);
	}

	if (imperalWeightList.length) {
		measuresList.push(`${imperalWeightList[0]} lb`);
	}

	return measuresList;
};

const getMeasures = (measures, types = [], isMetric) => {
	let measuresToReturn = [];

	types.map(type => {
		let measure = Enumerable.from(measures).firstOrDefault(x => x.PackageMeasureType === type);

		if (measure) {
			if (isMetric && measure.PackageMeasureMetric) {
				measuresToReturn.push(measure.PackageMeasureMetric.Value);
			} else if (measure.PackageMeasureImperial) {
				measuresToReturn.push(measure.PackageMeasureImperial.Value);
			}
		}
		return measure;
	});

	return measuresToReturn;
};

const SelectionCriteriaBuilder = {
	getSelectionCriterias: (retailItems, item) => {
		let selectionCriterias = Enumerable.from(item.SelectionCriteria)
			.groupBy(crit => crit.Code)
			.select(function (critGroup) {
				return {
					Code: critGroup.first().Code,
					Name: critGroup.first().Name,
					Values: critGroup.select(crt => crt.Value).distinct()
				};
			})
			.orderBy(crit => crit.Name)
			.toArray();

		selectionCriterias.forEach(criteria => {
			let selectedValue = item.SelectionCriteria.find(crit => crit.Code === criteria.Code);
			if (selectedValue) {
				criteria.SelectedValue = ToFirstUpper(selectedValue.Value);
			}
		});

		let retailItemsEnumerable = Enumerable.from(retailItems);
		let items = retailItemsEnumerable
			.where(function (criteria) {
				return criteria.SelectionCriteria !== undefined;
			})
			.selectMany(x => x.SelectionCriteria)
			.toArray();
		let updatedSelectionCriterias = Enumerable.from(items)
			.groupBy(x => x.Code)
			.select(function (z) {
				return {
					Code: z.key(),
					Values: z
						.select(w => w.Value)
						.distinct()
						.toArray(),
					SelectedValue: "",
					Name: z.firstOrDefault().Name
				};
			})
			.toArray();

		updatedSelectionCriterias.forEach(criteria => {
			let selectedValue = Enumerable.from(item.SelectionCriteria).firstOrDefault(crit => crit.Code === criteria.Code);

			if (selectedValue !== undefined) {
				criteria.SelectedValue = selectedValue.Value; // selectedValue.Value === "-" ? "None" :
			}
		});
		let enumerates = Enumerable.from(updatedSelectionCriterias);
		updatedSelectionCriterias.forEach(criteria => {
			let otherCriterias = enumerates
				.where(function (crt) {
					return crt !== criteria;
				})
				.toArray();
			let itemsMatching = retailItemsEnumerable
				.where(function (item) {
					return Enumerable.from(otherCriterias).all(function (selCrit) {
						return Enumerable.from(item.SelectionCriteria).any(function (itemCrit) {
							return itemCrit.Code === selCrit.Code && itemCrit.Value === selCrit.SelectedValue;
						});
					});
				})
				.toArray();

			let filteredValues = Enumerable.from(itemsMatching)
				.selectMany(function (item) {
					let enumSele = Enumerable.from(item.SelectionCriteria);
					let filters = enumSele
						.where(function (itemCrit) {
							return itemCrit.Code === criteria.Code;
						})
						.select(function (crit) {
							return crit.Value;
						})
						.distinct()
						.where(value => value !== undefined && value !== null)
						.toArray();
					return filters;
				})
				.toArray();
			criteria.Values = filteredValues;
		});
		let results = [];
		updatedSelectionCriterias.forEach(criteria => {
			let existingCriteria = selectionCriterias.find(crit => crit.Code === criteria.Code);
			if (existingCriteria) {
				let selectedValue = existingCriteria.SelectedValue;
				existingCriteria.Values = criteria.Values || [];
				existingCriteria.SelectedValue = ""; // HACK: Due to problem with databinding
				existingCriteria.SelectedValue = selectedValue;
			}
		});

		updatedSelectionCriterias.forEach(upitem => {
			let values = [];
			values.push(upitem.SelectedValue);
			let existing = upitem.Values;
			existing.forEach(val => {
				if (!values.includes(val)) {
					values.push(val);
				}
			});

			upitem.Values = values;
			results.push(upitem);
		});

		return results;
	},
	getSelectedItem: (retailItems, gprs) => {
		let enumerableItems = Enumerable.from(retailItems);
		let allGprs = Enumerable.from(gprs);
		let newItem = enumerableItems.firstOrDefault(function (item) {
			return allGprs.all(function (selcCrit) {
				return Enumerable.from(item.SelectionCriteria).any(function (itemCrit) {
					return itemCrit.Code === selcCrit.Code && itemCrit.Value === selcCrit.SelectedValue;
				});
			});
		});
		return newItem;
	}
};

export const getPrice = item => {
	if (item.price !== undefined && item.price !== null) {
		return item.price.Value;
	} else {
		return 0;
	}
};

export const getFamilyPrice = item => {
	if (item.familyPrice !== undefined) {
		return item.familyPrice.Value;
	} else {
		return 0;
	}
};

export const getPriceUnit = (price, priceFactor, isMetric) => {
	try {
		let unit = "";
		if (!price) {
			return unit;
		}
		if (!priceFactor) {
			return unit;
		}

		if (priceFactor.UnitPriceGroupCode !== undefined && priceFactor.UnitPriceGroupCode === UnitPriceFactorEnum.AREA) {
			unit = isMetric ? priceFactor.UnitTextMetric : priceFactor.UnitTextImperial;
		} else {
			if (isMetric) {
				unit =
					priceFactor.UnitPriceFactorMetric > 0 ? priceFactor.UnitPriceFactorMetric + " " + priceFactor.UnitTextMetric : priceFactor.UnitTextMetric;
			} else {
				unit =
					priceFactor.UnitPriceFactorImperial > 0
						? priceFactor.UnitPriceFactorImperial + " " + priceFactor.UnitTextMetric || ""
						: priceFactor.UnitTextMetric || "";
			}
		}

		return unit && unit !== "" ? "/" + unit : "";
	} catch (error) {
		return "";
	}
};

export const getUnitPrice = (price, priceFactor, isMetric) => {
	try {
		if (!price) {
			return null;
		}
		if (!priceFactor) {
			return price;
		}
		let unit = getPriceUnit(price, priceFactor, isMetric);
		if (priceFactor.UnitPriceGroupCode !== undefined && priceFactor.UnitPriceGroupCode !== UnitPriceFactorEnum.AREA) {
			let newPrice = { ...price };
			newPrice.Unit = unit;
			return newPrice;
		}
		if (priceFactor.IsMultiPack) {
			let newPrice = { ...price };
			newPrice.Unit = "";
			return newPrice;
		}

		if (!priceFactor.UnitPriceGroupCode) {
			let newPrice = { ...price };
			newPrice.Unit = "";
			return newPrice;
		}

		let amount = price.Value / (isMetric ? priceFactor.UnitPriceFactorMetric : priceFactor.UnitPriceFactorImperial);

		let newUnitPrice = { ...price };
		newUnitPrice.Unit = unit;
		newUnitPrice.Value = amount;
		return newUnitPrice;
	} catch (error) {
		//
	}
};

export const getUnitPreOfferPrice = (price, priceFactor, isMetric) => {
	try {
		let newPrice;
		if (!price) {
			return null;
		}

		if (!priceFactor) {
			newPrice = { ...price };
			newPrice.Value = price.OriginalValue;

			newPrice.Unit = "";
			return newPrice;
		}
		let unit = getPriceUnit(price, priceFactor, isMetric);
		if (priceFactor.UnitPriceGroupCode !== undefined && priceFactor.UnitPriceGroupCode !== UnitPriceFactorEnum.AREA) {
			let newareaPrice = { ...price };
			newareaPrice.Value = price.OriginalValue;
			newareaPrice.Unit = unit;
			return newareaPrice;
		}
		if (priceFactor.IsMultiPack) {
			newPrice = { ...price };
			newPrice.Value = price.OriginalValue;
			newPrice.Unit = price.UnitTextMetric;
			return newPrice;
		}
		let amount = price.OriginalValue / (isMetric ? priceFactor.UnitPriceFactorMetric : priceFactor.UnitPriceFactorImperial);

		let pricePerUnit = { ...price };
		pricePerUnit.Value = amount;

		pricePerUnit.Unit = unit;
		return pricePerUnit;
	} catch (error) {
		return null;
	}
};

export const getCurrency = item => {
	if (item.price !== undefined) {
		return item.price.Currency;
	} else {
		return "";
	}
};

export const GetPriceOfferDuration = (retailItem, type, offer) => {
	try {
		let familyPrice = getWebprice(retailItem, type);

		if (familyPrice) {
			let offerDuration = "";
			//let format = OfferDateFormat(countryCode);
			if (familyPrice.PriceReasonCode === offer) {
				let fromDate = new Date(familyPrice.FromDate);
				let toDate = new Date(familyPrice.ToDate);

				if (toDate >= fromDate) {
					offerDuration = familyPrice.FromDate + " - " + familyPrice.ToDate + " , ";
				}
			}
			return offerDuration;
		}
	} catch (error) {
		//
	}
	return "";
};

export const getPackageUnitPrice = (price, priceFactor, isMetric, locale) => {
	try {
		if (!price) {
			return null;
		}

		if (!priceFactor) {
			return price;
		}

		if (priceFactor.UnitPriceGroupCode === UnitPriceFactorEnum.AREA) {
			return price;
		}

		if (!canShowPackagePrice(priceFactor, locale)) {
			return null;
		}
		if (isNaN(price.Amount)) {
			return null;
		}
		let amount =
			price.Amount /
			(isMetric
				? priceFactor.UnitPriceFactorMetric > 0
					? priceFactor.UnitPriceFactorMetric
					: 1
				: priceFactor.UnitPriceFactorImperial > 0
				? priceFactor.UnitPriceFactorImperial
				: 1);
		let pricePerUnit = { ...price };
		pricePerUnit.Value = amount;
		return pricePerUnit;
	} catch (error) {
		return null;
	}
};

export const getPackageUnit = (price, priceFactor, messages, isMetric, locale) => {
	try {
		let unit = "";
		if (price === null) {
			return unit;
		}
		if (!priceFactor) {
			return unit;
		}

		if (priceFactor.UnitPriceGroupCode === UnitPriceFactorEnum.AREA) {
			unit = getMessage("SG_LBL_Package", messages, "Package");
		} else {
			if (priceFactor.IsMultiPack) {
				unit = "";
			} else {
				unit = canShowPackagePrice(priceFactor, locale)
					? isMetric
						? `${priceFactor.UnitTextMetric || ""}`
						: `${priceFactor.UnitTextImperial || ""}`
					: "";
			}
		}

		return unit && unit !== "" ? `${getMessage("SG_LBL_Price", messages, "Price")}/${unit}` : "";
	} catch (error) {
		return "";
	}
};

export const getWebprice = (item, type) => {
	if (item.RetailUnitPrices) {
		let price = item.RetailUnitPrices.find(stock => stock.Type === type);
		return price;
	}
	return null;
};

export const getDescription = (item, isMetric) => {
	if (item === undefined) return "";

	let infos = [];

	let productType = item.ProductType;

	if (productType !== undefined) {
		let combined = productType;
		infos.push(combined);
	}

	let color = item.ColourDescription || item.Colour;
	if (color === undefined || color === "") {
		let coolrfilter = Enumerable.from(item.SelectionCriteria).firstOrDefault(x => x.Code === "COLOUR");
		if (coolrfilter) {
			color = coolrfilter.Value;
		}
	}
	if (color !== undefined && color !== "-") {
		let combined = "";
		combined = combined.concat(",", "\xA0", color);
		infos.push(combined);
	}

	let measurement = "";
	if (isMetric) {
		measurement = item.ItemMeasureReferenceTextMetric;
	} else {
		measurement = item.ItemMeasureReferenceTextImperial;
	}

	if (!isMetric && item.ItemMeasureReferenceTextImperial && item.ItemMeasureReferenceTextMetric) {
		measurement = `${item.ItemMeasureReferenceTextImperial} (${item.ItemMeasureReferenceTextMetric})`;
	}

	if (measurement !== undefined) {
		let combined = "";
		combined = combined.concat(",", "\xA0", measurement);
		infos.push(combined);
	}

	let joinedInfo = infos.join("");
	return joinedInfo;
};

export const getPackageWeight = (item, byUnit) => {
	if (item) {
		let packageWeight = item.PackageWeight;

		if (byUnit === "IMPERIAL") {
			return packageWeight * CONVERSION_RATE_KG_TO_LB;
		}
		return packageWeight;
	}
	return 0;
};

export const getPackageWeightUnit = item => {
	if (item.IsMetric) {
		return "kg";
	} else {
		return "lb";
	}
};

export const getPackages = item => {
	return item.NumberOfPackages || 0;
};

export const getStock = item => {
	if (item.storeInfo && item.storeInfo.StockAvailabilities) {
		return item.storeInfo.StockAvailabilities.AvailableStockToSell;
	}
};

export const getStoreprice = (item, type) => {
	if (item.StorePrices) {
		let price = item.StorePrices.find(stock => stock.Type === type);
		return price;
	}
};

export const isInStock = item => {
	if (item.storeInfo && item.storeInfo.StockAvailabilities) {
		return item.storeInfo.StockAvailabilities.AvailableStockToSell > 0;
	}
	return false;
};

export const getPriceFilteredItemList = (retailItems, priceRange) => {
	const isPriceRangeFilterd = priceRange && !isNaN(priceRange[0]) && !isNaN(priceRange[1]);
	const listItems = isPriceRangeFilterd
		? retailItems.filter(item => {
				return getPrice(item) >= parseInt(priceRange[0]) && getPrice(item) <= parseInt(priceRange[1]);
		  })
		: retailItems;
	return listItems;
};

export const getPackageList = item => {
	if (item && item.Description) {
		let packages = Enumerable.from(item.Description.PackageMeasures)
			.orderBy(x => x.ConsumerPackNumber)
			.groupBy(y => y.ConsumerPackNumber)
			.select(function (pkg) {
				return {
					key: pkg.key() === undefined ? "1" : pkg.key(),
					measureTexts: getPackageMeasureList(pkg.toArray())
				};
			})
			.toArray();
		return packages;
	}
	return [];
};

export const toItemFormatId = item => {
	if (item === undefined) return "";
	if (item.length < 8) {
		return item;
	}
	return item.substring(0, 3) + "." + item.substring(3, 6) + "." + item.substring(6);
};

export const buildCatalogRetailItem = (element, isMetric, locale) => {
	let citem = {};
	if (element.props) {
		citem = { ...element.props };
	} else {
		citem = { ...element };
	}
	let retailItem = mapRetailItem({ ...citem }, isMetric, locale);

	const item = Object.assign(retailItem, {});
	return item;
};

const mapRetailItem = (element, isMetric, locale) => {
	let item = { ...element };
	let hasLowPrice = GetPriceOfferDuration(item, "NORMAL", "NEW_LOWER_PRICE");

	let hasrestrictedOffer = GetPriceOfferDuration(item, "NORMAL", "TIMERESTRICTEDOFFER");
	item.key = `$1${item.ItemType}${item.ItemId}`;
	item.isNew = item.NewsType ? true : false;
	item.priceFactor = item.UnitPriceFactor;
	item.price = item.storeInfo ? getStoreprice(item.storeInfo, "NORMAL") : getWebprice(item, "NORMAL");
	item.familyPrice = item.storeInfo ? getStoreprice(item.storeInfo, "FAMILY") : getWebprice(item, "FAMILY");

	item.webprice = getWebprice(item, "NORMAL");
	item.webfamilyPrice = getWebprice(item, "FAMILY");
	item.isMetric = element.IsMetric || isMetric;
	item.itemDescription = getDescription(item, isMetric);
	item.isFoodItem = isFoodItemSpec(item.storeInfo);
	item.isPieces = item.ItemUnitCode === "PIECES" || item.ItemUnitCode === "PIECE";
	item.hasLowPrice = hasLowPrice !== undefined && hasLowPrice !== "";
	item.locale = locale;
	item.hasRestrictedOffer = hasrestrictedOffer && hasrestrictedOffer !== "";

	if (!item.Colour) {
		if (item.SelectionCriteria) {
			let colorOption = item.SelectionCriteria.find(clr => clr.Code === "COLOUR");
			if (colorOption) {
				item.Colour = colorOption.Value;
				item.ColourDescription = colorOption.Value;
			}
		}
	}
	return item;
};

const updatePrice = (storeInfo, item) => {
	item.price = storeInfo ? getStoreprice(storeInfo, "NORMAL") : getWebprice(item, "NORMAL");
	item.familyPrice = storeInfo ? getStoreprice(storeInfo, "FAMILY") : getWebprice(item, "FAMILY");
};

const isFoodItemSpec = itemStoreInfo => {
	if (itemStoreInfo !== undefined && itemStoreInfo.PaNumber !== undefined && itemStoreInfo.PaNumber.includes("961")) return true;

	return false;
};
export const getImage = item => {
	if (item !== undefined && item.Images !== undefined) {
		let url = "";
		var image = item.Images && item.Images[0];
		if (image.PS) {
			url = image.PS.Url;
		}
		if (image.S3) {
			url = image.S3.Url;
		} else if (image.S4) {
			url = image.S4.Url;
		} else if (image.S5) {
			url = image.S5.Url;
		} else if (image.S2) {
			url = image.S2.Url;
		}
		if (url) {
			return url.includes("https") ? url : url.replace("http", "https");
		}
		return "../../../static/loading.png";
	}
	return "../../../static/loading.png";
};

export const getMediumImageUrl = images => {
	if (images !== undefined) {
		let url = "";
		let image = Enumerable.from(images).firstOrDefault();
		if (image.PS) {
			url = image.PS.Url;
		}
		if (image.S3) {
			url = image.S3.Url;
		} else if (image.S2) {
			url = image.S2.Url;
		} else if (image.S4) {
			url = image.S4.Url;
		} else if (image.S5) {
			url = image.S5.Url;
		}

		return url.replace("http", "https");
	}
};

export const toItemFormaId = item => {
	if (item === undefined) return "";
	if (item.length < 8) {
		return item;
	}
	return item.substring(0, 3) + "." + item.substring(3, 6) + "." + item.substring(6);
};

export const buildSearchRetailItem = (element, isMetric, locale) => {
	let citem = { ...element };
	let retailItem = mapRetailItem({ ...citem }, isMetric, locale);
	let remaingProp = {
		storeInfo: {}
	};

	const item = Object.assign(retailItem, remaingProp);
	return item;
};

export const getLink = (element, rel) => {
	try {
		return element.link(rel).uri.uri;
	} catch (error) {
		return "";
	}
};
export { SelectionCriteriaBuilder, mapRetailItem, updatePrice, isFoodItemSpec };

export const getPriceRange = retailItems => {
	if (retailItems.length && retailItems.length > 1) {
		const priceValues = retailItems.map(item => getPrice(item));
		let min = Math.min(...priceValues),
			max = Math.max(...priceValues);
		return {
			range: [min, max],
			min,
			max
		};
	}
};

export const handlePriceFilter = (price, filters, facets) => {
	const { min, max } = facets.price;
	const { range, currency } = price;
	const removePriceFilter = min === parseInt(range[0]) && max === parseInt(range[1]);
	const updatedFilters = removePriceFilter
		? filters.filter(filter => !filter.includes("price"))
		: [...filters.filter(filter => !filter.includes("price")), `price:${parseInt(range[0])}${currency} - ${parseInt(range[1])}${currency}`];
	return updatedFilters;
};

export const buildWebSearchRetailItem = (webItem, isMetric, locale) => {
	let color = webItem.colors && webItem.colors[0];
	let colors = webItem.colors && webItem.colors.map(x => x.name);
	if (webItem.gprDescription) {
		let gprColor = webItem.gprDescription.variants.find(x => x.id === webItem.id);
		if (gprColor && gprColor.colors) {
			colors = gprColor.colors.map(x => x.name);
			color = gprColor;
		}
	}
	let citem = {
		Rating: {
			Count: webItem.rating_count || "0",
			Max: webItem.rating_max_value || "0",
			Value: webItem.rating_value || "0",
			ValueInteger: webItem.rating_value_integer || "0"
		},
		ItemId: webItem.id.replace("s", ""),
		ItemType: webItem.id.includes("s") ? "SPR" : "ART",
		OnlineSellable: webItem.onlineSellable,
		Colours: colors,
		Colour: color && color.name,
		ColourDescription: color && color.name,
		ColourId: color && color.id,
		ProductType: webItem.typeName,
		ItemMeasureReferenceTextMetric: webItem.itemMeasureReferenceText,
		Name: webItem.name,
		IsBreathTakingItem: webItem.lastChance,
		ItemUnitCode: "PIECES",
		NewsType: webItem.is_new_product ? (webItem.is_new_product === "false" ? undefined : "NEWSTYPE") : undefined,
		Images: [
			{
				S3: {
					Url: webItem.mainImageUrl
				}
			}
		],
		HasMoreOptions: webItem.gprDescription !== undefined,
		RetailUnitPrices: [
			{
				Type: NORMAL,
				Value: parseInt(webItem.priceNumeral),
				Currency: webItem.currencyCode
			}
		]
	};

	let retailItem = mapRetailItem({ ...citem }, isMetric, locale);
	let remaingProp = {
		storeInfo: {}
	};

	if (citem.links !== undefined) {
		for (var key in citem.links) {
			if (Object.prototype.hasOwnProperty.call(citem.links, key)) {
				const data = citem.links[key];
				retailItem.links[key] = data.uri.uri;
			}
		}
	}

	const item = Object.assign(retailItem, remaingProp);
	return item;
};

export const buildEsalesWebFacets = (facetsResponse, catalogResult) => {
	var facets = {
		colour: {},
		itemType: {},
		productType: {},
		categories: {},
		showRating: false
	};

	const facetResult = facetsResponse.find(x => x.resultType === "facetList");
	if (facetResult !== undefined && facetResult.facetList !== undefined) {
		var colorFacet = facetResult.facetList.find(x => x.attribute === "color");
		if (colorFacet !== undefined) {
			colorFacet.values.forEach(item => {
				var val = item.text.split("$")[0];
				var id = item.text.split("$")[1];

				facets.colour[val] = {
					NumFound: item.count,
					Label: val,
					Id: id
				};
			});
		}
		var rating = facetResult.facetList.find(x => x.attribute === "rating_value");
		if (rating !== undefined) {
			if (rating.range.max !== "0.0") {
				facets.showRating = true;
			}
		}
	}
	const categoryTree = parseResponseByName(catalogResult, "category-overview", "categoryTree");
	const categoryList = parseResponseByName(catalogResult, "category-search", "categoryList");

	const categorySamples = getCategorySamples([], categoryList, categoryTree, categoryTree);

	var categories = Enumerable.from(categorySamples)
		.where(x => x.sample)

		.toArray();
	if (categories.length > 1)
		categories.forEach(cat => {
			facets.categories[cat.key] = {
				NumFound: cat.productCount,
				Label: cat.displayName,
				Id: cat.key
			};
		});
	return facets;
};

export const buildSikWebFacets = (colors, categories, ratings) => {
	var facets = {
		colour: {},
		itemType: {},
		productType: {},
		categories: {},
		showRating: ratings && ratings.values && ratings.values.length > 0
	};

	if (colors && colors.values) {
		colors.values.forEach(item => {
			var val = item.name;
			var id = item.id;

			facets.colour[val] = {
				NumFound: item.count,
				Label: val,
				Id: id
			};
		});
	}
	if (categories)
		categories.forEach(cat => {
			facets.categories[cat.key] = {
				NumFound: cat.count,
				Label: cat.name,
				Id: cat.key
			};
		});
	return facets;
};

export const parseResponseByName = (response, name, path) => {
	if (response) {
		const res = response.find(c => c.name === name);
		if (res) {
			return res[path];
		}
	}
	return null;
};
const getCategorySamples = (categoryFilter, categoryList, categoryTree, categoryNode, level = 0, sample = true, withinSelected = false) => {
	const categoryKeys = (categoryList || []).map(category => category.key);
	const categorySamples = [];
	const categoryProductCount = categoryTree.productCount || 0;
	if (categoryNode) {
		let sampleThis = sample;
		let sampleChildren = sample;
		// If category is currently selected / filtered on.
		const isSelected = categoryNode.key === categoryFilter;
		// If category is in "category search" list, and within level cut.
		const inSearch = categoryKeys.includes(categoryNode.key) && level <= 2;
		if (categoryNode.key !== "root") {
			// Sample this category if selected or in category search list
			// or if zero or more than one child.
			// If only one child, then sample that child node instead.
			sampleThis = isSelected || inSearch || (sample && (!categoryNode.subcategories.length || categoryNode.subcategories.length > 1));
			// If we should sample child nodes.
			sampleChildren = (sample && !sampleThis) || isSelected;
			// If the node has a majority of the products,
			// sample the child nodes instead.
			if (sampleThis && categoryNode.subcategories.length && categoryNode.productCount >= categoryProductCount * 0.5) {
				sampleThis = false || inSearch;
				sampleChildren = true;
			}
			// Push it to our flat list of categories.
			categorySamples.push({
				level,
				key: categoryNode.key,
				parentKey: categoryNode.parentKey,
				ticket: categoryNode.ticket,
				displayName: categoryNode.displayName,
				productCount: categoryNode.productCount,
				childrenCount: categoryNode.subcategories.length,
				url: categoryNode.attributes.url,
				sample: sampleThis,
				inSearch,
				isSelected,
				withinSelected
			});
		}
		categoryNode.subcategories.forEach(subCategoryNode => {
			getCategorySamples(
				categoryFilter,
				categoryList,
				categoryTree,
				subCategoryNode,
				level + 1,
				sampleChildren,
				withinSelected || isSelected
			).forEach(subCategory => categorySamples.push(subCategory));
		});
	}
	return categorySamples;
};

export const buildSikWebFacetsv2 = filters => {
	var facets = {
		colour: {},
		itemType: {},
		productType: {},
		categories: {},
		showRating: false
	};
	var colors = filters.find(x => x.id === "COLOR");
	if (colors && colors.values) {
		colors.values.forEach(item => {
			var val = item.name;
			var id = item.id;

			facets.colour[val] = {
				NumFound: item.count,
				Label: val,
				Id: id
			};
		});
	}
	var categories = filters.find(x => x.id === "CATEGORIES");
	if (categories && categories.values)
		categories.values.forEach(cat => {
			facets.categories[cat.id] = {
				NumFound: cat.count,
				Label: cat.name,
				Id: cat.id
			};
		});
	return facets;
};
export const GetNewLowerPriceIcon = locale => {
	var returnUrl = "";
	switch (locale) {
		case "cs_CZ":
		case "da_DK":
		case "fr_BE":
		case "fr_CH":
		case "fr_CA":
		case "fr_FR":
		case "hr_HR":
		case "hu_HU":
		case "it_CH":
		case "it_IT":
		case "ja_JP":
		case "ko_KR":
		case "nl_BE":
		case "no_NO":
		case "pt_PT":
		case "ro_RO":
		case "ru_RU":
		case "sk_SK":
		case "sr_RS":
		case "sv_SE":
		case "zh_CN":
		case "de_DE":
		case "de_CH":
			returnUrl = `${locale}.png`;
			break;

		case "en_AT":
		case "en_AU":
		case "en_BE":
		case "en_CA":
		case "en_CH":
		case "en_CN":
		case "en_ES":
		case "en_FR":
		case "en_GB":
		case "en_HR":
		case "en_IE":
		case "en_IN":
		case "en_IT":
		case "en_JP":
		case "en_KR":
		case "en_NL":
		case "en_PL":
		case "en_RO":
		case "en_RS":
		case "en_RU":
		case "en_SE":
		case "en_US":
			returnUrl = `en.png`;
			break;
		case "es_ES":
		case "ca_ES":
			returnUrl = "es_ES.png";
			break;

		case "es_US":
			returnUrl = "es_ES.png";
			break;

		case "fi_FI":
		case "fi_SE":
			returnUrl = "fi_FI.png";
			break;

		case "nl_NL":
		case "de_NL":
			returnUrl = "nl_NL.png";
			break;

		case "ru_PL":
		case "pl_PL":
			returnUrl = "pl_PL.png";
			break;

		case "it_AT":
		case "sl_AT":
		case "de_AT":
			returnUrl = "de_AT.png";
			break;
		default:
			returnUrl = "en.png";
			break;
	}

	return returnUrl;
};

const parseSpecialKeyWord = searchInput => {
	var input = searchInput.toUpperCase();
	if (input && input.toUpperCase() === "KOMPL") {
		return "Komplement";
	}
	return searchInput;
};
export { parseSpecialKeyWord };

export const validateBarcode = code => {
	if (code.length === 8) {
		return true;
	}
	if (code.length === 14) {
		if (["2", "9", "0"].indexOf(code[13]) >= 0) {
			return true;
		}
	}
	return false;
};
export const validateOrdercode = code => {
	if (code.length === 14 || code.length === 16) {
		if (code.includes("0000") || code.includes("00000")) {
			return true;
		}
		return false;
	}
};
