import React, { forwardRef, useImperativeHandle } from "react";
import { getStoreConfigSelector } from "../../utils/shellObjectService";
import useMicrofrontend from "../../hooks/useMicroFrontend";
import { DfpKey } from "@coworker/sharedlibrary";

/**
{view} use for CustomerForm,CustomerMode,AddressSuggestion and default is external-standalone flow.
{isStandalone} used for external customer flow.
{elementId} use when if multiple customerview used in sameview
{setFullWidth,hideInTablet,handleCustomerTypeSwitch} use for private/business customer mode control element
{addressProvider,messages,storeInfo} use for address autocomplete suggestion element
 */
const CustomerView = forwardRef(
	(
		{ view, isStandalone, elementId, setFullWidth, hideInTablet, handleCustomerTypeSwitch, includeBcmSearch, addressProvider, searchValue, autoSearch },
		ref
	) => {
		let config = getStoreConfigSelector();

		const [hideCustomerBanner, setHideCustomerBanner] = React.useState(false);

		if (config && config.preferences) {
			const preference = config.preferences.find(x => x !== undefined && x.name === DfpKey.ICM_PROFILE_ENABLED);

			config.isMfsProfile = preference && preference.value === "false";
		}

		const customerProfileApp = config && config.environment.microapps.customerProfileApp;

		const { appId, appUrl, appName } = customerProfileApp || {};

		const { isLoaded, customerprofile } = useMicrofrontend(appId, appUrl, appName);

		useImperativeHandle(ref, () => ({
			isValid() {
				return customerprofile.isValid();
			},
			hideview(show) {
				setHideCustomerBanner(show);
			}
		}));

		const elId = `${elementId || ""}${appId}`;

		React.useEffect(() => {
			if (!customerprofile || !config) return;
			let configprops = {
				...config,
				view,
				isStandalone,
				elementId,
				setFullWidth,
				hideInTablet,
				handleCustomerTypeSwitch,
				includeBcmSearch,
				addressProvider,
				searchValue,
				autoSearch
			};
			customerprofile.SetSharedConfiguration({ ...configprops });

			const { render, unMount } = customerprofile;

			configprops.view = view;
			render(elId, configprops);

			return () => {
				unMount(elId);
			};
		}, [isLoaded, searchValue, autoSearch]);

		if (!isLoaded) return null;
		if (!customerprofile) return <div />;

		return (
			<div
				id={elId}
				style={{
					width: "100%",
					visibility: hideCustomerBanner && view === "CustomerBanner" ? "hidden" : "visible"
				}}
			/>
		);
	}
);

CustomerView.displayName = "CustomerView";
export default CustomerView;
export { CustomerView };
