import ExternalMain from "./external/externalMain";
import Main from "./containers/main";
import NotificationContainer from "./components/notification/notification";
import React from "react";
import SplashView from "./containers/splash";
import WelcomeView from "./containers/welcome";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { isExternalClientUrl } from "./helpers/externalUrlHelper";
import { setUser } from "./store/auth/actions";
import shellEventParser from "./utils/shellEventParser";
import { useWindowEventListener } from "@coworker/sharedlibrary";

// import { RequestHelper } from "@coworker/sharedlibrary";
// import SalesCoworkerConfiguration from "./store/salesCoworkerConfiguration";
// // eslint-disable-next-line no-undef
// RequestHelper.setToken(process.env.REACT_APP_EXTERNAL_TOKEN);
// SalesCoworkerConfiguration.setIsExternal(true);
const App = props => {
	useWindowEventListener("HOST_LOG", e => {
		const logData = e.detail;
		shellEventParser("LOG", logData);
	});

	const isExternalClient = isExternalClientUrl() || isExternalState();

	let view = <SplashView isExternalClient={isExternalClient} />;

	if (props.user && props.coworkerData) {
		if (isExternalClient) {
			view = <ExternalMain {...props} />;
		} else {
			if (props.selectedHfb === undefined) {
				view = <WelcomeView />;
			} else {
				view = <Main {...props} />;
			}
		}
	}

	return (
		<>
			{view}
			<NotificationContainer />
		</>
	);
};

const mapStateToProps = state => ({
	user: state.authController.user,
	coworkerData: state.appController.coworkerData,
	selectedHfb: state.appController.selectedHfb
});

export default connect(mapStateToProps, dispatch => bindActionCreators({ setUser }, dispatch))(App);

export { App };

function isExternalState() {
	if (window.location.href.includes("code=")) {
		const urlParams = new URLSearchParams(window.location.search);
		const code = urlParams.get("code");
		const stateparam = urlParams.get("state");
		if (code) {
			const { state } = JSON.parse(atob(stateparam));
			if (state.includes("external/") || state.includes("customer.html")) {
				return true;
			}
		}
	}
}
