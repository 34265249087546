import { useSelector } from "react-redux";
import React from "react";
import ModifyOrderCheckOutOption from "./modifyOrderCheckOutOption";
import CreateOrderCheckoutOption from "./createOrderCheckoutOption";

const OrderCheckOutOptions = ({ disabled, canCreateSalesOrder, onlyService }) => {
	const { isModifyOrderinProgress, isQuotation } = useSelector(state => state.cartController);
	const isDraftMode = !isModifyOrderinProgress;
	if (isDraftMode) {
		return (
			<CreateOrderCheckoutOption
				onlyService={onlyService}
				disabled={disabled}
				isQuotation={isQuotation}
				canCreateSalesOrder={canCreateSalesOrder}
			></CreateOrderCheckoutOption>
		);
	} else {
		return <ModifyOrderCheckOutOption onlyService={onlyService} disabled={disabled} isQuotation={isQuotation} canCreateSalesOrder={canCreateSalesOrder} />;
	}
};
export default OrderCheckOutOptions;
