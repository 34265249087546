import { getCurrentCartDraftOrder } from "./../../../../../store/cart/cart.thunks";
import { clearCart } from "./../../../../../store/cart/cartActions";
import { GaHelper } from "@coworker/sharedlibrary";
import { useSelector, useDispatch } from "react-redux";
import React from "react";
import Modal, { Prompt, ModalFooter } from "@ingka/modal";
import Button from "@ingka/button";
import { getMessage } from "@coworker/sharedlibrary";
import styled from "styled-components";
import useMicrofrontend from "./../../../../../hooks/useMicroFrontend";
import { GUID } from "@coworker/sharedlibrary";
import { getStoreConfigSelector } from "../../../../../utils/shellObjectService";

//import { useCanUpdateQuotation } from "../../../hooks/useQuotation";

const NewShoppingBag = styled.div`
	.svg-icon {
		display: none;
	}
`;

const NewShoppingBagDialog = ({ onSubmit, onClose, deleteAllItems }) => {
	const { messages } = useSelector(state => state.intl);
	var config = getStoreConfigSelector();

	var { appId, appUrl, appName } = config.environment.microapps.recentOrderApp;

	const { recentorder } = useMicrofrontend(appId, appUrl, appName);

	const dispatch = useDispatch();
	const { isModifyOrderinProgress, order, cartId, isQuotation, hasServices } = useSelector(state => state.cartController);

	// const updateQuoteNeeded = useCanUpdateQuotation();
	function getModelButtons() {
		if (isQuotation || isModifyOrderinProgress || deleteAllItems) {
			return [
				{
					text: messages.SG_BTN_Yes,
					type: "primary",
					onClick: () => {
						onSubmit(true);
						dispatch(clearCart());
					}
				},
				{
					text: messages.SG_BTN_No,
					type: "secondary",
					onClick: () => {
						onSubmit(false);
					}
				}
			];
		} else {
			return [
				{
					text: messages.SG_BTN_SaveToDrafts,
					type: "primary",
					onClick: () => {
						dispatch(getCurrentCartDraftOrder()).then(draftOrder => {
							recentorder && recentorder.saveCurrentCarttoDraftOrder(draftOrder);
							onSubmit(true);
							dispatch(clearCart());
							GaHelper.SendEvent("ShoppingBag", "SaveToDrafts", `User saved shopping bag to draft`);
						});
					}
				},
				{
					text: getMessage("SG_BTN_EmptyShoppingBag", messages, "Delete current shopping bag"),
					type: "secondary",
					onClick: () => {
						GaHelper.SendEvent("ShoppingBag", "ClearShoppingBag", `User selected to empty shopping bag`);
						recentorder && recentorder.removeDraftOrder(cartId);
						onSubmit(true);
						dispatch(clearCart());
					}
				}
			];
		}
	}

	return (
		<NewShoppingBag>
			<Modal
				visible={true}
				escapable={true}
				handleCloseBtn={() => {
					onClose();
				}}
				focusLockProps={focus}
			>
				<Prompt
					title={
						isQuotation
							? messages.SG_LBL_QuotationOrderCloseMessage.replace("{order}", (order && order.orderNo) || "")
							: isModifyOrderinProgress
							? messages.SG_LBL_CartOrderCloseMessage.replace("{order}", order.orderNo)
							: deleteAllItems
							? messages.SG_LBL_ClearBag
							: messages.SG_LBL_CartSaveMessage
					}
					footer={
						<ModalFooter compact={false}>
							{getModelButtons().map(button => {
								return <Button key={GUID.Guid()} {...button} />;
							})}
						</ModalFooter>
					}
				>
					<p>
						{isQuotation
							? ""
							: deleteAllItems
							? ""
							: !isModifyOrderinProgress
							? `${messages.SG_LBL_CartSaveChangeMessage}${hasServices ? `\n ${messages.SG_LBL_CartCustomerSaveChange}` : ""}`
							: ""}
					</p>
				</Prompt>
			</Modal>
		</NewShoppingBag>
	);
};

export default NewShoppingBagDialog;
const focus = {
	returnFocus: false,
	autoFocus: true
};
