import styled from "styled-components";
import React from "react";
const CombinationImage = styled.div`
	width: 30px;
	height: 30px;
	img {
		width: 30px;
		height: 30px;
	}
`;
const CombinationImageContainer = styled.div`
	min-width: 60px;
	max-width: 63px;
	height: 90px;
	align-items: center;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	> img {
		width: 60px;
		height: 60px;
	}
`;

const CartImageList = ({ group }) => {
	if (!group.imageList) {
		return "";
	}

	return (
		<CombinationImageContainer>
			{group.imageList.length === 1 ? (
				<img alt="" src={group.imageList[0].Url} />
			) : (
				group.imageList.map(image => (
					<CombinationImage key={image.Id}>
						<img alt="" src={image.Url} />
					</CombinationImage>
				))
			)}
		</CombinationImageContainer>
	);
};

export default CartImageList;
