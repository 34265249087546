import { useSelector } from "react-redux";
import React from "react";
import { getMessage, GUID } from "@coworker/sharedlibrary";
import Modal, { Prompt, ModalFooter } from "@ingka/modal";
import Button from "@ingka/button";

const ShoppingListConfirmationPopup = ({ onSubmit, saveShoppingList, onClose }) => {
	const { messages } = useSelector(state => state.intl);

	function getModelButtons() {
		return [
			{
				text: messages.SG_BTN_OK || "OK",
				type: "primary",
				onClick: () => {
					onSubmit(true);
					saveShoppingList();
				}
			},
			{
				text: messages.SG_BTN_Cancel,
				type: "secondary",
				onClick: () => {
					onSubmit(false);
				}
			}
		];
	}

	return (
		<Modal
			focusLockProps={focus}
			visible
			modalType="alert"
			light={"false"}
			handleCloseBtn={() => {
				onClose();
			}}
		>
			<Prompt
				title={getMessage("SG_LBL_RemoveServiceWarning", messages, "Services will be removed from order")}
				footer={
					<ModalFooter compact={false}>
						{getModelButtons().map(button => {
							return <Button key={GUID.Guid()} {...button} />;
						})}
					</ModalFooter>
				}
			/>
		</Modal>
	);
};

export default ShoppingListConfirmationPopup;
const focus = {
	returnFocus: false,
	autoFocus: true
};
