export const Self = "self";

export const SellingHomeUser = "selling-home-user";

export const SellingHomeUserStores = "selling-home-user-stores";

export const SellingHomeStore = "selling-home-store";

export const SellingRetailItemRetailItem = "selling-retail-item-retail-item";

export const SellingRetailItemRetailItems = "selling-retail-item-retail-items";

export const SellingRetailItemRetailItemStoreInfo = "mfs-range:retail-item-store-info";

export const MfsRangeRetailItemRetailItemStoreInfo = "mfs-range:retail-item-store-info";

export const SellingRetailItemSearch = "selling-retail-item-search";

export const SellingRetailItemMoreResult = "selling-retail-item-search-more-results";

export const SellingRetailItemDailyForecasts = "selling-retail-item-daily-forecasts";

export const SellingRetailItemWeeklyForecasts = "selling-retail-item-weekly-forecasts";

export const SellingRetailItemGprChildItems = "selling-retail-item-gpr-child-items";

export const SellingRetailItemComplementaryItems = "selling-retail-item-complementary-items";

export const SellingRetailItemSimilarItems = "selling-retail-item-similar-items";

export const SellingRetailItemSprChildItems = "selling-retail-item-spr-child-items";

export const SellingStoreInfoStoresByLocaleOrIds = "selling-store-info-stores-by-locale-or-ids";

export const SellingCatalogCatalogs = "selling-catalog-catalogs";

export const SellingStoreInfoStoreInfo = "selling-store-info-store-info";

export const SellingStoreInfoAllStores = "selling-store-info-all-stores";

export const CcOrderCreation = "mfs-sales:cc-order-creation";

export const AssociatedCategories = "selling-catalog-associated-categories"; //

//old deprecated "cc-order-creation";

export const ShoppingListCreation = "mfs-sales:shopping-list-creation"; //old deprecated "shopping-list-creation";

export const OrderPrintout = "mfs-sales:order-printout"; // "order-printout";// "order-printout";

export const OrderEmail = "mfs-sales:order-email"; // "order-email";//"order-email";

export const Completion = "mfs-search:completion"; // "completion";

export const Offers = "selling-retail-item-store-offers";

export const Next = "next";

export const SellingOrderServicesTimeWindows = "selling-order-services-time-windows";

export const SellingOrderAvailableServiceTimeWindows = "selling-order-services-service-time-window";

export const SellingOrderServicesServiceOptions = "selling-order-services-service-options";

export const SellingOrderServicesDeliveryTimeWindows = "selling-order-services-delivery-service-time-windows";

export const SellingOrderServicesProvidedServicesTimeWindows = "selling-order-services-provided-service-time-windows";

export const SellingOrderServicesServiceProposal = "selling-order-services-service-proposal"; //TODO Not verified!selling-order-services-service-proposal

export const DeliveryQuestionnaire = "selling-delivery-questionnaire";

export const ServiceQuestionnaire = "selling-service-questionnaire";

export const SellingSalesOrderCreation = "selling-sales-order-creation";

export const SellingOrderRetrieval = "selling-order-retrieval";

export const SellingPlannerService = "selling-planner-service";

export const PlannerAttachmentDeepLink = "planner-attachment-deeplink";

export const PlannerAttachmentShortLink = "planner-attachment-shortlink";

export const SellingOrderConversionConvertSlToCcForm = "selling-order-conversion-convert-sl-to-cc-form";
export const SellingOrderConversionConvertSlToCc = "selling-order-conversion-convert-sl-to-cc";
export const SellingSubCategoryItems = "selling-catalog-browse-categories";
export const SellingCatelogBrowseItems = "selling-catalog-browse-items";

export const SellingStorePreference = "mfs-configuration:store-preference";

export const SellingSmsjobCreation = "mfs-communication:sms-job-creation";

export const SellingEmailjobCreation = "mfs-communication:email-job-creation"; //old email-job-creation

export const SellingPrintjobCreation = "mfs-communication:print-job-creation"; //old print-job-creation

export const SellingOrderSms = "mfs-sales:order-sms"; // order-sms

export const SellingProductConfigMpuPreference = "mfs-configuration:mpu-preference"; // order-sms

export const SellingProductConfigCountryPreference = "mfs-configuration:country-preference"; // order-sms

export const MfsRangeDetailStockinfo = "mfs-range:detail-stockinfo"; //selling-retail-item-detail-stockinfo

export const SellingSalesOrderCustomerSchema = "selling-sales-order-customer-schema";

export const StorePreference = "mfs-configuration:store-preference";

export const SellingRetailtemBrowse = "selling-retail-item-browse";

export const MfsSalesServicePrice = "mfs-sales:service-price";

export const SellingOrderModificationChangeItemQuantityForm = [
	"selling-shopping-list-modification-modify-item-quantity-form",
	"selling-cc-order-modification-modify-item-quantity-form"
];

export const SellingOrderModificationChangeItemQuantity = [
	"selling-shopping-list-modification-modify-item-quantity",
	"selling-cc-order-modification-modify-item-quantity"
];

export const SellingOrderModificationMoveItemForm = ["selling-shopping-list-modification-move-item-form", "selling-cc-order-modification-move-item-form"];

export const SellingOrderModificationRemoveItemForm = ["selling-shopping-list-modification-remove-item-form", "selling-cc-order-modification-remove-item-form"];

export const SellingOrderModificationRemoveItem = ["selling-shopping-list-modification-remove-item", "selling-cc-order-modification-remove-item"];

export const SellingOrderModificationAddItemForm = ["selling-shopping-list-modification-add-item-form", "selling-cc-order-modification-add-item-form"];

export const SellingOrderModificationAddItem = ["selling-shopping-list-modification-add-item", "selling-cc-order-modification-add-item"];

export const SellingOrderModificationCancelOrderForm = [
	"selling-shopping-list-modification-cancel-order-form",
	"selling-cc-order-modification-cancel-order-form",
	"mfs-sales:isom-order-modification-cancel-order"
];

export const SellingOrderModificationCancelOrder = [
	"selling-shopping-list-modification-cancel-order",
	"selling-cc-order-modification-cancel-order",
	"mfs-sales:isom-order-modification-cancel-order"
];

export const SellingOrderModificationCovertToCCOrder = ["selling-order-conversion-convert-sl-to-cc"];

export const MfsSalesQuestionnaire = "mfs-sales:questionnaire";

export const IsomDeliveryArrangements = "isom-deliveryarrangements";

export const IsomWindows = "Isom-Windows";

export const SellingIsomOrderCreation = "mfs-sales:isom-order-creation";
//ICM
export const IcmFind = "ICM-FindAPI";
export const IcmRead = "ICM-ReadAPI";
export const IcmPartialUpdate = "ICM-PartialUpdate";
export const IcmCreate = "ICM-CreateAPI";
export const IcmGoogleAuth = "ICM-Audience";
export const IcmAuth = "ICM-Auth";
