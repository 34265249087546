import React from "react";
import Modal, { Prompt, ModalFooter } from "@ingka/modal";
import Button from "@ingka/button/Button";
import { useSelector } from "react-redux";

const ConvertOrderDialog = ({ onSubmit, onClose }) => {
	const { messages } = useSelector(state => state.intl);

	function getModelButtons() {
		return [
			{
				text: messages.SG_BTN_ConvertOrder,
				type: "primary",
				onClick: () => {
					onSubmit(true);
				}
			},
			{
				text: messages.SG_BTN_ContinueWithoutService || "Continue without services",
				type: "secondary",
				onClick: () => {
					onSubmit(false);
				}
			}
		];
	}

	return (
		<Modal visible={true} escapable={true} handleCloseBtn={() => onClose()} focusLockProps={focus}>
			<Prompt
				title={messages.SG_LBL_ConvertToOrderTitle || "Conversion needed!"}
				footer={
					<ModalFooter compact={false}>
						{getModelButtons().map((button, i) => {
							return <Button key={i} {...button}></Button>;
						})}
					</ModalFooter>
				}
			>
				<p>{messages.SG_LBL_ConvertToOrderText || "If you want to add services to a shopping list, the items will be moved to a new shoppingbag"}</p>
			</Prompt>
		</Modal>
	);
};

export default ConvertOrderDialog;
const focus = {
	returnFocus: false,
	autoFocus: true
};
