import { IntlProvider } from "react-intl";
import { PagePaths } from "@coworker/sharedlibrary";
import { Switch } from "react-router";
import React, { Suspense } from "react";

import { ProductInformationView } from "./components/microfrontends/productInformationView";
import AppliedRoute from "./components/AppliedRoute";
import BrowseView from "./components/microfrontends/microBrowseView";
import CatalogsView from "./components/microfrontends/catalogsView";
import MicroCombinationView from "./components/microfrontends/microCombinationView";
import MircoOrderConfirmation from "./components/microfrontends/microOrderConfirmation";
import RecentOrderView from "./components/microfrontends/recentOrderView";
import SearchPageView from "./components/microfrontends/searchPageView";
import WebPlannersView from "./components/microfrontends/webPlannersView";
import MicroImportOrderView from "./components/microfrontends/microImportOrderView";
import CartView from "./containers/cart/cartView";

const { BrowsePage, SearchPagePath, CartPagePath, DetailPagePath, PlannerPagePath, PtagPagePath, RecentOrderPagePath } = PagePaths;

const Routes = props => {
	return (
		<IntlProvider locale={props.locale} messages={props.messages} defaultLocale="en">
			<Suspense fallback={<div>Loading...</div>}>
				<Switch>
					<AppliedRoute exact path="/" component={CatalogsView} props={props} />
					<AppliedRoute exact path="/home" component={CatalogsView} props={props} />
					<AppliedRoute path={BrowsePage} component={BrowseView} props={props} />
					<AppliedRoute component={SearchPageView} path={SearchPagePath} props={props} />
					<AppliedRoute component={CartView} path={CartPagePath} props={props} />
					<AppliedRoute component={CartView} path={"/order/:orderNo"} props={props} />

					<AppliedRoute props={props} component={ProductInformationView} path={DetailPagePath} />
					<AppliedRoute props={props} component={MicroCombinationView} path={PlannerPagePath} />
					<AppliedRoute props={props} component={MicroCombinationView} path={PtagPagePath} />

					<AppliedRoute props={props} component={RecentOrderView} path={RecentOrderPagePath} />
					<AppliedRoute props={props} component={MircoOrderConfirmation} path={"/confirmation/:type/:orderNo"} />

					<AppliedRoute props={props} component={MicroImportOrderView} path={"/importorder/:orderNo"} />

					<AppliedRoute
						props={props}
						component={MicroCombinationView}
						path={"/kitchenplanner/:type/:id"}
						///0001-1559-2093/KITCHEN
					/>

					<AppliedRoute props={props} component={MicroCombinationView} path={"/web/list"}></AppliedRoute>

					<AppliedRoute props={props} component={MicroCombinationView} path={"/kiosk/list"}></AppliedRoute>
					<AppliedRoute props={props} component={MicroCombinationView} path={"/receipt/:id"}></AppliedRoute>

					<AppliedRoute props={props} component={RecentOrderView} path={"/microorder"}></AppliedRoute>
					<AppliedRoute props={props} component={WebPlannersView} path={"/webplanners"}></AppliedRoute>
					<AppliedRoute path="*" component={Whoops404} />
				</Switch>
			</Suspense>
		</IntlProvider>
	);
};

Routes.displayName = "Routes";
export default Routes;

export const Whoops404 = () => (
	<div className="whoops-404">
		<h1>You reached a void place</h1>
	</div>
);
