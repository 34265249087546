import Enumerable from "linq";

class HasVaildAnswerSpec {
	constructor(question) {
		this.question = question;
	}
	IsSatisfiedBy(answer) {
		return IsValidAnswerToQuestionSpec(this.question, answer);
	}
	IsSatisfiedByAnswers(answers) {
		let answerItems = Enumerable.from(answers);
		return answerItems.any(x => (this.question.isMandatory ? IsValidAnswerToQuestionSpec(this.question, x) : true));
	}
}

class RequiresFreeTextSpec {
	IsSatisfiedBy(question, answer) {
		let options = Enumerable.from(question.options);
		if (answer === null) {
			return options.where(x => x.value === "UNDEFINED").any(x => x.requireFreeText);
		}

		let requireFreeText = options.where(x => x.requireFreeText);

		if (requireFreeText.any(x => x.value === answer.option)) {
			if (!answer.freeText || answer.freeText === "") return true;
		}

		return false;
	}
}

class ShouldFillQuestionnaires {
	IsSatisfiedBy(cartManager) {
		let delNeeded = false;
		let serNeeded = false;
		if (cartManager.deliveryQuestions && cartManager.deliveryQuestions.length > 0) {
			let questions = Enumerable.from(cartManager.deliveryQuestions);
			delNeeded = questions.any(x => new HasVaildAnswerSpec(x).IsSatisfiedByAnswers(cartManager.questionaireAnswers) === false);
		}
		if (cartManager.serviceQuestions && cartManager.serviceQuestions.length > 0) {
			let serquestions = Enumerable.from(cartManager.serviceQuestions);
			serNeeded = serquestions.any(x => new HasVaildAnswerSpec(x).IsSatisfiedByAnswers(cartManager.questionaireAnswers) === false);
		}
		return delNeeded || serNeeded;
	}
}

export { ShouldFillQuestionnaires, RequiresFreeTextSpec, HasVaildAnswerSpec };
const IsValidAnswerToQuestionSpec = (question, answer) => {
	if (answer === null) {
		return false;
	}
	if (question.reference !== answer.reference) {
		return false;
	}
	let options = Enumerable.from(question.options);
	if (!options.select(x => x.value).contains(answer.option)) {
		return false;
	}
	return !new RequiresFreeTextSpec().IsSatisfiedBy(question, answer);
};
