import { ObjectHelper, SharedConfiguration, Specifications } from "@coworker/sharedlibrary";
import { PriceModel, ShoppingCartItemFactory } from "@coworker/sharedlibrary";
import { calcPrice, cartItemsBuilder, httpService } from "@coworker/sharedlibrary";

import { APIEndpoints } from "@coworker/sharedlibrary";
import AppLogger from "../../logger/appLogger";
import SalesCoworkerConfiguration from "../../store/salesCoworkerConfiguration";
import { URI } from "@coworker/sharedlibrary";
import { calcTotalPrice } from "@coworker/sharedlibrary/src/helpers/bigDecimalHelper";

const FAMILY = "FAMILY";

const NORMAL = "NORMAL";
const FamilyViewType = "Family";
const { usesSpeCalcSpec, usesIndicativeTaxForIsom, useDeviatingShipmentIsom, usesExclPriceMarket, usesStateCodeForIndicativeTaxIsom } = Specifications;
const getCartPrice = async (
	cartItems,
	services = [],
	zipCode,
	isCCdDelivery = false,
	customer = undefined,
	discounts = [],
	currentOrder,
	fetchInclusiveTax = false
) => {
	try {
		const { currency, orderNo, orderNoSource } = currentOrder;
		const config = SalesCoworkerConfiguration.getUserConfig();
		const { buCode, countryCode, isCashLine } = config;
		if (!buCode) {
			return cartItems;
		}
		if (!usesSpeCalcSpec(countryCode)) {
			return cartItems;
		}
		if (cartItems.length === 0 && services.length === 0) {
			return;
		}
		let baseUrl = SharedConfiguration.getApiBaseUrl("cartservices");
		let link = new URI(`${baseUrl}${APIEndpoints.orderAmountUrl}`, true).fill({
			countryCode: countryCode,
			buCode: buCode
		});
		let factory = new ShoppingCartItemFactory();
		let isTransportDeskMode = cartItems.filter(x => x.importDetails).length > 0;
		let useExclusive = countryCode === "US" || countryCode === "CA";
		let maxId = factory.getLatestItemId(cartItems);
		let allItems = cartItemsBuilder.createItems(cartItems, true);
		let delivery = services.find(x => x.isCentralDelivery);
		let deliveryReferenceItems = [];
		allItems.forEach(x => {
			x.excludeTax = useExclusive && isTransportDeskMode && !isCashLine;
		});
		const requestItems = createItems(allItems);
		if (services && services.length > 0) {
			const hasStandaloneService = services.find(x => x.isCentralDelivery || x.isDelivery) === undefined;
			if (hasStandaloneService) {
				requestItems.forEach(itm => {
					delete itm.appliedPrices;
					delete itm.isLocked;
					if (useExclusive) {
						itm.priceOverride = {
							priceExclTax: 0
						};
					} else {
						itm.priceOverride = {
							priceInclTax: 0
						};
					}
				});
			}
		}
		if (delivery) {
			deliveryReferenceItems = delivery.referenceItems;
			requestItems.forEach(itm => {
				let refDelItem = deliveryReferenceItems.find(dl => dl.serviceReference.lineId.toString() === itm.lineId.toString());
				if (refDelItem && refDelItem.timeWindowLineItem) {
					if (useDeviatingShipmentIsom(countryCode)) {
						if (delivery.hasPickupPoint) {
							itm.deviatingShipTo = {
								countryCode: countryCode,
								stateCode: `${countryCode}${delivery.pickuppoint && delivery.pickuppoint.state}`,
								city: delivery.pickuppoint && delivery.pickuppoint.city,
								addressLine1: delivery.pickuppoint && delivery.pickuppoint.addressLine1,
								addressLine2: delivery.pickuppoint && delivery.pickuppoint.addressLine2,
								zipCode: delivery.pickuppoint && delivery.pickuppoint.zipCode,

								country: countryCode
							};
						} else {
							//TODO: enable this when SPE and TIM vertex and tax engine has aligned the CDC flow
							// let nodes = refDelItem.timeWindowLineItem.shipNode.split(".");
							// itm.deviatingShipFrom = {
							//   buCode: buCode, // nodes && nodes[1],
							//   buType: nodes && nodes[0],
							//   ctyCode: countryCode
							// };
						}
					}
				}
			});
		}
		let request = {
			items: requestItems
		};

		if (orderNo) {
			request.orderDetails = {
				orderNo: orderNo,
				orderNoSrc: orderNoSource,
				amountPaid: 0
			};
		}
		request.currencyCode = currency;
		let employeeDiscount = discounts.length > 0 && discounts.find(dis => dis.type.toUpperCase() === "EMPLOYEE");
		request.customer = {
			customerType: "Private"
		};
		let priceViewType = "Private";
		let hasFamily = customer && customer.ikeaFamilyNumber;
		if (employeeDiscount) {
			request.customer.customerType = "Employee";
			priceViewType = "Employee";
		}
		if (customer.isBusinessCustomer) {
			request.customer.customerType = "Business";
			priceViewType = "Business";
		}
		if (customer && customer.id) {
			request.customer.customerIdentificationId = customer.id;
		}
		if (customer && customer.ikeaFamilyNumber && !isTransportDeskMode) {
			request.customer.familyId = customer.ikeaFamilyNumber;
		}
		let sampleTransportItem = cartItems.find(x => x.receiptPriceDetails);
		let receiptPriceDetails = sampleTransportItem && sampleTransportItem.receiptPriceDetails;
		services.forEach(sr => {
			sr.exclusiveTaxDetails = isTransportDeskMode ? (receiptPriceDetails && receiptPriceDetails.taxDetails) || [] : undefined;
			sr.isTransportDeskMode = isTransportDeskMode && !isCashLine;
			sr.useExclusive = useExclusive;
			if (sr.subDeliveries) {
				sr.subDeliveries.forEach(sub => {
					sub.exclusiveTaxDetails = isTransportDeskMode ? (receiptPriceDetails && receiptPriceDetails.taxDetails) || [] : undefined;
					sub.isTransportDeskMode = isTransportDeskMode && !isCashLine;
					sub.useExclusive = useExclusive;
				});
			}
		});
		let couponDiscount = discounts.length > 0 && discounts.find(dis => dis.type.toUpperCase() === "COUPON");
		if (couponDiscount && couponDiscount.value) {
			request.coupons = [couponDiscount.value];
		}
		request.orderSavings = [];
		let manualDiscount = discounts.length > 0 && discounts.find(dis => dis.type.toUpperCase() === "MANUAL");
		if (manualDiscount) {
			request.orderSavings.push(manualDiscount);
		}
		let voucherDiscount = discounts.length > 0 && discounts.find(dis => dis.type.toUpperCase() === "VOUCHER");
		if (voucherDiscount) {
			request.orderSavings.push(voucherDiscount);
		}
		if (!ObjectHelper.isNil(services) && services.length > 0) {
			request.serviceArea = {
				zipCode: zipCode,
				countryCode: countryCode
			};
			if (usesIndicativeTaxForIsom(countryCode)) {
				request.provideIndicativeTax = fetchInclusiveTax;
				if (usesStateCodeForIndicativeTaxIsom(countryCode) && customer && customer.stateCode) {
					let formattedCode = customer.stateCode.substring(0, 2);
					request.serviceArea.stateCode = `${countryCode}${formattedCode}`;
				}

				if (customer && customer.addressLine1) {
					request.serviceArea.addressLine1 = customer.addressLine1;
				}
				if (customer && customer.addressLine2) {
					request.serviceArea.addressLine2 = customer.addressLine2;
				}
			}
			let { delRequests, lineId } = createDeliveryServices(services, countryCode, maxId);
			maxId = lineId;
			request.deliveryServices = delRequests;
			let paytoIkeaservicesRqts = createProvidedServices(
				services.filter(x => x.isProvidedService && x.serviceProviderPreferredPayment === "PAY_TO_IKEA") || [],
				countryCode,
				maxId
			);
			request.paytoIkeaServices = paytoIkeaservicesRqts.provRequests;
			maxId = paytoIkeaservicesRqts.srvLineId;

			let paytoSpservicesRqts = createProvidedServices(
				services.filter(x => x.isProvidedService && x.serviceProviderPreferredPayment === "PAY_TO_SP") || [],
				countryCode,
				maxId
			);
			request.paytoSPServices = paytoSpservicesRqts.provRequests;
			maxId = paytoSpservicesRqts.srvLineId;
		}

		let serviceSolutions = [];
		if (services && services.length > 0) {
			services.forEach(sol => {
				if (sol.isCentralDelivery) {
					let price = (sol.price && (sol.price.priceInclTax || sol.price.priceExclTax)) || 0;
					let solution = {
						solutionId: sol.solutionId,
						programmaticPriceOverride: {}
					};
					if (usesExclPriceMarket(countryCode)) {
						solution.programmaticPriceOverride.priceExclTax = price;
					} else {
						solution.programmaticPriceOverride.priceInclTax = price;
					}
					if (sol.orderNo && price !== 0) {
						if (sol.isLocked) {
							let { appliedUnitPrice, appliedSubTotal } = getDelliveryUnitAndSubTotalsFromOrderItem(sol);
							solution.isLocked = true;
							solution.appliedPrices = {
								appliedUnitPrice: appliedUnitPrice,
								appliedSubtotal: {
									inclSavings: appliedSubTotal.inclSavings,
									exclSavings: appliedSubTotal.exclSavings
								}
							};
						}
					}
					if (isTransportDeskMode && useExclusive) {
						let { appliedUnitPrice, appliedSubTotal } = getDelliveryUnitAndSubTotalsForTransportDesk(sol);
						solution.programmaticPriceOverride = undefined;
						solution.isLocked = true;
						solution.appliedPrices = {
							appliedUnitPrice: appliedUnitPrice,
							appliedSubtotal: {
								inclSavings: appliedSubTotal.inclSavings,
								exclSavings: appliedSubTotal.exclSavings
							}
						};
					}
					serviceSolutions.push(solution);
				}
			});
			request.solutions = serviceSolutions.length > 0 ? serviceSolutions : undefined;
		}

		let response = await httpService.post(link, {
			body: request,
			headers: config.headers
		});
		if (response && !response.error && response.type !== "EXTERNAL_CLIENT_ERROR" && response.type !== "INTERNAL_ERROR") {
			let {
				items,
				orderSubtotal,
				orderSummary,
				timezoneId,
				deliveryServices,
				solutions,
				currencyCode = currency,
				providedServices,
				orderSavings,
				couponInfo
			} = response;
			cartItems.forEach(itm => {
				if (itm.isCombination) {
					itm.childItems.forEach(child => {
						updateItemPrice(items, child, currencyCode, employeeDiscount);
						if (child.isProduct) {
							child.childItems.forEach(subChild => {
								updateItemPrice(items, subChild, currencyCode, priceViewType);
							});
						}
					});
					new ShoppingCartItemFactory().updateGroupQuantity(itm, itm.quantity, false);
				} else {
					updateItemPrice(items, itm, currencyCode, priceViewType);
					if (itm.isProduct) {
						itm.childItems.forEach(subChild => {
							updateItemPrice(items, subChild, currencyCode, priceViewType);
						});
					}
				}
			});

			//update solutions
			if (solutions) {
				solutions.forEach(sol => {
					let service = services.find(x => x.solutionId === sol.solutionId);
					if (sol.price) {
						sol.price.forEach(slPrice => {
							if (slPrice.inclSavings) {
								updatepriceSavingsCurrencyAndTax(slPrice.inclSavings, currencyCode);
							}
							if (slPrice.exclSavings) {
								updatepriceSavingsCurrencyAndTax(slPrice.exclSavings, currencyCode);
							}
						});
					}
					if (service) {
						sol.currencyCode = currencyCode;
						sol.deliverySolution = service;
					}
				});
			}

			//update deliveryServices
			if (deliveryServices) {
				let allDelServices = isCCdDelivery
					? services
							.filter(x => x.isCentralDelivery)
							.map(function (p) {
								return p.subDeliveries;
							})
							.reduce(function (a, b) {
								return a.concat(b);
							}, [])
					: services.filter(x => x.isDelivery);
				deliveryServices.forEach(dlService => {
					let sldelivery = allDelServices.find(x => x.serviceItemId === dlService.deliveryMethodId || x.serviceItemNo === dlService.deliveryMethodId);
					if (dlService.deliveryPrice) {
						dlService.deliveryPrice.forEach(dlPrice => {
							if (dlPrice.inclSavings) {
								updatepriceSavingsCurrencyAndTax(dlPrice.inclSavings, currencyCode);
							}
							if (dlPrice.exclSavings) {
								updatepriceSavingsCurrencyAndTax(dlPrice.exclSavings, currencyCode);
							}
						});
					}
					if (sldelivery) {
						dlService.selectedDelivery = sldelivery;
					}
				});
			}

			//update providedServices
			if (providedServices) {
				let providedServiceList = services.filter(x => x.isProvidedService);
				providedServices.forEach(prvService => {
					let providedService = providedServiceList.find(x => x.serviceProductId === prvService.serviceProductId);
					if (prvService.servicePrice) {
						prvService.servicePrice.forEach(dlPrice => {
							if (dlPrice.inclSavings) {
								updatepriceSavingsCurrencyAndTax(dlPrice.inclSavings, currencyCode);
							}
							if (dlPrice.exclSavings) {
								updatepriceSavingsCurrencyAndTax(dlPrice.exclSavings, currencyCode);
							}
						});
					}
					if (providedService) {
						prvService.selectedService = providedService;
					}
				});
			}
			//update orderSummary
			if (orderSummary && orderSummary.summary) {
				orderSummary.summary.forEach(ordSum => {
					if (ordSum.inclSavings) {
						updatepriceSavingsCurrencyAndTax(ordSum.inclSavings, currencyCode);
					}
					if (ordSum.exclSavings) {
						updatepriceSavingsCurrencyAndTax(ordSum.exclSavings, currencyCode);
					}
				});
			}
			//update orderSubtotal
			if (orderSubtotal) {
				orderSubtotal.forEach(subPrice => {
					if (subPrice.product) {
						if (subPrice.product.inclSavings) {
							updatepriceSavingsCurrencyAndTax(subPrice.product.inclSavings, currencyCode);
						}
						if (subPrice.product.exclSavings) {
							updatepriceSavingsCurrencyAndTax(subPrice.product.exclSavings, currencyCode);
						}
					}
					if (subPrice.delivery) {
						if (subPrice.delivery.inclSavings) {
							updatepriceSavingsCurrencyAndTax(subPrice.delivery.inclSavings, currencyCode);
						}
						if (subPrice.delivery.exclSavings) {
							updatepriceSavingsCurrencyAndTax(subPrice.delivery.exclSavings, currencyCode);
						}
					}
					if (subPrice.service) {
						if (subPrice.service.inclSavings) {
							updatepriceSavingsCurrencyAndTax(subPrice.service.inclSavings, currencyCode);
						}
						if (subPrice.service.exclSavings) {
							updatepriceSavingsCurrencyAndTax(subPrice.service.exclSavings, currencyCode);
						}
					}
					if (subPrice.payToProvider) {
						if (subPrice.payToProvider.inclSavings) {
							updatepriceSavingsCurrencyAndTax(subPrice.payToProvider.inclSavings, currencyCode);
						}
						if (subPrice.payToProvider.exclSavings) {
							updatepriceSavingsCurrencyAndTax(subPrice.payToProvider.exclSavings, currencyCode);
						}
					}
				});
			}
			let hasRefundItems = allItems.filter(x => x.isRefund).length > 0;
			let subtotalFamilyPrice = getSubTotalPrices(response, FamilyViewType);
			if (subtotalFamilyPrice && subtotalFamilyPrice.product && hasRefundItems) {
				subtotalFamilyPrice.product.Value = 0;
			}
			if (subtotalFamilyPrice && subtotalFamilyPrice.delivery && hasRefundItems) {
				subtotalFamilyPrice.delivery.Value = 0;
			}
			if (subtotalFamilyPrice && subtotalFamilyPrice.service && hasRefundItems) {
				subtotalFamilyPrice.service.Value = 0;
			}

			let subtotalPrivatePrice = getSubTotalPrices(response, priceViewType);
			if (subtotalPrivatePrice && subtotalPrivatePrice.product && hasRefundItems) {
				subtotalPrivatePrice.product.Value = 0;
			}
			if (subtotalPrivatePrice && subtotalPrivatePrice.delivery && hasRefundItems) {
				subtotalPrivatePrice.delivery.Value = 0;
			}
			if (subtotalPrivatePrice && subtotalPrivatePrice.service && hasRefundItems) {
				subtotalPrivatePrice.service.Value = 0;
			}
			if (hasFamily) {
				if (!subtotalPrivatePrice) {
					subtotalPrivatePrice = subtotalFamilyPrice;
				}
			}

			let totalFamilyPrice = getSummaryPrices(response, FamilyViewType);
			if (totalFamilyPrice && hasRefundItems) {
				totalFamilyPrice.Value = 0;
			}
			let totalPrice = getSummaryPrices(response, priceViewType);
			if (totalPrice && hasRefundItems) {
				totalPrice.Value = 0;
			}
			if (hasFamily) {
				if (!totalPrice) {
					totalPrice = totalFamilyPrice;
				}
			}

			let vat = getVat(response, priceViewType);
			if (hasFamily) {
				vat = getVat(response, FamilyViewType);
			}
			if (vat && hasRefundItems) {
				vat.Value = 0;
			}

			let savings = getOrderSaving(response, priceViewType);
			if (hasFamily) {
				savings = getOrderSaving(response, FamilyViewType);
			}
			let servicePrice = {
				totalServicePrice: new PriceModel({
					Value:
						parseFloat((subtotalPrivatePrice && subtotalPrivatePrice.delivery && subtotalPrivatePrice.delivery.Value) || 0) +
						parseFloat((subtotalPrivatePrice && subtotalPrivatePrice.service && subtotalPrivatePrice.service.Value) || 0),
					Currency: currencyCode,
					Type: NORMAL
				}),
				delivery: subtotalPrivatePrice && subtotalPrivatePrice.delivery,
				service: subtotalPrivatePrice && subtotalPrivatePrice.service,
				paytoIkeaService: subtotalPrivatePrice && subtotalPrivatePrice.service,
				payToSpPrice: subtotalPrivatePrice && subtotalPrivatePrice.payToSpPrice
			};
			return Promise.resolve({
				cartItems,
				salesPriceData: {
					couponInfo,
					orderSummary,
					orderSubtotal,
					timezoneId,
					deliveryServices,
					providedServices,
					solutions,
					subtotalPrivatePrice,
					subtotalFamilyPrice,
					totalPrice,
					totalFamilyPrice,
					vat,
					savings,
					servicePrice,
					currencyCode,
					orderSavings,
					discounts
				}
			});
		} else {
			AppLogger.warn("Salesprice error", "EXTERNAL_CLIENT_ERROR", {
				Error: response.error
			});
		}
		return Promise.reject({ error: "nodata" });
	} catch (error) {
		return Promise.reject(error);
	}
};

const updatepriceSavingsCurrencyAndTax = (priceSavings, currecy) => {
	priceSavings.currencyCode = currecy;
	priceSavings.taxAmount = priceSavings.indicativeTax || 0;
	if (!priceSavings.priceInclTax) {
		priceSavings.priceInclTax = priceSavings.priceExclTax || 0;
	}

	if (priceSavings.taxDetails) {
		priceSavings.taxDetails.forEach(tax => {
			tax.taxPercentage = tax.taxPercent;
		});
	} else {
		priceSavings.taxDetails = [];
	}
	return priceSavings;
};
const updateUnitPriceSavingsCurrencyAndTax = (priceSavings, currecy) => {
	priceSavings.currencyCode = currecy;
	if (!priceSavings.priceInclTax) {
		priceSavings.priceInclTax = priceSavings.priceExclTax || 0;
	}

	return priceSavings;
};

const createDeliveryServices = (services, countryCode, maxId) => {
	let delServices = services.filter(x => x.isCentralDelivery || x.isDelivery);
	let delRequests = [];
	if (delServices.length === 0) {
		return { delRequests: [], lineId: maxId };
	}
	let id = maxId;
	delServices.forEach(ser => {
		if (ser.isCentralDelivery) {
			ser.subDeliveries.forEach(sub => {
				id = id + 1;
				delRequests.push(mapCcdService(sub, ser, countryCode));
			});
		} else {
			id = id + 1;
			delRequests.push(mapLcdService(ser, id, countryCode));
		}
	});
	let lineIds = delRequests.map(x => x.lineId);
	let delId = Math.max(...lineIds);
	if (isNaN(delId)) {
		maxId = id;
	} else {
		maxId = delId + 1;
	}

	return { delRequests, lineId: maxId };
};

const createProvidedServices = (services, countryCode, maxId) => {
	let prvServices = services.filter(x => x.isProvidedService);
	let id = maxId;
	let provRequests = [];
	prvServices &&
		prvServices.length > 0 &&
		prvServices.forEach(ser => {
			id = id + 1;
			provRequests.push(mapProvidedService(ser, id, countryCode));
		});
	maxId = id;
	return { provRequests, srvLineId: maxId };
};

const mapLcdService = (delSer, lineId, countryCode) => {
	let delRequest = {
		deliveryMethod: delSer.deliveryMethod,
		deliveryMethodId: delSer.serviceItemNo.includes("SGR") ? delSer.serviceItemNo : `SGR${delSer.serviceItemNo}`,
		deliveryType: "LCD",
		lineId: lineId
	};
	if (delSer.price) {
		let price = delSer.price.priceInclTax || delSer.price.priceExclTax || 0;
		delRequest.programmaticPriceOverride = {};
		if (usesExclPriceMarket(countryCode)) {
			delRequest.programmaticPriceOverride.priceExclTax = price;
		} else {
			delRequest.programmaticPriceOverride.priceInclTax = price;
		}
	}
	if (delSer.referenceItems && delSer.referenceItems.length > 0) {
		delRequest.itemLineReferences = [];
		let refItems = [];
		delSer.referenceItems.forEach(refitem => {
			refItems.push({
				quantity: refitem.serviceReference.quantity,
				lineId: refitem.serviceReference.lineId
			});
		});
		delRequest.itemLineReferences = refItems;
	}
	return delRequest;
};

const mapProvidedService = (prvService, lineId, countryCode) => {
	let delRequest = {
		lineId: lineId,
		priceCalculation: {
			calculationType: prvService.priceCalculationUnit
		},
		serviceItemNo: prvService.serviceItemNo.includes("SGR") ? prvService.serviceItemNo : `SGR${prvService.serviceItemNo}`,
		serviceItemType: prvService.serviceType,
		serviceProductId: prvService.serviceProductId
	};
	if (prvService.price) {
		let price = prvService.price.priceInclTax || prvService.price.priceExclTax || 0;
		delRequest.programmaticPriceOverride = {};
		if (usesExclPriceMarket(countryCode)) {
			delRequest.programmaticPriceOverride.priceExclTax = price;
		} else {
			delRequest.programmaticPriceOverride.priceInclTax = price;
		}
	}
	if (prvService.isLocked) {
		if (prvService.orderNo && prvService.price.priceInclTax !== 0) {
			let { appliedUnitPrice, appliedSubTotal } = getDelliveryUnitAndSubTotalsFromOrderItem(prvService);
			delRequest.isLocked = true;
			delRequest.appliedPrices = {
				appliedUnitPrice: appliedUnitPrice,
				appliedSubtotal: {
					inclSavings: appliedSubTotal.inclSavings,
					exclSavings: appliedSubTotal.exclSavings
				}
			};
		}
	}
	if (prvService.isTransportDeskMode && prvService.useExclusive) {
		delRequest.programmaticPriceOverride = undefined;
		let { appliedUnitPrice, appliedSubTotal } = getDelliveryUnitAndSubTotalsForTransportDesk(prvService);
		delRequest.isLocked = true;
		delRequest.appliedPrices = {
			appliedUnitPrice: appliedUnitPrice,
			appliedSubtotal: {
				inclSavings: appliedSubTotal.inclSavings,
				exclSavings: appliedSubTotal.exclSavings
			}
		};
	}
	if (prvService.referenceItems && prvService.referenceItems.length > 0) {
		let refItems = [];
		prvService.referenceItems.forEach(refitem => {
			refItems.push({
				quantity: refitem.serviceReference.quantity,
				lineId: refitem.serviceReference.lineId
			});
		});
		delRequest.itemLineReferences = refItems;
	}
	return delRequest;
};

const mapCcdService = (delSer, sol, countryCode) => {
	let delRequest = {
		deliveryMethod: delSer.serviceItemId,
		deliveryMethodId: delSer.serviceItemId,
		deliveryType: "ISOM",
		lineId: delSer.deliveryId
	};

	if (delSer.price) {
		let price = delSer.price.priceInclTax || delSer.price.priceExclTax || 0;
		delRequest.programmaticPriceOverride = {};
		if (usesExclPriceMarket(countryCode)) {
			delRequest.programmaticPriceOverride.priceExclTax = price;
		} else {
			delRequest.programmaticPriceOverride.priceInclTax = price;
		}
	}
	if (delSer.isLocked) {
		if (delSer.orderNo && delSer.price.priceInclTax !== 0) {
			let { appliedUnitPrice, appliedSubTotal } = getDelliveryUnitAndSubTotalsFromOrderItem(delSer);
			delRequest.isLocked = true;
			delRequest.appliedPrices = {
				appliedUnitPrice: appliedUnitPrice,
				appliedSubtotal: {
					inclSavings: appliedSubTotal.inclSavings,
					exclSavings: appliedSubTotal.exclSavings
				}
			};
		}
	}
	if (delSer.isTransportDeskMode && delSer.useExclusive) {
		delRequest.programmaticPriceOverride = undefined;
		let { appliedUnitPrice, appliedSubTotal } = getDelliveryUnitAndSubTotalsForTransportDesk(delSer);
		delRequest.isLocked = true;
		delRequest.appliedPrices = {
			appliedUnitPrice: appliedUnitPrice,
			appliedSubtotal: {
				inclSavings: appliedSubTotal.inclSavings,
				exclSavings: appliedSubTotal.exclSavings
			}
		};
	}
	if (delSer.referenceItems && delSer.referenceItems.length > 0) {
		let refItems = [];
		delSer.referenceItems.forEach(refitem => {
			refItems.push({
				quantity: refitem.serviceReference.quantity,
				lineId: refitem.serviceReference.lineId
			});
		});
		delRequest.itemLineReferences = refItems;
		delRequest.solutionReference = sol.solutionId;
	}
	return delRequest;
};

const updateItemPrice = (speItems, cartItem, storeCurrency, priceViewType) => {
	let item = speItems.find(x => x.lineId.toString() === cartItem.id.toString());
	if (item) {
		let { subtotals, unitPrice, discountedUnitPrice } = item;

		if (subtotals && unitPrice) {
			let { prices } = subtotals;

			let unitnormalPrice = unitPrice.find(x => x.type === "RegularSalesUnitPrice");
			let unitfamilyPrice = unitPrice.find(x => x.type === "IKEAFamilySalesUnitPrice");

			if (discountedUnitPrice) {
				let familyPriceReg = discountedUnitPrice.find(x => x.viewType == "Family" && x.priceType === "RegularSalesUnitPrice");

				if (familyPriceReg) {
					unitnormalPrice.discountedPrice = {
						indicativeTax: familyPriceReg.indicativeTax,
						priceExclTax: familyPriceReg.priceExclTax,
						priceInclTax: familyPriceReg.priceInclTax
					};
				}

				if (unitfamilyPrice) {
					familyPriceReg = discountedUnitPrice.find(x => x.viewType == "Family" && x.priceType === "IKEAFamilySalesUnitPrice");

					if (familyPriceReg) {
						unitfamilyPrice.discountedPrice = {
							indicativeTax: familyPriceReg.indicativeTax,
							priceExclTax: familyPriceReg.priceExclTax,
							priceInclTax: familyPriceReg.priceInclTax
						};
					}
				}
			}

			cartItem.price = mapUnitPrice(unitnormalPrice, NORMAL, storeCurrency);

			cartItem.familyPrice = mapUnitPrice(unitfamilyPrice || unitnormalPrice, FAMILY, storeCurrency);

			let type = priceViewType;
			let normalPrice = prices.find(x => x.viewType === type);

			let familyPrice = prices.find(x => x.viewType === FamilyViewType);

			cartItem.totalPrice = mapTotalPrice(normalPrice || familyPrice, NORMAL, storeCurrency);

			cartItem.totalFamilyPrice = mapTotalPrice(familyPrice || normalPrice, FAMILY, storeCurrency);

			if (cartItem.isRefund) {
				cartItem.price.Value = cartItem.salesPrice.value;
				cartItem.familyPrice.Value = cartItem.salesPrice.value;

				cartItem.totalPrice.Value = cartItem.salesPrice.value * cartItem.quantity;

				cartItem.totalFamilyPrice.Value = cartItem.salesPrice.value * cartItem.quantity;
			}

			if (subtotals && subtotals.prices) {
				subtotals.prices.forEach(priceData => {
					if (priceData.exclSavings) {
						updatepriceSavingsCurrencyAndTax(priceData.exclSavings, storeCurrency);
					}
					if (priceData.inclSavings) {
						updatepriceSavingsCurrencyAndTax(priceData.inclSavings, storeCurrency);
					}
				});
			}

			if (subtotals && subtotals.prices) {
				subtotals.prices.forEach(priceData => {
					if (priceData.exclSavings) {
						updatepriceSavingsCurrencyAndTax(priceData.exclSavings, storeCurrency);
					}
					if (priceData.inclSavings) {
						updatepriceSavingsCurrencyAndTax(priceData.inclSavings, storeCurrency);
					}
				});
			}
			if (unitPrice) {
				unitPrice.forEach(priceData => {
					if (priceData.currentPrice) {
						updateUnitPriceSavingsCurrencyAndTax(priceData.currentPrice, storeCurrency);
					}
					if (priceData.discountedPrice) {
						updateUnitPriceSavingsCurrencyAndTax(priceData.discountedPrice, storeCurrency);
					}
				});
			}
			cartItem.unitPrice = unitPrice;
			cartItem.subtotals = subtotals;
		}
	}
};

const mapTotalPrice = (spePrice, type, storeCurrency) => {
	let { exclSavings, inclSavings } = spePrice;
	if (exclSavings) {
		updatepriceSavingsCurrencyAndTax(exclSavings);
	}
	if (inclSavings) {
		updatepriceSavingsCurrencyAndTax(inclSavings);
	}
	return {
		...exclSavings,
		Currency: storeCurrency,
		Value: inclSavings.priceInclTax || inclSavings.priceExclTax,
		Type: type,
		inclSavings: inclSavings,
		exclSavings: exclSavings
	};
};

const mapUnitPrice = (spePrice, type, storeCurrency) => {
	let { currentPrice, discountedPrice } = spePrice;
	if (currentPrice && !currentPrice.priceInclTax && currentPrice.priceExclTax) {
		currentPrice.priceInclTax = currentPrice.priceExclTax;
	}
	if (!discountedPrice) {
		discountedPrice = currentPrice;
	}
	if (discountedPrice && discountedPrice.priceExclTax && !discountedPrice.priceInclTax) {
		discountedPrice.priceInclTax = discountedPrice.priceExclTax;
	}
	if (currentPrice && !currentPrice.indicativeTax) {
		currentPrice.indicativeTax = 0;
	}
	if (discountedPrice && !discountedPrice.indicativeTax) {
		discountedPrice.indicativeTax = 0;
	}

	return {
		...currentPrice,
		Currency: storeCurrency,
		Value: currentPrice.priceInclTax,
		Type: type,
		discountedPrice: {
			...discountedPrice,
			taxAmount: discountedPrice && discountedPrice.indicativeTax,
			currencyCode: storeCurrency
		},
		salesPrice: {
			...discountedPrice,
			taxAmount: discountedPrice && discountedPrice.indicativeTax,
			currencyCode: storeCurrency
		},
		listPrice: {
			...currentPrice,
			taxAmount: currentPrice && currentPrice.indicativeTax,
			currencyCode: storeCurrency
		}
	};
};

const createItems = allItems => {
	let items = [];

	allItems.forEach(item => {
		let cartItem = item;
		let lineItem = {
			itemType: item.itemType,
			itemNo: item.itemNo,
			lineId: item.id,
			quantity: item.quantity === 0 ? 1 : item.quantity,
			isReferenceItem: item.quantity === 0 ? true : false
		};
		if (lineItem.itemType === "SPR") {
			lineItem.itemLineReferences = item.itemLineReferences;
		}
		if (cartItem && item.quantity > 0 && cartItem.importDetails && cartItem.receiptPriceDetails) {
			let { appliedUnitPrice, appliedSubTotal } = getUnitAndSubTotalsForItem(cartItem);
			lineItem.isLocked = true;
			lineItem.appliedPrices = {
				appliedUnitPrice: appliedUnitPrice,
				appliedSubtotal: {
					inclSavings: appliedSubTotal.inclSavings,
					exclSavings: appliedSubTotal.exclSavings
				}
			};
		} else if (cartItem && item.quantity > 0 && cartItem.orderItemReference) {
			//todo:
			let { appliedUnitPrice, appliedSubTotal } = getUnitAndSubTotalsForItem(cartItem);
			if (cartItem.quantity === cartItem.orderItemReference.quantity) {
				lineItem.isLocked = true;
				lineItem.appliedPrices = {
					appliedUnitPrice: appliedUnitPrice,
					appliedSubtotal: {
						inclSavings: appliedSubTotal.inclSavings,
						exclSavings: appliedSubTotal.exclSavings
					}
				};
			}
		}
		items.push(lineItem);
	});

	return items;
};

const updateCartPriceForQuotation = (cartItem, quotationCartItem, storeCurrency, priceViewType) => {
	const { unitPrice, subtotals } = quotationCartItem;
	let unitPrices = [{ ...unitPrice }];
	let subtotalsPrices = {
		prices: [{ ...subtotals.prices }]
	};
	let { prices } = subtotalsPrices;
	let unitnormalPrice = unitPrices.find(x => x.type === "RegularSalesUnitPrice");
	let unitfamilyPrice = unitPrices.find(x => x.type === "IKEAFamilySalesUnitPrice");
	cartItem.price = mapUnitPrice(unitnormalPrice || unitfamilyPrice, NORMAL, storeCurrency);
	cartItem.familyPrice = mapUnitPrice(unitfamilyPrice || unitnormalPrice, FAMILY, storeCurrency);
	let normalPrice = prices.find(x => x.viewType === priceViewType);
	let familyPrice = prices.find(x => x.viewType === FamilyViewType);
	cartItem.totalPrice = mapTotalPrice(normalPrice || familyPrice, NORMAL, storeCurrency);
	cartItem.totalFamilyPrice = mapTotalPrice(familyPrice || normalPrice, FAMILY, storeCurrency);
	cartItem.unitPrice = unitPrices;
	cartItem.subtotals = subtotalsPrices;
	return cartItem;
};

const getSubTotalPrices = (speResponse, pricetype) => {
	const orderSubtotal = speResponse && speResponse.orderSubtotal && speResponse.orderSubtotal.find(type => type.viewType === pricetype);
	if (orderSubtotal) {
		const { product, delivery, service, payToProvider } = orderSubtotal;
		let deliveryPricePrice;
		let totalItemPrice;
		let servicePrice;
		let payToProviderservicePrice;
		if (product) {
			let productPrice = getCurrentPrice(product);
			totalItemPrice = new PriceModel();
			totalItemPrice.Value = productPrice.priceInclTax;
			totalItemPrice.Currency = productPrice.currencyCode;
			totalItemPrice.Type = NORMAL;
		}

		if (delivery) {
			let totalDeliveryPrice = getCurrentPrice(delivery);
			deliveryPricePrice = new PriceModel();
			deliveryPricePrice.Value = totalDeliveryPrice.priceInclTax;
			deliveryPricePrice.Currency = totalDeliveryPrice.currencyCode;
			deliveryPricePrice.Type = NORMAL;
		}
		if (service) {
			let totalServicePrice = getCurrentPrice(service);
			servicePrice = new PriceModel();
			servicePrice.Value = totalServicePrice.priceInclTax;
			servicePrice.Currency = totalServicePrice.currencyCode;
			servicePrice.Type = NORMAL;
		}
		if (payToProvider) {
			let totalPayToProviderServicePrice = getCurrentPrice(payToProvider);
			payToProviderservicePrice = {
				Value: totalPayToProviderServicePrice.priceInclTax,
				Currency: totalPayToProviderServicePrice.currencyCode,
				Type: NORMAL
			};
		}
		return {
			product: totalItemPrice || {
				Value: 0,
				Type: NORMAL
			},
			delivery: deliveryPricePrice,
			service: servicePrice,
			payToSpPrice: payToProviderservicePrice
		};
	}
	return {
		product: {
			Value: 0,
			Type: NORMAL
		},
		delivery: {
			Value: 0,
			Type: NORMAL
		},
		service: {
			Value: 0,
			Type: NORMAL
		}
	};
};

const getSummaryPrices = (speResponse, pricetype) => {
	try {
		const summary = speResponse && speResponse.orderSummary && speResponse.orderSummary.summary.find(type => type.viewType === pricetype);

		let totalPrice = getCurrentPrice(summary);
		let total = new PriceModel();
		total.Value = totalPrice.priceInclTax;
		total.Currency = totalPrice.currencyCode;
		total.Type = NORMAL;

		return total;
	} catch {
		return undefined;
	}
};

const getVat = (speResponse, priceType) => {
	try {
		let summary = speResponse && speResponse.orderSummary && speResponse.orderSummary.summary.find(type => type.viewType === priceType);

		let totalPrice = getCurrentPrice(summary);
		let vat = new PriceModel();
		vat.Value = totalPrice.indicativeTax || 0;
		vat.Currency = totalPrice.currencyCode;
		vat.Type = NORMAL;

		return vat;
	} catch (error) {
		return undefined;
	}
};

const getOrderSaving = (speResponse, priceType) => {
	try {
		let summary = speResponse && speResponse.orderSummary && speResponse.orderSummary.summary.find(type => type.viewType === priceType);

		return summary.savings;
	} catch (error) {
		return undefined;
	}
};

const OrderAmountCalculatorService = {
	getCartPrice,
	updateCartPriceForQuotation
};
export default OrderAmountCalculatorService;

const getCurrentPrice = ({ inclSavings, exclSavings }) => {
	return JSON.stringify(inclSavings) === JSON.stringify(exclSavings) ? exclSavings : inclSavings;
};

const getUnitAndSubTotalsForItem = cartItem => {
	let { taxDetails, unitGrossAmount, unitNetAmount, unitTaxAmount } = cartItem.receiptPriceDetails;
	let unitPrice = calcTotalPrice(unitGrossAmount, unitNetAmount, unitTaxAmount, 1);
	let currentPrice = calcTotalPrice(unitGrossAmount, unitNetAmount, unitTaxAmount, cartItem.quantity);

	let appliedIncl = {
		priceInclTax: currentPrice.priceInclTax,
		priceExclTax: currentPrice.priceExclTax,
		indicativeTax: currentPrice.indicativeTax,
		taxDetails: taxDetails.map(tax => {
			return {
				taxCode: tax.taxCode,
				taxType: tax.taxType,
				// taxPercent: cartItem.excludeTax ? 0 : tax.taxPercent,
				// taxableAmount: cartItem.excludeTax ? 0 : currentPrice.priceExclTax,
				// taxAmount: cartItem.excludeTax ? 0 : currentPrice.indicativeTax,
				taxPercent: tax.taxPercent,
				taxableAmount: currentPrice.priceExclTax,
				taxAmount: currentPrice.indicativeTax,
				taxJurisdiction: tax.taxJurisdiction
			};
		})
	};
	return {
		appliedSubTotal: {
			exclSavings: appliedIncl,
			inclSavings: appliedIncl
		},
		appliedUnitPrice: {
			priceInclTax: unitPrice.priceInclTax,
			priceExclTax: unitPrice.priceExclTax,
			indicativeTax: unitPrice.indicativeTax
		}
	};
};

const getDelliveryUnitAndSubTotalsFromOrderItem = deliveryItem => {
	let { priceInclTax, priceExclTax, taxDetails = [], taxAmount = 0 } = deliveryItem.price;
	let grossAmount = priceInclTax;
	let indicativeTax = priceInclTax > 0 ? taxAmount || Math.abs(parseFloat(priceInclTax - priceExclTax)) : 0;
	let netAmount = priceExclTax;
	let currentPrice = calcPrice(grossAmount, netAmount, indicativeTax, 1, 1);
	let unitPrice = calcPrice(grossAmount, netAmount, indicativeTax, 1, 1);
	let appliedIncl = {
		priceInclTax: priceInclTax,
		priceExclTax: priceExclTax,
		indicativeTax: indicativeTax,
		taxDetails: taxDetails.map(tax => {
			return {
				taxCode: tax.taxCode,
				taxType: tax.taxType,
				taxPercent: tax.taxPercent || tax.taxPercentage,
				taxableAmount: currentPrice.priceExclTax,
				taxAmount: currentPrice.indicativeTax
			};
		})
	};
	return {
		appliedSubTotal: {
			exclSavings: appliedIncl,
			inclSavings: appliedIncl
		},
		appliedUnitPrice: {
			priceInclTax: unitPrice.priceInclTax,
			priceExclTax: unitPrice.priceExclTax,
			indicativeTax: unitPrice.indicativeTax
		}
	};
};

const getDelliveryUnitAndSubTotalsForTransportDesk = deliveryItem => {
	let { priceInclTax, priceExclTax } = deliveryItem.price;

	let appliedIncl = {
		priceInclTax: priceInclTax,
		priceExclTax: priceExclTax,
		indicativeTax: 0,
		taxDetails: []
	};
	return {
		appliedSubTotal: {
			exclSavings: appliedIncl,
			inclSavings: appliedIncl
		},
		appliedUnitPrice: {
			priceInclTax: priceInclTax,
			priceExclTax: priceInclTax,
			indicativeTax: 0
		}
	};
};
