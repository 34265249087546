import styled from "styled-components";
import React from "react";
import warning from "@ingka/ssr-icon/paths/warning";
import IconWithText from "@ingka/icon-with-text";
import { useModelPopup } from "../../components/modelPopup";
import { getMessage } from "@coworker/sharedlibrary";
import SalesCoworkerConfiguration from "../../store/salesCoworkerConfiguration";
import { useSelector } from "react-redux";

const TopBannerElement = styled.div`
	padding: 12px 120px;
	text-align: center;
	background-color: #ffa524;
	color: #111;
	font-size: 12px;
	position: fixed;

	width: calc(100% + 200px);
	left: -100px;
	padding-top: calc(env(safe-area-inset-top) + 12px);

	display: ${props => (props.guestMode ? "flex" : "none")};
	justify-content: center;
	align-items: center;
	height: ${props => (props.guestMode ? "auto" : "0")};
`;

const ReadMore = styled.span`
	text-decoration: underline;
	cursor: pointer;
`;

const TopBanner = ({ guestMode, topBannerHeight, messages }) => {
	const isRestrictedInMarket = SalesCoworkerConfiguration.isRestrictedInMarket();
	const { coworkerData } = useSelector(state => state.appController);
	const { getModel } = useModelPopup();
	let topBannerRef = React.useRef(null);
	React.useEffect(() => {
		setBannerHeight();
		window.addEventListener("orientationchange", setBannerHeight);
	}, []);

	React.useEffect(() => {
		setBannerHeight();
	}, [coworkerData]);

	function setBannerHeight() {
		setTimeout(() => {
			if (topBannerRef.current) {
				const { bottom } = topBannerRef.current.getBoundingClientRect();
				topBannerHeight(bottom);
			}
		}, 400);
	}

	async function onReadMoreClick() {
		await getModel("helpSectionDialog", {
			section: "faq",
			goto: "guestMode"
		});
	}
	return (
		<TopBannerElement ref={topBannerRef} guestMode={guestMode || isRestrictedInMarket}>
			<IconWithText ssrIcon={warning} title="" />
			<Guest messages={messages} onReadMoreClick={onReadMoreClick} isRestrictedInMarket={isRestrictedInMarket}></Guest>
		</TopBannerElement>
	);
};

export default TopBanner;
const Guest = ({ messages, onReadMoreClick, isRestrictedInMarket }) => {
	if (isRestrictedInMarket) {
		return <span>{messages.SG_LBL_Restricted_Mode || "You are not allowed to create order in this market. Please check with super user"}</span>;
	}
	return (
		<span>
			{getMessage("SG_LBL_UsingGuestMode", messages, "You are using SÄLJA GO! in guest mode.")}
			<ReadMore onClick={onReadMoreClick}>{getMessage("SG_LBL_ReadMoreSalja", messages, "Read more")}</ReadMore>.
		</span>
	);
};
