const isModelActive = () => {
	let modalOpened = document.getElementsByClassName("lightbox lightbox--dark lightbox--open");
	if (modalOpened && modalOpened.length > 0) {
		return true;
	}

	modalOpened = document.getElementsByClassName("modal-wrapper modal-wrapper--open");
	if (modalOpened && modalOpened.length > 0) {
		return true;
	}

	modalOpened = document.getElementsByClassName("inputpopup");
	if (modalOpened && modalOpened.length > 0) {
		return true;
	}

	return false;
};

export default isModelActive;
