import { goBack } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { getStoreConfigSelector } from "../../utils/shellObjectService";
import useMicrofrontend from "../../hooks/useMicroFrontend";
import { openOrder } from "../../store/cart/cartActions";

const MicroImportOrderView = props => {
	const dispatch = useDispatch();
	const { isTransportDeskMode, isModifyOrderinProgress } = useSelector(state => state.cartController);
	let config = getStoreConfigSelector();
	const { appId, appUrl, appName } = config.environment.microapps.recentOrderApp;

	const { isLoaded, recentorder } = useMicrofrontend(appId, appUrl, appName);
	const pageGoback = () => {
		dispatch(goBack());
	};

	const editOrder = () => {
		dispatch(goBack());
		dispatch(openOrder(props.match.params.orderNo));
		// dispatch(push(`/order/${props.match.params.orderNo}`));
	};

	React.useEffect(() => {
		if (!recentorder) return;
		config.view = "orderImport";
		config.order = props.location.state.order;
		config.orderNo = props.match.params.orderNo;
		config.isTransportDeskMode = isTransportDeskMode;
		config.isModifyOrderinProgress = isModifyOrderinProgress;
		config.close = () => {
			dispatch(goBack());
		};
		recentorder.SetSharedConfiguration(config);
		const { render, unMount, subscribe, unSubscribe } = recentorder;
		subscribe("HOST_GOBACK", pageGoback);
		subscribe("OPEN_ORDER", editOrder);

		render(appId, config);

		return () => {
			unSubscribe("HOST_GOBACK", pageGoback);
			unSubscribe("OPEN_ORDER", editOrder);
			unMount(appId);
		};
	}, [isLoaded]);

	if (!isLoaded) return null;
	if (!recentorder) return <div />;
	return <div id={appId} />;
};
export default MicroImportOrderView;
