import React from "react";
import { FormattedNumber } from "react-intl";
import styled from "styled-components";

import { GUID } from "@coworker/sharedlibrary";

import "../../styles/common_components/priceStyle.scss";

const PriceContainer = styled.div`
	font-size: ${props => (props.isDetailView ? "1.6rem" : "1rem")} !important;
`;

const formatProps = {
	style: "currency",
	minimumFractionDigits: 0
};

const hasPrice = price => {
	return price && price.Currency && price.Currency !== "" && price.Value && !isNaN(price.Value);
};

const PriceControl = ({ price, isDetailView = false, priceStyles }) => {
	if (!price || !hasPrice(price)) {
		return "";
	}

	let priceTypeClass = [];
	priceTypeClass.push("normalPrice");

	if (price.Type === "FAMILY") {
		priceTypeClass.push("familyPrice");
	}

	if (priceStyles && priceStyles.length > 0) {
		priceTypeClass = priceTypeClass.concat(priceStyles);
	}

	const unit = price.Unit;

	return (
		<PriceContainer key={GUID.Guid()} isDetailView={isDetailView}>
			<div className={priceTypeClass.join(" ")}>
				<FormattedNumber {...formatProps} currency={price.Currency} minimumFractionDigits={0} value={price.Value} />
				{unit && (
					<div>
						{"\xA0"}
						{unit}
					</div>
				)}
			</div>
		</PriceContainer>
	);
};

export default PriceControl;
