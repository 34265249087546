const dispatchEvent = (event, data) => window.dispatchEvent(new CustomEvent(event, { detail: data }));

export { dispatchEvent };

export const eventsToDispatch = {
	LOG: "HOST_LOG",
	ADD_ITEM_TO_CART: "ADD_ITEM_TO_CART",
	UPDATE_ITEM_QUANTITY: "UPDATE_ITEM_QUANTITY",
	SEND_IMPORT_DATA: "SEND_IMPORT_DATA",
	NAVIGATE: "NAVIGATE",
	SHOW_HOST_NOTIFICATION: "SHOW_HOST_NOTIFICATION",
	CLEAR_CART_NAVIGATE_HOME: "CLEAR_CART_NAVIGATE_HOME",
	OPEN_DRAFT_ORDER: "OPEN_DRAFT_ORDER",
	OPEN_ORDER: "OPEN_ORDER",
	HOST_GOBACK: "HOST_GOBACK",
	CLEAR_CUSTOMER: "CLEAR_CUSTOMER",
	SET_CUSTOMER: "SET_CUSTOMER",
	SET_CUSTOMER_BILLING: "SET_CUSTOMER_BILLING",
	SET_ICM_CUSTOMER: "SET_ICM_CUSTOMER",
	STORE_CHANGE: "STORE_CHANGE",
	GET_ADDRESS_SUGGESTION: "GET_ADDRESS_SUGGESTION",
	HOST_LOG: "HOST_LOG",
	BROWSE: "BROWSE",
	CLOSE_SERVICE_VIEW: "CLOSE_SERVICE_VIEW",
	ON_SERVICE_PROPOSAL_UPDATED: "ON_SERVICE_PROPOSAL_UPDATED",
	ON_SAVE_CUSTOMER: "ON_SAVE_CUSTOMER",
	SET_HEADER_HIDDEN: "SET_HEADER_HIDDEN",
	CLEAR_ALL_CUSTOMER: "CLEAR_ALL",
	ON_CLOSE_CUSTOMER_BANNER: "ON_CLOSE_CUSTOMER_BANNER",
	UPDATE_CART_ITEMS: "UPDATE_CART_ITEMS",
	UPDATE_ORDER_MODIFIED_STATE: "UPDATE_ORDER_MODIFIED_STATE",
	REBOOK_SERVICE: "REBOOK_SERVICE",
	USER_PROFILE_ATTRIBUTE_CHANGE: "USER_PROFILE_ATTRIBUTE_CHANGE",
	ON_EDIT_CUSTOMER_FORM: "ON_EDIT_CUSTOMER_FORM",
	CREATE_OR_UPDATE_CUSTOMER: "CREATE_OR_UPDATE_CUSTOMER",
	LOG_TO_NATIVE: "LOG_TO_NATIVE"
};
