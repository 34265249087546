/**
 * @param {String} img
 */
const getOrientation = async img => {
	const src = await fetch(img);

	const arrayBuffer = await src.arrayBuffer();
	const view = new DataView(arrayBuffer);
	if (view.getUint16(0, false) !== 0xffd8) {
		return -2;
	}

	const length = view.byteLength;
	let offset = 2;

	while (offset < length) {
		if (view.getUint16(offset + 2, false) <= 8) return -1;
		const marker = view.getUint16(offset, false);
		offset += 2;
		if (marker === 0xffe1) {
			// eslint-disable-next-line no-cond-assign
			if (view.getUint32((offset += 2), false) !== 0x45786966) {
				return -1;
			}

			const little = view.getUint16((offset += 6), false) === 0x4949;
			offset += view.getUint32(offset + 4, little);
			const tags = view.getUint16(offset, little);
			offset += 2;
			for (let i = 0; i < tags; i += 1) {
				if (view.getUint16(offset + i * 12, little) === 0x0112) {
					return view.getUint16(offset + i * 12 + 8, little);
				}
			}
		} else if ((marker & 0xff00) !== 0xff00) {
			// eslint-disable-line no-bitwise
			break;
		} else {
			offset += view.getUint16(offset, false);
		}
	}

	return -1;
};

/** @param {File} file */
export default file =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		const img = new Image();

		reader.readAsDataURL(file);
		reader.onload = event => {
			// @ts-ignore
			img.src = event && event.target && event.target.result;
		};

		img.onload = async () => {
			// width is constant 1000px since we want a 1000x1000 image
			// It is then used to calculate scaling factor to maintain aspect ratio
			const maxWidth = 1000;
			const width = img.width < maxWidth ? img.width : maxWidth;
			const scaleFactor = width / img.width;
			const elem = document.createElement("canvas");

			const ctx = elem.getContext("2d");

			const UA = navigator.userAgent;
			// We need to UA match browsers that doesn't auto rotate images upon upload from filepicker
			if (ctx) {
				// @ts-ignore
				if (
					(UA.includes("iPhone; CPU iPhone OS 13_") && !!Number(UA.charAt(UA.indexOf("13_") + 3) < 5)) ||
					// @ts-ignore
					(UA.includes("iPhone; CPU iPhone OS 1") && !!Number(UA.charAt(UA.indexOf("iPhone; CPU iPhone OS 1") + 23) < 3)) ||
					// @ts-ignore
					(UA.includes("Firefox/") && !!Number(UA.substring(UA.indexOf("Firefox/") + 8, UA.indexOf("Firefox/") + 10) < 69)) ||
					UA.indexOf("SamsungBrowser") !== -1
				) {
					const orientation = await getOrientation(img.currentSrc);

					if (orientation > 4 && orientation < 9) {
						elem.width = img.height * scaleFactor;
						elem.height = width;
					} else {
						elem.width = width;
						elem.height = img.height * scaleFactor;
					}

					switch (orientation) {
						case 2:
							ctx.transform(-1, 0, 0, 1, elem.width, 0);
							break;
						case 3:
							ctx.transform(-1, 0, 0, -1, elem.width, elem.height);
							break;
						case 4:
							ctx.transform(1, 0, 0, -1, 0, elem.height);
							break;
						case 5:
							ctx.transform(0, 1, 1, 0, 0, 0);
							break;
						case 6:
							ctx.transform(0, 1, -1, 0, img.height * scaleFactor, 0);
							break;
						case 7:
							ctx.transform(0, -1, -1, 0, elem.height, elem.width);
							break;
						case 8:
							ctx.transform(0, -1, 1, 0, 0, elem.height);
							break;
						default:
							break;
					}

					if (orientation > 4 && orientation < 9) {
						ctx.drawImage(img, 0, 0, elem.height, elem.width);
					} else {
						ctx.drawImage(img, 0, 0, elem.width, elem.height);
					}
					// Run below code if the browser has implented auto rotate image
				} else {
					elem.width = width;
					elem.height = img.height * scaleFactor;

					ctx.drawImage(img, 0, 0, elem.width, elem.height);
				}
			}

			const resizedImage = ctx && ctx.canvas.toDataURL("image/jpeg");

			resolve({
				imageData: resizedImage,
				size: {
					width: ctx.width,
					height: ctx.height
				},
				img: {
					data: resizedImage,
					height: img.height,
					width: img.width
				}
				//  img: img
			});
		};

		reader.addEventListener("error", () => reject(new Error("ImageResizeError")));
	});
