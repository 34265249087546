import SalesItemTypes from "@coworker/sharedlibrary/src/constants/salesItemTypes";
import PriceModel from "@coworker/sharedlibrary/src/models/priceModel";

const FAMILY = "FAMILY";
/**
 * @property id
 * @property retailItem
 * @property quantity
 * @property price
 * @property familyPrice
 * @property totalPrice
 * @property totalFamilyPrice
 * @property childItems
 * @property isCombination
 * @property itemId
 * @property name
 * @property itemDescription
 * @property availableStockToSell
 * @property canCreateOrder
 * @property totalQuantity
 * @property isProduct
 * @property isPlanner
 * @property isPtag
 * @property orderItemLinks
 * @property isExpanded
 * @property importDetails
 * @property allowedToDeliveryFromTransportDesk
 * @property soSalesItemNo
 * @property handedOverToTdDesk
 * @property handOverLocation
 * @class ShoppingCartItem
 */
class ShoppingCartItem {
	constructor() {
		this.id = "";
		this.retailItem = {};
		this.quantity = 1;
		this.price = {
			Value: 1
		};
		this.familyPrice = {
			Value: 1
		};
		this.totalPrice = {
			Value: 1
		};
		this.totalFamilyPrice = {
			Value: 1
		};
		this.childItems = [];
		this.isCombination = false;
		this.itemId = "";
		this.name;
		this.itemDescription;
		this.availableStockToSell;
		this.canCreateOrder;
		this.totalQuantity;
		this.isProduct;
		this.isPlanner;
		this.isPtag;
		this.isGroup;
		this.orderItemLinks;
		this.isExpanded;
		this.isTransportDeskItem;
		this.importDetails;
		this.allowedToDeliveryFromTransportDesk;
		this.soSalesItemNo;
		this.allowedModifications;
		this.canIncreaseQuantity;
		this.canDecreaseQuantity;
		this.canChangePrice;
		this.canRemoveItem;
		this.orderNo;
		this.isOrderLine;
		this.isRefund;
		this.handedOverToTdDesk = true;
	}
	setCartItemId(id) {
		this.id = id;
	}

	initProps(item, quantity, childItems) {
		this.retailItem = item;
		//needs to revisit the object to trim the properties
		this.quantity = quantity;
		this.totalQuantity = quantity;
		this.itemId = item.ItemId;
		this.itemType = item.ItemType;
		this.name = item.Name;
		this.itemDescription = item.itemDescription;
		this.productType = item.productType;

		this.price = new PriceModel({ ...this.retailItem.price });
		this.familyPrice = new PriceModel({ ...this.retailItem.familyPrice });
		this.familyPrice.Type = FAMILY;

		this.totalPrice = new PriceModel({ ...this.retailItem.price });
		this.totalPrice.Value = this.totalPrice.Value * this.quantity;

		this.totalFamilyPrice = new PriceModel({ ...this.retailItem.familyPrice });
		this.totalFamilyPrice.Value = this.totalFamilyPrice.Value * this.quantity;
		this.totalFamilyPrice.Type = FAMILY;
		this.childItems = [];
		if (childItems) {
			childItems.forEach(child => {
				this.childItems.push(child);
			});
		}
		this.canCreateOrder = this.quantity < this.availableStockToSell;
	}

	initItem(item, quantity, childItems, isCombination) {
		this.childItems = [];
		this.isCombination = isCombination;
		this.isGroup = item.ItemType === SalesItemTypes.GROUP;
		this.availableStockToSell = item.availableStockToSell;
		this.isProduct = item.ItemType === SalesItemTypes.SPR;
		this.initProps(item, quantity, childItems);
	}

	initOrderItem(orderItem) {
		var item = orderItem.retailItem;
		this.childItems = [];
		this.isCombination = false;
		this.availableStockToSell = item.availableStockToSell;
		this.orderItemLinks = orderItem.links;
		this.initProps(item, orderItem.quantity, []);
		if (!this.isCombination) {
			if (item.ItemType === SalesItemTypes.SPR) {
				this.isProduct = true;
			}
		} else {
			this.isPlanner = item.isPlanner;
		}
	}
	updateQuantity(newQuantity) {
		this.quantity = newQuantity;
		this.totalPrice.Value = this.retailItem.price.Value * this.quantity;
		this.totalFamilyPrice.Value = this.retailItem.familyPrice.Value * this.quantity;
		this.canCreateOrder = this.quantity <= this.availableStockToSell;
	}
	setSoSalesItemNo(id) {
		this.soSalesItemNo = id;
	}
}

export default ShoppingCartItem;
