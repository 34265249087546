import InputField from "@ingka/input-field";
import React from "react";
import styled from "styled-components";
import FormField from "@ingka/form-field";

import { Container, Label } from "./../../../../components/sharedlibrary";

const InputContainer = styled(InputField)`
	label {
		font-size: 14px !important;
	}
	padding: 0px 0px;
`;

const CouponDiscountView = ({ setCouponDiscount, messages, couponDiscount, setShowDiscount }) => {
	const couponProp = {
		req: "*",
		shouldValidate: true,
		type: "text",
		autoComplete: "off",
		id: "couponPropName",
		defaultValue: couponDiscount.value.toString(),
		label: messages.SG_LBL_Coupon + " *" || "Coupon *",
		onBlur: e => {
			setShowDiscount && setShowDiscount(true);
			if (e.target.value) {
				setCouponDiscount({
					...couponDiscount,
					value: e.target.value
				});
			}
		},
		onFocus: () => {
			setShowDiscount && setShowDiscount(false);
		}
	};
	return (
		<Container fdCol>
			<Label fontsize={"12px"}>{messages.SG_LBL_RequiresCouponCode || "Requires coupon code"}</Label>
			<FormField shouldValidate={true}>
				<InputContainer {...couponProp}></InputContainer>
			</FormField>
		</Container>
	);
};

export default CouponDiscountView;
