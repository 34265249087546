import React from "react";
import { getStoreConfigSelector } from "../../utils/shellObjectService";
import useMicrofrontend from "../../hooks/useMicroFrontend";

const MicroUserAccountView = props => {
	let config = getStoreConfigSelector();

	const { appId, appUrl, appName } = config.environment.microapps.userprofileApp;

	const { isLoaded, userprofile } = useMicrofrontend(appId, appUrl, appName);

	React.useEffect(() => {
		if (!userprofile) return;
		let userprops = { ...config, ...props };
		userprofile.SetSharedConfiguration(userprops);
		const { render, unMount } = userprofile;
		render(`userprofile-${appId}`, userprops);

		return () => {
			unMount(`userprofile-${appId}`);
		};
	}, [isLoaded]); // eslint-disable-line

	if (!isLoaded) return <div>{"loading"}</div>;
	if (!userprofile) return <div>{"loading"}</div>;
	return (
		<div
			id={`userprofile-${appId}`}
			style={{
				width: props.width
			}}
		/>
	);
};
export default MicroUserAccountView;
