import { useSelector } from "react-redux";
import { getMessage } from "@coworker/sharedlibrary";
import Button from "@ingka/button/Button";
import React from "react";
import rightIcon from "@ingka/ssr-icon/paths/chevron-right";
import SSRIcon from "@ingka/ssr-icon";
import styled from "styled-components";

import { useCartModelPopup } from "./modelPopup";
import { Label } from "../../../components/sharedlibrary";

const OrderPriceSummaryButton = styled(Button)`
	width: 100%;
	*,
	label {
		cursor: pointer !important;
	}

	span {
		padding: 0;

		&:hover {
			background: white;
		}
	}
`;

const OrderPriceSummarySelection = () => {
	const { getCartModel } = useCartModelPopup();
	const { messages } = useSelector(state => state.intl);

	async function showOrderMethod(event) {
		event.stopPropagation();
		await getCartModel("priceAndDiscountDialog");
	}

	return (
		<OrderPriceSummaryButton type="tertiary" onClick={showOrderMethod} data-testid="ordermethodbtn">
			<Label fontsize={"14px"} fontweight={"bold"}>
				{getMessage("SG_LBL_Price", messages, "Price")}
			</Label>
			<SSRIcon paths={rightIcon}></SSRIcon>
		</OrderPriceSummaryButton>
	);
};

export default OrderPriceSummarySelection;
