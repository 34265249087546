import "react-app-polyfill/ie11";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "date-input-polyfill";
import "custom-event-polyfill";
import smoothscroll from "smoothscroll-polyfill";
import { AppContainer } from "react-hot-loader";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom";

import { GaHelper } from "@coworker/sharedlibrary";
import { GlobalStyle } from "./globalStyle";
import App from "./App";
import AppLogger from "./logger/appLogger";
import ErrorContainer from "./ErrorContainer";
import registerServiceWorker from "./serviceWorker";
import store from "./store/store";
import { history } from "./store/history";
import "./index.scss";
import SalesCoworkerConfiguration from "./store/salesCoworkerConfiguration";
import * as webNotify from "./external/webNotify";

smoothscroll.polyfill();
AppLogger.init(store);
SalesCoworkerConfiguration.initStore(store);
webNotify.init();
history.listen(location => {
	scrollToMain();
	// eslint-disable-next-line no-undef
	if (!location.pathname.includes("id_token") && !location.pathname.includes("code")) {
		GaHelper.SendPageView(location.pathname);
	}
});

const rootElement = document.getElementById("root");
//injectJiraIssueCollector();

const Root = () => (
	<AppContainer>
		<Provider store={store}>
			<Router history={history}>
				<ErrorContainer>
					<App history={history} />
				</ErrorContainer>
			</Router>
		</Provider>
	</AppContainer>
);
const render = () => {
	ReactDOM.render(
		<>
			<GlobalStyle />
			<Root />
		</>,
		rootElement
	);
};
render();
// eslint-disable-next-line no-undef
if (module.hot) {
	console.log("hot load");
	// eslint-disable-next-line no-undef
	module.hot.accept("./App", () => {
		render();
	});
}

registerServiceWorker();

// eslint-disable-next-line no-undef

const scrollToMain = () => {
	try {
		window.scroll({ top: 0, left: 0, behavior: "smooth" });
		var maindiv = document.getElementsByClassName("routeArea")[0];
		if (maindiv) maindiv.scroll({ top: 0, left: 0, behavior: "smooth" });
	} catch (error) {
		//
	}
};
